import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./WordPower.css";
import NavigatingButtons from "../../components/Lessons/NavigatingButtons/NavigatingButtons";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  Tab,
  Tabs,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import axios from "axios";
import { Document, Packer, Paragraph } from "docx";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { findDiff } from "../../../utils";
import ExcelIcon from "./icons/Excel";
import DocxIcon from "./icons/Docx";
import { getHanjaTitle } from "../../../utils/index";

// current state:
// wordPowers use a number for lesson
// newHanjaCombos use both a number and a string for lesson (e.g. 1 and "1") we need to fix this to be consistent
class WordPower extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newHanja: [],
      newHanjaCombos: [],
      currentLesson: parseInt(this.props.match.params.lesson),
      wordPowerData: [],
      showLoading: false,
      yemunCount: 1,
      examplesTabValue: "simple",
      clickedWordTab: 0,
      clickedHanjaTab: false,
      clickedRadicalTab: false,
      showTranslation: false,
      engChecked: false,
      showNewHanjaComboWords: true,
      newHanjaComboWordsChecked: true,
      showAllWords: false,
      showAllWordsChecked: false,
      tabType: "hanja",
    };
  }

  componentDidMount() {
    this.setState({
      clickedHanja: { id: "null" },
      clickedWord: { id: "null" },
      clickedRadical: { id: "null" },
    });

    axios.get("/api/lesson/getNewHanja").then((resp) => {
      const currLesson = this.state.currentLesson;
      this.setState({
        newHanja: (resp.data || [])
          .sort((a, b) => a.hanja.localeCompare(b.hanja, "ko-KR"))
          .filter((word) => {
            return word.lesson === currLesson;
          }),
      });
    });

    axios.get("/api/okpyeon/getRadicals").then((resp) => {
      const currLesson = this.state.currentLesson;

      this.setState({
        radicals: (resp.data || [])
          .sort((a, b) => a.radical.localeCompare(b.radical))
          .filter((word) => {
            return word.lesson === currLesson;
          }),
      });
    });

    axios.get("/api/lesson/getNewHanjaCombos").then((resp) => {
      const currLesson = this.state.currentLesson;
      this.setState({
        newHanjaCombos: (resp.data || [])
          .sort((a, b) => a.hanja.localeCompare(b.hanja, "ko-KR"))
          .filter((combo) => {
            return combo.lesson == currLesson; // TODO: REMOVE duplicates and change from loose equality to strict because there are duplicates with different types
          }),
      });
    });
  }

  getWordPowerYemunData = (id) => {
    const params = { lesson: this.state.currentLesson };
    if (this.state.tabType === "hanja") {
      params.clickedHanja = id;
    } else if (this.state.tabType === "radical") {
      params.clickedRadical = id;
    }

    axios({
      method: "get",
      url: "/api/wordPower/v2/list",
      params,
    })
      .then((response) => {
        const wordPowerData = response.data.wordPowerList.sort((a, b) =>
          a.hanqca.localeCompare(b.hanqca, "ko-KR"),
        );
        this.setState({ wordPowerData: wordPowerData });
        this.setState({ showLoading: false });
      })
      .catch(() => {
        alert("Error receiving wordPower data");
      });
  };

  handleOnChangeExamplesTab = (event, value) => {
    this.setState({ examplesTabValue: value });
  };

  handleOnClickShowTranslation = () => {
    this.setState({ showTranslation: !this.state.showTranslation });
    this.setState({ engChecked: !this.state.engChecked });
  };

  handleOnChangeHanjaTab = (event, value) => {
    this.setState({
      clickedHanja: event.currentTarget,
      clickedHanjaTab: value,
    });
    this.setState({ showLoading: true });
    this.setState({ examplesTabValue: "simple", yemunCount: 1 });
    this.setState({ showTranslation: false, engChecked: false });
    this.setState({ clickedWord: { id: "null" } });
    this.setState({ clickedWordTab: 0 });
    this.getWordPowerYemunData(event.currentTarget.id);
  };

  handleOnChangeRadicalTab = (event, value) => {
    this.setState({
      clickedRadical: event.currentTarget,
      clickedRadicalTab: value,
    });
    this.setState({ showLoading: true });
    this.setState({ examplesTabValue: "simple", yemunCount: 1 });
    this.setState({ showTranslation: false, engChecked: false });
    this.setState({ clickedWord: { id: "null" } });
    this.setState({ clickedWordTab: 0 });
    this.getWordPowerYemunData(event.currentTarget.id);
  };

  handleOnClickWord = (event, value) => {
    this.setState({ clickedWord: event.currentTarget, clickedWordTab: value });
    this.setState({ examplesTabValue: "simple", yemunCount: 1 });
    this.setState({ showTranslation: false, engChecked: false });
  };

  handleClickNextYemun = () => {
    this.setState({ yemunCount: this.state.yemunCount + 1 });
    this.setState({ showTranslation: false, engChecked: false });
  };

  handleClickPrevYemun = () => {
    this.setState({ yemunCount: this.state.yemunCount - 1 });
    this.setState({ showTranslation: false, engChecked: false });
  };

  filterWordPowerData = (clickedWordIdPosition, char) => {
    const { tabType } = this.state;
    return this.state.wordPowerData
      .filter((item) => {
        if (
          !item.hanqca
            .trim()
            .normalize("NFC")
            .includes(
              char[tabType === "hanja" ? "hanja" : "radical"]
                .replace(/\s/g, "")
                .trim()
                .normalize("NFC"),
            )
        ) {
          return false;
        }
        return true;
      })
      .filter((i) => {
        if (!this.state.clickedWord) {
          return;
        }
        let clickedWordId = this.state.clickedWord.id.split("!!!");
        if (clickedWordIdPosition === 0) {
          if (
            clickedWordId[clickedWordIdPosition].trim().normalize("NFC") !==
            i.hanqca.trim().normalize("NFC")
          ) {
            return false;
          }
          return true;
        } else if (clickedWordIdPosition === 1) {
          if (clickedWordId[clickedWordIdPosition] !== i.hankul) {
            return false;
          }
          return true;
        } else if (clickedWordIdPosition === 2) {
          if (clickedWordId[clickedWordIdPosition] !== i.englishGloss) {
            return false;
          }
          return true;
        } else {
          return;
        }
      });
  };

  handleToggleNewHanjaCombos = () => {
    this.setState({
      showNewHanjaComboWords: !this.state.showNewHanjaComboWords,
      newHanjaComboWordsChecked: !this.state.newHanjaComboWordsChecked,
    });
    this.setState({ showAllWords: false, showAllWordsChecked: false });
    this.setState({ clickedWord: { id: "null" } });
    this.setState({ clickedWordTab: 0 });
  };

  handleToggleShowAllWords = () => {
    this.setState({
      showAllWords: !this.state.showAllWords,
      showAllWordsChecked: !this.state.showAllWordsChecked,
    });
    this.setState({
      showNewHanjaComboWords: false,
      newHanjaComboWordsChecked: false,
    });
    this.setState({ clickedWord: { id: "null" } });
    this.setState({ clickedWordTab: 0 });
  };

  generateHelper = (char) => {
    if (this.state.showAllWords === true) {
      wordPowers = this.state.wordPowerData.filter((item) =>
        item.hanqca
          .trim()
          .normalize("NFC")
          .includes(char["hanja"].replace(/\s/g, "").trim().normalize("NFC")),
      );
    } else {
      wordPowers = this.state.wordPowerData.reduce((filteredResult, item) => {
        this.state.newHanjaCombos.forEach((combo) => {
          if (
            item.hanqca
              .trim()
              .normalize("NFC")
              .includes(combo.hanja.replace(/\s/g, "").trim().normalize("NFC"))
          ) {
            if (filteredResult.indexOf(item) === -1) {
              filteredResult.push(item);
            }
          }
        });
        return filteredResult;
      }, []);
    }
    return wordPowers;
  };

  generateExcel = (char) => {
    const wordPowers = this.generateHelper(char);
    const hoon = findDiff(char.hoonEum, char.eum);
    const hanja = char.hanja.trim().normalize("NFC");
    const eum = char.eum;
    const ws = XLSX.utils.json_to_sheet([
      { Hankul: hoon, 한자: hanja, 음: eum },
    ]);
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          "한자어",
          "한글",
          "English Gloss",
          "Korean Sentences",
          "Known 한자",
          "한자-ized Sentences",
          "Translation",
        ],
      ],
      { origin: "D1" },
    );

    // initialize cell merges
    if (!ws["!merges"]) ws["!merges"] = [];

    // where to start adding the examples for each word
    let originRowNum = 2;

    wordPowers.forEach((item) => {
      const columnExamples = item.examples.reduce(
        (filteredResult, example, idx) => {
          filteredResult.push([
            idx === 0 ? item.hanqca : null,
            idx === 0 ? item.hankul : null,
            idx === 0 ? item.englishGloss : null,
            example.koreanSentence,
            example.simpleHanqca,
            example.hanqcaizedSentence,
            example.translation,
          ]);
          return filteredResult;
        },
        [],
      );
      XLSX.utils.sheet_add_aoa(ws, columnExamples, {
        origin: `D${originRowNum}`,
      });
      ws["!merges"].push(
        XLSX.utils.decode_range(
          `D${originRowNum}:D${originRowNum + item.examples.length - 1}`,
        ),
      );
      ws["!merges"].push(
        XLSX.utils.decode_range(
          `E${originRowNum}:E${originRowNum + item.examples.length - 1}`,
        ),
      );
      ws["!merges"].push(
        XLSX.utils.decode_range(
          `F${originRowNum}:F${originRowNum + item.examples.length - 1}`,
        ),
      );
      originRowNum += item.examples.length + 1;
    });

    const wscols = [
      { wch: 6 },
      { wch: 6 },
      { wch: 4 },
      { wch: 6 },
      { wch: 6 },
      { wch: 15 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
    ];
    ws["!cols"] = wscols;
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "WordPowers");
    const docTitle = `WordPowers for ${getHanjaTitle(char)} (${this.state.showAllWords ? "All" : "Combos"})`;
    XLSX.writeFile(wb, `${docTitle}.xlsx`, { compression: true });
  };

  generateDoc = (char) => {
    const title = getHanjaTitle(char);
    const all = [];
    const wordPowers = this.generateHelper(char);

    wordPowers.forEach((item) => {
      const wordHankul = item.hankul;
      const hanqca = item.hanqca;
      const englishGloss = item.englishGloss;
      const examples = item.examples;
      all.push(
        new Paragraph({
          text: `${wordHankul} ${hanqca} ${englishGloss}`,
          bullet: {
            level: 0,
          },
        }),
      );

      examples.forEach((example, idx) => {
        const inner = [
          new Paragraph({
            text: `Yemun ${idx + 1}`,
            bullet: {
              level: 1,
            },
          }),
          new Paragraph({
            text: example.koreanSentence,
            bullet: {
              level: 2,
            },
          }),
          new Paragraph({
            text: example.simpleHanqca,
            bullet: {
              level: 2,
            },
          }),
          new Paragraph({
            text: example.hanqcaizedSentence,
            bullet: {
              level: 2,
            },
          }),
          new Paragraph({
            text: example.translation,
            bullet: {
              level: 2,
            },
          }),
        ];
        all.push(...inner);
      });
    });

    const doc = new Document({
      title: "KORN351 Interline Reader",
      description: `KORN351 Interline Reader Word Power for ${this.state.clickedHanja.id}
			}`,
      styles: {
        default: {
          document: {
            run: {
              size: "11pt",
              font: "Batang",
            },
          },
        },
      },
      sections: [
        {
          children: [new Paragraph({ text: title }), ...all],
        },
      ],
    });
    const docTitle = `WordPowers for ${title} (${this.state.showAllWords ? "All" : "Combos"})`;
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, docTitle);
    });
  };

  render() {
    const {
      clickedHanjaTab,
      newHanja,
      radicals,
      newHanjaCombos,
      tabType,
      clickedRadicalTab,
      clickedHanja,
      clickedRadical,
    } = this.state;

    return (
      <Grid container>
        <Grid item md={1} />
        <Grid item xs={12} md={10}>
          <div
            className="col-lg-12 context engVer"
            style={{ paddingBottom: "48px" }}
            id="theHeader"
          >
            <div
              className={"storyHeader"}
              style={{ display: "flex", width: "100%" }}
            >
              <h3 style={{ textAlign: "left", width: "50%" }}>
                Building Word Power with 漢字/새 부수
              </h3>
            </div>
            <div>
              <h4 style={{ textAlign: "left", width: "50%" }}>
                제 {this.state.currentLesson} 과
              </h4>
            </div>
            <Divider style={{ marginBottom: "0.5rem" }} />
            <Grid container>
              <Grid item xs={12}>
                <Grid item md={1} />
                <Typography color="textSecondary" variant="body1">
                  Select a tab type to show words that contain that 漢字 or 새
                  부수.
                  <br />
                </Typography>
                <FormControl style={{ width: 250 }}>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={tabType}
                    onChange={(event) =>
                      this.setState({
                        tabType: event.target.value,
                        showAllWords: event.target.value === "radical",
                        showAllWordsChecked: event.target.value === "radical",
                      })
                    }
                  >
                    <MenuItem value="hanja">Hanja</MenuItem>
                    <MenuItem value="radical">Radical</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <Tabs
                    value={
                      tabType === "hanja" ? clickedHanjaTab : clickedRadicalTab
                    }
                    onChange={
                      tabType === "hanja"
                        ? this.handleOnChangeHanjaTab
                        : this.handleOnChangeRadicalTab
                    }
                    indicatorColor="secondary"
                    textColor="primary"
                    variant="scrollable"
                    scrollbuttons="auto"
                    style={{ padding: "2%" }}
                    wrapped="true"
                  >
                    {tabType === "hanja"
                      ? (newHanja || []).map((hanjaTab, idx) => {
                          return (
                            hanjaTab &&
                            hanjaTab.hoonEum &&
                            hanjaTab.hanja && (
                              <Tab
                                id={
                                  hanjaTab.hanja
                                    ? hanjaTab.hanja
                                        .replace(/\s/g, "")
                                        .trim()
                                        .normalize("NFC")
                                    : idx
                                }
                                key={idx}
                                label={
                                  <React.Fragment>
                                    {getHanjaTitle(hanjaTab)} &nbsp;&nbsp;
                                  </React.Fragment>
                                }
                              />
                            )
                          );
                        })
                      : (radicals || []).map(
                          (radical, idx) =>
                            radical &&
                            radical.radical && (
                              <Tab
                                id={
                                  radical.radical
                                    ? radical.radical
                                        .replace(/\s/g, "")
                                        .trim()
                                        .normalize("NFC")
                                    : idx
                                }
                                key={idx}
                                label={
                                  <React.Fragment>
                                    {radical.radical.trim().normalize("NFC")}{" "}
                                    &nbsp;&nbsp;
                                  </React.Fragment>
                                }
                              />
                            ),
                        )}
                  </Tabs>
                </div>
              </Grid>
              {!this.state.showLoading &&
                ((clickedHanjaTab === false && tabType === "hanja") ||
                  (clickedRadicalTab === false && tabType === "radical")) && (
                  <Typography color="error">
                    Select a tab to show a{" "}
                    {tabType === "hanja" ? "漢字" : "새 부수"}.
                  </Typography>
                )}
              {this.state.showLoading ? (
                <Grid item xs={12}>
                  <CircularProgress />
                  <Typography>Fetching data, please wait.</Typography>
                </Grid>
              ) : tabType === "hanja" ? (
                (newHanja || []).map((char, idx) => {
                  if (
                    char.hanja.replace(/\s/g, "").trim().normalize("NFC") ===
                    clickedHanja.id.trim().normalize("NFC")
                  ) {
                    return (
                      char && (
                        <Grid
                          item
                          xs={12}
                          className="word-power-grid-card"
                          key={idx}
                        >
                          <Card variant="outlined" className="word-power-card">
                            <CardContent className="word-power-card-content">
                              <div className="word-power-card-div-1">
                                <Typography variant="h5" component="h2">
                                  {getHanjaTitle(char)}
                                </Typography>
                                {char.hoonEum &&
                                  char.hoonEum.split(";").map((c) => (
                                    <Typography
                                      key={c}
                                      color="textPrimary"
                                      component="h3"
                                    >
                                      {c.trim()}{" "}
                                      {char.hanja.trim().normalize("NFC")}
                                    </Typography>
                                  ))}
                                <Typography color="textSecondary" gutterBottom>
                                  부수: {char.radical} &nbsp; (
                                  {char.radicalHangul}) +{" "}
                                  {char.characterStrokeCount}획
                                </Typography>
                              </div>
                              <Divider style={{ marginBottom: "0.5rem" }} />
                              <div className="word-power-card-div-1.5-combo-toggle">
                                <Grid
                                  container
                                  spacing={4}
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Grid item xs={6}>
                                    <FormControlLabel
                                      onChange={this.handleToggleNewHanjaCombos}
                                      checked={
                                        this.state.newHanjaComboWordsChecked
                                      }
                                      value="combos"
                                      control={<Switch />}
                                      label="漢字 combos only"
                                    />
                                    <br />
                                    <FormControlLabel
                                      onChange={this.handleToggleShowAllWords}
                                      checked={this.state.showAllWordsChecked}
                                      value="all-words"
                                      control={<Switch />}
                                      label="All words"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ gap: "1rem", display: "flex" }}
                                  >
                                    <Button
                                      startIcon={<GetAppIcon />}
                                      endIcon={<DocxIcon />}
                                      size="medium"
                                      variant="contained"
                                      onClick={() => this.generateDoc(char)}
                                    >
                                      DOCX
                                    </Button>
                                    <Button
                                      startIcon={<GetAppIcon />}
                                      endIcon={<ExcelIcon />}
                                      size="medium"
                                      variant="contained"
                                      onClick={() => this.generateExcel(char)}
                                    >
                                      XLSX
                                    </Button>
                                  </Grid>
                                </Grid>
                              </div>
                              <Divider style={{ marginBottom: "0.5rem" }} />
                              <div className="word-power-card-div-2">
                                {this.state.wordPowerData.length === 0 ? (
                                  <Grid item xs={12}>
                                    <br />
                                    <br />
                                    <Typography className="no-data">
                                      <h1>No data available.</h1>
                                    </Typography>
                                  </Grid>
                                ) : (
                                  <Tabs
                                    value={this.state.clickedWordTab}
                                    onChange={this.handleOnClickWord}
                                    indicatorColor="secondary"
                                    textColor="primary"
                                    style={{ padding: "1%", width: "100%" }}
                                    orientation="vertical"
                                    key={"Tab" + this.state.clickedWordTab}
                                    wrapped="true"
                                  >
                                    {this.state.showAllWords === true &&
                                      this.state.wordPowerData
                                        .filter((item) => {
                                          if (
                                            !item.hanqca
                                              .trim()
                                              .normalize("NFC")
                                              .includes(
                                                char.hanja
                                                  .replace(/\s/g, "")
                                                  .trim()
                                                  .normalize("NFC"),
                                              )
                                          ) {
                                            return false;
                                          }
                                          return true;
                                        })
                                        .map((wordTab, idx) => {
                                          return (
                                            <Tab
                                              id={
                                                wordTab.hanqca
                                                  .trim()
                                                  .normalize("NFC") +
                                                "!!!" +
                                                wordTab.hankul +
                                                "!!!" +
                                                wordTab.englishGloss
                                              }
                                              key={idx}
                                              label={
                                                <div className="wordTab-label-flexbox">
                                                  <div className="wordTab-label-flexbox-1">
                                                    <React.Fragment>
                                                      {wordTab.hanqca
                                                        .trim()
                                                        .normalize("NFC")}
                                                      ({wordTab.hankul})
                                                    </React.Fragment>
                                                  </div>
                                                  <div className="wordTab-label-flexbox-2">
                                                    <React.Fragment>
                                                      {wordTab.englishGloss}
                                                    </React.Fragment>
                                                  </div>
                                                </div>
                                              }
                                            />
                                          );
                                        })}

                                    {this.state.showNewHanjaComboWords ===
                                      true &&
                                      this.state.wordPowerData
                                        .reduce((filteredResult, item) => {
                                          newHanjaCombos.forEach((combo) => {
                                            if (
                                              item.hanqca
                                                .trim()
                                                .normalize("NFC")
                                                .includes(
                                                  combo.hanja
                                                    .replace(/\s/g, "")
                                                    .trim()
                                                    .normalize("NFC"),
                                                )
                                            ) {
                                              if (
                                                filteredResult.indexOf(item) ===
                                                -1
                                              ) {
                                                filteredResult.push(item);
                                              }
                                            }
                                          });
                                          return filteredResult;
                                        }, [])
                                        .map((wordTab, idx) => {
                                          return (
                                            <Tab
                                              id={
                                                wordTab.hanqca
                                                  .trim()
                                                  .normalize("NFC") +
                                                "!!!" +
                                                wordTab.hankul +
                                                "!!!" +
                                                wordTab.englishGloss
                                              }
                                              key={idx}
                                              label={
                                                <div className="wordTab-label-flexbox">
                                                  <div className="wordTab-label-flexbox-1">
                                                    <React.Fragment>
                                                      {wordTab.hanqca
                                                        .trim()
                                                        .normalize("NFC")}
                                                      ({wordTab.hankul})
                                                    </React.Fragment>
                                                  </div>
                                                  <div className="wordTab-label-flexbox-2">
                                                    <React.Fragment>
                                                      {wordTab.englishGloss}
                                                    </React.Fragment>
                                                  </div>
                                                </div>
                                              }
                                            />
                                          );
                                        })}
                                  </Tabs>
                                )}
                              </div>
                            </CardContent>
                          </Card>
                          <Card
                            variant="outlined"
                            className="word-power-card-left"
                          >
                            <CardContent className="word-power-card-content-left">
                              <div className="word-power-wordTabs">
                                <RadioGroup
                                  value={this.state.examplesTabValue}
                                  onChange={this.handleOnChangeExamplesTab}
                                >
                                  <FormControlLabel
                                    value="simple"
                                    control={<Radio />}
                                    label="간단한 한자 예문"
                                  />
                                  <FormControlLabel
                                    value="complete"
                                    control={<Radio />}
                                    label="완벽한 한자 예문"
                                  />
                                  <FormControlLabel
                                    value="hangul"
                                    control={<Radio />}
                                    label="한글"
                                  />
                                </RadioGroup>
                                <FormControlLabel
                                  onChange={this.handleOnClickShowTranslation}
                                  checked={this.state.engChecked}
                                  value="english"
                                  control={<Switch />}
                                  label="영어"
                                />
                                <br />
                                <Button
                                  variant="contained"
                                  style={{ marginRight: 8 }}
                                  onClick={this.handleClickPrevYemun}
                                >
                                  전 예문
                                </Button>
                                <Button
                                  variant="contained"
                                  onClick={this.handleClickNextYemun}
                                >
                                  다음 예문
                                </Button>
                              </div>
                              {this.state.examplesTabValue === "simple" && (
                                <div className="wordTab-results-div">
                                  {this.state.clickedWord.id === "null" && (
                                    <div className="no-data">
                                      <Typography>
                                        Please select a word from the list.
                                      </Typography>
                                    </div>
                                  )}
                                  {this.filterWordPowerData(0, char).map(
                                    (filteredItem, idx) => {
                                      if (filteredItem.examples.length === 0) {
                                        return (
                                          <div key={idx} className="no-data">
                                            <Typography>
                                              예문이 없습니다.
                                            </Typography>
                                          </div>
                                        );
                                      }
                                      return (
                                        <div key={idx}>
                                          <ul style={{ listStyleType: "none" }}>
                                            {filteredItem.examples.map(
                                              (sentence, idx, arr) => {
                                                if (idx + 1 < arr.length) {
                                                  return (
                                                    idx ===
                                                      this.state.yemunCount -
                                                        1 && (
                                                      <div key={idx}>
                                                        <li className="wordTab-results-yemun">
                                                          {
                                                            sentence.simpleHanqca
                                                          }
                                                        </li>
                                                        {this.state
                                                          .showTranslation ===
                                                          true && (
                                                          <li>
                                                            {
                                                              sentence.translation
                                                            }
                                                          </li>
                                                        )}
                                                      </div>
                                                    )
                                                  );
                                                }

                                                if (idx + 1 === arr.length) {
                                                  return (
                                                    idx ===
                                                      this.state.yemunCount -
                                                        1 && (
                                                      <div key={idx}>
                                                        <li className="wordTab-results-yemun">
                                                          {
                                                            sentence.simpleHanqca
                                                          }
                                                        </li>
                                                        {this.state
                                                          .showTranslation ===
                                                          true && (
                                                          <li>
                                                            {
                                                              sentence.translation
                                                            }
                                                          </li>
                                                        )}
                                                        <li className="no-more-yemun-msg">
                                                          ***마지막
                                                          예문입니다.***
                                                        </li>
                                                      </div>
                                                    )
                                                  );
                                                }
                                              },
                                            )}
                                          </ul>
                                        </div>
                                      );
                                    },
                                  )}
                                </div>
                              )}

                              {this.state.examplesTabValue === "complete" && (
                                <div className="wordTab-results-div">
                                  {this.state.clickedWord.id === "null" && (
                                    <div className="no-data">
                                      <Typography>
                                        Please select a word from the list.
                                      </Typography>
                                    </div>
                                  )}
                                  {this.filterWordPowerData(0, char).map(
                                    (filteredItem, idx) => {
                                      if (filteredItem.examples.length === 0) {
                                        return (
                                          <div key={idx} className="no-data">
                                            <Typography>
                                              예문이 없습니다.
                                            </Typography>
                                          </div>
                                        );
                                      }
                                      return (
                                        <div key={idx}>
                                          <ul style={{ listStyleType: "none" }}>
                                            {filteredItem.examples.map(
                                              (sentence, idx, arr) => {
                                                if (idx + 1 < arr.length) {
                                                  return (
                                                    idx ===
                                                      this.state.yemunCount -
                                                        1 && (
                                                      <div key={idx}>
                                                        <li className="wordTab-results-yemun">
                                                          {
                                                            sentence.hanqcaizedSentence
                                                          }
                                                        </li>
                                                        {this.state
                                                          .showTranslation ===
                                                          true && (
                                                          <li>
                                                            {
                                                              sentence.translation
                                                            }
                                                          </li>
                                                        )}
                                                      </div>
                                                    )
                                                  );
                                                }

                                                if (idx + 1 === arr.length) {
                                                  return (
                                                    idx ===
                                                      this.state.yemunCount -
                                                        1 && (
                                                      <div key={idx}>
                                                        <li className="wordTab-results-yemun">
                                                          {
                                                            sentence.hanqcaizedSentence
                                                          }
                                                        </li>
                                                        {this.state
                                                          .showTranslation ===
                                                          true && (
                                                          <li>
                                                            {
                                                              sentence.translation
                                                            }
                                                          </li>
                                                        )}
                                                        <li className="no-more-yemun-msg">
                                                          ***마지막
                                                          예문입니다.***
                                                        </li>
                                                      </div>
                                                    )
                                                  );
                                                }
                                              },
                                            )}
                                          </ul>
                                        </div>
                                      );
                                    },
                                  )}
                                </div>
                              )}

                              {this.state.examplesTabValue === "hangul" && (
                                <div className="wordTab-results-div">
                                  {this.state.clickedWord.id === "null" && (
                                    <div className="no-data">
                                      <Typography>
                                        Please select a word from the list.
                                      </Typography>
                                    </div>
                                  )}
                                  {this.filterWordPowerData(1, char).map(
                                    (filteredItem, idx) => {
                                      if (filteredItem.examples.length === 0) {
                                        return (
                                          <div key={idx} className="no-data">
                                            <Typography>
                                              예문이 없습니다.
                                            </Typography>
                                          </div>
                                        );
                                      }
                                      return (
                                        <div key={idx}>
                                          <ul style={{ listStyleType: "none" }}>
                                            {filteredItem.examples.map(
                                              (sentence, idx, arr) => {
                                                if (idx + 1 < arr.length) {
                                                  return (
                                                    idx ===
                                                      this.state.yemunCount -
                                                        1 && (
                                                      <div key={idx}>
                                                        <li className="wordTab-results-yemun">
                                                          {
                                                            sentence.koreanSentence
                                                          }
                                                        </li>
                                                        {this.state
                                                          .showTranslation ===
                                                          true && (
                                                          <li>
                                                            {
                                                              sentence.translation
                                                            }
                                                          </li>
                                                        )}
                                                      </div>
                                                    )
                                                  );
                                                }

                                                if (idx + 1 === arr.length) {
                                                  return (
                                                    idx ===
                                                      this.state.yemunCount -
                                                        1 && (
                                                      <div key={idx}>
                                                        <li className="wordTab-results-yemun">
                                                          {
                                                            sentence.koreanSentence
                                                          }
                                                        </li>
                                                        {this.state
                                                          .showTranslation ===
                                                          true && (
                                                          <li>
                                                            {
                                                              sentence.translation
                                                            }
                                                          </li>
                                                        )}
                                                        <li className="no-more-yemun-msg">
                                                          ***마지막
                                                          예문입니다.***
                                                        </li>
                                                      </div>
                                                    )
                                                  );
                                                }
                                              },
                                            )}
                                          </ul>
                                        </div>
                                      );
                                    },
                                  )}
                                </div>
                              )}
                            </CardContent>
                          </Card>
                        </Grid>
                      )
                    );
                  }
                })
              ) : (
                (radicals || []).map((char, idx) => {
                  if (
                    char.radical.replace(/\s/g, "").trim().normalize("NFC") ===
                    clickedRadical.id.trim().normalize("NFC")
                  ) {
                    return (
                      char && (
                        <Grid
                          item
                          xs={12}
                          className="word-power-grid-card"
                          key={idx}
                        >
                          <Card variant="outlined" className="word-power-card">
                            <CardContent className="word-power-card-content">
                              <div className="word-power-card-div-1">
                                <Typography variant="h5" component="h2">
                                  {char.radicalName}
                                </Typography>
                                {char.hoonEum &&
                                  char.hoonEum.split(";").map((c) => (
                                    <Typography
                                      key={c}
                                      color="textPrimary"
                                      component="h3"
                                    >
                                      {c.trim()}{" "}
                                      {char.radical.trim().normalize("NFC")}
                                    </Typography>
                                  ))}
                                <Typography color="textSecondary" gutterBottom>
                                  부수: {char.radical} &nbsp; (
                                  {char.radicalHangul}) +{" "}
                                  {char.characterStrokeCount}획
                                </Typography>
                              </div>
                              <Divider style={{ marginBottom: "0.5rem" }} />
                              <div className="word-power-card-div-1.5-combo-toggle">
                                <FormControlLabel
                                  onChange={this.handleToggleShowAllWords}
                                  checked={this.state.showAllWordsChecked}
                                  value="all-words"
                                  control={<Switch />}
                                  label="All words"
                                />
                              </div>
                              <Divider style={{ marginBottom: "0.5rem" }} />
                              <div className="word-power-card-div-2">
                                {this.state.wordPowerData.length === 0 ? (
                                  <Grid item xs={12}>
                                    <br />
                                    <br />
                                    <Typography className="no-data">
                                      <h1>No data available.</h1>
                                    </Typography>
                                  </Grid>
                                ) : (
                                  <Tabs
                                    value={this.state.clickedWordTab}
                                    onChange={this.handleOnClickWord}
                                    indicatorColor="secondary"
                                    textColor="primary"
                                    style={{ padding: "1%", width: "100%" }}
                                    orientation="vertical"
                                    key={"Tab" + this.state.clickedWordTab}
                                    wrapped="true"
                                  >
                                    {this.state.showAllWords === true &&
                                      this.state.wordPowerData
                                        .filter((item) => {
                                          if (
                                            !item.hanqca
                                              .trim()
                                              .normalize("NFC")
                                              .includes(
                                                char.radical
                                                  .replace(/\s/g, "")
                                                  .trim()
                                                  .normalize("NFC"),
                                              )
                                          ) {
                                            return false;
                                          }
                                          return true;
                                        })
                                        .map((wordTab, idx) => {
                                          return (
                                            <Tab
                                              id={
                                                wordTab.hanqca
                                                  .trim()
                                                  .normalize("NFC") +
                                                "!!!" +
                                                wordTab.hankul +
                                                "!!!" +
                                                wordTab.englishGloss
                                              }
                                              key={idx}
                                              label={
                                                <div className="wordTab-label-flexbox">
                                                  <div className="wordTab-label-flexbox-1">
                                                    <React.Fragment>
                                                      {wordTab.hanqca
                                                        .trim()
                                                        .normalize("NFC")}
                                                      ({wordTab.hankul})
                                                    </React.Fragment>
                                                  </div>
                                                  <div className="wordTab-label-flexbox-2">
                                                    <React.Fragment>
                                                      {wordTab.englishGloss}
                                                    </React.Fragment>
                                                  </div>
                                                </div>
                                              }
                                            />
                                          );
                                        })}

                                    {this.state.showNewHanjaComboWords ===
                                      true &&
                                      this.state.wordPowerData
                                        .reduce((filteredResult, item) => {
                                          newHanjaCombos.forEach((combo) => {
                                            if (
                                              item.hanqca
                                                .trim()
                                                .normalize("NFC")
                                                .includes(
                                                  combo.hanja
                                                    .replace(/\s/g, "")
                                                    .trim()
                                                    .normalize("NFC"),
                                                )
                                            ) {
                                              if (
                                                filteredResult.indexOf(item) ===
                                                -1
                                              ) {
                                                filteredResult.push(item);
                                              }
                                            }
                                          });
                                          return filteredResult;
                                        }, [])
                                        .map((wordTab, idx) => {
                                          return (
                                            <Tab
                                              id={
                                                wordTab.hanqca
                                                  .trim()
                                                  .normalize("NFC") +
                                                "!!!" +
                                                wordTab.hankul +
                                                "!!!" +
                                                wordTab.englishGloss
                                              }
                                              key={idx}
                                              label={
                                                <div className="wordTab-label-flexbox">
                                                  <div className="wordTab-label-flexbox-1">
                                                    <React.Fragment>
                                                      {wordTab.hanqca
                                                        .trim()
                                                        .normalize("NFC")}
                                                      ({wordTab.hankul})
                                                    </React.Fragment>
                                                  </div>
                                                  <div className="wordTab-label-flexbox-2">
                                                    <React.Fragment>
                                                      {wordTab.englishGloss}
                                                    </React.Fragment>
                                                  </div>
                                                </div>
                                              }
                                            />
                                          );
                                        })}
                                  </Tabs>
                                )}
                              </div>
                            </CardContent>
                          </Card>
                          <Card
                            variant="outlined"
                            className="word-power-card-left"
                          >
                            <CardContent className="word-power-card-content-left">
                              <div className="word-power-wordTabs">
                                <RadioGroup
                                  value={this.state.examplesTabValue}
                                  onChange={this.handleOnChangeExamplesTab}
                                >
                                  <FormControlLabel
                                    value="simple"
                                    control={<Radio />}
                                    label="간단한 한자 예문"
                                  />
                                  <FormControlLabel
                                    value="complete"
                                    control={<Radio />}
                                    label="완벽한 한자 예문"
                                  />
                                  <FormControlLabel
                                    value="hangul"
                                    control={<Radio />}
                                    label="한글"
                                  />
                                </RadioGroup>
                                <FormControlLabel
                                  onChange={this.handleOnClickShowTranslation}
                                  checked={this.state.engChecked}
                                  value="english"
                                  control={<Switch />}
                                  label="영어"
                                />
                                <br />
                                <Button
                                  variant="contained"
                                  style={{ marginRight: 8 }}
                                  onClick={this.handleClickPrevYemun}
                                >
                                  전 예문
                                </Button>
                                <Button
                                  variant="contained"
                                  onClick={this.handleClickNextYemun}
                                >
                                  다음 예문
                                </Button>
                              </div>
                              {this.state.examplesTabValue === "simple" && (
                                <div className="wordTab-results-div">
                                  {this.state.clickedWord.id === "null" && (
                                    <div className="no-data">
                                      <Typography>
                                        Please select a word from the list.
                                      </Typography>
                                    </div>
                                  )}
                                  {this.filterWordPowerData(0, char).map(
                                    (filteredItem, idx) => {
                                      if (filteredItem.examples.length === 0) {
                                        return (
                                          <div key={idx} className="no-data">
                                            <Typography>
                                              예문이 없습니다.
                                            </Typography>
                                          </div>
                                        );
                                      }
                                      return (
                                        <div key={idx}>
                                          <ul style={{ listStyleType: "none" }}>
                                            {filteredItem.examples.map(
                                              (sentence, idx, arr) => {
                                                if (idx + 1 < arr.length) {
                                                  return (
                                                    idx ===
                                                      this.state.yemunCount -
                                                        1 && (
                                                      <div key={idx}>
                                                        <li className="wordTab-results-yemun">
                                                          {
                                                            sentence.simpleHanqca
                                                          }
                                                        </li>
                                                        {this.state
                                                          .showTranslation ===
                                                          true && (
                                                          <li>
                                                            {
                                                              sentence.translation
                                                            }
                                                          </li>
                                                        )}
                                                      </div>
                                                    )
                                                  );
                                                }

                                                if (idx + 1 === arr.length) {
                                                  return (
                                                    idx ===
                                                      this.state.yemunCount -
                                                        1 && (
                                                      <div key={idx}>
                                                        <li className="wordTab-results-yemun">
                                                          {
                                                            sentence.simpleHanqca
                                                          }
                                                        </li>
                                                        {this.state
                                                          .showTranslation ===
                                                          true && (
                                                          <li>
                                                            {
                                                              sentence.translation
                                                            }
                                                          </li>
                                                        )}
                                                        <li className="no-more-yemun-msg">
                                                          ***마지막
                                                          예문입니다.***
                                                        </li>
                                                      </div>
                                                    )
                                                  );
                                                }
                                              },
                                            )}
                                          </ul>
                                        </div>
                                      );
                                    },
                                  )}
                                </div>
                              )}

                              {this.state.examplesTabValue === "complete" && (
                                <div className="wordTab-results-div">
                                  {this.state.clickedWord.id === "null" && (
                                    <div className="no-data">
                                      <Typography>
                                        Please select a word from the list.
                                      </Typography>
                                    </div>
                                  )}
                                  {this.filterWordPowerData(0, char).map(
                                    (filteredItem, idx) => {
                                      if (filteredItem.examples.length === 0) {
                                        return (
                                          <div key={idx} className="no-data">
                                            <Typography>
                                              예문이 없습니다.
                                            </Typography>
                                          </div>
                                        );
                                      }
                                      return (
                                        <div key={idx}>
                                          <ul style={{ listStyleType: "none" }}>
                                            {filteredItem.examples.map(
                                              (sentence, idx, arr) => {
                                                if (idx + 1 < arr.length) {
                                                  return (
                                                    idx ===
                                                      this.state.yemunCount -
                                                        1 && (
                                                      <div key={idx}>
                                                        <li className="wordTab-results-yemun">
                                                          {
                                                            sentence.hanqcaizedSentence
                                                          }
                                                        </li>
                                                        {this.state
                                                          .showTranslation ===
                                                          true && (
                                                          <li>
                                                            {
                                                              sentence.translation
                                                            }
                                                          </li>
                                                        )}
                                                      </div>
                                                    )
                                                  );
                                                }

                                                if (idx + 1 === arr.length) {
                                                  return (
                                                    idx ===
                                                      this.state.yemunCount -
                                                        1 && (
                                                      <div key={idx}>
                                                        <li className="wordTab-results-yemun">
                                                          {
                                                            sentence.hanqcaizedSentence
                                                          }
                                                        </li>
                                                        {this.state
                                                          .showTranslation ===
                                                          true && (
                                                          <li>
                                                            {
                                                              sentence.translation
                                                            }
                                                          </li>
                                                        )}
                                                        <li className="no-more-yemun-msg">
                                                          ***마지막
                                                          예문입니다.***
                                                        </li>
                                                      </div>
                                                    )
                                                  );
                                                }
                                              },
                                            )}
                                          </ul>
                                        </div>
                                      );
                                    },
                                  )}
                                </div>
                              )}

                              {this.state.examplesTabValue === "hangul" && (
                                <div className="wordTab-results-div">
                                  {this.state.clickedWord.id === "null" && (
                                    <div className="no-data">
                                      <Typography>
                                        Please select a word from the list.
                                      </Typography>
                                    </div>
                                  )}
                                  {this.filterWordPowerData(1, char).map(
                                    (filteredItem, idx) => {
                                      if (filteredItem.examples.length === 0) {
                                        return (
                                          <div key={idx} className="no-data">
                                            <Typography>
                                              예문이 없습니다.
                                            </Typography>
                                          </div>
                                        );
                                      }
                                      return (
                                        <div key={idx}>
                                          <ul style={{ listStyleType: "none" }}>
                                            {filteredItem.examples.map(
                                              (sentence, idx, arr) => {
                                                if (idx + 1 < arr.length) {
                                                  return (
                                                    idx ===
                                                      this.state.yemunCount -
                                                        1 && (
                                                      <div key={idx}>
                                                        <li className="wordTab-results-yemun">
                                                          {
                                                            sentence.koreanSentence
                                                          }
                                                        </li>
                                                        {this.state
                                                          .showTranslation ===
                                                          true && (
                                                          <li>
                                                            {
                                                              sentence.translation
                                                            }
                                                          </li>
                                                        )}
                                                      </div>
                                                    )
                                                  );
                                                }

                                                if (idx + 1 === arr.length) {
                                                  return (
                                                    idx ===
                                                      this.state.yemunCount -
                                                        1 && (
                                                      <div key={idx}>
                                                        <li className="wordTab-results-yemun">
                                                          {
                                                            sentence.koreanSentence
                                                          }
                                                        </li>
                                                        {this.state
                                                          .showTranslation ===
                                                          true && (
                                                          <li>
                                                            {
                                                              sentence.translation
                                                            }
                                                          </li>
                                                        )}
                                                        <li className="no-more-yemun-msg">
                                                          ***마지막
                                                          예문입니다.***
                                                        </li>
                                                      </div>
                                                    )
                                                  );
                                                }
                                              },
                                            )}
                                          </ul>
                                        </div>
                                      );
                                    },
                                  )}
                                </div>
                              )}
                            </CardContent>
                          </Card>
                        </Grid>
                      )
                    );
                  }
                })
              )}
            </Grid>
          </div>
        </Grid>
        <NavigatingButtons />
      </Grid>
    );
  }
}

export default withRouter(WordPower);
