import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import PlusIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import axios from "axios";
import * as _ from "lodash";
import Papa from "papaparse";
import React, { useCallback, useEffect, useState } from "react";
import { getNewHanja, getYemun } from "../../../../actions/KORN351/Lessons";
import { getRadicals } from "../../../../actions/KORN351/Okpyeon";
import sampleCsv from "../../../../assets/sample-wordpower-upload.csv";
import { compareHanja } from "../../../../utils";
import "./WordPower.css";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

const normalizeHanja = (hanja) =>
  hanja.replace(/[\s\p{P}]/gu, "").normalize("NFC"); // Remove spaces and punctuation

const WrappedCircular = () => (
  <TableBody>
    <TableRow>
      <TableCell>
        <CircularProgress />
      </TableCell>
    </TableRow>
  </TableBody>
);

const WordPowerModal = ({ onCreate, onCancel }) => {
  const [hanqca, setHanqca] = useState("");
  const [hankul, setHankul] = useState("");
  const [englishGloss, setEnglishGloss] = useState("");
  const [checkedAddNewHanjaCombo, setCheckedAddNewHanjaCombo] = useState(null);

  const createWordPower = () => {
    if (
      !hanqca ||
      !hankul ||
      !englishGloss ||
      checkedAddNewHanjaCombo == null
    ) {
      return;
    }
    const newWordPower = {
      hanqca,
      hankul,
      englishGloss,
      checkedAddNewHanjaCombo,
    };
    onCreate(newWordPower);
  };

  return (
    <Box className="ir-WordPower-modal">
      <Typography variant="h6">Add New Word Power</Typography>
      <TextField
        fullWidth
        label="Hanja"
        variant="outlined"
        value={hanqca}
        onChange={(e) => setHanqca(e.target.value.trim().normalize("NFC"))}
        sx={{ my: 2 }}
      />
      <TextField
        fullWidth
        label="Hankul"
        variant="outlined"
        value={hankul}
        onChange={(e) => setHankul(e.target.value.trim().normalize("NFC"))}
        sx={{ my: 2 }}
      />
      <TextField
        fullWidth
        label="English Gloss"
        variant="outlined"
        value={englishGloss}
        onChange={(e) =>
          setEnglishGloss(e.target.value.trim().normalize("NFC"))
        }
        sx={{ my: 2 }}
      />
      <Typography sx={{ mt: 2, mb: 1 }}>
        Also add to New Hanja Combos?
      </Typography>
      <RadioGroup
        onChange={(e, v) => setCheckedAddNewHanjaCombo(v)}
        value={checkedAddNewHanjaCombo}
      >
        <FormControlLabel value="add-combo" control={<Radio />} label="Add" />
        <FormControlLabel
          value="do-not-add-combo"
          control={<Radio />}
          label="Do not add"
        />
      </RadioGroup>
      <Box sx={{ mt: 2, display: "flex", alignItems: "center", gap: "0.5em" }}>
        <Button
          variant="contained"
          onClick={createWordPower}
          disabled={
            !hanqca ||
            !hankul ||
            !englishGloss ||
            checkedAddNewHanjaCombo == null
          }
          color="primary"
        >
          Create
        </Button>{" "}
        &nbsp;&nbsp; {/* eslint-disable-line */}
        <Button variant="contained" onClick={onCancel} color="secondary">
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

// Table to show all yemuns that are associated with a chosen hanja or radical
// We can create word powers from these yemuns
const PotentialWordPowerTable = (props) => {
  const { yemun, lesson, onCreate } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [enumYemun, setEnumYemun] = useState([]);
  const [showDuplicates, setShowDuplicates] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentYemun, setCurrentYemun] = useState({});

  const open = Boolean(anchorEl);
  const popperId = (yemunId) => (open ? `popper-${yemunId}` : undefined);

  useEffect(() => {
    const sortedYemun = [...yemun].sort((a, b) =>
      normalizeHanja(a.simpleHanqca).localeCompare(
        normalizeHanja(b.simpleHanqca),
        "ko-KR",
      ),
    );

    // Initialize a map to track duplicates by their normalized translation
    let duplicateStatusMap = {};

    sortedYemun.forEach((item) => {
      const key = normalizeHanja(item.simpleHanqca);
      if (duplicateStatusMap[key]) {
        duplicateStatusMap[key].count += 1;
      } else {
        duplicateStatusMap[key] = { count: 1 };
        item.main = true;
      }
    });

    const updatedYemun = sortedYemun.map((item) => {
      const key = normalizeHanja(item.simpleHanqca);
      return { ...item, isDuplicate: duplicateStatusMap[key].count > 1 };
    });

    setEnumYemun(updatedYemun);
    setIsLoading(false);
  }, [yemun]);

  const onAdd = (event, yemun) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setCurrentYemun(yemun);
  };

  const onCreateWordPower = (wordpower) => {
    wordpower = { ...wordpower, examples: [currentYemun], lesson };
    axios({
      method: "post",
      url: "/api/wordPower/createWordPower",
      data: wordpower,
    })
      .then(({ data }) => {
        onCreate(data.wordPower);
        setCurrentYemun({});
        setAnchorEl(null);
        alert("Created successfully!");
      })
      .catch((error) => {
        console.error("Error creating word power:", error);
        alert("An error occurred.");
      });
  };

  return (
    <Box>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        id={popperId(currentYemun._id)}
        modifiers={{
          offset: {
            enabled: true,
            offset: "0, 225",
          },
        }}
        style={{ zIndex: 3 }}
      >
        <WordPowerModal
          onCreate={onCreateWordPower}
          onCancel={() => setAnchorEl(null)}
        />
      </Popper>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          <div className="ir-WordPower-header">
            <h5>Create Word Powers from Yemun</h5>
          </div>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end">
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={showDuplicates}
                    onChange={() => setShowDuplicates(!showDuplicates)}
                  />
                }
                label={showDuplicates ? "Hide Duplicates" : "Show Duplicates"}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell align="right">Simple Hanja</TableCell>
              <TableCell align="right">Hanjaized Sentence</TableCell>
              <TableCell align="right">Korean Sentence</TableCell>
              <TableCell align="right">Translation</TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <WrappedCircular />
          ) : (
            <TableBody>
              {enumYemun
                .filter((y) => showDuplicates || !y.isDuplicate || y.main) // Show if showDuplicates is true, or it's not a duplicate, or if it's main example in the group of duplicates
                .map((y) => {
                  const grayOut = y.isDuplicate && !y.main;
                  return (
                    <TableRow
                      key={y._id}
                      style={{
                        backgroundColor: grayOut ? grey[200] : "inherit",
                      }}
                    >
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={(ev) => onAdd(ev, y)}
                          className="primary-button"
                          id={popperId(y._id)}
                        >
                          <PlusIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        {y.simpleHanqca.trim().normalize("NFC")}
                      </TableCell>
                      <TableCell align="right">
                        {y.hanqcaizedSentence.trim().normalize("NFC")}
                      </TableCell>
                      <TableCell align="right">
                        {y.koreanSentence.trim().normalize("NFC")}
                      </TableCell>
                      <TableCell align="right">
                        {y.translation.trim().normalize("NFC")}
                        {y.isDuplicate && (
                          <Tooltip
                            title={`This example is duplicated in a different lesson. (This example is from L${y.lesson})`}
                          >
                            <IconButton size="small">
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

// Table to show all yemuns that are associated with an existing word power
// We can add/remove yemuns that match the hanjaized sentence of the word power that are not already associated with the word power
const WordPowerExistingYemunTable = (props) => {
  const { yemun, wordPower, onSelect } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [enumYemun, setEnumYemun] = useState([]);
  const [showDuplicates, setShowDuplicates] = useState(false);

  useEffect(() => {
    const normalizedHanja = normalizeHanja(wordPower.hanqca);

    const markedYemun = yemun.map((y) => ({
      ...y,
      selected: wordPower.examples.some((example) => example._id === y._id),
    }));

    // Filter the yemun to only include those that contain the hanja of the word power
    const filteredYemun = markedYemun.filter(
      (y) =>
        y.hanqcaizedSentence &&
        typeof y.hanqcaizedSentence === "string" &&
        y.hanqcaizedSentence
          .replace(/\s/g, "")
          .trim()
          .normalize("NFC")
          .includes(normalizedHanja),
    );

    const sortedYemun = [...filteredYemun].sort((a, b) => {
      // First, prioritize selected items
      if (a.selected && !b.selected) return -1;
      if (!a.selected && b.selected) return 1;

      // If both have the same selected status, then sort by simpleHanqca
      const simpleHanqcaCompare = normalizeHanja(a.simpleHanqca).localeCompare(
        normalizeHanja(b.simpleHanqca),
        "ko-KR",
      );
      if (simpleHanqcaCompare !== 0) return simpleHanqcaCompare;

      // If simpleHanqca is the same, sort by lesson number
      return Number(a.lesson) - Number(b.lesson);
    });

    // Initialize a map to track duplicates by their normalized translation
    let duplicateStatusMap = {};

    sortedYemun.forEach((item) => {
      const key = normalizeHanja(item.simpleHanqca);
      if (duplicateStatusMap[key]) {
        duplicateStatusMap[key].count += 1;
      } else {
        duplicateStatusMap[key] = { count: 1 };
        item.main = true; // Mark the first occurrence as the main one
      }
    });

    const updatedYemun = sortedYemun.map((item) => {
      const key = normalizeHanja(item.simpleHanqca);
      return {
        ...item,
        isDuplicate: duplicateStatusMap[key].count > 1,
      };
    });

    setEnumYemun(updatedYemun);
    setIsLoading(false);
  }, [yemun, wordPower]);

  const handleSelectChange = (id) => {
    let selectedItem = null;

    const updatedYemun = enumYemun.map((y) => {
      if (y._id === id) {
        selectedItem = { ...y, selected: !y.selected }; // Store the updated item
        return selectedItem;
      }
      return y;
    });

    if (selectedItem) onSelect(wordPower._id, selectedItem);
    setEnumYemun(updatedYemun);
  };

  return (
    <Box>
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <div className="ir-WordPower-header">
            <h5>Existing Yemun to Add</h5>
          </div>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end">
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={showDuplicates}
                    onChange={() => setShowDuplicates(!showDuplicates)}
                  />
                }
                label={showDuplicates ? "Hide Duplicates" : "Show Duplicates"}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper} style={{ maxHeight: "50vh" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell align="right">Simple Hanja</TableCell>
              <TableCell align="right">Hanjaized Sentence</TableCell>
              <TableCell align="right">Korean Sentence</TableCell>
              <TableCell align="right">Translation</TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <WrappedCircular />
          ) : (
            <TableBody>
              {enumYemun
                .filter(
                  (y) =>
                    showDuplicates || !y.isDuplicate || y.selected || y.main,
                ) // Show if showDuplicates is true, or it's not a duplicate, or if it's selected or main example in the group of duplicates
                .map((y) => {
                  const grayOut = y.isDuplicate && !y.selected && !y.main;
                  return (
                    <TableRow
                      key={y._id}
                      style={{
                        backgroundColor: grayOut ? grey[200] : "inherit",
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          inputProps={{
                            "aria-labelledby": `enhanced-table-checkbox-${y._id}`,
                          }}
                          checked={y.selected}
                          onChange={() => handleSelectChange(y._id)}
                        />
                      </TableCell>
                      <TableCell align="right">
                        {y.simpleHanqca.trim().normalize("NFC")}
                      </TableCell>
                      <TableCell align="right">
                        {y.hanqcaizedSentence.trim().normalize("NFC")}
                      </TableCell>
                      <TableCell align="right">
                        {y.koreanSentence.trim().normalize("NFC")}
                      </TableCell>
                      <TableCell align="right">
                        {y.translation.trim().normalize("NFC")}
                        {y.isDuplicate && (
                          <Tooltip
                            title={`This example is duplicated in a different lesson. (This example is from L${y.lesson})`}
                          >
                            <IconButton size="small">
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

const WordPower = () => {
  const dispatch = useDispatch();

  const [wordPowerData, setWordPowerData] = useState([]);
  const [allYemun, setAllYemun] = useState([]);
  const [wordPowerToEdit, setWordPowerToEdit] = useState({});
  const [yemunToEdit, setYemunToEdit] = useState({});
  const [isWordPowerLoading, setIsWordPowerLoading] = useState(true);
  const [isPropsLoading, setIsPropsLoading] = useState(true);
  const [isWordPowerModalOpen, setIsWordPowerModalOpen] = useState(false);
  const [isYemunModalOpen, setIsYemunModalOpen] = useState(false);
  const [newWordPower, setNewWordPower] = useState({});
  const [newYemun, setNewYemun] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const [clickedHanja, setClickedHanja] = useState(null);
  const [clickedHanjaTab, setClickedHanjaTab] = useState(false);
  const [clickedRadical, setClickedRadical] = useState(null);
  const [clickedRadicalTab, setClickedRadicalTab] = useState(false);
  const [selectedWordPower, setSelectedWordPower] = useState(null);
  const [checkedAddNewHanjaCombo, setCheckedAddNewHanjaCombo] = useState(null);
  const [tabType, setTabType] = useState("hanja");
  const [contentTabType, setContentTabType] = useState("words");

  const currentLesson = parseInt(
    useSelector((state) => state.lessons.currentLesson),
  );

  const newHanja = useSelector((state) => state.lessons.newHanja).filter(
    (hanja) => hanja.lesson === currentLesson,
  );
  const radicals = useSelector((state) => state.okpyeon.radicals).filter(
    (radical) => radical.lesson === currentLesson,
  );

  const yemunRedux = useSelector((state) => state.lessons.yemun);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchData = useCallback(() => {
    setIsPropsLoading(true);

    Promise.all([
      dispatch(getNewHanja()),
      dispatch(getRadicals()),
      dispatch(getYemun(currentLesson)),
    ])
      .catch((error) => {
        console.error("Error fetching data:", error);
        alert("An error occurred.");
      })
      .finally(() => {
        setIsPropsLoading(false);
      });
  }, [currentLesson, dispatch]);

  const getWordPowerYemunData = (id) => {
    const params = { lesson: currentLesson };
    params[tabType === "hanja" ? "clickedHanja" : "clickedRadical"] = id
      .trim()
      .normalize("NFC");
    axios
      .get("/api/wordPower/v2/list", { params })
      .then(({ data }) => {
        const wordPowerToEdit = {};
        const yemunToEdit = {};
        const filteredYemun = yemunRedux.filter(
          (y) =>
            y.hanqcaMatch &&
            y.hanqcaMatch.some((hanqca) => compareHanja(hanqca, id)),
        );

        data.wordPowerList.forEach((d) => {
          wordPowerToEdit[d._id] = {};
          yemunToEdit[d._id] = getEmptyObjWithKeys(
            d.examples.map((e) => e._id),
          );
        });

        setWordPowerData(data.wordPowerList);
        setAllYemun(filteredYemun);
        setWordPowerToEdit(wordPowerToEdit);
        setYemunToEdit(yemunToEdit);
      })
      .catch(() => {
        alert("Error receiving wordPower data");
      })
      .finally(() => {
        setIsWordPowerLoading(false);
      });
  };

  const handleOnChangeHanjaTab = (event, value) => {
    setClickedHanja(event.currentTarget);
    setClickedHanjaTab(value);
    setIsWordPowerLoading(true);
    getWordPowerYemunData(event.currentTarget.id);
  };

  const handleOnChangeRadicalTab = (event, value) => {
    setClickedRadical(event.currentTarget);
    setClickedRadicalTab(value);
    setIsWordPowerLoading(true);
    getWordPowerYemunData(event.currentTarget.id);
  };

  const getEmptyObjWithKeys = (keys) => {
    const obj = {};
    keys.forEach((key) => (obj[key] = {}));
    return obj;
  };

  const onFileUpload = (event) => {
    setIsWordPowerLoading(true);
    const file = event.target.files[0];

    Papa.parse(file, {
      header: true,
      skipEmptyLines: "greedy",
      complete: ({ data }) => {
        axios
          .post("/api/wordPower/bulk-create", { data, lesson: currentLesson })
          .then(() => {
            alert("Upload complete. Press OK to refresh page");
            window.location.reload();
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            alert("An error occurred.");
          })
          .finally(() => {
            setIsWordPowerLoading(false);
          });
      },
    });
  };

  const saveWordPower = (id) => {
    setIsSaving(true);
    axios.put(`/api/wordPower/${id}`, wordPowerToEdit[id]).then(() => {
      setWordPowerData((prevData) =>
        prevData.map((w) =>
          w._id === id ? { ...w, ...wordPowerToEdit[id] } : w,
        ),
      );
      setIsSaving(false);
      alert("Successfully saved!");
    });
  };

  const createWordPower = () => {
    if (_.isEmpty(newWordPower)) return;
    const toCreate = { ...newWordPower };
    toCreate.lesson = currentLesson;
    toCreate.checkedAddNewHanjaCombo = checkedAddNewHanjaCombo;
    axios.post("/api/wordPower/createWordPower", toCreate).then(({ data }) => {
      const { wordPower, hanjaCombo } = data;
      setWordPowerData((prevData) => [wordPower, ...prevData]);
      setIsWordPowerModalOpen(false);
      setWordPowerToEdit((prevEdit) => ({ ...prevEdit, [wordPower._id]: {} }));
      if (hanjaCombo) dispatch(insertHanja(hanjaCombo));
      alert("Created successfully!");
    });
  };

  const deleteWordPower = (id) => {
    axios.delete(`/api/wordPower/${id}`).then(() => {
      setWordPowerData((prevData) => prevData.filter((w) => w._id !== id));
      alert("Deleted successfully.");
    });
  };

  const createYemun = (wordpowerId) => {
    if (_.isEmpty(newYemun)) return;
    const toCreate = { ...newYemun };

    toCreate.lesson = currentLesson;
    toCreate.wordPower = wordpowerId;

    axios
      .post("/api/wordPower/createYemun", toCreate)
      .then(({ data }) => {
        setWordPowerData((prevData) =>
          prevData.map((w) =>
            w._id === wordpowerId
              ? { ...w, examples: [data, ...w.examples] }
              : w,
          ),
        );
        setIsYemunModalOpen(false);
        setYemunToEdit((prevEdit) => {
          const newEdit = _.clone(prevEdit) || { [wordpowerId]: {} };
          newEdit[wordpowerId][data._id] = {};
          return newEdit;
        });
        alert("Created Yemun Successfully!");
      })
      .catch((error) => {
        console.error("Error creating Yemun:", error);
        alert("An error occurred.");
      });
  };

  const saveYemun = (wordpowerId, yemunId) => {
    const toSave = { ...yemunToEdit[wordpowerId][yemunId] };
    toSave.lesson = currentLesson;
    setIsSaving(true);
    axios.put(`/api/wordPower/yemun/${yemunId}`, toSave).then(() => {
      setWordPowerData((prevData) =>
        prevData.map((w) =>
          w._id === wordpowerId
            ? {
                ...w,
                examples: w.examples.map((example) =>
                  example._id === yemunId ? { ...example, ...toSave } : example,
                ),
              }
            : w,
        ),
      );
      setIsSaving(false);
      setIsYemunModalOpen(false);
      alert("Saved Yemun successfully");
    });
  };

  const onAddYemunModalOpen = (wordpowerId) => {
    setIsYemunModalOpen(true);
    setSelectedWordPower(wordpowerId);
  };

  const selectYemun = (wordpowerId, yemun) => {
    setIsSaving(true);
    axios
      .put(`/api/wordPower/${wordpowerId}`, { updatedYemun: yemun })
      .then(() => {
        setWordPowerData((prevData) =>
          prevData.map((w) => {
            if (w._id !== wordpowerId) return w;
            const updatedExamples = yemun.selected
              ? [...w.examples, yemun]
              : w.examples.filter((example) => example._id !== yemun._id);
            return { ...w, examples: updatedExamples };
          }),
        );
        setIsSaving(false);
        const action = yemun.selected ? "Added" : "Removed";
        alert(`${action} Yemun to Word Power successfully`);
      })
      .catch((error) => {
        console.error("Error updating Yemun:", error);
        setIsSaving(false);
        alert("An error occurred.");
      });
  };

  const deleteYemun = (wordpowerId, yemunId) => {
    axios.delete(`/api/wordPower/yemun/${yemunId}`).then(() => {
      setWordPowerData((prevData) =>
        prevData.map((w) =>
          w._id === wordpowerId
            ? {
                ...w,
                examples: w.examples.filter(
                  (example) => example._id !== yemunId,
                ),
              }
            : w,
        ),
      );
      alert("Deleted successfully.");
    });
  };

  const handleOnChangeAddNewHanjaComboRadio = (event, value) => {
    setCheckedAddNewHanjaCombo(value);
  };

  const handleHanqcaChange = (event) => {
    const { value } = event.target;
    setNewWordPower((prevData) => ({
      ...prevData,
      hanqca: value.trim().normalize("NFC"),
    }));
  };

  const handleHankulChange = (event) => {
    const { value } = event.target;
    setNewWordPower((prevData) => ({
      ...prevData,
      hankul: value.trim().normalize("NFC"),
    }));
  };

  const handleEnglishGlossChange = (event) => {
    const { value } = event.target;
    setNewWordPower((prevData) => ({
      ...prevData,
      englishGloss: value.trim().normalize("NFC"),
    }));
  };

  const wordPowerButtonDisabled =
    !newWordPower.hanqca ||
    !newWordPower.hankul ||
    !newWordPower.englishGloss ||
    checkedAddNewHanjaCombo == null;

  return (
    <div
      className="ir-WordPower edit-lesson-background"
      style={{ marginBottom: "5%" }}
    >
      <Divider />
      <br />
      <Typography color="textSecondary">
        Select a tab type to show words that contain that 漢字 or 새 부수.
      </Typography>
      <FormControl style={{ width: 250 }}>
        <InputLabel id="demo-simple-select-label">Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={tabType}
          onChange={(event) => setTabType(event.target.value)}
        >
          <MenuItem value="hanja">Hanja</MenuItem>
          <MenuItem value="radical">Radical</MenuItem>
        </Select>
      </FormControl>
      <Box>
        <Tabs
          value={tabType === "hanja" ? clickedHanjaTab : clickedRadicalTab}
          onChange={
            tabType === "hanja"
              ? handleOnChangeHanjaTab
              : handleOnChangeRadicalTab
          }
          indicatorColor="secondary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          style={{ padding: "2%" }}
        >
          {tabType === "hanja"
            ? (newHanja || []).map(
                (hanjaTab, idx) =>
                  hanjaTab &&
                  hanjaTab.hoonEum &&
                  hanjaTab.hanja && (
                    <Tab
                      id={
                        hanjaTab.hanja
                          ? hanjaTab.hanja
                              .replace(/\s/g, "")
                              .trim()
                              .normalize("NFC")
                          : idx
                      }
                      key={idx}
                      label={
                        <React.Fragment>
                          {hanjaTab.hoonEum.split(" ")[0]}{" "}
                          {hanjaTab.hanja.trim().normalize("NFC")}(
                          {hanjaTab.hoonEum.split(" ")[1]}) &nbsp;&nbsp;
                        </React.Fragment>
                      }
                      disabled={isPropsLoading}
                    />
                  ),
              )
            : (radicals || []).map(
                (rad, idx) =>
                  rad &&
                  rad.hoonEum && (
                    <Tab
                      id={
                        rad.radical
                          ? rad.radical
                              .replace(/\s/g, "")
                              .trim()
                              .normalize("NFC")
                          : idx
                      }
                      key={idx}
                      label={
                        <React.Fragment>
                          {rad.radical.trim().normalize("NFC")} &nbsp;&nbsp;
                        </React.Fragment>
                      }
                      disabled={isPropsLoading}
                    />
                  ),
              )}
        </Tabs>
      </Box>
      {!isPropsLoading &&
        ((clickedHanjaTab === false && tabType === "hanja") ||
          (clickedRadicalTab === false && tabType === "radical")) && (
          <Typography color="error">
            Select a tab to show a {tabType === "hanja" ? "漢字" : "새 부수"}.
          </Typography>
        )}
      <Divider />
      {isPropsLoading ? (
        <CircularProgress style={{ display: "flex", margin: "10px auto" }} />
      ) : (
        (tabType === "hanja" ? clickedHanja : clickedRadical) && (
          <div>
            <div className="ir-WordPower-header">
              <Tabs
                value={contentTabType}
                onChange={(_event, value) => setContentTabType(value)}
                indicatorColor="secondary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab label="Word Power" value="words" />
                <Tab label="Yemun" value="yemun" />
              </Tabs>
              {(clickedHanja || clickedRadical) && (
                <div className="ir-WordPower-headerActions">
                  <Button
                    size="small"
                    style={{ textDecoration: "underline", marginRight: 10 }}
                    onClick={() => window.open(sampleCsv)}
                  >
                    View Sample CSV
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    component="label"
                    disabled={isWordPowerLoading}
                  >
                    Upload CSV
                    <input
                      onChange={onFileUpload}
                      type="file"
                      accept=".csv"
                      hidden
                    />
                  </Button>
                  <IconButton
                    size="medium"
                    onClick={() => setIsWordPowerModalOpen(true)}
                    className="primary-button"
                    disabled={isWordPowerLoading}
                  >
                    <PlusIcon />
                  </IconButton>
                </div>
              )}
            </div>
            <Modal
              open={isWordPowerModalOpen}
              onClose={() => setIsWordPowerModalOpen(false)}
            >
              <Box className="ir-WordPower-modal">
                <Typography variant="h6" component="h6">
                  Add New Word Power
                </Typography>
                <br />
                Hanja:{" "}
                <input
                  type="text"
                  placeholder="Type Hanja.."
                  style={{ width: 300 }}
                  onChange={handleHanqcaChange}
                />
                <br />
                <br />
                Hankul:{" "}
                <input
                  type="text"
                  placeholder="Type Hankul.."
                  style={{ width: 300 }}
                  onChange={handleHankulChange}
                />
                <br />
                <br />
                English Gloss:{" "}
                <input
                  type="text"
                  placeholder="Type English Gloss.."
                  style={{ width: 300 }}
                  onChange={handleEnglishGlossChange}
                />
                <br />
                <br />
                Also add to New Hanja Combos?
                <RadioGroup
                  onChange={handleOnChangeAddNewHanjaComboRadio}
                  value={checkedAddNewHanjaCombo}
                >
                  <FormControlLabel
                    value="add-combo"
                    control={<Radio />}
                    label="Add"
                  />
                  <FormControlLabel
                    value="do-not-add-combo"
                    control={<Radio />}
                    label="Do not add"
                  />
                </RadioGroup>
                <br />
                <Button
                  className={"ir-WordPower-create-button"}
                  onClick={createWordPower}
                  disabled={wordPowerButtonDisabled}
                >
                  Create
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setIsWordPowerModalOpen(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Modal>

            <Modal
              open={isYemunModalOpen}
              onClose={() => setIsYemunModalOpen(false)}
            >
              <Box className="ir-WordPower-modal">
                <Typography variant="h6" component="h6">
                  Add Example
                </Typography>
                <br />
                Simple Hanja:
                <br />
                <textarea
                  defaultValue={newYemun.simpleHanqca}
                  style={{ overflowWrap: "break-word", width: 450 }}
                  rows={2}
                  onChange={(event) =>
                    setNewYemun({
                      ...newYemun,
                      simpleHanqca: event.target.value.trim().normalize("NFC"),
                    })
                  }
                ></textarea>
                <br />
                <br />
                Hanjaized Sentence:
                <br />
                <textarea
                  defaultValue={newYemun.hanqcaizedSentence}
                  style={{ overflowWrap: "break-word", width: 450 }}
                  rows={2}
                  onChange={(event) =>
                    setNewYemun({
                      ...newYemun,
                      hanqcaizedSentence: event.target.value
                        .trim()
                        .normalize("NFC"),
                    })
                  }
                ></textarea>
                <br />
                <br />
                Korean Sentence:
                <br />
                <textarea
                  defaultValue={newYemun.koreanSentence}
                  style={{ overflowWrap: "break-word", width: 450 }}
                  rows={2}
                  onChange={(event) =>
                    setNewYemun({
                      ...newYemun,
                      koreanSentence: event.target.value
                        .trim()
                        .normalize("NFC"),
                    })
                  }
                ></textarea>
                <br />
                <br />
                Translation:
                <br />
                <textarea
                  defaultValue={newYemun.translation}
                  style={{ overflowWrap: "break-word", width: 450 }}
                  rows={2}
                  onChange={(event) =>
                    setNewYemun({
                      ...newYemun,
                      translation: event.target.value.trim().normalize("NFC"),
                    })
                  }
                ></textarea>
                <br />
                <br />
                <Button
                  style={{
                    marginRight: "4px",
                    backgroundColor: "#00284d",
                    color: "white",
                    width: "20%",
                  }}
                  onClick={() => createYemun(selectedWordPower)}
                >
                  Create
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setIsYemunModalOpen(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Modal>

            <Divider />
            {isWordPowerLoading && (
              <CircularProgress
                style={{ display: "flex", margin: "10px auto" }}
              />
            )}
            {contentTabType === "words" ? (
              (wordPowerData || []).length ? (
                wordPowerData
                  .sort((a, b) => a.hanqca.localeCompare(b.hanqca, "ko-KR"))
                  .map((wordpower) => (
                    <div key={wordpower._id}>
                      <Accordion TransitionProps={{ mountOnEnter: true }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>
                            {wordpower.hanqca.trim().normalize("NFC") +
                              "(" +
                              wordpower.hankul.trim().normalize("NFC") +
                              ")" +
                              "   " +
                              wordpower.englishGloss.trim().normalize("NFC")}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                gap: "1em",
                              }}
                            >
                              <div>
                                Hanja:{" "}
                                <input
                                  type="text"
                                  defaultValue={wordpower.hanqca
                                    .trim()
                                    .normalize("NFC")}
                                  style={{ width: 300 }}
                                  onChange={(event) =>
                                    setWordPowerToEdit((prevData) => ({
                                      ...prevData,
                                      [wordpower._id]: {
                                        ...prevData[wordpower._id],
                                        hanqca: event.target.value
                                          .trim()
                                          .normalize("NFC"),
                                      },
                                    }))
                                  }
                                />
                                <br />
                                <br />
                                Hankul:{" "}
                                <input
                                  type="text"
                                  defaultValue={wordpower.hankul
                                    .trim()
                                    .normalize("NFC")}
                                  style={{ width: 300 }}
                                  onChange={(event) =>
                                    setWordPowerToEdit((prevData) => ({
                                      ...prevData,
                                      [wordpower._id]: {
                                        ...prevData[wordpower._id],
                                        hankul: event.target.value
                                          .trim()
                                          .normalize("NFC"),
                                      },
                                    }))
                                  }
                                />
                                <br />
                                <br />
                                English Gloss:{" "}
                                <input
                                  type="text"
                                  defaultValue={wordpower.englishGloss
                                    .trim()
                                    .normalize("NFC")}
                                  style={{ width: 300 }}
                                  onChange={(event) =>
                                    setWordPowerToEdit((prevData) => ({
                                      ...prevData,
                                      [wordpower._id]: {
                                        ...prevData[wordpower._id],
                                        englishGloss: event.target.value
                                          .trim()
                                          .normalize("NFC"),
                                      },
                                    }))
                                  }
                                />
                                <br />
                                <br />
                                {isSaving && (
                                  <CircularProgress
                                    className="ir-WordPower-saving"
                                    size="small"
                                  />
                                )}
                                <Button
                                  style={{
                                    marginRight: "4px",
                                    backgroundColor: "#00284d",
                                    color: "white",
                                  }}
                                  onClick={() => saveWordPower(wordpower._id)}
                                >
                                  {isSaving ? "Saving ..." : "Save"}
                                </Button>
                                <Button
                                  style={{
                                    marginRight: "4px",
                                    backgroundColor: "#f6152f",
                                    color: "white",
                                  }}
                                  onClick={() => deleteWordPower(wordpower._id)}
                                >
                                  Delete
                                </Button>
                                <Button
                                  style={{
                                    marginRight: "4px",
                                    backgroundColor: "#fabc05",
                                    color: "white",
                                  }}
                                  onClick={() =>
                                    onAddYemunModalOpen(wordpower._id)
                                  }
                                >
                                  Add Yemun
                                </Button>
                              </div>
                              <WordPowerExistingYemunTable
                                yemun={allYemun}
                                wordPower={wordpower}
                                onSelect={selectYemun}
                              />
                            </div>
                            <div className="ir-WordPower-header">
                              <h4>Examples</h4>
                            </div>
                            {wordpower.examples ? (
                              wordpower.examples
                                .sort((a, b) =>
                                  a.translation.localeCompare(b.translation),
                                )
                                .map((example) => (
                                  <Accordion key={example._id}>
                                    <AccordionSummary
                                      style={{
                                        display: "flex",
                                        justifyContent: "spaceBetween",
                                        backgroundColor: "#f7f2f2",
                                      }}
                                      expandIcon={<ExpandMoreIcon />}
                                    >
                                      <Typography>
                                        {example.translation
                                          .trim()
                                          .normalize("NFC")}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <div>
                                        Simple Hanja:
                                        <br />
                                        <textarea
                                          defaultValue={example.simpleHanqca
                                            .trim()
                                            .normalize("NFC")}
                                          style={{
                                            overflowWrap: "break-word",
                                            width: 450,
                                          }}
                                          rows={2}
                                          onChange={(event) =>
                                            setYemunToEdit((prevData) => ({
                                              ...prevData,
                                              [wordpower._id]: {
                                                ...prevData[wordpower._id],
                                                [example._id]: {
                                                  ...prevData[wordpower._id][
                                                    example._id
                                                  ],
                                                  simpleHanqca:
                                                    event.target.value
                                                      .trim()
                                                      .normalize("NFC"),
                                                },
                                              },
                                            }))
                                          }
                                        />
                                        <br />
                                        <br />
                                        Hanjaized Sentence:
                                        <br />
                                        <textarea
                                          defaultValue={example.hanqcaizedSentence
                                            .trim()
                                            .normalize("NFC")}
                                          style={{
                                            overflowWrap: "break-word",
                                            width: 450,
                                          }}
                                          rows={2}
                                          onChange={(event) =>
                                            setYemunToEdit((prevData) => ({
                                              ...prevData,
                                              [wordpower._id]: {
                                                ...prevData[wordpower._id],
                                                [example._id]: {
                                                  ...prevData[wordpower._id][
                                                    example._id
                                                  ],
                                                  hanqcaizedSentence:
                                                    event.target.value
                                                      .trim()
                                                      .normalize("NFC"),
                                                },
                                              },
                                            }))
                                          }
                                        />
                                        <br />
                                        <br />
                                        Korean Sentence:
                                        <br />
                                        <textarea
                                          defaultValue={example.koreanSentence
                                            .trim()
                                            .normalize("NFC")}
                                          style={{
                                            overflowWrap: "break-word",
                                            width: 450,
                                          }}
                                          rows={2}
                                          onChange={(event) =>
                                            setYemunToEdit((prevData) => ({
                                              ...prevData,
                                              [wordpower._id]: {
                                                ...prevData[wordpower._id],
                                                [example._id]: {
                                                  ...prevData[wordpower._id][
                                                    example._id
                                                  ],
                                                  koreanSentence:
                                                    event.target.value
                                                      .trim()
                                                      .normalize("NFC"),
                                                },
                                              },
                                            }))
                                          }
                                        />
                                        <br />
                                        <br />
                                        Translation:
                                        <br />
                                        <textarea
                                          defaultValue={example.translation
                                            .trim()
                                            .normalize("NFC")}
                                          style={{
                                            overflowWrap: "break-word",
                                            width: 450,
                                          }}
                                          rows={2}
                                          onChange={(event) =>
                                            setYemunToEdit((prevData) => ({
                                              ...prevData,
                                              [wordpower._id]: {
                                                ...prevData[wordpower._id],
                                                [example._id]: {
                                                  ...prevData[wordpower._id][
                                                    example._id
                                                  ],
                                                  translation:
                                                    event.target.value
                                                      .trim()
                                                      .normalize("NFC"),
                                                },
                                              },
                                            }))
                                          }
                                        />
                                        <br />
                                        <br />
                                        {isSaving && (
                                          <CircularProgress
                                            className="ir-WordPower-saving"
                                            size="small"
                                          />
                                        )}
                                        <Button
                                          style={{
                                            marginRight: "4px",
                                            backgroundColor: "#00284d",
                                            color: "white",
                                            width: "20%",
                                          }}
                                          onClick={() =>
                                            saveYemun(
                                              wordpower._id,
                                              example._id,
                                            )
                                          }
                                        >
                                          {isSaving ? "Saving ..." : "Save"}
                                        </Button>
                                        <Button
                                          style={{
                                            marginRight: "4px",
                                            backgroundColor: "#f6152f",
                                            color: "white",
                                            width: "20%",
                                          }}
                                          onClick={() =>
                                            deleteYemun(
                                              wordpower._id,
                                              example._id,
                                            )
                                          }
                                        >
                                          Delete
                                        </Button>
                                      </div>
                                    </AccordionDetails>
                                  </Accordion>
                                ))
                            ) : (
                              <h6>No examples found</h6>
                            )}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <br />
                    </div>
                  ))
              ) : (
                !isWordPowerLoading && <h5>No data</h5>
              )
            ) : contentTabType === "yemun" ? (
              <PotentialWordPowerTable
                yemun={allYemun}
                onCreate={(wordpower) =>
                  setWordPowerData((prevData) => [wordpower, ...prevData])
                }
                lesson={currentLesson}
              />
            ) : (
              <h1>Not available</h1>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default withRouter(WordPower);
