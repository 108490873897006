import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Bookmark from "@material-ui/icons/BookmarkBorderOutlined";
import Bookmarked from "@material-ui/icons/Bookmark";
import StoreAccessor from "../../../../utils/localStore";

const styles = {
  root: {
    maxWidth: "100%",
  },
};

const savedVocabsStore = new StoreAccessor("351savedVocabs");
const Vocab = (props) => {
  const { classes } = props;
  const [newVocab, setNewVocab] = useState("");
  const { lesson } = props.match.params;
  const [savedVocabs, setSavedVocabs] = useState(savedVocabsStore.get() || []);

  const onSaveVocab = (type, subVocId) => {
    const vocId = `${lesson}_${type}_${subVocId}`;
    const newV = savedVocabs.includes(vocId)
      ? savedVocabs.filter((v) => v !== vocId)
      : [...savedVocabs, vocId];
    savedVocabsStore.set(newV);
    setSavedVocabs(newV);
  };

  useEffect(() => {
    axios
      .get("/api/lesson/getNewVocab", { params: { lesson } })
      .then((resp) => {
        if (resp.data.length === 0) {
          setNewVocab("");
        } else {
          const data = resp.data[0];
          setNewVocab(data);
        }
      });
  }, [lesson]);

  return (
    <div>
      <br />
      <b style={{ paddingLeft: "2%", fontSize: "20px" }}>
        New Vocabulary from Main Text
      </b>
      <br />
      <br />
      <Table className={"table"} classes={{ root: classes.root }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "33%", textAlign: "left" }}>
              {" "}
              <b>Korean</b>{" "}
            </TableCell>
            {/* <TableCell style={{width: "25%", textAlign: 'left'}}> <b>Dictionary Form</b> </TableCell> */}
            <TableCell style={{ width: "33%", textAlign: "left" }}>
              {" "}
              <b>English</b>{" "}
            </TableCell>
            {/* <TableCell style={{width: "25%", textAlign: 'left'}}> <b>Hanja</b> </TableCell> */}
            <TableCell style={{ width: "33%", textAlign: "left" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!newVocab ? (
            newVocab.mainText.map((aVocab, index) => {
              return (
                <TableRow key={"mainTextVocab" + index}>
                  <TableCell style={{ width: "33%", textAlign: "left" }}>
                    {aVocab.kor}
                  </TableCell>
                  {/* <TableCell style={{width: "25%", textAlign: 'left'}}>{aVocab.dictionaryForm}</TableCell> */}
                  <TableCell style={{ width: "33%", textAlign: "left" }}>
                    {aVocab.eng}
                  </TableCell>
                  {/* <TableCell style={{width: "25%", textAlign: 'left'}}>{aVocab.hanja}</TableCell> */}
                  <TableCell style={{ width: "33%", textAlign: "right" }}>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => onSaveVocab("mainText", aVocab._id)}
                    >
                      {savedVocabs.includes(
                        `${lesson}_mainText_${aVocab._id}`,
                      ) ? (
                        <Bookmarked style={{ color: "#f2c664" }} />
                      ) : (
                        <Bookmark />
                      )}
                    </span>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow />
          )}
        </TableBody>
      </Table>
      <br />
      <br />
      <b style={{ paddingLeft: "2%", fontSize: "20px" }}>
        New Vocabulary from Example Sentences
      </b>
      <br />
      <br />
      <Table className={"table"} classes={{ root: classes.root }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "33%", textAlign: "left" }}>
              {" "}
              <b>Korean</b>{" "}
            </TableCell>
            {/* <TableCell style={{width: "25%", textAlign: 'left'}}> <b>Dictionary Form</b> </TableCell> */}
            <TableCell style={{ width: "33%", textAlign: "left" }}>
              {" "}
              <b>English</b>{" "}
            </TableCell>
            {/* <TableCell style={{width: "25%", textAlign: 'left'}}> <b>Hanja</b> </TableCell> */}
            <TableCell style={{ width: "33%", textAlign: "left" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!newVocab ? (
            newVocab.exSentences.map((aVocab, index) => {
              return (
                <TableRow key={"exSentencesVocab" + index}>
                  <TableCell style={{ width: "33%", textAlign: "left" }}>
                    {aVocab.kor}
                  </TableCell>
                  {/* <TableCell style={{width: "25%", textAlign: 'left'}}>{aVocab.dictionaryForm}</TableCell> */}
                  <TableCell style={{ width: "33%", textAlign: "left" }}>
                    {aVocab.eng}
                  </TableCell>
                  {/* <TableCell style={{width: "25%", textAlign: 'left'}}>{aVocab.hanja}</TableCell> */}
                  <TableCell style={{ width: "33%", textAlign: "right" }}>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => onSaveVocab("exSentences", aVocab._id)}
                    >
                      {savedVocabs.includes(
                        `${lesson}_exSentences_${aVocab._id}`,
                      ) ? (
                        <Bookmarked style={{ color: "#f2c664" }} />
                      ) : (
                        <Bookmark />
                      )}
                    </span>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow />
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default withRouter(withStyles(styles)(Vocab));
