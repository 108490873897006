import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Link, withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import "./Dashboard.css";
import StoreAccessor from "../utils/localStore";
import { getNumStories } from "../actions/KORN351/Lessons";
import { connect } from "react-redux";

const useStyles = makeStyles({
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Dashboard = (props) => {
  const lessonUrl = new StoreAccessor("351savedLessonUrl");
  const classes = useStyles();

  const { numStories, getNumStories } = props;

  useEffect(() => {
    if (numStories === 0) {
      getNumStories();
    }
  }, [numStories, getNumStories]);

  return (
    <div className="dashboard">
      <div className="dashboard-heading" style={{ marginBottom: "1%" }}>
        <div className="dashboard-heading-bg">
          <h2 style={{ fontWeight: "bold" }}>KORN 351 : Dashboard</h2>
          <p>
            Click on any of the boxes below to navigate to the corresponding
            material.
          </p>
        </div>
      </div>
      <div className="card-container">
        <Card
          className="card"
          onClick={() => {
            document.getElementById("lessonModal").style.display = "block";
          }}
        >
          <h1>
            <CardContent>
              <Typography variant="h5" component="h2">
                Lessons
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Main Texts & Practice Sentences
              </Typography>
              <Typography variant="body2" component="p">
                View each lesson
              </Typography>
            </CardContent>
          </h1>
        </Card>
        <Card className="card">
          <Link
            to="/dashboard/KORN351/quizzes"
            style={{ textDecoration: "none", color: "black" }}
          >
            <h1>
              <CardContent>
                <Typography variant="h5" component="h2">
                  Quizzes
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                  Quiz yourself with various flash card decks
                </Typography>
                <Typography variant="body2" component="p">
                  View flash card decks
                </Typography>
              </CardContent>
            </h1>
          </Link>
        </Card>
        <Card
          className="card"
          onClick={() => {
            document.getElementById("dictionaryModal").style.display = "block";
          }}
        >
          <h1>
            <CardContent>
              <Typography variant="h5" component="h2">
                옥편
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Search for a character easily by Radical, Hangul or Lesson
              </Typography>
              <Typography variant="body2" component="p">
                Search characters
              </Typography>
            </CardContent>
          </h1>
        </Card>
        <Card className="card">
          <h1>
            <CardContent>
              <Typography variant="h5" component="h2">
                From 한자 to 한문
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                After learning 한자 (漢字), try learning 한문 (漢文)
              </Typography>
              <Typography variant="body2" component="p">
                View various 사자성어 (四字成語)
              </Typography>
            </CardContent>
          </h1>
        </Card>
      </div>
      <div id="dictionaryModal" className="modal">
        <div className="modal-content">
          <span
            className="close"
            onClick={() => {
              document.getElementById("dictionaryModal").style.display = "none";
            }}
          >
            &times;
          </span>
          <Typography variant="h5" component="h2">
            Choose how you would like to search . . .
          </Typography>
          <br />
          <div style={{ textAlign: "center" }}>
            <Link
              to="/dashboard/KORN351/dictionary/radical"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div className="modal-options">By Radical</div>
            </Link>
            <Link
              to="/dashboard/KORN351/dictionary/hangul"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div className="modal-options">By Hangul</div>
            </Link>
            <Link
              to={`/dashboard/KORN351/dictionary/lesson/${parseInt(lessonUrl.get()) || 1}`}
              style={{ textDecoration: "none", color: "black" }}
            >
              <div className="modal-options">By Lesson</div>
            </Link>
          </div>
        </div>
      </div>
      <div id="lessonModal" className="modal">
        <div className="modal-content">
          <span
            className="close"
            onClick={() => {
              document.getElementById("lessonModal").style.display = "none";
            }}
          >
            &times;
          </span>
          <Typography variant="h5" component="h2">
            Choose a lesson . . .
          </Typography>
          <br />
          <div style={{ textAlign: "center" }}>
            {[...Array(numStories)].map((x, i) => {
              return (
                <Link
                  key={i}
                  to={`/dashboard/KORN351/lesson/${i + 1}`}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div className="modal-options">Lesson {i + 1}</div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  numStories: state.lessons.numStories,
});

const mapDispatchToProps = {
  getNumStories,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard),
);
