import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./AboutNewPhonetics.css";
import NavigatingButtons from "../../components/Lessons/NavigatingButtons/NavigatingButtons";
import {
  getNewHanjaCombos,
  getNewHanja,
  getAboutNewBusu,
} from "../../../actions/KORN351/Lessons";
import {
  Typography,
  Box,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getHanjaTitle } from "../../../utils/index";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(12),
  },
  flexBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  innerFlexRow: {
    margin: theme.spacing(1),
    padding: theme.spacing(0, 2),
    marginBottom: 0,
  },
  typographyH6: {
    variant: "h6",
    component: "p",
  },
  accordionDetails: {
    padding: 0,
  },
  summaryBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  hiddenBulletList: {
    listStyleType: "none",
  },
}));

const NewHanjaCombos = () => {
  let { lesson } = useParams();
  lesson = parseInt(lesson);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { newHanjaCombos, newHanja, aboutNewBusu } = useSelector((state) => ({
    newHanjaCombos: state.lessons.newHanjaCombos,
    newHanja: state.lessons.newHanja,
    aboutNewBusu: state.lessons.aboutNewBusu,
  }));
  const classes = useStyles();

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      await dispatch(getNewHanjaCombos());
      await dispatch(getNewHanja());
      await dispatch(getAboutNewBusu());
      if (isMounted) {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  const groupsCopy = useMemo(() => {
    const newGroups = new Map();

    newHanja
      .filter((combo) => combo.lesson === lesson)
      .forEach((hanja) => {
        if (!newGroups.has(hanja.hanja)) {
          newGroups.set(hanja.hanja, {
            hanja: hanja.hanja,
            isHanja: true,
            meaning: hanja.meaning,
            eum: hanja.eum,
            hoonEum: hanja.hoonEum,
            radical: hanja.radical,
            radicalHangul: hanja.radicalHangul,
            characterStrokeCount: hanja.characterStrokeCount,
            combos: [],
          });
        }
      });

    aboutNewBusu
      .filter((data) => data.lesson == lesson && data.busu.length > 0) // about new busu lesson == string
      .forEach((data) => {
        const normalized = data.busu[0].trim().normalize("NFC");
        if (!newGroups.has(normalized)) {
          newGroups.set(normalized, {
            isHanja: false,
            meaning: data.def,
            busu: data.busu,
            radical: normalized,
            combos: [],
          });
        }
      });

    newHanjaCombos
      .filter((combo) => combo.lesson == lesson)
      .forEach((combo) => {
        const normalized = combo.hanja.trim().normalize("NFC");
        for (const char of normalized) {
          if (newGroups.has(char)) {
            const combosArray = newGroups.get(char).combos;
            if (
              !combosArray.some(
                (existingCombo) => existingCombo.hanja === combo.hanja,
              )
            ) {
              const newCombo = {
                ...combo,
                children: {},
              };
              combosArray.push(newCombo);
            }
          }
        }
      });

    newGroups.forEach((group) => {
      group.combos.sort((a, b) => {
        const ah = a.hanja.trim().normalize("NFC");
        const bh = b.hanja.trim().normalize("NFC");

        const indexA = ah.indexOf(group.hanja);
        const indexB = bh.indexOf(group.hanja);

        // Sort based on the earliest occurrence of the Hanja character
        if (indexA !== indexB) {
          return indexA - indexB;
        }

        // Check if one string is a substring of the other
        if (ah.includes(bh) || bh.includes(ah)) {
          return ah.length - bh.length;
        }

        // Compare the substrings starting right after the Hanja character index
        const substringA = ah.substring(indexA + 1);
        const substringB = bh.substring(indexB + 1);

        // If both substrings are empty, sort by the entire string
        if (substringA === "" && substringB === "") {
          return ah.localeCompare(bh, "ko-KR", { numeric: true });
        }

        // Otherwise, compare the substrings
        return substringA.localeCompare(substringB, "ko-KR", { numeric: true });
      });
    });

    return newGroups;
  }, [newHanja, aboutNewBusu, newHanjaCombos, lesson]);

  const hanjaComboRow = useCallback(
    (children, isRoot) => (
      <Box
        className={`${classes.flexBox} ${classes.hiddenBulletList}`}
        style={isRoot ? { paddingLeft: "0px" } : {}}
        component="ul"
      >
        {Object.entries(children).map(([key, value]) => (
          <Box key={key} className={classes.flexBox} component="li">
            <Box className={`${classes.flexRow} ${classes.innerFlexRow}`}>
              <Typography variant="h6" component="p" color="textPrimary">
                {key} {value.kor}
              </Typography>
              <Typography variant="h6" color="textPrimary" component="p">
                {value.eng}
              </Typography>
            </Box>
            {renderLeaves(value)}
          </Box>
        ))}
      </Box>
    ),
    [
      classes.flexBox,
      classes.flexRow,
      classes.hiddenBulletList,
      classes.innerFlexRow,
      renderLeaves,
    ],
  );

  const renderLeaves = useCallback(
    (node) => {
      if (!node || Object.keys(node.children).length === 0) {
        return null;
      }
      return hanjaComboRow(node.children, false);
    },
    [hanjaComboRow],
  );

  const hanjaPrefixTree = useMemo(() => {
    const structuredGroups = Array.from(groupsCopy)
      .filter((group) => group[1].combos.length > 0)
      .sort((a, b) => a[0].localeCompare(b[0], "ko-KR"))
      .map((group) => group[1]);
    const tree = {};
    structuredGroups.forEach((group) => {
      let currComboGroup = group.combos[0];
      const stack = [];
      const root = {};
      group.combos.forEach((subCombo) => {
        const parent = currComboGroup.hanja.trim().normalize("NFC");
        const child = subCombo.hanja.trim().normalize("NFC");
        if (parent === child) {
          root[`${parent}`] = currComboGroup;
          return;
        }
        if (child.includes(parent)) {
          currComboGroup.children[`${child}`] = subCombo;
          stack.push(currComboGroup);
          currComboGroup = subCombo;
        } else {
          currComboGroup = stack.pop();
          if (currComboGroup !== undefined) {
            currComboGroup.children[`${child}`] = subCombo;
          } else {
            root[`${child}`] = subCombo;
            currComboGroup = subCombo;
          }
        }
      });
      const key = group.isHanja ? group.hanja : group.radical;
      tree[`${key}`] = {
        children: root,
        metadata: group,
      };
    });

    return (
      <Box className={classes.flexBox}>
        {Object.entries(tree).map(([key, value]) => {
          const { metadata, children } = value;
          return (
            <Accordion key={key}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box className={classes.flexRow} width="100%">
                  <Box className={classes.summaryBox}>
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{ color: "#19609d" }}
                    >
                      {metadata.isHanja
                        ? getHanjaTitle(metadata)
                        : metadata.radical}
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {metadata.isHanja
                        ? `부수: ${metadata.radical} (${metadata.radicalHangul}) + ${metadata.characterStrokeCount}획`
                        : ""}
                    </Typography>
                  </Box>
                  <Typography variant="h6" component="h6">
                    {metadata.meaning}
                  </Typography>
                </Box>
              </AccordionSummary>
              <Divider />
              <AccordionDetails className={classes.accordionDetails}>
                {hanjaComboRow(children, true)}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    );
  }, [
    classes.accordionDetails,
    classes.flexBox,
    classes.flexRow,
    classes.summaryBox,
    groupsCopy,
    hanjaComboRow,
  ]);

  return (
    <Container className={classes.container}>
      {!isLoading ? hanjaPrefixTree : <LinearProgress />}
      <NavigatingButtons />
    </Container>
  );
};

export default NewHanjaCombos;
