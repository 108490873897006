import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "./AboutNewPhonetics.css";
import NavigatingButtons from "../../components/Lessons/NavigatingButtons/NavigatingButtons";
import { getPhonetics } from "../../../actions/KORN351/Okpyeon";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

const AboutNewPhonetics = (_props) => {
  const [phonetics, setPhonetics] = useState([]);
  const dispatch = useDispatch();
  const { lesson } = useParams();
  const reduxPhonetics = useSelector((state) => state.okpyeon.phonetics);

  useEffect(() => {
    let isMounted = true;
    const fetchAndSetPhonetics = async () => {
      if (reduxPhonetics.length === 0) {
        await dispatch(getPhonetics());
      }
      const filteredPhonetics = reduxPhonetics
        .filter((ph) => ph.lesson === parseInt(lesson))
        .sort((a, b) => a.phonetic.localeCompare(b.phonetic));
      if (isMounted) setPhonetics(filteredPhonetics);
    };

    fetchAndSetPhonetics();

    return () => {
      isMounted = false;
    };
  }, [dispatch, lesson, reduxPhonetics]);

  return (
    <Grid container>
      <Grid item md={1} />
      <Grid item xs={12} md={10}>
        <div
          className="col-lg-12 context engVer"
          style={{ paddingBottom: "48px" }}
          id="theHeader"
        >
          <div
            className={"storyHeader"}
            style={{ display: "flex", width: "100%" }}
          >
            <h3 style={{ textAlign: "left", width: "50%" }}>
              About the New Phonetics
            </h3>
          </div>
          <div>
            <h4 style={{ textAlign: "left", width: "50%" }}>제 {lesson} 과</h4>
          </div>
          <Divider style={{ marginBottom: "0.5rem" }} />
          <Grid container style={{ overflowY: "auto", height: "100%" }}>
            {phonetics.map((phonetic) => {
              return phonetic ? (
                <Grid item xs={12} md={6} lg={6} key={phonetic.phonetic}>
                  <Card variant="outlined" className="phonetics-card">
                    <CardContent className="phonetics-card-content">
                      <Typography variant="h5" component="h2">
                        Phonetic {phonetic.phonetic} ({phonetic.pronunciation}
                        {phonetic.sub_pronunciation
                          ? "~" + phonetic.sub_pronunciation
                          : ""}
                        )
                      </Typography>
                      <br />
                      <Typography color="textSecondary" gutterBottom>
                        Observe the phonetic element{" "}
                        <b>
                          {phonetic.phonetic} ({phonetic.pronunciation}
                          {phonetic.sub_pronunciation
                            ? "~" + phonetic.sub_pronunciation
                            : ""}
                          )
                        </b>{" "}
                        in the following commonly used Chinese characters, all
                        pronounced <b>{phonetic.pronunciation}</b>:
                      </Typography>
                      <span>
                        {(phonetic.characters || []).map((charac) => {
                          return (
                            <span key={charac}>
                              &nbsp;&nbsp;&nbsp;&nbsp;{charac}
                            </span>
                          );
                        })}
                      </span>
                      <br />
                      {phonetic.sub_pronunciation_characters &&
                        "Note that this phonetic can also be read as shown below:"}
                      {phonetic.sub_pronunciation_characters
                        ? Object.entries(
                            phonetic.sub_pronunciation_characters,
                          ).map(([key, value]) => (
                            <div key={key}>
                              <Typography variant="body1">
                                {key} :&nbsp;
                                {value.join(", ")}
                              </Typography>
                              <br />
                              (where {key} is the sub pronounciation and{" "}
                              {value.join(", ")} are the sub characters)
                            </div>
                          ))
                        : null}
                    </CardContent>
                  </Card>
                </Grid>
              ) : (
                ""
              );
            })}
          </Grid>
        </div>
      </Grid>
      <NavigatingButtons />
    </Grid>
  );
};

export default AboutNewPhonetics;
