import React, { useEffect } from "react";
import "./LessonFilterTable.css";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { IconButton } from "@material-ui/core";
import { getRadicals, getPhonetics } from "../../../../actions/KORN351/Okpyeon";
import {
  getNewHanja,
  getNumStories,
} from "../../../../actions/KORN351/Lessons";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import StoreAccessor from "../../../../utils/localStore";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function LessonFilterTable(props) {
  const lessonUrl = new StoreAccessor("351savedLessonUrl");
  const {
    newHanja,
    phonetics,
    radicals,
    lesson,
    getRadicals,
    getPhonetics,
    getNewHanja,
    getNumStories,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const lessons = _.range(1, props.numStories + 1);
  useEffect(() => {
    selectItem(lesson);
    // if there is a problem, it's probably because of this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson]);

  const selectItem = (lesson) => {
    props.history.push(
      `/dashboard/KORN351/dictionary/lesson/${parseInt(lesson) || 1}`,
    );
    lessonUrl.set(lesson);
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (radicals.length === 0) getRadicals();
    if (newHanja.length === 0) getNewHanja();
    if (phonetics.length === 0) getPhonetics();
    if (props.numStories === 0) getNumStories();
  }, [
    radicals,
    newHanja,
    phonetics,
    getRadicals,
    getNewHanja,
    getPhonetics,
    getNumStories,
    props.numStories,
  ]);

  return (
    <div className="lesson-filter-container">
      <div className="lesson-filter">
        <div className="lesson-header">
          Selected:
          <div className="lesson-button" onClick={handleClick}>
            Lesson {lesson}
            <IconButton className="lesson-button-chevron" size="medium">
              <i className="material-icons">expand_more</i>
            </IconButton>
          </div>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{ height: "50%" }}
          >
            {lessons.map((_lesson) => (
              <StyledMenuItem
                key={_lesson}
                selected={lesson === _lesson}
                onClick={() => selectItem(_lesson)}
              >
                <ListItemText primary={"Lesson " + _lesson} />
              </StyledMenuItem>
            ))}
          </StyledMenu>
        </div>
        <br />
        <div>
          <h3>Radicals</h3>
          <Divider />
          <br />
          <Grid container style={{ overflowY: "auto" }}>
            {radicals.map((char) => {
              if (char.lesson === lesson)
                return (
                  <Grid
                    key={char.radical}
                    item
                    xs={1}
                    className="character-box2"
                    onClick={() => props.showResult(char.radical, char)}
                  >
                    <h3>{char.radical}</h3>
                  </Grid>
                );
            })}
          </Grid>
          <br />
          <br />

          <h3>Characters</h3>
          <Divider />
          <br />
          <Grid container>
            {newHanja.map((char) => {
              if (char.lesson === lesson)
                return (
                  <Grid
                    key={char.hanja}
                    item
                    xs={1}
                    className="character-box2"
                    onClick={() => props.showResult(char.hanja, char)}
                  >
                    <h3>{char.hanja}</h3>
                  </Grid>
                );
            })}
          </Grid>
          <br />
          <br />

          <h3>Phonetics</h3>
          <Divider />
          <br />
          <Grid container>
            {phonetics.map((phonetic) => {
              if (phonetic.lesson === lesson) {
                return (
                  <Grid
                    key={phonetic.phonetic}
                    item
                    xs={1}
                    className="character-box2"
                    onClick={() => props.selectPhonetic(phonetic)}
                  >
                    <h3>{phonetic.phonetic}</h3>
                  </Grid>
                );
              }
            })}
          </Grid>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    radicals: state.okpyeon.radicals,
    phonetics: state.okpyeon.phonetics,
    newHanja: state.lessons.newHanja,
    numStories: state.lessons.numStories,
  };
};

const mapDispatchToProps = {
  getRadicals,
  getPhonetics,
  getNewHanja,
  getNumStories,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LessonFilterTable),
);
