export const NEW_CHARACTERS = "New Characters";
export const RADICALS = "Radicals";
export const PHONETICS = "Phonetics";
export const NEW_COMBOS_VOCAB_KOR = "New Combos Vocab - Hanja vs. Korean";
export const NEW_COMBOS_VOCAB_ENG = "New Combos Vocab - Hanja vs. English";
export const ALL_COMBOS_VOCAB_KOR = "All Combos Vocab - Hanja vs. Korean";
export const ALL_COMBOS_VOCAB_ENG = "All Combos Vocab - Hanja vs. English";
export const PRACTICE_SENTENCES = "Practice Sentences";

export const QUIZ_TOPIC_MAP = {
  "new-chars": NEW_CHARACTERS,
  rad: RADICALS,
  phon: PHONETICS,
  "new-combo-kor": NEW_COMBOS_VOCAB_KOR,
  "new-combo-eng": NEW_COMBOS_VOCAB_ENG,
  "all-combo-kor": ALL_COMBOS_VOCAB_KOR,
  "all-combo-eng": ALL_COMBOS_VOCAB_ENG,
  "prac-sent": PRACTICE_SENTENCES,
};
