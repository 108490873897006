import logo from "../../../assets/ILReader_Logo-with-tagline2.png";
import ubcLogo from "../../../assets/UbcReaderLogo.png";
import "../style/about.css";
import React from "react";

const NewAbout = () => {
  return (
    <div>
      <div className="logo-container">
        <div className="logos-bg">
          <img className="about-logo" src={logo} alt="Interline Reader" />
          <img className="about-ubc-logo" src={ubcLogo} alt="UBC Reader" />
        </div>
      </div>
      <div className="about-container">
        <p style={{ margin: "5%" }}>
          <h3>Acknowledgements</h3>
          This website has benefitted from the hard work of numerous UBC student
          research assistants hired under the auspices of UBC&#39;s WorkLearn
          program. Special thanks go to Amy George, Sujin Park, Hyesun An, and
          Mifta Sintaha, and Max Cao on the programming side, and to Laura Lee,
          Nicole Lin, Venice Dam, Sarah Inomata, and Jae Won Seok. The latter
          three students in particular have been extremely helpful in preparing
          a completely revised version of the materials during the period May
          2022-August 2023. In addition, numerous students from the 2021-2022
          academic session of Korean 351 made helpful suggestions and
          corrections: Daphne Palmeter, Vanny Troung, Yenah Byun, Connor Ladron
          de Guevara, Kinnah Suh, Yon-jun Kim, and Renee Zhang. Carey Chang in
          San Jose, California, also made many helpful suggestions. I am also
          grateful to Professor Jeon Gwang-jin, Professor emeritus of
          Sungkyunkwan University, for his excellent materials on 한자(어) and
          한자(어)교육, easily found in the app store by searching for
          “속뜻사전,” and described <a href="https://속뜻사전.com/">here</a>.
          <br />
          <br />
          <h3>About this Site</h3>
          This site serves as a self-study resource for the Sino-Korean
          Companion volume that accompanies Ross King et al.&#39;s Advanced
          Korean, available from Tuttle Publishing. To benefit fully from the
          Sino-Korean Companion (whether just the PDFs or this site), you need
          to purchase the Advanced Korean textbook. For a PDF of the
          &apos;Preface&apos; to the original Sino-Korean Companion (2015) that
          was issued first in CD-Format and then later in downloadable PDF
          format, click{" "}
          <a href="https://www.academia.edu/37411815/PREFACE_Advanced_Korean_and_Advanced_Korean_Sino_Korean_Companion">
            here
          </a>
          . The “Preface” explains some of the original thinking behind the
          creation of the Sino-Korean Companion volume along with a short essay
          on “Methodological and Sociolinguistic Premises and Preliminaries.”
          Basically, these materials introduce intermediate to advanced learners
          of Korean to sinographs (한자), how to learn them, and why to learn
          them—not in order to be able to reproduce them (write them) from
          memory, as is typically the focus in courses in Chinese and Japanese
          as a foreign language—but in order to recognize them and the words in
          which they appear. That is, the aim is to help learners systematically
          tackle the sinographic vocabulary (한자어) that comprises such a huge
          proportion of the Korean lexicon—especially &#39;grown-up&#39;
          vocabulary of the sort necessary for reading newspapers, other
          non-fiction expository prose, academic materials, and anything
          published in South Korea from before the 1980s.
        </p>
      </div>
    </div>
  );
};

export default NewAbout;
