import {
  GET_ABOUT_NEW_BUSU,
  GET_ALL_HANJA_COMBOS,
  GET_MAIN_TEXT_AND_EX_SENTENCES,
  GET_NEW_HANJA,
  GET_NEW_HANJA_COMBOS,
  GET_NEW_VOCABULARY,
  GET_NUM_STORIES,
  GET_PRAC_SENTENCES,
  GET_YEMUN,
  INSERT_NEW_HANJA_COMBO,
} from "../../constants/351-action-types";

const initialState = {
  mainText: "",
  subText: "",
  currentLesson: 1,
  exampleSentences: [],
  title: "",
  newVocabulary: "",
  newHanjaCombos: [],
  allHanjaCombos: [],
  aboutNewBusu: [],
  pracSentences: [],
  newHanja: [],
  numStories: 0,
  yemun: [],
};

export default (state = initialState, action) => {
  if (action.payload === undefined) {
    return state;
  }
  switch (action.type) {
    case GET_MAIN_TEXT_AND_EX_SENTENCES:
      return {
        ...state,
        mainText: action.payload.text,
        currentLesson: action.payload.lesson,
        subText: action.payload.others,
        exampleSentences: action.payload.exampleSentences,
        title: action.payload.title,
      };
    case GET_NEW_VOCABULARY:
      return {
        ...state,
        newVocabulary: action.payload,
      };
    case GET_NEW_HANJA_COMBOS:
      return {
        ...state,
        newHanjaCombos: action.payload,
      };
    case INSERT_NEW_HANJA_COMBO:
      return {
        ...state,
        newHanjaCombos: [...state.newHanjaCombos, action.payload],
      };
    case GET_ALL_HANJA_COMBOS:
      return {
        ...state,
        allHanjaCombos: action.payload,
      };
    case GET_ABOUT_NEW_BUSU:
      return {
        ...state,
        aboutNewBusu: action.payload,
      };
    case GET_PRAC_SENTENCES:
      return {
        ...state,
        pracSentences: action.payload,
      };
    case GET_NEW_HANJA:
      return {
        ...state,
        newHanja: action.payload,
      };
    case GET_NUM_STORIES:
      return {
        ...state,
        numStories: action.payload,
      };
    case GET_YEMUN:
      return {
        ...state,
        yemun: action.payload,
      };
    default:
      return state;
  }
};
