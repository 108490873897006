import React, { useState, useCallback } from "react";
import {
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Input,
} from "@material-ui/core";
import axios from "axios";
import _ from "lodash";

function KGF() {
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchKGF = (value) => {
    setLoading(true);
    axios
      .get("http://www.koreangrammaticalforms.com/api/grammar-forms", {
        params: { name: value },
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceLoadData = useCallback(_.debounce(searchKGF, 1000), []);

  function onSearch(event) {
    let { value } = event.target;
    value = value.trim();
    if (!value) {
      return;
    }

    setSearchTerm(value);
    debounceLoadData(value);
  }

  return (
    <div className="ir-KGF">
      <h6 style={{ backgroundColor: "#ffd8d5", padding: "15px" }}>
        Instructions: start typing a search term such as &quot;가&quot; in the
        box below to search for grammar patterns starting with that character.
      </h6>
      <br />
      <div className="ir-KGF-search">
        <Input value={searchTerm} placeholder="Search..." onChange={onSearch} />
      </div>
      <br />
      {loading && (
        <CircularProgress style={{ display: "flex", margin: "10px auto" }} />
      )}
      {!!data.length &&
        data.map((d) => (
          <Accordion key={d} style={{ margin: "12px" }}>
            <AccordionSummary style={{ background: "#eeeeee45" }}>
              <h5>{d.entryName}</h5>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div>
                  {d.categoryKorean} {d.categoryEnglish}
                </div>
                <h4>Translation:</h4>
                <span dangerouslySetInnerHTML={{ __html: d.translation }} />
                <h4>Examples:</h4>
                <ol>
                  {d.sentences.map((sentence) => (
                    <div key={sentence}>
                      <li>
                        <span
                          dangerouslySetInnerHTML={{ __html: sentence.kor }}
                        />
                        <div
                          dangerouslySetInnerHTML={{ __html: sentence.eng }}
                        />
                      </li>
                    </div>
                  ))}
                </ol>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
}

export default KGF;
