import React, { Component } from "react";
import FusePicker from "react-fuse-picker-fixed";
import { connect } from "react-redux";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableRow from "@material-ui/core/TableRow";
import { getVocabforStory } from "../../../../../actions/KORN410/vocab";
import {
  updateGrammarSearch,
  startGrammarSearch,
  endGrammarSearch,
} from "../../../../../actions/KORN410/analytics";
import Grid from "@material-ui/core/Grid";

class GrammarSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      items: [
        {
          entryName: "가까이 ",
          typicalExample: "【보름 가까이 앓다가 돌아가셨다.】",
          categoryKorean: "Post-numeral",
          categoryEnglish: "(Post-numeral)",
          translation: "As post-numeral [for] nigh onto, [for] nearly",
          sentences: [
            {
              Kor: "보름 가까이 앓다가 돌아가셨다.",
              Eng: "[His father] lay sick in bed for nearly a fortnight before he died.",
            },
          ],
        },
        {
          entryName: "~가레",
          typicalExample: "【많이 벌었쉐다가레.  】",
          categoryKorean: "종결 어미 Final ending/Dialect morphology ",
          categoryEnglish: "(종결 어미 Final ending/Dialect morphology )",
          translation:
            "The endings in -가레 and -고래 are equivalent to standard -그려/-구려, used to make a (standard) Familiar Style apperceptive (equivalent to -구나, -구먼요, etc. in other styles)",
          sentences: [
            { Kor: "많이 벌었쉐다가레.  ", Eng: "My, you've earned a lot! " },
          ],
        },
        {
          entryName: "가로되",
          typicalExample: "【세 가지 특색이 있으니, 가로되】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "To wit, namely, viz., quote-unquote. Used to preface a quotation, this is from a now obsolete verb mean say; tell. ",
          sentences: [
            {
              Kor: "세 가지 특색이 있으니, 가로되",
              Eng: "had three characteristics, namely ...",
            },
          ],
        },
        {
          entryName: "~가 뭐예요?",
          typicalExample: "【그대루가 뭐요? 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Whadda ya mean...? ",
          sentences: [
            { Kor: "그대루가 뭐요? ", Eng: 'Whadda ya mean "as before"?' },
          ],
        },
        {
          entryName: "~가 아니다",
          typicalExample: "【그러나 '재취라도'가 아니었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "QUOTE + 가 아니다: 'QUOTE' ain't the half of it. Used as a rejoinder to gainsay something said previously",
          sentences: [
            {
              Kor: "그러나 '재취라도'가 아니었다 ",
              Eng: 'but being some "second wife" wasn\'t the half of it',
            },
          ],
        },
        {
          entryName: "간(間)에",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "This 간에 can show up in paired phrases of contrastive meaning, in which case it comes after the second of the pair. ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "감 [-깜]",
          typicalExample: "【남의 첩 감이야 】",
          categoryKorean: "Word-formation element",
          categoryEnglish: "(Word-formation element)",
          translation:
            'NOUN + 감: stuff, material; (fig.) a suitable person for, good "material" for. Other examples: 신랑감 husband material, husband-to-be 사윗감 person who would make a good son-in-law. Note that this is always pronounced [-깜].',
          sentences: [
            {
              Kor: "남의 첩 감이야 ",
              Eng: "[if he were a woman] he would be no better than somebody's concubine; he would be fit only to be somebody's concubine",
            },
          ],
        },
        {
          entryName: "-갔-",
          typicalExample: "【복네 강짜하갔구만.】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            "P'yŏng‘an dialects have -갔- for standard future-presumptive -겠-",
          sentences: [
            {
              Kor: "복네 강짜하갔구만.",
              Eng: "I'll bet Pongnyŏ must be jealous.",
            },
            {
              Kor: "멀믄 얼마나 멀갔게?",
              Eng: "lit., even if it's far, how far can it be? [answer: not far]; come on, it can't be all that far!",
            },
          ],
        },
        {
          entryName: "~ 같아서(는)",
          typicalExample: "【그렇지만 지금 같아선】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN 같아서(는): as for ... ; if it were ... ",
          sentences: [
            { Kor: "그렇지만 지금 같아선", Eng: "But as for now ..." },
          ],
        },
        {
          entryName: "~ 같았으면",
          typicalExample:
            "【예전 같았으면  걸음으로  벌써  황개골까지는 들어가 있었을텐데..... 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN 같았으면: if it were/be/had been NOUN",
          sentences: [
            {
              Kor: "예전 같았으면  걸음으로  벌써  황개골까지는 들어가 있었을텐데..... ",
              Eng: "lit., if things were like they used to be I would have been able to make it to Hwang-gae Valley on foot by now...",
            },
          ],
        },
        {
          entryName: "같애 ",
          typicalExample: "【눈이 올 것 같애 】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation:
            "Colloquial/substandard version of standard Intimate Style/infinitive 같아 seems like.",
          sentences: [
            { Kor: "눈이 올 것 같애 ", Eng: "looks like it's going to snow" },
          ],
        },
        {
          entryName: "~ 같으니 ",
          typicalExample: "【개쌍년 같으니!】",
          categoryKorean: "욕 Epithet",
          categoryEnglish: "(욕 Epithet)",
          translation:
            "욕 epithet in BLAH-BLAH 같으니 from 같은 이 thing/person resembling a BLAH-BLAH (이 = 것)",
          sentences: [
            {
              Kor: "개쌍년 같으니!",
              Eng: "that bitch! (lit., person like a vulgar woman)",
            },
            {
              Kor: "저런 무딘 사람 같으니 ",
              Eng: "what a thick-headed fella you are ",
            },
            {
              Kor: "망할 자식 같으니 ",
              Eng: "lit., person like a bastard to be ruined, i.e., bastard, asshole",
            },
          ],
        },
        {
          entryName: "~ 같으면",
          typicalExample: "【평소 품행이 허랑한 학생 같으면 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN 같으면: if it were/be NOUN.",
          sentences: [
            {
              Kor: "평소 품행이 허랑한 학생 같으면 ",
              Eng: "ordinarily, if it were the case of some morally lax student ...",
            },
          ],
        },
        {
          entryName: "거 ",
          typicalExample: "【거 병신 안됐는지 몰라 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contraction from 그거. Sometimes functions like a mild exclamation, as in 거참",
          sentences: [
            {
              Kor: "거 병신 안됐는지 몰라 ",
              Eng: "geez, she might have been crippled for all I know",
            },
          ],
        },
        {
          entryName: "거기",
          typicalExample: "【거기두 줬겠구만 】",
          categoryKorean: "Pronoun substitute",
          categoryEnglish: "(Pronoun substitute)",
          translation:
            "Sometimes 거기 there can function as a second-person pronoun: you",
          sentences: [
            {
              Kor: "거기두 줬겠구만 ",
              Eng: "she must have put out for you too",
            },
          ],
        },
        {
          entryName: "-거나 ",
          typicalExample:
            '【무턱대고 "어머, 좋겠다" 하거나 아니면  "외국 가서 살면 외롭지 않을까, 몇 년 갔다오는 것은 몰라도" 식의…】',
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Presents a choice between two or more possibilities: or",
          sentences: [
            {
              Kor: '무턱대고 "어머, 좋겠다" 하거나 아니면  "외국 가서 살면 외롭지 않을까, 몇 년 갔다오는 것은 몰라도" 식의…',
              Eng: 'I thought they would give responses such as "Wow—nice!" or, "Wouldn\'t it be lonely living on a foreign soil, unless it\'s just a few years out there"… ',
            },
            {
              Kor: "물오리들이 종종걸음을 치거나 주위를 선회하고 있었다 .",
              Eng: "The ducks were walking in short quick steps or rotating the area.",
            },
            {
              Kor: "배를 타러 가거나 배에서 내린 사람들이었었다. ",
              Eng: "They were people either going to board or having just gotten off ships. ",
            },
            {
              Kor: "과연 무엇을 느끼거나 상상할 수 있었을까?",
              Eng: "Those receiving the letters, what will they be able to feel or imagine?",
            },
            {
              Kor: "만나거나 지나치는 행인들의 동정 ",
              Eng: "the movements of people he would meet or those passing by",
            },
            {
              Kor: "마음이 불안하거나 집안이 뒤숭숭할 때는 ",
              Eng: "when she was ill at ease or when the family was troubled",
            },
            {
              Kor: "독직이 되거나 죄가 되는 것이 아니었다.  ",
              Eng: "It wasn't a case where it would be considered corruption or a crime.",
            },
            {
              Kor: "도망해야 할 때거나 하여튼 무언가 새 출발이 필요할 때",
              Eng: "whether it was times when I had to escape or just needed a new fresh start... ",
            },
            {
              Kor: "웃거나 대뜸 두 손바닥을 싹싹 비벼대며 한번만 봐달라고 할 것이다. ",
              Eng: "He would smile or rub his palms together, begging to be given one more chance. ",
            },
            {
              Kor: "재수없게 엉덩이를 채거나 머리를 쥐어 박히기가 십상이었다.  ",
              Eng: "It was a common happening that he'd get an unlucky kick in the butt, or get a little smack on the head.",
            },
            {
              Kor: "남자 둘이서 오만상을 찌푸려가며 이야기하거나 열을 올리고 있는 경우에는 보나마나다. ",
              Eng: "When two men are talking with fringed faces or seem to be getting angry, it's not worth the try - it won't work out. ",
            },
            {
              Kor: "청소가 제대로 되어 있지 않거나 설교 원고가 없어졌다고 했다. ",
              Eng: "he would say that the cleaning hadn't been properly or that his sermon draft had disappeared",
            },
            {
              Kor: "놀이터에서 그네를 타거나 상가 여기저기를 쏘다니며 지냈다.  ",
              Eng: "I spent my time swinging on the swing at the playground or cruising around the shops on the main road.",
            },
            {
              Kor: "보채거나 칭얼거리지 않았다.  ",
              Eng: "It did not fuss or whine.",
            },
            {
              Kor: "우리는 막막하게 앉아 이야기를 하거나 술잔을 채워갔다. ",
              Eng: "We sat desolately and either talked or else filled each other's glasses.",
            },
            {
              Kor: "하지만 그렇다고 내 일상이 불편해지거나 지장을 받는 것은 아니다.  ",
              Eng: "But, having said that, it is not as if my day-to-day existence is gettingmore inconvenient or obstacle-ridden.",
            },
            {
              Kor: "소음에 비틀거릴 때거나 밤늦게 신당동(新堂洞) 집 앞의 포장된 골목을 자동차로 올라갈 때 ",
              Eng: "times when I would stagger because of the noise, or drive up the paved road leading up to my place in Shindangdong late at night...",
            },
            {
              Kor: "그냥 시어머니와 마주쳤거나 마주친 김에 ",
              Eng: "it was either that they bumped into her mother-in-law or having chanced to bump into her, they figured they might as well …",
            },
          ],
        },
        {
          entryName: "-거나 ... -거나 ",
          typicalExample: "【무능한 소치거나 과실이거나 한 것은 아니었다. 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Shows indifference toward a choice between two conflicting actions or states: whether ... or; whether ... or not",
          sentences: [
            {
              Kor: "무능한 소치거나 과실이거나 한 것은 아니었다. ",
              Eng: "It wasn't because of (her) lack of talent or her faults or whatever.",
            },
          ],
        },
        {
          entryName: "-거나 간에 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Followed by 간에 and/or preceded by a question word): whether ... or ..., it makes no difference. ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-거나 한다",
          typicalExample: "【무능한 소치거나 과실이거나 한 것은 아니었다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'Is a question of whether A or B or the like/whatever. This pattern takes the "indifference toward a choice between two conflicting actions or states" pattern and rounds it off with 하- so that this, in turn, can be adnominalized to (used as a modifier for) a following noun, e.g., 것.',
          sentences: [
            {
              Kor: "무능한 소치거나 과실이거나 한 것은 아니었다. ",
              Eng: "It wasn't because of (her) lack of talent or her faults or whatever.",
            },
            {
              Kor: "엄마가 가끔 큰 소리를 내거나 하면 아빠는 엄마를 나무랐다.  ",
              Eng: "Whenever mom would cry out or the like, dad would scold her.",
            },
          ],
        },
        {
          entryName: "-거늘",
          typicalExample: "【온천물의 효험 따위를 믿어온 바도 없거늘 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            'Samuel Martin calls this the "literary concessive." -거늘 is a somewhat old-fashioned or even archaic-sounding sentence connector. The connection between the two clauses is rather loose, and like -(으)ㄴ/는데, this ending can be translated any number of ways in English: as, since, when, upon. 이희자 & 이종희 list only a usage whereby -거늘 sets up a rhetorical question. In this usage, -거늘 tends to collocate with the adverbs 하물며 and 어찌.',
          sentences: [
            {
              Kor: "온천물의 효험 따위를 믿어온 바도 없거늘 ",
              Eng: "as I've never really believed in the effectiveness of spring waters…",
            },
          ],
        },
        {
          entryName: "-거니1 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "하게체의 종결 어미 Final ending in Familiar Style  ",
          categoryEnglish:
            "(하게체의 종결 어미 Final ending in Familiar Style  )",
          translation:
            "Used to aver or confirm something on the basis of one's own past experience: indeed ... ; verily. Sounds rather poetic, bookish and old-fashioned.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-거니2 ",
          typicalExample:
            "【남편이 병으로 죽어 가거니 어쩌느니 핑계는 대었지만 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Used in rhetorical questions: given the content of the first clause, then how (why, etc.)? Old-fashioned usage. ",
          sentences: [
            {
              Kor: "남편이 병으로 죽어 가거니 어쩌느니 핑계는 대었지만 ",
              Eng: "She would make up excuses, saying things like 'My husband is dying of some disease—what shall I do?', but... ",
            },
          ],
        },
        {
          entryName: "-거니 ... -거니 한다",
          typicalExample:
            "【우리는 주거니 받거니 한 되 병을 다 말리고 말았다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Verb1-거니... Verb2-거니 used to designed alternating actions: what with doing one thing and then another (in alternation); now ... now ... ; sometimes ... (and) sometimes... ; by turns.",
          sentences: [
            {
              Kor: "우리는 주거니 받거니 한 되 병을 다 말리고 말았다. ",
              Eng: "We exchanged [our wine cup] back and forth several times, emptying one entire bottle. ",
            },
          ],
        },
        {
          entryName: "- 거니 싶다",
          typicalExample: "【몸을 던져 빠져 죽을 수도 없겠거니 싶자 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Quoting thoughts. Plain Style quoted thought + 싶- it feels like; as if maybe; think to oneself [it is likely/possible] that. Note that while the clause in front of 싶- looks like and feels like a quote, you can't put a quotative -고 after it. Usually occurs with -겠- as -겠거니 싶다.",
          sentences: [
            {
              Kor: "몸을 던져 빠져 죽을 수도 없겠거니 싶자 ",
              Eng: "once I realized I wouldn't be able to drown myself, either, ...",
            },
          ],
        },
        {
          entryName: "-거니와",
          typicalExample: "【일본말도 곧잘 철철대이거니와 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            'Samuel Martin calls this the "tentative sequential " and glosses it as together with the likely fact that; as well as; besides; admitting that; but (even so) not only ... , but also ... ',
          sentences: [
            {
              Kor: "일본말도 곧잘 철철대이거니와 ",
              Eng: "not only is his Japanese exact, but ...",
            },
            {
              Kor: "찾아올 일도 없거니와 ",
              Eng: "not only is there no reason [for someone] to come looking for me ...",
            },
            {
              Kor: "그래지지도 않거니와 ",
              Eng: "not only did it not turn out that way, but what's more ... ",
            },
            {
              Kor: "나진의 선례도 있거니와 ",
              Eng: "not only is there a precedent in Najin, but ...",
            },
            {
              Kor: "말할 것도 없거니와  ",
              Eng: "not only does it go without saying, but...",
            },
            {
              Kor: "몇 번씩 되풀이하는 이야기거니와 ",
              Eng: "not only was it said over and over several times, but...",
            },
            {
              Kor: "밥 먹을 정신이 나지도 않았거니와 ",
              Eng: "not only did he not even feel in the mood to eat, but ...",
            },
            {
              Kor: "듣지 못하였거니와 ",
              Eng: "not only could it not be heard...",
            },
          ],
        },
        {
          entryName: "-거니 한다",
          typicalExample: "【너무나 얇고 잔망스러운 손이거니 하였다.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'Samuel Martin calls the ending in -거니 the "tentative sequential" and glosses it as with the likely fact or reason that. But usually -거니 is followed by 하- functioning as a kind of verb of quotation: (says/thinks) that [QUOTE] is most likely the case; (says/thinks) that [QUOTE] is probably the case. Martin sees sentence-final (including cases where it is part of a quotation) -거니 as an old-fashioned equivalent of -지.',
          sentences: [
            {
              Kor: "너무나 얇고 잔망스러운 손이거니 하였다.",
              Eng: "It occurred to him that it was [most certainly] an all-too-slender, feeble old hand.",
            },
            {
              Kor: "그만 화재를 보았거니 하는 것이다.",
              Eng: 'It was a case of his thinking, "Wouldn\'t you know it, the place ending up going up in smoke."',
            },
            {
              Kor: "야미꾼이거니 했었는데 ...",
              Eng: "I thought he was probably a blackmarketeer …",
            },
          ],
        },
        {
          entryName: "-거던(요) ",
          typicalExample: "【여기 장사도 꽤 괜찮거던요.】",
          categoryKorean: "Dialect morphology/Substandard ",
          categoryEnglish: "(Dialect morphology/Substandard )",
          translation:
            'Substandard or dialect for sentence final explanatory -거든(요). This ending in is a one-shape ending, and can occur with any base. ㄹ-extending bases appear in their extended form before this ending: 사-ㄹ- live → 살거든요. Used to offer a kind of follow-up explanation to what has just been said (or implied)—sometimes insistently and/or as a corrective to a perceived lack of information or understanding on the part of one\'s interlocutor. Also functions as a sort of politeness strategy to keep conversations moving by inviting a response (it usually has a rising, question-like intonation). A useful translation strategy is " ..., you see?"',
          sentences: [
            {
              Kor: "여기 장사도 꽤 괜찮거던요.",
              Eng: "From spring until fall, this place (business) does well, you see.",
            },
            {
              Kor: "벌써 그렇게 자랐거던요. ",
              Eng: "He's already all grown up.",
            },
          ],
        },
        {
          entryName: "-거든",
          typicalExample: "【쫓겨 가는 운명이거든 어디를 간들 신신하랴. 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Provisional -거든: (1) provided that ... ; if/when ... (usually followed by command, suggestion, or promise); (2) if; given that (followed by a rhetorical question). This use of -거든 is not to be confused with the sentence-final 'explanatory' -거든(요) used to provide reasons.",
          sentences: [
            {
              Kor: "쫓겨 가는 운명이거든 어디를 간들 신신하랴. ",
              Eng: "If one suffers the fate of being chased out/away…",
            },
            {
              Kor: "그럼 우리 집에서 먼저 긷거든 기르슈. ",
              Eng: "Well then, go ahead if you’re going to draw water from our place.",
            },
            {
              Kor: "혹 서울엘 오거든나도 서울 가서 있을 테니까",
              Eng: "If you ever come to Seoul, I will be there in the city, too, so...",
            },
            {
              Kor: "자기가 죽거든 자기 입든 옷을 꼭 그래도 입혀서 묻어 달라구...",
              Eng: "She asks to be buried, when she dies, in the clothes that she used to wear… ",
            },
            {
              Kor: "마땅한 자리 나서거든 재취 자리라도 가거라. ",
              Eng: "If a suitable man comes around, you should marry him even if you were to be his second wife. ",
            },
          ],
        },
        {
          entryName: "-거든(요)",
          typicalExample: "【사람이 많아지면 하늘을 잊는 법이거든. 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            'Sentence-final explanatory -거든(요). This ending in is a one-shape ending, and can occur with any base. L-extending bases appear in their extended form before this ending: 사-ㄹ- live → 살거든요. Used to offer a kind of follow-up explanation to what has just been said (or implied)—sometimes insistently and/or as a corrective to a perceived lack of information or understanding on the part of one\'s interlocutor. Also functions as a sort of politeness strategy to keep conversations moving by inviting a response (it usually has a rising, question-like intonation). A useful translation strategy is " ..., you see?"',
          sentences: [
            {
              Kor: "사람이 많아지면 하늘을 잊는 법이거든. ",
              Eng: "When the number of people increases, people forget Heaven.",
            },
            {
              Kor: "집에서 이런 장살 하거든요. ",
              Eng: "Our family is in this business, you see?",
            },
            {
              Kor: "항상 유행가만 부르라고 하거든요.",
              Eng: "Those guys always tell me to sing popular songs, you see?",
            },
            {
              Kor: "목이 졸려 죽으면 목 주위에 멍이 들거든. ",
              Eng: "When you are strangled, you get bruises around your neck, see?",
            },
            {
              Kor: "그런데 이 남자의 목은 아주 깨끗했거든.  ",
              Eng: "But this guy's neck was really clean, see?",
            },
            {
              Kor: "발자국을 내 눈으로 똑똑히 봤거든. ",
              Eng: "I did see [the tiger's] footprints clearly with my very own eyes.",
            },
            {
              Kor: "제가 오늘 애인과 이별을 했거든요. ",
              Eng: "You see, today I broke up with my girlfriend.",
            },
          ],
        },
        {
          entryName: "-거들랑",
          typicalExample:
            "【소녀더러 병이 좀 낫거들랑 이사 가기 전에 한번 개울가로 나와 달라는 말을 못 해둔 것이었다】",
          categoryKorean: "Dialect morphology/연결어미 Connective ending ",
          categoryEnglish: "(Dialect morphology/연결어미 Connective ending )",
          translation:
            "Dialect equivalent to provisional –거든: (1) provided that; if/when (usually followed by command, suggestion, or promise); (2) if; given that (followed by a rhetorical question)",
          sentences: [
            {
              Kor: "소녀더러 병이 좀 낫거들랑 이사 가기 전에 한번 개울가로 나와 달라는 말을 못 해둔 것이었다",
              Eng: "He hadn’t had a chance to ask her [‘ahead of time/in good time’] to come out to the streams again before she moved away, if/once her sickness improves a bit (if/once she gets a bit better)",
            },
          ],
        },
        {
          entryName: "-거라",
          typicalExample: "【사가거라.】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "Plain Style Imperative -거라. Affectionate command by a superior. Also used in songs or poetry without the imperative meaning. Usually only possible with following monosyllabic bases: 가-, 나-, 자-, 서-, 되-, 있-, 듣-, 죽-, 앉- and 믿-.",
          sentences: [
            { Kor: "사가거라.", Eng: '"Buy it!"' },
            { Kor: "너 나가거라 하는 말 ", Eng: "told you to leave ..." },
            {
              Kor: "직무에 충실하거라 ",
              Eng: "Make something out of your job!",
            },
          ],
        },
        {
          entryName: "거라구요 ",
          typicalExample: "【선생님들 약속 믿는 거라구요.】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contracted from 것이라고요 ← 것 fact + -(이)라 (quoted copula) + -고 (quotative particle) + polite 요",
          sentences: [
            {
              Kor: "선생님들 약속 믿는 거라구요.",
              Eng: "I’m telling you, he believes that you will keep your promise.",
            },
          ],
        },
        {
          entryName: "거라는 ",
          typicalExample: "【우리집 우물물을 퍼가려고 했던 거라는 소문이.】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contracted from 것이라는 ← 것이라[고 하]는: 것 fact + -(이)라 (quoted copula) + -고 (quotative particle) + 하- say",
          sentences: [
            {
              Kor: "우리집 우물물을 퍼가려고 했던 거라는 소문이.",
              Eng: "The rumor was that [Mr. Yi's mother] had come to our well to fetch water.",
            },
          ],
        },
        {
          entryName: "-거린다",
          typicalExample: "【헐떡거리며 집으로 돌아온 맹순사는…】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'Mimetic + -거리다: does repeatedly/continuously; does and does; keeps doing, does on and on, does (keeps doing) it away; does again and again; does over and over again. In essence, the suffix in -거리- derives verbs from mimetic adverbs, where "mimetic" is Samuel Martin\'s cover term for what, in traditional Korean grammar, are called 의성어 (phonomimes, i.e., words that mimic sounds) and 의태어 (phenomimes, i.e., words that mimic actions or states). ',
          sentences: [
            {
              Kor: "헐떡거리며 집으로 돌아온 맹순사는…",
              Eng: "Police officer Maeng, who came back home huffing and puffing...",
            },
            {
              Kor: "내 턱은 뻐스가 껑충거리는 데 따라서 함께 덜그럭거리고 있었다. ",
              Eng: "My chin was bobbing to the bus’ every bounce.",
            },
            {
              Kor: "불꽃처럼 넘실거렸다. ",
              Eng: "…was brimming like fireworks.",
            },
            {
              Kor: "만지작거리며",
              Eng: "…fondled [the collar of my pajamas] while ...",
            },
            { Kor: "뻐스의 덜커덩거림이 ", Eng: "the rattling of the bus" },
            {
              Kor: "헐레벌떡거리며 이장네 집으로 뛰어 들어왔다.",
              Eng: "Huffing and puffing, he came running into the village head's home.",
            },
            { Kor: "반들거리는 눈동자. ", Eng: "glistening pupils" },
            {
              Kor: "이혼을 합네 마네 투닥거리기도 했다. ",
              Eng: "She even groused about divorcing me.",
            },
            {
              Kor: "사촌이 논을 사서가 아니라 거들먹거리는 품이 아니꼬워서다. ",
              Eng: "It wasn't because the proverbial cousin had bought a paddy, but because the way they swaggered made me want to puke.",
            },
            {
              Kor: "내 쪽을 계속 흘깃거리면서 설거지를 한다.  ",
              Eng: "She is doing the dishes, all the while sneaking glances in my direction.",
            },
            {
              Kor: "발을 대롱거리며 좋아한다.",
              Eng: "He likes it, dangling his feet",
            },
            { Kor: "아들 녀석이 칭얼거린다.  ", Eng: "Our son is whining." },
            {
              Kor: "연기가 그의 입에서 나와 뭉클거리며 ... ",
              Eng: "[While] the smoke exits his mouth and congeals into lumps...",
            },
            {
              Kor: "그러자 그는 흐물거리며 웃으며 고개를 젓는다. ",
              Eng: "…whereupon he shakes his head, laughing and kidding around.",
            },
            {
              Kor: "그 남자가 그렇게 중얼거렸다.",
              Eng: "He mumbled like that.",
            },
            {
              Kor: "사람들이 웅성거리며 아래를 내려다보고 있었다.",
              Eng: "People were buzzing and looking down below.",
            },
            {
              Kor: "남자의 몸이 휘청거렸다.  ",
              Eng: "The man's body wobbled/teetered.",
            },
            {
              Kor: "남자를 흘끔거리며 투덜대는 일행에게 남편이 말했었다.  ",
              Eng: "Her husband said to his pals who were grumbling and glaring at him…",
            },
            {
              Kor: "떠듬거리는 영어로 ",
              Eng: "in his stammering/faltering English",
            },
            {
              Kor: "부스럭거리며 짐을 챙겨드는 소리가 들렸다. ",
              Eng: "…heard the sound of rustling as somebody packed his things",
            },
            {
              Kor: "보채거나 칭얼거리지 않았다.  ",
              Eng: "no fussing and no whining",
            },
            {
              Kor: "지수는 문득문득 중얼거리곤 했다.  ",
              Eng: "Chisu would sometimes suddenly start mumbling to herself.",
            },
            {
              Kor: "바람에 밀린 잔물결이 난장이네 좁은 마당 끝에 와 찰싹거렸다.  ",
              Eng: "The ripples pushed by the breeze lapped against the edge of the dwarf's little yard.",
            },
          ],
        },
        {
          entryName: "거쳐",
          typicalExample:
            "【그의 손이 내 발등에서부터 무릎을 거쳐 허벅지를 천천히 애무한다.  】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation: "NOUN을 거쳐: by way of NOUN; via NOUN",
          sentences: [
            {
              Kor: "그의 손이 내 발등에서부터 무릎을 거쳐 허벅지를 천천히 애무한다.  ",
              Eng: "hand caresses my body, starting at my foot, past my knee, and up to my thigh.",
            },
          ],
        },
        {
          entryName: "건",
          typicalExample: "【술집에 남아있는 건】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation: "Abbreviated from 거 (← 것) + particle ㄴ (← 는)",
          sentences: [
            {
              Kor: "술집에 남아있는 건",
              Eng: "things that were left at the bar",
            },
          ],
        },
        {
          entryName: "-건 ...  -건 (간에)",
          typicalExample: "【보기 싫건 나가렴.= 보기 싫거나 [말거나]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "A shortened version of the pattern in Verb1-거나 ... Verb2-거나 (간에) whether ... or ... showing indifference toward a choice between two conflicting actions or states. Cf. the nearly identical patterns in -든 ... -든 (간에) and -든지 ... -든지 (간에).",
          sentences: [
            {
              Kor: "보기 싫건 나가렴.= 보기 싫거나 [말거나]",
              Eng: "If you hate the sight of it, then leave. ",
            },
            {
              Kor: "그게 학 자신의 뜻에서였건 또는 딴 짐승의 짓이건 간에 …",
              Eng: "whether it was the will of the cranes or the actions of some other animal ...",
            },
            {
              Kor: "남편과 딸이 의아해하건 놀라와하건 ",
              Eng: "...not caring whether my husband and daughter were suspicious or surprised",
            },
          ],
        },
        {
          entryName: "건너",
          typicalExample: "【우리집 건너였다. 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation: "NOUN 건너: across from NOUN",
          sentences: [
            { Kor: "우리집 건너였다. ", Eng: "It was across from our place." },
            {
              Kor: "개천 건너 빈민촌에 가 살다시피 했다.  ",
              Eng: "Chisŏp crossed the stream over to the slum and practically lived there. ",
            },
          ],
        },
        {
          entryName: "-건마는/ -건만(은)",
          typicalExample: "【자신에게 이로울 게 없건마는 】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation:
            "Substandard/colloquial spelling of -건만 extended by 은. Martin calls -건만 the 'semi-literary concessive', i.e., this is a somewhat fancy, literary equivalent to -지만 but, even though, while, although.",
          sentences: [
            {
              Kor: "자신에게 이로울 게 없건마는 ",
              Eng: "lit., there was nothing beneficial for himself, i.e., there was nothing for him to gain from it, but… ",
            },
          ],
        },
        {
          entryName: "-건만(은) ",
          typicalExample: "【그의 복덕방에서 잠까지 빌려 자건만 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Martin calls this the 'semi-literary concessive', i.e., this is a somewhat fancy, literary equivalent to -지만 but, even though, while, although.",
          sentences: [
            {
              Kor: "그의 복덕방에서 잠까지 빌려 자건만 ",
              Eng: "even though he would sometimes go so far as to snatch a few winks at the real estate agency…",
            },
            {
              Kor: "그렇건만  소년은 지금 자기가 씹고 있는 대추알의 단맛을 모르고 있었다. ",
              Eng: "And yet, the boy could not appreciate the sweetness of the dates that he was choosing. ",
            },
            {
              Kor: "카운터에 놓건만  ",
              Eng: "even though the man would [pick out his bottle of wine and then] place it on the counter [practically every day]…",
            },
            {
              Kor: "낮에 고단하게 일하건만  ",
              Eng: "even though she was exhausted from working during the day …",
            },
            {
              Kor: "곡조를 알 리 없건만  또 무엇에서 나는 소린지 알 리 없건만 ",
              Eng: "though there was no way for him to recognize the tune or the instrument the tunes was coming from ...",
            },
            {
              Kor: "사느라고 열심히 살았건만  ",
              Eng: "I did my best to live a full life, but ...",
            },
            {
              Kor: "별로 대꾸를 하지 않았건만  그래도 무에라고 연해 웅얼거리면서 나를 보고 웃어 보였다. ",
              Eng: "…didn't really make any response, but still smiled at me, mumbling something.",
            },
            {
              Kor: "궐녀는 20원 몸값을 10년을 두고 갚았건만  그래도 주인에게 빚이 60원이나 남았었는데",
              Eng: "…had paid off [her buying price of 20 wŏn] over the past ten years, but she still owed her master 60 wŏn",
            },
            {
              Kor: "생각했던 때도 있었건만  ",
              Eng: "although there were also times when she thought [that way]",
            },
            {
              Kor: "차리고 하였건만  맹순사는 웬일인지 우선 스스로가 위엄도 없고, 신도 나는 줄을 모르겠고 하였다.",
              Eng: "despite the well-kept appearance, Officer Maeng didn't feel as though there was anything commanding about the man, nor did he feel that he was having any fun.",
            },
          ],
        },
        {
          entryName: "걸",
          typicalExample:
            "【안초시는 거의 달포나 서참위의 복덕방에 나오지 않았었다.  그런 걸 박희완 영감이 가서 데리고 왔었다. = 그런 것을 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'What Martin calls the use of the accusative to "mark an antithetical clause that ends in a modifier + 것." The meaning is contrary to what you might expect or think, despite contrary anticipations/reservations; although, but; (it\'s) too bad (that ...), in spite of the fact that ... ; anyway, so there!',
          sentences: [
            {
              Kor: "안초시는 거의 달포나 서참위의 복덕방에 나오지 않았었다.  그런 걸 박희완 영감이 가서 데리고 왔었다. = 그런 것을 ",
              Eng: "Despite the fact that An Ch’osi had not been showing himself at Sŏ Ch’am-wi's real estates agency for almost a month or so, Old man Park managed to bring him out. ",
            },
            {
              Kor: "이번 앤 꽤 여러날 앓는 걸 약두 변변히 못 써봤다드군. ",
              Eng: "lit., under the adverse circumstances of the girl this time being sick for several days [they couldn't even administer medicine effectively]",
            },
            {
              Kor: "우리 어머니가 싫다는 걸 내가 디리 졸라서 한 번 숙이네한테 통혼을 넣다가 거절을 당한 일이 있었다. ",
              Eng: "There was a time when I kept bugging my mother about making a marriage proposal to Sook—even though she didn't want to, and I was rejected.",
            },
          ],
        },
        {
          entryName: "것",
          typicalExample: "【사대부 집안의 요조숙녀도 아닐 것이 】",
          categoryKorean: "Noun",
          categoryEnglish: "(Noun)",
          translation: "The noun 것 can sometimes refer to a human.",
          sentences: [
            {
              Kor: "사대부 집안의 요조숙녀도 아닐 것이 ",
              Eng: "the unchaste and immodest woman of the magistrate’s household... (Here, 것 refers to 춘향.)",
            },
          ],
        },
        {
          entryName: "-것다",
          typicalExample: "【팔거쏘 ← 팔것소 】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            'Samuel Martin calls this ending the "tentative assertive." It has the following meanings:1. is or does, I assume (suppose, think); 2. surely (certainly) is or does; you WILL do it (understand), implying a threat; 3. given this and that (enumerates a series of reasons arguing for a conclusion).',
          sentences: [
            {
              Kor: "팔거쏘 ← 팔것소 ",
              Eng: "(the meaning here seems bound up with the hawker's cant:) \"I'll sell [your glass bottles and soy sauce tins]!\"",
            },
            {
              Kor: "날 (나를) 은근히 멸시했것다. ",
              Eng: "I'll bet you have secretly held me in contempt.",
            },
          ],
        },
        {
          entryName: "-게1",
          typicalExample: "【하늘의 별이 와르르 얼굴 위에 쏟아질 듯싶게】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Adverbative -게: in such a way that; so as to; so that it is/does; so that one can; in a manner such that; -ly.",
          sentences: [
            {
              Kor: "하늘의 별이 와르르 얼굴 위에 쏟아질 듯싶게",
              Eng: "the stars, as if threatening to come pouring down on his face with a clatter ",
            },
            {
              Kor: "여름이 극성스럽게 덥더니, 추위도 그럴 징조인지",
              Eng: "This summer was extremely hot, and seemed as though the winter would be just as bad. (lit., in such a way that one got impatient, impetuous, overeager, upset, mad, frantic, i.e., extremely, exasperatingly, annoyingly)",
            },
            {
              Kor: "박희완 영감은 술이 거나하게 취해 갔다.  ",
              Eng: "Old man Park was getting drunk to the point that he was tipsy (pleasantly drunk).",
            },
            {
              Kor: "비위 좋게 생각하면 그만이었다. ",
              Eng: "It was best to just think of it in a positive light.",
            },
            {
              Kor: "시어머니와 그녀만이 알게 ",
              Eng: "in such a way that only her mother-in-law and she knew [of his escape]",
            },
            {
              Kor: "남부럽지 않게 지낼 수 있었다. ",
              Eng: "…were able to carve out a decent living (lit., in such a way that there was no need to be envious of others [= 남])",
            },
            {
              Kor: "이를 빈 입인 채 빠드득 소리가 나게 한번 물어 보고 고개를 들었다.",
              Eng: "He clamped his teeth hard once to produce a grinding sound and raised his head.",
            },
            {
              Kor: "소년은 저도 모르게 벌떡 일어섰다.",
              Eng: "The boy sprang up unbeknownst to himself.",
            },
            {
              Kor: "보험료나 타 먹게 어서 죽어 달라는 소리로도 들리었다.",
              Eng: "Sometimes it also sounded like they wanted me to die so they could get the insurance money. ",
            },
            {
              Kor: "뛰고 싶게 기뻤다. ",
              Eng: "...was so happy that he wanted to leap",
            },
            {
              Kor: "대보름 새벽부터 물을 길러 오게?",
              Eng: "It's the 15th of the first month and you're already going to draw water at dawn? ",
            },
            {
              Kor: "드물지 않게 호랑이 꿈을 꿨다.  ",
              Eng: "I dreamt about the tigers without exception.",
            },
            {
              Kor: "몸부림을 치고 싶게 의탁할 곳이 없는 것이 서러웠다. ",
              Eng: "His heart was full of sorrow that he had no one/where to depend on, so much so that he wanted to just drop to the ground and scream and kick it all away.",
            },
            {
              Kor: "여자는 자신과 남편을 열등하게 느꼈다. ",
              Eng: "The woman felt that she and her husband were inferior. ",
            },
            {
              Kor: "떠들지 못하게 물이나 먹이는 거죠. ",
              Eng: "I'm buying them a round to shut them up.",
            },
            {
              Kor: "이 세상 사람 같이 않게 아름다왔다.  ",
              Eng: "She was beautiful unlike anyone in this world.",
            },
            {
              Kor: "배짱 좋게 깔깔거리고 있었다.",
              Eng: "[The darkness] had the nerve to laugh his heart out.",
            },
            {
              Kor: "떨어지지 않게 날라와야 했다. ",
              Eng: "[The lasses] had to serve [the booze] in such a way that the alcohol would never run out.",
            },
            {
              Kor: "그해의 대학 입시에서 윤호는 보기 좋게 떨어졌다.  ",
              Eng: "Yunho failed that year’s college entrance examination spectacularly.",
            },
            {
              Kor: "윤호의 학력이 눈에 띄게 늘어가는 줄만 알았다.  ",
              Eng: "He simply assumed that Yunho's studies were improving in leaps.",
            },
            {
              Kor: "소나무 위에까지 힘들지 않게 날았다. ",
              Eng: "…flew effortlessly onto the top of the pinetree.",
            },
            {
              Kor: "울타리가 넘게 피었던 코스모스들이 끓는 물에 데쳐낸 것처럼 시커멓게 무르녹고 말았다.  ",
              Eng: "The cosmos flowers, that bloomed in such a way that they spilled over the fences, had mellowed to black as if they had been dipped in boiled water. (lit.,in such a way that they spilled over the fences) (Note also that the verb 넘다 exceed; be more than; is over/beyond optionally marks its object with 이/가: 그분은 오십이 넘었어요 He is over 50.)",
            },
          ],
        },
        {
          entryName: "-게2",
          typicalExample: '【"그렇게 말했어?" "맞아 죽게?" 】',
          categoryKorean: "해체의 종결 어미 Final ending in Intimate Style",
          categoryEnglish: "(해체의 종결 어미 Final ending in Intimate Style)",
          translation:
            "adverbial -게 in a somewhat special function: you wanna .. or something? Are you fixin' to ... ?",
          sentences: [
            {
              Kor: '"그렇게 말했어?" "맞아 죽게?" ',
              Eng: '"Did you say that to her?" "What, and get killed?"',
            },
          ],
        },
        {
          entryName: "게3",
          typicalExample: "【푹 쉬었다가 오게 】",
          categoryKorean: "하게체의 종결 어미 Final ending in Familiar Style  ",
          categoryEnglish:
            "(하게체의 종결 어미 Final ending in Familiar Style  )",
          translation:
            "The imperative ending in the Familiar or 하네 Style. This style (indicative하네, suggestion 하세, question 하나, command 하게, retrospective statement 하데, retrospective question 하디 and optional -(으)ㄹ세 for the copula and adjectives (-이네/-일세, 좋을세), is used only among grown-ups, e.g., by a senior addressing a grown-up junior or between grown-up social equals, and is typically used by parents-in-law addressing their sons-in-law, or by a male supervisor to a male supervisee. It is widely used among adult male friends who are not close enough to use the Intimate Style, and less widely by (or to) women. To make a Familiar Style apperceptive (equivalent to -구나, -구먼요, etc. in other styles), you add -그려 or -구려 to -네. Familiar Style (하네) Style commands in -게 can often followed by particle-(으)나.",
          sentences: [
            {
              Kor: "푹 쉬었다가 오게 ",
              Eng: "get completely rested and return",
            },
            {
              Kor: "할일 없으면 세무서로 좀 들러 주게. ",
              Eng: "If you're free, please drop by the office.",
            },
          ],
        },
        {
          entryName: "-게까지 ",
          typicalExample: "【거북설스럽게까지 느껴졌던 것이다.】",
          categoryKorean: "Adverbative",
          categoryEnglish: "(Adverbative)",
          translation:
            "Adverbial -게 + particle 까지: even, so far as (extent, emphasis).",
          sentences: [
            {
              Kor: "거북설스럽게까지 느껴졌던 것이다.",
              Eng: "It even became awkward.",
            },
          ],
        },
        {
          entryName: "-게끔",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation: "[Usage]",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-게나",
          typicalExample: "【회사에 소작료를 바치게나 되었으면 】",
          categoryKorean: "Adverbative",
          categoryEnglish: "(Adverbative)",
          translation:
            "Adverbative -게 + quasi-particle -(이)나: or the like; or something, often with a gently chiding or deprecatory nuance.",
          sentences: [
            {
              Kor: "회사에 소작료를 바치게나 되었으면 ",
              Eng: "if they had ended up just/at least handing over their tenancy fees to the company [this, at least, would have been preferable, but...]",
            },
          ],
        },
        {
          entryName: "-게 내버린다",
          typicalExample: "【술이나 실컷 먹게 내버려 두지요, 뭐 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "A variant on the causative pattern in -게 하-: leave somebody to their own devices; let somebody loose; abandon somebody. Hence, leave/let somebody to ... ",
          sentences: [
            {
              Kor: "술이나 실컷 먹게 내버려 두지요, 뭐 ",
              Eng: "just let them drink as much as they want",
            },
          ],
        },
        {
          entryName: "-게는",
          typicalExample: "【어이 육실하게는 춥네 】",
          categoryKorean: "Adverbative",
          categoryEnglish: "(Adverbative)",
          translation:
            "Adverbative -게 + particle 는: as for so that it is (or one does); indeed, such that it is. The 는 implies contrast with other things that might have been said.",
          sentences: [
            {
              Kor: "어이 육실하게는 춥네 ",
              Eng: "Man, it sure is friggin' [as opposed to some other manner adverb] cold!",
            },
          ],
        },
        {
          entryName: "-게는 하-",
          typicalExample: "【그를 비관하게는 하지 않았다. 】",
          categoryKorean: "Causative",
          categoryEnglish: "(Causative)",
          translation:
            "Causative in -게 하- with particle 는 for implied contrast. ",
          sentences: [
            {
              Kor: "그를 비관하게는 하지 않았다. ",
              Eng: "It didn't make him pessimistic. (I.e., didn't make him feel pessimistic, but it may have caused other emotions).",
            },
          ],
        },
        {
          entryName: "-게도",
          typicalExample: "【이상하게도 그들을 못 잡아먹어 앙탈이었다. 】",
          categoryKorean: "Adverbative",
          categoryEnglish: "(Adverbative)",
          translation:
            "Even (indeed) so that; also/either so that; ...-ingly enough. Cf. other adverbs in -게도: 유감스럽게도 unfortunately, regrettably; 불행하게도 sad to say, unfortunately; 유난스럽게도 unusually; 공교롭게도 as luck would have it; 이렇게도 in such a way; so very ... (like this). ",
          sentences: [
            {
              Kor: "이상하게도 그들을 못 잡아먹어 앙탈이었다. ",
              Eng: "(이상하게도 strangely enough; or, strange indeed, too [was the fact that]) Strangely enough, it just seemed impossible for them to leave them alone./Strangely enough, it seemed like they couldn't stand but to try to eat them alive.",
            },
            {
              Kor: "운수 좋게도  방까지 오는 동안 아무도 만나지 못했었고 아파트 복도에도 사람은 없었다. ",
              Eng: "(운수 좋게도 fortunately (indeed); as luck would have it) Fortunately, he hadn't run into anyone on his way to his place and there was no one in the apartment corridors. ",
            },
            {
              Kor: "운수 나쁘게도  오늘밤 혼자인 것이다. ",
              Eng: "As bad luck would have it, [he was alone tonight].",
            },
            {
              Kor: "치사하게도  시치미를 떼고 있다. ",
              Eng: "Shamefully/disgracefully enough, it was playing dumb/playing innocent.",
            },
            {
              Kor: "무슨 까닭으로 산이 이렇게도  그리울까 ",
              Eng: "For what reason did he miss the mountains so/so very much?",
            },
            {
              Kor: "유난스럽게도  한편에 옹졸봉졸 몰려들었다. ",
              Eng: "They all huddled up to one particular side. ",
            },
            {
              Kor: "공교롭게도  주영나무 가지에 야트막하게 달린 벌집을 찾아냈다. ",
              Eng: "As luck would have it, he discovered a bee hive hanging narrowly on a chuyŏng tree branch.",
            },
            {
              Kor: "신통하게도 아주 변심하여 영원히 안 와버릴 애인은 아니니 ",
              Eng: "Since, amazingly enough, he is not the sort of boyfriend to suffer a complete change of heart and never come around again… ",
            },
            {
              Kor: "남자의 손가락을 타고 신기하게도  사라지고  ",
              Eng: "rode up the man's fingers then disappeared mysteriously",
            },
            {
              Kor: "은희 아버지의 지위를 생각하고, 용렬하게도  일이 나쁘게 풀렸을 때  ",
              Eng: "He had thought of Ŭnhŭi's father's positon, and when, lousily enough, things had played out so badly…",
            },
          ],
        },
        {
          entryName: "-게 된다",
          typicalExample: "【그들의 입길에서 오르내리게 되었다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Turning out (getting to be, coming to pass, happening) so that; getting to; being arranged so that.",
          sentences: [
            {
              Kor: "그들의 입길에서 오르내리게 되었다. ",
              Eng: "[the sound of people saying, “This is it, it’s all over,”] came rolling off their lips with great frequency",
            },
            {
              Kor: "실작인에게는 지주 행세를 하게 되었다. ",
              Eng: '[this thing called a "middleman tenant" came into being, and, while they had never once even touched the earth with their hands, they on the role of tenant farmer at the Oriental Development Company, and] ended up behaving like a landlord to the actual/true farmhand',
            },
            {
              Kor: "일본으로 또 벌이를 찾아가게 되었다.",
              Eng: "…ended up going over to Japan to look for work. ",
            },
            {
              Kor: "그 일본집에 있게 되었던 것이었다. ",
              Eng: "She had ended up staying with that Japanese family.",
            },
            {
              Kor: "여기엔 얼마쯤 있게 되나? ",
              Eng: "(lit., how long does it turn out that you will be here?) How long are you going to be here?",
            },
            {
              Kor: "오지 않게 될 것 같은 예감이 들었다. ",
              Eng: "I had a feeling that I would not come back.",
            },
            {
              Kor: "나중엔 날 수가 없게 되어 모조리 잡혀 멸종당했다. ",
              Eng: "Later [the birds] lost the ability to fly and became extinct after they were all caught.",
            },
            {
              Kor: "이주자들은 불모의 황무지에 살게 될 것이라고 책을 통해 알게 된 것",
              Eng: "the things he had learned through the book; that the settlers would end up living in a barren wasteland",
            },
            {
              Kor: "시계가 틀리면 산소가 떨어진 것을 몰라 죽게 된다.  ",
              Eng: "If your watch is wrong, you end up dying because you don't know you've run out of oxygen.",
            },
          ],
        },
        {
          entryName: "-게 마련이다 ",
          typicalExample: "【먹구 살게는 마련야 = 먹고 살게(는) 마련이야 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Usually preceded by adverbative -게, the pattern in 마련이다 means one cannot help doing/being; one inevitably does/is. The same pattern also occurs as both -기 마련이다 and -도록 마련이다 with the same meaning. Samuel Martin flags -게 마련이다 as dialect, but it appears to be pretty standard.",
          sentences: [
            {
              Kor: "먹구 살게는 마련야 = 먹고 살게(는) 마련이야 ",
              Eng: "life is such that you always survive somehow",
            },
            {
              Kor: "그럼 사무실은 비게 마련이다. ",
              Eng: "In that case, the offices are bound to be empty.",
            },
            {
              Kor: "손님이 끓게 마련이고 ",
              Eng: "customers would inevitably crowd into [the tabang]; it was natural that [the tabang] would be crowded with customers [on a cold day like today] ",
            },
            {
              Kor: "대개 여기서 끝나게 마련이다.",
              Eng: "[The deal] usually ends here. ",
            },
          ],
        },
        {
          entryName: "-게만",
          typicalExample: "【점점 더 못되게만  돌아가 】",
          categoryKorean: "Adverbative",
          categoryEnglish: "(Adverbative)",
          translation:
            "Adverbative -게 + particle 만: only becoming ... ; only ... -ly.",
          sentences: [
            {
              Kor: "점점 더 못되게만  돌아가 ",
              Eng: "the situation was only becoming gradually worse ...",
            },
            {
              Kor: "귀찮게만  아는 이 애비 ",
              Eng: "lit., this old man of hers, whom she knew only as an annoyance (whom she only knew to be annoying)",
            },
            {
              Kor: "좌우간 맛있게만  말아 주쇼 ",
              Eng: "anyway, just make it tasty, please",
            },
            {
              Kor: "지수 자매를 향해 아버지는 단지 이렇게만  말했다. ",
              Eng: "This was all that their father said to Chisu and her sister.",
            },
            {
              Kor: "검게만  보이던 물 ",
              Eng: "the water, which had look nothing but black",
            },
          ],
        },
        {
          entryName: "-게만 돼봐라",
          typicalExample: "【다시 세상 구경을 하게만 돼봐라 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Just wait and see what happens when ...  (warning/threat).",
          sentences: [
            {
              Kor: "다시 세상 구경을 하게만 돼봐라 ",
              Eng: "Just wait until I get a chance to see the world again...",
            },
          ],
        },
        {
          entryName: "-게 만든다",
          typicalExample: "【내 몸을 전연 느끼지 못하게 만들던 그 화투짝】",
          categoryKorean: "Causative",
          categoryEnglish: "(Causative)",
          translation:
            "Causative pattern, similar in function to -게 하-: make (in a way) so that; to make it so that; in a manner such that; so that one can; -ly.",
          sentences: [
            {
              Kor: "내 몸을 전연 느끼지 못하게 만들던 그 화투짝",
              Eng: "the cards that would make it so that my body would not feel a thing",
            },
            {
              Kor: "올케를 불편하게 만들 생각은 없었다. ",
              Eng: "I had no thoughts of bothering my sister-in-law.",
            },
            {
              Kor: "환장을 하게 만들었다. ",
              Eng: "[The food] made him crazy.",
            },
          ],
        },
        {
          entryName: "-게스리",
          typicalExample: "【내 먹게스리 】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            "Dialect for -게(끔) or -게(시리): so that ... , in order that ... ",
          sentences: [{ Kor: "내 먹게스리 ", Eng: "for me to eat" }],
        },
        {
          entryName: "-게 여긴다",
          typicalExample: "【그게 조금도 다행스럽지 않고 두렵게 여겨졌다.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The full pattern is A가 B를 C-게 여기- A considers B to be/as C. In general, the pattern can be translated as: deem, consider, think [something to be such and such a way].",
          sentences: [
            {
              Kor: "그게 조금도 다행스럽지 않고 두렵게 여겨졌다.",
              Eng: "I felt it was not at all lucky, but rather scary.",
            },
          ],
        },
        {
          entryName: "-게(요)1",
          typicalExample: "【누구 위해 들었게요 그럼? 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Riddling or rhetorical question marker. Samuel Martin treats both the riddling use and the rhetorical use as subtypes of adverbative -게. In the latter case, he treats the -게 as an abbreviation of -되지 않겠느냐?",
          sentences: [
            {
              Kor: "누구 위해 들었게요 그럼? ",
              Eng: "Who [else] would I have taken out the insurance for, then?",
            },
            {
              Kor: "멀믄 얼마나 멀갔게? ",
              Eng: "Come on, it can't be all that far! (lit., even if it's far, how far can it be? [answer: not far]) ",
            },
            {
              Kor: "그랬다가는 매를 맞게?!",
              Eng: "You do that and you'll get whipped, won't you?",
            },
          ],
        },
        {
          entryName: "-게(요)2",
          typicalExample: "【저녁에 이 마을서 장사지내게?】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Are you fixing to? Is it your intention to? (always second person question).",
          sentences: [
            {
              Kor: "저녁에 이 마을서 장사지내게?",
              Eng: "Are you fixin' to hold a funeral in the village tonight? (i.e., are you trying to kill us?)",
            },
          ],
        },
        {
          entryName: "-게 한다",
          typicalExample: "【청백 순사를 얼굴 간지럽게 하였다. 】",
          categoryKorean: "Causative",
          categoryEnglish: "(Causative)",
          translation:
            "Causative in -게 하-. The full pattern is A가 B를 C-게 하- A makes B do C or A가 B한테 C-게 하- A has/lets B do C.",
          sentences: [
            {
              Kor: "청백 순사를 얼굴 간지럽게 하였다. ",
              Eng: "…made the face of this upright constable feel ticklish.",
            },
            {
              Kor: "편도선을 붓게 하던 독한 담배꽁초 ",
              Eng: "the nasty cigarette butts that made my tonsils swell up",
            },
            {
              Kor: "문득 생각나게 한 것이었다. ",
              Eng: "It suddenly reminded me of ...",
            },
            {
              Kor: "보는 사람을 신경 질나게 한다고 생각하였다. ",
              Eng: "I thought that it drives people crazy.",
            },
            {
              Kor: "나는 이모에게 소주를 사오게 하여 ",
              Eng: "I had my aunt buy some soju for me and drank myself into a drunken stupor.",
            },
            {
              Kor: "돈을 쓰며 처리하게 하였다. ",
              Eng: "…had him use and handle the money",
            },
            {
              Kor: "맞춰 짓게 허구 = 맞추어(서) 짓게 하고 ",
              Eng: "have them/somebody order to his size and specifications and make",
            },
            {
              Kor: "대화가 자꾸 술을 마시게 했다. ",
              Eng: "The conversation prompted me to keep drinking.",
            },
            {
              Kor: "이 학마을을 잘 되게 해 줄 사람들이 ",
              Eng: "[rumour had it that those people were not] people who would do anything for Crane Village",
            },
            {
              Kor: "몇 차례 놓이게 하여 주기도 하였다. ",
              Eng: "...also/even got him released several times",
            },
            { Kor: "연상하게 하였다", Eng: "it reminded me of ..." },
            {
              Kor: '"어머니, 그냥 여기 있게 해주세요." ',
              Eng: '"Mother, please just let me stay here."',
            },
            {
              Kor: "그는 우주인과 그 가족을 만나게 해주겠다면서 윤호를 끌고 나갔다. ",
              Eng: "Chisŏp dragged Yunho there, saying that he would let him meet the spaceman and his family. ",
            },
            {
              Kor: "지수의 나날을 견디게 한 것은  ",
              Eng: "the thing that allowed Chisu to endure these days upon end",
            },
          ],
        },
        {
          entryName: "-겠나",
          typicalExample:
            "【일러 주구 구경만 하자겠나 = 일러 주고 구경만 하자고 하겠나? 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Rhetorical question in -겠나? Is it the case that will do/be? (You bet!) ",
          sentences: [
            {
              Kor: "일러 주구 구경만 하자겠나 = 일러 주고 구경만 하자고 하겠나? ",
              Eng: "Do you suppose anyone would tell you such an inside tip and then intend to just stand by and watch [without asking for money of course not!]?",
            },
          ],
        },
        {
          entryName: "-겠냐는 거",
          typicalExample: "【며칠을 더 버티겠냐는 거였다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Quoted rhetorical question adnominalized to 거 ← 것: -겠[느]냐 [-고 하-] –는 거.",
          sentences: [
            {
              Kor: "며칠을 더 버티겠냐는 거였다 ",
              Eng: 'lit., it was a matter of "how many more days would they hold out?" (implying not many)',
            },
          ],
        },
        {
          entryName: "-겠네",
          typicalExample:
            '【“동구 씨는 결혼했대요? 민석 씨네는 이제 아기 있겠네?"】',
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-겠네 is the future-presumptive -겠- followed by Mild Surprise -네, hence: Well, then, I suppose it must be the case that ... !",
          sentences: [
            {
              Kor: '“동구 씨는 결혼했대요? 민석 씨네는 이제 아기 있겠네?"',
              Eng: '"Did they say Tonggu got married? Minseok and his wife must have a baby by now, yes?"',
            },
          ],
        },
        {
          entryName: "-겠는가(요)?",
          typicalExample:
            "【세상에 자기 아내와 자식 귀하지 않은 놈 있겠는가. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-겠는가(요)? Would it be the case that ... ?  I.e., question ending -(으)ㄴ가(요)? (often used for musing or wondering to oneself) on future-presumptive -겠-.",
          sentences: [
            {
              Kor: "세상에 자기 아내와 자식 귀하지 않은 놈 있겠는가. ",
              Eng: "What jerk is there on earth who doesn't care for his wife and kids?",
            },
          ],
        },
        {
          entryName: "-겠는 걸(요)",
          typicalExample: "【자, 난 이제 가봐야겠는 걸 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'This is an example of what Martin calls the use of the accusative to "mark an antithetical clause that ends in a modifier + 것." The meaning is: contrary to what you might expect or think, despite contrary anticipations/reservations; although, but; (it\'s) too bad (that ...), in spite of the fact that ... ; anyway, so there!',
          sentences: [
            {
              Kor: "자, 난 이제 가봐야겠는 걸 ",
              Eng: "well, I guess I'll be on my way now ...",
            },
            {
              Kor: "가기가 그리 쉽지 않겠는 걸",
              Eng: "it probably won't be so easy to go on ",
            },
          ],
        },
        {
          entryName: "-겠는데(요)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "For Imminent Elaboration. ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-겠다는 ",
          typicalExample: "【혼자 남아 있겠다는 지수】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "-겠다는 ← -겠다[고 하]는 (contracted indirect quotation).",
          sentences: [
            {
              Kor: "혼자 남아 있겠다는 지수",
              Eng: "Chisu, who said she would stay behind by herself",
            },
          ],
        },
        {
          entryName: "-겠던지 ",
          typicalExample: "【못 참겠던지】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier [-(으)ㄴ, -던, -었던, -었는, -는] + postmodifier 지: the uncertain fact whether. When left hanging in mid-sentence, you can interpret the pattern as having left out a following -도 모르지만: it may have been the case that, i.e., perhaps [it is/was] because... ",
          sentences: [
            {
              Kor: "못 참겠던지",
              Eng: "perhaps/maybe because he felt he wouldn't be able to stand it, ...",
            },
          ],
        },
        {
          entryName: "-겠었-",
          typicalExample: "【대체 웬일인지를 모르겠었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Past tense on a 'future-presumptive' in -겠-:  it was the case that wouldn't/couldn't ... For all practical purposes, this only occurs with 모르겠- I wouldn't know.",
          sentences: [
            {
              Kor: "대체 웬일인지를 모르겠었다 ",
              Eng: 'he didn\'t know what in the world for (lit., at the time [in the past], felt/thought "모르겠다")',
            },
          ],
        },
        {
          entryName: "-겠으며",
          typicalExample:
            "【그녀에게 무슨 기회가 오겠으며 그럴 능력이나 있겠는가....... 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-겠으며, i.e., future-presumptive -겠- + -(으)며, with the latter functionally equivalent to -고. ",
          sentences: [
            {
              Kor: "그녀에게 무슨 기회가 오겠으며 그럴 능력이나 있겠는가....... ",
              Eng: "what opportunity could she have and would she even have the ability to do it?",
            },
          ],
        },
        {
          entryName: "-겠지",
          typicalExample: "【따로 있다는 것뿐이겠지 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "따로 있다는 것뿐이겠지 ",
              Eng: "it only that there is a different place [for classical and pop songs to be sung]",
            },
          ],
        },
        {
          entryName: "-겠지 한다",
          typicalExample:
            "【바쁘셔서 오늘밤 호텔에서 주무신다는 비서의 전화겠지 했다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'NOUN(이)겠지 한다: say/thinks (to oneself) "I\'ll bet it must be NOUN" ',
          sentences: [
            {
              Kor: "바쁘셔서 오늘밤 호텔에서 주무신다는 비서의 전화겠지 했다.  ",
              Eng: "He thought to himself, 'it must be a call from my dad's secretary saying he's spending tonight at a hotel'.",
            },
          ],
        },
        {
          entryName: "격",
          typicalExample: "【사위녀석 격인 청년 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "Postnoun 격: 1) standing, rank, class, grade, order or 2) style, manner, rule, pattern.",
          sentences: [
            {
              Kor: "사위녀석 격인 청년 ",
              Eng: "this young man who was along the order of/could have been [was equivalent to the rank of] his [rascal of a] son-in-law (here followed by -인, the modifier form of the copula)",
            },
            {
              Kor: "두루마기 격으로 ",
              Eng: "along the lines of a turumagi (Korean men's outer coat)",
            },
          ],
        },
        {
          entryName: "격이 다르다",
          typicalExample: "【빤빤한 사찰과는 그 격이 다르다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN과는(그) 격이 다르-: is not in the manner of NOUN, is a style different from NOUN, is in a different league from NOUN.",
          sentences: [
            {
              Kor: "빤빤한 사찰과는 그 격이 다르다 ",
              Eng: "its manner was different from that of a fancy temple",
            },
          ],
        },
        {
          entryName: "결1",
          typicalExample: "【이장 영감은 잠결에 그 소리를 듣고 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "Postnoun 결: (in) the course of; in passing while (doing); at the same time as; when, while one is at it; incidental (to); happening (to) in passing; '(on) the wave of'.",
          sentences: [
            {
              Kor: "이장 영감은 잠결에 그 소리를 듣고 ",
              Eng: "the village head heard the noise half in sleep / through his slumber ...",
            },
          ],
        },
        {
          entryName: "결2",
          typicalExample: "【모르는 결에 그것을 느낄 뿐이다 】",
          categoryKorean: "Postmodifier",
          categoryEnglish: "(Postmodifier)",
          translation:
            "Postmodifier 결 = -는길에: (in) the course of; in passing while (doing); at the same time as; when, while one is at it; incidental (to); happening (to) in passing; '(on) the wave of'.",
          sentences: [
            {
              Kor: "모르는 결에 그것을 느낄 뿐이다 ",
              Eng: "before he realized it, that was the only thing he felt",
            },
          ],
        },
        {
          entryName: "결단코 ",
          typicalExample:
            "【결단쿄 서분이 스스로의 무능한 소치거나 과실이거나 한 것은 아니었다. 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "The adverb 결단쿄: usually precedes a negative verb, and means definitely [not], not [at all/ in the least]. ",
          sentences: [
            {
              Kor: "결단쿄 서분이 스스로의 무능한 소치거나 과실이거나 한 것은 아니었다. ",
              Eng: "It was by no means a product of Sŏbun's own ineptness or fault.",
            },
          ],
        },
        {
          entryName: "결코",
          typicalExample: "【나는 결코  실망하지 않았다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Always followed by a negative, with the meaning not VERB at all, not VERB in the least.",
          sentences: [
            {
              Kor: "나는 결코  실망하지 않았다 ",
              Eng: "I was not disappointed in the least",
            },
            {
              Kor: "결코  호랑이는 나타나질 않았다 ",
              Eng: "there was not a tiger in sight",
            },
            {
              Kor: "세상은 결코  단 한 차례도 고요에 빠진 적이 없는 것 같았다.  ",
              Eng: "It seemed the world had never even once fallen into peace and quiet.",
            },
            {
              Kor: "결코  야산에 지나지 않고 ",
              Eng: "it is not any different from any old hill ...",
            },
          ],
        },
        {
          entryName: "겸",
          typicalExample: "【아들 겸 믿으며 살던 윗동네에 있는 그의 매부가 】",
          categoryKorean: "Noun",
          categoryEnglish: "(Noun)",
          translation:
            "NOUN 겸: and also, in addition, as well, plus, at the same time, combining (two functions).",
          sentences: [
            {
              Kor: "아들 겸 믿으며 살던 윗동네에 있는 그의 매부가 ",
              Eng: "his brother-in-law who was regarded also as a son [by Mr. Yi's mother] who lived in the next village",
            },
          ],
        },
        {
          entryName: "겹다",
          typicalExample: "【미련겨워하기를】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "[Usage]",
          sentences: [
            { Kor: "미련겨워하기를", Eng: "to be reluctant to let go ..." },
          ],
        },
        {
          entryName: "경우",
          typicalExample: "【늘 한가지를 사는 경우 】",
          categoryKorean: "Postmodifier",
          categoryEnglish: "(Postmodifier)",
          translation:
            "Situation, case used as a postmodifier: in cases when, in circumstances when.",
          sentences: [
            {
              Kor: "늘 한가지를 사는 경우 ",
              Eng: "in cases where they always bought the same thing",
            },
          ],
        },
        {
          entryName: "-고?",
          typicalExample: "【몇 번이고  】",
          categoryKorean: "해체의 종결 어미 Final ending in Intimate Style",
          categoryEnglish: "(해체의 종결 어미 Final ending in Intimate Style)",
          translation:
            'In Middle Korean and in some (southeastern) Korean dialects, verbs in "WH-questions" (i.e., questions which contain a question word like "what?" "how many" or the like, as opposed to yes-no questions) take a question ending in final -고 rather than -가 as in the modern standard language.',
          sentences: [
            {
              Kor: "몇 번이고  ",
              Eng: 'time and time again (The expression here is an archaism, frozen in time, with idiomatic semantic change: lit., "How many times is it?" → "Ever so many times; time and time again.")',
            },
          ],
        },
        {
          entryName: "-고",
          typicalExample: "【서울의 어느 거리에서고  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Question word + -고 as a generalizer: each and every ...",
          sentences: [
            {
              Kor: "서울의 어느 거리에서고  ",
              Eng: "on each (and every) Seoul street ...",
            },
            {
              Kor: "여러 자 깊이로 쌓이고  쌓인 깨금잎 ",
              Eng: "filbert leaves, piling up a few feet",
            },
            {
              Kor: "그립고  그리운 건 조선의 물이더군요 ",
              Eng: "that which I had craved so was Korea's water ",
            },
            {
              Kor: "왜요? 좋은 직업 놔두고 ? ",
              Eng: '"Why? And leave a good job behind?" (In other words, this sentence picks up on the previous line of dialogue, and if it had been spelled out and spoken 100% pedantically, the speaker would have said: 걔 좋은 직업 놔두고, 이민을 왜 가?)',
            },
          ],
        },
        {
          entryName: "-고 ... -고",
          typicalExample: "【엎어지고  자빠지고  여러 차례였다 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "엎어지고  자빠지고  여러 차례였다 ",
              Eng: "we tumbled over forwards and backwards many a time",
            },
          ],
        },
        {
          entryName: "-고 ... -고 하-",
          typicalExample: "【대껄을 하고 할 따름이었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "VERB1고 ... (VERB2고) 하-: does/is and stuff.",
          sentences: [
            {
              Kor: "대껄을 하고 할 따름이었다 ",
              Eng: "would just treat her and stuff ...",
            },
            {
              Kor: "굴고 하염직은 한 노릇이었다 ",
              Eng: "it was worth acting this way ...",
            },
            {
              Kor: "한밤중에 청소하는 것도 청승맞고 해서  ",
              Eng: "cleaning in the middle of the night was bad luck and stuff, too",
            },
          ],
        },
        {
          entryName: "-고가",
          typicalExample: "【뭐가 더 배가 부르고 안 부르고가 있니? 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "A verb phrase (더 배가 부르고 안 부르고) treated as one large noun and affixed with the 가 to function as the subject of 있어요 (here functioning in a rhetorical question).",
          sentences: [
            {
              Kor: "뭐가 더 배가 부르고 안 부르고가 있니? ",
              Eng: "is there any question of your stomach getting full or not? (i.e., it makes no difference whether your stomach is full or not) ",
            },
            {
              Kor: "배가 부르고 안 부르고가 문제가 아니었다 ",
              Eng: "it was not a matter of [Kilsu's] stomach being full or not",
            },
          ],
        },
        {
          entryName: "-고 그런다",
          typicalExample:
            "【또 가끔씩 매운탕집에서 화투도 치고 그러는 것뿐인데.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB-고 그런다: VERBs and stuff; VERBS and also does other unspecified things. ",
          sentences: [
            {
              Kor: "또 가끔씩 매운탕집에서 화투도 치고 그러는 것뿐인데.  ",
              Eng: "And besides, all I do is sometimes play hwat'u at the maeunt'ang place and stuff.",
            },
          ],
        },
        {
          entryName: "-고 난다",
          typicalExample: "【한참 세수를 하고 나더니 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Just finishes doing (usually past)",
          sentences: [
            {
              Kor: "한참 세수를 하고 나더니 ",
              Eng: "she had just finished washing her face for quite some time [whereupon she suddenly ...]",
            },
            {
              Kor: "마지막 바퀴를 돌고 난 학들은 ",
              Eng: "the cranes that had made their last round",
            },
          ],
        },
        {
          entryName: "-고 나니(까) ",
          typicalExample: "【담배만 몇 대를 피고 나니 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The just finishes doing pattern in -고 나- combined with 'sequential' -(으)니 since it is or does; when (in the past) then [one finds] meaning now that one [just/recently] finishes doing, one realizes/discovers ...",
          sentences: [
            {
              Kor: "담배만 몇 대를 피고 나니 ",
              Eng: "since has had just finished smoking several cigarettes [on an empty stomach]",
            },
            { Kor: "그러고 나니 ", Eng: "and with that ..." },
            {
              Kor: "직업을 잃고 나니 ",
              Eng: "now that he had just lost his job ...",
            },
            {
              Kor: "두 끼를 굶고 나니  ",
              Eng: "after having skipped two meals ...",
            },
            {
              Kor: "책을 내려놓고 나니  ",
              Eng: "once having put down the books ...",
            },
            {
              Kor: "분명히 사랑해서 결혼했는데 사랑을 이루고 나니   ",
              Eng: "clearly I married for love, but after achieving that love",
            },
          ],
        },
        {
          entryName: "-고나",
          typicalExample: "【넉넉하고나】",
          categoryKorean: "Dialect morphology/Substandard ",
          categoryEnglish: "(Dialect morphology/Substandard )",
          translation:
            "An older spelling and/or dialect or substandard version of what is nowadays treated as apperceptive (First Realization) -구나.",
          sentences: [
            { Kor: "넉넉하고나", Eng: "I'll be darned, it's enough!" },
          ],
        },
        {
          entryName: "-고 나면 ",
          typicalExample:
            "【사정을 하고 나면  불에라도 덴 것처럼 몸을 일으키고는 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Once one does it, after one does it ... ",
          sentences: [
            {
              Kor: "사정을 하고 나면  불에라도 덴 것처럼 몸을 일으키고는 ",
              Eng: "after ejaculating, he would get up [and head for the bathroom] as if he had burned himself or something",
            },
            { Kor: "그리고 나면  ", Eng: "and after that ..." },
            {
              Kor: "공짜로 닦아주고 나면  ",
              Eng: "if and when/after I give somebody a freebie shoe-shine ...",
            },
            {
              Kor: "10층까지 오르내리고 나면  ",
              Eng: "after walking up and down ten floors ...",
            },
            {
              Kor: "이렇게 마음을 다지고 나면  ",
              Eng: "once he would harden his heart in this way ...",
            },
          ],
        },
        {
          entryName: "-고 나서",
          typicalExample:
            "【전화를 끊고 나서  나는 일부러 괜한 한숨을 한번 쉬고는  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "After ...-ing.",
          sentences: [
            {
              Kor: "전화를 끊고 나서  나는 일부러 괜한 한숨을 한번 쉬고는  ",
              Eng: "after hanging up, I make a point of heaving a big, empty sigh, and then",
            },
            {
              Kor: "술 한 잔씩을 마시고 나서  ",
              Eng: "after we had each downed a drink ...",
            },
            {
              Kor: "쓰고 나서  나는 그 편지를 읽어봤다 ",
              Eng: "after writing the letter I read it ",
            },
            { Kor: "점심을 먹고 나서  ", Eng: "after eating lunch ..." },
            { Kor: "뒤를 돌아보고 나서  ", Eng: "after looking back, he ..." },
            {
              Kor: "막상 작정하고 나서  ",
              Eng: "finally, after making up my mind ...",
            },
            {
              Kor: "긴 연설을 한바탕 늘어놓고 나서  ",
              Eng: "after they had reeled off a long speech ...",
            },
            {
              Kor: "백화 쪽을 힐끗 보고 나서  말했다 ",
              Eng: "after glancing in Paekhwa's direction he said, ...",
            },
          ],
        },
        {
          entryName: "-고 나서야",
          typicalExample: "【몇 차례를 겪고 나서야  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The pattern in -고 나서 meaning after ...-ing, followed by particle (이)야 only if it be. Hence, only after ...-ing.",
          sentences: [
            {
              Kor: "몇 차례를 겪고 나서야  ",
              Eng: "it was only after undergoing this several times; or, it was not until [the villagers] had experienced [this] several times that ...",
            },
            {
              Kor: "남편이 다시 잠들고 나서야   ",
              Eng: "only once her husband had gone back to sleep again",
            },
          ],
        },
        {
          entryName: "-고 나서면서(는)",
          typicalExample: "【국물이나 얻어 마시고 나서면서는 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The pattern in -고 나서 meaning after ...-ing combined with -(이)면서 while being a case of ... (Alternatively, this could be -고 나서- with 나서- embark on; finish off doing followed by just -(으)면서 while ...-ing.)",
          sentences: [
            {
              Kor: "국물이나 얻어 마시고 나서면서는 ",
              Eng: "finishing off some soup or the like that he had got ... ",
            },
          ],
        },
        {
          entryName: "-고 나서부터",
          typicalExample: "【아버지가 돌아가시고 나서부터였다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Ever since [starting from] the time when/after.",
          sentences: [
            {
              Kor: "아버지가 돌아가시고 나서부터였다",
              Eng: "Had been ever since [the time] his father had died ...",
            },
          ],
        },
        {
          entryName: "-고 나선",
          typicalExample: "【집 나가고 나선 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contracted from -고나서는: ever since ...ing; after ...-ing.",
          sentences: [{ Kor: "집 나가고 나선 ", Eng: "after he left home" }],
        },
        {
          entryName: "-고 난 다음부터",
          typicalExample: "【통곡을 하고난 다음부터 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "After finishing doing (usually past)",
          sentences: [
            { Kor: "통곡을 하고난 다음부터 ", Eng: "after lamenting that ..." },
          ],
        },
        {
          entryName: "-고 난 뒤 ",
          typicalExample: "【한참 울고 난 뒤다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "After finishing doing (usually past). I.e., the pattern in -고 나- in modifier form, adnominalized to 뒤.",
          sentences: [
            {
              Kor: "한참 울고 난 뒤다 ",
              Eng: "it was (is) just after having cried for a spell",
            },
            {
              Kor: "처음 선생님이 가시고 난 뒤에는 ",
              Eng: "after the first time you left ...",
            },
            {
              Kor: "맘에 안 드는 녀석 하나를 골탕먹이고 난 뒤와같이 ",
              Eng: "as if we had just gotten rid of a guy we didn't like ...",
            },
            {
              Kor: "나를 헌신짝처럼 차버리고 난 뒤 ",
              Eng: "after discarding me like an old worn-out shoe ...",
            },
          ],
        },
        {
          entryName: "-고 난 후(에)",
          typicalExample: "【남자가 어디론가 사라지고 난 후에도  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "After ...-ing; after finishing ...-ing.",
          sentences: [
            {
              Kor: "남자가 어디론가 사라지고 난 후에도  ",
              Eng: "even after the man had disappeared somewhere",
            },
            {
              Kor: "그 꿈을 꾸고 난 얼마 후의 일이었다.  ",
              Eng: "It happened a few days after I dreamed that dream.",
            },
          ],
        },
        {
          entryName: "-고 난리다",
          typicalExample: '【"걔 딸이 학교 안 간다고 울고 난리래." 】',
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB-고 난리다: is VERB-ing and generally making a great fuss/causing a scene. ",
          sentences: [
            {
              Kor: '"걔 딸이 학교 안 간다고 울고 난리래." ',
              Eng: '"Apparently his daughter is making a big fuss and refusing to go to school."',
            },
          ],
        },
        {
          entryName: "-고 VERB2-고를 떠나서",
          typicalExample: "【이것은 아내에 대한 사랑이 있고 없고를 떠나서  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB1-고 VERB2-고를 떠나서: getting away from/abstracting away from the question of VERB1 and/or VERB2 ... .",
          sentences: [
            {
              Kor: "이것은 아내에 대한 사랑이 있고 없고를 떠나서  ",
              Eng: "getting away from the question of whether this is about a man's love for his wife or the lack thereof",
            },
          ],
        },
        {
          entryName: "-고는",
          typicalExample:
            "【그러고는  눈을 감은 채 아이의 뺨에 자기 뺨을 대고  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Placing the 는 after -고 forces the and [then] reading of -고, as opposed to the and [also] reading. In other words, it means when... , ... then; upon doing, but emphasizing the consequence. ",
          sentences: [
            {
              Kor: "그러고는  눈을 감은 채 아이의 뺨에 자기 뺨을 대고  ",
              Eng: "and then, eyes still closed, pressed her cheek up against his",
            },
            {
              Kor: "전화를 끊고 나서 나는 일부러 괜한 한숨을 한번 쉬고는   ",
              Eng: "after hanging up, I make a point of heaving a big, empty sigh, and then",
            },
            {
              Kor: "금방 갔다 올게, 하고는  밖으로 나갔다.  ",
              Eng: '"I\'ll be back right away," I said, then went out. ',
            },
            {
              Kor: "고개를 욕조에 깊이 처박고는  엉덩이를 들썩대며  ",
              Eng: "As I stick my head deep in the bathtub and lift my hips up and down…",
            },
            { Kor: "그리고는 ", Eng: "and then ..." },
            {
              Kor: "아주머니는 나에게 우리 시어머니예요, 하고는  노파에겐 손님이예요, 하도 추워하시길래 안방으로 모셨어요, 했다. ",
              Eng: '"This is my mother-in-law," she said, and then to the old lady she said, "She\'s a guest here, the cold seemed to be really getting to her so I asked her into the inner room."  ',
            },
            {
              Kor: "이 추운 날 소리를 하고는  ",
              Eng: 'upon uttering "on this cold day" ...',
            },
            {
              Kor: "한마디 하고는  얼굴을 조금 찡그렸다 ",
              Eng: "he said one word and scowled",
            },
            {
              Kor: "감사한 얼굴을 하고는  ",
              Eng: "gave me a grateful look and then ...",
            },
            {
              Kor: "담배를 재떨이에 비비고는  ",
              Eng: "rubbed out his cigarette in the ashtray and then ...",
            },
            {
              Kor: "통곡 소리를 연상하고는  ",
              Eng: "when, remembering the wailing sounds ...",
            },
            {
              Kor: "빈대떡은 이렇게 대질러놓고는  ",
              Eng: "Pancake retorted like this, and then ...",
            },
            {
              Kor: "처음에 이 일을 시작하고는  ",
              Eng: "when [after] he first started this work...",
            },
            {
              Kor: "다른 편 어깨 위로 바꾸어 메고는  ",
              Eng: "he switched [the bag] over to the other shoulder, and then he ...",
            },
            {
              Kor: "창 밖으로 고개를 돌리고는  ",
              Eng: "turned his head to look out the window and then ...",
            },
            {
              Kor: "송진을 상채기에다 문질러 바르고는  ",
              Eng: "after he had rubbed the pine resin on her wound",
            },
            {
              Kor: "책보를 집어던지고는  ",
              Eng: "threw down his book satchel and then ... ",
            },
            {
              Kor: "차장은 나를 힐끔 보고는  ",
              Eng: "the driver took one look at me, and then ...",
            },
            {
              Kor: "이몽룡의 차림을 보고는  ",
              Eng: "looked at how Yi Mongnyong was dressed and then...",
            },
            {
              Kor: "그 말을 듣고는  놀라지 않았다 ",
              Eng: "he wasn't surprised to hear it",
            },
            {
              Kor: "지갑을 도로 꾹 찔러 주고는  ",
              Eng: "thrusting out the wallet out at her ...",
            },
            {
              Kor: "맥주를 시키고는  다시 말했다 ",
              Eng: "ordered a beer and then resumed talking",
            },
            { Kor: "벼슬이 떨어지고는  ", Eng: "lost his post and then ..." },
            {
              Kor: "더러운 때의 앙금을 군데군데 남기고는  비어 있었다.",
              Eng: "After leaving [filthy sediments here and there], it was/had become empty.",
            },
            {
              Kor: "맞은편 산에 맞고는  길게 되돌아 울어 왔다 ",
              Eng: "the cries hit the opposite mountain and then resonated a long time ",
            },
            {
              Kor: "혼잣말을 뱉고는  영을 넘어 면으로 갔다 ",
              Eng: "spitting these words to himself, he went off over the ridge to the county office",
            },
            {
              Kor: "온 방안의 물건을 자세히 보리라고 다짐하고는  ",
              Eng: "he vowed to look carefully at all the objects in the room, and then ...",
            },
          ],
        },
        {
          entryName: "-고는 한다",
          typicalExample: "【불면의 밤은 허옇게 밝아지고는 하였다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The 'habitual' pattern,  more commonly abbreviated as -곤 하다: makes a habit/practice of doing it; does it from time to time; sometimes does.",
          sentences: [
            {
              Kor: "불면의 밤은 허옇게 밝아지고는 하였다 ",
              Eng: "her sleepless nights would yield to the light gray of dawn",
            },
            {
              Kor: "거침없이 해내곤 했던 것이다 ",
              Eng: "I used to (have thoughts) without (any embarrassment) or hesitation",
            },
            {
              Kor: "창 밖을 내다보곤 할 뿐이었다 ",
              Eng: "all she did was continually stare out of the window",
            },
          ],
        },
        {
          entryName: "-고도1",
          typicalExample: "【일 안하고】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "More or less equivalent to -어도, but with the nuance of even after doing, even after having done; and yet, but, while, though.",
          sentences: [
            {
              Kor: "일 안하고",
              Eng: "[she got more money] even without working",
            },
            {
              Kor: "두둑한 여비를 갖고도 ",
              Eng: "even though I had plenty of travel money ...",
            },
            {
              Kor: "크게 천대받지 않고도  ",
              Eng: "even if they are not treated with great contempt ...",
            },
            {
              Kor: "그 사내가 떠나고도  ",
              Eng: "even after the guy departed ...",
            },
            {
              Kor: "시작 시간을 5분 남겨 놓고도  ",
              Eng: "even though there were (just) five minutes left until starting time...",
            },
            {
              Kor: "아이를 눕혀놓고도   ",
              Eng: "even after putting the child down [i.e., to sleep]",
            },
            {
              Kor: "먹지 않고도  살 수 있는 사람인가 하는 궁금증뿐이었다. ",
              Eng: "It was only the concern as to whether the woman was a person who could live without even eating.",
            },
            {
              Kor: "그것들을 모두 확인하고도   ",
              Eng: "even after confirming all of those things",
            },
            {
              Kor: "예정일이 지나고도  일주일.  ",
              Eng: "a good week after her due date had passed [lit.: one week, even after her due date had passed]",
            },
            {
              Kor: "깨어나고도  한참 동안은 몸을 움직일 수 없었다.  ",
              Eng: "Even after waking up, I was unable to move for some time.",
            },
            {
              Kor: "단지까지 갔다 오고도  나는 피로한 줄도 몰랐다.  ",
              Eng: "Even after going all the way to the apartment complex and back, I don't feel tired.",
            },
          ],
        },
        {
          entryName: "-고도2",
          typicalExample: "【길고도  어두운 동굴이었다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Equivalent to -기도 하고 on descriptive verbs: both ...and yet ...; and yet, but,  while, though; also does, is also.",
          sentences: [
            {
              Kor: "길고도  어두운 동굴이었다.  ",
              Eng: "It was a long, and [yet, at the same time] dark, cave.",
            },
            { Kor: "귀엽고도  아름다운 ", Eng: "both cute and beautiful ..." },
            {
              Kor: "달고도  썼다 ",
              Eng: "the liquor tasted] sweet, and yet bitter",
            },
            {
              Kor: "그 단순하고도  명료한 세계가 마음에 들었다.  ",
              Eng: "That world, so simple and clear, appealed to me.",
            },
          ],
        },
        {
          entryName: "-고도3",
          typicalExample: "【높고도  높은 곳 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "VERB-고도 VERB: very VERB, extremely VERB.",
          sentences: [
            { Kor: "높고도  높은 곳 ", Eng: "a very very high place" },
            {
              Kor: "무료하고도  무료했던 내 대학 생활 ",
              Eng: "my university days, which were so incredibly boring",
            },
          ],
        },
        {
          entryName: "-고 또 VERB2-곤 한다",
          typicalExample: "【사진 속의 아버지를 들여다보고 또 보곤 했다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB1-고 또 VERB2-곤 한다 is an emphatic equivalent to the 'habitual' pattern in just -곤 한다 makes a habit/regular practice (a regular thing) of doing it; does it from time to time.",
          sentences: [
            {
              Kor: "사진 속의 아버지를 들여다보고 또 보곤 했다.  ",
              Eng: "She would peer at her father in the photo over and over again.",
            },
          ],
        },
        {
          entryName: "-고래",
          typicalExample:
            "【한 댓 냥 꿰주소고래 (The shape 소 here after 주- give is also a dialect shape, as we would expect 주오 (semi-authoritative Style) in standard.)】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            "Dialect morphology. The endings in -가레 and -고래 are equivalent to standard -그려/-구려, used to make a (standard) Familiar Style apperceptive (equivalent to -구나, -구먼요, etc. in other styles). ",
          sentences: [
            {
              Kor: "한 댓 냥 꿰주소고래 (The shape 소 here after 주- give is also a dialect shape, as we would expect 주오 (semi-authoritative Style) in standard.)",
              Eng: "Why don't you lend me 5 nyang? ",
            },
          ],
        },
        {
          entryName: "-고 만다1",
          typicalExample: "【멈출 줄 모르는 고질병이 되고 말았다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The -고 form + auxiliary verb 마-ㄹ- to show final development of an action: ends up doing, finally does, gets around to doing, ultimately does.  ",
          sentences: [
            {
              Kor: "멈출 줄 모르는 고질병이 되고 말았다  ",
              Eng: "it finally became a chronic disease which would never stop (lit., which did not know how to stop)",
            },
            {
              Kor: "동양척식회사의 소유에 들어가고 말았다  ",
              Eng: "ended up becoming the property of the Oriental Development Company",
            },
            {
              Kor: "그의 어머니 또한 죽고 말았다  ",
              Eng: "his mother also ended up dying",
            },
            {
              Kor: "그날 밤 이장영감도 갑자기 세상을 떠나고 말았다  ",
              Eng: "that night, the village head suddenly passed away, too",
            },
            {
              Kor: "병을 다 말리고 말았다  ",
              Eng: "ended up draining the bottle",
            },
            {
              Kor: "타국의 외로운 혼이 되고 말았다  ",
              Eng: "[his father] ended up a lonely soul in a foreign land",
            },
            {
              Kor: "그를 내보내지 못하고 말았다  ",
              Eng: "I ended up not being able to send him away",
            },
            {
              Kor: "8.15가 덜컥 달려들고 말았지만 ",
              Eng: "the liberation of August Fifteenth ended up springing at him with a snap, but ...",
            },
            {
              Kor: "그러나 결국 “안녕히 가십시오”는 나오지 않고 말았다  . ",
              Eng: "I ended up not even getting a good-bye.",
            },
            { Kor: "무안만 당하고 말았", Eng: "I ended up disgraced ..." },
            { Kor: "눕고 말았다   ", Eng: "ended up by lying down" },
            {
              Kor: "앓아눕고 말았다   ",
              Eng: "he] ended up falling ill [in bed sick]",
            },
            {
              Kor: "요즘은 적어지고 말았다   ",
              Eng: "lately [such emotions] had ended up becoming quite infrequent altogether ",
            },
            {
              Kor: "동네 부근에 퍼지고 말았다   ",
              Eng: "had spread throughout the village",
            },
            {
              Kor: "두세 번 먼저 퍼 담아주고 말았다   ",
              Eng: "I ended up first filling up her pitcher two or three times",
            },
            {
              Kor: "무슨 결산이나 하고 마는 것처럼 ",
              Eng: "this was seen as sort of a score-settling final act ",
            },
            {
              Kor: "배가 푹 꺼지고 만다 ",
              Eng: "his stomach ends up totally empty",
            },
            { Kor: "그대로 지나치고 말았다   ", Eng: "ended up walking past" },
            {
              Kor: "중지되고 마는 바람에 ",
              Eng: "on account of it ending up being suspended/called off",
            },
            {
              Kor: "역사적인 인물이 되고 만다는 ",
              Eng: "[to the effect that one] will end up becoming a historical figure ...",
            },
            {
              Kor: "영달이는 픽 웃고 말았다   ",
              Eng: "Yŏngdal in the end just grinned ",
            },
            {
              Kor: "집어 던지고 만다 ",
              Eng: "ends up picking it out [of her mouth] and throwing it away",
            },
            {
              Kor: "잡아서 먹여 버리고 말았던 것이다 ",
              Eng: "he ended up killing it and feeding it to her",
            },
            {
              Kor: "푹 그 자리에 까무러치고 말았다   ",
              Eng: "[he] ended up fainting dead away right on the spot",
            },
            {
              Kor: "이웃 마을로 시집을 가고 말았다   ",
              Eng: "[T'ansil] ended up marrying [someone] from the neighboring village",
            },
            {
              Kor: "상대편을 찌르고 말듯한 절망",
              Eng: "a despair as if [she] might up and stab the other person",
            },
            {
              Kor: "칼을 풀어 놓고 말았다   ",
              Eng: "ended up taking off his sword ...",
            },
          ],
        },
        {
          entryName: "-고 만다2",
          typicalExample: "【그년 낮짝을 싹 후벼놓고 말까부다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The -고 form + auxiliary verb 마-ㄹ- showing the resolve or determination: sees an action through to completion. Sometimes translatable as  do it anyway (despite reluctance, procrastination or adverse circumstances). Typically used in conjunction with 'future'-type patterns",
          sentences: [
            {
              Kor: "그년 낮짝을 싹 후벼놓고 말까부다",
              Eng: "I'd love to take a swing at that mug of hers; or, why don't I just smack that bitch once in the kisser",
            },
            { Kor: "꼭 받고 말겠어요 ", Eng: "I must get [that money]" },
          ],
        },
        {
          entryName: "-고만 있다",
          typicalExample: "【지나가는 소리를 듣고만 있었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "All one is doing, the only thing one is in the process of doing is ... In other words, this is the progressive pattern in -고 있- reinforced or intensified by particle 만 just, only.",
          sentences: [
            {
              Kor: "지나가는 소리를 듣고만 있었다 ",
              Eng: "I was just listening to the passing sounds",
            },
            { Kor: "그저 듣고만 있었다 ", Eng: "[Yi] was just listening" },
          ],
        },
        {
          entryName: "-고 말겠다",
          typicalExample: "【꼭 받고 말겠어요 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "To show resolve or determination; sees an action through to completion. Come what may, I will ... Usually first-person.",
          sentences: [
            { Kor: "꼭 받고 말겠어요 ", Eng: "I must get [that money]" },
          ],
        },
        {
          entryName: "-고 말고(요)",
          typicalExample: "【나서고 말구요 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Also heard and seen (in dialogue) as -구 말구요. Of course, certainly, sure. Can attach to any base.",
          sentences: [
            { Kor: "나서고 말구요 ", Eng: "I'll certainly come forward" },
            {
              Kor: "지키고 말구요 ",
              Eng: "of course we will keep the promise",
            },
            { Kor: "사실이구 말구요 ", Eng: "of course it's the truth" },
            {
              Kor: "알아 주는 몰이꾼이구 말구요 ",
              Eng: "is certainly the most recognized hunter ...",
            },
            {
              Kor: "암, 헛살지 않았고 말고 ",
              Eng: "well, you certainly have not lived your life in vain (The 암 is shortened from 아무려면 of course, certainly, sure.)",
            },
          ],
        },
        {
          entryName: "-고 말까보다",
          typicalExample: "【그년 낮짝을 싹 후벼놓고 말까부다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The -고 마-ㄹ-2 pattern showing the resolve or determination; sees an action through to completion in combination with - (으)ㄹ까 보- I've a mind to ...",
          sentences: [
            {
              Kor: "그년 낮짝을 싹 후벼놓고 말까부다 ",
              Eng: "I'd love to take a swing at that mug of hers; or, why don't I just smack that once in the kisser",
            },
          ],
        },
        {
          entryName: "-고 뭐고 (간에)",
          typicalExample: "【변하고 뭐고 간에 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB-고 뭐고 (간에) you can say what you like about VERB and stuff, but ... ; VERB, schmerb ... The 간에can also show up in paired phrases of contrastive meaning, in which case it comes after the second of the pair: 읽든지 안 읽고 싶든지 (간에) 읽어야 돼 whether you want to read it or not, you have to.",
          sentences: [
            {
              Kor: "변하고 뭐고 간에 ",
              Eng: "change, schmange, whatever you want to call it [there was nothing]",
            },
            { Kor: "자식이고 계집이고 간에 ", Eng: "whether girl or woman..." },
          ],
        },
        {
          entryName: "-고 보니(까)",
          typicalExample: "【농사를 짓고 보니  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Does and then realizes (or considers or looks back on the matter).",
          sentences: [
            {
              Kor: "농사를 짓고 보니  ",
              Eng: "once they had tried their hand at farming",
            },
            {
              Kor: "또 중간 소작료인에게 긁히고 보니 ",
              Eng: "once we had been fleeced/shaken down yet again by the middleman",
            },
            {
              Kor: "이서방이라는 것을 알고 보니  ",
              Eng: "now that I realized that it was Mr. Yi ...",
            },
            { Kor: "그러고 보니  ", Eng: "and thus ...; or, but then ..." },
            {
              Kor: "알고 보니 ",
              Eng: "lit., he found out and then realized; he came to realize, he found out [that...]; he found out [through Pak Huiwan that] ...; what he learned was ... ",
            },
            {
              Kor: "어긋난 데서 시작된 여행이고 보니  ",
              Eng: "seeing as it was a journey that had started off wrong ...",
            },
            {
              Kor: "그러고 보니  문득 그가 낯설게 느껴진다.  ",
              Eng: "when I think about it like this, suddenly he feels strange to me ",
            },
            {
              Kor: "아닌게아니라 듣고 보니  ",
              Eng: "sure enough, after having listened [what he came to learn was] ...",
            },
          ],
        },
        {
          entryName: "-고 보면",
          typicalExample: "【이런 자연 풍광에 몸을 적시고 보면  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Similar to -다보면: if one does something over a period of time [and then steps back to reflect on it] ... ; if you do something for a while, then  take stock ... .",
          sentences: [
            {
              Kor: "이런 자연 풍광에 몸을 적시고 보면  ",
              Eng: "once one immerses one's body in nature's scenery ...",
            },
            {
              Kor: "그러나 알고 보면  사정은 아주 다르다",
              Eng: "but upon closer scrutiny, the situation is very different",
            },
            { Kor: "그러고 보면  ", Eng: "and then looking back ..." },
          ],
        },
        {
          entryName: "-고부터는 ",
          typicalExample: "【살기가 돌기 시작하고부터는 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Ever since ...-ing.",
          sentences: [
            {
              Kor: "살기가 돌기 시작하고부터는 ",
              Eng: "ever since the violence began to prevail ...",
            },
          ],
        },
        {
          entryName: "고사하고",
          typicalExample: "【밥은 고사하구 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN(은/는) 고사하고: forget NOUN (all I care about is...); who cares about (I just wish ...); let alone NOUN.",
          sentences: [{ Kor: "밥은 고사하구 ", Eng: "forget about food..." }],
        },
        {
          entryName: "-고서",
          typicalExample: "【중국인을 붙들고서  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Does and then; doing and then. The 서 after -고 makes explicit the and then meaning of -고, which can carry the meanings of simple concatenative and and and then. One also encounters -고서는 with the slight difference of more emphasis on the consequence: does and THEN.",
          sentences: [
            {
              Kor: "중국인을 붙들고서  ",
              Eng: "latched onto the Chinese man and then ...",
            },
            {
              Kor: "기회가 없고서  ",
              Eng: "there was no opportunity and so then (after that) ...",
            },
            {
              Kor: "몇만원 집어먹고서  ",
              Eng: "get several tens of thousands of wŏn and then ...",
            },
            {
              Kor: "일년에 작파하고서  ",
              Eng: "screwed off for a year and then ...",
            },
            {
              Kor: "다른 파출소로 옮아가고서  ",
              Eng: "was moved to a different police box and then ...",
            },
            {
              Kor: "안성기를 보고서  갑자기 가슴이 찌르르해지던 그때 기분 같기도 하다. ",
              Eng: "it is also like the feeling I had the time I saw An Sŏnggi and suddenly got butterflies in my stomach",
            },
            {
              Kor: "뒷짐을 지고서 ",
              Eng: "fold my hands behind my back, and then ...",
            },
            {
              Kor: "밥상을 받고서  ",
              Eng: "took the dinner table and then ...",
            },
            {
              Kor: "하씨를 대하고서  느꼈던 불안감쯤은 ",
              Eng: "the anxiety I had felt after coming into contact with Mr. Ha ...",
            },
          ],
        },
        {
          entryName: "-고서야 ",
          typicalExample: "【석 잔이나 비우고서야 일어났다 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Only after/only if; only under conditions such that ... ",
          sentences: [
            {
              Kor: "석 잔이나 비우고서야 일어났다 ",
              Eng: "only after emptying three glasses, she stood",
            },
          ],
        },
        {
          entryName: "-고 싶은 것을 이를 악문다",
          typicalExample: "【주저앉아버리고 싶은 것을 이를 악물며 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-고 싶은 것을 이를 악무-ㄹ-: resists the desire (urge) to ...",
          sentences: [
            {
              Kor: "주저앉아버리고 싶은 것을 이를 악물며 ",
              Eng: "fighting back through clenched teeth his desire to collapse ...",
            },
          ],
        },
        {
          entryName: "-고 싶은 것을 참는다",
          typicalExample: "【담배 피우고 싶은 것을 참을 때 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Resists/endures the urge to ...",
          sentences: [
            {
              Kor: "담배 피우고 싶은 것을 참을 때 ",
              Eng: "when he endured the urge to smoke...",
            },
          ],
        },
        {
          entryName: "-고 안하고도 없지요",
          typicalExample: "【제가 어떻게 걱정을 하고 안하고도 없지요 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "It's not a matter of whether or not ... .",
          sentences: [
            {
              Kor: "제가 어떻게 걱정을 하고 안하고도 없지요 ",
              Eng: "it's not a matter of worrying or not",
            },
          ],
        },
        {
          entryName: "-고야 말았다",
          typicalExample: "【진상을 알아내고야 말았다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The -고 form + auxiliary verb 말다 to show final development of an action: ended up doing, finally did, got around to doing, ultimately did, but reinforced with particle 야: the inevitable final result was ... ",
          sentences: [
            {
              Kor: "진상을 알아내고야 말았다",
              Eng: "ended up finding out the actual state of things ...",
            },
            {
              Kor: "과시 무거운 변이 마을을 흔들고야 말았다 ",
              Eng: "indeed, a calamity ended up taking its toll on the village",
            },
          ],
        },
        {
          entryName: "-고 있는데",
          typicalExample: "【냉장고에서 물을 꺼내 마시고 있는데】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-고 있는데: just at the juncture when one is in the middle of ...-ing, ... .",
          sentences: [
            {
              Kor: "냉장고에서 물을 꺼내 마시고 있는데",
              Eng: "just as I had taken out the water from the fridge to drink…",
            },
          ],
        },
        {
          entryName: "-고 있다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-고 있- : meaning is/continues in a (changed) state. E.g., 병원 에 입원 하고 있다 is in the hospital; 가고 없다 is gone.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-고자",
          typicalExample:
            "【책 하나를 번역하고자 한두 달 작정으로 폐사(廢寺)나 다름없는 환음사의 절방 하나를 차지하고 있었다. 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Wanting to; ready/prepared/willing to; intending to, going to.",
          sentences: [
            {
              Kor: "책 하나를 번역하고자 한두 달 작정으로 폐사(廢寺)나 다름없는 환음사의 절방 하나를 차지하고 있었다. ",
              Eng: "I was intending to translate a book, hence for a month or two I was occupying a room in Hwaneum Temple - more or less a dilapidated temple...",
            },
          ],
        },
        {
          entryName: "-고자 한다",
          typicalExample: "【나의 환상을 실제화시키고자 하는 욕망이었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Want to; be ready/prepared/willing to; intend to.",
          sentences: [
            {
              Kor: "나의 환상을 실제화시키고자 하는 욕망이었다 ",
              Eng: "it was my ambition to make my illusion a reality",
            },
          ],
        },
        {
          entryName: "-고 지다",
          typicalExample: "【보고지라】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Somewhat archaic and defective pattern meaning wishes/wants/desires to.",
          sentences: [{ Kor: "보고지라", Eng: "I want to see him ..." }],
        },
        {
          entryName: "-고 하-",
          typicalExample: "【사무실문을 밀치고 하는 것이다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB1-고 (plus VERB2-고) 하-: does [and stuff, and everything]. Note that 채만 식 uses this pattern frequently in his stories.",
          sentences: [
            {
              Kor: "사무실문을 밀치고 하는 것이다 ",
              Eng: "[they] push open the office doors [and stuff]",
            },
            {
              Kor: "무엇을 생각하고 어쩌고 하는 게 아니라",
              Eng: "It wasn't that I had something in mind or anything, but ...",
            },
            {
              Kor: "서울손님도 오고 했으니까 ",
              Eng: "since we have a visitor from Seoul and stuff ...",
            },
            {
              Kor: "내쉬고 하시었다 ",
              Eng: "she was exhaling [and stuff/and everything]",
            },
            {
              Kor: "뺨을 대보고 하는 것이 아닌가 ",
              Eng: "and wouldn't ya know it she's rubbing it against her cheek",
            },
            {
              Kor: "토끼 문안을 가고 하였다 ",
              Eng: "I was going to check up on the rabbit [and stuff]",
            },
            {
              Kor: "몸 보신시킬 형편도 못되고 하여 ",
              Eng: "didn't have the financial means to be able to get medical treatment [and stuff] ...",
            },
            {
              Kor: "모나던 성질이 둥그러지고 하여 ",
              Eng: "harsh dispositions usually crumble (fall down) and stuff ...",
            },
            {
              Kor: "구박을 하고 하여도, 좀처럼 맞서서 언성을 높여 탄하고 싸우고 하는 법이 없었다.",
              Eng: "Even when I was harsh on him/found fault with him, there was hardly any occasion where he'd raise his voice to argue back and such. ",
            },
            {
              Kor: "술대접이나 받고 하는 것은 ",
              Eng: "receiving gifts of liquor and stuff...",
            },
            {
              Kor: "물론 때리지 못하게 되었고 하니",
              Eng: "and of course now they weren't allowed to hit anyone and stuff ...",
            },
            {
              Kor: "신도 나는 줄을 모르겠고 하였다 ",
              Eng: "didn't consider/find it exciting and stuff ...",
            },
            {
              Kor: "안심한 얼굴로 대하고 하여야 할 것인데 ",
              Eng: "this need to treat to treat (people) with an easy-going face and stuff...",
            },
            {
              Kor: "삐라도 뿌려 주고 하는 것",
              Eng: "the thing of passing out hand bills and stuff ...",
            },
            {
              Kor: "시원시원 하여 주고 하여서 ",
              Eng: "he did them in a lively and accommodating way and stuff, and so ...",
            },
            {
              Kor: "생사람 죽이구 하긴 매일반였지",
              Eng: "and killing someone and stuff is all one and the same",
            },
            {
              Kor: "차리고 하였건만 ",
              Eng: "even though he maintained himself and stuff ... ",
            },
            {
              Kor: "양수를 뽑아내고 했던 검사에서도  ",
              Eng: "even on the test when they had extracted her amniotic waters",
            },
            {
              Kor: "쫑쫑대고 쌩동거리고 하던 ",
              Eng: "muttered on and on and was full of life...",
            },
            {
              Kor: "(아무리) 구박을 하고 하여도 ",
              Eng: "no matter how much she nagged and stuff ...",
            },
            {
              Kor: "턱에다 받쳐도 보고 하였다 ",
              Eng: "I also held him up against my chin and stuff",
            },
            {
              Kor: "급하긴 하고 해서 ",
              Eng: "it was indeed urgent [and stuff], so ...",
            },
          ],
        },
        {
          entryName: "-곤 ",
          typicalExample: "【호랑이 길을 찾아내곤】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contraction of -고는. Placing the 는 after -고 in -고는 forces the and [then]... reading of -고, as opposed to the and [also]... reading. In other words, it means when ... , ... then; upon doing, but emphasizing the consequence. ",
          sentences: [
            {
              Kor: "호랑이 길을 찾아내곤",
              Eng: "when I found the tiger's tracks, then ...",
            },
          ],
        },
        {
          entryName: "-곤 한다",
          typicalExample: "【가져도 안 가고 혼자 먹어 버리곤 하였다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The 'habitual pattern', abbreviated from -고는 하- makes a habit/regular practice (a regular thing) of doing; does it from time to time.",
          sentences: [
            {
              Kor: "가져도 안 가고 혼자 먹어 버리곤 하였다",
              Eng: "used to never take any [of the harvest to the field owner] over and would eat it all up by himself instead",
            },
            {
              Kor: "힘있게 부인하곤 하였다 ",
              Eng: "she would [always/routinely] deny this forcefully",
            },
            {
              Kor: "나의 감각 속에서 일어나곤 했었던 것이다 ",
              Eng: "[this strange phenomenon] arose from time to time in my perception",
            },
            {
              Kor: "아랫목 이불 속에 묻었다가 내주곤 했다 ",
              Eng: "would bury it for a while under a blanket covering the warm spot of the floor then give it to him",
            },
            {
              Kor: "숲과 골을 떠올리곤 했던 것이다 ",
              Eng: "I kept on conjuring up mountains and valleys",
            },
            {
              Kor: "얼음이 녹았다가 다시 얼곤 해서 ",
              Eng: "because ice would melt and then freeze again",
            },
            {
              Kor: "몰래 쥐어 주곤 했다 ",
              Eng: "she would sneak [cigarettes] into their hands",
            },
            {
              Kor: "별들로 바뀌어져 있는 것을 느끼곤 했었다 ",
              Eng: "I felt from time to time as if the [sounds of the frogs] had transformed into countless stars",
            },
            {
              Kor: "서 있곤 했었다 ",
              Eng: "made it a habit of standing / used to stand",
            },
            {
              Kor: "부두로 달음질치곤 했다 ",
              Eng: "would always rush out to the wharf",
            },
            {
              Kor: "김칫국 해서 먹이곤 했다 ",
              Eng: "would always feed (him) kimch'i soup or something",
            },
            {
              Kor: "그 다리는 흔들거리곤 했는데 ",
              Eng: "the bridge would sway ...",
            },
            {
              Kor: "독신생활을 그리곤 했다 ",
              Eng: "regularly yearned for the single life",
            },
            {
              Kor: "달리 할일이 없으면 화랑을 찾곤 했다 ",
              Eng: "I would regularly go to galleries when I had nothing else to do",
            },
            {
              Kor: "천천히 물러서곤 하였다 ",
              Eng: "[the wall] would slowly step back",
            },
            {
              Kor: "한 쌍의 학이 찾아오곤 하였었다 ",
              Eng: "a pair of cranes used to come visit",
            },
            {
              Kor: "학은 꼭 찾아오곤 하였었다 ",
              Eng: "the cranes would always visit",
            },
            {
              Kor: "그해 안에 시집을 가곤 하였다 ",
              Eng: "they would invariably get married within the year",
            },
            {
              Kor: "으례 큰 쪽을 봉네에게 주곤 하였다 ",
              Eng: "he would always gave the biggest portion to Pongne",
            },
            {
              Kor: "홱 돌아 들어가곤 하였다 ",
              Eng: "she would turn with a jerk and go back in",
            },
            {
              Kor: "떠나가곤 하는 것이었다 ",
              Eng: "they (as was their regular practice) headed off; they would leave",
            },
            {
              Kor: "물오리떼가 내려 앉았다가는 날아오르곤 했다",
              Eng: "a flock of ducks would land [on the field] and then take off again ...",
            },
            {
              Kor: "왕밤만을 골라 봉네를 주곤 하였다 ",
              Eng: "he would select and give her only the big chestnuts",
            },
          ],
        },
        {
          entryName: "~과 ... ~과 (~와 ... ~와)",
          typicalExample: "【노파와 나와의 인사 소개 】",
          categoryKorean: "조사",
          categoryEnglish: "(조사)",
          translation:
            "NOUN1과 NOUN2과 where modern Korean usually drops the second 과",
          sentences: [
            {
              Kor: "노파와 나와의 인사 소개 ",
              Eng: "the introduction between the old woman and myself",
            },
          ],
        },
        {
          entryName: "~과 더불어",
          typicalExample:
            "【초조와 더불어  연방 그런 구멍을 여새겨 보았었다.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN과/와 더불어: together with NOUN, along with NOUN.",
          sentences: [
            {
              Kor: "초조와 더불어  연방 그런 구멍을 여새겨 보았었다.",
              Eng: "Together with fretting, he visited the same imagination many times over.",
            },
            {
              Kor: "소리와 더불어  한 장한이 척 들어섰다.",
              Eng: "Together with the sound, a strapping fellow entered the room.",
            },
            {
              Kor: "일인(日人) 포수와 더불어  ",
              Eng: "together with a Japanese hunter ...",
            },
          ],
        },
        {
          entryName: "~과도 같다",
          typicalExample: "【뇌관을 가진 폭탄과도 같았다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN과도 같-: is even~also like/similar to NOUN; is the same as ... ; looks /just like ...",
          sentences: [
            {
              Kor: "뇌관을 가진 폭탄과도 같았다.  ",
              Eng: "It was also/even like a bomb with a detonation cap attached.",
            },
            {
              Kor: "그녀에게는 오랜 친구와도 같았다. ",
              Eng: "They were also like old friends to her.",
            },
            {
              Kor: "마치 땅에서 솟아난 한 포기의 나무와도 같은 느낌이다. ",
              Eng: "It feels just like a tree springing forth from the ground.",
            },
          ],
        },
        {
          entryName: "~과 동시에",
          typicalExample: "【찬탄과 동시에】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "At the same time as, simultaneously as, while/as doing; together with.",
          sentences: [
            {
              Kor: "찬탄과 동시에",
              Eng: "while I admired ...; at the same time as my admiration",
            },
          ],
        },
        {
          entryName: "~과의 NOUN2",
          typicalExample: "【윤호가 여자이이들과의 일을 이야기했을 때  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN1과의 NOUN2: NOUN2(s) with NOUN1. Particle 의 adnominalizes what precedes it to the noun following it (allows what precedes it to modify what follows it",
          sentences: [
            {
              Kor: "윤호가 여자이이들과의 일을 이야기했을 때  ",
              Eng: "When Yunho had spoken of matters with other girls…",
            },
          ],
        },
        {
          entryName: "~과 일반으로",
          typicalExample: "【마을에서 볼 때와 일반으로 멀다.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN과/와 일반으로: compared to NOUN, it is generally ... ; with no difference to NOUN.",
          sentences: [
            {
              Kor: "마을에서 볼 때와 일반으로 멀다.",
              Eng: "It was on the whole more distant than looking out from the village.",
            },
          ],
        },
        {
          entryName: "~과/와(는) 그 격이 다르다",
          typicalExample: "【빤빤한 사찰과는 그 격이 다르다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN과는 그 격이 다르다: is not in the manner of NOUN, is a style different from NOUN, is in a different league from NOUN.",
          sentences: [
            {
              Kor: "빤빤한 사찰과는 그 격이 다르다. ",
              Eng: "It gave off a different air from that of a ostentatious temple.",
            },
          ],
        },
        {
          entryName: "~과(는) 딴판으로",
          typicalExample: "【가깝게 보이던 것과는 딴판으로】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN과(는) 딴판으로: quite on the contrary to, completely different from, quite to. ",
          sentences: [
            {
              Kor: "가깝게 보이던 것과는 딴판으로",
              Eng: "quite different from the way it looked up close ...",
            },
          ],
        },
        {
          entryName: "-관",
          typicalExample: "【졸음 오는데, 님자 치우시관 】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            "P'yŏngan dialect morphology. Clearly some sort of imperative in -관, but not easily traced in standard reference works. ",
          sentences: [
            {
              Kor: "졸음 오는데, 님자 치우시관 ",
              Eng: "[I'm (lit., others are)] sleepy, you clear it out of the way",
            },
          ],
        },
        {
          entryName: "-구",
          typicalExample: "【입구  벗을 거면 】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation: "Colloquial/substandard version of -고",
          sentences: [
            {
              Kor: "입구  벗을 거면 ",
              Eng: "put on and take off ... (i.e., as long as you have clothes to wear ...)",
            },
            {
              Kor: "좋구  낮은 것 = 좋고 낮은 것 ",
              Eng: "things good and bad (humble, mean, low)",
            },
            {
              Kor: "똥도 밖에 가 누구  들어와요 ",
              Eng: "he also defecates outside before he comes in ...",
            },
            {
              Kor: '"생각 좀 해 보구 ." ',
              Eng: "I'll think about it. (lit.: After thinking about it a bit)",
            },
          ],
        },
        {
          entryName: "-구나",
          typicalExample: '【"어마! 그랬었구나." 】',
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "Apperceptive ending -군(요), -구나 with the meaning of 'first realization': Now I see/realize that ... !; Well I see...; Well so it is ...; So it is!; So it seems! ;Well I'll be (damned)!; Now that I think about it, I realize... (shows a sudden realization, confirmation, interest, delight, surprise, astonishment or insistence). ",
          sentences: [
            {
              Kor: '"어마! 그랬었구나." ',
              Eng: "Oh my goodness—so that's what happened!",
            },
          ],
        },
        {
          entryName: "-구나 생각한다 ",
          typicalExample: "【윤호에게는 어머니가 없었구나 생각했다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: '"QUOTE"구나 생각하-: Thinks to oneself, \'QUOTE-구나‘.',
          sentences: [
            {
              Kor: "윤호에게는 어머니가 없었구나 생각했다. ",
              Eng: "Ŭnhŭi thought to herself, 'Say, he never had a mother!'",
            },
          ],
        },
        {
          entryName: "-구나 싶다",
          typicalExample:
            "【그제서야 그 동안 그녀가 내게 꽤 살가웠구나 싶어진다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-구나 싶다, i.e., Plain Style (Internal) Quote + 싶- think to oneself that ... Note that, while the clause in front of 싶- looks like and feels like a quote, you can't put a quotative -고 after it.",
          sentences: [
            {
              Kor: "그제서야 그 동안 그녀가 내게 꽤 살가웠구나 싶어진다.  ",
              Eng: "It was only then that I realized how nice she’d been to me all this time.",
            },
          ],
        },
        {
          entryName: "-구두 ",
          typicalExample: "【긁어 먹구두 】",
          categoryKorean: "Colloquial ",
          categoryEnglish: "(Colloquial )",
          translation:
            "Colloquial/substandard version of -고도, more or less equivalent to -어도, but with the nuance of even after doing, even after having done; and yet, but, while, though.",
          sentences: [
            {
              Kor: "긁어 먹구두 ",
              Eng: "even though [he] squeezes people ...",
            },
            { Kor: "그리구두", Eng: "and even also..." },
          ],
        },
        {
          entryName: "-구려 ",
          typicalExample: "【한잔 내 오구려  】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            'Interchangeable with -구료 to make a gentle or cajoling command, often with honorific -(으)시-. Tacked on to endings in the Familiar (하네), Semi-Formal (하오) and Formal (합니다) Styles, this can also be used to make an "apperceptive" of First Realization form akin to the endings in -구나, -구먼요, etc. ',
          sentences: [
            { Kor: "한잔 내 오구려  ", Eng: "bring out a drink for us" },
            {
              Kor: "순사한테두 맘 못 놓겠구려  ",
              Eng: "We won't be able to relax with these constables",
            },
            {
              Kor: "예서 하룻밤 푹 묵었다 가시구려 ? ",
              Eng: "Why don't you guys stay the night and take a rest before you go?",
            },
            {
              Kor: "외나무대리서 만났구려 ? ",
              Eng: "So we’ve met again on this log bridge; or, so we meet again",
            },
          ],
        },
        {
          entryName: "-구료 ",
          typicalExample: "【아가씨 신세 가엾구료 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Interchangeable with -구려 to make a gentle or cajoling command, often with honorific -(으)시-. Also used to make a (standard) Familiar Style apperceptive (equivalent to -구나, -구먼요, etc. in other styles).",
          sentences: [
            {
              Kor: "아가씨 신세 가엾구료 ",
              Eng: "the girl's situation was pitiful, indeed",
            },
          ],
        },
        {
          entryName: "-구마",
          typicalExample: "【동행이 되겠구마 】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            "Kyŏngsang-do (경상도) dialect equivalent of -구먼(요), where -구먼(요) is a variant form of the First Realization pattern in -군(요), -구나 Well I see... ; Well so it is ... ; So it is!; So it seems!; Well I'll be (damned)! (shows a sudden realization, confirmation, interest, delight, surprise, astonishment or insistence).",
          sentences: [
            {
              Kor: "동행이 되겠구마 ",
              Eng: "Well, then, I guess that would make us fellow travelers!",
            },
            { Kor: "반갑구마", Eng: "My, my, what a pleasure!" },
          ],
        },
        {
          entryName: "-구만(요)",
          typicalExample: "【거기두 줬겠구만 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            'First Realization surprise form (called "apperceptives"by Samuel Martin). These translate as well I see... ; well so it is ... ; so it is!; so it seems!; well I\'ll be (damned)! (showing a sudden realization, confirmation, interest, delight, surprise, astonishment or insistence).',
          sentences: [
            {
              Kor: "거기두 줬겠구만 ",
              Eng: "she must have put out for you too",
            },
          ],
        },
        {
          entryName: "-구말구(요)",
          typicalExample: "【행복했었구말구】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation:
            "Colloquial or sub-standard equivalent to -고말고 it is needless to say that ... ; there is no doubt about it that ... ; it is a matter of course that ... ; of course ... !",
          sentences: [
            {
              Kor: "행복했었구말구",
              Eng: "you bet I was happy; or, sure I was happy",
            },
            { Kor: "그럼 그렇구말구", Eng: "yes, of course—you bet!" },
          ],
        },
        {
          entryName: "-구먼(요)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            'First Realization surprise form (called "apperceptives"by Samuel Martin). These translate as well I see... ; well so it is ... ; so it is!; so it seems!; well I\'ll be (damned)! (showing a sudden realization, confirmation, interest, delight, surprise, astonishment or insistence).',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-구서리",
          typicalExample: "【한 그릇으 멕이거든 고마운 줄 모르구서리 】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation: "Northeastern dialect equivalent of -고서.",
          sentences: [
            {
              Kor: "한 그릇으 멕이거든 고마운 줄 모르구서리 ",
              Eng: "doesn't know how to be thankful for the bowl that fed him (With 멕이거든 as dialect for 먹이거든 and 모르구서리 for 모르고서.)",
            },
          ],
        },
        {
          entryName: "-구서야",
          typicalExample: "【심청머리가 그러구서야】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation:
            "Colloquial or sub-standard equivalent to -고서야 only after/only if; only under conditions where ... ",
          sentences: [
            {
              Kor: "심청머리가 그러구서야",
              Eng: "only this perverse kind of thinking ...",
            },
          ],
        },
        {
          entryName: "-구야",
          typicalExample: "【잡아가구 말구야 】",
          categoryKorean: "Dialect morphology/Substandard ",
          categoryEnglish: "(Dialect morphology/Substandard )",
          translation:
            "Dialect/substandard equivalent of -고야, where an entire phrase ending in –고 is treated as a noun phrase and takes particle (이)야: [A-고 B-고]야 a matter of ...-ing or not.",
          sentences: [
            {
              Kor: "잡아가구 말구야 ",
              Eng: "To think that they can stand there and discuss whether or not to come after me?",
            },
          ],
        },
        {
          entryName: "-구야 만다 ",
          typicalExample: "【뀌구야 말아요 】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            "The -고 form (here raised to -구 in dialect or substandard speech) + auxiliary verb 말다 (1) to show final development of an action: ends up doing, finally does, gets around to doing, ultimately does; or  (2) does it anyway (despite reluctance or procrastination). Here the particle 야 intensifies the meaning: does it all the way ( = really does it); simply has (got) to do it; ends in doing; finally does; ends or winds up by doing.",
          sentences: [
            {
              Kor: "뀌구야 말아요 ",
              Eng: "[once you've been caught by me/fallen into my clutches,] you have no choice but to lend it to me",
            },
          ],
        },
        {
          entryName: "-구프다",
          typicalExample: "【입구퍼 했어 = 입고파 했어 = 입고 싶어 했어】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation:
            "A colloquial/sub-standard abbreviation from -고 싶다 (싶어한다) I want (you want, he wants) to do. Note that some varieties of Korean restructure verb bases with final aspirates like 같다, 싶다, etc., as vowel bases: 가트다, 싶으다, etc.",
          sentences: [
            {
              Kor: "입구퍼 했어 = 입고파 했어 = 입고 싶어 했어",
              Eng: "he wanted to wear…",
            },
          ],
        },
        {
          entryName: "-구 한다",
          typicalExample: "【팔구 팔구 하면 】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation: "Colloquial/substandard for -고 한다.",
          sentences: [
            {
              Kor: "팔구 팔구 하면 ",
              Eng: "if we sell them again and again ...",
            },
          ],
        },
        {
          entryName: "-군(요)",
          typicalExample: "【그렇군 꼭 서른 여섯 해째군 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            'First Realization ("apperceptive") in -군(요), -구먼, -구나, -군요, -구려, etc. It attaches a) as an inflectional ending to the copula (-이다), adjective bases, the verbs 있- and 없- as well as the past -었- and future -겠-, and the retrospective -더- b) as a postmodifier following processive -는. Note that the copula optionally takes an irregular shape before this ending: -이로-. These translate as Well I see...; Well so it is ...; So it is!; So it seems!; Well I\'ll be (damned)! (showing a sudden realization, confirmation, interest, delight, surprise, astonishment or insistence).',
          sentences: [
            {
              Kor: "그렇군 꼭 서른 여섯 해째군 ",
              Eng: "that's right, it's the thirty-sixth year",
            },
            {
              Kor: "“이건, 불랙 홀이 아니라 화이트 홀이군.”",
              Eng: "So, [I see]—this isn't not a black hole, but a white hole",
            },
          ],
        },
        {
          entryName: "-군 한다",
          typicalExample:
            "【그이는 앞사람들처럼 하룻밤을 지내구 떠나가군 했어요 】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation:
            "Colloquial/sub-standard version of the habitual pattern in -곤 하다, abbreviated from -고는 하다 makes a habit/regular practice (a regular thing) of doing it; does it from time to time...",
          sentences: [
            {
              Kor: "그이는 앞사람들처럼 하룻밤을 지내구 떠나가군 했어요 ",
              Eng: "he, like those who were before him, would stay one night and leave ...",
            },
          ],
        },
        {
          entryName: "그",
          typicalExample: "【그 무엇도 보이지 않았다.  】",
          categoryKorean: "Emphatic",
          categoryEnglish: "(Emphatic)",
          translation:
            "In literary contexts, a 그 in front of an interrogative/indefinite makes the whole construction more emphatic, as well as more fancy/literary. ",
          sentences: [
            {
              Kor: "그 무엇도 보이지 않았다.  ",
              Eng: "She could see nothing at all ",
            },
            {
              Kor: "달의 가장자리, 그 어느 어름에선가 흰 줄기가 ... ",
              Eng: "From somewhere along the halo around the moon, from some juncture, a white rope ... ",
            },
          ],
        },
        {
          entryName: "그 ... ~도",
          typicalExample: "【아무 것도, 그 무엇도 하려 하지 않는 둘째 아이.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "그 무엇도 is a fancy literary equivalent reprising and emphasizing the 아무 것도. ",
          sentences: [
            {
              Kor: "아무 것도, 그 무엇도 하려 하지 않는 둘째 아이.",
              Eng: "The second child, who never did anything, not anything.",
            },
          ],
        },
        {
          entryName: "그다지",
          typicalExample: "【그다지  그리운 것이 아니었다 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 그다지 + Neg. [not] to that extent (degree), like that, in that way, so (much).",
          sentences: [
            {
              Kor: "그다지  그리운 것이 아니었다 ",
              Eng: "he didn't miss [their faces] so much",
            },
            {
              Kor: "그다지  드문 경우가 아닙니다.  ",
              Eng: "It's not such a rare thing/case.",
            },
            {
              Kor: "그것은 내 예상에서 그다지  빗나가지 않은 아내의 속마음이었다.  ",
              Eng: "My prediction about my wife's inner feelings wasn't far off the mark. ",
            },
          ],
        },
        {
          entryName: "그대로 ",
          typicalExample: "【어제 입은 그대로다 】",
          categoryKorean: "Postmodifier",
          categoryEnglish: "(Postmodifier)",
          translation:
            "As postmodifier: like that, as it is/stands, intact thus, that way, as things stand/are.",
          sentences: [
            {
              Kor: "어제 입은 그대로다 ",
              Eng: "[his clothes] were just as he had worn yesterday",
            },
          ],
        },
        {
          entryName: "그따위 ",
          typicalExample: "【그따위 주변에 】",
          categoryKorean: "Modifer",
          categoryEnglish: "(Modifer)",
          translation:
            "Derogatory adnominal modifier. A thing or person of that sort, such a one, that kind of, that sort of.",
          sentences: [
            {
              Kor: "그따위 주변에 ",
              Eng: "that kind of [pathetic] resourcefulness ...",
            },
          ],
        },
        {
          entryName: "그래",
          typicalExample: "【그래 그런지 =그래서 그런지 】",
          categoryKorean: "Abbreviation",
          categoryEnglish: "(Abbreviation)",
          translation: "Abbreviated from 그래서.",
          sentences: [
            {
              Kor: "그래 그런지 =그래서 그런지 ",
              Eng: "perhaps that is why; maybe this was why",
            },
          ],
        },
        {
          entryName: "그러다도",
          typicalExample: "【그러다도 = 그러다가도】",
          categoryKorean: "Abbreviation",
          categoryEnglish: "(Abbreviation)",
          translation: "Abbreviated from 그러다가도 even after ...-ing. ",
          sentences: [
            {
              Kor: "그러다도 = 그러다가도",
              Eng: "even after carrying on like this for a while ...",
            },
          ],
        },
        {
          entryName: "그러던",
          typicalExample: "【그러던 그들은 】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "Retrospective modifier form of 그러- do so, do like that. Sometimes best translated sentence-initially as Meanwhile; while having done.",
          sentences: [
            {
              Kor: "그러던 그들은 ",
              Eng: "Meanwhile … (lit.: They, who were carrying on like that) ",
            },
          ],
        },
        {
          entryName: "그렇게 ...-ㄹ 수가 없-",
          typicalExample: "【마음이 그렇게 평안해질 수가 없었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Similar in effect to 여간 + NEGATIVE (or implied negative): very; not a little, to no small extent, quite, extremely, terribly.",
          sentences: [
            {
              Kor: "마음이 그렇게 평안해질 수가 없었다 ",
              Eng: "my mind was very much / ever so eased",
            },
            {
              Kor: "그렇게 마음에 들 수가 없었다 ",
              Eng: "it couldn't have pleased me more",
            },
            {
              Kor: "술 맛이 그렇게 달고 부드러울 수가 없었다 ",
              Eng: "[Mr. Yang's] pace was extremely fast",
            },
            {
              Kor: "그렇게 근사해 보일 수가 없었다 ",
              Eng: "looked so wonderful",
            },
            {
              Kor: "반점이 그렇게 선명할 수 없었다 ",
              Eng: "his spots were extremely clear",
            },
            { Kor: "그렇게 달 수가 없었다 ", Eng: "couldn't be that sweet" },
            {
              Kor: "그렇게 아득하게 여겨질 수가 없었던 것이다 ",
              Eng: "seemed extremely distant",
            },
          ],
        },
        {
          entryName: "-그려",
          typicalExample: "【취했네그려 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            'Tacked on to endings in the Familiar (하네), Semi-Formal (하오) and Formal (합니다) Styles, this ending makes an "apperceptive" or First Realization form akin to the endings in -구나, -구먼요, etc. ',
          sentences: [
            { Kor: "취했네그려 ", Eng: "say, [this guy] is drunk" },
            { Kor: "그대가 나를 웃겼소그려  ", Eng: "you made me laugh" },
            {
              Kor: "평생 병 걸릴 염려는 없겠습니다, 그려  ",
              Eng: "You wouldn't have to [worry about getting sick your whole life]!",
            },
          ],
        },
        {
          entryName: "그만1",
          typicalExample: "【그만  사랑하게 돼서 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 그만 presages some eventual or inevitable development, which is often indicated with -고 말았다 or -게 되-.",
          sentences: [
            { Kor: "그만  사랑하게 돼서 ", Eng: "I ended up loving him ..." },
            {
              Kor: "그만  어떤 일본 사람 가게 앞에서 낚싯대 도적으로 붙들린 것이었다 ",
              Eng: "ended up getting himself caught for stealing a fishing rod from the front of a shop owned by a Japanese person",
            },
          ],
        },
        {
          entryName: "그만2",
          typicalExample: "【밖에서까지 그만  마시라는 잔소리 들어 가며 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "그만 + VERB: stops VERBing; gives up VERBing. ",
          sentences: [
            {
              Kor: "밖에서까지 그만  마시라는 잔소리 들어 가며 ",
              Eng: "...to have to listen to her nagging me to stop drinking when we were out...",
            },
          ],
        },
        {
          entryName: "그만이다",
          typicalExample: "【비위 좋게 생각하면 그만이었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The best option is to ... ; ... -ing is the best option; the best way to go is ...-ing. The expression 그만이- means  the best (in the world), the most, tops, superb.",
          sentences: [
            {
              Kor: "비위 좋게 생각하면 그만이었다 ",
              Eng: "it was best to just think of it in a positive light",
            },
          ],
        },
        {
          entryName: "그토록 ",
          typicalExample: "【그토록 절절히 고통에의 열망에 젖을 때가 있을까.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "To such an extent, presumably from 그하-도록 (not normally used) with projective -도록. ",
          sentences: [
            {
              Kor: "그토록 절절히 고통에의 열망에 젖을 때가 있을까.",
              Eng: "Could there have ever been a time when one was so infused with such fervent longing for pain?",
            },
          ],
        },
        {
          entryName: "근 ...-(이)나",
          typicalExample: "【근 이십 가구나 되던 마을이 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "근(近) plus NUMERAL reinforced by -(이)나: about, around, approximately. ",
          sentences: [
            {
              Kor: "근 이십 가구나 되던 마을이 ",
              Eng: "a village of about twenty households or so; a village of some twenty households; a village that used to consist of some twenty households",
            },
          ],
        },
        {
          entryName: "-기",
          typicalExample: "【\"'남편 기 살리기' 라나 봐.\" 】",
          categoryKorean: "Nominalizer/Summative",
          categoryEnglish: "(Nominalizer/Summative)",
          translation:
            "Nominalizer/Summative -기 the act of, the fact of/that; ...-ing (as immediate subject or object, turns the preceding phrase into one big noun). ",
          sentences: [
            {
              Kor: "\"'남편 기 살리기' 라나 봐.\" ",
              Eng: "\"I think it was something like 'Reviving your Husband's Morale'.\"",
            },
            {
              Kor: "“백 일 동안의 고국 바로 알기”  ",
              Eng: '"A correct understanding of the homeland in 100 days"',
            },
          ],
        },
        {
          entryName: "-기 TIME을 앞두고 ",
          typicalExample: "【이서방이 나타나기 십여 일을 앞두고 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "TIME before ...ing; with TIME to go before ...-ing.",
          sentences: [
            {
              Kor: "이서방이 나타나기 십여 일을 앞두고 ",
              Eng: "about ten days before Mr. Yi appeared ...",
            },
          ],
        },
        {
          entryName: "-기(가)",
          typicalExample: "【대신 하여 주기가 성가시기는 하였으나】",
          categoryKorean: "Nominalizer/Summative",
          categoryEnglish: "(Nominalizer/Summative)",
          translation:
            "Summative -기: the act of; the fact of/that; to do or be; that it does or is. Here with the 가 particle as a subject.",
          sentences: [
            {
              Kor: "대신 하여 주기가 성가시기는 하였으나",
              Eng: "doing it for him as a favor [was annoying alright but ...]",
            },
          ],
        },
        {
          entryName: "-기(가) 거북하다",
          typicalExample: "【한동안 숨쉬기가 거북했다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Feel awkward or cumbersome to do. ",
          sentences: [
            {
              Kor: "한동안 숨쉬기가 거북했다.  ",
              Eng: "For a while, it was difficult to breathe.",
            },
          ],
        },
        {
          entryName: "-기(가) 두렵다",
          typicalExample: "【눈을 뜨기가 두려운 햇살 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Be afraid to ...; is scared of ...-ing...",
          sentences: [
            {
              Kor: "눈을 뜨기가 두려운 햇살 ",
              Eng: "sunbeams that made one fearful to open one's eyes",
            },
            {
              Kor: "학을 쳐다보기가 두려운 듯 ",
              Eng: "as if scared of watching the cranes ...",
            },
          ],
        },
        {
          entryName: "-기(가) 말할 수 없다",
          typicalExample: "【복잡하기가 말할 수 없네 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Be incredibly/unbelievably ...",
          sentences: [
            {
              Kor: "복잡하기가 말할 수 없네 ",
              Eng: "[the area] is incredibly busy",
            },
          ],
        },
        {
          entryName: "-기(가) 뭣하다",
          typicalExample: "【드러내놓고 화를 내기도 뭣해서 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "It seems or one feels awkward to... ; it seems like not the right thing to do.",
          sentences: [
            {
              Kor: "드러내놓고 화를 내기도 뭣해서 ",
              Eng: "it didn't seem right to get all indignant at him...; or, because it was awkward to reveal his anger ...",
            },
          ],
        },
        {
          entryName: "-기(가) 바쁘다",
          typicalExample: "【그러나 요즘은 살림하고 아이들 키우기 바빠서 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "1) it is difficult/hard to ... ; 2) one is busy ...-ing.",
          sentences: [
            {
              Kor: "그러나 요즘은 살림하고 아이들 키우기 바빠서 ",
              Eng: "but lately she was so busy keeping house and raising the kids that ...",
            },
          ],
        },
        {
          entryName: "-기(가) 아쉽다",
          typicalExample: "【넘기기가 아쉬웠다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "One feels reluctant to do it, one feels sorry to do it, one feels put out or inconvenienced by having to do it.",
          sentences: [
            {
              Kor: "넘기기가 아쉬웠다 ",
              Eng: "he felt sorry/reluctant to swallow it",
            },
          ],
        },
        {
          entryName: "-기(가) 이를 데 없다",
          typicalExample: "【을씨년스럽기 이를 데 없다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Be indescribably/incredibly ... ",
          sentences: [
            {
              Kor: "을씨년스럽기 이를 데 없다 ",
              Eng: "there was no explaining his shabbiness ...",
            },
          ],
        },
        {
          entryName: "-기(가) 일이다",
          typicalExample: "【매맞기가 일이요 하였다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Make a habit or regular practice of ...-ing.",
          sentences: [
            {
              Kor: "매맞기가 일이요 하였다 ",
              Eng: "made a habit of getting beaten ...",
            },
          ],
        },
        {
          entryName: "-기 까닭에 ",
          typicalExample: "【그렇기 까닭에 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Same as -기 때문에: because (but much rarer).",
          sentences: [{ Kor: "그렇기 까닭에 ", Eng: "because of this ..." }],
        },
        {
          entryName: "-기까지 ",
          typicalExample: "【대형 편의점으로 만들기까지  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Until (even) [the point when] it does; even to go as far as to.",
          sentences: [
            {
              Kor: "대형 편의점으로 만들기까지  ",
              Eng: "until he had built it into a large-scale convenience store",
            },
            {
              Kor: "그 아이가 자라 할아버지를 찾게 되기까지   ",
              Eng: "until the child grew up and looked for its grandfather",
            },
            {
              Kor: "흘겨보기까지  하였다 ",
              Eng: "[they] even went as far as looking sideways with disdain at him ...",
            },
            {
              Kor: "홍파동으로 이사해 오기까지  ",
              Eng: "until the time when he moved to Hongp'adong ...",
            },
            { Kor: "얻어맞기까지  했다 ", Eng: "he even got smacked" },
            {
              Kor: "갈꽃이 아주 뵈지 않게 되기까지  그대로 서 있었다 ",
              Eng: "he kept standing there until ['it came about that'] the reed flowers were no longer visible at all",
            },
            {
              Kor: "얼굴이 은은히 빛나 보이기까지  했다 ",
              Eng: "it was to a point that her face appeared to glow ever so subtly ",
            },
            {
              Kor: "자기까지 는 안하나 ",
              Eng: "he doesn't go so far as to sleep [there], but ...",
            },
          ],
        },
        {
          entryName: "-기까지 한다",
          typicalExample:
            "【난 당신 포기했어, 라고 스스로 공언하기까지 했다.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Does even ...-ing, even ...-es.",
          sentences: [
            {
              Kor: "난 당신 포기했어, 라고 스스로 공언하기까지 했다.",
              Eng: "She herself even went so far as to pronounce once, 'I've given up on you.'",
            },
          ],
        },
        {
          entryName: "-기 그지 없다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "[Usage]",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-기나 한다",
          typicalExample: "【잘 닦아오기나 해 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "At least does; just does (nothing more than) ← summative -기 + pseudo-particle -(이)나 or the like, like, or something, or so; the likes of, any; at least, anyway, just (used in gently/vaguely urging), even if it is not of particular interest.",
          sentences: [
            {
              Kor: "잘 닦아오기나 해 ",
              Eng: "just do a good job polishing them",
            },
            {
              Kor: "서울서는 이런 개울물을 보지 못하기나 한듯이 ",
              Eng: "as if she had never seen a stream like this in Seoul (or something)",
            },
          ],
        },
        {
          entryName: "-기는",
          typicalExample: "【정보를 얻기는  크게 어려운 일은 아닙니다. 】",
          categoryKorean: "Nominalizer/Summative",
          categoryEnglish: "(Nominalizer/Summative)",
          translation: "As for doing/being [as topic].",
          sentences: [
            {
              Kor: "정보를 얻기는  크게 어려운 일은 아닙니다. ",
              Eng: "As for gathering information, it's not such a difficult thing to do.",
            },
            {
              Kor: "그러나 가장 즐겁기는  젊은이들이었다. ",
              Eng: "The most joyful people were the youngsters. (lit.: But as far as being the most joyful, it was the youngsters)",
            },
            {
              Kor: "술대접을 받기는  실로 부지기수였다. ",
              Eng: "As for receiving gifts of liquor, there were countless occasions.",
            },
            {
              Kor: "집에 들어가기는  너무 이른 시간이었다.",
              Eng: "[As for] the time, it was too early to return home.",
            },
            {
              Kor: "그러나 영문을 모르긴 마찬가지였다. ",
              Eng: "But I was still just as ignorant of the situation as before.",
            },
            { Kor: "알기는  알면서도 ", Eng: "even though he did know ..." },
          ],
        },
        {
          entryName: "-기는(요)",
          typicalExample: "【칠칠맞기는 . 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "VERB-기는, i.e., nominalizer -기 followed by topic particle 는 to set up a topic—speaking of VERB-ing ... ; when it comes to VERB-ing ... ; as for VERB-ing ... .",
          sentences: [
            { Kor: "칠칠맞기는 . ", Eng: "Talk about sloppy. " },
            {
              Kor: "살아 있기는  살아 있을까? ",
              Eng: "But are they alive?; or, But do you suppose they're really alive?",
            },
          ],
        },
        {
          entryName: "-기는 하다",
          typicalExample: "【남편이긴 하지만】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB1-기는 VERB1 or -기는 하-: Does all right (it is true) but; is indeed (I must admit) but.",
          sentences: [
            {
              Kor: "남편이긴 하지만",
              Eng: "[She's right—] I'm her husband alright, but ...",
            },
            {
              Kor: "내가 가정적이지 못한 것이 불만이긴 하겠지만 ",
              Eng: "that I wasn't much of a family man was a source of dissatisfaction, true enough",
            },
            { Kor: "좋긴 하지만요. ", Eng: "It is nice, though ..." },
            {
              Kor: "식탁에 앉기는 했지만",
              Eng: "sit at the table I did, but ...",
            },
            {
              Kor: "관형어(冠刑語)가 있기는 하지만 ",
              Eng: "it is indeed a pre-noun, but ...",
            },
            {
              Kor: "기대가 있기는 하였으나 ",
              Eng: "it's true that he had expectations, but ...",
            },
            {
              Kor: "칼을 풀어 놓기는 하였었다 ",
              Eng: "took off his sword, but ...",
            },
            {
              Kor: "성가시기는 하였으나 ",
              Eng: "was annoying alright but ...",
            },
            { Kor: "좀 멀긴 하지만 ", Eng: "it is a bit far, but ..." },
            { Kor: "아프긴 하지만 ", Eng: "despite the pain ..." },
            {
              Kor: "몇 술 뜨기는 했으나 ",
              Eng: 'had a few mouthfuls of "breakfast", but… (lit.: lifted a few spoonfuls of "breakfast" (liquor) [it is true], but…)',
            },
            {
              Kor: "편지가 한번 있기는 했으나 ",
              Eng: "she had sent one letter but ...",
            },
            {
              Kor: "나이 차이가 좀 많아 보이기는 하지만 ",
              Eng: "of course it seemed that was a big difference in age, but",
            },
            {
              Kor: "사실 초라하기는 했던 것이다. ",
              Eng: "It was for a fact that he was shabby, but ...",
            },
            {
              Kor: "아흔 아홉 번만에 못 캐었대도 이상하달 것은 없는 그 노릇이기는 하였다.",
              Eng: "I admit that if I heard he had failed even after ninety-nine attempts, I wouldn't be surprised/there'd be nothing strange about it.",
            },
            {
              Kor: "고급 시계이긴 하나 ",
              Eng: "it is indeed a high-quality clock, but ....",
            },
            {
              Kor: "아니 그것이 필요하기나 했었을까? ",
              Eng: "Is that even necessary?",
            },
            {
              Kor: "약기는 한 안초시는 ",
              Eng: "Mr. An, who was indeed a shrewd fellow (the same pattern in modified version is converted from 안초시는 약기는 했다)",
            },
          ],
        },
        {
          entryName: "-기도 전부터",
          typicalExample: "【또 그의 아버지가 나기도 전부터라 했다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Since even before ...-ing.",
          sentences: [
            {
              Kor: "또 그의 아버지가 나기도 전부터라 했다. ",
              Eng: "Or, some said it was since even before his father was born.",
            },
          ],
        },
        {
          entryName: "-기도 전에",
          typicalExample: "【그들이 무슨 말을 걸기도 전에 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Before even doing it.",
          sentences: [
            {
              Kor: "그들이 무슨 말을 걸기도 전에 ",
              Eng: "but before they even uttered a word ...",
            },
            {
              Kor: "미처 무슨 말을 찾기도 전에 ",
              Eng: "before [he] could even find a word [to say] ...",
            },
          ],
        },
        {
          entryName: "-기도 하다1",
          typicalExample: "【경망스럽기도 하겠지만 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Summative -기 plus particle 도: -기도 하- doing; being also; even also does; is also does; is indeed (really); and, also. Substitutes for the repeat of the verb or adjective, i.e., 춥기도 하다 = 춥기도 춥다 is really cold; sure is cold.",
          sentences: [
            {
              Kor: "경망스럽기도 하겠지만 ",
              Eng: "would be careless indeed, but ...",
            },
          ],
        },
        {
          entryName: "-기도 하다2",
          typicalExample:
            "【가슴이 아픈 것 같기도 하고 화가 난 것 같기도 하고 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Summative -기 plus particle 도: -기도 하- does/is both A and B, e.g. 좋기도 하고 나쁘기도 하다, where both actions or states are explicitly listed.",
          sentences: [
            {
              Kor: "가슴이 아픈 것 같기도 하고 화가 난 것 같기도 하고 ",
              Eng: "my chest seemed to hurt and I also seemed to be angry",
            },
            {
              Kor: "아이같기도 하고 할머니 같기도 하고 ",
              Eng: "is both like a kid and also like a grandmother",
            },
            {
              Kor: "어머니며 누나이며 애인이기도 한 ",
              Eng: "a mother, sister and lover all in one",
            },
          ],
        },
        {
          entryName: "-기도 하다3",
          typicalExample: "【벌기도 하는 게지 = 벌기도 하는 것이지 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Summative -기 plus particle 도: -기도 하- is/was also the case that ... More often than not, the parallel action or state implied by this pattern is not listed/mentioned explicitly.",
          sentences: [
            {
              Kor: "벌기도 하는 게지 = 벌기도 하는 것이지 ",
              Eng: "lit.: it is the case that guys [who have had the experience of losing (spilling) money can] also make some, too",
            },
            {
              Kor: "남몰래 세어보기도 수십번을 했다.",
              Eng: "He secretly counted his money several dozens of times. ",
            },
            {
              Kor: "어떤 이는 짜증을 내기도 했지만 ",
              Eng: "there were those who would get all irritated, but… ",
            },
            {
              Kor: "목교를 건너오기도 했다. ",
              Eng: "[Some also] crossed the wooden bridge.",
            },
            { Kor: "빛나기도 했고 ", Eng: "it also sparkled ..." },
            {
              Kor: "머리속에 떠오르기도 했지만 ",
              Eng: "[the image] did in fact come to mind, but… ",
            },
            {
              Kor: "대개는 손님편에서 가격을 말해주기도 하는데 ",
              Eng: "the customers would usually also tell her the price…",
            },
            {
              Kor: "가끔 거리 쪽을 바라보기도 하였다.",
              Eng: "Sometimes (she) would even also peek in the direction of the street.",
            },
            {
              Kor: "조용하기도 했던 것이다. ",
              Eng: "It was something that had also made it quiet. ",
            },
            {
              Kor: "두손으로 껴안기도 하며 ",
              Eng: "while others were clasping [their bags] with two hands ...",
            },
            {
              Kor: "뱅뱅 돌리기도 하며 ",
              Eng: "swinging [their bookbags] around ...",
            },
            {
              Kor: "반갑기도 한 듯 ",
              Eng: "and also seemed glad to see him ...",
            },
            {
              Kor: "먹기도 하고 하였다. ",
              Eng: "…and he would receive and stuff like that.",
            },
            {
              Kor: "낮잠 때문이기도 하였다. ",
              Eng: "It was also because of my nap.",
            },
            {
              Kor: "내 간절한 믿음이기도 했다. ",
              Eng: "It was also an earnest belief.",
            },
            {
              Kor: "가장 큰 소리이기도 하였다. ",
              Eng: "It was also the loudest sound.",
            },
            {
              Kor: "아주 버릴 수는 없는 희망이기도 하였다. ",
              Eng: "It was also [a source of] hope that they could not give up completely.",
            },
          ],
        },
        {
          entryName: "-기두 하다 ",
          typicalExample: "【야, 그놈의 눈송이 탐스럽기두 하다 】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation:
            "Colloquial/substandard for -기도 하다 does/is indeed (really), and/also (substitutes for the repeat of the verb or adjective).",
          sentences: [
            {
              Kor: "야, 그놈의 눈송이 탐스럽기두 하다 ",
              Eng: "Say, these snowflakes are really something ...",
            },
          ],
        },
        {
          entryName: "-기 때문이다 ",
          typicalExample: "【우리가 알고 있는 사람에 한하는 것이기 때문이다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "It is because ...",
          sentences: [
            {
              Kor: "우리가 알고 있는 사람에 한하는 것이기 때문이다 ",
              Eng: "It is because of the very limited knowledge that we have about people.",
            },
            {
              Kor: "계산부터 앞서기 때문이다  ",
              Eng: "This is because business comes first.",
            },
            {
              Kor: "그래서는 안될 것 같았기 때문이다  ",
              Eng: "So it didn't seem things would work out.",
            },
          ],
        },
        {
          entryName: "-기 땜에 ",
          typicalExample: "【발령이 이곳으로 났기 땜에 】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation:
            "Colloquial/substandard for (and abbreviated from) -기 때문에.",
          sentences: [
            {
              Kor: "발령이 이곳으로 났기 땜에 ",
              Eng: "I'm here because of an official appointment.",
            },
          ],
        },
        {
          entryName: "-기라도 한다",
          typicalExample: "【길을 걷다가 부딪히기라도 하면  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "pseudo-particle -(이)라도 on nominalizer -기 + 한다 to give the pattern -기라도 하- with a function similar to -기나 하- do (at the least) or do (even though its not the best option) or do (or the likes).",
          sentences: [
            {
              Kor: "길을 걷다가 부딪히기라도 하면  ",
              Eng: "if you happened to bump into him or something on the street ",
            },
            {
              Kor: "나는 묶여버리기라도 한 것처럼 몸을 움직일 수 없다.  ",
              Eng: "I was unable to move, as if bound up.",
            },
            {
              Kor: "마치 ... 이 순간을 기다리기라도 한 듯  ",
              Eng: "just as if ... had been waiting [or something] for this instant [or something]",
            },
            {
              Kor: "넘어질 것을 겁내기라도 하는 듯  ",
              Eng: "as if fearing she might fall over or something",
            },
            {
              Kor: "비행기가 오르기라도 한 듯한 어지럼증 ",
              Eng: "a dizziness, as if the airplane had gone up into the air or something",
            },
            {
              Kor: "생각을 잊어버리기라도 하려는 듯이 ",
              Eng: "as if trying to banish the thought from his mind (or something)",
            },
          ],
        },
        {
          entryName: "-기란",
          typicalExample: "【칼날로 잘라내기란 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Summative -기+ -(이)란, a contracted quotation from -(이)라 [고 하-]ㄴ that which is/are called, used to set up a generic definition or value judgement, or to make topics, especially in generic pronouncements or universalizing/ generalizing statements.: as for ...-ing; when it comes to ...-ing. ",
          sentences: [
            {
              Kor: "칼날로 잘라내기란 ",
              Eng: "[the act of] cutting with a razor [was not an easy thing to do]",
            },
            {
              Kor: "매일 마주하기란 간단한 일이 아니었다.",
              Eng: "Facing them every day was no easy task.",
            },
          ],
        },
        {
          entryName: "-기로(는)",
          typicalExample: "【우리가 알기로도】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "When it comes to ...-ing; as far as ...-ing is concerned.",
          sentences: [
            { Kor: "우리가 알기로도", Eng: "as far as we know also ..." },
            {
              Kor: "가깝기로는 공짜 민박이나 무전여행 쪽이 훨씬 가깝지요.",
              Eng: "I would say it's closer/more like free lodging or penniless journey.",
            },
            {
              Kor: "똑똑하고 하기로는 ",
              Eng: "it being the case that (she) was smart ...",
            },
          ],
        },
        {
          entryName: "-기로서니",
          typicalExample: "【모르는 골짜기가 있기로서니 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Even though/if, even given/admitted that, it is true that ... , but.",
          sentences: [
            {
              Kor: "모르는 골짜기가 있기로서니 ",
              Eng: "admitted that there is an unknown valley ...",
            },
          ],
        },
        {
          entryName: "-기로 약속한다",
          typicalExample: "【속에서만 살기로 약속한다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Promises (agrees) to do; makes up one's mind to do",
          sentences: [
            {
              Kor: "속에서만 살기로 약속한다",
              Eng: "I promise to live within the limits of ...",
            },
          ],
        },
        {
          entryName: "-기로 한다",
          typicalExample: "【잔디가 곱게 깔린 방죽길을 걷기로 했다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Decides to do, makes up one's mind to do; arranges to do; fixes (arranges) it so that; promises (agrees) to do.",
          sentences: [
            {
              Kor: "잔디가 곱게 깔린 방죽길을 걷기로 했다 ",
              Eng: "I decided to walk towards the embankment where the grass was laid out prettily.",
            },
            {
              Kor: "나는 고쳐 생각하기로 했다 ",
              Eng: "I decided to think about it again.",
            },
            {
              Kor: "우리 서로 거짓말은 하지 말기로 해 ",
              Eng: "let's agree to never lie to each other",
            },
            {
              Kor: "흐린 날엔 사람들은 헤어지지 말기로 하자 ",
              Eng: "let's agree that people should never part on an overcast day",
            },
            {
              Kor: "삼천원을 돌리기로 하였다 ",
              Eng: "arranged to borrow 3,000 wŏn",
            },
            {
              Kor: "“내일 어머니가 오시기로 했어.”  ",
              Eng: '"My mother has decided to come tomorrow"; or, "It\'s been arranged for my mother to come tomorrow."',
            },
            {
              Kor: "몸이 먼저 아이가 담긴 양수를 밀어내기로 한 모양이었다.  ",
              Eng: "It appeared that [Chisu's] body had decided to be the first to push out the amniotic fluid that was hugging the baby.",
            },
          ],
        },
        {
          entryName: "-기루",
          typicalExample: "【시굴 사람들이기루 = 아무리 시골 사람들이기로 】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            "Dialect/substandard for concessive -기로, usually preceded by 암만 or 아무리: no matter how/however much one ... (still/nonetheless).",
          sentences: [
            {
              Kor: "시굴 사람들이기루 = 아무리 시골 사람들이기로 ",
              Eng: "No matter how much of a country bumpkin they might be [do you think they wouldn't catch on?]",
            },
          ],
        },
        {
          entryName: "-기(를)",
          typicalExample: "【임종하는 자리에까지 내내 미련겨워하기를 】",
          categoryKorean: "Nominalizer",
          categoryEnglish: "( Summative)",
          translation:
            "Summative -기: the act of; the fact of/that; to do or be; that it does or is. Here with the 를 particle as an object.",
          sentences: [
            {
              Kor: "임종하는 자리에까지 내내 미련겨워하기를 ",
              Eng: "to be reluctant to let go even at one's deathbed...",
            },
          ],
        },
        {
          entryName: "-기(를) 권한다",
          typicalExample: "【소파에 앉기를 권했다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Urges (somebody) to do (something); suggests that (somebody does); suggests/offers/recommends to someone that they ...",
          sentences: [
            {
              Kor: "소파에 앉기를 권했다 ",
              Eng: "she offered a seat on the sofa",
            },
            {
              Kor: "그에게 앉아 있기를 권했으나 ",
              Eng: "urged him to stay seated, but ...",
            },
            {
              Kor: "자기 집에서 자고 가기를 권했다 ",
              Eng: "urged me to stay the night at his house",
            },
          ],
        },
        {
          entryName: "-기(를) 그만둔다",
          typicalExample: "【어느 날부터인가 여자는 출근하기를 그만두고  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Quits ...-ing.",
          sentences: [
            {
              Kor: "어느 날부터인가 여자는 출근하기를 그만두고  ",
              Eng: "from one day (can't remember when it was) the woman stopped going to work in the morning",
            },
          ],
        },
        {
          entryName: "-기를 기다린다",
          typicalExample: "【그녀의 강의실 앞에서 강의가 끝나기를 기다려  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB-기를 기다린다: waits for somebody/something to VERB. ",
          sentences: [
            {
              Kor: "그녀의 강의실 앞에서 강의가 끝나기를 기다려  ",
              Eng: "waiting outside her classroom for her class to finish",
            },
            {
              Kor: "미장원에서 잡지를 보며 그녀의 퍼머가 끝나기를 기다리기도 했다.  ",
              Eng: "And I even read magazines at the hairdressers while I waited for her perm to set.",
            },
            {
              Kor: "담배 여인 이야기를 끝맺어주기를 기다리고 있다.  ",
              Eng: "I'm waiting for him to finish the story about the cigarette lady.",
            },
            {
              Kor: "어서 시간이 되기만 기다렸다 ",
              Eng: "just waited for the time to quickly pass (here as –기 acts as an object/subject, with 를 being suppressed by 만)",
            },
            {
              Kor: "그녀가 오기만을 기다리며 나는 하루종일을 보내고 있다.  ",
              Eng: "I am spending the whole day waiting only for her to come.",
            },
            {
              Kor: "소녀가 비키기를 기다리자는 것이다 ",
              Eng: "he was ('suggesting that he be' = thinking to himself that he would be) waiting for the girl to get out of the way",
            },
            {
              Kor: "토요일이 돌아오기를 기다렸다 ",
              Eng: "I waited for Saturday to come around",
            },
            { Kor: "익기를 기다려 ", Eng: "wait till the crops were ripe ..." },
          ],
        },
        {
          entryName: "-기(를) 꺼린다",
          typicalExample: "【비너스 다방에 가기를 꺼려했다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Dislike, abhor, be leery of, regard with aversion; to fear or be apprehensive of ...ing.",
          sentences: [
            {
              Kor: "비너스 다방에 가기를 꺼려했다 ",
              Eng: "[All of them] were reluctant to go to the Venus tabang.",
            },
            {
              Kor: "그들에게로 다가오기를 꺼려하며 ",
              Eng: "she hesitated to approach them ...",
            },
          ],
        },
        {
          entryName: "-기(를) 마친다",
          typicalExample: "【빌기를 마치더니 펄썩 주저앉는다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Finishes ...-ing.",
          sentences: [
            {
              Kor: "빌기를 마치더니 펄썩 주저앉는다. ",
              Eng: "…ended her prayer, whereupon she collapsed on her feet...",
            },
          ],
        },
        {
          entryName: "-기를 바란다",
          typicalExample: "【도로 살가운 모습이 되어 주기를 바라는 마음  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB-기를 바란다: hopes/wishes that (somebody) will VERB. ",
          sentences: [
            {
              Kor: "도로 살가운 모습이 되어 주기를 바라는 마음  ",
              Eng: "my wish for her to go back to being her amiable old self",
            },
            {
              Kor: "부자가 되기를 바라는 것만큼이나 허황된 꿈이었다. ",
              Eng: "It was as absurd a dream as hoping to become a rich person.",
            },
          ],
        },
        {
          entryName: "-기(를) 반복한다",
          typicalExample: "【어둠에 잠기기를 반복하고 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB-기(를) 반복한다: does VERB over and over again; repeats VERBing.",
          sentences: [
            {
              Kor: "어둠에 잠기기를 반복하고 ",
              Eng: "repeatedly becoming immersed in darkness",
            },
            {
              Kor: "발목을 억세게 움켜잡았다 놓기를 반복하던 손길 ",
              Eng: "his touch, which had repeatedly grabbed her ankle firmly and released it",
            },
          ],
        },
        {
          entryName: "-기(를) 위하여 ",
          typicalExample: "【XX 파출소로가기 위하여 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Older, more bookish equivalent of -기 위해: in order to, to the end that, so as to, so that, for the purpose, with the intention of, with a view to. Note that 하여 is the literary equivalent of colloquial 해.",
          sentences: [
            {
              Kor: "XX 파출소로가기 위하여 ",
              Eng: "so as to go to such and such a police station ...",
            },
            {
              Kor: "길을 건너기 위하여 ",
              Eng: "with the intent of crossing the street",
            },
            {
              Kor: "기계에 넣기 위해 가져온 동전 ",
              Eng: "the money she brought to put in the machine",
            },
            {
              Kor: "화장실에 가기 위해 ",
              Eng: "(I arose) for the purpose of going to the washroom",
            },
            {
              Kor: "먼 촌으로부터 등교하기 위하여 ",
              Eng: "from the far village, [students] who were coming to attend school ",
            },
          ],
        },
        {
          entryName: "-기(를) 잊지 않다 ",
          typicalExample: "【눈을 흘기기만은 잊지 않았다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Doesn't forget to ...  I.e., is sure/makes sure to … .",
          sentences: [
            {
              Kor: "눈을 흘기기만은 잊지 않았다 ",
              Eng: "never forgot to look askance [at Oksoe]",
            },
          ],
        },
        {
          entryName: "-기(를) 잘한다1",
          typicalExample: "【울기 잘하는 안초시 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Be good at (something); do (something) a lot or well. ",
          sentences: [
            {
              Kor: "울기 잘하는 안초시 ",
              Eng: "Mr. An, who cried a lot [lately] ",
            },
          ],
        },
        {
          entryName: "-기(를) 잘한다2",
          typicalExample: "【산에 들어오기를 잘했다고 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "It a good thing that one does/ did VERB ... (usually past tense).",
          sentences: [
            {
              Kor: "산에 들어오기를 잘했다고 ",
              Eng: "[thinking to himself that] it's a good thing he came to the mountain",
            },
          ],
        },
        {
          entryName: "-기(를) 좋아한다",
          typicalExample: "【나가 버리기를 좋아하기 때문에 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Like to VERB ... ;  enjoy VERB-ing ...",
          sentences: [
            {
              Kor: "나가 버리기를 좋아하기 때문에 ",
              Eng: "because [conversations] always like to go off ...",
            },
            { Kor: "노닐기 좋아했다 ", Eng: "enjoyed meandering" },
            {
              Kor: "해 보이기 좋아한다 ",
              Eng: "[she] likes to make a show of [zipping the zipper up and down] ...",
            },
          ],
        },
        {
          entryName: "-기(를) 청한다",
          typicalExample: "【나그네 되기를 청하였다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Asks, begs, requests, demands (somebody) to do (something).",
          sentences: [
            {
              Kor: "나그네 되기를 청하였다 ",
              Eng: "requested to become a wanderer",
            },
            {
              Kor: "바래다 주기를 청했던 바로 그때부터 ",
              Eng: "exactly from the time when she begged me to accompany her ...",
            },
          ],
        },
        {
          entryName: "-기 마련이다 ",
          typicalExample: "【그같은 부류의 인간들은 있기 마련입니다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "One cannot help doing/being; one inevitably does/is. This pattern also occurs as -게 마련이다 and -도록 마련이다 with the same meaning. Samuel Martin flags this as 'dialect', but it appears to be pretty standard.",
          sentences: [
            {
              Kor: "그같은 부류의 인간들은 있기 마련입니다 ",
              Eng: "it is inevitable that there will be those kinds of people",
            },
            {
              Kor: "모든 여자들이 한번씩 꿈꾸어 보기 마련인 독신생활 ",
              Eng: "the single life that all women can't help dreaming of once ",
            },
          ],
        },
        {
          entryName: "-기만 기다린다",
          typicalExample: "【그저 어서 봄이 오기만 기다리고 있었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Waits only for ...; just waits for ...",
          sentences: [
            {
              Kor: "그저 어서 봄이 오기만 기다리고 있었다 ",
              Eng: "they were only waiting for spring to come",
            },
          ],
        },
        {
          entryName: "-기만 하다1",
          typicalExample: "【나는 그저 웃기만 했다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Do/be only ...",
          sentences: [
            { Kor: "나는 그저 웃기만 했다 ", Eng: "I simply smiled." },
            {
              Kor: "밉기만 하던 그들 ",
              Eng: "those whom he had done nothing but despise",
            },
          ],
        },
        {
          entryName: "-기만 하다2",
          typicalExample: "【그저 신비스럽기만 했다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Is simply ...  (in a sense of wonder or exclamation).",
          sentences: [
            {
              Kor: "그저 신비스럽기만 했다 ",
              Eng: "[The old story] was simply marvelous.",
            },
          ],
        },
        {
          entryName: "-기만 하면",
          typicalExample: "【신도들은 모이기만 하면 엄마 얘기를 했다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "whenever one does it; no sooner does one do it, than ... ; all one/it has to do or be is ... (and then some result); once one does something, then ...; whenever ... ; as soon as; if one only does/is or were to do/be.",
          sentences: [
            {
              Kor: "신도들은 모이기만 하면 엄마 얘기를 했다.  ",
              Eng: "whenever the parishioners got together, they talked about mom",
            },
            {
              Kor: "누구라도 부딪치기만 하면",
              Eng: "if somebody ever so much as ['just'] bumped into him",
            },
            { Kor: "무진에 오기만 하면  ", Eng: "whenever I go to Mujin ..." },
            {
              Kor: "그것도 일단 무진을 떠나기만 하면  ",
              Eng: "this too, as soon as I will leave Mujin ...",
            },
            { Kor: "정말이기만 하면  ", Eng: "if it were only true" },
            {
              Kor: "순댓점을 얻어먹기만 하면  ",
              Eng: "if only [he] could get a morsel or two of sundae ...",
            },
            {
              Kor: "길게 울어 주기만 하면  ",
              Eng: "no sooner did they give out a long cry, than ...",
            },
            {
              Kor: "마을 사람들은 모여 앉기만 하면  분해하였다  ",
              Eng: "whenever the villagers would sit down together, they would get indignant",
            },
            {
              Kor: "여자애가 바로 옆에 앉아 있기만 하다면",
              Eng: "if only I had a sweetheart sitting right across from me ...",
            },
            {
              Kor: "네가 호랑이를 찍기만 한다면 ",
              Eng: "if only you take a picture of the tiger ...",
            },
          ],
        },
        {
          entryName: "-기만 하면 그만이다 ",
          typicalExample: "【거두어들이기만 하면 그만이었다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "All one has to do is ... and that's it.",
          sentences: [
            {
              Kor: "거두어들이기만 하면 그만이었다",
              Eng: "all they had to do was [wait till the crops were ripe enough to] harvest ",
            },
          ],
        },
        {
          entryName: "-기만 해도 ",
          typicalExample: "【툭 건드리기만 해도 부러질 것만 같던 손가락들】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Even if one only ...-es; even if one so much as just ...-es. ",
          sentences: [
            {
              Kor: "툭 건드리기만 해도 부러질 것만 같던 손가락들",
              Eng: "its fingers, which seemed certain to break if one so much as touched them ",
            },
          ],
        },
        {
          entryName: "-기만 했으면",
          typicalExample: "【꼭 그 성격대로이기만 했으면 좋겠어요】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "If one only did/was or were to do/be.",
          sentences: [
            {
              Kor: "꼭 그 성격대로이기만 했으면 좋겠어요",
              Eng: "I only wish one's personality were in exact correspondence [to blood type].",
            },
          ],
        },
        {
          entryName: "-기만큼 싫다",
          typicalExample:
            "【무슨 수를 내야지 매일 아침 회사 들어가기가 죽기만큼 싫다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-기만큼 싫다 with nominalizer -기 followed by particle 만큼 as much as ... ; to the same extent as. ",
          sentences: [
            {
              Kor: "무슨 수를 내야지 매일 아침 회사 들어가기가 죽기만큼 싫다. ",
              Eng: "I've got to come up with something—I hate going to the office every morning like death itself.",
            },
          ],
        },
        {
          entryName: "-기 망정이지 ",
          typicalExample:
            "【청백했기 망정이지 다른 동간들 당했단 소리 들었지?  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "It's fortunate that ... ; one is lucky that ...  Typically used mid-sentence with suspective -지 in its suspensive intonation, followed by a dire consequence narrowly avoided: good thing that ... (otherwise/or else ... ).",
          sentences: [
            {
              Kor: "청백했기 망정이지 다른 동간들 당했단 소리 들었지?  ",
              Eng: "It's fortunate that I'm (so) upright, you heard about how the other guys/police had it real bad, right? ",
            },
          ],
        },
        {
          entryName: "-기 십상이다",
          typicalExample: "【머리를 쥐어박히기가 십상이었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Just the right thing, excellent, perfect, just the way it ought to be ... ; be ideally suited to ...-ing.",
          sentences: [
            {
              Kor: "머리를 쥐어박히기가 십상이었다 ",
              Eng: "it was natural to expect that he would get punched in the head",
            },
            {
              Kor: "그래서 불놀이하기는 십상이었다 ",
              Eng: "so it was ideally suited for fireworks",
            },
          ],
        },
        {
          entryName: "-기 아깝다",
          typicalExample: "【어느것 하나 나 혼자 보기 아깝지 않은 게 없었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "It is a shame to ... ; it is a pity to ...",
          sentences: [
            {
              Kor: "어느것 하나 나 혼자 보기 아깝지 않은 게 없었다 ",
              Eng: "it was a shame to see it all by myself",
            },
          ],
        },
        {
          entryName: "-기야 하-",
          typicalExample: "【없기야 하겠습니까? 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Summative -기+ particle 이야: only if it be; if (it be), when it comes to; even, indeed. In questions, the effect tends to be rhetorical, while in statements, the effect is to confirm (despite reservations): indeed, sure it's the case, but ...",
          sentences: [
            {
              Kor: "없기야 하겠습니까? ",
              Eng: "Can you go as far as saying that there are none?",
            },
            { Kor: "똑똑하기야 하지", Eng: "Yes, smart she is, but ..." },
          ],
        },
        {
          entryName: "-기에1",
          typicalExample: "【그 곳 내부사정을 좀 알아 두어야겠기에   】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "As, because; in accordance with [the fact that]; by/from doing. Similar in meaning to -기 때문에 and -길레, but note that -기에 meaning because can be used only in statements. However, it is possible to build a question on an embedded statement in -기에2: 무엇을 잡수셨기에 배가 아프십니까? What is you ate that gave you such a stomachache? More examples: 내가 생각하기에 as I think, in my opinion, 내가 보기에 as I see it.",
          sentences: [
            {
              Kor: "그 곳 내부사정을 좀 알아 두어야겠기에   ",
              Eng: "as I would have to scout out some of the details about the interior ...",
            },
            {
              Kor: "대답하기도 싫기에   ",
              Eng: "and because I was also not in the mood to answer",
            },
            {
              Kor: "참혹한 사람살이를 듣기에   쓴물이 났다 ",
              Eng: "Upon hearing his miserable life's tale, I felt a bitter taste in my mouth (i.e., hearing his miserable life's tale left a bitter taste in my mouth). ",
            },
            { Kor: "딸이 듣기에  도 ", Eng: "even to his daughter's ears" },
            {
              Kor: "미스 김 누나가 있기에   ",
              Eng: "[but] because of / thanks to Miss Kim ...",
            },
            {
              Kor: "몸이 영 안 좋아, 라고 하기에   ",
              Eng: "When he says, 'I'm really not feeling well'… ",
            },
            { Kor: "남 보기에  는", Eng: "in the view of others ..." },
          ],
        },
        {
          entryName: "-기에2",
          typicalExample: "【무엇을 잡수셨기에   배가 아프십니까? 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "It is possible to build a question on an embedded statement in -기에2: 무엇을 잡수셨기에 배가 아프십니까? What is you ate that gave you such a stomachache? ",
          sentences: [
            {
              Kor: "무엇을 잡수셨기에   배가 아프십니까? ",
              Eng: "What is you ate that gave you such a stomachache?",
            },
          ],
        },
        {
          entryName: "-기에3",
          typicalExample: "【그렇기에  는 나보다 너무 나이가 많아 보였다. 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation: "For the ...-ing, for the purpose of/sake of ...-ing.",
          sentences: [
            {
              Kor: "그렇기에  는 나보다 너무 나이가 많아 보였다. ",
              Eng: "To that end, he looked so much older than I am",
            },
            {
              Kor: "일본 사람 곁에 앉아 구경하기에  는 ",
              Eng: "when it came to sitting down next to a Japanese person to watch him [fish] ",
            },
          ],
        },
        {
          entryName: "-기에 걸맞다",
          typicalExample: "【그런 일을 하기에는 걸맞은 인상 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Be appropriate, suitable for / to ...-ing.",
          sentences: [
            {
              Kor: "그런 일을 하기에는 걸맞은 인상 ",
              Eng: "an image suitable to doing such work",
            },
          ],
        },
        {
          entryName: "-기에 과하다",
          typicalExample:
            "【이런 산골에 처박혀 있기엔 아무래도 과했던 그년이 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Be too much for (somebody ) to ... ",
          sentences: [
            {
              Kor: "이런 산골에 처박혀 있기엔 아무래도 과했던 그년이 ",
              Eng: "that girl, for whom it was too much to be stuck here in this kind of mountain village...",
            },
          ],
        },
        {
          entryName: "-기에는 너무",
          typicalExample:
            "【그런 것들로 이루어진 집이라는 일상에 갇혀 살기에는 그는 너무나도 자유에 익숙해졌다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB-기에는 너무…: be too ... for VERB-ing; be too ... to VERB.",
          sentences: [
            {
              Kor: "그런 것들로 이루어진 집이라는 일상에 갇혀 살기에는 그는 너무나도 자유에 익숙해졌다.  ",
              Eng: "He is too accustomed to his freedom to live all cooped in the everyday life called 'home' made up of things like these.",
            },
          ],
        },
        {
          entryName: "-기에 (너무) 어리다",
          typicalExample:
            "【한 생명을 책임지기에는, 그 생명을 버리기에는 너무 어린 나이 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "is too young for  ...-ing/too young to ... .",
          sentences: [
            {
              Kor: "한 생명을 책임지기에는, 그 생명을 버리기에는 너무 어린 나이 ",
              Eng: "an age too young to take responsibility for a life and to be throwing away that life",
            },
          ],
        },
        {
          entryName: "-기에 따라서(는)",
          typicalExample: "【듣기에 따라서는 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "According to VERBing; with regard to VERBing ...",
          sentences: [
            {
              Kor: "듣기에 따라서는 ",
              Eng: "according to what I was hearing ...",
            },
          ],
        },
        {
          entryName: "-기에 딱딱하다",
          typicalExample: "【얼굴을 덮기에는 조금 딱딱했던지  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Be too hard to ... ; be too hard for ...-ing. ",
          sentences: [
            {
              Kor: "얼굴을 덮기에는 조금 딱딱했던지  ",
              Eng: "perhaps because it had been a bit hard for covering his face",
            },
          ],
        },
        {
          entryName: "-기에 불편하다",
          typicalExample: "【걷기에는 그리 불편하지 않았고】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Uncomfortable for ...-ing.",
          sentences: [
            {
              Kor: "걷기에는 그리 불편하지 않았고",
              Eng: "it wasn't so uncomfortable for walking ...",
            },
          ],
        },
        {
          entryName: "-기에 안성맞춤이다 ",
          typicalExample: "【몸을 녹이기에도 안성맞춤이었다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Is ideal/perfect/just the thing for ...-ing.",
          sentences: [
            {
              Kor: "몸을 녹이기에도 안성맞춤이었다",
              Eng: "in terms of a place to warm up, it was just the spot; or, [the tabang] was a perfect place to warm up ",
            },
          ],
        },
        {
          entryName: "-기에 알맞다",
          typicalExample: "【되새김질 하기에 아주 알맞았다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Be just the thing for ...-ing, be appropriate for ...-ing, go well with ...-ing. ",
          sentences: [
            {
              Kor: "되새김질 하기에 아주 알맞았다 ",
              Eng: "it was ideal for contemplating ...",
            },
            {
              Kor: "쉬어가기에 알맞았다 ",
              Eng: "it was just perfect as a resting place",
            },
          ],
        },
        {
          entryName: "-기에 이른다",
          typicalExample: "【결심을 하기에 이르렀던 것이었었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Reaches the point where... .",
          sentences: [
            {
              Kor: "결심을 하기에 이르렀던 것이었었다 ",
              Eng: "had reached the point of making a decision",
            },
          ],
        },
        {
          entryName: "-기에 적당하다",
          typicalExample: "【웃기 보다 찡그리기에 가장 적당한 얼굴】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Be suitable for/appropriate for ...-ing.",
          sentences: [
            {
              Kor: "웃기 보다 찡그리기에 가장 적당한 얼굴",
              Eng: "a face best suited to scowling than smiling",
            },
          ],
        },
        {
          entryName: "-기에 지친다 ",
          typicalExample: "【마음을 새기기에 지쳤음이더라 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Tires from, gets exhausted from ...-ing.",
          sentences: [
            {
              Kor: "마음을 새기기에 지쳤음이더라 ",
              Eng: "[I recall that = -더라] he was exhausted from replaying these memories over and over in his mind",
            },
          ],
        },
        {
          entryName: "-기에 충분하다",
          typicalExample: "【그것만으로도 신이 되기엔 충분하지.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Is sufficient/plenty/enough for ...-ing.",
          sentences: [
            {
              Kor: "그것만으로도 신이 되기엔 충분하지.  ",
              Eng: "even on the basis of just that it was sufficient to become a god",
            },
          ],
        },
        {
          entryName: "-기에 편하다",
          typicalExample: "【길이 그런대로 걷기에 편했다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Comfortable for/easy for/appropriate for ...-ing.",
          sentences: [
            {
              Kor: "길이 그런대로 걷기에 편했다 ",
              Eng: "the road was comfortable enough to walk on",
            },
          ],
        },
        {
          entryName: "-기엔",
          typicalExample: "【내 보기엔 좋은 여자 같군 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Abbreviated from –기에는: in accordance with [the fact that].",
          sentences: [
            {
              Kor: "내 보기엔 좋은 여자 같군 ",
              Eng: "in my view/the way I see it, she seems like a good woman",
            },
            {
              Kor: "남보기엔",
              Eng: "to others ...; i.e., the way others saw it ...",
            },
          ],
        },
        {
          entryName: "-기 외에는 ",
          typicalExample: "【돈 놓고 돈 먹기 외에는 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "VERB-기 외에는: outside of (except for) ...-ing.",
          sentences: [
            {
              Kor: "돈 놓고 돈 먹기 외에는 ",
              Eng: 'money matters (lit.L: aside from "betting and taking money")',
            },
          ],
        },
        {
          entryName: "-기 위해(서)",
          typicalExample: "【알아듣기 위해 귀를 쫑긋 세우곤 했지만  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "In order to do. ",
          sentences: [
            {
              Kor: "알아듣기 위해 귀를 쫑긋 세우곤 했지만  ",
              Eng: "I would always perk up my ears so as to hear, but…",
            },
            {
              Kor: "윤호는 권총을 찾기 위해 다시 사다리 위로 올라갔다. ",
              Eng: "Yunho went up the ladder again to search for the gun.",
            },
          ],
        },
        {
          entryName: "-기 위해서라도",
          typicalExample: "【내 마음을 붙들어매놓기 위해서라도  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB-기 위해서라도: even if it should be for the sake of VERBing. I.e., a combination of -기 위해서 for the sake of ...-ing + pseudo-particle -(이)라도 (1) even if (it be) (2) just, (or the) like ( = 이나 ); or even any, some. ",
          sentences: [
            {
              Kor: "내 마음을 붙들어매놓기 위해서라도  ",
              Eng: "if only to reign in this sentiment of mine, I ...",
            },
          ],
        },
        {
          entryName: "-기 전부터",
          typicalExample: "【퍽 오기 전부터 알던 사람처럼 느껴졌어요. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Before ...-ing; since (even) before ...-ing.",
          sentences: [
            {
              Kor: "퍽 오기 전부터 알던 사람처럼 느껴졌어요. ",
              Eng: "Since long before you came, I had been feeling as if I'd known you from before.",
            },
            {
              Kor: "이장영감이 아직 나기 전부터라 했다 ",
              Eng: "they said it was since before village-head Yi was born",
            },
          ],
        },
        {
          entryName: "-기 전이다",
          typicalExample: "【배는 아직 닻도 내리기 전이었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Is or was before ...-ing. Followed by the copula.",
          sentences: [
            {
              Kor: "배는 아직 닻도 내리기 전이었다 ",
              Eng: "it was even before the ship lowered its anchor",
            },
          ],
        },
        {
          entryName: "-기 전에야",
          typicalExample: "【머리가 밖으로 나오기 전에야 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "From -기 전에 before ...-ing + particle -(이)야 only if it be (marking a reinforced contingency or prerequisite for a main clause, one or the other of which is unlikely, unexpected, or anticipated to be difficult or unpleasant). ",
          sentences: [
            {
              Kor: "머리가 밖으로 나오기 전에야 ",
              Eng: "[until and] before they stick their heads out [of the nest] ",
            },
          ],
        },
        {
          entryName: "-기 짝이 없다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "[Usage]",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-기 한이 없다",
          typicalExample: "【 섭섭하기 한이 없었다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Is incredibly/unbelievably ... . Based on Sino-Korean 한(限) limit, hence literally has no limit in its ...-ing/-ness. ",
          sentences: [
            {
              Kor: " 섭섭하기 한이 없었다",
              Eng: "there was no limit to his sadness",
            },
          ],
        },
        {
          entryName: "-기 힘들다",
          typicalExample: "【구하기도 힘들 거야 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Is difficult to do ...; hard to do ...",
          sentences: [
            {
              Kor: "구하기도 힘들 거야 ",
              Eng: "it's also likely to be hard to find a teacher like you ",
            },
          ],
        },
        {
          entryName: "-긴 하-",
          typicalExample:
            "【하긴 그녀와 길게 얘기를 나눠 본 것도 꽤 오래되긴 했다. 】",
          categoryKorean: "Abbreviation",
          categoryEnglish: "(Abbreviation)",
          translation:
            "Abbreviated from -기는 하-: does (is) all right, (it is true) but ... ; is indeed (I must admit) but ...",
          sentences: [
            {
              Kor: "하긴 그녀와 길게 얘기를 나눠 본 것도 꽤 오래되긴 했다. ",
              Eng: "Still, it had also been quite a long time since I had spoken with her at any length. ",
            },
            {
              Kor: '"좀 힘들긴 한데 ... " ',
              Eng: '"It\'s a bit of a stretch ... "',
            },
            {
              Kor: "약간 마음에 걸리긴 해도 ",
              Eng: "despite the fact that it does slightly rub me the wrong way ...",
            },
            {
              Kor: "나쁜 뜻으로이긴 하지만",
              Eng: "it is indeed with a negative implication, but ...",
            },
            {
              Kor: "부분골절이긴 해도 ",
              Eng: "even though it is a local fracture ...",
            },
            {
              Kor: "급하긴 하고 해서 ",
              Eng: "it was indeed urgent [and stuff], so ...",
            },
            {
              Kor: "윗길이긴 하지만 ",
              Eng: "it's the upper road, all right, but ...",
            },
            {
              Kor: "이정표가 있긴 했으나 ",
              Eng: "there was a milestone, all right, but ...",
            },
          ],
        },
        {
          entryName: "-길 ",
          typicalExample: "【죽길 어째 죽으며 】",
          categoryKorean: "Colloquial ",
          categoryEnglish: "(Colloquial )",
          translation:
            "Colloquial contraction from-기를, here functioning as a cognate object similar to 잠(을) 자- 춤(을) 추- , etc. ",
          sentences: [
            { Kor: "죽길 어째 죽으며 ", Eng: "How are they being killed?" },
          ],
        },
        {
          entryName: "-길래1",
          typicalExample: "【입구 벗을 옷이 어딨어? 날 언제 옷 해줬길래  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "WH-question word (who, what, where, when, why)... -길래 since wh…/wh...ever has one ... such that …?",
          sentences: [
            {
              Kor: "입구 벗을 옷이 어딨어? 날 언제 옷 해줬길래  ",
              Eng: "When have you ever given me clothes? That I have clothes to change into?",
            },
          ],
        },
        {
          entryName: "-길래2",
          typicalExample: "【하도 추워하시길래  안방으로 모셨어요 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "For –기에: as, because, since; with the fact of doing. -길래 is usually prefaced by an intensifier like 하도 so [very], or a WH-question.",
          sentences: [
            {
              Kor: "하도 추워하시길래  안방으로 모셨어요 ",
              Eng: "as she seemed so terribly cold, I led her to the main room ...",
            },
            {
              Kor: "아버님과 집안 얘기를 하길래  ",
              Eng: "because of his talk of my father and my household ...",
            },
          ],
        },
        {
          entryName: "김에",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "While (one is at/about it), when, as, since, as long as (...anyway); incidental to the occasion of",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "까지",
          typicalExample:
            "【혼(魂)까지  옮아 붙은 영정(影幀)을 보는 느낌이었다 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "(extent, emphasis) up to even so far as; the very; up to and including.",
          sentences: [
            {
              Kor: "혼(魂)까지  옮아 붙은 영정(影幀)을 보는 느낌이었다 ",
              Eng: "it was as though I were looking at a portrait into which he had moved her very soul",
            },
            {
              Kor: "결혼까지  하고 ",
              Eng: "and I even went so far as to marry him ...",
            },
            {
              Kor: "사는 날까지  ",
              Eng: "as long as I am alive (lit., until the day I am [still] alive)",
            },
            { Kor: "머리때까지 ", Eng: "even the dirt from his hair" },
            {
              Kor: "매까지  맞은 날이면 ",
              Eng: "on days that they went as far as whipping him ...",
            },
            {
              Kor: "주정뱅이 하씨까지  데리고 ",
              Eng: "went as far as taking Mr. Ha the drunkard along ...",
            },
            {
              Kor: "춤까지  춰댔다 ",
              Eng: "went so far as to dance up a storm",
            },
            {
              Kor: "반주까지  한잔 얻어마실 순 없겠습니까? ",
              Eng: "Would it not be possible to go as far as getting a drink off of you? ",
            },
            {
              Kor: "그녀는 이런 말까지  하지 않았던가.  ",
              Eng: "Didn't she once even goes so far as to say this?",
            },
            {
              Kor: "삶을 비관하고 자살 충동까지  생겨서 그렇다며?  ",
              Eng: "Is it true that they become pessimistic about life and even develop impulses to commit suicide?",
            },
            {
              Kor: "짜증까지  섞여 있었다.  ",
              Eng: "...was even tinged with annoyedness",
            },
          ],
        },
        {
          entryName: "까지도",
          typicalExample: "【시어머니까지도 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "The particle 도 till; to; ... also; even; indeed renders the delimiter 까지 even more emphatic.",
          sentences: [
            { Kor: "시어머니까지도 ", Eng: "[not] even her mother-in-law" },
            {
              Kor: "그녀의 남편까지도  ",
              Eng: "even her own husband [rushed out]",
            },
            {
              Kor: "학생이나 부녀자들까지도  ",
              Eng: "even students or married women",
            },
            {
              Kor: "다른 단골손님들을 거의 익힐 때까지도  ",
              Eng: "even right up until the time when she had come to recognize the other regular customers",
            },
          ],
        },
        {
          entryName: "까지밖에",
          typicalExample: "【차가 겨우 영흥까지밖에 못통할 때에는 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Particle 까지 meaning up to, even, so far as plus postnoun 밖에 outside of (a limit), (with the) exception of (+ negative). Followed by a negative.",
          sentences: [
            {
              Kor: "차가 겨우 영흥까지밖에 못통할 때에는 ",
              Eng: "at the time when cars could barely make it even as far as Yŏnghŭng ...",
            },
          ],
        },
        {
          entryName: "까지야",
          typicalExample: "【뭐 딱할 거까지야 있나? 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "까지 + particle 야, alternant of 이야 after vowels meaning only if it be; if (it be), when it comes to; even, indeed (marks a reinforced contingency or prerequisite for a main clause, one or the other of which is unlikely, unexpected, or anticipated to be difficult or unpleasant).",
          sentences: [
            {
              Kor: "뭐 딱할 거까지야 있나? ",
              Eng: "What is there to go so far as to feel pity for?",
            },
          ],
        },
        {
          entryName: "까지의",
          typicalExample: "【이제까지의 모든 소리들 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Particle 까지 up to, until + particle 의 adnominalized to (modifying) a following noun phrase. ",
          sentences: [
            {
              Kor: "이제까지의 모든 소리들 ",
              Eng: "all sounds [characterized by the fact that they are] up until now",
            },
          ],
        },
        {
          entryName: "까짓",
          typicalExample: "【그까짓 것을 일이라고 하고 있느냐?】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Particle 까지 + ㅅ used to modify a following noun with the meaning of such as ...; the piddling likes of ... (forms a pre-noun, usually derogatory).",
          sentences: [
            {
              Kor: "그까짓 것을 일이라고 하고 있느냐?",
              Eng: 'Why are you (wasting time) on that so-called "job"?',
            },
            { Kor: "제까짓  것들이 ", Eng: "those bastards ..." },
            {
              Kor: "까짓  가는 데까지 가구 내일 또 갑시다.  ",
              Eng: "We'll go as far as we can, and we can continue tomorrow.",
            },
          ],
        },
        {
          entryName: "깐 ",
          typicalExample: "【네깐놈 = 네 까짓놈 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Particle 까[지] + ㄴ used to modify a following noun with the meaning of such as ...; the piddling likes of ... (forms a pre-noun, usually derogatory).",
          sentences: [
            {
              Kor: "네깐놈 = 네 까짓놈 ",
              Eng: "a despicable guy like you; an s.o.b. the likes of you",
            },
          ],
        },
        {
          entryName: "깨나",
          typicalExample: "【소작밭깨나】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "Just because of (a trifle) or the (paltry) likes. Sometimes spelled -개나.",
          sentences: [
            {
              Kor: "소작밭깨나",
              Eng: "[whenever one of the local old men would get him] a lousy plot of leased land",
            },
            {
              Kor: "복녀도 감자깨나 잘 도둑질하여 왔다 ",
              Eng: "Pongnyŏ would often steal the odd potato or two, too. ",
            },
          ],
        },
        {
          entryName: "꺼정",
          typicalExample:
            "【어디꺼정 가는기오 = 어디까지 가는 거예요 or 가는가요?】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            "Kyŏngsang-do (경상도) dialect for particle 까지 up to; as far as; until; even (including).",
          sentences: [
            {
              Kor: "어디꺼정 가는기오 = 어디까지 가는 거예요 or 가는가요?",
              Eng: "Where are you going?",
            },
            {
              Kor: "모친꺼정 돌아갔구마 ",
              Eng: "So, even his mother died, eh?",
            },
            { Kor: "사령장꺼정", Eng: "even had a letter of appointment" },
            { Kor: "살인강도꺼정", Eng: "even murdering robbers  " },
          ],
        },
        {
          entryName: "껏",
          typicalExample: "【그때껏 내 주위를 맴돌며 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "NOUN껏 = alternate form for NOUN까지 up to NOUN, (right) up until NOUN.",
          sentences: [
            {
              Kor: "그때껏 내 주위를 맴돌며 ",
              Eng: "going round and round me until that moment ",
            },
            { Kor: "이때껏", Eng: "until now " },
          ],
        },
        {
          entryName: "-께",
          typicalExample: "【내 불러다 주께 】",
          categoryKorean: "Colloquial ",
          categoryEnglish: "(Colloquial )",
          translation:
            "Colloquial abbreviation of the Immediate Future/promise-like form in -(으)ㄹ께.",
          sentences: [
            { Kor: "내 불러다 주께 ", Eng: "I'll get them [for you]." },
            {
              Kor: "제 큰오빠뻘이나 되실텐데요",
              Eng: "You could just as well be a big brother to me. ",
            },
          ],
        },
        {
          entryName: "께1",
          typicalExample: "【나 그 사람들께 손해 끼친 거 하나두 없어요 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "For 에게.",
          sentences: [
            {
              Kor: "나 그 사람들께 손해 끼친 거 하나두 없어요 ",
              Eng: "I have done absolutely no harm to those people ...",
            },
          ],
        },
        {
          entryName: "께2",
          typicalExample: "【갑자기 명치께가 아팠다. 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "Post-noun 께 around, in the vicinity/neighborhood of, near (a place) (here followed by 가 as subject).",
          sentences: [
            {
              Kor: "갑자기 명치께가 아팠다. ",
              Eng: "suddenly I felt a pain around my solar plexus",
            },
            {
              Kor: "그녀의 앉은 엉덩이께에는  ",
              Eng: "the area near her seat",
            },
            {
              Kor: "잠든 아이의 정수리께 ",
              Eng: "around the crown of the sleeping child",
            },
            {
              Kor: "가슴께에 눌린 밥풀 몇 개가 허옇게 말라붙어 있다.  ",
              Eng: "There are a few grains of dried white rice stuck around the chest area.",
            },
            { Kor: "꼬리께 까만 깃 ", Eng: "the black feathers on its tail " },
            {
              Kor: "오늘은 그 앞 못(池)께까지 날았는데 ",
              Eng: "today they flew as far as the area near the pond out in front ",
            },
            {
              Kor: "그런 길수의 오른 쪽 손바닥은 왼쪽 가슴께를 꼭 누르고 있었다. ",
              Eng: "[Kilsu's right hand pressed firmly] on the left side of his chest.",
            },
          ],
        },
        {
          entryName: "께로",
          typicalExample: "【현관께로 나간는데 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Postnoun 께 around, in the vicinity/neighbourhood of, near (a place) followed by directional (으)로.",
          sentences: [
            {
              Kor: "현관께로 나간는데 ",
              Eng: "I went out to the front gate... ",
            },
          ],
        },
        {
          entryName: "꼴1",
          typicalExample: "【세 번 결근에 한 번 꼴로 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "세 번 결근에 한 번 꼴로 ",
              Eng: "for every three absences, one lousy ...",
            },
          ],
        },
        {
          entryName: "꼴2",
          typicalExample: "【볼품 없는 꼴로 늙어 가는데 】",
          categoryKorean: "Postmodifier",
          categoryEnglish: "(Postmodifier)",
          translation:
            "Postmodifier 꼴: (1) shape, form, appearance; (2) [pejorative] face, countenance, personal appearance, mug; (3) personal behaviour, manner(ism), action, business (as with one's hands), carrying on, the things one does [also tends to be somewhat pejorative].",
          sentences: [
            {
              Kor: "볼품 없는 꼴로 늙어 가는데 ",
              Eng: "...was growing old in an ugly sort of way",
            },
            {
              Kor: "내 하는 꼴을 엿보고 있었구나 ",
              Eng: "she was spying on what I was doing; she was secretly watching what I was doing",
            },
            {
              Kor: "몸을 가눌 수도 없는 꼴이 되어 ",
              Eng: "...came to the point where he could not even control his own body ",
            },
            {
              Kor: '"꼴에 큰 가방은 또 뭘까?" ',
              Eng: "You being as pathetic as you are, what's with the big bag? (For [그/네] 꼴에 in such a/your pathetic state, what's with the ...?)",
            },
          ],
        },
        {
          entryName: "끼리",
          typicalExample: "【우리끼리 마시는 게 훨씬 편했다. 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "Postnoun 끼리: the separate group (of like people); among (by, between, to) ourselves (or yourselves, themselves).",
          sentences: [
            {
              Kor: "우리끼리 마시는 게 훨씬 편했다. ",
              Eng: "It was far more convenient to drink just among ourselves.",
            },
            {
              Kor: "남남끼리이면서 가장 친한 두 손 ",
              Eng: "the most intimate two hands, which, even though they belonged to two different people",
            },
            {
              Kor: "단란한 가족끼라 의 식탁 ",
              Eng: "a dining table of a harmonious family ",
            },
            {
              Kor: "사람들이 그들끼라  ",
              Eng: "people chatting among themselves ",
            },
            {
              Kor: "당자끼라  직접 팔고 사는 것이 ",
              Eng: "buying and selling directly between the parties concerned",
            },
            {
              Kor: "동네 사람끼라  ",
              Eng: "among the people in the neighborhood",
            },
            {
              Kor: "장사 할멈끼라  ",
              Eng: "the merchant grannies [would say] amongst themselves",
            },
            {
              Kor: "저희끼린 동정하는 말로 ",
              Eng: "[the merchant women] would sympathize amongst themselves ",
            },
            {
              Kor: "대사업의 동업자끼라 이기도 한 이 두 손",
              Eng: "two hands which were also like the hands of partners, sharing some great undertaking ",
            },
            {
              Kor: "저희끼라  지껄이더니 ",
              Eng: "chatted amongst themselves and then...",
            },
          ],
        },
        {
          entryName: "끼리도",
          typicalExample: "【이웃끼리도 친척끼리도 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation: "Postnoun 끼리 + 도: even among ...",
          sentences: [
            {
              Kor: "이웃끼리도 친척끼리도 ",
              Eng: "even among neighbors and family members …",
            },
          ],
        },
        {
          entryName: "ㄴ",
          typicalExample: "【넓적다린 내놓구 = 넓적다리는  내놓고 】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "Abbreviated particle 는 after a noun ending in a vowel.",
          sentences: [
            {
              Kor: "넓적다린 내놓구 = 넓적다리는  내놓고 ",
              Eng: "lit.: with her thighs exposed",
            },
            { Kor: "하나는 나인  ", Eng: "the other's age..." },
            { Kor: '"아버진  미쳤어." ', Eng: "“Dad's crazy.”" },
          ],
        },
        {
          entryName: "-ㄴ가",
          typicalExample: "【당쟁과 어떤 연관이 있지 않은가 생각됩니다  】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Modifier [-(으)ㄴ, -던, -었던, -었는, -는] + postmodifier 가: the question of/whether. Sometimes used like a quoted question/thought (to oneself): wondering to oneself, is it not a case of ... ? ",
          sentences: [
            {
              Kor: "당쟁과 어떤 연관이 있지 않은가 생각됩니다  ",
              Eng: "the thought occurred to me of whether it is not the case that there is a connection with party rivalry",
            },
            { Kor: "재작년엔가 ", Eng: "lit.: Was it the year before last?" },
            {
              Kor: "황무진가 ? = 황무지인가 ? ",
              Eng: "Is it a wasteland [I wonder]?",
            },
            {
              Kor: "남자들과 나는 다름없지 않은가  ",
              Eng: "lit.: Is it not the case that I am no different from men [who ...]?",
            },
            {
              Kor: "다른 사람 노트인가 ? ",
              Eng: "Is it perhaps somebody else's notebook?",
            },
          ],
        },
        {
          entryName: "Question word + -(이)ㄴ가",
          typicalExample: "【전에도 몇 번인가  열린 적이 있지만  】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Question word + -(이)ㄴ가: some-WH or other. Similar in function to quasi-particle -(이)나: some ... or other. Note the following examples: 언젠가 sometime or other; 어디엔가 somewhere or other; 어디에선가 나타났어요 …appeared from out of nowhere; 어딘가 다르다 there's something different about it.",
          sentences: [
            {
              Kor: "전에도 몇 번인가  열린 적이 있지만  ",
              Eng: "…had been offered a few times before previously, too, but",
            },
            {
              Kor: "이건 또 무슨 소리인가 .  ",
              Eng: "What's she talking about?",
            },
            {
              Kor: "영달은 어디로 갈 것인가  궁리해 보면서 ",
              Eng: "while wondering where he would go, Yŏngdal ...",
            },
            {
              Kor: "무진엔 왜 내가 못 올 덴가 ?",
              Eng: "Why wouldn't I come to Mujin? (For 못 올데 + -인가, with 데 place.)",
            },
            {
              Kor: "어디쯤에선가  ",
              Eng: "from somewhere (or other) along the road ...",
            },
            {
              Kor: "어느 날인가 는 헤어진다고 생각하기 때문에 ",
              Eng: "because she thought that, some day or other, they would separate",
            },
            {
              Kor: "어느 해엔가 는  ",
              Eng: "some year or other; some years back ...",
            },
            {
              Kor: "그게 얼만가 ? ← 얼마인가 ? ",
              Eng: "I wonder how much that is? or, How much might that be?",
            },
            {
              Kor: "나는 무슨 말을 하려는 것인가  ",
              Eng: "What was it that I was trying to say? ",
            },
            {
              Kor: "여자는 어딘가  불안했는지 ",
              Eng: "the woman seemed to feel uneasy/anxious about something ...",
            },
            {
              Kor: "무엇인가  붙어 있는 것을 발견했다 ",
              Eng: "[He] discovered something stuck to [the mirror].",
            },
            {
              Kor: "부녀 이상의 비밀스러운 무엇인가 가 있었다 ",
              Eng: "(But) there was a secretive something which went beyond that of father and daughter.",
            },
            {
              Kor: "어디엔가 에",
              Eng: "somewhere [or other] ...(← 어디에 + -인가에.)",
            },
            {
              Kor: "얼마나 어둡고 지리한 시간의 궤적인가 를 안다 ",
              Eng: "I know how dark and tedious the passage of time it is.",
            },
          ],
        },
        {
          entryName: "-ㄴ가가 ",
          typicalExample: "【부녀 이상의 비밀스러운 무엇인가가  있었다 】",
          categoryKorean: "Subject  ",
          categoryEnglish: "(Subject  )",
          translation:
            "From question -(으)ㄴ가 + subject marker 가: the question of ... (as subject). ",
          sentences: [
            {
              Kor: "부녀 이상의 비밀스러운 무엇인가가  있었다 ",
              Eng: "(But) there was a secretive something which went beyond that of father and daughter.",
            },
          ],
        },
        {
          entryName: "-ㄴ가는",
          typicalExample: "【좋은가 나쁜가는  둘째고 】",
          categoryKorean: "Topic  ",
          categoryEnglish: "(Topic  )",
          translation:
            "Modifier [-(으)ㄴ, -던, -었던, -었는, -는] + postmodifier 가: the question of/whether + 는 (i.e., the whole question as topic).",
          sentences: [
            {
              Kor: "좋은가 나쁜가는  둘째고 ",
              Eng: "whether [the painting] was good was secondary …",
            },
          ],
        },
        {
          entryName: "-ㄴ가를 설명한다",
          typicalExample: "【내게 강냉이술이 얼마나 몸에 좋은가를 설명했다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "To explain about ...; to go on about ...",
          sentences: [
            {
              Kor: "내게 강냉이술이 얼마나 몸에 좋은가를 설명했다  ",
              Eng: "He explained to me how good corn brew was for one's health.",
            },
            {
              Kor: "얼마나 큰 일이고 횡재할 일인가를 설명해  주었다 ",
              Eng: "I explained what a big deal it was and what an unexpected fortune.",
            },
          ],
        },
        {
          entryName: "-ㄴ가 보다 ",
          typicalExample: "【등신인가 바  = 등신인가 봐  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Seem to be. Note that the auxiliary verb 보다 in this pattern is descriptive. ",
          sentences: [
            {
              Kor: "등신인가 바  = 등신인가 봐  ",
              Eng: "[all young guys nowadays] seem to be…",
            },
            {
              Kor: "“뼈가 상한 것은 아닌가 봐요 .”",
              Eng: '"The bone doesn’t seem to be damaged."',
            },
          ],
        },
        {
          entryName: "-ㄴ가 싶다",
          typicalExample:
            "【도마뱀의 혀가 들락날락하면서 내는 소리가 아닌가 싶다 .  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "think, feel or wonder whether ... , where the question in -(으)ㄴ가 functions as an indifferent quoted thought. ",
          sentences: [
            {
              Kor: "도마뱀의 혀가 들락날락하면서 내는 소리가 아닌가 싶다 .  ",
              Eng: "I wonder if perhaps it isn't the sound of the lizard's tongue going in and out",
            },
            {
              Kor: "처음엔 웬 애인인가 싶어  의아했다. ",
              Eng: "At first I was dubious as to who the boyfriend was.",
            },
          ],
        },
        {
          entryName: "-ㄴ 가운데",
          typicalExample: "【산들이 사면을 둘러싼 가운데  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "In the middle/center of",
          sentences: [
            {
              Kor: "산들이 사면을 둘러싼 가운데  ",
              Eng: "in the center of the area encompassed on all four sides by mountains ...",
            },
            {
              Kor: "밀어닥친 가운데  ",
              Eng: "in the middle of being crowded around ... ",
            },
          ],
        },
        {
          entryName: "-ㄴ가 의심이 든다 (드-ㄹ-)",
          typicalExample: "【농담이라도 하는 게 아닌가 의심이 들었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "To doubt whether it is not ... ; to wonder whether it is not the case that ...",
          sentences: [
            {
              Kor: "농담이라도 하는 게 아닌가 의심이 들었다  ",
              Eng: "I wondered whether he wasn't jesting...",
            },
          ],
        },
        {
          entryName: "-ㄴ가 하는 추정",
          typicalExample:
            "【이 사건에 여자가 개입한 것이 아닌가 하는 추정 을 하게 됐어. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The supposition as to whether perhaps ... In other words, a question in modifier -(으)ㄴ + postmodifier 가 quoted to oneself (-ㄴ가 한다 wonders to oneself whether perhaps where 한다 means think) and then adnominalized to 추정 supposition.",
          sentences: [
            {
              Kor: "이 사건에 여자가 개입한 것이 아닌가 하는 추정 을 하게 됐어. ",
              Eng: "So he came to assume that perhaps a woman had been involved in this case.",
            },
          ],
        },
        {
          entryName: "-ㄴ가 한다",
          typicalExample: "【문득 이 무슨 미친 짓인가 하는  생각 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Indirect quoted thought in -(으)ㄴ가 하-.",
          sentences: [
            {
              Kor: "문득 이 무슨 미친 짓인가 하는  생각 ",
              Eng: 'I suddenly thought, "What the hell am I doing?"',
            },
          ],
        },
        {
          entryName: "-ㄴ 감이 있다",
          typicalExample: "【늦은 감이 있어요  】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation: "To have a feeling that; to have a hunch that.",
          sentences: [
            {
              Kor: "늦은 감이 있어요  ",
              Eng: "I have a feeling that it is too late. ",
            },
          ],
        },
        {
          entryName: "-ㄴ 거나 아닐까",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Isn't is perhaps the case that ... ← -(으)ㄴ 것이나 아닐까. The following 하- serves in its function of think.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ걸",
          typicalExample: "【[Examples?]】",
          categoryKorean: "해체의 종결 어미 Final ending in Intimate Style",
          categoryEnglish: "(해체의 종결 어미 Final ending in Intimate Style)",
          translation: "[Usage]",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ 걸(요)",
          typicalExample: "【벌써 이십 오년 동안이나 그러고 계신 걸요  】",
          categoryKorean: "해요체의 종결 어미 Final ending in Polite Style",
          categoryEnglish: "(해요체의 종결 어미 Final ending in Polite Style)",
          translation:
            'This is an example of what Martin calls the use of the accusative to "mark an antithetical clause that ends in a modifier + 것." The meaning is contrary to what you might expect or think, despite contrary anticipations/reservations; although, but; (it\'s) too bad (that ...), in spite of the fact that ... ; anyway, so there!',
          sentences: [
            {
              Kor: "벌써 이십 오년 동안이나 그러고 계신 걸요  ",
              Eng: "She has been like this for already 25 years [contrary to what you may have been thinking].",
            },
            {
              Kor: "저는 어머님 모시고 벌써 먹은 걸요  ",
              Eng: "I've already eaten [lunch] with my mother-in-law [contrary to what you may have been thinking].",
            },
            {
              Kor: "방이 모자라법석인걸요  ",
              Eng: "[Contrary to what you might think] things get crazy because there aren't enough rooms!",
            },
            {
              Kor: "소주도 마셔 본걸요  ",
              Eng: "When I was with my friends we even locked the door from the inside and drank soju.",
            },
            {
              Kor: "책임도 부책임도 없는 곳인걸요  ",
              Eng: "However, this is a place that called for neither responsibility nor a lack of it.",
            },
            {
              Kor: "그렇지만 오늘은 흐린걸  ",
              Eng: "But (contrary to your expectations) today is cloudy.",
            },
            {
              Kor: "이건 좀 너무한 편인걸  ",
              Eng: "This (unfortunate situation) here is a little too much. ",
            },
          ],
        },
        {
          entryName: "-ㄴ 것",
          typicalExample:
            "【부친 위해 보험 든 거  있지? = 부친(을) 위해(서) 보험(에) 든 것 (이) 있지?】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "With object + verb collocations like 보험(에) 들다 take out insurance in the first example below, the pattern OBJECT + VERB-(으)ㄴ 것 is equivalent to saying VERB- (으)ㄴ + OBJECT. The longer version adnominalized to 것 typically drops any case particles, and because it is slightly longer and more redundant, is easier to catch on the fly in colloquial, spoken Korean. ",
          sentences: [
            {
              Kor: "부친 위해 보험 든 거  있지? = 부친(을) 위해(서) 보험(에) 든 것 (이) 있지?",
              Eng: "You took out some insurance for your father, didn't you?",
            },
            {
              Kor: "아침에 김치 썬 것  얻다 넣었어?",
              Eng: "Where did you put the kimch'i we cut this morning? [아침에 썬 김치 would sound odd; too analytical and preplanned an utterance for colloquial language]",
            },
            {
              Kor: "순사 된 것 을 못내 후회하면서 ",
              Eng: "While constantly regretting becoming a constable again...",
            },
          ],
        },
        {
          entryName: "- ㄴ 것도 모르고 ",
          typicalExample: "【잠방이 띠가 풀린 것도 모르고  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Not even know that . . .; be unaware even of the fact that . . .",
          sentences: [
            {
              Kor: "잠방이 띠가 풀린 것도 모르고  ",
              Eng: "[Oksoe was dancing] not even aware that the sash of his shorts had come undone, and…",
            },
          ],
        },
        {
          entryName: "-ㄴ 것만 같다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier + 것만 같- all it seems like is, definitely seems. The addition of particle 만 to the seem pattern in Modifier + 것 같다 lends an air of absolute certainly and insistence to the construction, as if to say the one and only thing that seemed [likely] was . . . —i.e., that what seemed to be happening is imminent, inevitable, or inescapable.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ 것만(은) 분명하- ",
          typicalExample: "【아닌 것만은 분명하다는  말 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "The one thing that is/was clear is ...",
          sentences: [
            {
              Kor: "아닌 것만은 분명하다는  말 ",
              Eng: "The word was that  they clearly were not ... ",
            },
          ],
        },
        {
          entryName: "- ㄴ 것이다",
          typicalExample: "【인제 망월 (望月)을 할 참 인 것이다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "It was the fact or act of ...-ing; it was the case that ... did; it was a matter of ...-ing.",
          sentences: [
            {
              Kor: "인제 망월 (望月)을 할 참 인 것이다  ",
              Eng: "It was now just about the time for moon-gazing.",
            },
          ],
        },
        {
          entryName: "-ㄴ 것처럼",
          typicalExample:
            "【이렇게 당연한 순서인 것처럼  외로움이 기다리고 있다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄴ 것처럼: as if it is a case of being ... (with descriptive verbs; with processives the meaning would be as if it is a case of having done ...). ",
          sentences: [
            {
              Kor: "이렇게 당연한 순서인 것처럼  외로움이 기다리고 있다.  ",
              Eng: "Loneliness awaits, as if it were the natural sequence of events.",
            },
            {
              Kor: "사정을 하고 나면 불에라도 덴 것처럼  몸을 일으키고는 욕실로 향했다. ",
              Eng: "After ejaculating, he would get up and head for the bathroom, as if he had burned himself or something.",
            },
            {
              Kor: "그러면 나는 불결한 여자가 된 것처럼  느껴지곤 했다.  ",
              Eng: "Whenever he did that, it always made me feel like I had become filth.",
            },
            {
              Kor: "자기의 장점인 것처럼  ",
              Eng: "as if it were her strong point ...",
            },
            {
              Kor: "일상사인 것처럼  ",
              Eng: "as if it were a daily routine ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 것을 기뻐한다",
          typicalExample: "【우선 흉년을 면한 것을 기뻐했다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Be/feel delighted/happy with the fact that (somebody/something) did . . .",
          sentences: [
            {
              Kor: "우선 흉년을 면한 것을 기뻐했다  ",
              Eng: "…were first and foremost delighted that they had avoided a famine ",
            },
            {
              Kor: "어머니는 내가 무사한 것을 기뻐했고  ",
              Eng: "My mother was happy that I was safe, and ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 것을 안다",
          typicalExample: "【나만 앵한 것을 알았다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Know that ...; realize that ...; figure out that ...",
          sentences: [
            {
              Kor: "나만 앵한 것을 알았다  ",
              Eng: "I realized that I was the only one upset about the whole thing.",
            },
            {
              Kor: "그들이 북한 괴뢰군인 것을 알았고  ",
              Eng: "[They] realized that they were North Korean puppet soldiers, and...",
            },
            {
              Kor: "무방비인것을 알자  ",
              Eng: "As soon as they found that [their counterpart/target was] defenseless ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 것이 틀림없다 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "There is no doubt that (it did/it is) …; there is no questioning that fact that ...",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ 게 틀림없다 ",
          typicalExample: "【새벽에 동네를 빠져나간 게 틀림없습니다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "In origin, from -(으)ㄴ 것이 틀림없다: There is no doubt that (it did) …; there is no questioning that fact that ...",
          sentences: [
            {
              Kor: "새벽에 동네를 빠져나간 게 틀림없습니다  ",
              Eng: "She must have snuck off at dawn.",
            },
          ],
        },
        {
          entryName: "-ㄴ 결과",
          typicalExample: "【경찰이 확인해 본 결과  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "As a result of ...-ing; as a result of having ...-ed",
          sentences: [
            {
              Kor: "경찰이 확인해 본 결과  ",
              Eng: "As a result of the police confirming … (i.e., the police confirmed that ...)",
            },
          ],
        },
        {
          entryName: "-ㄴ 경우 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Situation, case used as a postmodifier: in cases when, in circumstances when.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "- ㄴ고 ",
          typicalExample: "【얼마나 하였음인고  】",
          categoryKorean: "하게체의 종결 어미 Final ending in Familiar Style  ",
          categoryEnglish:
            "(하게체의 종결 어미 Final ending in Familiar Style  )",
          translation:
            'A question ending built on the modifier -(으)ㄴ + postmodifier 고 for \'WH-questions\', which contrasts with -(으)ㄴ가 for yes-no questions, itself a combination of modifier -(으)ㄴ + postmodifier 가. In Middle Korean and in some Korean dialects, verbs in "WH-questions" (i.e., questions which contain a question word like "what?" "how many" or the like, as opposed to yes-no questions) take a question ending in final -고 rather than -가 as in the modern standard language, so this can be considered either an archaism or a regionalism, depending on context.',
          sentences: [
            {
              Kor: "얼마나 하였음인고  ",
              Eng: "How (much more stern) were they?",
            },
            { Kor: "몇십 년인고 ? ", Eng: "How many decades has it been?" },
            {
              Kor: "저것들이 송장이 앙이구 무스겐고 ? ",
              Eng: "If these aren't corpses, then what are they? (앙이구 is dialect for 아니고, and 무스겐고 is dialect for 무엇인가.)",
            },
          ],
        },
        {
          entryName: "-ㄴ 고로 ",
          typicalExample: "【그런고로 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Postmodifier -(으)ㄴ 고로 = -(으)ㄴ 까닭으로: for the reason that. This pattern sounds somewhat old fashioned. The 고 is Sino-Korean 故 reason.",
          sentences: [{ Kor: "그런고로 ", Eng: "for that reason ..." }],
        },
        {
          entryName: "-ㄴ구 ",
          typicalExample: "【날 언제 옷 해줬길래, 옷 많이씩이냐는 건구 ? 】",
          categoryKorean: "하게체의 종결 어미 Final ending in Familiar Style  ",
          categoryEnglish:
            "(하게체의 종결 어미 Final ending in Familiar Style  )",
          translation:
            'A variant of -ㄴ고, which is a question ending built on the modifier -(으)ㄴ + postmodifier 고 for \'WH-questions\', which contrasts with -(으)ㄴ가 for yes-no questions, itself a combination of modifier -(으)ㄴ + postmodifier 가. In Middle Korean and in some Korean dialects, verbs in "WH-questions" (i.e., questions which contain a question word like "what?" "how many" or the like, as opposed to yes-no questions) take a question ending in final -고 rather than -가 as in the modern standard language, so this can be considered either an archaism or a regionalism, depending on context.',
          sentences: [
            {
              Kor: "날 언제 옷 해줬길래, 옷 많이씩이냐는 건구 ? ",
              Eng: "When have you ever bought me clothes, that you're saying that I have lots of clothes? (The WH-trigger here is 언제. The verb form is from -이냐[고 하]는 것인고.)",
            },
          ],
        },
        {
          entryName: "-ㄴ기오?",
          typicalExample: "【그런기오 ?】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            "경상도 dialect equivalent for -(으)ㄴ가요? Probably reduced ← -ㄴ 거요 ← -ㄴ것이오?",
          sentences: [{ Kor: "그런기오 ?", Eng: "Is that so?" }],
        },
        {
          entryName: "-ㄴ기오",
          typicalExample: "【반가와하는 사람이 다 뭔기오  】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            "Kyŏngsang-do (경상도) dialect question ending equivalent in function to standard -는가요? but derived historically from -ㄴ 게 (← 것이)오 → -ㄴ 기오?",
          sentences: [
            {
              Kor: "반가와하는 사람이 다 뭔기오  ",
              Eng: "Whadda mean 'was there anybody to greet me?' [Of course not!] (With 뭔기오 = 무엇인가요? Rhetorical question emphasized with 다.)",
            },
          ],
        },
        {
          entryName: "-ㄴ 김에",
          typicalExample: "【그냥 시어머니와 마주쳤거나 마주친 김에  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "While (one was at/about it), when, as, since, as long as (...anyway) one had done it or was doing it; incidental to the occasion of doing something (in the past). ",
          sentences: [
            {
              Kor: "그냥 시어머니와 마주쳤거나 마주친 김에  ",
              Eng: "It was either that they bumped into her mother-in-law or having chanced to bump into her, they figured they might as well ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 까닭이다 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Was because ...; was the reason that ...",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ 꼴이다",
          typicalExample: "【만사 태평인 꼴이었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'Personal behavior, manner(ism), action, "carrying on" the things one does; giving the shabby or undesirable appearance of ...',
          sentences: [
            {
              Kor: "만사 태평인 꼴이었다  ",
              Eng: "He carried on as if all was well with the world (i.e., he was as laid-back as ever).",
            },
          ],
        },
        {
          entryName: "-ㄴ 끝에 ",
          typicalExample: "【시어머니를 오랜동안 극진히 봉양한 끝에  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "After...-ing; at the end of having done.",
          sentences: [
            {
              Kor: "시어머니를 오랜동안 극진히 봉양한 끝에  ",
              Eng: "At the end of this long devoted support of her mother-in-law ...",
            },
          ],
        },
        {
          entryName: "ㄴ 나머지 ",
          typicalExample: "【내 목소리는 그지없이 당당한 나머지   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "As a result of, from an excess of, driven by.",
          sentences: [
            {
              Kor: "내 목소리는 그지없이 당당한 나머지   ",
              Eng: "My voice, full as it was with boundless righteousness …",
            },
            {
              Kor: "그 아이들을 사랑한 나머지 ",
              Eng: "Because I love the children so incredibly much, ...",
            },
            {
              Kor: "놀란 나머지  ",
              Eng: "To his shock (as a result of being startled), he...",
            },
            {
              Kor: "잠시 놀란 나머지  ",
              Eng: "As a result of his momentary shock ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 눈치",
          typicalExample: "【발언권을 얻겠다고 단단히 작정한 눈치다 .  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "A subtle, unspoken indication or sign that ... .",
          sentences: [
            {
              Kor: "발언권을 얻겠다고 단단히 작정한 눈치다 .  ",
              Eng: "… makes it clear that she is resolved to have her say.",
            },
          ],
        },
        {
          entryName: "-ㄴ 느낌이다 ",
          typicalExample: "【우리편으로 다가든 느낌이었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Is a feeling of ... ; gives the feeling of ... ; feels like ...",
          sentences: [
            {
              Kor: "우리편으로 다가든 느낌이었다 ",
              Eng: "It felt like [the tiger] would come over.",
            },
          ],
        },
        {
          entryName: "-ㄴ다 해도",
          typicalExample: "【설령 그를 내보낸다 해도 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            '...-(으)ㄴ다 해도 ? -(으)ㄴ다[고] 해도: even supposing ... Lit.: saying, "Quote." Used to give a reason. Even supposing one says that ...; hence even granted that ...; even supposing ... . ',
          sentences: [
            {
              Kor: "설령 그를 내보낸다 해도 ",
              Eng: "even if I were to send him away ...",
            },
            {
              Kor: "설령 나에 대한 나쁜 여론을 조성한다 해도  ",
              Eng: "even supposing it contributes to a negative public opinion of myself ...",
            },
            {
              Kor: "비록 모양틀 안에서 똑같은 얼음으로 얼려진다 해도   ",
              Eng: "Even if [life] once concedes that it gets frozen into one set frame like so many ice-cubes … ",
            },
          ],
        },
        {
          entryName: "-ㄴ다고",
          typicalExample: "【학비라도 보탠다고  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            'Lit., saying, "Quote." Used to give a reason or pretext.',
          sentences: [
            {
              Kor: "학비라도 보탠다고  ",
              Eng: "in order to at least pay the tuition ...",
            },
            {
              Kor: "몇 사람이 총을 멘 바우를 구경한다고  모였다",
              Eng: "a few people gathered to see the rifle-toting Pau ...",
            },
          ],
        },
        {
          entryName: "-ㄴ다고 믿는다",
          typicalExample: "【살라만다는 불 속에서 산다고 믿어졌지 .】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Believe that ‘QUOTE’. ",
          sentences: [
            {
              Kor: "살라만다는 불 속에서 산다고 믿어졌지 .",
              Eng: "It was believed that salamanders lived in fire.",
            },
          ],
        },
        {
          entryName: "-ㄴ다고 해도",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'Even supposing ... Lit.: saying, "Quote." Used to give a reason.',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ다고 (해서)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Followed by a negative clause, a quoted phrase like this means nevertheless; having said that; (but) just because A, B is not necessarily the case.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ다구 ",
          typicalExample: '【"뭘 안다구  떠들어." 】',
          categoryKorean: "Colloquial ",
          categoryEnglish: "(Colloquial )",
          translation:
            "colloquial -ㄴ다구 ← -ㄴ다고, with the quotation functioning almost like a reason: saying you know what? → on account of you knowing what?",
          sentences: [
            {
              Kor: '"뭘 안다구  떠들어." ',
              Eng: "What do you think you know, mouthing off like that?",
            },
          ],
        },
        {
          entryName: "-ㄴ다기보다",
          typicalExample: "【화가 난다기보다  모욕감 같은 것이 들었다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB1-ㄴ다기보다 VERB2 more as what you would call VERB2 than VERB1; it's not so much that A, rather ... B. In origin, an abbreviated indirect quotation: VERB1-ㄴ다기보다 ← VERB1-ㄴ다[고 하]기보다 [something else] rather (or more) than say that it is a case of VERB1, it is [actually/rather] [something else].",
          sentences: [
            {
              Kor: "화가 난다기보다  모욕감 같은 것이 들었다.  ",
              Eng: "It's not so much that I'm angry as that I feel somehow insulted.",
            },
            {
              Kor: "그건 난다기보다  떨어지는 것이었다",
              Eng: "it was more like falling than flying",
            },
          ],
        },
        {
          entryName: "-ㄴ다나",
          typicalExample: "【매 평 얼마나 간다나 ? = 얼마나 간다고 하나 ? 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "매 평 얼마나 간다나 ? = 얼마나 간다고 하나 ? ",
              Eng: "How much did they say it was going for per p'yŏng?",
            },
          ],
        },
        {
          entryName: "-ㄴ다는1 NOUN",
          typicalExample: "【그 딸 준다는  차주전자 】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "The [NOUN, etc.] about which they [or whoever] say/about which it is said that, abbreviated from an indirect quotation in -ㄴ다[고 하]는, modifying the following thing, etc.",
          sentences: [
            {
              Kor: "그 딸 준다는  차주전자 ",
              Eng: "the tea kettle he claimed he was going to give to his daughter",
            },
            {
              Kor: "나날이 달라간다는  숙이 ",
              Eng: "Sugi who was saying that it was changing day by day ...",
            },
            {
              Kor: "전염성이 없어진다는  약 복용 후 삼 주일.  ",
              Eng: "Three weeks after taking the medication, about the time when it was claimed that the contagiousness would go away",
            },
          ],
        },
        {
          entryName: "-ㄴ다는2 NOUN",
          typicalExample: "【나날이 달라간다는  숙이 】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "[The person] who says/claims that ... abbreviated from an indirect quotation in –ㄴ 다[고 하]는, modifying the following person, etc.",
          sentences: [
            {
              Kor: "나날이 달라간다는  숙이 ",
              Eng: "Sugi who was saying that it was changing day by day ...",
            },
          ],
        },
        {
          entryName: "-ㄴ다는3 NOUN",
          typicalExample: "【깔끔해야 한다는  지론 】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "The putative/alleged [fact, claim, words, etc.] to the effect that QUOTE, abbreviated from an indirect quotation in -다[고 하]는, modifying the following fact, claim, words, etc.",
          sentences: [
            {
              Kor: "깔끔해야 한다는  지론 ",
              Eng: "of the opinion that [the more of a single woman they are,] the more tidy they must be … (Here modifying 지론 theory; position, opinion.)",
            },
            {
              Kor: "아버지가 바쁘셔서 ... 호텔에서 주무신다는  비서의 전화겠지 했다. ",
              Eng: "He thought to himself, 'It must be a call from dad's secretary saying he's too busy and will be spending [tonight] at a hotel'.",
            },
          ],
        },
        {
          entryName: "-ㄴ다는 걸 몰랐다",
          typicalExample: "【나는 그녀가 일기를 쓴다는 걸 몰랐다 . 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-ㄴ다는 걸 몰랐다 ← -ㄴ다는 것을 몰랐다: Had no idea that somebody was ...-ing. Of course, the -ㄴ다는 bit is, in origin, a quotation, -ㄴ다[고 하]는.",
          sentences: [
            {
              Kor: "나는 그녀가 일기를 쓴다는 걸 몰랐다 . ",
              Eng: "I had no idea she was writing a diary.",
            },
          ],
        },
        {
          entryName: "-ㄴ다는 것",
          typicalExample:
            "【뭘 쓴다는 것 이 그녀에게는 도무지 안 어울리는 일이었다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "From the indirect quotation pattern in -ㄴ다[고 하]는 것, etc., meaning the putative or alleged fact that ...; the claim (is/was) that ...; the alleged fact that ... , etc., setting up a  generic definition or value judgement (like-(이)란), or a comment about the (often unusual/noteworthy) topic marked by -는 것. ",
          sentences: [
            {
              Kor: "뭘 쓴다는 것 이 그녀에게는 도무지 안 어울리는 일이었다. ",
              Eng: "The idea of writing did not suit her at all.",
            },
            {
              Kor: "조용히 잠든다는 것 은 ",
              Eng: "(The thing about) sleeping in peace ...",
            },
            {
              Kor: "하루하루 다가든다는 것 이 ",
              Eng: "This attack day after day ...",
            },
            {
              Kor: "낑낑댄다는 것 이 우습게 생각되었다 ",
              Eng: "The way people went on groaning and complaining seemed silly.",
            },
            {
              Kor: "무생물에 놀란다는 것 은 부끄러운 일이다라고 그는 생각했다. ",
              Eng: "He considered it embarrassing to be frightened by inanimate objects.",
            },
            { Kor: "학교에 다닌다는 것  ", Eng: "the act of attending school" },
            {
              Kor: "학생들을 가르친다는 것  ",
              Eng: "the act of teaching students",
            },
            {
              Kor: "어떤 사람을 잘 안다는 것  ",
              Eng: "[the notion of] knowing someone well ",
            },
            {
              Kor: "잘 아는 체한다는 것 이 ",
              Eng: "pretending to know someone well",
            },
            { Kor: "술을 마신다는 것 이 즐겁다.  ", Eng: "Drinking is fun." },
          ],
        },
        {
          entryName: "-ㄴ다는 것도 그러려니와",
          typicalExample:
            "【요리를 손수 한다는 것도 그러려니와  장보기도 손수하고 세탁물도 손수 찾아가지고 갔다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Besides the putative fact that A, moreover [B is also the case]. The 그러려니와 is base 그렇- be so, be thus plus ending -(으)려니와 but; and anyway (what's more); moreover. Also note the use of 한다난 것 (as opposed to just 하는 것) to indicate the (putative/alleged) fact that.",
          sentences: [
            {
              Kor: "요리를 손수 한다는 것도 그러려니와  장보기도 손수하고 세탁물도 손수 찾아가지고 갔다. ",
              Eng: "He said that he cooked for himself and what was more, he did his own grocery shopping and picked up his own dry cleans. ",
            },
          ],
        },
        {
          entryName: "-ㄴ다는 것을",
          typicalExample:
            "【자신들을 친친 감아나간다는 것을  먼저 알게 된 것도 지수의 언니였다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "From the indirect quotation pattern in -ㄴ다[고 하]는 것, etc., meaning the putative or alleged fact that ...; the claim (is/was) that ...; the alleged fact that ... , etc., deployed as an object. ",
          sentences: [
            {
              Kor: "자신들을 친친 감아나간다는 것을  먼저 알게 된 것도 지수의 언니였다. ",
              Eng: "It was also Chisu's older sister who sooner realized how it was gradually winding around them and binding them.",
            },
          ],
        },
        {
          entryName: "-ㄴ다는 것을 안다",
          typicalExample: '【"내가 은희를 좋아한다는 건 너도 알지 ?" 】',
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "-ㄴ다는 것 아-ㄹ-: knows that ... ",
          sentences: [
            {
              Kor: '"내가 은희를 좋아한다는 건 너도 알지 ?" ',
              Eng: '"You know that I like Ŭnhŭi, right?"',
            },
          ],
        },
        {
          entryName: "-ㄴ다는 것이다",
          typicalExample: "【시집을 간다는 것이었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "From the indirect quotation pattern in -ㄴ다[고 하]는 것, etc., meaning it is the putative or alleged fact that ...; the claim (is/was) that ...; the alleged fact that ... , etc. ",
          sentences: [
            {
              Kor: "시집을 간다는 것이었다  ",
              Eng: "It was said that/rumour had it that the girl would marry ...",
            },
          ],
        },
        {
          entryName: "-ㄴ다는 둥 ",
          typicalExample:
            "【일본 음식은 너무 싱거워서 처음에는 속이 뉘엿거린다는 둥 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "From -ㄴ다[고 하-]는 둥, with postmodifier 둥 for what is originally 등 in its meaning (following a list or enumeration) of et cetera, and so forth, and so, and the like, and all that, and such like for a list of (usually quoted) hypothetical options or choices.",
          sentences: [
            {
              Kor: "일본 음식은 너무 싱거워서 처음에는 속이 뉘엿거린다는 둥 ",
              Eng: "[He carried on about how] Japanese food was so bland that at first he felt nauseous, yadda yadda yadda, and ...",
            },
            {
              Kor: "소위 팔자를 고친다는 둥  ",
              Eng: "the so-called changing of fate or ...",
            },
            { Kor: "허리띠를 푼다는 둥  ", Eng: "or you become wealthy ..." },
          ],
        },
        {
          entryName: "-ㄴ다는 듯(이)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Lit.: like, as, giving the appearance as if to say ... from an abbreviated indirect quotation structure in -ㄴ다[고 하]는 듯(이).",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ다는 듯하다",
          typicalExample: "【재미난다는 듯한  표정으로 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Contracted from -ㄴ다[고 하]는 듯하다: as if to say that ... ",
          sentences: [
            {
              Kor: "재미난다는 듯한  표정으로 ",
              Eng: "with an expression that read 'this is interesting' ... ",
            },
          ],
        },
        {
          entryName: "-ㄴ다는 사실",
          typicalExample:
            "【중요한 것은 윤호가 지섭을 좋아한다는 사실이었다 .  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "중요한 것은 윤호가 지섭을 좋아한다는 사실이었다 .  ",
              Eng: "The important point was the fact that Yunho liked Chisŏp.",
            },
          ],
        },
        {
          entryName: "-ㄴ다는 생각 ",
          typicalExample: "【끌려가서는 안된다는 생각  밖에 없었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Thoughts of ... ; the thought that ... ; thought[s] to the effect that ‘QUOTE’. Built on an abbreviated indirect quotation in -ㄴ다[고 하-] modifying the following 생각 thought.",
          sentences: [
            {
              Kor: "끌려가서는 안된다는 생각  밖에 없었다 ",
              Eng: "The only thought [Kilsu had] was that he mustn't be dragged away.",
            },
          ],
        },
        {
          entryName: "-ㄴ다는 얘기",
          typicalExample:
            "【사람이 죽을 때 사정을 한다는 얘기 를 들은 적이 있어. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Words or story to the effect that QUOTE, where -는다는 (as well as -(으)ㄴ다는, -다는, and -(이)라는) is from the indirect quotation pattern in -는다 [고 하]는, here modifying 얘기 ← 이야기. ",
          sentences: [
            {
              Kor: "사람이 죽을 때 사정을 한다는 얘기 를 들은 적이 있어. ",
              Eng: "I once heard that men ejaculated when they died.",
            },
          ],
        },
        {
          entryName: "-ㄴ다는 이유로",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "On account of the (alleged) reason that ..., with an abbreviated indirect quotation in -ㄴ다는 ← -ㄴ다[고 하]는 modifying 이유 reason.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ다는 인상 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Impression to the effect that ...; impression to the effect that QUOTE. Built on an abbreviated indirect quotation in -ㄴ다[고 하-] modifying 인상 impression.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "ㄴ다니까",
          typicalExample: '【"안다니까 ."】',
          categoryKorean: "해체의 종결 어미 Final ending in Intimate Style",
          categoryEnglish: "(해체의 종결 어미 Final ending in Intimate Style)",
          translation: "For insistent, reproachful reiterations.",
          sentences: [
            { Kor: '"안다니까 ."', Eng: "I know that (duh!)" },
            {
              Kor: "몰라 몰라 정말 모른다니까  ",
              Eng: "I don't know, I don't know, I'm telling you I really don't know!",
            },
            {
              Kor: "정말 안 한다니까요  ",
              Eng: "[At times like those] we never think of (making) money.",
            },
          ],
        },
        {
          entryName: "-ㄴ다더구나",
          typicalExample: "【[Examples?]】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            'From an abbreviated quote in -ㄴ다[고 하]더구나! with a retrospective apperceptive in -더구나, -더군: now I realize that somebody said/claimed that . . . ! Apperceptives carry the meaning of "first realization" ...in - 군, -구나, -군요, -구려, etc.',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ다더군(요)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Abbreviated ← -다[고 하]더군요: I see that they say that ...!",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ다더라",
          typicalExample:
            '【"참, 오늘 회사 강당에서 사우 아내들한테 교양 강좌를 한다더라 ." 】',
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "-ㄴ다더라 contracted from -ㄴ다[고 하]더라: I recall that somebody said that ... ; apparently, ... ; apparently, they say that ... ",
          sentences: [
            {
              Kor: '"참, 오늘 회사 강당에서 사우 아내들한테 교양 강좌를 한다더라 ." ',
              Eng: '"Oh, by the way, apparently they\'re putting on a training seminar for company wives today in the auditorium."',
            },
          ],
        },
        {
          entryName: "-ㄴ다던 ",
          typicalExample: "【살림을 내준다던  것도 헛소리였다 】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "For -ㄴ다[고 하]던, an abbreviated indirect quotation + retrospective modifier, meaning something like: [NOUN] about which it used to be said that QUOTE. ",
          sentences: [
            {
              Kor: "살림을 내준다던  것도 헛소리였다 ",
              Eng: "Saying that he could support a household was nonsense. ",
            },
          ],
        },
        {
          entryName: "-ㄴ다던가",
          typicalExample: "【단단한 두개골을 갖게 된다던가 .  】",
          categoryKorean: "하게체의 종결 어미 Final ending in Familiar Style  ",
          categoryEnglish:
            "(하게체의 종결 어미 Final ending in Familiar Style  )",
          translation:
            "-ㄴ/는다던가 ← -ㄴ/는다[고 하]던가: was it the case that somebody said/claimed that ... (I wonder)? Usually a soliloquy form used when trying to recall something.",
          sentences: [
            {
              Kor: "단단한 두개골을 갖게 된다던가 .  ",
              Eng: "Was it the case that they said that they came to have a firm skull?",
            },
          ],
        },
        {
          entryName: "-ㄴ다덴?",
          typicalExample:
            "【화투패나 밤낮 떼면 너이 어멈이 살아온다덴 ? (from 살아 온다고 하더냐?) 】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation: "From -ㄴ다고 하더냐.",
          sentences: [
            {
              Kor: "화투패나 밤낮 떼면 너이 어멈이 살아온다덴 ? (from 살아 온다고 하더냐?) ",
              Eng: "Is it true what they say about your mother, that she is …  ",
            },
          ],
        },
        {
          entryName: "-ㄴ다매",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            'From -는다(고 하)며 meaning 1) while saying that "QUOTE," . . .  and 2) Is it true that "QUOTE?"',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-(으)ㄴ다며",
          typicalExample:
            "【아침에 가득 채워 나왔는데 어느새 바닥이 비어 간다며  양 씨가 미안한 표정을 지었다.】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Contraction from the quotation pattern in -(으)ㄴ다[고 하]며 = 하면서.",
          sentences: [
            {
              Kor: "아침에 가득 채워 나왔는데 어느새 바닥이 비어 간다며  양 씨가 미안한 표정을 지었다.",
              Eng: "Yang made an apologetic face, saying that he had brought it out filled to the top in the morning, but already it was starting to gradually empty out ...",
            },
          ],
        },
        {
          entryName: "-ㄴ다며",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            'From -ㄴ다(고 하)며 meaning 1) while saying that "QUOTE," . . .  and 2) Is it true that "QUOTE?"',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ다면",
          typicalExample: "【값진 물건을 가져간다면  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Conditional in -는다면, -ㄴ다면, -다면. In origin, this pattern is built on an indirect quotation in -는다[-고 하-]면, -ㄴ다[-고 하-]면, -다[-고 하-]면 and can be interpreted in two ways: 1) literally, as if one says/supposes that . . .  and 2) as a more hypothetical if than the usual conditional in -(으)면.",
          sentences: [
            {
              Kor: "값진 물건을 가져간다면  ",
              Eng: "if he were to walk off with something valuable ...",
            },
            {
              Kor: "혹시 제가 지금 미친다면  ",
              Eng: "if I happen to go crazy ... ",
            },
          ],
        },
        {
          entryName: "-ㄴ다면서",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            'From -는다[-고 하-]면서: while saying that "QUOTE," . . . ',
          sentences: [
            { Kor: "[Examples?]", Eng: "" },
            {
              Kor: "자연환경을 보호한다면서  ",
              Eng: "while claiming to protect the environment ...",
            },
          ],
        },
        {
          entryName: "- ㄴ다면서(요)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation: 'From -ㄴ다(고 하)면서: Is it true that "QUOTE?"',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ 다음부터",
          typicalExample: "【외국으로 떠난 다음부터  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Ever since ...-ing; starting with the time when ... ",
          sentences: [
            {
              Kor: "외국으로 떠난 다음부터  ",
              Eng: "ever since she left the country … ",
            },
            {
              Kor: "지섭이 쫓겨나간 다음부터   ",
              Eng: "ever since Chisŏp had been chased away...",
            },
          ],
        },
        {
          entryName: "-ㄴ 다음(에)",
          typicalExample: "【그러면 그녀는 한숨을 쉰 다음  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "After ...-ing.",
          sentences: [
            {
              Kor: "그러면 그녀는 한숨을 쉰 다음  ",
              Eng: "Then, after she had heaved a sigh …",
            },
          ],
        },
        {
          entryName: "-ㄴ다지 뭐예요?",
          typicalExample: "【그런데 가버린다지 뭐예요   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Wouldn't you know it...!; the next thing you know...",
          sentences: [
            {
              Kor: "그런데 가버린다지 뭐예요   ",
              Eng: "The next thing I knew, she said she was leaving me. ",
            },
          ],
        },
        {
          entryName: "-ㄴ다 하나",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Abbreviated indirect quotation from -ㄴ다[고] 하나: They say that . . ., but . . . ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ단 말야",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "An abbreviated indirect quotation in -ㄴ단 ← -ㄴ다[고 하-]ㄴ as modifier to following 말 words to the effect that QUOTE, where 말 (말야 ← 말이야, 말입니다, etc.—말 followed by a form of the copula -이다), meaning I mean, you know, you see, uh, that is, etc.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ단 말이다 ",
          typicalExample: "【쪼끔 벌릴까 말까 한단 말입니다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "I'm tellin' ya. ",
          sentences: [
            {
              Kor: "쪼끔 벌릴까 말까 한단 말입니다  ",
              Eng: "It's a matter of 'Shall I spread my legs for him or not?', I'm tellin' ya.",
            },
          ],
        },
        {
          entryName: "-ㄴ단다",
          typicalExample: "【죽자구나 도리머리를 어지럽게 흔든단다   】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "Abbreviated Plain Style quotations in -ㄴ단다 ← -ㄴ다[고 하-]ㄴ다 are used (often by children) as impudent, taunting or proud boasts.",
          sentences: [
            {
              Kor: "죽자구나 도리머리를 어지럽게 흔든단다   ",
              Eng: "She would shake her head wildly. (Note also -자구나 same as  -자꾸나 as a Plain Style 'let's' functioning here adverbially as 'like the dickens'.)",
            },
          ],
        },
        {
          entryName: "-ㄴ답니다 ",
          typicalExample: "【이런 나를 마마보이라고도 한답니다   】",
          categoryKorean: "하십시오체의 종결어미 Final ending in Formal Style",
          categoryEnglish:
            "(하십시오체의 종결어미 Final ending in Formal Style)",
          translation:
            "Abbreviated from an indirect quotation in -ㄴ/는다[고 하]ㅂ니다: I say it is/does; I tell you (somewhat insistently or excitedly) it is/does; it really is/does; it is/does, you see (mind you).",
          sentences: [
            {
              Kor: "이런 나를 마마보이라고도 한답니다   ",
              Eng: 'They call boys like me "mama\'s boy," you know. ',
            },
          ],
        },
        {
          entryName: "-ㄴ답시고 ",
          typicalExample: "【계집이 살림을 한답시고   】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Sarcastic/ironic -(으)ㄴ답시고 , -(으)랍시고: making the (somewhat ridiculous/ laughable/humorous/pompous) claim that ...",
          sentences: [
            {
              Kor: "계집이 살림을 한답시고   ",
              Eng: "lit.: [the somewhat ridiculous/laughable situation where] a girl like her [could claim] to be keeping house ...",
            },
          ],
        },
        {
          entryName: "-ㄴ대도",
          typicalExample: "【K가 간대도   】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Abbreviated quotation from -(으)ㄴ다고 해도: even though one says or claims that …; even if someone were to say that ...",
          sentences: [
            {
              Kor: "K가 간대도   ",
              Eng: "even though K said he was leaving… (from 간다(고) 해도)",
            },
            {
              Kor: "누가 죽인대도   ",
              Eng: "even if someone were to [say they were going to] kill me ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 대로1",
          typicalExample: "【꼰대가 불러준 대로   쓴 편지 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Used on a processive base: in accordance with the way one did.",
          sentences: [
            {
              Kor: "꼰대가 불러준 대로   쓴 편지 ",
              Eng: "the letter he had written according to Kkondae's instructions; or, the letter he had been dictated by Kkondae (lit: the letter he had written in accordance with the way Kkondae called it out)",
            },
            {
              Kor: "마음먹은 대로  ",
              Eng: "in accordance with what I had resolved to do",
            },
            { Kor: "먼저 이야기한 대로   ", Eng: "like I said at first ..." },
            {
              Kor: "그녀 자신이 거칠게 표현한대로   ",
              Eng: "just as she herself had expressed rather roughly",
            },
          ],
        },
        {
          entryName: "-ㄴ 대로2 ",
          typicalExample: "【교통이 편한 대로   온양으로 왔다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Used on a descriptive base to give a reason: in so far as, given that, seeing as ...; in accordance with the way one did.",
          sentences: [
            {
              Kor: "교통이 편한 대로   온양으로 왔다 ",
              Eng: "Given that the convenient transportation, Onyang was where I went.",
            },
          ],
        },
        {
          entryName: "-ㄴ 대신(에) ",
          typicalExample: "【그가 나보다 불행한 대신   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Instead of ..., in place/lieu ...; while on the one hand ...; in compensation for [not] ...ing.",
          sentences: [
            {
              Kor: "그가 나보다 불행한 대신   ",
              Eng: "instead of continuing to be unhappy ...",
            },
            {
              Kor: "소심한 위인인 대신   ",
              Eng: "instead of being a coward like me ...",
            },
          ],
        },
        {
          entryName: "-ㄴ대야 ",
          typicalExample: "【과객(過客)이 주인을 안대야 별것 있겠습니까? 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Contraction from -ㄴ/는ㄷ[ㅏ고 ㅎ]ㅐ야 meaning (literally) does one HAVE to say/claim that ... (before/as a precondition for something else)? ",
          sentences: [
            {
              Kor: "과객(過客)이 주인을 안대야 별것 있겠습니까? ",
              Eng: "Is it such a peculiar thing that a passerby might know his host? ",
            },
          ],
        },
        {
          entryName: "-ㄴ대(요)",
          typicalExample: "【그 여자를 좋아한대   】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Colloquial contracted quotation from -ㄴㄷ[ㅏ고 ㅎ]ㅐ요 says that somebody does it. Used for hearsay reporting—they say that QUOTE or I hear that QUOTE. ",
          sentences: [
            {
              Kor: "그 여자를 좋아한대   ",
              Eng: "They say that [Pak] likes her.",
            },
            { Kor: '"걔 이민 간대  ." ', Eng: '"He says he\'s emigrating."' },
          ],
        },
        {
          entryName: "-ㄴ 댐에",
          typicalExample: "【나한테 들킨 댐에는 】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation: "Dialect/substandard 댐에 for 다음에: after.",
          sentences: [
            {
              Kor: "나한테 들킨 댐에는 ",
              Eng: "Once you've been caught by me/fallen into my clutches … ",
            },
          ],
        },
        {
          entryName: "-ㄴ댔어?",
          typicalExample: '【"누가 꼭 가야 한댔어  ?" 】',
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "-ㄴ댔어? Contracted from -ㄴㄷ[ㅏ고 ㅎ]ㅐ-ㅆ어? Did somebody say that ... ",
          sentences: [
            {
              Kor: '"누가 꼭 가야 한댔어  ?" ',
              Eng: '"Did anybody say you have to go?"',
            },
          ],
        },
        {
          entryName: "-ㄴ댔지 ",
          typicalExample: "【네시 반 버스로 온천장으로 나가신댔지   】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation: "Contracted from -(으)ㄴ다고 했지.",
          sentences: [
            {
              Kor: "네시 반 버스로 온천장으로 나가신댔지   ",
              Eng: "You said you'd take the four-thirty bus to the hot springs, right?",
            },
          ],
        },
        {
          entryName: "-ㄴ 덕택으로 ",
          typicalExample: "【이번 트럭도에 갔다 온 덕택으로  는 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Thanks to having done…",
          sentences: [
            {
              Kor: "이번 트럭도에 갔다 온 덕택으로  는 ",
              Eng: "Thanks to having been to Truc Island...",
            },
          ],
        },
        {
          entryName: "-ㄴ데다(가)",
          typicalExample: "【성질은 그러한데다   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "From -(으)ㄴ/는데(에) + -(이)다가: not only ... but in addition (on top of that). Used only with descriptive verbs; for past reference, -었는데다(가) is used. ",
          sentences: [
            {
              Kor: "성질은 그러한데다   ",
              Eng: "Not only was her disposition like that, but in addition ...",
            },
            {
              Kor: "수심(水深)이 얕은데다가  ",
              Eng: "The water is too shallow and what's more ...",
            },
            {
              Kor: "머리는 긴데다   외눈 쌍까풀인 계집년 ",
              Eng: "A chick with long hair, and (on top of that) one double-lidded eye ...",
            },
            {
              Kor: "해지고 낡은데다 ",
              Eng: "Not only were [the clothes] ragged and worn-out ...",
            },
            {
              Kor: "10층인데다가   ",
              Eng: "On top of it being the tenth floor ...",
            },
            {
              Kor: "아침잠이 번히 늦은데다가   ",
              Eng: "Not only had I slept very late, but ...",
            },
            {
              Kor: "연말인 데다   토요일이어서 ",
              Eng: "Not only was it the end of the year but it was also Saturday...",
            },
          ],
        },
        {
          entryName: "-ㄴ데 대해서 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "With regard to/with respect to having [done/been]… .",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "- ㄴ데도",
          typicalExample: "【두 번째 받는 상인데도   】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Even though, in spite of; given the circumstance that it is/does, even though, though. This pattern can usually be taken as an abbreviation of -는데도 불구하고 or -(으)ㅁ에도 (불구하고). Used only with descriptive verbs. For past reference, -었는데도 is used.",
          sentences: [
            {
              Kor: "두 번째 받는 상인데도   ",
              Eng: "in spite of the fact that it was only the second meal I had taken (at the place) ...",
            },
            {
              Kor: "집을 떠난 지가 오래 된 것 같은데도   ",
              Eng: "even though it seemed like it had been ages since I left home ...",
            },
            {
              Kor: "남녘인데도  ",
              Eng: "in spite of the fact that it was southerly ...",
            },
            {
              Kor: "마음은 전혀 그렇지 않은데도   그녀가 들떠 하는 것이 이상하게 못마땅했다",
              Eng: "Even though I wasn't feeling that way in the slightest, her animation was annoying.",
            },
          ],
        },
        {
          entryName: "-ㄴ데(에)",
          typicalExample: "【대화를 나눈데에   책임이 없는 것은 아니었지만】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Circumstantial -ㄴ데(에): with respect to ...-ing; as far as ...-ing is concerned, with respect to past tense.",
          sentences: [
            {
              Kor: "대화를 나눈데에   책임이 없는 것은 아니었지만",
              Eng: "There was nothing irresponsible with having conversed, but ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 데(에)는",
          typicalExample: "【막 부둥켜안은 데는   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Circumstantial -(으)ㄴ 데(에)는: when it comes to ...-ing; with respect to/with regard to having.",
          sentences: [
            {
              Kor: "막 부둥켜안은 데는   ",
              Eng: "but when it came to her smothering it in her arms ...",
            },
          ],
        },
        {
          entryName: "-ㄴ데(요)",
          typicalExample: "【이거 50원인데요  ? 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "For Imminent Elaboration. Used on descriptive bases only. For past reference, -었는데(요) is used. ",
          sentences: [
            {
              Kor: "이거 50원인데요  ? ",
              Eng: "But this is only 50 wŏn ...(where's the rest of it?)",
            },
          ],
        },
        {
          entryName: "-ㄴ 동안 ",
          typicalExample: "【그는 윤호가 학교에 간 동안    】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "During the interval while something had happened; while.",
          sentences: [
            {
              Kor: "그는 윤호가 학교에 간 동안    ",
              Eng: "While Yunho was gone at school, ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 뒤(는) ",
          typicalExample:
            "【남자에게 심각한 얼굴로 헤어지자고 한 뒤   술을 마시고 싶다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "After ...-ing. ",
          sentences: [
            {
              Kor: "남자에게 심각한 얼굴로 헤어지자고 한 뒤   술을 마시고 싶다.  ",
              Eng: "I want to drink with a man after telling him, with a serious face, that I want to break up",
            },
            { Kor: "그가 돌아간 뒤   ", Eng: "after he had gone away …" },
            {
              Kor: "다시 마을로 돌아온 뒤는   ",
              Eng: "after returning to the village ...",
            },
            {
              Kor: "밖에서 제멋대로 이루어진 뒤   ",
              Eng: "after (these thoughts) had formed as they please outside of me ...",
            },
            {
              Kor: "장난삼아 그가 손가락에 낀 뒤  ",
              Eng: "after jokingly trying on (the ring) ...",
            },
            {
              Kor: "남기고 떠난 뒤였다 ",
              Eng: "The bus was already on its way, leaving only the dust behind it.",
            },
            {
              Kor: "텃밭엔 이미 김장 배추를 간 뒤였지만   ",
              Eng: "They had already taken the cabbages from the vetagable gardens for preparing kimch’I for the upcoming winter, but ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 뒤로 ",
          typicalExample: "【내가 좀 나이가 든 뒤로   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "After ...-ing; ever since ...-ing; subsequent to having ...-ed.",
          sentences: [
            {
              Kor: "내가 좀 나이가 든 뒤로   ",
              Eng: "after I had grown a bit older ...",
            },
            {
              Kor: "이 신당리로 떠나온 뒤로  는 ",
              Eng: "after coming to Sindangni ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 뒤로도 ",
          typicalExample: "【경욱과 헤어진 뒤로도   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Even after ...-ing; even ever since ..-ing; even subsequent to having ...-ed.",
          sentences: [
            {
              Kor: "경욱과 헤어진 뒤로도   ",
              Eng: "even after parting ways with Kyŏnguk ...",
            },
            {
              Kor: "나는 결혼한 뒤로는 ",
              Eng: "ever since/after getting married, I ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 뒤에 ",
          typicalExample: "【마누라 잃은 뒤엔   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "After ...-ing. ",
          sentences: [
            { Kor: "마누라 잃은 뒤엔   ", Eng: "after he lost his wife ..." },
          ],
        },
        {
          entryName: "-ㄴ 뒤에도",
          typicalExample: "【연주가 시작된 뒤에도   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Even after ...-ing.",
          sentences: [
            {
              Kor: "연주가 시작된 뒤에도   ",
              Eng: "even after the performance began ...",
            },
            {
              Kor: "그 목소리가 끊겨 버린 뒤에도    ",
              Eng: "even after the voice has hung up",
            },
          ],
        },
        {
          entryName: "-ㄴ 뒤에야",
          typicalExample: "【그 사내에게 비워준 뒤에야   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Only after ...-ing.",
          sentences: [
            {
              Kor: "그 사내에게 비워준 뒤에야   ",
              Eng: "only after emptying it for the guy ...",
            },
            {
              Kor: "내 집을 떠난 뒤에야 ",
              Eng: "only after he left my house ...",
            },
          ],
        },
        {
          entryName: "-ㄴ들 ",
          typicalExample: "【낸들   아나? 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Lit.: granted that, even though it is or does [often followed by a rhetorical question].",
          sentences: [
            {
              Kor: "낸들   아나? ",
              Eng: "How would I know? [lit.: even though it's me, would I know?] (Here from 나 I + copula -이- + -ㄴ들.)",
            },
            {
              Kor: "낸들   그대를 어찌 하리. ",
              Eng: "Even though it is [just] I ... what shall I do about you?",
            },
            {
              Kor: "쫓겨 가는 운명이거든 어디를 간들   신신하랴. ",
              Eng: "Go where you will, if it's one's fate is to be chased (away)—how could one ever be at peace/feel new and fresh regardless where one goes?  ",
            },
            {
              Kor: "그렇다고 한들 어디를 보나 살림 사는 아줌마일 뿐인 그녀 ",
              Eng: "My wife, who, even supposing that is the case, is just a housewife no matter how you look at it.",
            },
            {
              Kor: "하지만 오지 않은들   그게 무슨 큰일이랴.",
              Eng: "But even if he shouldn't come, what is the big deal in that?",
            },
            {
              Kor: "하지만 그런들   어떻단 말인가.  ",
              Eng: "But be that as it may, what can be done about it?",
            },
            {
              Kor: "호랑이보다 더한 짐승인들   ",
              Eng: "Granted there are beasts larger than the tiger, ... ",
            },
            {
              Kor: "어찌 막벌이인들   될까 ",
              Eng: "Granted that/even if it be work as a day-laborer, how could it work out?",
            },
            {
              Kor: "누가 밥인들   잘 줄까 ",
              Eng: "Who would give [even some paltry] food [to a healthy woman in her prime]?",
            },
            {
              Kor: "생의 어느 한때인들 그토록 절절히 고통에의 열망에 젖을 때가 있을까. ",
              Eng: "However much it might be a part of human life, there is no other moment in life when you would be that deeply emersed in the ardent eagerness for pain. (Here the form is on the copula -(이)ㄴ들 granted that it be/is; even though it be/is . . .)",
            },
          ],
        },
        {
          entryName: "-ㄴ들 ... -ㄴ들",
          typicalExample: "【불이 난들  , 싸움이 난들   무서울 것이 없이 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Whether/as (showing indifference to two or more options or choices. ",
          sentences: [
            {
              Kor: "불이 난들  , 싸움이 난들   무서울 것이 없이 ",
              Eng: "whether a fire broke out or a fight erupted, there was nothing to be afraid of, and…",
            },
          ],
        },
        {
          entryName: "-ㄴ 듯(이) ",
          typicalExample: "【남편과 딸이 근심스러운 듯이   물었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Like, as, giving the appearance of ...; appearing (to be), looking (like); seeming as if; as if/though. This pattern is often 'flagged' in front by the adverb 마치 which serves as a sort of warning to the speaker that a like/as if pattern in 듯이 or -(으)ㄴ/-는 것 처럼 is coming up.",
          sentences: [
            {
              Kor: "남편과 딸이 근심스러운 듯이   물었다  ",
              Eng: "My husband and daughter asked me, as if concerned.",
            },
            { Kor: "무료한 듯이   ", Eng: "as if bored" },
            { Kor: "궁금한 듯   물었다 ", Eng: "...asked, as if curious." },
            {
              Kor: "두 사내를 불안한 듯이   지켜보고 있었다 ",
              Eng: "[Paekhwa] was watching the two men anxiously.",
            },
            {
              Kor: "자기 마음엔 그렇지는 않은 듯   ",
              Eng: "It seemed like it wasn't the case to him.",
            },
            { Kor: "겸연쩍인 듯  ", Eng: "As though embarrassed ..." },
            {
              Kor: "모두 나 때문인 듯이   여겨졌던 것이다 ",
              Eng: "It felt as though it were somehow all my fault.",
            },
            {
              Kor: "넋나간 듯이   ",
              Eng: "as if having lost [her] spirit ...",
            },
            {
              Kor: "고물버스들이 지친 듯이   ",
              Eng: "the old buses, as if exhausted ...",
            },
            { Kor: "눈물이 괸 듯   ", Eng: "as if tears had welled up" },
            {
              Kor: "금시발복이나 된 듯   ",
              Eng: "as if he had suddenly become a millionaire or something ",
            },
            {
              Kor: "산삼을 찾아나선 듯이 ",
              Eng: "as if you were setting off to look for mountain ginseng",
            },
            {
              Kor: "온 산이 불덩이 속에 든 듯  ",
              Eng: "as if the whole mountain were placed inside a fireball",
            },
            {
              Kor: "서울서는 이런 개울물을 보지 못하기나 한듯이   ",
              Eng: "as if he had never seen a stream like his in Seoul (or something)",
            },
            {
              Kor: "곧바로 평형을 되찾은 듯   보였다 ",
              Eng: "He immediately seemed to regain his composure. ",
            },
            {
              Kor: "급류 앞에 선 듯 어찔어찔했다 ",
              Eng: "She felt dizzy as if standing in front of a swift current.",
            },
            {
              Kor: "모욕을 당한 듯이  ",
              Eng: "as if she had suffered an insult",
            },
            {
              Kor: "미아(迷兒)가 된 듯   막막했다 ",
              Eng: "She felt as bleak and hopeless as if she had become a lost child.",
            },
            {
              Kor: "잠든 듯   고요하던 마을 ",
              Eng: "the village as still as if it were asleep ...",
            },
            {
              Kor: "안개는 씻은 듯   벗겨졌다 ",
              Eng: "The fog peeled off as if it had been rinsed away.",
            },
            {
              Kor: "그럼 고만두란 듯이   (고만두란 ← 고만두라[고 하]ㄴ)",
              Eng: "as if to say 'Well then, let's forget the whole thing' ...",
            },
            {
              Kor: "집 속에 쥐 죽은 듯이  ",
              Eng: "the villagers had stayed inside so quietly …  (NB: 쥐 죽은 듯이 is a stock phrase meaning quiet as a mouse [lit.: quiet as if all the mice had died])",
            },
            {
              Kor: "고개만 미친 듯이   저으며 ",
              Eng: "all the while doing nothing but shake her head, as if she had gone insane ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 듯싶다",
          typicalExample: "【조선의 얼굴을 똑똑히 본 듯싶었다   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier clause in -(으)ㄴ + 듯 + 싶-: it feels/seems like, as if maybe; think to oneself that... On processive bases, the sense is past tense: seems/feels as though it did/was. On descriptive bases the sense is non-past: seems/feels as though it is.",
          sentences: [
            {
              Kor: "조선의 얼굴을 똑똑히 본 듯싶었다   ",
              Eng: "I felt as though I had had a clear view of the face of Korea.",
            },
            {
              Kor: "패잔병인 듯싶은   ",
              Eng: "who appeared to be stragglers from the defeated [North Korean] army ...",
            },
            {
              Kor: "아무래도 우리 모두의 복인듯 싶다   ",
              Eng: "It feels as if it is, after all, blessedly lucky for us.  ",
            },
          ],
        },
        {
          entryName: "-ㄴ 듯하다",
          typicalExample: "【죽은 듯한 정적 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier + 듯 (하다) ( i.e., -ㄴ 듯하다, -ㄹ 듯하다, -는 듯하다): is like, is as (if) is like, is as (if).",
          sentences: [
            { Kor: "죽은 듯한 정적 ", Eng: "a dead silence" },
            {
              Kor: "10년 가량은 늙어진 듯하였다  ",
              Eng: "seemed to have aged approximately 10 years",
            },
            {
              Kor: "시절에 맞추어 고른 듯한   핸드빽 ",
              Eng: "a handbag, as if chosen to fit the season ...",
            },
            {
              Kor: "벼랑 끝에 선 듯한   ",
              Eng: "[the dreariness/bleakness] that felt as if one was standing on the edge of a cliff",
            },
            {
              Kor: "아무렇게나 걸친 듯한   옷차림 ",
              Eng: "attire which seemed as if it had been thrown on any old which way",
            },
            {
              Kor: "연인인듯한   사람들이 ",
              Eng: "two people who appeared to lovers",
            },
            {
              Kor: "대학생인 듯한   청년 서넛이 ",
              Eng: "three or four young guys/gals who appeared to be college students",
            },
            {
              Kor: "나와 같은 심정인 듯했다   ",
              Eng: "they were in the same state of mind as myself",
            },
            {
              Kor: "그들은 농사 관계의 시찰원들인 듯했다   ",
              Eng: "they appeared to be inspectors with some connection to farming",
            },
            {
              Kor: "가맣게 잊고 있은 듯했다   ",
              Eng: "it was as if he had completely forgotten",
            },
            {
              Kor: "처량한 듯한   그 음악소리 ",
              Eng: "the desolate-sounding music",
            },
          ],
        },
        {
          entryName: "-ㄴ 때",
          typicalExample: "【상륙하는 사람이 여자인 때  는 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier + 때: the time/occasion when... Usually only with descriptive verbs like the copula, but occasionally also on processive bases, in which case the reference is past tense. ",
          sentences: [
            {
              Kor: "상륙하는 사람이 여자인 때  는 ",
              Eng: "whenever one of the disembarking passengers was a woman...",
            },
            {
              Kor: "물건을 산 때  의 마음도 ",
              Eng: "even when he was buying things, his mood ...",
            },
            {
              Kor: "물론 그것을 얻은 때  는 불도 거의 탄 새벽녘이었으나 ",
              Eng: "Of course, the time when he had obtained this thing was at dawn of the day, by then the torch that had stayed lit overnight had almost died out ... ",
            },
          ],
        },
        {
          entryName: "-ㄴ 때문",
          typicalExample: "【돌아다닌 때문   같았다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The postnoun 때문: reason; because (of); (for) the sake of, usually occurs after summative -기 or regular nouns, but can also occur (rarely) as a postmodifier.",
          sentences: [
            {
              Kor: "돌아다닌 때문   같았다 ",
              Eng: "seemed as though it was because of having cruised around",
            },
          ],
        },
        {
          entryName: "-ㄴ 뜻에서 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "From the purpose/meaning/sense of",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ 만큼 ",
          typicalExample: "【우선 교통이 불편한 만큼   】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "In as much as, in so far as, since (had done /been/used to be/used to do); to the extent that (had done /been/used to be/used to do).",
          sentences: [
            {
              Kor: "우선 교통이 불편한 만큼   ",
              Eng: "first of all, in so far as traffic was inconvenient ...",
            },
            {
              Kor: "꼭 필요한 만큼  의 동작만 ",
              Eng: "lit.: movements only in the amount which were absolutely necessary",
            },
            {
              Kor: "힘을 썼던 것이 부끄러운만큼   ",
              Eng: "to the extent that he was ashamed about having fought for the Japanese ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 면이 있다",
          typicalExample: "【오계장도 비범한 면이 있소  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Have sides that ...; have qualities that ... ",
          sentences: [
            {
              Kor: "오계장도 비범한 면이 있소  ",
              Eng: "Head forester O also has unique qualities.",
            },
          ],
        },
        {
          entryName: "-ㄴ 모양(으로)",
          typicalExample:
            "【그러면서 그들이 하는 불평은, 강마담 저년은 비너스 귀신이 될 작정인 모양 이라는 것이 고작이었다.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Appearance, a look, an air; signs, indications, symptoms; a way, manner.",
          sentences: [
            {
              Kor: "그러면서 그들이 하는 불평은, 강마담 저년은 비너스 귀신이 될 작정인 모양 이라는 것이 고작이었다.",
              Eng: "And the only form of complaint they ever expressed was, that it seemed as though [she] was determined to become the ghost of Venus tabang.",
            },
          ],
        },
        {
          entryName: "-ㄴ 모양이다 ",
          typicalExample: "【맞게 찾아온 모양이군요  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "A 모양 is an appearance, look, or air (signs, indications, symptoms way, manner). Thus, on processive bases, this pattern means looks like it did, appears to have done, while on descriptive bases it means appears to be, seems to be. ",
          sentences: [
            {
              Kor: "맞게 찾아온 모양이군요  ",
              Eng: "it seems as if I have come to the right place",
            },
            {
              Kor: "남편 일행은 적잖은 돈을 앗긴 모양이었다 .  ",
              Eng: "It seemed her husband and his group had been deprived of/had lost a considerable amount of money.",
            },
            {
              Kor: "지수의 몸이 먼저 아이가 담긴 양수를 밀어내기로 한 모양이었다 . ",
              Eng: "It appeared that Chisu's body had decided to be the first to push out the waters encasing the baby.",
            },
            {
              Kor: "말하고 싶은 모양이었다  ",
              Eng: "it appeared as though he wanted to speak",
            },
          ],
        },
        {
          entryName: "-ㄴ 무렵",
          typicalExample: "【임신한 지 5개월에 접어든 무렵이었다 .  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The (approximate) time/occasion when (something happened ~ had happened). Usually you will see postmodifier 무렵 following the prospective modifier -(으)ㄹ, but here it is on modifier -(으)ㄴ.",
          sentences: [
            {
              Kor: "임신한 지 5개월에 접어든 무렵이었다 .  ",
              Eng: "It was at about the time when she was getting past having been pregnant for five months.",
            },
          ],
        },
        {
          entryName: "-ㄴ 바람에",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier + postmodifier 바람: (in) conjunction (with); (in) the process (of); (as a) consequence (of); (as a) result (of); impetus, momentum, influence.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ 반면 ",
          typicalExample: "【산의 윤곽이 더욱 날카로와진 반면  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Whereas on the one hand ...",
          sentences: [
            {
              Kor: "산의 윤곽이 더욱 날카로와진 반면  ",
              Eng: "whereas on the one hand, the mountain's features were even more sharp ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 법이다 ",
          typicalExample: "【양반의 새끼는 어려선 미운 법이라 했다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "There is reason to expect; it is (or seems) reasonable that ...; it can be expected that ...; it ought (it is supposed) to be that ...This pattern is encountered frequently in proverbs, as in the first example below.",
          sentences: [
            {
              Kor: "양반의 새끼는 어려선 미운 법이라 했다  ",
              Eng: "[The grown-ups] would say that the children of gentry are nasty little buggers when they are young.",
            },
          ],
        },
        {
          entryName: "-ㄴ 보람(이) 있다",
          typicalExample: "【동양 삼국옷을 한 몸에 감은 보람이 있어  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Postmodifier 보람: usefulness, worth, effect, here in a pattern meaning it had been rewarding/worthwhile/beneficial to have done ...",
          sentences: [
            {
              Kor: "동양 삼국옷을 한 몸에 감은 보람이 있어  ",
              Eng: "[He] had gained some benefit from wrapping himself in the attire of the three East Asian nations, so ...",
            },
            {
              Kor: "확실히 간 보람은 있었다  ",
              Eng: "Assuredly, it had been worthwhile to go.",
            },
          ],
        },
        {
          entryName: "-ㄴ 성싶다 ",
          typicalExample: "【치아가 튼튼하라는 뜻인 성싶다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Seems/appears to have been  [processive] or to be  [descriptive] the case that); I guess (that).",
          sentences: [
            {
              Kor: "치아가 튼튼하라는 뜻인 성싶다  ",
              Eng: "As if it were in order to make your teeth hard.",
            },
            {
              Kor: "정녕 그것인 성싶었다 ",
              Eng: "It seemed for certain that it was the case. ",
            },
            {
              Kor: "아주 버릇이 되어 버린 성싶었다  ",
              Eng: "It appeared to have become an inveterate habit.",
            },
            {
              Kor: "분명 불에도 취한 성싶었다 ",
              Eng: "He was clearly drunk from the flames",
            },
          ],
        },
        {
          entryName: "-ㄴ 셈으로",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Postmodifier 셈 followed by the particle 으로: with the calculation, conjecture,supposition; accounting (for).",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ 셈이다",
          typicalExample: "【영리한 권유를 내게 한 셈이었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier -(으)ㄴ + postmodifier 셈 the calculation, conjecture, supposition; accounting (for) from the verb 세- count, reckon. Other meanings are (judgement based on) appearances, to all appearances/indications, that one might call ... Literally, then: the calculation or reckoning is that [somebody/something did something] or… In the case of descriptive bases, the calculation or reckoning is that [somebody/something is a certain way]. More colloquially, as good as did/was/is . . .  or for all practical purposes, did/was/is, etc. ",
          sentences: [
            {
              Kor: "영리한 권유를 내게 한 셈이었다 ",
              Eng: "it seemed like [they gave me] very good advice",
            },
            {
              Kor: "전보다 훨씬 준 셈이다  ",
              Eng: "in effect, it had been much curtailed (줄다) compared to before",
            },
            {
              Kor: "절은 사방 산으로 가리워진 셈이다  ",
              Eng: "the temple seemed to be concealed by mountains on all four sides",
            },
            {
              Kor: "새벽에 몸만 간신히 빠져나온 셈인데  ",
              Eng: "one might say that I barely escaped with my life at dawn ...",
            },
            {
              Kor: "대가 끊긴 셈이지  ",
              Eng: "I guess that means their family line has been cut off",
            },
            {
              Kor: "뛰어든 셈이었다  ",
              Eng: "it was as if [he] had plunged into ...",
            },
            {
              Kor: "김 영감 편에서 투정을 건 셈이다  ",
              Eng: "It was more like old man Kim had started the fight.",
            },
            {
              Kor: "중실에게 엉뚱한 분풀이가 돌아온 셈이었다  ",
              Eng: "It seemed as if Chungsil was the butt of his extreme rage.",
            },
            {
              Kor: "추워진 날씨 덕을 본 셈이다  ",
              Eng: "The cold turn in the weather was paying off.",
            },
            { Kor: "[Examples?]", Eng: "" },
          ],
        },
        {
          entryName: "- ㄴ숭만숭 하- ",
          typicalExample: "【그저 본숭만숭이었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Hardly seem to be doing it; seem one moment like one is doing it, then seem the next moment like one isn't doing it ...",
          sentences: [
            {
              Kor: "그저 본숭만숭이었다  ",
              Eng: "he carelessly glanced them over",
            },
          ],
        },
        {
          entryName: "-ㄴ 양",
          typicalExample: "【생명 없는 물체인 양   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄴ 양, i.e., post-modifier 양 as an abbreviation of 모양: appearance, shape, form; signs, indications; state of affairs: as if to, in a manner suggestive of ... ",
          sentences: [
            {
              Kor: "생명 없는 물체인 양   ",
              Eng: "as if he were an inert/lifeless object",
            },
          ],
        },
        {
          entryName: "-ㄴ 연후(에)",
          typicalExample: "【녹초가 된 연후였지만  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "After, past, beyond.",
          sentences: [
            {
              Kor: "녹초가 된 연후였지만  ",
              Eng: "We were way past the point of exhaustion, but ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 예감이 든다",
          typicalExample: "【오지 않게 될 것 같은 예감이 들었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄴ 예감이 드-ㄹ-: to have a feeling that, to have a hunch that ...",
          sentences: [
            {
              Kor: "오지 않게 될 것 같은 예감이 들었다  ",
              Eng: "I had a feeling that I wouldn't be coming back.",
            },
          ],
        },
        {
          entryName: "-ㄴ 외에 ",
          typicalExample: "【우리 우물을 길은 외에  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Apart from/aside from having done, besides having done.",
          sentences: [
            {
              Kor: "우리 우물을 길은 외에  ",
              Eng: "not only had [Mr. Yi's mother come early] to fetch water from our well ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 이래 ",
          typicalExample: "【뻐스를 갈아탄 이래  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "-(으)ㄴ 이래: Ever since . . . -ing.",
          sentences: [
            {
              Kor: "뻐스를 갈아탄 이래  ",
              Eng: "ever since we got on the bus ...",
            },
            {
              Kor: "도마뱀이 내 몸 속으로 들어오기 시작한 이래  ",
              Eng: "ever since the lizard started coming inside me ",
            },
          ],
        },
        {
          entryName: "-ㄴ 이후(에)",
          typicalExample: "【우리 집에 들어온 이후 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "After ...-ing, after having done.",
          sentences: [
            {
              Kor: "우리 집에 들어온 이후 ",
              Eng: "after having entered our home ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 정도(로) ",
          typicalExample: "【싸늘한 정도로  상쾌한 산냄새 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "To the point that.",
          sentences: [
            {
              Kor: "싸늘한 정도로  상쾌한 산냄새 ",
              Eng: "the mountain fragrance, subtly refreshing ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 줄(도) 모른다",
          typicalExample:
            "【내가 어디서 굴러온 줄도 모르는  말라빠진 음악선생 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Not to (even) know/ to be unaware (even) that it is ... ",
          sentences: [
            {
              Kor: "내가 어디서 굴러온 줄도 모르는  말라빠진 음악선생 ",
              Eng: "the bony teacher that showed up for me from who-knows-where ...",
            },
            {
              Kor: "단지까지 갔다 오고도 나는 피로한 줄도 몰랐다 .  ",
              Eng: "Even after going all the way to the apartment complex and back I don't feel tired",
            },
          ],
        },
        {
          entryName: "-ㄴ 줄(을) 모른다",
          typicalExample: "【술꾼인 줄은 몰랐는데  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Not to know that / be unaware that it is ...",
          sentences: [
            {
              Kor: "술꾼인 줄은 몰랐는데  ",
              Eng: "I didn't know we had a big drinker here …",
            },
            {
              Kor: "나는 아픈 줄을 몰랐다 ",
              Eng: "I was unaware of the fact that I was hurt.",
            },
          ],
        },
        {
          entryName: "-ㄴ 줄(로) 모른다",
          typicalExample: "【이렇게 예쁜 줄은 몰랐네  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Postmodifier 줄 (following the -(으)ㄴ modifier) the assumed fact; likelihood, probability [+ (로) + verb of thinking]: has no idea/doesn't know that it did (with processive verbs); has no idea/doesn't know that it is (with descriptive verbs).",
          sentences: [
            {
              Kor: "이렇게 예쁜 줄은 몰랐네  ",
              Eng: "I had no idea they were so pretty!",
            },
          ],
        },
        {
          entryName: "-ㄴ 줄(로) 안다 ",
          typicalExample: "【재미 본 게 댁뿐인 줄 아쇼 ? 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄴ 줄(로) 아-ㄹ-: postmodifier 줄 following the -(으)ㄴ modifier means the assumed fact; likelihood, probability. Followed by (로) + verb of thinking, this pattern means: presumes/assumes that it did (with processive verbs); ",
          sentences: [
            {
              Kor: "재미 본 게 댁뿐인 줄 아쇼 ? ",
              Eng: "Do you think you were the only one who had a good time?",
            },
            {
              Kor: "삼포가 여기서 몇 린 줄 아쇼 ? (몇 린 줄 아쇼 for 몇 리 인줄 아시오 ?) ",
              Eng: "Do you know how many li Samp'o is from here? (i.e., It's far!)",
            },
            {
              Kor: "빈 것인 줄 알면서도  ",
              Eng: "even while knowing it was empty",
            },
            {
              Kor: "손님인 줄 알고  ",
              Eng: "assuming that he was a guest, [the madam ...]",
            },
            {
              Kor: "어느 땐 줄 알구  코만 고누 = 어느 때인 줄 알고  코만 고노? ",
              Eng: "lit.: assuming/thinking that it is what time ...?, i.e., what time do you think it is anyway snoring away like that) ...?",
            },
            {
              Kor: "돈이 보통 들어간 줄 아나  ",
              Eng: "Do you think that she cost me a normal sum of money ? (i.e., she cost me a bundle!)",
            },
          ],
        },
        {
          entryName: "-ㄴ 줄(로) 알았다",
          typicalExample: "【그저 분교 근무나 시키는 줄 알았어요  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄴ 줄(로) 알았다-: Thought that, was under the impression that. ",
          sentences: [
            {
              Kor: "그저 분교 근무나 시키는 줄 알았어요  ",
              Eng: "I assumed that he is merely coming to supervise the school's work or something. ",
            },
            {
              Kor: "당연히 가계부인 줄 알았다 . 그런데 일기장이었다. ",
              Eng: "I assumed it must be her household accounts book. But it was a diary.",
            },
            {
              Kor: "나는 아주 치사한 건달인 줄 알았어  ",
              Eng: "I assumed that you were a mean, good-for-nothing bum.",
            },
          ],
        },
        {
          entryName: "-ㄴ 줄만 안다",
          typicalExample: "【그게 사내의 농담인 줄만 알고  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄴ 줄만 아-ㄹ-: to just presume/think that ... ; to assume only (often erroneously) that ...",
          sentences: [
            {
              Kor: "그게 사내의 농담인 줄만 알고  ",
              Eng: "thought it was still a joke on the fella's part ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 줄이나 안다",
          typicalExample: "【김생 구여운 줄이나 알겠니 ? 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Usually as a rhetorical question): How would he/she/it/they know?",
          sentences: [
            {
              Kor: "김생 구여운 줄이나 알겠니 ? ",
              Eng: "Since when would they appreciate a cute animal? (Note also dialect 김생 for 짐승 and dialect 구여운 for 귀여운.)",
            },
          ],
        },
        {
          entryName: "-ㄴ 중에도 ",
          typicalExample: "【그런 중에도  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "In spite of being in the middle of all this ... ; even in the middle of all of this.",
          sentences: [
            { Kor: "그런 중에도  ", Eng: "even in spite of all that ..." },
          ],
        },
        {
          entryName: "-ㄴ즉 ",
          typicalExample: "【버리지 않을 것인즉  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "If/when ... ; as since, now that; so far as (it is) concerned, speaking of. Somewhat literary = -으메 or -으면은 since, as, when.",
          sentences: [
            {
              Kor: "버리지 않을 것인즉  ",
              Eng: "in so far as it was something that was thrown out ...",
            },
            {
              Kor: "가까이 가 본즉  마침 마당에서 놀고 있던 서너 살 돼 보이는 사내아이가 낯선 사람을 보고 뒤꼍으로 급히 돌아가더니 이윽고 어미일 듯싶은 아낙네의 손을 잡고 나오는 것이었다. ",
              Eng: "When I approached, a child who happened to be playing in the front yard - who looked about three or four years old - upon sighting a stranger, ran to the back of the house and came back holding onto a lady's hand who seemed to be his mother.",
            },
          ],
        },
        {
          entryName: "-ㄴ지",
          typicalExample: "【사기꾼인지  강돈지 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Oblique/indirect question in -ㄴ지. Modifier [-(으)ㄴ, -던, -었던, -었는, -는] + postmodifier 지 the uncertain fact whether; perhaps because. When left hanging in mid-sentence, you can interpret the pattern as having left out a following (도) 모르지만 it may have been the case that or it is uncertain WH... [but...]; perhaps it is/was ... ; maybe it is/was ... . With an accompanying question word, the pattern is best understood as an ellipsis for one doesn't know what/why/when/where, etc., but ...",
          sentences: [
            {
              Kor: "사기꾼인지  강돈지 ",
              Eng: "whether he might be a swindler or a burglar ...",
            },
            { Kor: "그래서 그런지   ", Eng: "maybe that's why ..." },
            {
              Kor: "책가방이 주체스러운 모양인지  ",
              Eng: "perhaps because their bookbags appeared to be hard to handle ...",
            },
            {
              Kor: "그것도 자기 팔자소간인지  ",
              Eng: "it may be that this was his fate...",
            },
            {
              Kor: "만족하는 때문인지  ",
              Eng: "perhaps it was because of my satisfaction ...",
            },
            {
              Kor: "태도 때문인지  ",
              Eng: "perhaps it was because of (his) youthful freshness and brimming self-confident attitude, (but anyhow) ...",
            },
            {
              Kor: "어떻게 들으라고 하는 소리인지  ",
              Eng: "lit.: perhaps it is something she said as if to say, somehow, listen to this",
            },
            {
              Kor: "추위도 그럴 징조인지  ",
              Eng: "lit.: perhaps the cold, too, was an omen that [the weather] would be that way, but [in any case] ...",
            },
          ],
        },
        {
          entryName: "Question-word + -ㄴ 지",
          typicalExample: "【몰라, 무슨 일인지 .  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "When -ㄴ 지 is paired with a question word, we can imagine a dropped 모르지만 I have no idea since when, but ... ",
          sentences: [
            {
              Kor: "몰라, 무슨 일인지 .  ",
              Eng: "no idea what it might be; don't know what's the matter",
            },
            {
              Kor: "언제부터인지  고등학교 동창을 만나도 불알 친구라는 다정함이 없다.  ",
              Eng: "I don't know since when it started, but now even when my meet my high school classmates, the chumminess of old bosom buddies is gone.",
            },
            {
              Kor: "오늘따라 왜 이리 마음에 걸리는 게 많은지 ",
              Eng: "I wonder why today, of all today, there should be so many things on my mind",
            },
            {
              Kor: "무슨 결점으로인지  [모르지만] ",
              Eng: "lit.: I don't know on account of what defect it was, but ... = for/on account of heaven-knows-what defect",
            },
            {
              Kor: "누구의 입에서부터인지  퍼져 나왔다 ",
              Eng: "…weren't sure from whose mouth it had originated, but it had spread (spread, though heaven knows who started the rumor)",
            },
            { Kor: "그 어떤 이유에선지  ", Eng: "for some reason ..." },
            {
              Kor: "춘향을 어떻게 처분하려던 것인지  ",
              Eng: "… didn't know how to take care of Ch'unhyang. ",
            },
            {
              Kor: "어느 결엔지  ",
              Eng: "before he realized [in which instant it was] ...",
            },
            {
              Kor: "드디어 산을 넘어 어디론지  ",
              Eng: "In the end [they] went over the mountain and headed off somewhere (or other).",
            },
            {
              Kor: "언제적 내린 눈인지 ",
              Eng: "goodness knows how long ago the snow had fallen on it ...",
            },
            {
              Kor: "그들도 어디론지  도망쳤다 ",
              Eng: "They also fled somewhere.",
            },
            { Kor: "누군지가", Eng: "someone or other ..." },
            { Kor: "어디선지", Eng: "from somewhere or other" },
            {
              Kor: "어디서 흥정이 한 자리 떨어지느라고인지 ",
              Eng: "perhaps because he was caught up with a business transaction 'going down' somewhere ",
            },
            { Kor: "어디로인지 ", Eng: "somewhere or other ..." },
          ],
        },
        {
          entryName: "-ㄴ지고 ",
          typicalExample: "【서른지고 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation: "Poetic exclamation with descriptive verbs: How ... !",
          sentences: [{ Kor: "서른지고 ", Eng: "How pitiful!" }],
        },
        {
          entryName: "-ㄴ지 구별(이) 안 가다",
          typicalExample: "【음식맛이 단지  짠지조차 구별이 안갔다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Can't tell whether ...",
          sentences: [
            {
              Kor: "음식맛이 단지  짠지조차 구별이 안갔다  ",
              Eng: "couldn't even distinguish the mere taste of [salted radish]",
            },
          ],
        },
        {
          entryName: "-ㄴ 지도",
          typicalExample:
            "【골프 장비를 대여하는 일을 하다 그만둔 지도  이 년이 되었다고 했다.  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation: "-(으)ㄴ 지도: even since ...-ing (for time elapsed). ",
          sentences: [
            {
              Kor: "골프 장비를 대여하는 일을 하다 그만둔 지도  이 년이 되었다고 했다.  ",
              Eng: "He said it had been two years since he had quit his job of renting out golf equipment.",
            },
          ],
        },
        {
          entryName: "-ㄴ지(도) 모른다",
          typicalExample: "【들려 주기를 기대한 건지도 모른다 .  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄴ지(도) 모른다: lit.: doesn't know whether it might perhaps be (with descriptive verbs), hence, just perhaps might be. ",
          sentences: [
            {
              Kor: "들려 주기를 기대한 건지도 모른다 .  ",
              Eng: "or maybe I had even hoped that it would sound to her [like 'go out and get some fresh air or something']",
            },
            {
              Kor: "그렇지 않은지도 모른다 ",
              Eng: "maybe they weren't, either",
            },
          ],
        },
        {
          entryName: "-ㄴ지 모를 ",
          typicalExample:
            "【나는 어떤 집인지 모를  불 켜진 창을 올려다보며  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Question-word ...-(으)ㄴ지 모를 NOUN: a NOUN for which one has no idea WH- ... . ",
          sentences: [
            {
              Kor: "나는 어떤 집인지 모를  불 켜진 창을 올려다보며  ",
              Eng: "looking up at the lit window of some unknown person's home",
            },
          ],
        },
        {
          entryName: "- ㄴ지 몰라도 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Even though one doesn't know ['the uncertain fact'] whether, what, how, why, etc. Usually in a bigger pattern of the type: I don't know (or nobody knows) A, but B ...",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ지라(서) ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "As/since it does; it is a fact that ... ; grounds for, reason for (equivalent to colloquial -드니, according to Roth). Usually for a ceremonious/solemn pronouncement.  ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ지(요)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation: "Used as a polite indirect question.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ 참이었다",
          typicalExample: "【산호섬으로 떠난 참이었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄴ 참이었다: had just finished ...-ing; it was the juncture when something had only just happened. ",
          sentences: [
            {
              Kor: "산호섬으로 떠난 참이었다 ",
              Eng: "they had just departed for the coral island",
            },
          ],
        },
        {
          entryName: "-ㄴ 채(로)",
          typicalExample: "【버스는 텅텅 비다시피 한채  떠났다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier -(으)ㄴ + postmodifier 채: just as it is or did, (in) the original state of ...",
          sentences: [
            {
              Kor: "버스는 텅텅 비다시피 한채  떠났다 ",
              Eng: "the bus took off in the state of being nearly empty",
            },
            {
              Kor: "반반한 채  ",
              Eng: "[with her face being always] pretty ...",
            },
            {
              Kor: "그것조차 아리숭한 채  ",
              Eng: "even it was still unclear to me ...",
            },
            {
              Kor: "빈 입인 채  ",
              Eng: "lit.: with his mouth still in its empty state",
            },
            {
              Kor: "차라리 종이 노끈인 채 ",
              Eng: "[decided to use them] just as they were, with their paper cord",
            },
            {
              Kor: "소위 청백한 채로  ",
              Eng: "in the original state of (his) so-called uprightness ...",
            },
            {
              Kor: "울상이 된 채  ",
              Eng: "being in the state I was, frustrated to the verge of tears ...",
            },
            {
              Kor: "치매가 된 채  ",
              Eng: "in her state of suffering from dementia ...",
            },
            {
              Kor: "그 자리에 못박힌 채  ",
              Eng: "as if nailed to the spot ...",
            },
            {
              Kor: "가방을 손에 든 채  ",
              Eng: "with his backpack in his hand ...",
            },
            {
              Kor: "읽던 신문을 손에 든 채  ",
              Eng: "with the newspaper that I had been reading in my hand ...",
            },
            {
              Kor: "아내의 눈길을 무시한 채  ",
              Eng: "lit.: in the state of ignoring my wife's eye ",
            },
            {
              Kor: "아내의 비난을 무시한 채 ",
              Eng: "ignoring my wife's criticism ",
            },
            {
              Kor: "있는 대로 다 잠근 채  ",
              Eng: "all locked just as they were ...",
            },
            {
              Kor: "엎드려 누운 채  나는 전보를 펴 보았다 ",
              Eng: "I opened the telegram lying flat as I had been.",
            },
            { Kor: "입을 딱 벌린 채 ", Eng: "with his mouth wide open ..." },
            {
              Kor: "영달이는 입을 벌린 채  ",
              Eng: "Yŏngdal, with his mouth gaping ...",
            },
            {
              Kor: "입을 벌린 채  ",
              Eng: "lit.: with his mouth [still] in a state resulting from having been opened; gaping",
            },
            {
              Kor: "얼굴을 감싼 채  ",
              Eng: "covering his face (lit.: with his face still covered)",
            },
            { Kor: "몸을 숨긴 채  ", Eng: "always kept hidden ..." },
            { Kor: "바위에 기댄 채  ", Eng: "leaning on a boulder ..." },
            { Kor: "엷은 미소를 띤 채 ", Eng: "smiling a thin smile, he ..." },
            {
              Kor: "양씨가 내 손을 꼭 잡은 채  속삭였다 ",
              Eng: "Grasping my hand tightly, Mr. Yang whispered.",
            },
            { Kor: "회색으로 퇴색한 채  ", Eng: "its color faded to grey ..." },
            {
              Kor: "어두워지는 숲에 싸인 채  ",
              Eng: "in the state of being surrounded by a darkening forest",
            },
            {
              Kor: "벌거벗은 채 ",
              Eng: "lit.: still in her original state of having stripped naked ",
            },
            { Kor: "뚜껑이 열린 채  ", Eng: "[her lipstick], uncapped …" },
            {
              Kor: "물 속에 자신을 맡긴 채  ",
              Eng: "lit.: still in a state resulting from having immersed himself in the water",
            },
            {
              Kor: "불을 켠 채  ",
              Eng: "with the lights [still] turned on ...",
            },
            {
              Kor: "왜병의 옷을 그냥 입은 채  ",
              Eng: "[they came into the village] still wearing Japanese military uniforms ...",
            },
            {
              Kor: "이장 영감은 고개를 수그린 채  주억주억하였다 ",
              Eng: "The village head nodded, his head bowed. ",
            },
            {
              Kor: "이장영감은 여전히 눈을 감은 채 ",
              Eng: "the village head, his eyes still closed ...",
            },
            {
              Kor: "학의 둥우리만이 빈 채  달려 있었다 ",
              Eng: "Only the crane's nest hung empty in the tree.",
            },
            {
              Kor: "돌아앉은 채 였다  ",
              Eng: "was just sitting there with his back to [me] ...",
            },
            {
              Kor: "여자는 의자에 궁둥이를 붙인 채로  ",
              Eng: "the woman, with her buttocks glued to the chair ...",
            },
            { Kor: "고개를 숙인 채로  ", Eng: "with their heads down ..." },
            {
              Kor: "정씨는 고개를 밑으로 숙인 채로  ",
              Eng: "Mr. Chŏng, with his head lowered, ...",
            },
            {
              Kor: "백화는 얼굴이 젖은 채로  웃고 있었다",
              Eng: "Paekhwa was smiling, her face wet [with tears]",
            },
            {
              Kor: "입술을 뾰죽이 한 채로  ",
              Eng: "with her lips still puckered",
            },
            {
              Kor: "노루를 산 채로 붙들면 ",
              Eng: "if he keeps the deer just as they are ...",
            },
            {
              Kor: "관심을 내보이지 않은 채  ",
              Eng: "(in a state of) not showing any particular interest ...",
            },
            {
              Kor: "관목 숲에서 눈을 떼지 않은 채  물었다 ",
              Eng: "He asked, without taking his eyes off the shrubbery.",
            },
            { Kor: "혼자서 아무것도 안 든 채  ", Eng: "alone, empty-handed" },
            {
              Kor: "비틀어진 채 ",
              Eng: "[the white laundry was in its original] twisted [state]",
            },
            {
              Kor: "마른 갈대밭이 헝클어진 채  ",
              Eng: "the dried, tangled field of reeds ...",
            },
            {
              Kor: "우표가 붙여진 채 ",
              Eng: "[his face, still] in the state of having a stamp attached to it ...",
            },
            {
              Kor: "불이 붙여진 채  ",
              Eng: "[the new cigarette], [still] in the state of having been lit",
            },
            {
              Kor: "주임을 뒤에 남겨 놓은 채  도서관을 나왔다 ",
              Eng: "I came out of the library leaving the librarian behind [as she was].",
            },
          ],
        },
        {
          entryName: "-ㄴ 척한다 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier + 척하- (= -ㄴ 체하-): pretend to ... ; act as if ...",
          sentences: [
            { Kor: "[Examples?]", Eng: "" },
            {
              Kor: "관광호텔에서 묵은 척하려면  ",
              Eng: "in order to pretend that I had stayed in a tourist hotel ...",
            },
            {
              Kor: "길수는 들은 척 도 안했다 ",
              Eng: "Kilsu didn't even pretend that he heard.",
            },
          ],
        },
        {
          entryName: "-ㄴ 체한다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier + 체하- (= -ㄴ 척하-): pretend to (do, have done, be).",
          sentences: [
            { Kor: "[Examples?]", Eng: "" },
            { Kor: "못 들은 체  ", Eng: "pretending not to hear, [Kilsu ...]" },
            {
              Kor: "길수는 못들은 체했다 ",
              Eng: "Kilsu pretended not to hear",
            },
            { Kor: "못 들은 체 했다  ", Eng: "pretended not to have heard" },
            {
              Kor: "이장 영감은 못들은 체  ",
              Eng: "pretending not to hear, the village head ...",
            },
            { Kor: "나는 놀란 체  했다  ", Eng: "I pretended to be surprised" },
            {
              Kor: "먼저 못본 체  ",
              Eng: "[he] first pretended not to notice and ...",
            },
          ],
        },
        {
          entryName: "-ㄴ 탓에",
          typicalExample: "【그러나 잠이 덜 깬 탓에   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄴ 탓에 = -(으)ㄴ 탓으로: on account of, because of, due to (usually for some less-than-positive reason), where 탓 is a grounds, reason or fault. ",
          sentences: [
            {
              Kor: "그러나 잠이 덜 깬 탓에   ",
              Eng: "but because she is still only half-awake",
            },
          ],
        },
        {
          entryName: "-ㄴ 탓으로",
          typicalExample: "【심한 노동에 지친 탓으로 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Postmodifier 탓 reason, ground(s), fault + 으로: on account of, because of, by reason of.",
          sentences: [
            {
              Kor: "심한 노동에 지친 탓으로 ",
              Eng: "as a result of/on account of exhaustion from severe physical labor",
            },
            {
              Kor: "끄지 않고 나간 탓으로  ",
              Eng: "on account of [her] having gone out without turning off [the transistor]",
            },
          ],
        },
        {
          entryName: "- ㄴ 탓이다",
          typicalExample: "【필요 없을 만큼 그가 건강한 탓이 기도 했다 .】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄴ 탓이다: be due to [the unpleasant or regrettable fact that...], be the fault of having ... with post-modifier 탓 fault, reason.",
          sentences: [
            {
              Kor: "필요 없을 만큼 그가 건강한 탓이 기도 했다 .",
              Eng: "It was also because he was healthy to the extent that it was unnecessary.",
            },
            {
              Kor: "생활하는 시간대가 다른 탓이다 .  ",
              Eng: "It was because our livelihood schedules were different.",
            },
            {
              Kor: "어두운 탓이겠지  ",
              Eng: "What did mean young woman? It was probably because of the dark.",
            },
            {
              Kor: "불장난을 심히 하고 온 탓이리라고  ",
              Eng: "thinking it's probably because of the vigorous fireplay ...",
            },
            {
              Kor: "아마 쇠약해진 탓이리라  생각하니 ",
              Eng: "since he thought it was probably on account of her having grown weak …",
            },
            {
              Kor: "소내기 맞은 탓 아니야 ? ",
              Eng: "Might it not have been as a result of getting caught in the shower?",
            },
          ],
        },
        {
          entryName: "-ㄴ 탓이 있다",
          typicalExample: "【불놀이를 많이 하고 온 탓도 있겠다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Postmodifier 탓 reason, ground(s), fault + 있다: is at fault for ...-ing; 'has the mistake/fault of having done something.",
          sentences: [
            {
              Kor: "불놀이를 많이 하고 온 탓도 있겠다  ",
              Eng: "It must also be because I played with those fireworks.",
            },
          ],
        },
        {
          entryName: "-ㄴ 터이다",
          typicalExample: "【옛말같이 된 터이었지만  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Functions as a fancy equivalent of 바 which is itself a somewhat stiff and literary equivalent of 것 thing; fact; case. On the -(으)ㄴ modifier, this forms a pattern meaning it is the case that ... did/was. With descriptive verbs, the pattern means it is the case that it is ... ",
          sentences: [
            {
              Kor: "옛말같이 된 터이었지만  ",
              Eng: "has become a thing of the past...",
            },
            { Kor: "한잔 한 터이라  ", Eng: "because I had had a drink ..." },
            {
              Kor: "그는 진작부터 예상했던 터였다  ",
              Eng: "It was something that he had expected from the start/from early on.",
            },
            {
              Kor: "홧김에 기생질한 터라  ",
              Eng: "...would go off to a kisaeng  in a fit of rage ... (NB: Here [(이)라서] for -(으)ㄴ바이다 or -(으)ㄴ것이다.)",
            },
          ],
        },
        {
          entryName: "-ㄴ 편이다 ",
          typicalExample: "【누나는 예쁜 편이었다[Examples?].  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄴ 편이다: is on the ...-ing side. I.e., is somewhat/rather/pretty ... ; is ...-ish; shows an inclination to ...",
          sentences: [
            {
              Kor: "누나는 예쁜 편이었다[Examples?].  ",
              Eng: "His older sister was pretty good-looking.",
            },
            {
              Kor: '“자기야 근무 여건이 좋은 편이지 , 안 그래?"',
              Eng: '"Her own working conditions are pretty decent, don\'t you think?"',
            },
            {
              Kor: "얼굴도 예쁜 편이고  ",
              Eng: "her face was also on the pretty side ...",
            },
            {
              Kor: "살결이 검은 편이었다 ",
              Eng: "My friend Cho ... had a darkish complexion.",
            },
          ],
        },
        {
          entryName: "-ㄴ 티(가) 붙다",
          typicalExample: "【그럴싸한 티도 붙어서  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Has/acquires a spot (touch, smack, taste, air) of ... ",
          sentences: [
            {
              Kor: "그럴싸한 티도 붙어서  ",
              Eng: "I had acquired also that kind of air about me ... ",
            },
          ],
        },
        {
          entryName: "-ㄴ 후(에)",
          typicalExample: "【어머니와 사별하신 후  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "After ...-ing.",
          sentences: [
            {
              Kor: "어머니와 사별하신 후  ",
              Eng: "after departing with mother at her death",
            },
            {
              Kor: "제대를 한 후  ",
              Eng: "after I was released from the army",
            },
          ],
        },
        {
          entryName: "-나 ",
          typicalExample: "【아물아물해지는 것 같으나  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Concessive~adversative -(으)나: does/is but; though it is/does; admittedly ... , but.",
          sentences: [
            {
              Kor: "아물아물해지는 것 같으나  ",
              Eng: "it was as if it were flickering, but ...",
            },
            { Kor: "능력은 있으나   ", Eng: "the ability is there, but ..." },
            {
              Kor: "어떻게 먹으나 ",
              Eng: "no matter how [Kilsu] eats [the rice] ...",
            },
            {
              Kor: "입맛이 있을 턱이 없으나 ",
              Eng: "he had an excessive appetite, but...",
            },
            {
              Kor: "산 속은 고요하나  ",
              Eng: "it is indeed quiet in the heart of the mountains, but ...",
            },
            {
              Kor: "떨고 섰는 것이나  ",
              Eng: "was standing there trembling, but ...",
            },
            { Kor: "밖으로 뛰어왔으나 ", Eng: "I came running out, but ..." },
            {
              Kor: "내 심기를 건드렸으나 ",
              Eng: "he rubbed me the wrong way, but ...",
            },
            {
              Kor: "그의 전력이 궁금했으나 ",
              Eng: "I was curious about his personal history ...",
            },
            {
              Kor: "습관이었으나 ",
              Eng: "it was habitual for her to ... but...",
            },
            {
              Kor: "밖을 내다보고 있었으나 ",
              Eng: "...was looking outside, but",
            },
            {
              Kor: "그렇다고 한들 어디를 보나  살림 사는 아줌마일 뿐인 그녀 ",
              Eng: "my wife, who, even supposing that is the case, is just a housewife no matter how you look at it",
            },
          ],
        },
        {
          entryName: "-나?",
          typicalExample:
            "【이건 물을 긷나 , 밥을 제대루 하나 , 손님을 받나 , 소용없어 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "The question ending in the Familiar or 하네 Style. This style (indicative 하네, suggestion 하세, question 하나, command 하게, retrospective statement 하데, retrospective question 하디 and optional -(으)ㄹ세 for the copula and adjectives (-이네, 좋을세), is used only among grown-ups, e.g., by a senior addressing a grown-up junior or between grown-up social equals, and is typically used by parents-in-law addressing their sons-in-law, or by a male supervisor to a male supervisee. It is widely used among adult male friends who are not close enough to use the Intimate Style, and less widely by (or to) women. To make a Familiar Style apperceptive (equivalent to -구나, -구먼요, etc. in other styles), you add -그려 or -구려 to -네.",
          sentences: [
            {
              Kor: "이건 물을 긷나 , 밥을 제대루 하나 , 손님을 받나 , 소용없어 ",
              Eng: "Can she draw water? Cook properly? Treat customers? She's useless.",
            },
            {
              Kor: "지난일 그까짓 생각할 건 뭐 있나  ",
              Eng: "lit.: As for thinking to such a degree about past matters, what is the point?",
            },
            {
              Kor: "오래 끌 건 뭐 있나  →  오래 끌 것은 뭐 있나 ? ",
              Eng: "lit.: As for [the fact of] dragging it out / hanging on for a long time, what is the point of it? i.e., What is the point of hanging on for so long? ",
            },
            {
              Kor: "선산이 있나 , 묻힐 데가? ",
              Eng: "Do you have ancestral family burial ground (I wonder), a place [for him] to be buried?",
            },
            { Kor: "[Examples?]", Eng: "" },
            {
              Kor: "그만 눈치 없겠나  ",
              Eng: "Do you suppose they would be tactless to such an extent?  (On a future base in -겠-.)",
            },
          ],
        },
        {
          entryName: "-나-",
          typicalExample: "【크나 큰 근심을 】",
          categoryKorean: "단어 연결어미 Connective ending",
          categoryEnglish: "(단어 연결어미 Connective ending)",
          translation: "[Usage]",
          sentences: [{ Kor: "크나 큰 근심을 ", Eng: "this enormous worry" }],
        },
        {
          entryName: "나름 ",
          typicalExample: "【제 나름 대로의 】",
          categoryKorean: "Postmodifier/Postnoun",
          categoryEnglish: "(Postmodifier/Postnoun)",
          translation:
            "Functions as both a postmodifier and postnoun meaning one's [own] specific way; depending on ...",
          sentences: [
            {
              Kor: "제 나름 대로의 ",
              Eng: "[offered their opinions] each according to his own specific way ...",
            },
          ],
        },
        {
          entryName: "나름이다",
          typicalExample: "【사람 나름이지만 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN 나름이다: it depends upon the NOUN, it varies according to the NOUN.",
          sentences: [
            {
              Kor: "사람 나름이지만 ",
              Eng: "it depends on the person, but ...",
            },
            {
              Kor: "땅 나름이지만 ",
              Eng: "it depends on the property, but ...",
            },
          ],
        },
        {
          entryName: "-나마",
          typicalExample:
            "【막연하나마  도덕이라는 것에 대한 기품을 가지고 있었다. 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Extended adversative -(으)나마: (is/does) but; anyway; however, despite (the value-detracting fact that ...)",
          sentences: [
            {
              Kor: "막연하나마  도덕이라는 것에 대한 기품을 가지고 있었다. ",
              Eng: "It was somewhat vague, but possessed a moral dignitiy.",
            },
            {
              Kor: "이것도 손쉬운 방법이나마  일상의 탈피니까.  ",
              Eng: "This is an easy method, true enough, but it is an escape from everyday life",
            },
          ],
        },
        {
          entryName: "-나마나(이다)",
          typicalExample: "【옷을 입으나마나다  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "-(으)나마나(이다): there might as well be, it's all the same whether one does it or not, makes no difference whether one does it or not, one might as well not [even] there is no need to; it is useless/unnecessary to ...",
          sentences: [
            {
              Kor: "옷을 입으나마나다  ",
              Eng: "it is all the same whether one wears clothes or not (i.e., he might as well have been naked).",
            },
            {
              Kor: "있으나마나다 ",
              Eng: "it doesn't make any difference to them whether there are [Japanese and Western-food restaurants in the Mambo Building ...]",
            },
            {
              Kor: "보나마나다 ",
              Eng: "lit.: it makes no difference whether you look or not; i.e., it is obvious/predictable ",
            },
            {
              Kor: "낚으나마나 가 아니라 ",
              Eng: "it is not just a matter of whether or not he fished [the two pairs of shoes] ...",
            },
          ],
        },
        {
          entryName: "-나만 ",
          typicalExample: "【복녀는 원래 가난은 하나만  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "A non-standard combination of adversative -(으)나 but, strengthened by particle 만.",
          sentences: [
            {
              Kor: "복녀는 원래 가난은 하나만  ",
              Eng: "Pongnyŏ [was from] an originally poor, but [honest farming family]",
            },
          ],
        },
        {
          entryName: "-나 보다",
          typicalExample: "【있다나 보데 ← 있다고 하나 보데 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "It seems that, I think that.",
          sentences: [
            {
              Kor: "있다나 보데 ← 있다고 하나 보데 ",
              Eng: "it seems that they say they have some, I recall",
            },
            {
              Kor: "이제 재수가 티나 부다 ! ← 트이나 보다  ",
              Eng: "Now luck seems to be turning my way! I seem to be in for some luck now!",
            },
          ],
        },
        {
          entryName: "-나 생각된다 ",
          typicalExample: "【그렇게 변하지 않았나 생각된다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "One wonders if it is the case that ... ; it occurred to one that it might be ...",
          sentences: [
            {
              Kor: "그렇게 변하지 않았나 생각된다  ",
              Eng: "I wondered if it weren't [because of his experiences] that he had changed so.",
            },
          ],
        },
        {
          entryName: "-나 싶다",
          typicalExample: "【깜박 잠에 빠졌나 싶을 때 면  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Plain Style Quote + 싶- it feels like, as if maybe; think to oneself that ... ; wonder to oneself whether ... Note that, while the clause in front of 싶- looks like and feels like a quote, you cannot put a quotative -고 after it. ",
          sentences: [
            {
              Kor: "깜박 잠에 빠졌나 싶을 때 면  ",
              Eng: "Just when she thought she had fallen asleep …",
            },
          ],
        },
        {
          entryName: "-나 어쩌나?",
          typicalExample: "【이사하는 걸 가 보나 어쩌나  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Shall I ... or not?",
          sentences: [
            {
              Kor: "이사하는 걸 가 보나 어쩌나  ",
              Eng: "Should I go over and watch them move or not?",
            },
          ],
        },
        {
          entryName: "-(으)나  ... -(으)나 [간에]",
          typicalExample:
            "【죽으나  사나  이 놈의 회사에 모가지 붙들려 있는 것에 비하면  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "VERB1 -(으)나 ... VERB2 -(으)나 [간에] ... to show alternation between two actions or states: [no matter] whether VERB1 or VERB2 (or the like, or whatever); whether VERB1 or VERB2. Cf. 자나 깨나 night and day (whether asleep or awake). ",
          sentences: [
            {
              Kor: "죽으나  사나  이 놈의 회사에 모가지 붙들려 있는 것에 비하면  ",
              Eng: "compared to hanging by the neck for dear life at this wretched company …",
            },
            {
              Kor: "저무나  새나 ",
              Eng: "dawn till dusk... (always, constantly)",
            },
            {
              Kor: "꼭꼭 씹어먹으나  그냥 처넣으나  ",
              Eng: "[no matter] whether you chewed it forever or just shoved it in ...",
            },
          ],
        },
        {
          entryName: "-나 한다",
          typicalExample: "【혹시 대문이 열렸나 하고  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Wonders to oneself whether ... ; wonders to oneself ...",
          sentences: [
            {
              Kor: "혹시 대문이 열렸나 하고  ",
              Eng: "wondering whether perhaps the front gate was open ...",
            },
            {
              Kor: "누가 앓지나 않나 하고  ",
              Eng: "I worried whether perhaps there hadn't been illnesses ...",
            },
          ],
        },
        {
          entryName: "-나 [해서]",
          typicalExample: "【또 술 이야기가 없나  찾아보았다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-나 as an internal/thought quote—thinking to myself, 'I wonder whether ... ?' In other words, we can imagine a deleted form of 하- after -나 meaning think.",
          sentences: [
            {
              Kor: "또 술 이야기가 없나  찾아보았다.  ",
              Eng: "I looked to see if perhaps there was anything else about drinking.",
            },
          ],
        },
        {
          entryName: "날",
          typicalExample: "【날  위해 하는 거문 = 나를 위해(서) 하는 것이면 】",
          categoryKorean: "Pronoun/준말 Abbreviation",
          categoryEnglish: "(Pronoun/준말 Abbreviation)",
          translation:
            "Abbreviation of first-person pronoun 나 I/me + object particle 를.",
          sentences: [
            {
              Kor: "날  위해 하는 거문 = 나를 위해(서) 하는 것이면 ",
              Eng: "if it's something you're doing for my sake",
            },
            {
              Kor: "날  업구 왔으니 ",
              Eng: "since you carried me here on your back ...",
            },
            { Kor: "날  같으믄 ", Eng: "if it were me ... (For 날 같으면. )" },
          ],
        },
        {
          entryName: "남아",
          typicalExample: "【턱이 반 남아  파묻혀서 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN + 남아: More than NOUN.",
          sentences: [
            {
              Kor: "턱이 반 남아  파묻혀서 ",
              Eng: "because more than half his chin was buried in [the collar of his army jumper] …",
            },
          ],
        },
        {
          entryName: "남짓",
          typicalExample: "【한 달 남짓  남았다는 사실 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Numeral + 남짓: more than/over Numeral.",
          sentences: [
            {
              Kor: "한 달 남짓  남았다는 사실 ",
              Eng: "the fact that over one month still remained ",
            },
            { Kor: "일 년 남짓  ", Eng: "for more than a year" },
          ],
        },
        {
          entryName: "-냐구요 ",
          typicalExample: "【어떻게 되는 사이냐구요 ? 】",
          categoryKorean: "해요체의 종결 어미 Final ending in Polite Style",
          categoryEnglish: "(해요체의 종결 어미 Final ending in Polite Style)",
          translation: "Contracted quoted question in -(으)냐고요. ",
          sentences: [
            {
              Kor: "어떻게 되는 사이냐구요 ? ",
              Eng: "What exactly is your relationship, I'm asking you! ",
            },
          ],
        },
        {
          entryName: "-냐니까",
          typicalExample: "【[Examples?]】",
          categoryKorean: "해체의 종결 어미 Final ending in Intimate Style",
          categoryEnglish: "(해체의 종결 어미 Final ending in Intimate Style)",
          translation:
            "Contracted from -냐[고 하]니까. For insistent, reproachful reiterations. But this abbreviated pattern has a special nuance: I insist in my question as to whether ... ; or I ask you (emphatically, yet again) is/does it ... ? ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-냐를 묻는다 ",
          typicalExample: "【오늘 떠날 거냐 하루 더 묵을 거냐를 물어왔다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Quoted question in -냐 as object of 묻다 ask.",
          sentences: [
            {
              Kor: "오늘 떠날 거냐 하루 더 묵을 거냐를 물어왔다  ",
              Eng: "He asked me whether I was intending to leave today or stay another night.",
            },
          ],
        },
        {
          entryName: "-냐를 묻다 ",
          typicalExample: "【오늘 떠날 거냐 하루 더 묵을 거냐를 물어왔다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Quoted question in -냐 as object of 묻다 ask.",
          sentences: [
            {
              Kor: "오늘 떠날 거냐 하루 더 묵을 거냐를 물어왔다  ",
              Eng: "he asked me whether I was intending to leave today or stay another night",
            },
          ],
        },
        {
          entryName: "-냔 말이야",
          typicalExample: "【글쎄 어서 났냔 말이야 ? 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "I mean— ... pattern in -[느]냐[고하]ㄴ 말이야. Consists of a QUOTE + verb of saying modified to 말 + a form of the copula -이다. Means something like it is to say/what I mean is ... ; what I mean to ask is, ... ",
          sentences: [
            {
              Kor: "글쎄 어서 났냔 말이야 ? ",
              Eng: "Whatever, but where did this come from? (Here on the Past Base 났-.)",
            },
          ],
        },
        {
          entryName: "내",
          typicalExample: "【봄내 여름내  비 한 방울 안 왔다 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "Postnoun 내 throughout, all through (a period of time).",
          sentences: [
            {
              Kor: "봄내 여름내  비 한 방울 안 왔다 ",
              Eng: "throughout spring and summer not one drop of rain fell ",
            },
            { Kor: "알지. 내  다 알지 ", Eng: "I know. I know everything." },
          ],
        },
        {
          entryName: "내게 ",
          typicalExample: "【내게  말했다 】",
          categoryKorean: "준말 Abbreviation",
          categoryEnglish: "(준말 Abbreviation)",
          translation: "Abbrevated from 나에게 to me.",
          sentences: [
            { Kor: "내게  말했다 ", Eng: "He spoke to me." },
            {
              Kor: "그게 내게  상관 있니?",
              Eng: "What makes you think that is any of my concern?",
            },
            {
              Kor: "양씨가 거듭 내게  말했다 ",
              Eng: "Mr. Yang said again and again to me",
            },
          ],
        },
        {
          entryName: "내내",
          typicalExample: "【1주일 내내  한 번도 못 만나는 적도 있다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "DURATION 내내: for as long as DURATION; for the DURATION of ... ; for the whole DURATION ...; for the whole time ... .",
          sentences: [
            {
              Kor: "1주일 내내  한 번도 못 만나는 적도 있다. ",
              Eng: "There are times when we can't meet for an entire week.",
            },
            {
              Kor: "나는 한 학기  내내  그녀만 쫓아다녔다. ",
              Eng: "I did nothing for an entire semester but chase after her.",
            },
            {
              Kor: "내가 한 달 내내  스트레스 받아 가며 버는 돈 ",
              Eng: "the salary I earn as I stress out for the entire month",
            },
          ],
        },
        {
          entryName: "내야",
          typicalExample: "【내야  천가 놈 패거리 땜에 】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation:
            "Substandard/colloquial treatment of 나야: first-person pronoun 나 + particle (이)야.",
          sentences: [
            {
              Kor: "내야  천가 놈 패거리 땜에 ",
              Eng: "As for myself, [I can't go to the station] because of Mr. Ch'ŏn and his gang.",
            },
          ],
        },
        {
          entryName: "낸들 ",
          typicalExample: "【낸들  아나 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Even though it be (just) me. From 나 + copula -이- + -ㄴ들 (always followed by a rhetorical question).",
          sentences: [
            {
              Kor: "낸들  아나 ",
              Eng: "How would I know? (Lit.: Even though it me be, would I know?)",
            },
          ],
        },
        {
          entryName: "너머(로)",
          typicalExample: "【유리 너머로  지수를 바라보았다.  】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "Postnoun 너머 (← 넘다, 넘어 go beyond, exceed) over/across; beyond; in excess of; the other side (of something tall/high). ",
          sentences: [
            {
              Kor: "유리 너머로  지수를 바라보았다.  ",
              Eng: "gazed out at Chisu through [and over] the glass",
            },
            { Kor: "돋보기 너머로 ", Eng: "over/across his spectacles" },
            {
              Kor: "담 너머  빈 들을 달린다 ",
              Eng: "cross over the walls and frolic in the empty fields",
            },
            {
              Kor: "둑 너머로  사라졌다 ",
              Eng: "he disappeared over the other side of the dike",
            },
            {
              Kor: "창 너머로  사람들의 목소리가 들려왔다. ",
              Eng: "[Voices of people were heard] from the other side of the windows.",
            },
            {
              Kor: "개울 너머  분교 뒤에서부터 산냄새가 끊겼다 이어졌다 하면서 날아왔다. ",
              Eng: "Fom the branch school beyond the stream the scent of the mountain drifted in this direction, on and off.",
            },
            {
              Kor: "여자의 어깨 너머로  들여다보고 ",
              Eng: "peered over the woman's shoulder and ...",
            },
            {
              Kor: "창 너머  아득하게 떨어지는 눈발 ",
              Eng: "the distantly falling snow flakes outside the window ...",
            },
            { Kor: "누구의 어깨 너머로  ", Eng: "over whose shoulders ..." },
            {
              Kor: "어깨 너머로  넘겨 들기도 하며 ",
              Eng: "swinging [their bookbags] over their shoulders ...",
            },
            {
              Kor: "남의 등 넘어로 도 ",
              Eng: "even [looking] over the backs of others ...",
            },
          ],
        },
        {
          entryName: "너머에",
          typicalExample: "【저 산 너머에  가본 일 있니? 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "Postnoun 너머 (from 넘다) beyond, over, the other side (of something tall/high); over; more than, in excess of; beyond + particle 에 at.",
          sentences: [
            {
              Kor: "저 산 너머에  가본 일 있니? ",
              Eng: "Have you ever been/gone over that hill, over that mountain? ",
            },
          ],
        },
        {
          entryName: "너머에서",
          typicalExample: "【그 바다 너머에서  잠깨어 우는 아이 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "Postnoun 너머 (from 넘다) beyond, over, the other side (of something tall/high); over; more than, in excess of; beyond + particle 에서 from. ",
          sentences: [
            {
              Kor: "그 바다 너머에서  잠깨어 우는 아이 ",
              Eng: "a child across the sea who had woken up and was crying",
            },
          ],
        },
        {
          entryName: "너이 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation: "Dialect/substandard for 너의 your .",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "넘게",
          typicalExample:
            "【아까 5분 넘게  벨을 눌러도 끄떡 않던 그녀의 잠은  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "DURATION + 넘게 for more than DURATION. (Based on 넘- exceed plus adverbial -게 (in a way) so that, so that it is or does; so that one can; in a manner such that; -ly. ",
          sentences: [
            {
              Kor: "아까 5분 넘게  벨을 눌러도 끄떡 않던 그녀의 잠은  ",
              Eng: "my wife's slumber, undisturbed though I had just now rung the buzzer for more than five straight minutes … ",
            },
          ],
        },
        {
          entryName: "넘어",
          typicalExample: "【열 번을 넘어  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Numeral expression + 을~를 넘어 more than, in excess of. ",
          sentences: [
            {
              Kor: "열 번을 넘어  ",
              Eng: "[even if one went around it] more than ten times ...",
            },
          ],
        },
        {
          entryName: "-네",
          typicalExample:
            "【바람에 밀린 잔물결이 난장이 네  좁은 마당 끝에 와 찰싹거렸다.  】",
          categoryKorean: "Word-formation element",
          categoryEnglish: "(Word-formation element)",
          translation:
            "NAME-네: 1) NAME and his/her family; 2) the mother in NAME's family. ",
          sentences: [
            {
              Kor: "바람에 밀린 잔물결이 난장이 네  좁은 마당 끝에 와 찰싹거렸다.  ",
              Eng: "Pushed by the breeze, the ripples lapped up against the edge of the dwarf's little yard.",
            },
            {
              Kor: '동구 씨는 결혼했대요? 민석 씨네 는 이제 아기 있겠네?" ',
              Eng: '"Did they say Tonggu got married? Minsŏk and his wife must have a baby by now, yes?"',
            },
            {
              Kor: "춘향네 에 이르니",
              Eng: "since it was in Ch'unhyang's family ...",
            },
            {
              Kor: "소식조차 끊어지네  ",
              Eng: "even the news has been cut off",
            },
            { Kor: "[Examples?]", Eng: "" },
            { Kor: "헐 말 없겠네  ", Eng: "there's nothing to say..." },
          ],
        },
        {
          entryName: "네",
          typicalExample: "【세 여인네  】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "Postnoun 네: the group, all of ... This makes an explicit plural for nouns referring to people; it is also used in combination with the synonyms 들 and 끼리: 우리, 우리네, 우리들, 우리네들, 우리들네 we/us. Also means and [her] family.",
          sentences: [
            { Kor: "세 여인네  ", Eng: "the three women ..." },
            { Kor: "손님네 ", Eng: "all the guests" },
            { Kor: "도망간 부인네 는 ", Eng: "the runaway wife ..." },
            {
              Kor: "남정네 를 열띠게 하듯 ",
              Eng: "just like [a woman] causing the menfolk to get excited ...",
            },
            {
              Kor: "아저씨네 는 뭘 갖고 다녀요? ",
              Eng: "What do you guys travel with? ",
            },
          ],
        },
        {
          entryName: "-네그려",
          typicalExample: "【벗구 한다네그려 = 벗고 한다[고 하]네그려 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Familiar Style in 하네. This style (indicative 하네, suggestion 하세, question 하나, command 하게, retrospective statement 하데, retrospective question 하디 and optional -(으)ㄹ세 for the copula and adjectives (-이네, 좋을세), is used only among grown-ups, e.g. by a senior addressing a grown-up junior or between grown-up social equals, and is typically used by parents-in-law addressing their sons-in-law, or by a male supervisor to a male supervisee. It is widely used among adult male friends who are not close enough to use the Intimate Style, and less widely by (or to) women. To make a Familiar Style apperceptive (equivalent to -구나, -구먼요, etc. in other styles), you add -그려 or -구려 to -네 as the speaker has done in our example.",
          sentences: [
            {
              Kor: "벗구 한다네그려 = 벗고 한다[고 하]네그려 ",
              Eng: "you know, they say [that the more civilized the country, the more they] tend to do it [dance] with their clothes off",
            },
          ],
        },
        {
          entryName: "-네요 ",
          typicalExample: "【어깨가 참 넓으네요  】",
          categoryKorean: "해요체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해요체의 종결 어미 Final ending in Plain Style)",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "어깨가 참 넓으네요  ",
              Eng: "My, your shoulders are quite wide! (Here 넓으네요 is substandard and/or dialect for standard 넓네요.)",
            },
          ],
        },
        {
          entryName: "-노?",
          typicalExample: "【누가 아노 ? 】",
          categoryKorean: "해체의 종결 어미 Final ending in Intimate Style",
          categoryEnglish: "(해체의 종결 어미 Final ending in Intimate Style)",
          translation:
            'Interrogative -노. In some Korean dialects, verbs in "WH-questions" (i.e., questions which contain a question word like "what?" "how many" or the like, as opposed to yes-no questions) take a question ending in final -노 or -고 rather than -나 or –가 as in the standard language. ',
          sentences: [
            { Kor: "누가 아노 ? ", Eng: "Who knows?" },
            {
              Kor: "이 사람 봐아..... 어느 땐 줄 알구 코만 고노  (←코(를) 골다) ?",
              Eng: "Look at this guy...... How are you snoring away at times like this?; or, [what time to you think it is, anyway,] just snoring [away like that]? (Here, the 어느 in the preceding phrase has triggered the question ending in -노.)",
            },
            {
              Kor: "얼마나 아름답고 포근한 마을이었노 ",
              Eng: "What a beautiful, cozy village it was! (Here, the 얼마나 has triggered the -노.)",
            },
          ],
        },
        {
          entryName: "-노라",
          typicalExample: "【짐은 그대에게 자유의 몸이 될 것을 허락하노라  】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "Sentence-final ceremonial or solemn proclamation or declaration in -노라. Usually first person.",
          sentences: [
            {
              Kor: "짐은 그대에게 자유의 몸이 될 것을 허락하노라  ",
              Eng: "I hereby grant you your freedom.",
            },
          ],
        },
        {
          entryName: "-노라(고)1",
          typicalExample: "【박동무라는 사람은 이 마을에는 없노라고  했다 】",
          categoryKorean: "Quotative",
          categoryEnglish: "(Quotative)",
          translation:
            "The embedded (and probably best understood as quoted) equivalent of ceremonial or solemn proclamation or declaration in -노라. This ending seems to be used primarily in a sarcastic sense, and is usually first person and quoted (or at least used in such a way that an indirect quotation can be imagined): making the ceremonious claim that ... (for first-person claims treated as somewhat dubious or otherwise scorn-worthy by the person reporting the claim). In other words, the -노라 is in origin a first-person (I, we) ceremonious form for declarations and proclamations (I hereby do solemnly blah-blah-blah, though perhaps not quite so heavy), quoted here with -고 by somebody else with the implication that the quoter doesn't quite buy it. ",
          sentences: [
            {
              Kor: "박동무라는 사람은 이 마을에는 없노라고  했다 ",
              Eng: "[the villagers] claimed there was no one in the village named Comrade Pak",
            },
            {
              Kor: "숨이 막혀 견딜 수 없노라고  했다.  ",
              Eng: "She reported/declared/proclaimed that it was so stifling she could not bear it.",
            },
            {
              Kor: "무용가노라  이름을 날리며 ",
              Eng: 'made a name for herself as a dancer (lit.:let her name fly, striving to be a dancer; or, let her name fly, making pretensions to the effect that "I solemnly hereby pronounce that I am a dancer") [The usage here is somewhat unusual, since one does not normally expect to find this ending on a descriptive verb, let alone the copula (무용가 + -(이)노라)]',
            },
            {
              Kor: "나는 청백하였노라 . 이것은 맹순사의 청백관이었다. ",
              Eng: "I am upright—this was Officer Maeng's motto regarding moral honesty",
            },
            {
              Kor: "나를 기다렸노라고  말했다 ",
              Eng: "He said that he had been waiting for me. ",
            },
            {
              Kor: "헐벗지는 않고 살았노라  하였다 ",
              Eng: "He intoned that he did not live in need of clothes.",
            },
            {
              Kor: "그들은 이 마을 사람들을 해방시키려 왔노라고  했다 ",
              Eng: "They claimed that they had come to liberate the villagers.",
            },
            {
              Kor: "청진까지 가보려던 것이었노라  한다 ",
              Eng: "He claimed he had just been about to go all the way to Ch'ŏngjin.",
            },
            {
              Kor: "아이의 울음소리가 ... 머릿속을 ... 파고들어서 아이를 버렸노라고  ",
              Eng: "Declaring that she had abandoned the child because the sound of the child‘s crying had dug inside her as if it might split open the inside of her head …",
            },
            {
              Kor: "다녀오겠노라고  장인이 위독해서 가보겠다고 쓰고 있다. ",
              Eng: "Claiming (somewhat ceremoniously) she would visit her father (my father-in-law) because he's critically ill, and return after...",
            },
          ],
        },
        {
          entryName: "-노라(고)2",
          typicalExample: "【간장주전자로 쓰노라고  장사 할멈들 받아 주었다 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Literary equivalent of -느라(고) (processive adjunctive + particle): 1) = -느라 what with doing, as a result of doing, because of (doing ...); 2) with the idea to do, with the intention of doing, trying to do. Technically the -노라 (as opposed to느라) is for first person, and is always quoted and has a ceremonious or pompous flair to it, meaning that it is not always easy to distinguish this pattern from -노라(고)1.",
          sentences: [
            {
              Kor: "간장주전자로 쓰노라고  장사 할멈들 받아 주었다 ",
              Eng: "The peddling grannies would take them, saying they’d use these as soy sauce bottles.",
            },
            {
              Kor: "사람들을 홀리노라고  이따금 한 번씩 휘장을 열어 보일 때 순간 순간이나마 공구경을 하는 재미가 좋았다.",
              Eng: "Every once in a while when I drew the curtain aside, (what with the intention of) enticing the people, I enjoyed so very much catching the sight of the ball-play.",
            },
            {
              Kor: "뺨깨나 좋이 맞을 것을 면하노라고  원산서 자선가로 유명한 B 서양 부인의 눈이 이내 그곳에 머무르게 되었고",
              Eng: "With the intention of escaping a good face-slapping, Lady B, a western lady who was a famous philanthropist in the Wŏnsan area, set her sight on him, … (좋이 is the adverbative -이 from 좋- good, nice.)",
            },
          ],
        },
        {
          entryName: "-노라고(는) 한다",
          typicalExample: "【늘 그렇게 쥐노라고는 했지만  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "One does one's very best to try to VERB.  The ending in -노라고 is a literary equivalent of -느라고. ",
          sentences: [
            {
              Kor: "늘 그렇게 쥐노라고는 했지만  ",
              Eng: "he always did his best to try and clench his fist that way, but ...",
            },
          ],
        },
        {
          entryName: "-노라는 ",
          typicalExample:
            "【비극을 이룬 원인이 되었노라는  것을 암시하여 말했다. 】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "-노라는 contracted from-노라[고 하]는to quote solemn proclamations/declarations and used to modify a following noun.",
          sentences: [
            {
              Kor: "비극을 이룬 원인이 되었노라는  것을 암시하여 말했다. ",
              Eng: "He hinted that/Said he, hinting that this was one of the reasons for the tragedy.",
            },
            {
              Kor: "서울서 무슨 공장엘 다니다 왔노라는  바우는 ",
              Eng: "Pau, who claimed he had returned after working in some factory in Seoul ...",
            },
            {
              Kor: "어미를 기다리고 있는 것을 보았노라는  아낙네도 있었다 ",
              Eng: "There was also a woman who claimed that she saw [the three baby cranes] waiting for their mother ...",
            },
          ],
        },
        {
          entryName: "-노라니 ",
          typicalExample: "【점심을 먹다 듣노라니 = 점심을 먹다가 듣노라니 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "A combination of -노라(고)2, the literary equivalent of -느라(고) what with doing; with the idea to do, with the intention of doing, and 하니(까) when (in the past) ... , then. The sense is something like when I set about to VERB-ing, I found ...",
          sentences: [
            {
              Kor: "점심을 먹다 듣노라니 = 점심을 먹다가 듣노라니 ",
              Eng: "when I began eating lunch, I heard ...",
            },
          ],
        },
        {
          entryName: "-노라면",
          typicalExample: "【귀를 열고 있노라면   】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "-노라면 what with the process of ...-ing; because of the long process of ...-ing. Same as -느라면, but with a tinge of implication that the subject of the verb is somewhat pretentious and/or unjustified to see the process as such a big deal. When/while/whenever; in the course of ...-ing (somewhat ceremonious or pretentious, and originally first-person in reference). In origin, probably -노라(고) + 하면 when/whenever one is doing one's best to claim to ... .",
          sentences: [
            {
              Kor: "귀를 열고 있노라면   ",
              Eng: "When she was doing her best to open up her ears …",
            },
            {
              Kor: "몸을 비트노라면  지친 그는 쉽게 잠든다.  ",
              Eng: "Exhausted from his physical gyrations, he easily falls asleep.",
            },
            {
              Kor: "아줌마들이 강을 내려다보고 있노라면   ",
              Eng: "Whenever the middle-aged aunties are looking down at the river …",
            },
            {
              Kor: "나이 사십이 되노라면  ",
              Eng: "When a person turns forty ...",
            },
            {
              Kor: "으슥한 골목을 지나노라면 ",
              Eng: "Whenever he happened to be walking down a secluded backstreet ...",
            },
            {
              Kor: "어시장 앞을 지나노라면  ",
              Eng: "Whenever he passed in front of the fish market ...",
            },
            {
              Kor: "욕실 쪽을 보고 있노라면  ",
              Eng: "When he deigned to look out in the direction of the bathroom ...",
            },
          ],
        },
        {
          entryName: "놨-",
          typicalExample: "【다 풀어 놨으니  】",
          categoryKorean: "Colloquial/준말 Abbreviation ",
          categoryEnglish: "(Colloquial/준말 Abbreviation )",
          translation: "Colloquial abbreviation for 놓았-.",
          sentences: [{ Kor: "다 풀어 놨으니  ", Eng: "released them all" }],
        },
        {
          entryName: "-누?",
          typicalExample: "【얼마나 타게 되누 ? = 타게 되노 ?】",
          categoryKorean:
            "Dialect morphology/해체의 종결 어미 Final ending in Intimate Style ",
          categoryEnglish:
            "(Dialect morphology/해체의 종결 어미 Final ending in Intimate Style )",
          translation:
            'Dialect question ending equivalent to (dialect) -노. In some Korean dialects, verbs in "WH-questions" (i.e., questions which contain a question word like "what," "how many," or the like, as opposed to yes-no questions) take a question ending in final -노 or -고 rather than -나 or -가 as in the standard language. ',
          sentences: [
            {
              Kor: "얼마나 타게 되누 ? = 타게 되노 ?",
              Eng: "About how much will you end up getting (get to get)? (Here, 얼마나 has triggered the question ending in -누.)",
            },
          ],
        },
        {
          entryName: "누구는 ",
          typicalExample:
            "【누구는  맞아죽구, 누구는  집에다 불을 지르구, 누구는  】",
          categoryKorean: "Pronoun",
          categoryEnglish: "(Pronoun)",
          translation:
            "Someone or other, so-and-so. Korean interrogatives can also function as indefinites.",
          sentences: [
            {
              Kor: "누구는  맞아죽구, 누구는  집에다 불을 지르구, 누구는  ",
              Eng: "someone was beaten to death, someone had their house burned down, and someone...",
            },
          ],
        },
        {
          entryName: "누구도",
          typicalExample: "【누구도  내 환심을 사려 들지 않을 뿐더러  】",
          categoryKorean: "Pronoun",
          categoryEnglish: "(Pronoun)",
          translation:
            "Same as 아무도: no one, nobody; not one. (usually followed by a negative). ",
          sentences: [
            {
              Kor: "누구도  내 환심을 사려 들지 않을 뿐더러  ",
              Eng: "not only does nobody attempt to curry favor with me, but ...",
            },
            {
              Kor: "누구도  감당할 수가 없는 건가 봐요 ",
              Eng: "It seems like it is something that not everyone can cope with.",
            },
          ],
        },
        {
          entryName: "누굴",
          typicalExample: "【이건 누굴  엿먹이느라구 수작질인가】",
          categoryKorean: "Pronoun/준말 Abbreviation",
          categoryEnglish: "(Pronoun/준말 Abbreviation)",
          translation: "Abbreviated from 누구를 whom?",
          sentences: [
            {
              Kor: "이건 누굴  엿먹이느라구 수작질인가",
              Eng: 'Just who are you trying to make fun of? (lit.: [I thought] "is this guy pulling a stunt to provoke someone [me]?")',
            },
          ],
        },
        {
          entryName: "누그르 ",
          typicalExample: "【누그르  】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            "Hamkyŏng (함경) dialect version of 누구를 whom? Hamkyŏng dialects have 으/르 corresponding to standard 을/를.",
          sentences: [{ Kor: "누그르  ", Eng: "who/whom" }],
        },
        {
          entryName: "-누나 ",
          typicalExample: "【아이구 사람 죽이누나  】",
          categoryKorean: "Dialect morphology/준말 Abbreviation ",
          categoryEnglish: "(Dialect morphology/준말 Abbreviation )",
          translation:
            "Substandard/dialect abbreviation of –는구나: Well I see ...! ; Well so it is ...!; So it seems!; Well I'll be (damned)!; Hey look! etc.",
          sentences: [
            {
              Kor: "아이구 사람 죽이누나  ",
              Eng: "Oh my God, he's trying to kill me!",
            },
          ],
        },
        {
          entryName: "-누마",
          typicalExample: "【고향에서 오누마  】",
          categoryKorean: "Dialect morphology/준말 Abbreviation ",
          categoryEnglish: "(Dialect morphology/준말 Abbreviation )",
          translation:
            'Dialect form of standard apperceptive ("first realization") form in -는구나, abbreviated from -는구마.',
          sentences: [
            {
              Kor: "고향에서 오누마  ",
              Eng: "I'm on my way back from my hometown (the -누마 makes it lively).",
            },
          ],
        },
        {
          entryName: "뉘",
          typicalExample: "【형님은 뉘  집에? 】",
          categoryKorean: "Dialect morphology/준말 Abbreviation ",
          categoryEnglish: "(Dialect morphology/준말 Abbreviation )",
          translation:
            "Dialect morphology. 뉘 is from the old form 누 who? + possessive 의, contracted to 뉘. It can also represent just 누구.",
          sentences: [
            { Kor: "형님은 뉘  집에? ", Eng: "Whose place have you been to?" },
            {
              Kor: "뉘  집엘? ",
              Eng: "Who's house did he go into? (Also 엘 ← 에를 is used to mark destinations.)",
            },
            { Kor: "뉘 시요?", Eng: "Who is it?" },
            {
              Kor: "딸이 배에서 내렸는지 뉘  아나! ",
              Eng: "Who knows, my daughter could have come on a ship!",
            },
            { Kor: "그 안에 뉘  있나? ", Eng: "Is anyone in there?" },
          ],
        },
        {
          entryName: "뉘게다",
          typicalExample: "【뉘게다 골으 내오 】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation: "Dialect for 누구에다.",
          sentences: [
            {
              Kor: "뉘게다 골으 내오 ",
              Eng: "Who's he getting pissed off at?  (With Hamkyŏng [함경] accusative -으 and 골을 내- get angry.)",
            },
          ],
        },
        {
          entryName: "뉘기 ",
          typicalExample: "【애고 이게 뉘기시오  】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation: "Dialect for 누구.",
          sentences: [
            { Kor: "애고 이게 뉘기시오  ", Eng: "Oh my! Who is this?" },
            {
              Kor: "자네가 뉘기여 ? ",
              Eng: "Who are you? (Note -여 ← -이어 [infinitive of copula]).",
            },
          ],
        },
        {
          entryName: "-느냐니까",
          typicalExample: "【[Examples?]】",
          categoryKorean: "해체의 종결 어미 Final ending in Intimate Style",
          categoryEnglish: "(해체의 종결 어미 Final ending in Intimate Style)",
          translation:
            'Abbreviated from -느냐고 하니까. For insistent, reproachful reiterations. But this abbreviated pattern has a special nuance: I say/insist it is/does or I tell you (emphatically, yet again) it is/does = it really is/does; it is/does, you see (mind you); but I tell you, ". . . !"',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-느냐를 묻다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Quoted question in -냐 as object of 묻다 ask.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-느냐의 NOUN",
          typicalExample: "【호랑이가 존재하느냐의  나의 질문 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "The NOUN as to whether ...",
          sentences: [
            {
              Kor: "호랑이가 존재하느냐의  나의 질문 ",
              Eng: "my question as to whether or not a tiger exists ...",
            },
          ],
        },
        {
          entryName: "-(느)ㄴ다 . . .  -(느)ㄴ다",
          typicalExample: "【집을 고친다  사들인다  돌아다닌다 하느라고 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "A -(느)ㄴ다 B-(느)ㄴ다 하다 is an archaic/literary equivalent to A-느니 B-느니 하다 to carry on/worry about [the question of] A vs. B / A or B. This pattern functions similarly to, and is in fact coupled with, the pattern in -느라(고) what with busying oneself doing A and then B ...",
          sentences: [
            {
              Kor: "집을 고친다  사들인다  돌아다닌다 하느라고 ",
              Eng: "Because of things like fixing the house, buying a record player, and running around networking … (Alternatively, one could just take this as a series of quotes: What with [being so busy] saying/claiming gotta fix the house, gotta buy a phonograph, gotta run around making contacts [there was no money in the budget for him].)",
            },
          ],
        },
        {
          entryName: "-느니 . . . -느니  (하-)",
          typicalExample:
            "【만 원짜리 집을 사느니 , 오십 석 추수의 땅을 양주에다 사놓았느니 , 상사회사를 꾸며 가지고 대주주가 되어 사직하고 나가느니 하였다 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "A-느니 B-느니 (하-): what with carrying doing A and doing B; whatever/or the like; worry about [the question of] A vs. B. For listing or (quoting) tedious blah-blah-blah items. Martin treats -느니 as a variant of question -느냐, but in this particular case the pattern functions similarly to A-느라 B-느라 what with being busy doing A and B ...",
          sentences: [
            {
              Kor: "만 원짜리 집을 사느니 , 오십 석 추수의 땅을 양주에다 사놓았느니 , 상사회사를 꾸며 가지고 대주주가 되어 사직하고 나가느니 하였다 ",
              Eng: "It was in the likes of buying a ten thousand wŏn home or having bought land in Yangjoo worth fifty sŏk of rice harvest, or coming up with some sort of a company, becoming a major stockholder, retiring and then leaving.",
            },
            {
              Kor: "대판에 가 있느니  동경 가 있느니 하더니  ",
              Eng: "what with going over and staying in Osaka, going over and staying in Tokyo, and so on, the next thing you know ...",
            },
            {
              Kor: "거리마다 짓느니  고층건축 들이요, 동네마다 느느니  그림 같은 문화주택들이다.",
              Eng: "A high rise building was being constructed on every street, and a modern residence was being expanded in every neighborhood. ",
            },
            {
              Kor: "삼십 배가 올랐느니  오십 배가 올랐느니 하고 ",
              Eng: "There was talk that [land] had gone up 30-fold or 50-fold [and the like], and …",
            },
          ],
        },
        {
          entryName: "-느니라[고]",
          typicalExample: "【이제야말로 정말 끝장이 나느니라 했다  】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "Archaic/old-fashioned. It is the case that, it is a fact that, from earlier -느니 (where 이 = 것) + copula -(이)라, as if this could be paraphrased into modern Korean as –는 것이다.",
          sentences: [
            {
              Kor: "이제야말로 정말 끝장이 나느니라 했다  ",
              Eng: "they said that this would indeed be the end of the world ...",
            },
          ],
        },
        {
          entryName: "-느니만큼 ",
          typicalExample:
            "【오늘 밤으로 딱 다가서 걱정되느니만큼  몹시 초조하고 조급한 것이었다. 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "More or less equivalent to -(으)ㄹ 만큼 (prospective modifier + noun + particle) to the extent that will do/be (=-(으)ㄹ정도(로)); enough to do, as much as necessary for doing.",
          sentences: [
            {
              Kor: "오늘 밤으로 딱 다가서 걱정되느니만큼  몹시 초조하고 조급한 것이었다. ",
              Eng: "It was a matter very vexing and dire to the extent that I spent the whole night fretting ...",
            },
          ],
        },
        {
          entryName: "-느니보다(는)",
          typicalExample:
            "【하느니보다는  여지껏 악착같이 집착했던 내가 이룩한 생활을 헌신짝처럼 차 버리고 훨훨 자유로와지고 싶었다.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Rather than ...ing ...; would rather ...than ...",
          sentences: [
            {
              Kor: "하느니보다는  여지껏 악착같이 집착했던 내가 이룩한 생활을 헌신짝처럼 차 버리고 훨훨 자유로와지고 싶었다.",
              Eng: "Like kicking off an old shoe, I would rather set myself free from the life I'd been leading than to be obsessed/stressed over it like I had always been.",
            },
          ],
        },
        {
          entryName: "-느라(고)",
          typicalExample: "【그러느라고  아주 숙면이 되었었다 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Processive adjunctive -느라(고): what with doing; with the idea to do, with the intention of doing; because of the process involved in ... ; as a result of doing, because of (...-ing); what with all the effort involved in . . . -ing.",
          sentences: [
            {
              Kor: "그러느라고  아주 숙면이 되었었다 ",
              Eng: "As a result of this, he became very familiar with [Kang].",
            },
            {
              Kor: "그럼 구두 낚느라고  애를 쓰지 않아도 된다 ",
              Eng: "If so, he would not have had to work so hard to fish for shoes",
            },
            {
              Kor: "단추를 여느라고  턱을 치켜들었다 ",
              Eng: "[He] raised his jaw so as to unbutton [his fur hat].",
            },
            {
              Kor: "정 씨는 웃음을 참느라구  자꾸만 송림 쪽으로 고개를 돌렸다. ",
              Eng: "Mr. Chŏng, trying to suppress his laughter, kept turning his head back in the direction of Songrim. ",
            },
            {
              Kor: "인원 점검을 받느라고  군청 마당에 도열한 사람들 사이로 뛰어갔다.",
              Eng: "I weaved through lines of people, all waiting to be counted, that had now forming in the yard in front of the county office. ",
            },
            {
              Kor: "생각의 갈피를 헤매느라  베개 위에서 머리를 뒤척이는 동안 불면의 밤은 허옇게 밝아지고는 하였다.  ",
              Eng: "While I tossed my head left and right on my pillow, wandering in between these thoughts/In between these reveries, the sleepless nights would brighten up into a new day.",
            },
            {
              Kor: "먼길을 걷느라고  발갛게 달아 있었다 ",
              Eng: "Her face had turned red, as a result of walking the long distance.",
            },
            {
              Kor: "이건 누굴 엿먹이느라구  수작질인가 ",
              Eng: 'Just who are you trying to make fun of? (lit.: [I thought,] "Is this guy pulling a stunt to provoke someone [me]?")',
            },
            {
              Kor: "그이가 좋아하는 음식을 만드느라 좀 부산을 떨었다.  ",
              Eng: "I got busy preparing the food he liked.",
            },
            {
              Kor: "밤새 깨어 있었던 흔적을 내지 않느라   ",
              Eng: "in an effort to give no indication that she had been awake all night long",
            },
            {
              Kor: "집안일을 하느라  엄마는 새벽부터 자정까지 부지런히 움직여야 했다.  ",
              Eng: "With all the housework she had to do, mom was on the move from dawn until midnight.",
            },
            {
              Kor: "홀로 지수 자매를 키우느라  황혼을 맞은 남자.  ",
              Eng: "[Their father] was a man who had entered the twilight of his life struggling to raise Chisu and her sister on his own. ",
            },
          ],
        },
        {
          entryName: "-느라(고) [한다]",
          typicalExample:
            "【멕기를 올리느라 한참 열이 오를 때는 입에서는 연상 푸푸 소리가 나고】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "One does one's very best to try to VERB.",
          sentences: [
            {
              Kor: "멕기를 올리느라 한참 열이 오를 때는 입에서는 연상 푸푸 소리가 나고",
              Eng: "In trying their best to make it shine, he would stoke up the fire, and then a purring noise would come out of his lips ... ",
            },
            {
              Kor: "사느라고 열심히 살았건만 ",
              Eng: "I did my best to live a full life, but ...",
            },
          ],
        },
        {
          entryName: "-느라(고) 분주하다",
          typicalExample: "【낚시 도구를 챙기느라 분주했던  남편 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "-느라(고) 분주하다: be busy/preoccupied ...-ing. ",
          sentences: [
            {
              Kor: "낚시 도구를 챙기느라 분주했던  남편 ",
              Eng: "her husband, who had been busy preparing the fishing tackle",
            },
          ],
        },
        {
          entryName: "는",
          typicalExample: "【지적(知的) 분위기라고는  하지만 】",
          categoryKorean: "보조사 Particle",
          categoryEnglish: "(보조사 Particle)",
          translation:
            "Use of particle 은/는 for contrast or downplaying effect. It is indeed; true enough; admittedly (as opposed to some other appellation)",
          sentences: [
            {
              Kor: "지적(知的) 분위기라고는  하지만 ",
              Eng: 'an atmosphere that can be called "intellectual," but ...',
            },
            {
              Kor: "어쨌든 내게도 그녀가 도로 살가운 모습이 되어 주기를 바라는  마음이 없다고는  할 수 없으니까.",
              Eng: "Because I could not say I did not have the inclination for her to return to her old warm-hearted, kind self. ",
            },
            {
              Kor: "사정이 있어 아빠와는  같이 살지 못하는 아이들이다. ",
              Eng: "They are children who, circumstances being what they were, were unable to live with their father [though, by contrast, they were of course living with somebody else—such is the implication of the 는]",
            },
            {
              Kor: "결정적으로 심각한 그늘을 드리운다고는  생각해 본 적이 없다. ",
              Eng: "I had never thought that it cast any decisively severe shadow [over her life].",
            },
            {
              Kor: "학원 안으로 들어오리라고는  생각지 못하고 있던 참이었기 때문이었다. ",
              Eng: "It was because it had never occurred to me just then that he might walk into the cram school; or, While I may have been thinking of other things, one thing I wasn't thinking of was that he would come into the cram school ... ",
            },
          ],
        },
        {
          entryName: "-는가",
          typicalExample:
            "【학교 다닐 때 ... 모범생이었다는 게 무슨 내세울 일이나 되는가 . 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Modifier [-(으)ㄴ, -던, -었던, -었는, -는] + postmodifier 가: the question of/whether. Question form for processive verbs, 있다 & 없다, as well as past and future bases (-었는가, -겠는가). Descriptive verbs take -(으)ㄴ가. Note that this particular question ending is often used for musings to oneself: I wonder whether ...; Could it be that ...?; is it the case that ...? (I wonder).",
          sentences: [
            {
              Kor: "학교 다닐 때 ... 모범생이었다는 게 무슨 내세울 일이나 되는가 . ",
              Eng: "Since when has the fact that you were a model student in school become something worth bragging about?",
            },
            {
              Kor: "누군가 어둠 속에서 수영을 하는가  ",
              Eng: "Was somebody perhaps swimming in the dark?",
            },
            {
              Kor: "무슨 일이 일어날 수 있겠는가. ",
              Eng: "What could possibly have happened?",
            },
          ],
        },
        {
          entryName: "-는가가 ",
          typicalExample:
            "【술이나 안주를 가운데 상에 넘겨 놓는가가  문제였다 】",
          categoryKorean: "Subject  ",
          categoryEnglish: "(Subject  )",
          translation:
            "From question -는가 + subject marker 가 the question of ... (as subject). ",
          sentences: [
            {
              Kor: "술이나 안주를 가운데 상에 넘겨 놓는가가  문제였다 ",
              Eng: "the question of [over whose shoulders] they placed the drink or appetizers on the central table was the issue",
            },
          ],
        },
        {
          entryName: "-는가 싶다",
          typicalExample: "【몸을 움츠리는가 싶던  여자가 ... 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-는가 싶-: I.e., Plain Style Quote + 싶- it feels like, as if maybe; think to oneself that ... ; wonder to oneself whether ... Note that, while the clause in front of 싶- looks like and feels like a quote, you cannot put a quotative",
          sentences: [
            {
              Kor: "몸을 움츠리는가 싶던  여자가 ... ",
              Eng: "the woman whom [Chisu] thought/felt was perhaps  flinching",
            },
            {
              Kor: "그날이 그야말로 사는가 싶지 못한  쓸쓸한 날이었다 ",
              Eng: "Those were indeed the lonely days when he did not feel as if he were alive.",
            },
          ],
        },
        {
          entryName: "-는 가운데 ",
          typicalExample: "【다들 말없는 가운데  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "In the middle/midst of ...-ing.",
          sentences: [
            {
              Kor: "다들 말없는 가운데  ",
              Eng: "all of them, 'in the middle of not saying anything' (i.e., without saying anything, everyone ...)",
            },
            {
              Kor: "아무도 말이 없는 가운데  ",
              Eng: "with nobody saying a word ...",
            },
          ],
        },
        {
          entryName: "-는가 의심이 든다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-는가 의심이 드-ㄹ-: to doubt whether it is not ... ; to wonder whether it is not the case that ...",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "- 는가 하다",
          typicalExample: "【아침부터 날기 연습을 하는가 했다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Indirect quoted thought in -(으)ㄴ/-는가 하다 where 하다 functions as think/wonder to oneself.",
          sentences: [
            {
              Kor: "아침부터 날기 연습을 하는가 했다  ",
              Eng: "He wondered if perhaps they might start practicing flying in the morning.",
            },
            {
              Kor: "눈에 살기가 번뜩이는가 하는  순간 ",
              Eng: "Just [at the instant] when it appeared that violence was in his eyes ... (with -는 순간 meaning just when ...)",
            },
            {
              Kor: "잠이 들었는가 하는데  ",
              Eng: 'lit.: At the juncture when he thought to himself, "Am I asleep?" (i.e.: just when he thought he was falling asleep; just when he thought he must have drifted off ...)',
            },
            {
              Kor: "날이 풀리는가 하더니  다시 눈이 오고 ",
              Eng: "Just when one figured the weather would warm up, it would snow again.",
            },
          ],
        },
        {
          entryName: "-는가 하면",
          typicalExample: "【새벽같이 도서관 자리를 맡아 주는가 하면   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB1-는가 하면 (VERB2) meaning, literally, if you ask me whether VERB1 happens, actually (and contrary to or on top of what you might expect) VERB2 also happens. Hence: not only VERB1 but also VERB2. ",
          sentences: [
            {
              Kor: "새벽같이 도서관 자리를 맡아 주는가 하면   ",
              Eng: "Not only did I save spaces for her at the library from the crack of dawn, [I also rummaged through libraries at other universities for books for her papers.] ",
            },
            {
              Kor: "친구의 전화를 간섭하는가 하면  저녁 외출을 되물었다.",
              Eng: "Not only did she interfere with phone calls from the woman's friends, (she also pried into the woman's comings and goings in the evening.) [lit.: If you ask me, 'Does she interfere with phone calls from my friends?' (She was actually also ...)]",
            },
            {
              Kor: "기저귀를 개키고 있는가 하면  저녁을 짓고 있기도 했다 ",
              Eng: "Not only was she folding diapers, she was also preparing supper. [lit.: If you ask me 'Was she folding diapers?', she was actually also...]",
            },
          ],
        },
        {
          entryName: "-는 거겠지",
          typicalExample: "【자기의 처녀적 생각이 나는 거겠지 . 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-는 거겠지 ← -는 것이겠지: must certainly be a case of ...-ing, right?",
          sentences: [
            {
              Kor: "자기의 처녀적 생각이 나는 거겠지 . ",
              Eng: "She must be thinking of the times before she was married.",
            },
          ],
        },
        {
          entryName: "-는 거나 아닐까 ",
          typicalExample: "【운수가 좀 피려는 거나 아닐까  하며 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Isn't is perhaps the case that ... ? ← -는 것이나 아닐까. ",
          sentences: [
            {
              Kor: "운수가 좀 피려는 거나 아닐까  하며 ",
              Eng: "While thinking to myself that perhaps my luck was about to change for the better … ",
            },
          ],
        },
        {
          entryName: "-는 걸",
          typicalExample:
            "【이번 앤 꽤 여러날 앓는 걸  약두 변변히 못 써봤다드군. 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Following a processive modifier (-는), the shape 걸—abbreviated from거 (← 것) + ㄹ (← 를)—makes an 'adverse circumstantial' construction: under these somewhat adverse circumstances; given these somewhat unfortunate circumstances. This is an example of what Martin calls the use of the accusative to mark an antithetical clause that ends in a modifier + 것 although, but; (it's) too bad (that/yet ...); in spite of the fact that ...; anyway, so there! (used to end a sentence with the meaning 'indeed', or 'despite contrary anticipations/reservations', or 'I guess').",
          sentences: [
            {
              Kor: "이번 앤 꽤 여러날 앓는 걸  약두 변변히 못 써봤다드군. ",
              Eng: "lit.: Under the adverse circumstances of the girl’s having been sick for several days [they couldn't even administer medicine effectively]",
            },
            {
              Kor: "우리 어머니가 싫다는 걸  내가 디리 졸라서 한 번 숙이네한테 통혼을 넣다가 거절을 당한 일이 있었다.   ",
              Eng: "The reason my mother was so annoyed was that I once pestered her to put in a marriage proposal to Sook'e even though she was against it, and I got rejected anyway.",
            },
          ],
        },
        {
          entryName: "-는 걸 보면",
          typicalExample: "【또 악상꺼지 당하는 걸 보면  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Considering that fact that ... ; judging by how ... From -는 것을 the doing, the fact or act of doing (as object) + 보면 if/when one sees.",
          sentences: [
            {
              Kor: "또 악상꺼지 당하는 걸 보면  ",
              Eng: "if you consider how they're also suffering yet another calamity ...",
            },
            {
              Kor: "서 있는 걸 보면  ",
              Eng: "watching her standing there ...",
            },
          ],
        },
        {
          entryName: "-는 걸 봤다",
          typicalExample: "【화장대 위에 웬 노트가 놓여 있는 걸 보았다 . 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "-는 걸 봤다: saw how something was happening. ",
          sentences: [
            {
              Kor: "화장대 위에 웬 노트가 놓여 있는 걸 보았다 . ",
              Eng: "I saw some sort of notebook lying on top of the dressing table.",
            },
          ],
        },
        {
          entryName: "-는 걸 안다",
          typicalExample: '【"집에 있는 걸 알아요.” 】',
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-는 걸 알다 ← -는 것을 알다: know [‘the fact'] that ... (less putative and hypothetical than -다는 걸 알다).",
          sentences: [
            {
              Kor: '"집에 있는 걸 알아요.” ',
              Eng: "“They know you're at home.” ",
            },
          ],
        },
        {
          entryName: "-는 걸(요)",
          typicalExample: "【걜 본 적도 없는걸요 .  】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Modifier + 걸 used to end a sentence with the meaning indeed, or despite contrary anticipations/reservations. Usually pronounced with a rising, question-like intonation.",
          sentences: [
            {
              Kor: "걜 본 적도 없는걸요 .  ",
              Eng: "Actually, I've never seen him.",
            },
            {
              Kor: "용도 있고 코끼리도 있다는 걸  ",
              Eng: "[Indeed,] there are dragons and elephants too!",
            },
            {
              Kor: "그놈 발자국을 똑똑히 봤는 걸요 ",
              Eng: "I saw that thing's footprints clearly, I tell ya.",
            },
          ],
        },
        {
          entryName: "-는 것만 같다",
          typicalExample: "【옆구리를 푹 찌르는 것만 같아서  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier + 것만 같- all it seems like is, definitely seems. The addition of particle 만 to the seem pattern in Modifier + 것 같다 lends an air of absolute certainly and insistence to the construction, as if to say the one and only thing that seemed [likely] was . . .—that what seemed to be happening or about to happen was/is imminent, inevitable, or inescapable.",
          sentences: [
            {
              Kor: "옆구리를 푹 찌르는 것만 같아서  ",
              Eng: "could be stabbed in the side ...",
            },
            { Kor: "후려갈기는 것만 같아서  ", Eng: "could be beaten ..." },
          ],
        },
        {
          entryName: "-는 것으로 ",
          typicalExample: "【훼손시키는 것으로  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "With or by means of doing.",
          sentences: [
            {
              Kor: "훼손시키는 것으로  ",
              Eng: "by means of smudging [the glassy surface of the gallery floor as they stepped across]",
            },
          ],
        },
        {
          entryName: "-는 것으로 믿는다",
          typicalExample: '【"그렇지만 내가 아주 죽는 거로 믿지 마 ." 】',
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "-는 것으로 믿는다: believe/take as a case of ...-ing.",
          sentences: [
            {
              Kor: '"그렇지만 내가 아주 죽는 거로 믿지 마 ." ',
              Eng: '"But don\'t think of it as me dying forever."',
            },
          ],
        },
        {
          entryName: "-는 것으로 보아",
          typicalExample: "【비교적 싼 술을 사는 것으로 보아  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Judging from the fact that (somebody/something) does; judging by how (somebody/something) does.",
          sentences: [
            {
              Kor: "비교적 싼 술을 사는 것으로 보아  ",
              Eng: "When you look at the relatively inexpensive wine he bought …",
            },
          ],
        },
        {
          entryName: "-는 것으로 시작한다",
          typicalExample: "【꿈은 역시 도마뱀이 움직이는 것으로  시작한다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-는 것으로 시작한다: starts by ...-ing; starts by way of ...-ing; starts with something ...-ing.",
          sentences: [
            {
              Kor: "꿈은 역시 도마뱀이 움직이는 것으로  시작한다.  ",
              Eng: "Sure enough, the dream starts with the lizard moving.",
            },
          ],
        },
        {
          entryName: "-는 것을 ",
          typicalExample: "【울고 있는 것을 마냥 내버려두고 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "The doing, the fact or act of doing (as object).",
          sentences: [
            {
              Kor: "울고 있는 것을 마냥 내버려두고 ",
              Eng: "I left him alone to his sobbing, and ...",
            },
          ],
        },
        {
          entryName: "-는 것을 끝으로",
          typicalExample:
            "【마른 빨래를 개키는 것을 끝으로  자신의 방으로 돌아가면  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-는 것을 끝으로: with ...-ing as the last thing; wrapping up with ...-ing. ",
          sentences: [
            {
              Kor: "마른 빨래를 개키는 것을 끝으로  자신의 방으로 돌아가면  ",
              Eng: "when she would return to her room after finishing folding the dried laundry ... ",
            },
          ],
        },
        {
          entryName: "-는 것을 느낀다",
          typicalExample: "【나는 오스스 소름이 돋는 것 느낀다 .  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "-는 것을 느낀다: feels/senses something ...-ing.",
          sentences: [
            {
              Kor: "나는 오스스 소름이 돋는 것 느낀다 .  ",
              Eng: "I feel the goosebumps rising.",
            },
          ],
        },
        {
          entryName: "-는 것을 바라본다",
          typicalExample: "【퍼져가는 것을 바라본다 .  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-는 것을 바라본다: gazes at/observes something happening.",
          sentences: [
            {
              Kor: "퍼져가는 것을 바라본다 .  ",
              Eng: "gazes at it as it fans out",
            },
          ],
        },
        {
          entryName: "-는 것을 발견한다",
          typicalExample: "【잊어버린 것이 없다는 것을 발견하자 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Discovers that (someone/something) is ...",
          sentences: [
            {
              Kor: "잊어버린 것이 없다는 것을 발견하자 ",
              Eng: "as soon as [she] discovered there was nothing missing ...",
            },
            {
              Kor: "손아귀 속에 쥐어 있는 것을 발견한다 ",
              Eng: "He discovers that the [spoon] is clasped in his hand.",
            },
          ],
        },
        {
          entryName: "-는 것을 본다 ",
          typicalExample:
            "【도마뱀이 자신의 배와 다리 위를 기어 다니는 것을 보면서  자라나.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "-는 것을 보- -는 것을 보-: see/watch it ...-ing. ",
          sentences: [
            {
              Kor: "도마뱀이 자신의 배와 다리 위를 기어 다니는 것을 보면서  자라나.  ",
              Eng: "They grow up watching lizards crawling around on their stomachs and legs. ",
            },
            {
              Kor: "윤호는 운전 기사가 뒤쪽 시트에 묻어 있는 피를 씻어내는 것을 보았다 .  ",
              Eng: "Yunho saw the driver washing off the blood stains on the back seat.",
            },
            {
              Kor: "그는 날마다 사람들이 죽는 것을 보았다 .  ",
              Eng: "He saw people dying every day.",
            },
          ],
        },
        {
          entryName: "-는것을 안다 ",
          typicalExample: "【택하고 싶어해 가는 것을 알고  있었기 때문이다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "-는것을 아-ㄹ-: to know",
          sentences: [
            {
              Kor: "택하고 싶어해 가는 것을 알고  있었기 때문이다 ",
              Eng: "Because she knew that I wanted more and more to choose ...",
            },
          ],
        },
        {
          entryName: "-는 것이",
          typicalExample: "【일손을 놓고 시계를 보는 것이  10분 간격이었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Fact or act of ...-ing (as subject).",
          sentences: [
            {
              Kor: "일손을 놓고 시계를 보는 것이  10분 간격이었다 ",
              Eng: "[The act of] putting down her work and looking at the clock was at ten-minute intervals.",
            },
            {
              Kor: "사람들을 헤아리는 것이  눈에 들어 ",
              Eng: "her checking out of the people caught her eye, and ...",
            },
          ],
        },
        {
          entryName: "-는 것이 서럽다",
          typicalExample: "【의탁할 곳이 없는 것이 서러웠다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "One feels regret or wistfulness about doing ...",
          sentences: [
            {
              Kor: "의탁할 곳이 없는 것이 서러웠다  ",
              Eng: "[He] regretted that there was no place for him to rely on ...",
            },
          ],
        },
        {
          entryName: "-는 것이 아닌가? ",
          typicalExample: "【폭 싸여 있는 것이 아닌가  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Wouldn't ya know; I'll be damned it isn't a case of ... In other words, a rhetorical question.",
          sentences: [
            {
              Kor: "폭 싸여 있는 것이 아닌가  ",
              Eng: "Whaddaya know, there was a [rabbit] wrapped up ...",
            },
          ],
        },
        {
          entryName: "-는 것(이) 아닐까 ",
          typicalExample: "【깨시는 것이 아닐까 , 하였다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Mightn't it be the case that ...? In other words, a rhetorical question.",
          sentences: [
            {
              Kor: "깨시는 것이 아닐까 , 하였다 ",
              Eng: "I thought that perhaps she was waking me …  (Here followed by 하- in its meaning of think.)",
            },
          ],
        },
        {
          entryName: "-는 것이다 ",
          typicalExample: "【딴사람에게 떠넘겨버리는 것이다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "It is a matter/case of ...-ing.",
          sentences: [
            {
              Kor: "딴사람에게 떠넘겨버리는 것이다  ",
              Eng: "selling it off to the other person",
            },
            {
              Kor: "둥우리 밖에까지 빼내는 것이었다  ",
              Eng: "[they] would stretch [their necks] even beyond the nest",
            },
          ],
        },
        {
          entryName: "-는 것이었다",
          typicalExample: "【비오-- 비오-- 울어 고해 주는 것이었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Would always do / habitually did; it was a fact that one did ...; it was the case that (usually/habitually) did ... ",
          sentences: [
            {
              Kor: "비오-- 비오-- 울어 고해 주는 것이었다  ",
              Eng: 'They would always [do the villagers a favor and] serve notice by crying "Pio, pio!"',
            },
            {
              Kor: "학 이야기를 들려 주는 것이었다  ",
              Eng: "They would tell stories about the cranes.",
            },
            {
              Kor: "떠나가곤 하는 것이었다  ",
              Eng: "They (as was their regular practice) headed off; They would leave. (Here -는 것이었다 reinforces the habitual pattern in –고는 하-)",
            },
            {
              Kor: "홱 비켜 지나가 버리는 것이었다  ",
              Eng: "She would step aside and pass him by.",
            },
          ],
        },
        {
          entryName: "-는 것처럼",
          typicalExample:
            "【동경을 볼 수 있는 것처럼  이름 없이 떠돌다 간 가인(歌人)의 노래에서도 지배 계층에 대한 통렬한 풍자와 야유를 발견할 수 있는 것이지요.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "As if it did/is, like, (the same) as, as if ...; as, just as, just as it is/was; in accordance with.",
          sentences: [
            {
              Kor: "동경을 볼 수 있는 것처럼  이름 없이 떠돌다 간 가인(歌人)의 노래에서도 지배 계층에 대한 통렬한 풍자와 야유를 발견할 수 있는 것이지요.",
              Eng: "As though they were admired, bitter satire and ridicules against the ruling school could be found even in a song by a nameless singer who was no longer of this world.",
            },
            {
              Kor: "갈라지는 것처럼  아프고 ",
              Eng: "[His head] hurt as if it was splitting ...",
            },
            {
              Kor: "바늘로 쑤시는 것처럼  비비틀렸다 ",
              Eng: "[His whole body] wrenched as if he was being poked by needles.",
            },
            {
              Kor: "낚시질이나 나오는 것처럼  흰밥과 팥밥덩이를 한데신문지에 싸 끼고 해변으로 어슬렁거리고 나왔다. ",
              Eng: "As if [he himself] was going fishing, he came ambling down to the beach, holding in his arm the cooked rice and red beans that he had packed. ",
            },
            {
              Kor: "공연히 주눅들어하는 것처럼  말입니다 ",
              Eng: "I mean, like pointlessly losing their nerve. ",
            },
          ],
        },
        {
          entryName: "-는 것처럼 느낀다",
          typicalExample: "【아주머니의 눈이 젖어오는 것처럼 느꼈으나  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Feels like/as if/as though. ",
          sentences: [
            {
              Kor: "아주머니의 눈이 젖어오는 것처럼 느꼈으나  ",
              Eng: "I felt as though her eyes were getting teary, but ...",
            },
            {
              Kor: "마치 영화를 보거나 소설을 읽는 것처럼 느껴진다 .  ",
              Eng: "It feels just like watching a movie or reading a novel.",
            },
          ],
        },
        {
          entryName: "-는 것처럼 보이다",
          typicalExample: "【그 모습이 구토하는 것처럼 보인다 .  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Look/seem as if it/one is ...-ing.",
          sentences: [
            {
              Kor: "그 모습이 구토하는 것처럼 보인다 .  ",
              Eng: "In appearance, it looks like it is vomiting.",
            },
          ],
        },
        {
          entryName: "-는 게 낫다",
          typicalExample: '【"힘들어도 내 땅에서 사는 게 낫지 ." 】',
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "...-ing would be/is better; ...-ing would be/is preferable. Often preceded/flagged by adverb 차라리. ",
          sentences: [
            {
              Kor: '"힘들어도 내 땅에서 사는 게 낫지 ." ',
              Eng: "\"However difficult it may be, it's better to live in one's own land.\"",
            },
            {
              Kor: "역시 안 말하는 게 나을  뻔했다고",
              Eng: "Sure enough, I should have kept my mouth shut … (It would have ['almost'] been better/preferable if one had ... )",
            },
          ],
        },
        {
          entryName: "-는 게 아니래서였던지",
          typicalExample: "【그러나 그건 시집을 가는 게 아니래서였던지  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "(Nobody knows) the uncertain fact (since when). When left hanging in mid-sentence, you can interpret the pattern as having left out a following -도 모르지만 it may have been the case that … i.e., perhaps [it is/was] because ... .",
          sentences: [
            {
              Kor: "그러나 그건 시집을 가는 게 아니래서였던지  ",
              Eng: "but perhaps because that was not a marriage ...",
            },
          ],
        },
        {
          entryName: "-는 게 아닌가",
          typicalExample: "【소녀가 앉아 있는게 아닌가 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Negative rhetorical question for literary effect: is it not the case that . . . ? = Wouldn't you know it—thus-and-such is the case!",
          sentences: [
            {
              Kor: "소녀가 앉아 있는게 아닌가 ",
              Eng: "Wouldn't you know it—the girl is sitting [on this side of the bank of the stream]; or, And whaddaya know, the girl is sitting ...; or, Whoa! She's sitting on this side of the stream!",
            },
          ],
        },
        {
          entryName: "-는 게 틀림없다 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "In origin, from -는 것이 틀림없다: There is no doubt that (it does), there is no questioning that fact that ...",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는 게냐?",
          typicalExample: "【여기서 뭘 하 는 게냐?   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "-는 게냐? ← -는 것이냐? Is it a case of ...-ing?",
          sentences: [
            {
              Kor: "여기서 뭘 하 는 게냐?   ",
              Eng: "What are you doing in here?",
            },
          ],
        },
        {
          entryName: "-는 게지",
          typicalExample: "【죽는 날이나 기다리고 있는 게지  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Abbreviated from -는 것이지 (← 거 + -이지): it is the case that ... (of course/to be sure).",
          sentences: [
            {
              Kor: "죽는 날이나 기다리고 있는 게지  ",
              Eng: "I'm just waiting for the day I die.",
            },
          ],
        },
        {
          entryName: "-는 결에",
          typicalExample: "【모르는 결에  그것을 느낄 뿐이다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'Postmodifier 결 = -는길에: (in) the course of; in passing while (doing); at the same time as; when, while one is at it; incidental (to); happening (to) in passing; "(on) the wave of."',
          sentences: [
            {
              Kor: "모르는 결에  그것을 느낄 뿐이다 ",
              Eng: "Before he could realize it, that was the only thing he felt.",
            },
          ],
        },
        {
          entryName: "-는 경우 ",
          typicalExample: "【늘 한가지를 사는 경우  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Situation, case used as a postmodifier: in cases when, in circumstances when.",
          sentences: [
            {
              Kor: "늘 한가지를 사는 경우  ",
              Eng: "in cases where they always bought the same thing",
            },
          ],
        },
        {
          entryName: "-는고 ",
          typicalExample: "【무슨 사내값에 가는고  】",
          categoryKorean: "하게체의 종결 어미 Final ending in Familiar Style  ",
          categoryEnglish:
            "(하게체의 종결 어미 Final ending in Familiar Style  )",
          translation:
            'In Middle Korean and in some Korean dialects, verbs in "WH-questions" (i.e., questions which contain a question word like "what?" "how many" or the like, as opposed to yes-no questions) take a question ending in final -고 rather than -가 as in the modern standard language.',
          sentences: [
            {
              Kor: "무슨 사내값에 가는고  ",
              Eng: "of what value is a husband ...",
            },
          ],
        },
        {
          entryName: "-는구",
          typicalExample: "【무얼 하는구? 】",
          categoryKorean: "하게체의 종결 어미 Final ending in Familiar Style  ",
          categoryEnglish:
            "(하게체의 종결 어미 Final ending in Familiar Style  )",
          translation:
            'For -는고? In Middle Korean and in some Korean dialects, verbs in "WH-questions" (i.e., questions which contain a question word like "what?" "how many" or the like, as opposed to yes-no questions) take a question ending in final -고 rather than -가 as in the modern standard language.',
          sentences: [{ Kor: "무얼 하는구? ", Eng: "What would you do?" }],
        },
        {
          entryName: "-는구나",
          typicalExample: "【[Examples?]】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "Apperceptive ending with the meaning of 'first realization': Now I see/realize that . . . ! ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는구려",
          typicalExample: "【지질힌 오래 댕기는구려  】",
          categoryKorean:
            "하오체의 종결 어미 Final ending in Semiformal Style  ",
          categoryEnglish:
            "(하오체의 종결 어미 Final ending in Semiformal Style  )",
          translation:
            "Used to make a (standard) Familiar Style apperceptive (equivalent to -는구나,-는구먼요, etc. in other styles).",
          sentences: [
            {
              Kor: "지질힌 오래 댕기는구려  ",
              Eng: "So you're still a constable after this tedious long time!",
            },
          ],
        },
        {
          entryName: "-는구먼(요)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Apperceptive ending with the meaning of 'first realization': Now I see/realize that ...! ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는군(요)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Apperceptive ending with the meaning of 'first realization': Now I see/realize that ...! ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는 기색이다",
          typicalExample: "【그러나 엄마는 믿지 않는 기색이다 .  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Gives the appearance/air of ...-ing.",
          sentences: [
            {
              Kor: "그러나 엄마는 믿지 않는 기색이다 .  ",
              Eng: "But mom looks as though she does not believe me.",
            },
          ],
        },
        {
          entryName: "-는기오?",
          typicalExample: "【오래 살았는기오?  】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            "Kyŏngsang-do (경상도) dialect question ending equivalent in function to standard -는가요? but derived historically from -는 게 (← 것이)오 → -는 기오?",
          sentences: [
            { Kor: "오래 살았는기오?  ", Eng: "Have you lived [here] long?" },
          ],
        },
        {
          entryName: "-는 길로 ",
          typicalExample: "【그날 아침에도 이장 영감은 일어나는 길로  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "As soon as ...; no sooner than ...",
          sentences: [
            {
              Kor: "그날 아침에도 이장 영감은 일어나는 길로  ",
              Eng: "that same morning, as soon as he got up, the village head ...",
            },
          ],
        },
        {
          entryName: "-는 까닭에 ",
          typicalExample: "【밤중에 운신하는 까닭에  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Because of ...-ing.",
          sentences: [
            {
              Kor: "밤중에 운신하는 까닭에  ",
              Eng: "because they move around only in the deep of night ...",
            },
          ],
        },
        {
          entryName: "-는 내내 ",
          typicalExample: "【제가 안방에 있는 내내  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The whole time one is ...-ing; all throughout ...-ing; for as long as ... ; for the duration of ...; for the whole time ...",
          sentences: [
            {
              Kor: "제가 안방에 있는 내내  ",
              Eng: "the whole time I was in the room ...",
            },
            {
              Kor: "섹스하는 내내  ",
              Eng: "all the time I was having sex, ....",
            },
          ],
        },
        {
          entryName: "-는 눈치(이)다",
          typicalExample: "【무척 존경하는 눈치였다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "One's mind (intention, inclination), one's mental attitude towards (a person), one's motive/design; a sign, a hint",
          sentences: [
            {
              Kor: "무척 존경하는 눈치였다  ",
              Eng: "[That junior] seemed to really respect me.",
            },
            {
              Kor: "나한테서 기대하는 눈치였다  ",
              Eng: "It appeared that he was expecting something of me.",
            },
          ],
        },
        {
          entryName: "-는 눈치(를) 보인다",
          typicalExample: "【곧이들리지 않는다는 눈치를 보였다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Give (show) indirect signs or hints that ...",
          sentences: [
            {
              Kor: "곧이들리지 않는다는 눈치를 보였다  ",
              Eng: "He looked as if he had no idea what I was talking about.",
            },
          ],
        },
        {
          entryName: "-는다거나",
          typicalExample:
            "【어린 나무를 짓밟는다거나  하여 자연을 훼손하는 일이 더러 있지만 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "어린 나무를 짓밟는다거나  하여 자연을 훼손하는 일이 더러 있지만 ",
              Eng: "trampling on young trees or the like and injuring the environment happens sometimes, but ...",
            },
          ],
        },
        {
          entryName: "-는다고",
          typicalExample: "【그릇을 손으로 더듬는다고  소리를 지르며 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            'For reasons/pretexts. Lit., saying, "Quote." Used to give a reason or pretext.',
          sentences: [
            {
              Kor: "그릇을 손으로 더듬는다고  소리를 지르며 ",
              Eng: "while yelling at (the blind man) for fumbling with the ( dishes) ...",
            },
            {
              Kor: "오히려 나는 도마뱀이 더 빨리 내려오지 않는다고  답답해했던 것 같다. ",
              Eng: "Actually, it seemed I was annoyed that the lizard did not come down faster.",
            },
          ],
        },
        {
          entryName: "-는다고 (해서)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Followed by a negative clause, a quoted phrase like this means nevertheless; having said that (but); just because A, B is not necessarily the case.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다고 해도",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'Even supposing ... Lit.: Saying "Quote." used to give a reason.',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다기보다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB1-는다기보다 VERB2: more as what you would call VERB2 than VERB1; it's not so much that A, rather ... B. In origin, an abbreviated indirect quotation: VERB1-는다기보다 ← VERB1-는다[고 하]기보다 rather/more than say that it is a case of VERB1, it is [actually/rather] [something else].",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다느니 만큼 ",
          typicalExample: "【점심을 아홉 번이나 먹는다느니만큼  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "To the extent that they say that ...",
          sentences: [
            {
              Kor: "점심을 아홉 번이나 먹는다느니만큼  ",
              Eng: "because lunch is eaten as many as nine times ...",
            },
          ],
        },
        {
          entryName: "-는다는1",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "The [thing, etc.] about which they [or whoever] say/about which it is said that, abbreviated from an indirect quotation in -는다[고 하]는, modifying the following thing, etc. ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다는2",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "[The person] who says/claims that . . . abbreviated from an indirect quotation in -는다[고 하]는, modifying the following person, etc.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다는3 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "The putative/alleged [fact, claim, words, etc.] to the effect that QUOTE, abbreviated from an indirect quotation in -다[고 하]는, modifying the following fact, claim, words, etc.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다는 걸 알린다",
          typicalExample: "【늦는다는 걸 알리는  그이의 목소리.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-는다는 걸 알린다 Tells or informs ['of the fact'] that ... , where -다는 걸/-다는 것을 (cf. also -ㄴ다는 것, -는다는 것, -(이)라는 것) from the indirect quotation pattern in -다[고 하]는 것, etc., meaning the putative or alleged fact that ... .",
          sentences: [
            {
              Kor: "늦는다는 걸 알리는  그이의 목소리.  ",
              Eng: "His voice, informing me that he'll be late.",
            },
          ],
        },
        {
          entryName: "-는다는 것",
          typicalExample: "【자위를 하다가 죽는다는 것도  이상하고  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Also -(이)라는 것, -다는 것, abbreviated from -는다[고 하]는 것, etc. the claim that ... ; the alleged (putative)fact that ... to set up a generic definition or value judgement (like-(이)란). Used to set up a comment about the often unusual/noteworthy) topic marked by -는 것",
          sentences: [
            {
              Kor: "자위를 하다가 죽는다는 것도  이상하고  ",
              Eng: "dying in the middle of masturbating is strange, and besides...",
            },
            {
              Kor: "그 핏줄을 찾는다는 것이었다 ",
              Eng: "It was tracing the bloodlines.",
            },
          ],
        },
        {
          entryName: "-는다는군(요)",
          typicalExample: "【아들이 하숙집에 들어오지를 않는다는군요  】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Contracted quotation from -는다[고 하]는군요 Wouldn't you know—they say that ...! ",
          sentences: [
            {
              Kor: "아들이 하숙집에 들어오지를 않는다는군요  ",
              Eng: "The son hasn't come home to the boarding house [for more than a week].",
            },
          ],
        },
        {
          entryName: "-는다니까",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            'Abbreviated from -다고 하니까. For insistent, reproachful reiterations. But this abbreviated pattern has a special nuance: I say/insist it is/does or I tell you (emphatically, yet again) it is/does = it really is/does; it is/does, you see (mind you); But I tell you, " . . . !"',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다는 둥 ",
          typicalExample: "【조선 사람은 고추를 끔찍이 많이 먹는다는 둥  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "From -는다[고 하-]는 둥, with postmodifier 둥 for what is originally 등 in its meaning (following a list or enumeration) of et cetera, and so forth, and so, and the like, and all that, and such like for a list of (usually quoted) hypothetical options or choices.",
          sentences: [
            {
              Kor: "조선 사람은 고추를 끔찍이 많이 먹는다는 둥  ",
              Eng: "… carried on about how Koreans ate incredible amounts of hot pepper, blah-blah-blah, and ...",
            },
          ],
        },
        {
          entryName: "-는다는 듯(이)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Lit.: like, as, giving the appearance as if to say ... from an abbreviated indirect quotation structure in -다[고 하]는 듯(이).",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다는 생각 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구/Modifier",
          categoryEnglish: "(관용구/Modifier)",
          translation:
            "Thoughts of ...; the thought that ...; thought[s] to the effect that QUOTE. Built on an abbreviated indirect quotation in -는다[고 하-] modifying the following 생각 thought.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다는 이유로",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구/Modifier",
          categoryEnglish: "(관용구/Modifier)",
          translation:
            "On account of the (alleged) reason that ... with an abbreviated indirect quotation in -는다는 ← -는다[고 하]는 modifying 이유 reason.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다는 인상 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구/Modifier",
          categoryEnglish: "(관용구/Modifier)",
          translation:
            "Impression to the effect that ...; impression to the effect that QUOTE. Built on an abbreviated indirect quotation in -는다[고 하-] modifying 인상 impression.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다더구나",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            'From an abbreviated quote in -는다[고 하]더구나! with a retrospective apperceptive in -더구나, -더군: Now I realize that somebody said/claimed that ... ! Apperceptives carry the meaning of "first realization" ... in - 군, -구나, -군요, -구려, etc.',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다더군(요)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Abbreviated ← -다[고 하]더군요: I see that they say that ...!",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다던 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "For -는다[고 하]던, an abbreviated indirect quotation + retrospective modifier, meaning something like: [NOUN] about which it used to be said that QUOTE. ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다매",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            'From -는다(고 하)며 meaning 1) While saying that "QUOTE," . . .  and 2) Is it true that "QUOTE"?',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다며1",
          typicalExample:
            "【나는 용감한 자만이 미인을 얻는다며  짐짓 비장해 했다.  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            '-는다며 for -는다[고 하]며 meaning While saying/thinking that "QUOTE." ',
          sentences: [
            {
              Kor: "나는 용감한 자만이 미인을 얻는다며  짐짓 비장해 했다.  ",
              Eng: "I made a point of playing the tragi-heroic, claiming that only the very brave get the good-looking women.",
            },
          ],
        },
        {
          entryName: "-는다며2",
          typicalExample: "【[Examples?]】",
          categoryKorean: "해체의 종결 어미 Final ending in Intimate Style",
          categoryEnglish: "(해체의 종결 어미 Final ending in Intimate Style)",
          translation:
            '-는다며 for -는다[고 하]며 meaning Is it true that "QUOTE"?',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다면",
          typicalExample: "【호랑일 좇는다면  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Conditional in -는다면, -ㄴ다면, -다면. In origin, this pattern is built on an indirect quotation in -는다[-고 하-]면, -ㄴ다[-고 하-]면, -다[-고 하-]면 and can be interpreted in two ways: 1) literally, as if one says/supposes that . . .  and 2) as a more hypothetical if than the usual conditional in -(으)면.",
          sentences: [
            {
              Kor: "호랑일 좇는다면  ",
              Eng: "if we go after the tiger … (For 호랑이를.)",
            },
          ],
        },
        {
          entryName: "-는다면서",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            'From -는다[-고 하-]면서 meaning While saying that "QUOTE," . . .',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다면서(요)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation: 'From -는다(고 하)면서 meaning Is it true that "QUOTE"?',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다지 뭐예요?",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Wouldn't you know it...!, The next thing you know...",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는다 하나",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Abbreviated indirect quotation from -다[고] 하나: They say that, . . . but . . . ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는단다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "Abbreviated Plain Style quotations in -단다 ← -다[고 하-]ㄴ다 are used (often by children) as impudent, taunting or proud boasts.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는단 말야",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "An abbreviated indirect quotation in -는단 ← -는다[고 하-]ㄴ as modifier to following 말 words to the effect that QUOTE, where 말 (말야 ← 말이야, 말입니다, etc.—말 followed by a form of the copula -이다), meaning I mean, you know, you see, uh, that is, etc.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는담서 ",
          typicalExample: "【뭐 관광호텔을 여러 채 짓는담서 】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            'Dialect for -는다면서 or -다(고 하)면서: 1) While saying that "QUOTE," . . .  and 2) Is it true that "QUOTE"?',
          sentences: [
            {
              Kor: "뭐 관광호텔을 여러 채 짓는담서 ",
              Eng: "They say they're building several tourist hotels or something ...",
            },
          ],
        },
        {
          entryName: "-는답니다 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "하십시오체의 종결어미 Final ending in Formal Style",
          categoryEnglish:
            "(하십시오체의 종결어미 Final ending in Formal Style)",
          translation:
            "Abbreviated from an indirect quotation in -ㄴ/는다[고 하]ㅂ니다: I say it is/does; I tell you (somewhat insistently or excitedly) it is/does; it really is/does; it is/does; you see (mind you).",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는대도",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Abbreviated quotation from  -는다고 해도: Even though one says or claims that . . . ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는 대로1",
          typicalExample: "【있는 대로  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "As, just as, just as it is (was); in accordance with; depending on how things go/develop.",
          sentences: [
            { Kor: "있는 대로  ", Eng: "just as it was ..." },
            {
              Kor: "마음에 바라는 대로  새 복이 올겐가 하고 잠깐 생각해 보지 않을 수 없었다.",
              Eng: "… couldn't help but imagine that new fortune will come soon according to their hopes.  ",
            },
            {
              Kor: "있는 대로  혓바닥을 빼서 용용이를 쳐주고 싶었지만 차마 그럴 수는 없었다. ",
              Eng: "He had the urge to stick out his tongue and taunt her to the best of his ability, but he knew that he just couldn't do that.",
            },
            {
              Kor: "나는 그들이 권하는 대로  두 대접이나 마셨다. ",
              Eng: "I drank the whole two glasses of it just as they told me ...",
            },
            {
              Kor: "양씨가 이끄는 대로  , 발자국을 따르지  않고 바로 능선으로 기어올랐다. ",
              Eng: "Just as Mr. Yang showed us, instead of following the footsteps, I crawled right up towards the ridgeline ...",
            },
            {
              Kor: "뭐 되가는 대루 . 그런데 삼포는 어느 쪽입니까? ",
              Eng: "Um ... depends on how things go, but could you tell me the direction to Sampo?",
            },
            {
              Kor: "이런 생각을 되는 대로  하면서 자꾸 머리속으로 들어오는 아버지의 모습을 열심히 지워 나갔다. ",
              Eng: "I did my best to keep thinking useless thoughts like these so as to block out images of my father which kept coming into my head. ",
            },
            {
              Kor: "그들은 무엇인지는 모르는 대로  ",
              Eng: "they had no idea why, but ...",
            },
            {
              Kor: "그들이 놀리는 대로  과연 ",
              Eng: "just as they teased me, sure enough, ...",
            },
            {
              Kor: "되는 대로  짐을 우겨넣은 커다란 트렁크 ",
              Eng: "her huge trunk, stuffed any old which way with her things (With 되- this pattern produces a somewhat idiomatic meaning: as much as possible, insofar as possible.)",
            },
          ],
        },
        {
          entryName: "-는 대로2",
          typicalExample:
            "【그리고 서울에서 준비가 되는 대로  소식을 드리면 당신은 무진을 떠나서 제게 와 주십시오.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "As soon as, no sooner than.",
          sentences: [
            {
              Kor: "그리고 서울에서 준비가 되는 대로  소식을 드리면 당신은 무진을 떠나서 제게 와 주십시오.",
              Eng: "I will notify you as soon as preparations in Seoul are all set, and when that happens, I want you to leave Mujin and come to me.",
            },
            {
              Kor: "눈에 띄는 대로  휴지 조각을 밀착시켰다. ",
              Eng: "As soon as [he] caught a glimpse, he stuck a small piece/scrap of toilet paper onto it. ",
            },
            {
              Kor: "굵은 놈으로만 먹히는 대로  땄다 ",
              Eng: "… picked only the fattest ones as fast as he could eat them.",
            },
          ],
        },
        {
          entryName: "-는 대신(에)",
          typicalExample: "【말로 묻는 대신  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Instead of ..., in place/lieu ... ; while on the one hand ... ; in compensation for [not] ...-ing.",
          sentences: [
            {
              Kor: "말로 묻는 대신  ",
              Eng: "instead of wording her question ...",
            },
            {
              Kor: "양씨의 말을 빌리는 대신  ",
              Eng: "instead of borrowing Mr. Yang's words ...",
            },
            {
              Kor: "잊어버린 것이 없는 대신  ",
              Eng: "lit.: in compensation for the fact that nothing was missing ...; i.e., while [on the one hand] nothing was missing ...",
            },
            {
              Kor: "이 번에는 굽실대는 대신  ",
              Eng: "this time, instead of servile bowing ...",
            },
          ],
        },
        {
          entryName: "-는대(요)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending/Colloquial",
          categoryEnglish: "(종결 어미 Final ending/Colloquial)",
          translation:
            "Colloquial contracted quotation from -는다[고 ㅎ]ㅐ요: Says that somebody does it.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄴ데다",
          typicalExample:
            "【그런데다  돈은 흔해져서 관철동 다옥정 같은 중앙 지대에는 그리 고옥만 아니면 만 원대를 예사로 훌훌 넘었다.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Abbreviated from -(으)ㄴ/-는데다가: On top of ...-ing, besides ...-ing. ",
          sentences: [
            {
              Kor: "그런데다  돈은 흔해져서 관철동 다옥정 같은 중앙 지대에는 그리 고옥만 아니면 만 원대를 예사로 훌훌 넘었다.",
              Eng: "On top of money becoming so plentiful that in central areas like Ta'ok-chŏng in Gwanchŏl precinct, as long as it was not a really old housing, it was priced at over ten-thousand wŏn.",
            },
          ],
        },
        {
          entryName: "-는 데(에)",
          typicalExample: "【그녀를 데려다 주고 집까지 되돌아오는 데에  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "-는 데(에) in the course of ...-ing. Here, the “circumstantial 데” occurs as a quasi-free noun (that is, a noun that cannot begin a sentence but must always be modified by something in front of it) with the meaning course (of doing something); circumstance, occasion. The locative particle 에 spells out the sense of in the course or circumstance of ...-ing, and the following clause usually has expressions like 도움을 준다 helps, 좋은 참조가 된다 serves as a good reference, 쓴다/사용한다 one uses it, 필요하다 is necessary, 중요하다 is important, etc. The literal sense is something like in the process or course of ...-ing, ...",
          sentences: [
            {
              Kor: "그녀를 데려다 주고 집까지 되돌아오는 데에  ",
              Eng: "just taking her home and coming back",
            },
            {
              Kor: "아버지가 지섭을 내쫓는 데  누나의 의견도 많은 참작이 되었다.  ",
              Eng: "His older sister's opinions had been a big factor in his father's kicking out Chisŏp. ",
            },
            {
              Kor: "나는 그 여자보다 훨씬 손아래지만 지금 남편 옆에서 볼품없는 꼴로 늙어 가는데 ",
              Eng: "I am supposed to be decades younger than her but look at me growing old and pathetic alongside my husband...",
            },
            {
              Kor: "목숨을 이어가는 데  해어쯤이 없으면 어떨까도 생각되었다. ",
              Eng: "He wouldn't have seafood, but in the course of continuing to eke out a living that would not bother him, he thought.",
            },
          ],
        },
        {
          entryName: "- 는 데까지",
          typicalExample: "【고개가 더 돌아가지 않는 데까지  눈을 주어 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "To the point where; until the point when/where; as far as ...",
          sentences: [
            {
              Kor: "고개가 더 돌아가지 않는 데까지  눈을 주어 ",
              Eng: "stared, turning his head till he could turn his head no more",
            },
            {
              Kor: "힘에 자라는 데까지  졌다 ",
              Eng: "he carried it until he reached the limits of his strength",
            },
          ],
        },
        {
          entryName: "-는데다(가)",
          typicalExample: "【별로 말이 없는데다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "From -는데(에) + -(이)다가: Not only ... but in addition; on top of that. Used for processive bases, past and future bases, and 있-/없-.",
          sentences: [
            {
              Kor: "별로 말이 없는데다  ",
              Eng: "not only did I have nothing much to say, (but on top of that) ...",
            },
            {
              Kor: "학식과 재기가 있는데다  = 있는데다가  ",
              Eng: "on top of having learning and talent",
            },
          ],
        },
        {
          entryName: "-는데도",
          typicalExample:
            "【가스 불을 줄여 놓았는데도  벌써 프라이팬에서 연기가 올라오고 있다.  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "-(으)ㄴ/-는데도: Given the circumstance that it is/does, even though, though; in spite of the fact that ... . This pattern can usually be taken as an abbreviation of -는데도 불구하고 in spite of the fact that … . ",
          sentences: [
            {
              Kor: "가스 불을 줄여 놓았는데도  벌써 프라이팬에서 연기가 올라오고 있다.  ",
              Eng: "Even though I had turned down the flame, there was already smoke rising from the frying pan.",
            },
            {
              Kor: "시트를 걷어낸 기억이 없는데도   ",
              Eng: "even though she had no memory of stripping the sheets …",
            },
            {
              Kor: "난로가 있는데도  ",
              Eng: "even though there was a stove …",
            },
            {
              Kor: "달맞이하는데도 ",
              Eng: "even though it is about viewing the moon ...",
            },
          ],
        },
        {
          entryName: "-는데(요)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "For Imminent Elaboration. Used with processive bases, 있-/없-, past bases and future bases (latter two listed separately under -었는데(요) and -겠는데(요)). -던데(요), -었는데(요), -겠는데(요), -ㄴ데(요).",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는데 대해서 ",
          typicalExample: "【그가 세간살이를 태운다는데 대해선  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "With regard to/with respect to ...-ing.",
          sentences: [
            {
              Kor: "그가 세간살이를 태운다는데 대해선  ",
              Eng: "with regard to his burning down his entire household ...",
            },
          ],
        },
        {
          entryName: "-는 데 소질이 있다",
          typicalExample:
            "【그녀는 아이를 키우고 집안일을 하는 데 소질이 있는  편이었다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Is talented in ...-ing; Has a talent for ...-ing. ",
          sentences: [
            {
              Kor: "그녀는 아이를 키우고 집안일을 하는 데 소질이 있는  편이었다.  ",
              Eng: "She has a knack for raising kids and managing the house.",
            },
          ],
        },
        {
          entryName: "-는 데야 ",
          typicalExample: "【결혼도 마케팅이라는 데야 할 말 있나요? 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Given the indisputable circumstance (데) that... with circumstantial -는데 + particle 야 only if.",
          sentences: [
            {
              Kor: "결혼도 마케팅이라는 데야 할 말 있나요? ",
              Eng: "Is there anything I could have said given the fact that she regarded even marriage to be a financial transaction?",
            },
          ],
        },
        {
          entryName: "-는데(요) 뭘",
          typicalExample: "【20대에도 애인 없던 시절이 있었는데 뭘 . 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "This is -(으)ㄴ/는데(요) for Imminent Elaboration, rounded off with 뭘 meaning something like After all/Jeez, give me a break already! ",
          sentences: [
            {
              Kor: "20대에도 애인 없던 시절이 있었는데 뭘 . ",
              Eng: "After all, there was a time during my twenties when I did not have a boyfriend, too.",
            },
          ],
        },
        {
          entryName: "-는데(에)1",
          typicalExample: "【묻고 있는데  당신이 불러들였으니까 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Circumstantial –는데: (just) when one is in the middle of doing something [something else happens]. ",
          sentences: [
            {
              Kor: "묻고 있는데  당신이 불러들였으니까 ",
              Eng: "I was just in the middle of asking him but you called me out",
            },
            {
              Kor: "자는데  깨우면 ",
              Eng: "if you wake me up when I'm sleeping ...",
            },
            {
              Kor: "방문을 여는데  월매의 하던 말끝이 들렸다. ",
              Eng: "She was opening the door when she caught the last bit of what Wŏlmae was saying ...",
            },
          ],
        },
        {
          entryName: "-는데(에)2",
          typicalExample: "【오르는 데 십리, 내리는 데  십리 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Circumstantial -는데(에): in/for the process of . . . ing [something is required] for/in order to . . .  ",
          sentences: [
            {
              Kor: "오르는 데 십리, 내리는 데  십리 ",
              Eng: "[they say that] it is ten li going up and ten li going down ...",
            },
          ],
        },
        {
          entryName: "-는데(에)3",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Circumstantial -는데(에): with respect to . . . -ing; as far as . . . -ing is concerned.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는데(예) 따라(서)",
          typicalExample: "【내 턱은 뻐스가 껑충거리는 데 따라서  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "In accordance with the way it does ...",
          sentences: [
            {
              Kor: "내 턱은 뻐스가 껑충거리는 데 따라서  ",
              Eng: "my chin (bounced) with the bus every time the bus would rattle ...",
            },
          ],
        },
        {
          entryName: "-는 동시 ",
          typicalExample: "【그들의 액운을 동정하는 동시  그 반면 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "At the same time (as), simultaneously, concurrently (with) ...",
          sentences: [
            {
              Kor: "그들의 액운을 동정하는 동시  그 반면 ",
              Eng: "while I felt sympathy for their misfortune, on the other hand ...",
            },
          ],
        },
        {
          entryName: "-는 동안",
          typicalExample: "【벌써 두 여름이 되는 동안 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "While ...-ing, during ...-ing.",
          sentences: [
            {
              Kor: "벌써 두 여름이 되는 동안 ",
              Eng: "during the two years which it had already become ...",
            },
          ],
        },
        {
          entryName: "-는 듯(이)",
          typicalExample: "【매달리는 듯이  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Like, as, giving the appearance of ...; appearing (to be), looking (like); seeming as if; as if/though. This pattern is often 'flagged' in front by the adverb 마치 which serves as a sort of warning to the speaker that a like/as if pattern in 듯이 or -ㄴ/-는 것 처럼 is coming up.",
          sentences: [
            { Kor: "매달리는 듯이  ", Eng: "as if hanging on / pestering" },
            {
              Kor: "눈앞에 그리는 듯이  ",
              Eng: "as if picturing/seeing it before his very eyes",
            },
            {
              Kor: "술로 녹이려는 듯이  ← 녹이려[고 하-]는 듯이 ",
              Eng: "as if meaning/intending to drown (lit., melt) [his sorrow] in alcohol",
            },
            {
              Kor: "생각을 잊어버리기라도 하려는 듯이  ← 하려[고 하-]는 듯이  ",
              Eng: "as if [meaning/intending] to banish the thought from his mind or something",
            },
            {
              Kor: "흐린 눈을 돋우는 듯  ",
              Eng: "as if (he) was raising his cloudy eyes...",
            },
            { Kor: "신이 나는 듯  ", Eng: "seeming excited ..." },
            {
              Kor: "정분을 느끼는 듯  ",
              Eng: "seemed to have kindred feelings for ...",
            },
            {
              Kor: "큰 선심이나 쓰는 듯이  ",
              Eng: "as if exercising some great kindness ...",
            },
            { Kor: "저주하는 듯 ", Eng: "as if inflicting a curse ..." },
            {
              Kor: "이장 영감은 잠깐 무엇을 생각하는 듯  ",
              Eng: "the village head, as if thinking about something for a moment ...",
            },
          ],
        },
        {
          entryName: "-는 듯 마는 듯하- ",
          typicalExample: "【잠을 이루는 듯 마는 듯하면서  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Seems alternately like it does/is ...; does/is not ... Based on 듯 is like, is as (if).",
          sentences: [
            {
              Kor: "잠을 이루는 듯 마는 듯하면서  ",
              Eng: "on this sleepless night ...",
            },
          ],
        },
        {
          entryName: "-는 듯싶다",
          typicalExample: "【자기 혼자 보는 듯싶었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier clause in -는 + 듯 + 싶- it feels/seems like, as if maybe it does/is doing; think to oneself that it does/is doing.",
          sentences: [
            {
              Kor: "자기 혼자 보는 듯싶었다  ",
              Eng: "it seemed to him that he alone was seeing it ...",
            },
          ],
        },
        {
          entryName: "-는 듯하다",
          typicalExample: "【풀려지는 듯하였다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier + 듯 (하다) ( i.e., -ㄴ 듯하다, -ㄹ 듯하다, -는 듯하다) is like, is as (if) is like, is as (if).",
          sentences: [
            {
              Kor: "풀려지는 듯하였다  ",
              Eng: "seemed to be relaxing/thinning",
            },
            {
              Kor: "짜는 듯한  목 ",
              Eng: "his voice, which seemed to be cracking",
            },
            {
              Kor: "기를 펴는 듯한  아이들 ",
              Eng: "the children who seemed to relax ...",
            },
            { Kor: "떨리는 듯한  음성 ", Eng: "in a quivering voice ..." },
            {
              Kor: "심장이 싸늘해지는 듯한 긴장감 ",
              Eng: "a heart-chilling kind of tension ...",
            },
            {
              Kor: "알마덴의 안도하는 듯한  기색 ",
              Eng: "[Almaden's] face seemed relieved",
            },
            {
              Kor: "들리는 듯도  하였다 ",
              Eng: "as if also (the sound) could be heard ... ",
            },
          ],
        },
        {
          entryName: "-는 때도 있다",
          typicalExample: "【마을로 작업을 하러 내려오는 때도 있었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "There are also times when ...",
          sentences: [
            {
              Kor: "마을로 작업을 하러 내려오는 때도 있었다  ",
              Eng: "There were also times when [under the surveillance of military police, the prisoners] would come down to the village to do some work.",
            },
          ],
        },
        {
          entryName: "-는 뜻에서 ",
          typicalExample: "【그럼 사과하는 뜻에서 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "In the sense/meaning of ...-ing; by way of implying ...",
          sentences: [
            { Kor: "그럼 사과하는 뜻에서 ", Eng: "by way of an apology ..." },
          ],
        },
        {
          entryName: "-는 마당에",
          typicalExample: "【왜놈들이 들끓는 마당에  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Same as -는 판에. In the somewhat shabby or embarrassing situation where/that ...",
          sentences: [
            {
              Kor: "왜놈들이 들끓는 마당에  ",
              Eng: "in a situation swarming with Japs ... ",
            },
          ],
        },
        {
          entryName: "-는 만큼 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "In as much as, in so far as, since (does); to the extent that (does).",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는 모양(으로)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "With an appearance, look, or air (signs, indications, symptoms way, manner) of doing.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는 모양이다 ",
          typicalExample: "【높이 평가하는 모양이었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "A 모양 is an appearance. Thus, this pattern means looks like it is doing, appears to be doing.",
          sentences: [
            {
              Kor: "높이 평가하는 모양이었다  ",
              Eng: "it seemed as though I was highly appreciated",
            },
            {
              Kor: "도무지 모를 말도 바우는 아는 모양이었다 .",
              Eng: "Pau appeared to understand even some absolutely incomprehensible words, too.",
            },
          ],
        },
        {
          entryName: "~는 물론",
          typicalExample:
            "【텔레비나 라디오는 물론  그 흔한 잡지 모퉁이나 신문 조각에도 성명 삼 자가 오르내려본 적이 없는 평범한 소시민이었기 때문이다.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "A는 물론: as for A, it goes without saying ...",
          sentences: [
            {
              Kor: "텔레비나 라디오는 물론  그 흔한 잡지 모퉁이나 신문 조각에도 성명 삼 자가 오르내려본 적이 없는 평범한 소시민이었기 때문이다.",
              Eng: "It was because he was just a petty bourgeois who had never had the three letters of his name appeared anywhere on any corners of magazines or newspapers that seemed to be just about everywhere, let alone on television or radio.",
            },
          ],
        },
        {
          entryName: "-는 바람에",
          typicalExample: "【웃음소리를 내는 바람에  아이는 놀라  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier + postnoun/postmodifier 바람 (in) conjunction (with); (in) the process (of); (as a) consequence (of); (as a) result (of); due to the impetus/momentum/influence of ...-ing. The upshot is often undesirable.",
          sentences: [
            {
              Kor: "웃음소리를 내는 바람에  아이는 놀라  ",
              Eng: "the child startled on account of his laughing aloud",
            },
            {
              Kor: "살기 좋다는 바람에  ",
              Eng: "on account of people saying it was good to live there",
            },
            {
              Kor: "차장이 깨우는 바람에  ",
              Eng: "because the driver woke me up",
            },
            {
              Kor: "다른 회사와 합병되는 바람에  ",
              Eng: "as a consequence of the fact that [the pharmaceutical company] was merged with another [bigger] company ...",
            },
            {
              Kor: "중지되고 마는 바람에  ",
              Eng: "it ending up being suspended/called off",
            },
          ],
        },
        {
          entryName: "-는 바이다1",
          typicalExample: "【어느 정도 즐기는 바이지만  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "A fancy, more stiff and formal or bookish equivalent to -는 것이다 it is the case/fact that ...",
          sentences: [
            {
              Kor: "어느 정도 즐기는 바이지만  ",
              Eng: "enjoy it to some extent, but ...",
            },
            {
              Kor: "내가 항상 생각하는 바지만 ",
              Eng: "this is what I always think, but ...",
            },
          ],
        },
        {
          entryName: "-는 바이다2",
          typicalExample: "【알리는 바입니다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "A fancy, more stiff and formal or bookish equivalent to -는 것이다 it is the case/fact that ... Here this pattern indicates a solemn pronouncement.",
          sentences: [
            { Kor: "알리는 바입니다  ", Eng: "I am hereby informing you ..." },
          ],
        },
        {
          entryName: "-는 법이다",
          typicalExample: "【딸은 엄마를 닮는 법이다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "There is reason to expect; it is (or seems) reasonable that ...; it can be expected that; it ought (it is supposed) to be that ...; it is the usual turn of events that ...; it is nature's law that ...; the inevitable way of the world is that; it stands to reason that ..., the way (law) of the world that ...",
          sentences: [
            {
              Kor: "딸은 엄마를 닮는 법이다  ",
              Eng: "daughters always resemble their mothers",
            },
            {
              Kor: "여자의 질투를 위해선 휘어잡을 머리채가 마련돼 있어야 하는 법이다 . ",
              Eng: "There ought to be [a hank of hair for one to grab] if you are to suffer/cope a woman's jealousy.",
            },
            {
              Kor: "평생을 지속하는 법입니다  ",
              Eng: "It is usually the case that it continues throughout one's life.",
            },
            {
              Kor: "흉도 이쁜 법이었다  ",
              Eng: "It can be expected that her faults would be cute.",
            },
            {
              Kor: "가난해두 두려울 게 없는 법야  ",
              Eng: "Even if we're poor we ought not fear.",
            },
            {
              Kor: "달도 차면 기우는 법인데  ",
              Eng: "lit.: the full moon will always wane; i.e., all good things must come to an end",
            },
            {
              Kor: "저절로 잊게 되는 법이오  ",
              Eng: "Naturally, one tends to forget them [i.e., out of sight, out of mind].",
            },
            {
              Kor: "남 안 주는 법이야  ",
              Eng: "It ought not to be given to others.",
            },
            {
              Kor: "사람이 많아지면 하늘을 잊는 법이거든[Examples?] ",
              Eng: "When the number of people increases, people forget Heaven (Rounded off with the explanatory or follow-up ending in –거든[요].)",
            },
          ],
        },
        {
          entryName: "-는 법(이) 있다/없다",
          typicalExample: "【결혼해 버리는 법이 어디 있어 ? 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Here 법 works as a post-modifier meaning occasion, event (like 작, 수, 일), but with more a sense of regular or rule-governed/expected event. In this usage, 법 is invariably followed by 없다, and cases with 있다 tend to be rhetorical questions, implying a negative response. ",
          sentences: [
            {
              Kor: "결혼해 버리는 법이 어디 있어 ? ",
              Eng: "how is it possible to go ahead and get married ...",
            },
            {
              Kor: "이 남자는 그러는 법도 없었다  ",
              Eng: "(but) this man never did that; or, there were never occasions when he did this",
            },
            { Kor: "당황하는 법이 없었다 .  ", Eng: "he was never flustered" },
          ],
        },
        {
          entryName: "-는 사이에 ",
          typicalExample: "【바라보는 사이  구름의 분홍빛이 점차 바래지다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "While ...-ing; during the interval/time/space (사이) while one is ...-ing.  ",
          sentences: [
            {
              Kor: "바라보는 사이  구름의 분홍빛이 점차 바래지다  ",
              Eng: "while she was watching, the pink in the cloud gradually faded, until...",
            },
            {
              Kor: "자신들은 알지 못하는 사이에  ",
              Eng: "lit.: before they could realize it ...",
            },
          ],
        },
        {
          entryName: "-는 성싶다 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Seems/appears (to be the likely case that); I guess (that likely).",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는 셈으로",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Postmodifier 셈 followed by the particle 으로: with the calculation, conjecture, supposition; accounting (for).",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "- 는 셈이다",
          typicalExample: "【내게 연애편지를 쓰는 셈이지  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Processive modifier -는 + postmodifier 셈: the calculation, conjecture, supposition; accounting (for) from the verb 세- count. reckon. Other meanings are (judgement based on) appearances, to all appearances/indications, that one might call ... Literally, then: calculation or reckoning is that [somebody/something is doing something]. More colloquially, as good as does. . .  or for all practical purposes, does, etc. ",
          sentences: [
            {
              Kor: "내게 연애편지를 쓰는 셈이지  ",
              Eng: "it's as if [Pak] is writing me a love letter ...",
            },
            {
              Kor: "8천4백원이 되는 셈이다 ",
              Eng: "[His monthly] income comes to 8,400 wŏn.",
            },
            {
              Kor: "환상적 절간 구색은 모두 갖추고 있는 셈이었다  ",
              Eng: "It seemed like the dreamy temple had it all.",
            },
          ],
        },
        {
          entryName: "-는 수(가) 있-/없- ",
          typicalExample: "【제 주둥이로 물어 내버리는 수가 있다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "An occasion/time/event/case/instance/happening/circumstance; does sometime / occasionally / on occasion happen. Or, there are (even/also) times/occasions when ... ",
          sentences: [
            {
              Kor: "제 주둥이로 물어 내버리는 수가 있다  ",
              Eng: "There are occasions when they pick up their young in their beaks and throw them out.",
            },
          ],
        },
        {
          entryName: "-는 수밖에 없다 ",
          typicalExample: "【수로로 다니는 수밖에 없었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Have no choice but to do (be doing).",
          sentences: [
            {
              Kor: "수로로 다니는 수밖에 없었다  ",
              Eng: "have no choice but to go by way of the sea",
            },
            {
              Kor: "섰는 수밖에 없었다  ",
              Eng: "[He] had no choice but to stand there ...(for 서 있는 . . . )",
            },
          ],
        },
        {
          entryName: "-는 순간",
          typicalExample: "【누운 자리가 눅눅하다는 것을 깨닫는 순간   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Just at the moment/instant one does it; as soon as ...; no sooner had one done it, than ... ; just when/as ... ",
          sentences: [
            {
              Kor: "누운 자리가 눅눅하다는 것을 깨닫는 순간   ",
              Eng: "the instant she realized that the bedding she was lying on was wet",
            },
            {
              Kor: "행기가 흰 선을 타고 날아가는 것을 보는 순간  ",
              Eng: "the moment she saw the airplane flying along the white line",
            },
            {
              Kor: "남자의 눈길이 닿는 순간   ",
              Eng: "the moment the man's gaze touched/reached …",
            },
            {
              Kor: "아이의 울음소리를 듣는 순간   ",
              Eng: "the moment she heard the baby's cry",
            },
            {
              Kor: "눈에 살기가 번뜩이는가 하는 순간  ",
              Eng: "Just [at the instant] when it appeared that violence was in his eyes ...",
            },
            { Kor: "고개를 드는 순간  ", Eng: "the instant he looked up " },
            {
              Kor: "그의 반가운 기색을 보는 순간  ",
              Eng: "the moment I saw his welcoming countenance ",
            },
          ],
        },
        {
          entryName: "-는 양(으로)",
          typicalExample: "【재미있는 양  자꾸 물을  움킨다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-는 양: post-modifier 양 (as abbreviation of 모양 appearance, shape, form; signs, indications; state of affairs) meaning as if to, in a manner suggestive of ... ",
          sentences: [
            {
              Kor: "재미있는 양  자꾸 물을  움킨다. ",
              Eng: "[Looking] as if it were fun/As if she were having a grand old time, she kept trying to cup water with her hands.",
            },
            {
              Kor: "옛 노마를 부리는 양 ",
              Eng: "serving just like the old Noma ...",
            },
            {
              Kor: "손가락 사이로 빠져나가는 모래들, 그 흔적을 잡아두려는 양 ",
              Eng: "The fine-grained sand that escaped between her fingers, and as if to catch/arrest their traces...",
            },
          ],
        },
        {
          entryName: "-는 외에 ",
          typicalExample: "【이 나이가 시키는 외에  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Apart from ...-ing; aside from ...-ing; besides ...-ing; outside of (except for) ...-ing. ",
          sentences: [
            {
              Kor: "이 나이가 시키는 외에  ",
              Eng: "besides all the things that this age brought on ...",
            },
            {
              Kor: "예배당에 갔다오는 외에 는 ",
              Eng: "outside of going to and from the worship hall ...",
            },
            {
              Kor: "그는 윤호가 물어보는 이외의  것을 가르치기 위해  ",
              Eng: "so as to teach Yunho things other than what he asked him",
            },
          ],
        },
        {
          entryName: "-는 이만 못하다",
          typicalExample: "【늙어서 고생하긴 젊어서 죽는 이만 못하당이  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN은 . . . VERB-는 이만 못하다: NOUN is worse than VERBing.",
          sentences: [
            {
              Kor: "늙어서 고생하긴 젊어서 죽는 이만 못하당이  ",
              Eng: "it is worse to be old and suffering than to die young",
            },
          ],
        },
        {
          entryName: "-는 일이 있다 ",
          typicalExample: "【물동이에 떨어지는 일이 있었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "It sometimes happens that ...",
          sentences: [
            {
              Kor: "물동이에 떨어지는 일이 있었다  ",
              Eng: "There were times when crane droppings happened to fall into the [girls'] water pots.",
            },
          ],
        },
        {
          entryName: "-는 적도 있다 ",
          typicalExample: "【1주일 내내 한 번도 못 만나는 적도 있다 . 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "There are also times when ...",
          sentences: [
            {
              Kor: "1주일 내내 한 번도 못 만나는 적도 있다 . ",
              Eng: "There are times when we can't meet for an entire week.",
            },
          ],
        },
        {
          entryName: "-는 정도(가) 아니다",
          typicalExample: "【모자라는 정도가 아니라  병아리 눈물이었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier -는 + 정도(가) 아니다 is a (sometimes ironic) way of saying it isn't just ... it's even more the case. E.g. a) 저 여자는 좀 까다롭지요? She's quite persnickety, isn't she?; b) 까다로운 정도가 아니에요 Persnickety isn't the half of it",
          sentences: [
            {
              Kor: "모자라는 정도가 아니라  병아리 눈물이었다 ",
              Eng: "the amount of rice wasn't just insufficient, it was pathetic ('mere chick tears')",
            },
          ],
        },
        {
          entryName: "-는 정도(이)다 ",
          typicalExample:
            "【남편은 옷을 갈아 입으러 집에 들르고 하는 정도였다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Is [only] to the extent that ...; is to the point that ...",
          sentences: [
            {
              Kor: "남편은 옷을 갈아 입으러 집에 들르고 하는 정도였다  ",
              Eng: "Things were at the point where he would [only] drop by to change his clothes.",
            },
            {
              Kor: "주지승밖에 없는 정도여서  ",
              Eng: "… was nothing more than the head monk of the temple, and so …",
            },
          ],
        },
        {
          entryName: "-는 줄도 모르다",
          typicalExample: "【갓이 뒤로 벗겨지는 줄도 모르고  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Not even to know/ to be unaware even that ...",
          sentences: [
            {
              Kor: "갓이 뒤로 벗겨지는 줄도 모르고  ",
              Eng: "unaware of the fact that their kat (horsehair hats) were coming off ...",
            },
          ],
        },
        {
          entryName: "- 는 줄(을) 모른다",
          typicalExample: "【가는 줄 모르게  십리씩은 가곤 하였다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Not to know that / be unaware that it is ...",
          sentences: [
            {
              Kor: "가는 줄 모르게  십리씩은 가곤 하였다. ",
              Eng: "In such a way that he was unaware how far he had gone, he would travel at least ten li or so.",
            },
          ],
        },
        {
          entryName: "-는 줄(로) 안다",
          typicalExample: "【밤 열두시 이후에만 우는 줄로 알고  있었는데요 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "To know that, to think that, to be under the impression that; to assume (often erroneously) that . . . ",
          sentences: [
            {
              Kor: "밤 열두시 이후에만 우는 줄로 알고  있었는데요 ",
              Eng: "I was under the impression that they only cry after midnight ...",
            },
            {
              Kor: "처음엔 아무도 없는 줄 알았는데  ",
              Eng: "At first, I thought there was no one there, but ...",
            },
          ],
        },
        {
          entryName: "-는 줄만 알다",
          typicalExample:
            "【윤호의 학력이 눈에 띄게 늘어가는 줄만 알았다 .  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-는 줄만 아-ㄹ-: think or assume (often erroneously) that... reinforced with particle 만 only: think or assume only that ...",
          sentences: [
            {
              Kor: "윤호의 학력이 눈에 띄게 늘어가는 줄만 알았다 .  ",
              Eng: "He simply assumed that Yunho's studies were improving noticeably. ",
            },
          ],
        },
        {
          entryName: "-는 줄 짐작하다",
          typicalExample: "【왜 다행이라고 말씀하시는 줄 짐작하겠어요  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "To guess, conjecture, estimate.",
          sentences: [
            {
              Kor: "왜 다행이라고 말씀하시는 줄 짐작하겠어요  ",
              Eng: "Let me guess why it is that you said it was a lucky thing.",
            },
          ],
        },
        {
          entryName: "-는 중(에)",
          typicalExample: "【모진 목숨을 이어가는 중 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Modifier + postmodifier 중 during, while, in, within.",
          sentences: [
            {
              Kor: "모진 목숨을 이어가는 중 ",
              Eng: "in the middle of eking out their miserable existence",
            },
          ],
        },
        {
          entryName: "-는 중에도 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "In spite of being in the middle of all this ... ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는지",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Oblique/indirect question in -는지. Modifier [-(으)ㄴ, -던, -었던, -었는, -는] + postmodifier 지 the uncertain fact whether; perhaps; because. When left hanging in mid-sentence, you can interpret the pattern as having left out a following (도) 모르지만 it may have been the case that or it is uncertain WH... [but...]. With an accompanying question word, the pattern is best understood as an ellipsis for one doesn't know what/why/when/where, etc., but ...",
          sentences: [
            { Kor: "[Examples?]", Eng: "" },
            {
              Kor: "바람에 흔들리는지  ",
              Eng: "perhaps because they were being shaken by the wind",
            },
            {
              Kor: "식탁 불의 불빛이 눈을 찌르는지  한 쪽 소매로 눈을 가리는데  ",
              Eng: "perhaps because the light over the dining table was blinding her, she covered her eyes with one sleeve",
            },
            {
              Kor: "어떻게나 쫓아다니는지  ",
              Eng: "and how she's following me around ",
            },
            {
              Kor: "무슨 일을 하고 있는지  모르는 채  ",
              Eng: "clueless as to what she was doing ... (Here Q-word  + -는지 (몰라) it's so ... you have no idea.)",
            },
          ],
        },
        {
          entryName: "-는 지(가)",
          typicalExample: "【이런 질문을 자신에게 던지는 지가  이미 오래였다】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Oblique question in -는지 the time elapsed since one is doing something. The pattern is stylistically somewhat odd, as we are used to seeing the 'time since' pattern on just the past modifier, i.e., -(으)ㄴ지 . Use of the processive modifier here implies that the questioning is an on-going process.",
          sentences: [
            {
              Kor: "이런 질문을 자신에게 던지는 지가  이미 오래였다",
              Eng: "it had already been a long time since he was putting questions like this to himself",
            },
            {
              Kor: "학이 안 오는 지가  벌써 삼십년이 넘어 ",
              Eng: "it has already been more than thirty years since the cranes have stopped coming",
            },
          ],
        },
        {
          entryName: "-는지 구별(이) 안 가다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Can't tell whether ...",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는지도 모르다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "(The uncertain fact) whether it will be or do; doesn't know whether perhaps.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는지도 모른다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Perhaps it is the case that it does/did. ",
          sentences: [
            { Kor: "[Examples?]", Eng: "" },
            {
              Kor: "나는 뭘 잘못했는지도 모르는  채 아빠에게 빈다.  ",
              Eng: "I plead for forgiveness to daddy, still ignorant as to what it is I have done wrong.",
            },
          ],
        },
        {
          entryName: "-는지 몰라도",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Even though one doesn't know ['the uncertain fact'] whether, what, how, why, etc. Usually in a bigger pattern of the type: I don't know (or nobody knows) A, but B ...",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는지라(서)",
          typicalExample: "【노마 때에 겪음이 있는지라  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "As/since it does; it is a fact that.., grounds for, reason for (equivalent to colloquial -드니, according to Roth). Usually for a ceremonious/solemn pronouncement.  ",
          sentences: [
            {
              Kor: "노마 때에 겪음이 있는지라  ",
              Eng: " He sure didn't forget about what happened with Noma, ...",
            },
            {
              Kor: "산중서 뼈골이 굳었는지라  보름 정도는 먹지 않고 달려도 끄떡이 없지요. ",
              Eng: "Since my bones have hardened, I can run for a good two weeks without any food and I'll be fine.",
            },
          ],
        },
        {
          entryName: "-는지(요)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation: "Used as a polite indirect question.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는 참이다",
          typicalExample: "【그리로 가고 있는 참이었었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "At the point of doing, just as it is happening; is just (as coincidence would have it) in the process of doing/happening",
          sentences: [
            {
              Kor: "그리로 가고 있는 참이었었다  ",
              Eng: "was just going there now",
            },
            {
              Kor: "나가는 참이었다 ",
              Eng: "was just in the process of [paying and] leaving",
            },
            {
              Kor: "꿈같이 반기는 참이었다  ",
              Eng: "she was just at the point of being glad, just like a dream ... ",
            },
            {
              Kor: "올려다보는 참에  ",
              Eng: "just at the point of rising up to look ...",
            },
          ],
        },
        {
          entryName: "-는 채(로)",
          typicalExample: "【여전히 마을을 내려다보는 채였다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier -는 + postmodifier 채: just as it is or did, (in) the original state of ...",
          sentences: [
            {
              Kor: "여전히 마을을 내려다보는 채였다  ",
              Eng: "[Park] was looking down on the village as before.",
            },
          ],
        },
        {
          entryName: "-는 척한다 ",
          typicalExample: "【등을 긁어 주는 척 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier + 척하-: pretend to (do, have done, be); act as if ...",
          sentences: [
            {
              Kor: "등을 긁어 주는 척 ",
              Eng: "pretending to scratch its back",
            },
          ],
        },
        {
          entryName: "-는 체한다",
          typicalExample: "【못 이기는 체하고  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Same as -는 척 한다: pretend to (do, have done, be).",
          sentences: [
            {
              Kor: "못 이기는 체하고  ",
              Eng: "pretending to not prevail and ...",
            },
            {
              Kor: "지갑을 꺼내는 체하면서  ",
              Eng: "while pretending to take out his wallet ...",
            },
            {
              Kor: "못이기는 체 ",
              Eng: "pretending to not be able to prevail ...",
            },
            {
              Kor: "닦아놓은 부츠를 집어드는 체하며  ",
              Eng: "pretending to pick up the polished boots ...",
            },
            {
              Kor: "으례 안영감을 아는 체하였다  ",
              Eng: "would always pretend to know him",
            },
            {
              Kor: "잘 아는 체한다는  것이 ",
              Eng: "pretending to know someone well ...",
            },
            {
              Kor: "몇 숟가락 뜨는 체하고 ",
              Eng: "pretends to scoop several spoonfuls and...",
            },
            {
              Kor: "쇠파리라도 잡는 체  ",
              Eng: "(pretending) as if he were trying to kill a fly ",
            },
          ],
        },
        {
          entryName: "-는 탓으로",
          typicalExample: "【한 이웃에 사는 탓으로 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Postmodifier 탓 reason, ground(s) + 으로: on account of, because of.",
          sentences: [
            {
              Kor: "한 이웃에 사는 탓으로 ",
              Eng: "as a result of/on account of living in the same neighbourhood",
            },
          ],
        },
        {
          entryName: "-는 터이다",
          typicalExample: "【못하고 있는 터였다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "터 functions as a fancy equivalent of 바 which is itself a somewhat stiff and literary equivalent of 것 thing; fact; case. Thus, this pattern is equivalent to (but stiffer than) the patterns in -는 바이다 and -는 것이다: it is the case that  ... does/is doing.",
          sentences: [
            {
              Kor: "못하고 있는 터였다  ",
              Eng: "the thing was that he hadn't been able to [give a free shoe-shine even once.]",
            },
            {
              Kor: "이름자는 적는 터이며  ",
              Eng: "it was the case that he could write his name and...",
            },
            {
              Kor: "옛날부터 전해 내려오는 터이다  ",
              Eng: "[superstitions] that have come down to us from the earliest times",
            },
          ],
        },
        {
          entryName: "-는 판에 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "[Usage]",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는 판이다",
          typicalExample: "【인정이 휙 변해가는 판인데  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "In the (somewhat lamentable or regrettable) situation where/that ...",
          sentences: [
            {
              Kor: "인정이 휙 변해가는 판인데  ",
              Eng: "Nowadays when people's sentiments are changing so fast ...",
            },
          ],
        },
        {
          entryName: "-는 편이다 ",
          typicalExample:
            "【그녀는 아이를 키우고 집안일을 하는 데 소질이 있는 편이었다 .  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB-는 편이다: is on the VERB-ing side; is inclined to VERB.",
          sentences: [
            {
              Kor: "그녀는 아이를 키우고 집안일을 하는 데 소질이 있는 편이었다 .  ",
              Eng: "She has a knack for raising the kids and managing the house.",
            },
          ],
        },
        {
          entryName: "-는 폼 ",
          typicalExample: "【언제나 시퍼렇게 살아올라오는 폼 으로 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "As a noun, 폼 means form, carriage, way of holding oneself. As postmodifier the meaning is similar, but has also the meaning of style with an ironic or humorous twist (cf. 폼을 잡다: put on airs, assume a somewhat contrived or pompous air).",
          sentences: [
            {
              Kor: "언제나 시퍼렇게 살아올라오는 폼 으로 ",
              Eng: "[the kimch'i] always looked like it was ready to come to life and crawl out of the bowl ...",
            },
            {
              Kor: "여간 반가와하는 품 이 아니었다 ",
              Eng: "he was very happy to see me",
            },
          ],
        },
        {
          entryName: "~는 하-",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'VN는 하-: particles 은, 도, and 만 can sometimes intervene between an "inseparable" verbal noun ("VN") and its following dummy verb 하다. Normally, descriptive verbs made up of a descriptive verbal noun + 하다 are inseparable, but the verbal noun can be separated from 하다 by 은, 도, or 만 for stylistic effect.',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-는 한",
          typicalExample: "【할 수 있는 한  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "So long as; so far as; even if.",
          sentences: [
            { Kor: "할 수 있는 한  ", Eng: "insofar as possible ..." },
            {
              Kor: "그가 가게에 나타나지 않는 한  ",
              Eng: "as long as he didn't show up at the store",
            },
            {
              Kor: "인숙이가 믿어 주는 한  ",
              Eng: "as long as you (Insuk) trust me ...",
            },
            { Kor: "될 수 있는 한  ", Eng: "to the extent possible" },
          ],
        },
        {
          entryName: "-는 한에서는",
          typicalExample: "【그들이 아는 한에서는  일찌기 없었던 일이었다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "So long as; so far as; to the limit that; within the limits of ...-ing; (negative +) unless.",
          sentences: [
            {
              Kor: "그들이 아는 한에서는  일찌기 없었던 일이었다",
              Eng: "As far as they knew, this had never happened before.",
            },
          ],
        },
        {
          entryName: "-니 ...-니 한다",
          typicalExample: "【도꾜가 어떠니 , 오사까가 어떠니 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Tedious lists in -(이)니 A (이)니 B (이)니 하다 or A-느니 B-느니 하다: To carry on/worry about [the question of] A vs. B/ A or B. Martin treats this as a variant of question -냐. Note also -는 따위 saying ...and the likes.",
          sentences: [
            {
              Kor: "도꾜가 어떠니 , 오사까가 어떠니 ",
              Eng: "… carried on about how Tokyo was so-and-so, and Osaka was thus-and-such …",
            },
          ],
        },
        {
          entryName: "-니(까)",
          typicalExample: "【자고 일어나 보니  집에는 아무도 없었다. 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Sequential -(으)니(까): since it is or does; when (in the past) then [one finds].",
          sentences: [
            {
              Kor: "자고 일어나 보니  집에는 아무도 없었다. ",
              Eng: "I woke up from my sleep to find nobody at home.",
            },
            {
              Kor: "애인이니  조금의 쓸쓸함만을 남길 따름이다. ",
              Eng: "Since he's my boyfriend, it only leaves a bit of sadness.",
            },
            {
              Kor: "얼굴을 가까이 대보니  ",
              Eng: "when I look closely at her face",
            },
            {
              Kor: "하기는 지금 잠들어 있는 얼굴을 보니   ",
              Eng: "still, when I look at her sleeping face now",
            },
          ],
        },
        {
          entryName: "-니(까) 원",
          typicalExample: "【당신이 히스테리 부릴 때가 다 있으니 원  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Mild exclamation.",
          sentences: [
            {
              Kor: "당신이 히스테리 부릴 때가 다 있으니 원  ",
              Eng: "Well geez, you sure do have your bouts of hysteria so ... (go ahead).",
            },
          ],
        },
        {
          entryName: "-다",
          typicalExample:
            "【산골짜기에서 뱅뱅 돌다 결국 불벼락을 맞은 것이다 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Transferentive: -다 abbreviated from -다가 does/did (is/was) and then;  does/did (is/was) but;  when. Shows a change or shift in action or state. ",
          sentences: [
            {
              Kor: "산골짜기에서 뱅뱅 돌다 결국 불벼락을 맞은 것이다 ",
              Eng: "going round and round the mountain, it had finally been struck by a fire bolt",
            },
            {
              Kor: " 그때 소파를 옮기다 허리가 비끗했다.  ",
              Eng: "I threw out my back then trying to move the sofa.",
            },
            {
              Kor: "딴 세상 사람처럼 바라보다 떠났다. ",
              Eng: "stared at her for a while as though she was a person from another world and then left",
            },
            {
              Kor: "온갖 주접을 다 떨다 미신을 하나 만들어 냈는데, 글쎄 그게......",
              Eng: "after thinking of every crazy possibility, they made up this superstition, and it's...",
            },
            {
              Kor: "이름없이 떠돌다 간 기인(奇人) ",
              Eng: "the wandering eccentricss, nameless ...",
            },
            {
              Kor: "사람을 치다 붙잡혀",
              Eng: "would hit (attack) people and be caught ...",
            },
            {
              Kor: "담배 깜부기를 피우다 두 번이나 들키었다",
              Eng: "got caught a time or two smoking cigarette butts",
            },
            {
              Kor: "춤을 추다 웃음판에 그대로 나가넘어진 일도 있었다. ",
              Eng: "He danced, suddenly bursting in laughter, and then fell onto the floor. ",
            },
            {
              Kor: "또한번 천천히 수염을 내리쓸다 그 끝을 쥐고 내려다 보며 중얼거렸다.",
              Eng: "Once again [he] slowly stroked his beard, grabbed the ends and mumbled while looking down at it.",
            },
            {
              Kor: "젤 큰  피해는 부두 노동을 하다 궤짝에 치여 죽은 덕이 아버지였다.",
              Eng: "The biggest casualty was Dŏk's father, who, while working on the docks, was hit by a (wooden) box and died.",
            },
            {
              Kor: "어린애 둘은 두부찌게만 먹다 죽었다",
              Eng: "the two children died, having eaten nothing but tofu stew",
            },
            {
              Kor: "화투패를 떼다 = 떼다가",
              Eng: "if, when he cuts a deck of Korean hwat'u cards and [the card does not fall his way; or does not come up a lucky one]",
            },
            {
              Kor: "이불 속에서 잘 따져보다 그 옥토끼가 나에게 참으로 고마운 동물임을 비로서 깨달았다.",
              Eng: "I thought it over under the covers and finally realized how gratifying of an animal the Ottokki really is to me.",
            },
            {
              Kor: "나무하러 왔다 낮잠을 잔 적도 여러 번이었다",
              Eng: "There were also many occasions when, after setting off on his way to collect firewood, he took a nap.",
            },
            {
              Kor: '"그러다 바가지 써요.” ',
              Eng: '"Do that, and you\'ll get ripped off."',
            },
            {
              Kor: "“이러다 큰일낸다구.” ",
              Eng: '"Keep this up, and you\'ll really do something stupid."',
            },
          ],
        },
        {
          entryName: "-다 ... -다",
          typicalExample: "【조르다, 조르다 성이 나가지고는 이를 북북 갈면서】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "VERB1 -다(가) VERB2 -다(가):  alternate between the two;  keep on ...-ing (in an annoying way).",
          sentences: [
            {
              Kor: "조르다, 조르다 성이 나가지고는 이를 북북 갈면서",
              Eng: "pestered and pestered and now frustrated, grinding his teeth together...",
            },
          ],
        },
        {
          entryName: "-다가",
          typicalExample: "【며칠 동안 계시다가 오세요】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            " Does/did (is/was) and then;  does/did (is/was) but;  when. Shows a change or shift in action or state or emphasizes a shift of place, purpose, direction, benefit, etc., in the action occurring.",
          sentences: [
            {
              Kor: "며칠 동안 계시다가 오세요",
              Eng: "stay in Mujin for several days and then return",
            },
            {
              Kor: "여름 장마 때나 돼야 물이 콸콸 흐르다가 마는 이름만 개울이지",
              Eng: "It's a stream alright, only in names though, since there's lots of water flowing only during the rainy season in the summer.",
            },
            {
              Kor: "전화를 받다가 문득 이런 생각을 했다 ",
              Eng: "As I answered the phone these thoughts suddenly popped into my head.",
            },
            {
              Kor: "나는 몸을 떨다가 기진했다 ",
              Eng: "I was shaking until I was exhausted.",
            },
            {
              Kor: "나는 자꾸 움츠러들다가 마치 날개 뜯긴 날벌레처럼 몸을 바르르 떨었다.",
              Eng: "I was constantly flinching and trembled like an insect who had his wings torn away. ",
            },
            {
              Kor: "어느 순간은 종이 한장 차이도 나는 것 같지 않다가 검지만 않아도 되는 머리털에서 그들이 선남선녀로 느껴지는 순간이었다. ",
              Eng: "… seemed [at one moment] like there was not so much as a single page's difference [them and me], but then [the next moment]they seemed like angels and fairies simply from the fact that their hair did not have to be black [like mine].",
            },
            {
              Kor: "관계를 맺다가 돌아오곤 했다 ",
              Eng: "I would secure the relationship and then come home.",
            },
            {
              Kor: "무명화가를 불쌍해하다가 ",
              Eng: "At first, I took pity on this struggling (lit.: unknown) artist, but with time ...",
            },
            {
              Kor: "읽다가 접어둔 책",
              Eng: "a book that I had been reading for a while and then put down ",
            },
            {
              Kor: "망설이다가 수화기를 들었다.  ",
              Eng: "After hesitating a moment, he picked up the receiver.",
            },
            {
              Kor: "떠내려가다 막힌 녹두 껍질 = 떠내려가다가 막힌 녹두 껍질 ",
              Eng: "pea pods which had gotten stuck while being washed down the drainage",
            },
            {
              Kor: "그 여교사가 시내에 나왔다가 나에게 연락을 해서 군청 휴게실에서 만났다. ",
              Eng: "아버지 11",
            },
          ],
        },
        {
          entryName: "-다가 ... -다가",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "VERB1 -다(가) VERB2 -다(가):  alternate between the two;  keep on ...-ing (in an annoying way).",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-다가는1",
          typicalExample: "【웃음을 손님들 앞에서 뿌리다가는 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Transferentive –다가는:  does/did and THEN;  does/did BUT. Like in -고는, the presence of 는 reinforces the  and THEN reading. ",
          sentences: [
            {
              Kor: "웃음을 손님들 앞에서 뿌리다가는 ",
              Eng: "[Madam Kang] would sprinkle smiles at her customers, and then ...",
            },
            {
              Kor: "담싹 집어다가는 저도 역시 입을 맞추고 뺨을 대보고 하는 것이 아닌가. ",
              Eng: "Sweeping it up into her arms and (to my surprise), she also proceeded to give it a kiss and rubbed her cheek against and such.",
            },
            {
              Kor: "눈발을 바라보다가는 요란하게 울리는 퇴근벨 소리에 밀리긋 화장실을 나왔다. ",
              Eng: "I watched the snowflakes and then left the restroom almost as if being pushed out by the noisy ringing bell that signaled the end of the workday...",
            },
            {
              Kor: "한참씩 학나무를 내려다 보다가는 드디어 산을 넘어 어드론지 떠나가곤 하는 것이었다. ",
              Eng: "[They] each looked down at the crane tree for a while, and then finally flew over the mountain and away to an unknown somewhere ...",
            },
            {
              Kor: "물오리떼가 내려 앉았다가는 날아오르곤 했다 ",
              Eng: "a flock of ducks would land [on the field] and then take off again ...",
            },
            {
              Kor: "튀어 올라갔다가는 떨어져 내렸다 ",
              Eng: "sprung upwards and then dropped downwards",
            },
          ],
        },
        {
          entryName: "-다가는2",
          typicalExample: "【이러다가는 아이들이 깰 것만 같다.  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Warning pattern:  if one keeps on doing then (unfortunately) ...",
          sentences: [
            {
              Kor: "이러다가는 아이들이 깰 것만 같다.  ",
              Eng: "if he carries on like that, the kids are bound to wake up",
            },
            {
              Kor: "멋모르고 설치다가는 재수 없게 엉덩이를 채거나 머리를 쥐어 박히기가 삽상이었다.",
              Eng: "[If one] acted carelessly and just barged in, it would not be surprising to be receiving an unfortunate kick in the butt or a little smack on the head. ",
            },
            {
              Kor: "눈치 없이 덤비다가는 재수 옴 붙는다.",
              Eng: "If you were to act hastily without being sensitive to your surroundings, you would be getting a hell of a lot of bad luck. ",
            },
            {
              Kor: "호두송이를 맨손으로 깠다가는 ",
              Eng: "[warnings to the effect that] if you peel walnut burrs with your bare hands [you'll get a rash]",
            },
          ],
        },
        {
          entryName: "-다가도",
          typicalExample:
            "【장전을 내었다가도 그만 화재를 보았거니 하는 것이다.】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Transferentive -다(가) indicating a change or shift of action or state plus particle 도  even, i.e.,  even in the middle of ...-ing.",
          sentences: [
            {
              Kor: "장전을 내었다가도 그만 화재를 보았거니 하는 것이다.",
              Eng: "And then even after running a furniture store for a while, [something else happened, and] the store would get engulfed in fire/was set ablaze and such.",
            },
            {
              Kor: "그의 집으로 향하여 시적시적 가다가도 그때의 그 폐부를 찌르고 창자를 에는 듯한 통곡 소리를 연상하고는 다시 발길을 돌리고 하였다.",
              Eng: "Likewise, I was shuffling along [towards his house], when I would relive the wailing—that almost gut-wrenching wailing seemed to stab one's heart—and decide to turn back. ",
            },
            {
              Kor: "한참 바라보고 있다가도 고개를 쳐드니까 하늘에서도 불이 일고 있는 듯 ",
              Eng: "even after staring for a while, when I raised my head high, it seemed as if the sky was set on fire ...",
            },
            {
              Kor: "저 양반 잠자다가도 호랑이 생각만 나면 벌떡벌떡 몸을 일으키지요. ",
              Eng: "That gentleman, even when asleep, would jump up instantly at the thought of the tiger. ",
            },
            {
              Kor: "욕을 하다가도 마수거리만 아니면 우동 그릇 인절밋 개를 김칫국해서 먹이곤 했다.",
              Eng: "Even after heaping obscenities on him, if it was not the first sale of the day, I would always feed him a bowl of udon or kimch’i soup with slices of rice cake or something",
            },
            {
              Kor: "멀리서 보면 아무 뜻이 없다가도 일단 그 속에 들어가면 ",
              Eng: "Even though the mountain looked bland from afar, once you were inside it...",
            },
            {
              Kor: "하루종일 한가하다가도 바로 그때가 쾨근길의 손님이 밀리는 때이므로 ",
              Eng: "Because even on days when things would be quiet all day, that time of the day was when the customers would come crowding into the store on their way home from work …  ",
            },
            {
              Kor: "물동이를 들고 싸립문을 나오다가도 덕이를 보면 홱 돌아 들어가곤 하였다.",
              Eng: "Even when she would come out of the brushwood gate carrying a water jug, upon spotting Dŏki, she would turn around coldly and go back inside.",
            },
          ],
        },
        {
          entryName: "-다가 만다 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Base + transferentive -다(가) (with 가 optionally deleted) followed by 마-ㄹ-:  cease, desist;  does (a while) then stops;  stops/hesitates in the middle of doing, meaning to stop in mid-action, or drop what one is doing in favor of a new action.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-다간1",
          typicalExample:
            "【관광호텔 앞까지 갔다간 돌아서서 허름한 2류 여관을 찾고 】",
          categoryKorean: "연결어미 Connective ending/준말 Abbreviation",
          categoryEnglish: "(연결어미 Connective ending/준말 Abbreviation)",
          translation:
            "Abbreviated from Transferentive –다가는:  does/did and THEN;  does/did BUT. Like in -고는, the presence of 는 reinforces the  and THEN reading. ",
          sentences: [
            {
              Kor: "관광호텔 앞까지 갔다간 돌아서서 허름한 2류 여관을 찾고 ",
              Eng: "I went all the way up to a tourist hotel, but then turned around and found a second-rate motel...",
            },
            {
              Kor: "조심조심 고개를 내밀었다간 재빨리 움츠러들었다. ",
              Eng: "They stuck their heads out carefully but then recoiled back in a flash. ",
            },
            {
              Kor: "가 놀다간 삐라도 뿌려 주고 ",
              Eng: "… would go and mess around and then distribute some posters for him...",
            },
          ],
        },
        {
          entryName: "-다간2",
          typicalExample: "【아침부터 주색잡다간 저녁에 마을서 장사지내게? 】",
          categoryKorean: "연결어미 Connective ending/준말 Abbreviation",
          categoryEnglish: "(연결어미 Connective ending/준말 Abbreviation)",
          translation:
            "Abbreviated from the warning pattern in -다가는:  if one keeps on doing then (unfortunately) ...",
          sentences: [
            {
              Kor: "아침부터 주색잡다간 저녁에 마을서 장사지내게? ",
              Eng: "If we start indulging in booze and women first thing in the morning, then you will be watching your funeral by the evening in this town.",
            },
          ],
        },
        {
          entryName: "-다거나",
          typicalExample:
            "【서울을 떠나보고 싶다거나 남편 곁을 떠나 보고 싶다거나 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "서울을 떠나보고 싶다거나 남편 곁을 떠나 보고 싶다거나 ",
              Eng: "it wasn't so much that I wanted to leave Seoul or that I wanted to pull away from my husband, but rather ...",
            },
          ],
        },
        {
          entryName: "-다고1 ",
          typicalExample:
            "【몇 푼 떨어지겠다고 저렇게 비굴하게 구나 싶었다. 】",
          categoryKorean: "Quotative",
          categoryEnglish: "(Quotative)",
          translation:
            "Quotative -다고 used to quote a thought:  think/hear/claim that ...",
          sentences: [
            {
              Kor: "몇 푼 떨어지겠다고 저렇게 비굴하게 구나 싶었다. ",
              Eng: "How much profit would she be making that she would be so subservient? ",
            },
            {
              Kor: "점심 굶기를 잘했다고 생각하는 길수의 전신에선 불끈불끈 힘이 솟았다. ",
              Eng: '[Thinking] "Good thing I skipped lunch", Kilsu\'s entire body boiled with strength.',
            },
          ],
        },
        {
          entryName: "-다고2",
          typicalExample:
            '【 “우리 나라 문화 수준이 낮다고 좀 높여 보자고요." 】',
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation: 'Lit.:  … saying, "Quote." Used to give a reason.',
          sentences: [
            {
              Kor: ' “우리 나라 문화 수준이 낮다고 좀 높여 보자고요." ',
              Eng: '"So as to raise the low standard of our national culture."',
            },
            {
              Kor: '"싫다고 그렇게 쉽게 떠나 버리면 ... " ',
              Eng: '"If you just up and leave so easily like just because you don\'t like it ... "',
            },
            {
              Kor: '"걔 딸이 학교 안 간다고 울고 난리래." ',
              Eng: '"Apparently his daughter is making a big fuss and refusing to go to school."',
            },
            {
              Kor: "잔돈이 없다고 맘대로 10원을 떼먹을 작정인 것이다. ",
              Eng: "Claiming that he had no change, the man was determined to get away without paying the 10 wŏn...",
            },
          ],
        },
        {
          entryName: "-다(고) 그런다",
          typicalExample: '【"없다고 그러세요." 】',
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            " Say that ... (for indirect quotations, with 그러- as the quoting verb).",
          sentences: [
            { Kor: '"없다고 그러세요." ', Eng: "“Tell them I'm not here.”" },
          ],
        },
        {
          entryName: "-다고 본다",
          typicalExample: "【그런 게 있었다고 봐야겠지요】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Indirect quote in -다고 보- where 보- means  consider, view;  take as. ",
          sentences: [
            {
              Kor: "그런 게 있었다고 봐야겠지요",
              Eng: "Guess we'll have to assume that there was such a thing.",
            },
            {
              Kor: "무엇 때문에 그들이 과객들에게 베풀었다고 보십니까? ",
              Eng: "What do you think is the reason that they are charitable towards the wayfarers?",
            },
          ],
        },
        {
          entryName: "-다고 치자",
          typicalExample:
            "【설령 아내가 진짜로 다른 남자와 새 연애를 하고 싶어한다고 치자.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "(설령) ...-다고 치자  even (설령) supposing (치-) that QUOTE;  let's suppose for a moment that QUOTE—even so, ... . The 설령 is optional, but is useful for flagging an upcoming concessive ( even though), especially when it is far away, as in this following example.",
          sentences: [
            {
              Kor: "설령 아내가 진짜로 다른 남자와 새 연애를 하고 싶어한다고 치자.  ",
              Eng: "… even supposing that my wife really wants to have an affair with another man.",
            },
          ],
        },
        {
          entryName: "-다고 (해서)",
          typicalExample: "【그렇다고 한밤중에 청소하는 것도  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Having said that ... ; on account of saying QUOTE,  hence,  just because ... [doesn't mean that ...];  just because [of the alleged reason in the [QUOTE] ... (followed by some sort of rebuttal).",
          sentences: [
            {
              Kor: "그렇다고 한밤중에 청소하는 것도  ",
              Eng: "Having said that, cleaning in the middle of the night, too …",
            },
            {
              Kor: "그렇다고 사랑하는 것 같지도 않았다.  ",
              Eng: "Having said that, it didn't even seem as though they loved me.",
            },
            {
              Kor: "그러니까 그때 영어나 수학 따위를 좀 잘했다고 해서 그렇지 못했던 친구들의 물질적 성공을 부당하게 생각하는 것",
              Eng: "I mean, just because you did well in English or Math or whatever at the time and then thinking it was unfair that friends who were not as good at such subjects should have the material successes that they had...",
            },
            {
              Kor: "그렇다고 그가 그릇된 사회구조로부터 박해받는 사람도 아니고 ",
              Eng: "Having said that he wasn't somebody who was oppressed by the flawed structure of society …",
            },
            {
              Kor: "그렇다고 자는 것도 아닌 모양 ",
              Eng: "having said that, it also appeared as if no one were sleeping",
            },
          ],
        },
        {
          entryName: "-다고 해도",
          typicalExample:
            "【맘에 드는 남자가 있다고 해도 여기서는 살기가 싫어요 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            " Even supposing ... Lit.:  … saying, “Quote.” Used to give a reason.",
          sentences: [
            {
              Kor: "맘에 드는 남자가 있다고 해도 여기서는 살기가 싫어요 ",
              Eng: "Even if/Supposing there were a man that I fancied, I would still hate to live here.",
            },
          ],
        },
        {
          entryName: "-다구 ",
          typicalExample: '【"난 이만큼이나 여자아이들과 잤다구." 】',
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation: "Colloquial/substandard -다구 for standard -다고.",
          sentences: [
            {
              Kor: '"난 이만큼이나 여자아이들과 잤다구." ',
              Eng: "I've slept with this many girls (I tell 'ya).",
            },
          ],
        },
        {
          entryName: "-다기로 ",
          typicalExample: "【오래 산에서 살았다기로 】",
          categoryKorean: "관용구/준말 Abbreviation",
          categoryEnglish: "(관용구/준말 Abbreviation)",
          translation:
            " Insofar as it was said that ... ;  given that they say that ...  (abbreviated from -다고 하기로).",
          sentences: [
            {
              Kor: "오래 산에서 살았다기로 ",
              Eng: "Given that [someone had been] living on a mountain for quite some time ",
            },
          ],
        },
        {
          entryName: "-다기보다",
          typicalExample: "【처음부터 누굴 해치려고 나타났다기보다는 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN1다기보다 NOUN2:  more as NOUN2 than as NOUN1;  more as what you would call NOUN2 than NOUN1;  it's not so much that … rather ...",
          sentences: [
            {
              Kor: "처음부터 누굴 해치려고 나타났다기보다는 ",
              Eng: "It was not so much that they had set out intending to kill someone ...",
            },
            {
              Kor: "이쁘다기보다",
              Eng: "I wouldn't say that she was pretty, but rather ...",
            },
          ],
        },
        {
          entryName: "-다나? ",
          typicalExample: "【자기도 자유가 필요하다나?  】",
          categoryKorean:
            "하게체의 종결 어미 Final ending in Familiar Style/준꼴 Contraction",
          categoryEnglish:
            "(하게체의 종결 어미 Final ending in Familiar Style/준꼴 Contraction)",
          translation:
            "Contracted from -다[고 하]나? meaning  does one say that ... ?;  Is what one’s said that ... ?;  Are they saying ... I wonder (half to myself, half for the benefit of anybody listening)? ",
          sentences: [
            {
              Kor: "자기도 자유가 필요하다나?  ",
              Eng: "Now let me see—doesn't she claim she needs her freedom, too?",
            },
          ],
        },
        {
          entryName: "-다 남다",
          typicalExample: "【배에서 먹다 남은 실과 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Transferentive -다(가) + 남-:  something remains left over from having been half-done. ",
          sentences: [
            {
              Kor: "배에서 먹다 남은 실과 ",
              Eng: "fruit left over from somebody eating it on the ship ",
            },
            {
              Kor: "피다 남은 꽁초 ",
              Eng: "the half-smoked cigarette  (stub)",
            },
            { Kor: "반 타다 남은 시체 ", Eng: "a half-burned corpse" },
          ],
        },
        {
          entryName: "-다느니",
          typicalExample:
            "【가끔 편지만 해주었어도 일이 이 꼴에까지 이르지 않았다느니 】",
          categoryKorean: "연결어미 Connective ending/준꼴 Contraction",
          categoryEnglish: "(연결어미 Connective ending/준꼴 Contraction)",
          translation:
            "QUOTE1 느니 QUOTE2느니, etc.:  The claim that QUOTE1 and QUOTE2 , etc. In origin, a contracted quotation from -다[고 하]느니.",
          sentences: [
            {
              Kor: "가끔 편지만 해주었어도 일이 이 꼴에까지 이르지 않았다느니 ",
              Eng: "Some claimed it would not have come to this if he had at least sent letters once in a while ...",
            },
          ],
        },
        {
          entryName: "-다느니보다 ",
          typicalExample:
            "【까맣게 잊었다느니보다도 그쪽에 보낼 마음의 가닥이 있을 수 없도록 엄청난 변이었던 것이다. 】",
          categoryKorean: "관용구/준꼴 Contraction",
          categoryEnglish: "(관용구/준꼴 Contraction)",
          translation:
            " It isn't so much that A, [but rather B];  rather than A, one might say B instead. Abbreviated from -다고 하느니보다.",
          sentences: [
            {
              Kor: "까맣게 잊었다느니보다도 그쪽에 보낼 마음의 가닥이 있을 수 없도록 엄청난 변이었던 것이다. ",
              Eng: "It was not so much that he had completely forgotten as there having been a disastrous misfortune that had left him with no leisure whatsoever to send her his regards.",
            },
          ],
        },
        {
          entryName: "-다는1",
          typicalExample: "【연애를 하고 싶다는 그녀 말의 속뜻은  】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "-다는 NOUN means  the NOUN about which they say/it is said that QUOTE, where -다는 is abbreviated from the indirect quotation pattern in -다[고 하]는 and modifies the following  noun.",
          sentences: [
            {
              Kor: "연애를 하고 싶다는 그녀 말의 속뜻은  ",
              Eng: "What she really meant when she said she wanted to have an affair was … ",
            },
            {
              Kor: "용하다는 침도 ",
              Eng: "[She even tried some] acupuncture treatments that were supposed to be beneficial, but ...",
            },
            {
              Kor: "지나가던 행인에게 간판이 떨어졌다는 날이다. ",
              Eng: "It is a day when a sign had supposedly fallen onto some passing pedestrian.",
            },
            {
              Kor: "어제 남편 일행과 골프를 쳤다는 사람이었다.  ",
              Eng: "It was the man with whom her husband and his pals had supposedly played golf yesterday.",
            },
            {
              Kor: "설탕 한 알 들지 않았다는 곡주 ",
              Eng: "the brew, which they said did not contain even a single grain of sugar ",
            },
          ],
        },
        {
          entryName: "-다는2",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            " [The person] who says/claims that ... abbreviated from an indirect quotation in -다 [고 하]는, modifying the following  person, etc.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-다는3",
          typicalExample: "【고맙다는 인사 】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            " The putative/alleged [fact, claim, words, etc.] to the effect that QUOTE, abbreviated from an indirect quotation in -다[고 하]는, modifying the following  fact, claim, words, etc.",
          sentences: [
            { Kor: "고맙다는 인사 ", Eng: "a gesture of thanks " },
            {
              Kor: "아무래도 알 수 없다는 표정 ",
              Eng: 'the expression that said, "No matter what [was explained], I have no idea"',
            },
            {
              Kor: "인색하고 무례하다는 평판만 있으면 ",
              Eng: "The minute there was a report ['saying'] that they/he was stingy or discourteous …",
            },
            {
              Kor: "짐승의 발소리를 들었다는 내 얘기 ",
              Eng: "my story of hearing the footsteps of a tiger",
            },
            {
              Kor: "두꺼운 입술이 병약하다는 인상을 버리도록 요구하고 있었다. ",
              Eng: "Her thick lips were demanding that he cast away his impression of her as having a sickly countenance.",
            },
          ],
        },
        {
          entryName: "-다는4",
          typicalExample: "【여기서 더 지껄였다는 나만 앵한 것을 알았다. 】",
          categoryKorean: "연결어미 Connective ending/준꼴 Contraction",
          categoryEnglish: "(연결어미 Connective ending/준꼴 Contraction)",
          translation:
            "An abbreviated form of the warning pattern in -다가는:  if one keeps on doing then (unfortunately) ...",
          sentences: [
            {
              Kor: "여기서 더 지껄였다는 나만 앵한 것을 알았다. ",
              Eng: "If I continue to go on like this, I knew I'd be the only one bitter. ",
            },
          ],
        },
        {
          entryName: "-다는 거",
          typicalExample: "【집안일이 지겹고 힘들다는 거야 나도 알지. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-다는 거, where –다는 ← -다[고 하]는, and hence the phrase means  the alleged/purported (and third-hand) fact that QUOTE. ",
          sentences: [
            {
              Kor: "집안일이 지겹고 힘들다는 거야 나도 알지. ",
              Eng: "Of course I know that she finds household chores tiresome and difficult.",
            },
          ],
        },
        {
          entryName: "-다는 걸까 ",
          typicalExample:
            "【그녀가 언제부터 이렇게 자기 생각을 갖고 산다는 걸까.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-다는 걸까←-다는 것일까 with -다는 것 (cf. also -ㄴ다는 것, -는다는 것, -(이)라는 것) from the indirect quotation pattern in -다[고 하]는 것, etc., meaning  the putative or alleged fact that ...  ",
          sentences: [
            {
              Kor: "그녀가 언제부터 이렇게 자기 생각을 갖고 산다는 걸까.  ",
              Eng: "I wonder how long it's been since she's presumed to have ideas of her own like this?",
            },
          ],
        },
        {
          entryName: "-다는 것",
          typicalExample:
            "【전혀 웃고 있지 않다는 것은 지수의 착각이었을까.  】",
          categoryKorean: "Indirect Quotation",
          categoryEnglish: "(Indirect Quotation)",
          translation:
            "From the indirect quotation pattern in -다[고 하]는 것, etc., meaning  the putative or alleged fact that ... , setting up a comment about or definition of the (often unusual/noteworthy) topic marked by -다는 것. ",
          sentences: [
            {
              Kor: "전혀 웃고 있지 않다는 것은 지수의 착각이었을까.  ",
              Eng: "The fact that he was not smiling at all—could it just have been Chisu’s imagination?",
            },
            {
              Kor: "동시에 무진이 가까왔다는 것이 더욱 실감되었다 ",
              Eng: "Then I could feel the proximity of Mujin (lit.: the fact that Mujin had drawn near)",
            },
            {
              Kor: "자신이 없다는 것이 ",
              Eng: "[her] purported lack of confidence",
            },
            {
              Kor: "그렇게 많은 사람들이 살고 있다는 건 좀 이상스럽거든요 ",
              Eng: "It's a bit strange that so many people live there. ",
            },
            {
              Kor: "군인들께 팔았다는 것인데 ",
              Eng: "They say that she used to sell [rice cakes] to the soldiers, but ...",
            },
            {
              Kor: "부귀영화를 같이 하고 싶다는 것이었다 ",
              Eng: "… wanted to share the wealth and glory.",
            },
            {
              Kor: "찾고 있다는 것이었다",
              Eng: "… was trying to find the person",
            },
            {
              Kor: "아직 어리다는 것이 ",
              Eng: "the alleged fact that she was still too young ...",
            },
            {
              Kor: "따로 있다는 것뿐이겠지 ",
              Eng: "If only there was a different place [for classical and pop songs to be sung].",
            },
          ],
        },
        {
          entryName: "-다는 것을 깨닫는다",
          typicalExample: "【누운 자리가 눅눅하다는 것을 깨닫는 순간  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            " Realizes the (putative or alleged) fact that ...  In origin, a contracted quotation from the -는다[고 하]는 것, etc. ",
          sentences: [
            {
              Kor: "누운 자리가 눅눅하다는 것을 깨닫는 순간  ",
              Eng: "the instant she realized that the bedding she was lying on was wet",
            },
          ],
        },
        {
          entryName: "-다는 것을 말해 준다 ",
          typicalExample:
            "【그녀에게 강좌가 있다는 것을 말해 줄 마음이 들었다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            " Tells (somebody) that ... ;  lets (somebody) know that ... , with -다는 것 (cf. also -는다는 것, -ㄴ다는 것, -(이)라는 것) from the indirect quotation pattern in -ㄴ다[고 하]는 것, etc., meaning  the putative or alleged fact that ... . ",
          sentences: [
            {
              Kor: "그녀에게 강좌가 있다는 것을 말해 줄 마음이 들었다.  ",
              Eng: "I felt like letting her know that there were lectures.",
            },
          ],
        },
        {
          entryName: "-다는 것을 안다 ",
          typicalExample:
            "【그러나 이제 시작에 불과하다는 것을 윤호는 알았다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            " Know that ... From -(으)ㄴ다는 것, -는다는 것, -다는 것, -(이)라는 것  the putative or alleged fact that ...  (as object) followed by 알다  know. In origin, from the indirect quotation pattern in -는다[고 하]는 것, etc. ",
          sentences: [
            {
              Kor: "그러나 이제 시작에 불과하다는 것을 윤호는 알았다.  ",
              Eng: "But Yunho knew that this was merely the beginning.",
            },
          ],
        },
        {
          entryName: "-다는 것을 증명한다",
          typicalExample:
            "【시험문제는 ‘1이 0보다 크다는 것을 증명하라’는 것이었다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            " Proves that QUOTE;  proves the fact that QUOTE, where -다는 is abbreviated from -다[고 하]는.",
          sentences: [
            {
              Kor: "시험문제는 ‘1이 0보다 크다는 것을 증명하라’는 것이었다.  ",
              Eng: "The exam asked to 'prove that 1 is greater than 0.'",
            },
          ],
        },
        {
          entryName: "-다는 것이다",
          typicalExample: "【태반이 안 나왔다는 거야.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-다는 것이다 (treated colloquially as -다는 거다) means literally  it is a case or matter of saying that QUOTE ;  the claim is that QUOTE  and is from the indirect quotation pattern in -다[고 하]는 것이다. In a more extended usage, it can also function to mean  apparently ,  allegedly (as if reporting hearsay) .",
          sentences: [
            {
              Kor: "태반이 안 나왔다는 거야.",
              Eng: "She was saying that her placenta had not come out yet.",
            },
            {
              Kor: "그 장면이 하나도 괴이쩍게 여겨지지 않았다는 것이다.  ",
              Eng: "The thing was that the scene did not seem the least bit off.",
            },
            {
              Kor: "절로 해결되는 걸 공연히 안달복달할 필요가 없다는 거였다.  ",
              Eng: "It was a case of saying that there was no need to get all bent out of shape for no good reason over something that would resolve itself on its own.",
            },
          ],
        },
        {
          entryName: "-다는 게",
          typicalExample:
            "【 이런 섬세한 느낌을 가질 수 있다는 게 여자 된 즐거움인 듯도 하다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-다는 게←-다는 것이, where -다는 것 (cf. also -는다는 것, -ㄴ다는 것, -(이)라는 것) is from the indirect quotation pattern in -다[고 하]는 것, etc., meaning  the putative or alleged fact that ... (as subject).",
          sentences: [
            {
              Kor: " 이런 섬세한 느낌을 가질 수 있다는 게 여자 된 즐거움인 듯도 하다. ",
              Eng: "That I am able to feel such sensations seems to be one of the joys of being a woman.",
            },
          ],
        },
        {
          entryName: "-다는 게 무엇보다 좋다",
          typicalExample: "【유유자적할수 있다는 게 무엇보다 좋았다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: " The best part of it is the fact that ...",
          sentences: [
            {
              Kor: "유유자적할수 있다는 게 무엇보다 좋았다 ",
              Eng: "The best part of it was that I could live a carefree life.",
            },
          ],
        },
        {
          entryName: "-다는 기색",
          typicalExample: "【어서 끝내고 싶다는 기색이 역력했다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-다는 기색:  An air or suggestion as if to say ... where -다는 is abbreviated from the indirect quotation pattern in -다[고 하]는.",
          sentences: [
            {
              Kor: "어서 끝내고 싶다는 기색이 역력했다.  ",
              Eng: "It was clear that he wanted to get it over with right away.",
            },
          ],
        },
        {
          entryName: "-다는 둥 ",
          typicalExample: "【오늘따라 반찬이 없다는 둥  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-다는 둥 ← -다[고 하-]는 둥 , with post-modifier 둥 for what is originally 등 in its meaning (following a list or enumeration) of  et cetera,  and so forth,  and so,  and the like,  and all that,  and such like. For a list of (usually quoted) hypothetical options or choices, with the implication is that the litany of quotes is somewhat tedious and tiresome.",
          sentences: [
            {
              Kor: "오늘따라 반찬이 없다는 둥  ",
              Eng: "[she kept talking—things like] today of all days we don't have any panch'an",
            },
            {
              Kor: "설마 당신 진짜로 일찍 들어올 줄 몰랐다는 둥  ",
              Eng: "[she kept talking—things like] I didn't dream that you would really come home early tonight, etc.",
            },
          ],
        },
        {
          entryName: "-다는 듯(이)",
          typicalExample: "【기다렸다는 듯이 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Based on -는 듯(이)  like,  as,  giving the appearance of ...;  appearing (to be),  looking (like);  seeming as if;  as if/though plus an abbreviated indirect quotation in -다[고 하-]. Hence:  as if to say ... ;  like,  as,  giving the appearance as if to say ... ",
          sentences: [
            {
              Kor: "기다렸다는 듯이 ",
              Eng: "as if thinking that he had waited...",
            },
            {
              Kor: "그는 마치 다 알고 있었다는 듯이 ",
              Eng: "as if he had known all about it ...",
            },
            {
              Kor: "미안하다는 듯 말했다 ",
              Eng: 'He asked apologetically (lit.: as if to say "I\'m sorry")',
            },
            {
              Kor: "박은 기다렸다는 듯이 ",
              Eng: "Pak, as if to say he had been waiting ...",
            },
            {
              Kor: "그는 유쾌해 죽겠다는 듯이 웃어대었다 ",
              Eng: "He was laughing as if he was delighted to death.",
            },
            {
              Kor: "지수를 바라보기에도 지쳤다는 듯  ",
              Eng: "as if to say that they were also exhausted from gazing at Chisu",
            },
            {
              Kor: "문을 모르겠다는 듯  ",
              Eng: "as if to say he had no clue why …",
            },
            {
              Kor: "평생을 그래왔다는 듯  ",
              Eng: "as to say they had done that all their lives …",
            },
            {
              Kor: "알 수 없다는 듯 서늘한 얼굴",
              Eng: "a cool face that was if to say ‘no idea’",
            },
            {
              Kor: "이따금 생각났다는 듯  ",
              Eng: "now and then as if to say it had just come to mind",
            },
            {
              Kor: "여자는 승낙을 받아냈다는 듯 ",
              Eng: "the woman, as if to say that she had secured permission",
            },
          ],
        },
        {
          entryName: "-다는 뜻에서 ",
          typicalExample: "【잘 만날 수 없다는 뜻에서 그렇게 표현한 것이다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            " From the [quoted, and hence 'purported'] purpose of ...-ing;  in the meaning/sense of ...-ing, built on -다는 from the indirect quotation pattern in -다[고 하]는 (cf. also -는다는, -(으)ㄴ다는,  -(이)라는) adnominalized to 뜻  meaning; sense. ",
          sentences: [
            {
              Kor: "잘 만날 수 없다는 뜻에서 그렇게 표현한 것이다. ",
              Eng: "She had expressed it in such a way as to mean that we could not meet often.",
            },
            {
              Kor: "달이 없었다는 뜻에서 ",
              Eng: "… to mean that it being moonless ... ",
            },
          ],
        },
        {
          entryName: "-다는 말",
          typicalExample: "【오셨다는 말을 듣고 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            " The putative/alleged words to the effect that QUOTE, abbreviated from an indirect quotation in -다[고 하]는, modifying the following  말, 말씀, etc.",
          sentences: [
            {
              Kor: "오셨다는 말을 듣고 ",
              Eng: "when I heard that he was coming ...",
            },
            {
              Kor: "가장 모범적인 자연보호운동을 시행하고 있다는 말씀이었소 ",
              Eng: "They said that you were the most exemplary of all environmentalists.",
            },
            {
              Kor: "허리를 다쳤다는 말을 들었을 때  ",
              Eng: "when I heard her saying that she had hurt her back …",
            },
            {
              Kor: "아이를 가졌다는 지수의 말 ",
              Eng: "Chisu’s words to the effect that she was pregnant",
            },
          ],
        },
        {
          entryName: "-다는 사실 ",
          typicalExample: "【아이가 건강하다는 사실 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            " The putative/alleged fact that QUOTE, abbreviated from an indirect quotation in -다[고 하]는, adnominalizated to 사실  fact. The contracted quotation structure in -다는←-다[고 하]는 points up the alleged/purported (and third-hand) nature of the 사실 it is modifying. ",
          sentences: [
            {
              Kor: "아이가 건강하다는 사실 ",
              Eng: "the fact that her child was healthy",
            },
            {
              Kor: "이 섬에도 죽음이 있다는 사실 ",
              Eng: "the fact that there was death on this island, too",
            },
            {
              Kor: "내가 자기를 바라보고 있다는 사실이 믿기지 않는 듯  ",
              Eng: "as if she can't bring herself to believe the fact that I am gazing at her",
            },
            {
              Kor: "아물지 않은 상처를 헤집고 나왔다는 사실이  ",
              Eng: "the fact that it had made its way out of the unhealed wound",
            },
          ],
        },
        {
          entryName: "-다는 생각 ",
          typicalExample:
            "【그 여자를 어서 만나보고 싶다는 생각이 되살아났다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            " Thoughts of ... ;  the thought that ... ;  thought[s] to the effect that QUOTE. Built on an abbreviated indirect quotation in -다[고 하-] modifying the following 생각  thought.",
          sentences: [
            {
              Kor: "그 여자를 어서 만나보고 싶다는 생각이 되살아났다 ",
              Eng: "The thoughts of my desire to meet her were quickly revived.",
            },
            {
              Kor: "오로지 졸리다는 생각 뿐 ",
              Eng: "the only thought I had was that I was sleepy ...",
            },
            {
              Kor: "차라리 꽃꽂이나 서예 강좌가 낫다는 생각이 들었다. ",
              Eng: "[Instead of lectures of this nature,] it occurs to me that flower arranging or calligraphy lessons would be better.",
            },
            {
              Kor: "도와 드려야겠다는 생각뿐이에요 ",
              Eng: "The only thought I have is that I should help her.",
            },
          ],
        },
        {
          entryName: "-다는 소리(를) 듣는다",
          typicalExample:
            "【그렇다고 학생 때 무슨 글을 써봤다는 소리도 듣지 못했다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Lit.:  hear sounds to the effect that ... , i.e.,  hear that ... ;  hear something  to the effect that ... .",
          sentences: [
            {
              Kor: "그렇다고 학생 때 무슨 글을 써봤다는 소리도 듣지 못했다. ",
              Eng: "Having said that, I had never heard anything about her trying her hand at writing when she was a student, either. ",
            },
          ],
        },
        {
          entryName: "-다는 이유로",
          typicalExample: "【단지 일자리를 잃었다는 이유만으로 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            " On account of the (alleged) reason that ... with an abbreviated indirect quotation in -다는 ← -다[고 하]는 modifying 이유  reason.",
          sentences: [
            {
              Kor: "단지 일자리를 잃었다는 이유만으로 ",
              Eng: "merely on the account that I had lost my job ...",
            },
          ],
        },
        {
          entryName: "-다는 인상 ",
          typicalExample:
            "【두꺼운 입술이 병약하다는 인상을 버리도록 요구하고 있었다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            " Impression to the effect that ... ;  impression to the effect that QUOTE. Built on an abbreviated indirect quotation in -다[고 하-] modifying 인상  impression.",
          sentences: [
            {
              Kor: "두꺼운 입술이 병약하다는 인상을 버리도록 요구하고 있었다. ",
              Eng: "Her thick lips were demanding that he cast away his impression of her as having a sickly countenance.",
            },
          ],
        },
        {
          entryName: "-다는 핑계로",
          typicalExample: "【아프다는 핑계루 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            ' Taking ... as one\'s excuse;  on the pretext of ... ;  under the pretense of ... ;  with a plea of ... , preceded by a contracted quotation in -다는 핑계로 ← -다[고 하]는 핑계로 meaning  on the pretense/with the excuse that "QUOTE."',
          sentences: [
            {
              Kor: "아프다는 핑계루 ",
              Eng: "under the pretense of not feeling well ...",
            },
          ],
        },
        {
          entryName: "-다니(요)",
          typicalExample: "【옷 호사를 못 하다니 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            " Tsk-tsk pattern in plain base + -다니 (originally abbreviated from an indirect quotation in -다고 하니)  To think that ...!,  Just imagine!,  Can you believe it?,  Who woulda thought that?,  What a shame to think that ... Note that this ending only appears on the Plain Base of verbs. ",
          sentences: [
            {
              Kor: "옷 호사를 못 하다니 ",
              Eng: "what a shame that (she) doesn't dress well ... ",
            },
            {
              Kor: "이 많은 사람들이 한결같이 이 영화를 보러 몰려들다니 ",
              Eng: "to think that this many people would line up to see this film",
            },
            {
              Kor: "아직도 이런 무릉도원이 남아 있다니 ",
              Eng: "to think that there is still such a utopia left on this earth ...",
            },
            {
              Kor: "아무도 없다니 ",
              Eng: "to think that there is nobody [here]",
            },
            { Kor: "도와 드리다니요? ", Eng: "Help her? But how?" },
            {
              Kor: "서방님이 오시다니 ",
              Eng: "I'm telling you that the young master has come!",
            },
            {
              Kor: "벌써 마흔 마리를 넘어 낚았다니 ",
              Eng: "Just imagine, [they have already] fished over 40 pairs [of shoes]! ",
            },
          ],
        },
        {
          entryName: "-다니까",
          typicalExample: "【잔돈이 그것밖에 없다니까 】",
          categoryKorean:
            "해체의 종결 어미 Final ending in Intimate Style/준꼴 Contraction",
          categoryEnglish:
            "(해체의 종결 어미 Final ending in Intimate Style/준꼴 Contraction)",
          translation:
            'Abbreviated from -다고 하니까. For insistent, reproachful reiterations. But this abbreviated pattern has a special nuance:  I say/insist it is/does or  I tell you (emphatically, yet again) it is/does =  it really is/does;  it is/does, you see (mind you)!;  But I tell you: " ... !"',
          sentences: [
            {
              Kor: "잔돈이 그것밖에 없다니까 ",
              Eng: "I told you, these are the only change I have!",
            },
            {
              Kor: "글쎄 아무 일도 없다니까 ",
              Eng: "Believe me, there is nothing wrong [with T'aesik]!",
            },
            {
              Kor: "십년이 넘었다니까 ",
              Eng: "It's been over ten years, I tell you!",
            },
            {
              Kor: "마누라들은 한가하게 인생 타령이나 하고, 수준들 높다니까.  ",
              Eng: "The wives kick back and complain about their lot in life—a pretty high-class bunch, I'm tellin' ya!",
            },
            { Kor: '"넌 까딱없다니까." ', Eng: "Like I said, you're fine." },
          ],
        },
        {
          entryName: "-다더구나",
          typicalExample: "【그 토끼를 잡아먹었다더구나! 】",
          categoryKorean: "종결 어미 Final ending/준꼴 Contraction",
          categoryEnglish: "(종결 어미 Final ending/준꼴 Contraction)",
          translation:
            'From an abbreviated quote in -다[고 하]더구나! with a retrospective apperceptive in -더구나, -더군:  now I realize that somebody said/claimed that ... ! Apperceptives in - 군, -구나, -군요, -구려, etc., carry the meaning of "first realization."',
          sentences: [
            {
              Kor: "그 토끼를 잡아먹었다더구나! ",
              Eng: "I heard them saying that they ate the rabbit!",
            },
          ],
        },
        {
          entryName: "-다더군(요)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending/준꼴 Contraction",
          categoryEnglish: "(종결 어미 Final ending/준꼴 Contraction)",
          translation:
            "Abbreviated ← -다[고 하]더군요:  I see that they say/somebody says that ...!",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-다더라",
          typicalExample: "【야, 미국은 좀 그렇고 캐나다가 좋다더라.  】",
          categoryKorean:
            "해라체의 종결 어미 Final ending in Plain Style/준꼴 Contraction",
          categoryEnglish:
            "(해라체의 종결 어미 Final ending in Plain Style/준꼴 Contraction)",
          translation:
            "Contracted from -다[고 하]더라  I recall that somebody said that ... ;  apparently, ... ;  apparently, they say that ... .",
          sentences: [
            {
              Kor: "야, 미국은 좀 그렇고 캐나다가 좋다더라.  ",
              Eng: "Say, I heard that the US was not that great but that Canada was nice.",
            },
          ],
        },
        {
          entryName: "-다던",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "For -다[고 하]던, an abbreviated indirect quotation + retrospective modifier, meaning something like:  [NOUN] about which it used to be said that QUOTE. ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-다도 ",
          typicalExample: "【드팀전을 하다도 실패를 하였고,  ← 하다(가)도 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Abbreviated from Transferentive -다(가) indicating a change or shift of action or state plus particle 도  even, i.e.,  even in the middle of ...-ing.",
          sentences: [
            {
              Kor: "드팀전을 하다도 실패를 하였고,  ← 하다(가)도 ",
              Eng: "[he] had also run [a dry-goods store] for a while but then that failed as well",
            },
            {
              Kor: "그러다도 = 그러다가도 ",
              Eng: "even after carrying on like this for a while",
            },
            {
              Kor: "그리다도 배가 고파 어찌할 수 없을 때엔",
              Eng: "even after carrying on like this for a while, when he was overtaken by extreme hunger and unable to ignore it...",
            },
          ],
        },
        {
          entryName: "-다드군",
          typicalExample: "【약두 변변히 못 써 봤다드군 】",
          categoryKorean: "해체의 종결 어미 Final ending in Intimate Style",
          categoryEnglish: "(해체의 종결 어미 Final ending in Intimate Style)",
          translation:
            "From -다[고 하-]더군, i.e., an indirect quotation followed by the Retrospective ('First Realization') form in -더군. The shape -드- is now considered substandard for -더-.",
          sentences: [
            {
              Kor: "약두 변변히 못 써 봤다드군 ",
              Eng: "[They say, as I recall with some surprise, that] they couldn't even administer medicine properly.",
            },
          ],
        },
        {
          entryName: "-다란 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Bound adjective",
          categoryEnglish: "(Bound adjective)",
          translation: "[Usage]",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-다랗다",
          typicalExample: "【가느다랗고 긴 바늘 】",
          categoryKorean: "Bound adjective",
          categoryEnglish: "(Bound adjective)",
          translation:
            "Bound adjective -다랗다 (-다래요, -다란):  is rather,  quite,  sort of (sorta),  kind of  (kinda);  is ...-ish. Note that ㄹ-extending bases regularly lose their -ㄹ- before this ending; e.g., 기다랗다 ← 기-ㄹ-  longish, 가느다랗다  fine, thin, slender, etc.",
          sentences: [
            {
              Kor: "가느다랗고 긴 바늘 ",
              Eng: "an ever-so-thin, long needle ",
            },
            {
              Kor: "아버지의 기다란 손가락",
              Eng: "her father's ever-so-long fingers",
            },
            {
              Kor: "되는 대로 짐을 우겨넣은 커다란 트렁크 ",
              Eng: "her huge trunk, stuffed any old which way with her things",
            },
            {
              Kor: "세 줄의 가느다란 금사슬 ",
              Eng: "three slender gold chains",
            },
            { Kor: "가느다란 연기 ", Eng: "an ever-so-slender wisp of smoke " },
            { Kor: "높다란 망루 ", Eng: "a rather tall lookout post " },
            {
              Kor: "길다란 철책과 철조망 ",
              Eng: "a longish iron fence and a network of barbed wire (Here the ㄹ is retained exceptionally.)",
            },
          ],
        },
        {
          entryName: "-다래요 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Bound adjective",
          categoryEnglish: "(Bound adjective)",
          translation: "[Usage]",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "다름(이) 없다",
          typicalExample: "【여느날과 다름없이】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN과/와 다름(이) 없- :  is no different (from);  is the same (as);  is constant, unchanging.",
          sentences: [
            {
              Kor: "여느날과 다름없이",
              Eng: "… is no different from any other day ",
            },
            {
              Kor: "왁자지껄하고 싸우는 것도 전과 다름없다 ",
              Eng: "The boisterousness and fighting were also no different than before.",
            },
          ],
        },
        {
          entryName: "-다 마다",
          typicalExample: "【반갑다마다】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "Plain Style indicative form in -다 followed by 말다 (or the same, with the ㄹ dropped; cf. the pattern in -자 마자  as soon as, which in origin is -자 말자):  of course. This pattern is equivalent in meaning (and structure!) to -고 말고.",
          sentences: [
            { Kor: "반갑다마다", Eng: "of course she was happy to see me" },
            { Kor: "닿다마다", Eng: "of course [the train] goes that far " },
          ],
        },
        {
          entryName: "다만 ",
          typicalExample: "【다만 기벽이 그러했다는 것뿐으로 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Adverb  only,  just;  but,  however can occur by itself but often indicates that a 뿐 is coming up.",
          sentences: [
            {
              Kor: "다만 기벽이 그러했다는 것뿐으로 ",
              Eng: "however, it was said to be just a peculiarity ...",
            },
          ],
        },
        {
          entryName: "-다 만다 ",
          typicalExample: "【큰 집 하다 말고】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-다(가) 마-ㄹ-: Base + transferentive -다(가) (with 가 optionally deleted) followed by 말다  cease, desist;  does (a while) then stops;  stops/hesitates in the middle of doing, meaning  to stop in mid-action, or  drop what one is doing in favor of a new  action.",
          sentences: [
            {
              Kor: "큰 집 하다 말고",
              Eng: '[Yŏngdal] just said "the big workshop" and then stopped …',
            },
            {
              Kor: "도중에서 가다 말고 ",
              Eng: "[they will] stop halfway along the road ...",
            },
            {
              Kor: "낱가리 밑에서나 배회사 창고 기슭에서 자다 말고 ",
              Eng: "[he] would sleep for a while under the pollack drying racks or at the edge of the boat company warehouse, and then ...",
            },
            {
              Kor: "일본집 쓰레기통에서 무얼 주워먹다 말고 ",
              Eng: "[he] would go dumpster-diving at the Japanese restaurants and then ...",
            },
            {
              Kor: "저녁을 먹다 말고, 단잠을 자다 말고 ",
              Eng: "[they] would leave their meals half-eaten or wrench themselves from a sweet sleep and ...",
            },
            {
              Kor: "사서주임은 화장을 고치다 말고 ",
              Eng: "the head librarian stopped in the middle of fixing her make up ...",
            },
            {
              Kor: "또 한 발을 내디디다 말고 ",
              Eng: "he took a step forward and then ...; or, he stopped in mid-step, and then ...",
            },
            {
              Kor: "한참 덕이네 대문을 흘겨보다 말고 ",
              Eng: "[Pau] scowled for a moment at the front gate to Togi's house and then stopped …",
            },
            {
              Kor: "비가 오다 말았어요",
              Eng: "It was raining for a while but the rain had stopped.",
            },
            { Kor: "무너지다 만 담 ", Eng: "walls that were half crumbling" },
          ],
        },
        {
          entryName: "-다매",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            'From -는다[고 하]며 meaning 1)  while saying that "QUOTE," ...  and 2)  Is it true that  "QUOTE?" A highly colloquial form.',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-다며1",
          typicalExample: "【노인은 그렇겠다며 고개를 끄덕였다 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            'From -다[고 하]며 meaning  while saying/thinking that "QUOTE," and ... .',
          sentences: [
            {
              Kor: "노인은 그렇겠다며 고개를 끄덕였다 ",
              Eng: 'The old man nodded as if to say, "That is likely so."',
            },
          ],
        },
        {
          entryName: "-다며2 ",
          typicalExample: "【삶을 비관하고 자살 충동까지 생겨서 그렇다며?  】",
          categoryKorean: "해체의 종결 어미 Final ending in Intimate Style",
          categoryEnglish: "(해체의 종결 어미 Final ending in Intimate Style)",
          translation: 'From -다[고 하]며 meaning  Is it true that "QUOTE?"',
          sentences: [
            {
              Kor: "삶을 비관하고 자살 충동까지 생겨서 그렇다며?  ",
              Eng: "Is it true that they became pessimistic about life and even developed impulses to commit suicide?",
            },
          ],
        },
        {
          entryName: "-다면",
          typicalExample: "【수면제를 만들어낼 수 있다면 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Conditional in -는다면, -ㄴ다면, -다면. In origin, this pattern is built on an indirect quotation in -는다[-고 하-]면, -ㄴ다[-고 하-]면, -다[-고 하-]면 and can be interpreted in two ways: 1) literally, as  if one says/supposes that ... and 2) as a more hypothetical  if than the usual conditional in -(으)면.",
          sentences: [
            {
              Kor: "수면제를 만들어낼 수 있다면 ",
              Eng: "if it were possible to make a sleeping tonic ...",
            },
            {
              Kor: "세상에 나무가 없다면 ",
              Eng: "how wonderful would it be if there were no trees ...",
            },
            { Kor: "내 환몽이 깊다면 ", Eng: "if my fantasy runs deep ..." },
            {
              Kor: "정 약을 안 사다먹겠다면 ",
              Eng: "if [Kilsu] really didn't intend to buy any medicine ...",
            },
            {
              Kor: "만약 정말로 자위를 했다면  ",
              Eng: "if he had really been masturbating …",
            },
            {
              Kor: "만약 그녀가, 그럼 어떡해요 당신도 없는데, 했다면  ",
              Eng: 'If she had said, "What was I supposed to do—you weren\'t home?" …',
            },
            {
              Kor: "그렇지 않다면 벌써 전에 때려치웠을 것이라고 생각했다.  ",
              Eng: "Otherwise he thought he would have stopped going [to the cram school] long ago already.",
            },
            {
              Kor: "지섭이 있었다면 이야기를 했을 것이다.  ",
              Eng: "If Chisŏp had been there, he would have told him. ",
            },
            {
              Kor: "조금이라도 순수한 면을 지녔다면 윤호는 그를 용서했을지도 모른다. ",
              Eng: "If [In'gyu] had had even the slightest purity of character, Yunho might have forgiven him.",
            },
          ],
        },
        {
          entryName: "-다면서",
          typicalExample:
            "【그는 우주인과 그 가족을 만나게 해주겠다면서 윤호를 끌고 나갔다. 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            'Contracted from -(으)ㄴ/-는다[고 하]면서  while saying that "QUOTE," ... ',
          sentences: [
            {
              Kor: "그는 우주인과 그 가족을 만나게 해주겠다면서 윤호를 끌고 나갔다. ",
              Eng: "Chisŏp dragged Yunho there, saying that he would let him meet the spaceman and his family. ",
            },
            {
              Kor: "진통제도 안 맞으시겠다면서 ",
              Eng: "while he is saying that he will not take painkillers ...",
            },
            {
              Kor: "온 천만에 말씀을 다 하신다면서 ",
              Eng: "while saying, \"Don't mention it\" … (The stock expression is (온)천만에 말씀을 다 하십니다  Don't mention it.)",
            },
          ],
        },
        {
          entryName: "-다면서(요)?",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            'From -다[-고 하-]면서(요) meaning  Is it true that "QUOTE?" A highly colloquial form.',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-다 못해(서) ",
          typicalExample: "【기다리 다 못해  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-다(가) 못해  unable to ... any longer (lit.:  in the middle of ...-ing,  is not able to continue);  is unable to complete the original action. Based on transferentive -다(가)  does/ did (is/was) and then;  does/did (is/was) but, when emphasizing a shift of place, purpose, direction, benefit, etc. in the action occurring.",
          sentences: [
            { Kor: "기다리 다 못해  ", Eng: "unable to wait any longer" },
            {
              Kor: "기다리다 못해 ",
              Eng: "waited and then couldn't wait any more ...",
            },
            {
              Kor: "박훈장이 보다 못해 ",
              Eng: "Not being able to sit by idly and watch, Schoolmaster Pak ...",
            },
            {
              Kor: "남편이 놀라다 못해 신기해했다 ",
              Eng: "My husband, not quite capable of mustering his surprise, asked in a bemused way …; or, My husband was so surprised that he began to wonder [why I wanted to travel] ...",
            },
          ],
        },
        {
          entryName: "-다 보니(까) ",
          typicalExample: "【자꾸 이렇게 변명 비슷한 말을 늘어놓다 보니 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-다(가) 보니(까):  if/when one tries doing it over a period of time, then (one discovers).",
          sentences: [
            {
              Kor: "자꾸 이렇게 변명 비슷한 말을 늘어놓다 보니 ",
              Eng: "after trotting forth these lame excuses all the time",
            },
          ],
        },
        {
          entryName: "-다 보면 ",
          typicalExample:
            "【 그래서 부엌으로 데리고 나와 다시 칼질을 하다 보면 어느새  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            " If one does something over a period of time [and then steps back to reflect on it] ;  if you do something for a while, then ... . This pattern uses the (abbreviated) transferentive in -다(가) followed by the verb to see in the conditional form (-(으)면).",
          sentences: [
            {
              Kor: " 그래서 부엌으로 데리고 나와 다시 칼질을 하다 보면 어느새  ",
              Eng: "So after I brought her to the kitchen and started chopping again, the next thing I know …",
            },
            {
              Kor: "꼭꼭 씹다보면 ",
              Eng: "if [Kilsu] chewed real hard for a while ...",
            },
            {
              Kor: "이런 꿈을 꾸다보면 ",
              Eng: "after dreaming this kind of dream ...",
            },
            {
              Kor: "한 달, 두 달, 하다 보면 ",
              Eng: "after one month, two months of [taking care of the prisoners] …",
            },
          ],
        },
        {
          entryName: "-다뿐 ",
          typicalExample:
            "【몸만 떠나왔다 뿐 마음은 오랫동안 몸에 밴 내 나름의 생활의 관습에 얽매인】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "From -다뿐이지, - 다뿐+ copula: [rhetorical] question  it is/was only the case that ...",
          sentences: [
            {
              Kor: "몸만 떠나왔다 뿐 마음은 오랫동안 몸에 밴 내 나름의 생활의 관습에 얽매인",
              Eng: "I had left in body only, and my mind was still bound to those everyday habits which had been growing on me for quite a while now...",
            },
          ],
        },
        {
          entryName: "-다뿐이오?",
          typicalExample: "【암 내다뿐이오. 예서 하룻밤 푹 묵었다 가시구려.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "As a retort, giving back the verb in the original question:  I'll VERB and then some!;  Do you think that's all I'll do? I'll do more!",
          sentences: [
            {
              Kor: "암 내다뿐이오. 예서 하룻밤 푹 묵었다 가시구려.",
              Eng: "But of course, and that's not all, why don't you make yourself at home and stay the night? ",
            },
          ],
        },
        {
          entryName: "-다시피 ",
          typicalExample: "【반은 미치다시피 되어 목을 매 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Usually treated by Korean grammarians as an unanalyzed ending -다사피:  In a way that tends to do,  in a way inclined toward doing,  in a way such that it is possible/likely;  in a way that is virtually in accordance with ,  practically,  virtually,  as much as to do,  almost,  as if to,  like-to.",
          sentences: [
            {
              Kor: "반은 미치다시피 되어 목을 매 ",
              Eng: "half of the men went crazy and hung themselves ",
            },
          ],
        },
        {
          entryName: "-다시피 하-",
          typicalExample: "【개천 건너 빈민촌에 가 살다시피 했다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-다 시피 하- (usually treated by Korean grammarians as an unanalyzed ending  -다사피):  in a way that tends to do,  in a way inclined toward doing,  in a way such that it is possible/likely;  in a way that is virtually in accordance with,  practically,  virtually,  as much as to do,  almost,  as if to,  “like-to”.",
          sentences: [
            {
              Kor: "개천 건너 빈민촌에 가 살다시피 했다.  ",
              Eng: "Chisŏp crossed the stream over to the slum and practically lived there. ",
            },
            {
              Kor: "거의 실성하다시피 한 시어머니 ",
              Eng: "her mother-in-law, who had practically gone insane ...",
            },
            {
              Kor: "버스는 텅텅 비다시피 한채 떠났다 ",
              Eng: "The bus took off in the state of being nearly empty.",
            },
            { Kor: "뛰다시피 해", Eng: "practically running ..." },
          ],
        },
        {
          entryName: "-다 싶다",
          typicalExample: "【 피곤하다 싶으면 영락없이 도진다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-다 싶다, i.e., Plain Style Quote + 싶-:  it feels like,  as if maybe;  think to oneself that ... Note that, while the clause in front of 싶- looks like and feels like a quote, you cannot put a quotative -고 after it.",
          sentences: [
            {
              Kor: " 피곤하다 싶으면 영락없이 도진다.  ",
              Eng: "Whenever I feel tired, it hurts like hell.",
            },
          ],
        },
        {
          entryName: "다오 ",
          typicalExample: "【[Examples?]】",
          categoryKorean:
            "하오체의 종결 어미 Final ending in Semiformal Style  ",
          categoryEnglish:
            "(하오체의 종결 어미 Final ending in Semiformal Style  )",
          translation:
            "The Semiformal Style (하오체—also known as the Blunt Style) indicative assertive of the defective verb 달다 and meaning  please give;  please (for a demand or request to an inferior).",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "다우 ",
          typicalExample: "【한푼이라두 다우 = 한푼이라도 다오】",
          categoryKorean:
            "Substandard/하오체의 종결 어미 Final ending in Semiformal Style  ",
          categoryEnglish:
            "(Substandard/하오체의 종결 어미 Final ending in Semiformal Style  )",
          translation:
            "Substandard for 다오, the Semiformal Style (하오체—also known as the Blunt Style) indicative assertive of the defective verb 달다 and meaning  please give;  please (for a demand or request to an inferior).",
          sentences: [
            {
              Kor: "한푼이라두 다우 = 한푼이라도 다오",
              Eng: "Give me a penny at least, please.",
            },
          ],
        },
        {
          entryName: "-다웁게",
          typicalExample: "【산다웁게】",
          categoryKorean: "Substandard",
          categoryEnglish: "(Substandard)",
          translation:
            "Substandard -다웁게 for -답게  is like,  is worth being,  is worthy of the name,  is becoming to,  is every bit a .... Some dialects reanalyze ㅂ~w verbs by 'cutting' modifier forms like 더운  hot (from 덥다, 더워요) before the -ㄴ: 더우-ㄴ, and then leaving the ㅜ in: 더웁다, etc.",
          sentences: [
            { Kor: "산다웁게", Eng: "as is becoming any mountain ..." },
          ],
        },
        {
          entryName: "-다지(요)?",
          typicalExample: "【강사를 모집한다지요? 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "-다지(요) for -다[고 하]지(요)?  They say that . . .—isn't that right?;  I hear that...— is that so? Thus, this pattern is similar in function to -다면서(요)?",
          sentences: [
            {
              Kor: "강사를 모집한다지요? ",
              Eng: "So I hear they're hiring lecturers?",
            },
            {
              Kor: "도마뱀을 신으로 모시는 부족도 있다지.  ",
              Eng: "They say there are even tribes that worship lizards as gods, you know.",
            },
            {
              Kor: "출세했다지?",
              Eng: "They say he succeeded in life—is that true?",
            },
          ],
        },
        {
          entryName: "-다지 않어 ",
          typicalExample: "【이런 말을 했다지 않어? 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "From -다[고 하-]지 않어  isn't it true/the case that ... said?",
          sentences: [
            {
              Kor: "이런 말을 했다지 않어? ",
              Eng: "Didn't they say she said something like this ...?",
            },
          ],
        },
        {
          entryName: "-다 하나",
          typicalExample: "【비록 많다 하나 】",
          categoryKorean: "관용구/준말 Abbreviation",
          categoryEnglish: "(관용구/준말 Abbreviation)",
          translation:
            "Abbreviated indirect quotation from -다[고] 하나:  They say that, ... but ... ",
          sentences: [
            {
              Kor: "비록 많다 하나 ",
              Eng: "although they say there's lots [of wasteland]",
            },
          ],
        },
        {
          entryName: "-다 한다",
          typicalExample:
            "【한양길이 멀다 하나 일엽서신 없는 뜻은 그대는 모르겠는가.】",
          categoryKorean: "관용구/준말 Abbreviation",
          categoryEnglish: "(관용구/준말 Abbreviation)",
          translation: "Abbreviated from -다[고] 하-  says that QUOTE.",
          sentences: [
            {
              Kor: "한양길이 멀다 하나 일엽서신 없는 뜻은 그대는 모르겠는가.",
              Eng: "[They say] Seoul is a long way from here, but do you still not understand the meaning behind the fact that there have been no letters or any contact from him? ",
            },
          ],
        },
        {
          entryName: "-다 해도",
          typicalExample: "【설사 무슨 변이 있다 해도 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "설사 무슨 변이 있다 해도 ",
              Eng: "even though something awful should happen ...",
            },
          ],
        },
        {
          entryName: "단 ....도 (+ negative)",
          typicalExample: "【단 하루도 쉬지 않았어요. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: " Not even one ... ",
          sentences: [
            {
              Kor: "단 하루도 쉬지 않았어요. ",
              Eng: "She hasn't rested even one day.",
            },
          ],
        },
        {
          entryName: "-단다",
          typicalExample: "【서울 우리 학교에 큰 등나무가 있었단다 】",
          categoryKorean:
            "해라체의 종결 어미 Final ending in Plain Style/준꼴 Contraction",
          categoryEnglish:
            "(해라체의 종결 어미 Final ending in Plain Style/준꼴 Contraction)",
          translation:
            "Abbreviated Plain Style quotations in -단다 ← -다[고 하-]ㄴ다 are used (often by children) as impudent, taunting or proud boasts.",
          sentences: [
            {
              Kor: "서울 우리 학교에 큰 등나무가 있었단다 ",
              Eng: "Our school in Seoul had a big wisteria tree (so there!)",
            },
            {
              Kor: ' "아빠는 너희와 함께 계시지 못한단다"는 말이 불행스러운 느낌을 줄까 봐 ',
              Eng: "for fear that saying \"I'm afraid your daddy can't be with you\" might make them feel unhappy",
            },
          ],
        },
        {
          entryName: "-단 말이다, etc.",
          typicalExample: "【호랑이도 있단 말입니다 】",
          categoryKorean: "관용구/준꼴 Contraction",
          categoryEnglish: "(관용구/준꼴 Contraction)",
          translation:
            "An abbreviated indirect quotation in -단 ← -다[고 하-]ㄴ as modifier to following 말or 말씀  words to the effect that QUOTE, where 말 is followed by a form of the copula (말야 ← 말이야, 말입니다,말이다, etc.), and the whole thing means:  I’m tellin’ya;  what I'm saying is ... ;  I mean,  you know,  you see,  uh,  that is, etc.",
          sentences: [
            {
              Kor: "호랑이도 있단 말입니다 ",
              Eng: "there is also a tiger, I tell you",
            },
            {
              Kor: "아주 폐농이 되었단 말씀이오 ",
              Eng: "[so,] are you saying the village had become a complete ghost town?",
            },
            {
              Kor: "우리 집안을 알고 있단 말예요? ",
              Eng: "You mean to say he knows our household? (Contraction ← 말이에요)",
            },
          ],
        },
        {
          entryName: "-단 말인가?",
          typicalExample: "【하지만 그런들 어떻단 말인가.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The pattern in -단 말이다, etc., in question form, means:  Is that to say ...? =  Do you mean ...?,  Do you mean to say ...?",
          sentences: [
            {
              Kor: "하지만 그런들 어떻단 말인가.  ",
              Eng: "But be that as it may, what can be done about it?",
            },
            {
              Kor: "무슨 흥신소 직원이라도 된단 말인가? ",
              Eng: "Can it mean that he's some sort of detective agency employee?",
            },
            {
              Kor: "무슨 말을 할 수 있단 말인가 ",
              Eng: "I mean, what on earth was I supposed to say [about my father]?",
            },
            {
              Kor: "어떻게 위로하고 부축할 수 있단 말인가 ",
              Eng: "How in the world can anyone ever comfort or support anyone else, I ask you?",
            },
            {
              Kor: "그러나 나는 지금 누구의 머리채를 휘어잡을 수 있단 말인가",
              Eng: "But now whose hank of hair could I possibly grab? ",
            },
          ],
        },
        {
          entryName: "단지",
          typicalExample: "【단지 일자리를 잃었다는 이유만으로 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "The adverb 단지:  only,  merely;  it is just (that), etc., often signals an upcoming pattern with 만 or 뿐.",
          sentences: [
            {
              Kor: "단지 일자리를 잃었다는 이유만으로 ",
              Eng: "merely on the account that I had lost my job ...",
            },
            { Kor: "단지 그거뿐이예요 ", Eng: "That is the only thing." },
          ],
        },
        {
          entryName: "-달 ",
          typicalExample:
            "【열 계집 싫달 사내 기방언약 지킬 사내 있을 것이오. 】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "Contraction of modifier quotation pattern in -다[고 하]ㄹ",
          sentences: [
            {
              Kor: "열 계집 싫달 사내 기방언약 지킬 사내 있을 것이오. ",
              Eng: "There are no men who will refuse ten girls or keep their promises with a kisaeng … (For 싫다고 할.)",
            },
          ],
        },
        {
          entryName: "달다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation: "[Usage]",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "달라",
          typicalExample: "【[Examples?]】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation: "[Usage]",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "달라는",
          typicalExample: "【죽어 달라는 소리 = 죽어 달라고 하는 소리 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Abbreviated indirect quotation from 달라고 하는  asking to give [to oneself].",
          sentences: [
            {
              Kor: "죽어 달라는 소리 = 죽어 달라고 하는 소리 ",
              Eng: "lit.: sounds to the effect of 'Please do me the favor of dying' (as if quoted from 죽어주세요)",
            },
          ],
        },
        {
          entryName: "달래도",
          typicalExample:
            "【기생 갈보 따위가 사글셋방 한 간을 얻어 달래도 녜 녜 하고 따라나서야 하는... ← 얻어 달라고 해도】",
          categoryKorean: "관용구/준꼴 Contraction",
          categoryEnglish: "(관용구/준꼴 Contraction)",
          translation:
            "Abbreviated from 달라[고 ㅎ]ㅐ도:  even though one asks for somebody to do something for oneself.",
          sentences: [
            {
              Kor: "기생 갈보 따위가 사글셋방 한 간을 얻어 달래도 녜 녜 하고 따라나서야 하는... ← 얻어 달라고 해도",
              Eng: 'Even when it was someone as lowly as a kisaeng or prostitute who had asked him to get a room for rent, [he was the kind of person who] was supposed to say "Yes, yes" and accompany them all around…',
            },
          ],
        },
        {
          entryName: "달랬나",
          typicalExample: "【누가 제놈더러 달랬나 】",
          categoryKorean: "관용구/준꼴 Contraction",
          categoryEnglish: "(관용구/준꼴 Contraction)",
          translation:
            "Abbreviated from 달라[고 ㅎ]ㅐ-ㅆ나:  Who?;  One asked for somebody to do something for oneself?",
          sentences: [
            {
              Kor: "누가 제놈더러 달랬나 ",
              Eng: "Since when did anybody [I] ask him to give me a break (a meal)?",
            },
          ],
        },
        {
          entryName: "달리",
          typicalExample: "【제까닥 와이프에게 불평이 가는 남편과 달리 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN과/와 달리:  different from NOUN;  opposite to NOUN;  in contrast to NOUN;  unlike NOUN",
          sentences: [
            {
              Kor: "제까닥 와이프에게 불평이 가는 남편과 달리 ",
              Eng: "completely opposite of this husband who would suddenly start complaining",
            },
          ],
        },
        {
          entryName: "-담?",
          typicalExample: "【심청이 그래야 옳담?】",
          categoryKorean: "해체의 종결 어미 Final ending in Intimate Style",
          categoryEnglish: "(해체의 종결 어미 Final ending in Intimate Style)",
          translation:
            " (Usually to oneself) do you mean to say that ...?;  don't tell me that...!;  really? (shows incredulity, irritation, complaint, or reproach—often of oneself). In origin, probably a contracted quotation from -다[고 하-]ㅁ. Note that this form is always used as a question, and thus with rising intonation at the end.",
          sentences: [
            {
              Kor: "심청이 그래야 옳담?",
              Eng: "Is this some perverse kind of thinking? ",
            },
          ],
        },
        {
          entryName: "-답게",
          typicalExample: "【사람답게 살고 싶대. 】",
          categoryKorean: "Word-formation element",
          categoryEnglish: "(Word-formation element)",
          translation:
            "-답게, the adverbative in -게 of the pattern in NOUN + -답다 (ㅂ~w) meaning  is like,  is worth being,  is worthy of the name,  is to,  is every bit a ... . Thus, -답게 means in a way becoming of a NOUN,  in a quite NOUN-like way, whereas -답지 않게 means  in a way unbecoming of a NOUN,  in a quite un-NOUN-like way.",
          sentences: [
            {
              Kor: "사람답게 살고 싶대. ",
              Eng: "He says he wants to live like a real person.",
            },
          ],
        },
        {
          entryName: "-답니다",
          typicalExample: "【처녀쩍에 군용차두 탔답니다 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Abbreviated indirect quotation from -다고 합니다 meaning either a)  They say it is/does; b)  I say it is/does or  I tell you it is/does; or c)  it really is/does;  it is/does, you see (mind you).",
          sentences: [
            {
              Kor: "처녀쩍에 군용차두 탔답니다 ",
              Eng: "They said that she even rode the military jeeps.",
            },
            {
              Kor: "골절이 됐답니다 ",
              Eng: "The doctor said that the bone was fractured.",
            },
            {
              Kor: "이상한 편지가 왔답니다 ",
              Eng: "A strange letter came [from Seoul].",
            },
            {
              Kor: "인제 막 시작을 했답니다 ",
              Eng: "So I have just started, you see.",
            },
            {
              Kor: "손님을 기다렸답니다 ",
              Eng: "[You see,] I was waiting for a lodger.",
            },
            { Kor: "[Examples?]", Eng: "" },
          ],
        },
        {
          entryName: "-답다, -다워요",
          typicalExample: "【천성이 활달한 여자답게 】",
          categoryKorean: "Word-formation element",
          categoryEnglish: "(Word-formation element)",
          translation:
            "NOUN-다w-:  be like,  be worth being,  be worthy of the name,  be becoming to, be every bit a NOUN.",
          sentences: [
            {
              Kor: "천성이 활달한 여자답게 ",
              Eng: "in the typically cheerful feminine way ...",
            },
            {
              Kor: "시골 사람들답지 않게 ",
              Eng: "in a way that was not becoming to country folk ...",
            },
            {
              Kor: "바다다운 바다 ",
              Eng: "the real ocean ... (an ocean worthy of the name)",
            },
            {
              Kor: "핏제랄드의 팬답지 않게 ",
              Eng: "very much unlike a Fitzgerald fan ...; in a way unbecoming of a Fitzgerald fan",
            },
          ],
        },
        {
          entryName: "-답잖게 ",
          typicalExample: "【연령답잖게 건장한 체구에다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: " In a manner unbecoming of / unfitting for ...",
          sentences: [
            {
              Kor: "연령답잖게 건장한 체구에다 ",
              Eng: "on top of a sturdy physique not typical for his age ...",
            },
          ],
        },
        {
          entryName: "-답지 못하-",
          typicalExample: "【율사답지 못했다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-답지 못하-. NOUN+ -답다 (ㅂ~w) means  is like,  is worth being,  is worthy of the name,  is to,  is every bit a . . . . Thus, -답지 못하다 means  behaves in a way unbecoming of a NOUN,  is quite un-NOUN-like.",
          sentences: [
            {
              Kor: "율사답지 못했다.  ",
              Eng: "it wasn't at all becoming of a lawyear; or, it didn't suit a lawyer at all",
            },
          ],
        },
        {
          entryName: "-답지 않게",
          typicalExample: "【가을답지 않게 몹시 추웠고  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-답지 않게. NOUN + -답다, -다워요 means  is like,  is worth being,  is worthy of the name,  is to,  is every bit a ... . Thus, NOUN + -답지 않게 means  in a way unbecoming of a NOUN,  in a quite un-NOUN-like way.",
          sentences: [
            {
              Kor: "가을답지 않게 몹시 추웠고  ",
              Eng: "it was incredibly cold—very unlike autumn …",
            },
            {
              Kor: "그녀답지 않게 웬 신랄함일까?  ",
              Eng: "What's with the seriousness, so unlike her?",
            },
          ],
        },
        {
          entryName: "당신",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Pronoun",
          categoryEnglish: "(Pronoun)",
          translation:
            "The pronoun 당신 has two main functions: a) as a second-person pronoun ('you'), used either between spouses or in somewhat stiff, awkward and/or tense exchanges where the Korean interlocutors know nothing about each other, and thus have no other titles or names to fall back on. This usage includes that of 'foreigner talk'  second-person pronoun reserved for use with foreign learners of Korean. b) as an honorific reflexive pronoun ( 'himself, herself'), and thus as the highest in the three-way system of 저 (lowest), 자기 (plain) and 당신 (honorific). ",
          sentences: [
            { Kor: "[Examples?]", Eng: "" },
            {
              Kor: "당신 임의로는 못하시는 일 ",
              Eng: "something which she herself is unable to control",
            },
          ],
        },
        {
          entryName: "당한다",
          typicalExample:
            "【나중엔 날 수가 없게 되어 모조리 잡혀 멸종당했다.  】",
          categoryKorean: "Word-formation element",
          categoryEnglish: "(Word-formation element)",
          translation:
            "Used to make passives with verbal nouns in the pattern VN(을) + 당한다  suffers,  undergoes,  sustains,  has to put up with,  is afflicted with,  faces,  has to face,  has something unpleasant happen to one;  be on the suffering end of ... ;  get nailed with ... , etc. Typically with Sino-Korean verbal nouns. E.g., 부친 상을 당하-  suffer the death of one's father; 거절(을) 당하-  be refused/rejected; 습격(을) 당하-  be/get raided, etc. ",
          sentences: [
            {
              Kor: "나중엔 날 수가 없게 되어 모조리 잡혀 멸종당했다.  ",
              Eng: "Later [the birds] lost the ability to fly and became extinct after they were all caught.",
            },
            {
              Kor: "무안만 당하고 말았지 ",
              Eng: "I ended up being disgraced ...",
            },
            {
              Kor: "문책을 당하였다 ",
              Eng: "[He would be called in to Mrs. B’s] for a scolding.",
            },
            {
              Kor: "유배당해 버리고 없었다 ",
              Eng: "… had suffered a complete banishment and was not there.",
            },
            {
              Kor: "한마디로 거절당하자 ",
              Eng: "suffering rejection in a single word ...",
            },
          ],
        },
        {
          entryName: "-대(요)",
          typicalExample: "【사람답게 살고 싶대.】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Contracted indirect quotation from -다[고 ㅎ]애(요). The ending in -대(요) attaches to descriptive bases, future bases and past bases.",
          sentences: [
            {
              Kor: "사람답게 살고 싶대.",
              Eng: "he says he wants to live like a real person",
            },
            {
              Kor: "아이 아빠가 고수부지에 버렸대 ",
              Eng: "They said that the child's father had abandoned it on the terraced land on the river.",
            },
            {
              Kor: '“집 나온 지 일 년이 넘었대." ',
              Eng: "They said it had been a year since she left home.",
            },
            {
              Kor: '"동구 씨는 결혼했대요? 민석 씨네는 이제 아기 있겠네?"',
              Eng: '"Did they say Tonggu got married? Minsŏk and his wife must have a baby by now, yes?" ',
            },
            {
              Kor: "고급 아파트들 인기가 떨어진다고 하대? ",
              Eng: "Do they say that expensive apartments are losing their popularity? ",
            },
            {
              Kor: '"거기 가서는 뭐 주인 행세하고 살 수 있대요?" ',
              Eng: '"Does he say he can go over there and live life as his own boss?"',
            },
            {
              Kor: '"엄마, 저 결혼해야겠어요. 지수가 애를 가졌대요." ',
              Eng: "\"Mom, I'm going to have to get married. Chisu says she's pregnant.\"",
            },
            {
              Kor: '"시골에서 저희 어머니가 올라왔대요." ',
              Eng: '"She says her mother came up from the countryside."',
            },
            {
              Kor: "그리 오르진 않았대 ← 그리 오르지(는) 않았다고 해",
              Eng: "They say it hasn't gone up that much.",
            },
            {
              Kor: "돌아 버렸대 ",
              Eng: "She just turned around and came back, they said.",
            },
          ],
        },
        {
          entryName: "-대도",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Abbreviated quotation from  -다[고 ㅎ]ㅐ도: even though one says or claims that …",
          sentences: [
            { Kor: "[Examples?]", Eng: "" },
            { Kor: "[Examples?]", Eng: "" },
            { Kor: "[Examples?]", Eng: "" },
          ],
        },
        {
          entryName: "대로",
          typicalExample: "【그의 호릿하고 가냘픈 외형대로】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "NOUN + postnoun 대로: as, just as, just as it is (was); with something still as it was; in accordance with, in proportion with NOUN.",
          sentences: [
            {
              Kor: "그의 호릿하고 가냘픈 외형대로",
              Eng: "just like her tall and slender appearance ...",
            },
            {
              Kor: "그 길로 지나간 사람 수효대로 ",
              Eng: "according to the number of people who trod on the soil ...",
            },
            {
              Kor: "턱은 턱대로 떨리고 혀는 혀대로 춤을 추었지 ",
              Eng: "His chin trembled one way, his tongue danced the other. (lit.: His chin trembled in its own chin-like way, and his tongue, for its part, danced its own little dance.)",
            },
            { Kor: "별명대로", Eng: "just like his nickname ..." },
            { Kor: "하씨 말대로  ", Eng: "just like Mr. Ha had said ..." },
            {
              Kor: "나의 예상대로 ",
              Eng: "according to my expectations / just as I predicted …",
            },
            { Kor: "그의 짐작대로 ", Eng: "according to her guess ..." },
            {
              Kor: "부분대로 하였읍니다 ",
              Eng: "… did it according to their section ...",
            },
            {
              Kor: "춘향은 춘향대로 그 말을 풀이했다.",
              Eng: "Ch'unhyang understood those words the way Ch'unhyang would.",
            },
            {
              Kor: "향단은 향단이대로 늦도록 앉아 있었다.",
              Eng: "Hyangdan stayed up late sitting the way Hyangdan would.",
            },
            {
              Kor: "일정대로 남편 일행은 뱃길로 한 시간 거리라는 산호섬으로 떠난 참이었다. ",
              Eng: "According to the schedule/itinerary, by then her husband and his company had left for the Coral Islands, supposedly an hour away on ship from here.",
            },
          ],
        },
        {
          entryName: "~ 대로의 NOUN",
          typicalExample: "【나는 나대로의 딴 희망】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN1대로의 NOUN2 meaning a NOUN2 specific or peculiar to to NOUN1. ",
          sentences: [
            {
              Kor: "나는 나대로의 딴 희망",
              Eng: "as for me, my own different hope ...",
            },
          ],
        },
        {
          entryName: "-대서",
          typicalExample: "【전에 많이들 행악을 했대서? 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "From -다[고 ㅎ]ㅐ서: (just) because somebody had said ...",
          sentences: [
            {
              Kor: "전에 많이들 행악을 했대서? ",
              Eng: "Just because they said we had acted terribly in the past?",
            },
          ],
        },
        {
          entryName: "-대서인지 ",
          typicalExample: "【나라를 도로 찾았대서인지 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "From -다고 해서 인지: whether/ perhaps because somebody had said ...",
          sentences: [
            {
              Kor: "나라를 도로 찾았대서인지 ",
              Eng: "whether perhaps because [someone said] that they got their country back ...",
            },
            {
              Kor: "돌아왔대서인지",
              Eng: "or perhaps because [someone said that their two grandsons] had returned ...",
            },
          ],
        },
        {
          entryName: "대신(에)",
          typicalExample: "【대답 대신 우리 집안을 들먹였다 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "NOUN 대신(에): instead of ..., in place/lieu ...; while on the one hand ...",
          sentences: [
            {
              Kor: "대답 대신 우리 집안을 들먹였다 ",
              Eng: "Instead of responding, he checked out our house.",
            },
            {
              Kor: "좀 전의 악의 대신 ",
              Eng: "instead of the ill-will of a moment before ...",
            },
            {
              Kor: "위엄과 신비 대신에 ",
              Eng: "instead of dignity and mystery …",
            },
            { Kor: "풍덩 소리 대신 ", Eng: "instead of a splashing sound …" },
          ],
        },
        {
          entryName: "-대죠",
          typicalExample: '【“아이는 죽었대죠?" 】',
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation:
            "-대죠, colloquial readjustment of -다죠 → 다지요 → 다[고 하]지요: It's the case that they that QUOTE, right? or I guess they say that QUOTE, eh? (if you're from Canada). ",
          sentences: [
            {
              Kor: '“아이는 죽었대죠?" ',
              Eng: "“They say that the child died, right?”",
            },
          ],
        },
        {
          entryName: "대체 ",
          typicalExample: "【대체 이게 무슨 소리야. 】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation:
            "대체 plus Question word—variant of 도대체 plus question word +...-기에/-길래: WH- on earth is/does it (such that...).",
          sentences: [
            {
              Kor: "대체 이게 무슨 소리야. ",
              Eng: "What on earth is this all about?",
            },
          ],
        },
        {
          entryName: "댁에 ",
          typicalExample: "【댁에 괜찮은 사내야 】",
          categoryKorean: "Pronoun",
          categoryEnglish: "(Pronoun)",
          translation: "Substandard second-person pronoun ('you').",
          sentences: [
            { Kor: "댁에 괜찮은 사내야 ", Eng: "You ...you're an OK fella." },
            { Kor: "댁에 때문에 ", Eng: "because of you ..." },
            { Kor: "댁에 같은 ", Eng: "a man like you ..." },
          ],
        },
        {
          entryName: "-댄다",
          typicalExample: "【부르릉대며】",
          categoryKorean: "Word-formation element",
          categoryEnglish: "(Word-formation element)",
          translation:
            "Base (usually mimetic) + -댄다 has more or less the same effect as mimetic + -거린다, with the minor difference that -댄다 tends to imply a more ad nauseum or continuous repeating of the mimetic: does terribly, awfully, a lot, like crazy; does repeatedly/continuously; does and does; keeps doing, does on and on, does (keeps doing) it away; does again and again, does over and over again.",
          sentences: [
            { Kor: "부르릉대며", Eng: "wheezing constantly ..." },
            { Kor: "투털댄 것도 ", Eng: "I mumbled over and over ..." },
            {
              Kor: "영달이가 투덜대자 정씨가 말했다 ",
              Eng: "As soon as Yŏngdal whined, Mr. Chŏng said ...",
            },
            {
              Kor: "노래를 흥얼대다가 ",
              Eng: "he was humming a tune, when ...",
            },
            { Kor: "재잘대는 소리", Eng: "the sounds of chattering" },
            {
              Kor: "낑낑댄다는 것이 우습게 생각되었다 ",
              Eng: "the way people went on groaning and complaining seemed silly",
            },
            {
              Kor: "쫑쫑대고 쌩동거리고 하던 ",
              Eng: "muttered on and on and was full of life...",
            },
            {
              Kor: "찔끔대며 웃어댔다 ",
              Eng: "… laughed with tears in our eyes.",
            },
            {
              Kor: "그 쪽 기가 펄럭대고 있었으니 말이다 ",
              Eng: "I mean, their side's flag was still fluttering.",
            },
            {
              Kor: "얼마나 남겠다고 저렇게 굽신대나 싶어 ",
              Eng: 'lit: I wondered to myself, "How much will she make such that she\'s being so subservient?"',
            },
            {
              Kor: "고개를 욕조에 깊이 처박고는 엉덩이를 들썩대며  ",
              Eng: "as I stick my head deep in the bathtub and move my hips up and down …",
            },
            {
              Kor: "칭얼대고 보챌 때마다 참기름이며 달걀이 묻은 손을 씻고  ",
              Eng: "Every time she fussed and whined, I washed my hands that were stained with sesame oil and egg, and …",
            },
            { Kor: "나는 혼자 중얼대며  ", Eng: "I mumbled to myself …" },
            {
              Kor: "원시 부족의 타악기 연주가 내 귀를 꽝꽝대며 울린다.  ",
              Eng: "The sounds of a primitive percussion performance boomed and reverberated in my ears.",
            },
            {
              Kor: "남자를 흘끔거리며 투덜대는 일행에게 남편이 말했었다.  ",
              Eng: "Her husband said to his pals who were grumbling and glaring at the man.",
            },
            {
              Kor: "무거운 머리를 들지 못해 흐느적대던 아침.  ",
              Eng: "Mornings when she floundered in bed because she couldn’t raise her heavy head.",
            },
            {
              Kor: "아이들은 열심히 무릎을 비비대었다.  ",
              Eng: "The [boys and girls] were busy rubbing knees with each other.",
            },
          ],
        },
        {
          entryName: "댐에",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Dialect morphology/Substandard ",
          categoryEnglish: "(Dialect morphology/Substandard )",
          translation: "Dialect/substandard 댐에 for 다음에 after.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-댔나",
          typicalExample: "【님자도 들어갔댔나? 】",
          categoryKorean: "종결 어미 Final ending/준꼴 Contraction",
          categoryEnglish: "(종결 어미 Final ending/준꼴 Contraction)",
          translation:
            "The ending in -댔나 is a contracted quotation from -다[고 ㅎ]ㅐ-ㅆ나?; lit.: did you/somebody say that...?",
          sentences: [
            {
              Kor: "님자도 들어갔댔나? ",
              Eng: "Did you say that you’ve been to (his place), too?",
            },
          ],
        },
        {
          entryName: "-댔쉐까",
          typicalExample: "【형님이댔쉐까?형님도 들어갔댔쉐까?】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            "Dialect morphology. The ending in -댔쉐까 looks like it might be for a retrospective form akin to -더소이다 ~ -더소이까.",
          sentences: [
            {
              Kor: "형님이댔쉐까?형님도 들어갔댔쉐까?",
              Eng: "Is that you ['sister']? Have you been making house calls, too? (NB: 형님 here is for [older] sister.)",
            },
          ],
        },
        {
          entryName: "-더구먼(요) ",
          typicalExample: "【인기가 대단하더구먼】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Retrospective apperceptive. Apperceptives carry the meaning of 'first realization': now that I look back on/think about it, I realize ...",
          sentences: [
            {
              Kor: "인기가 대단하더구먼",
              Eng: "… were terribly popular (as I recall) ",
            },
          ],
        },
        {
          entryName: "-더군(요)",
          typicalExample: "【사람은 누구나 혼자라고 그러더군요 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Retrospective apperceptive: now that I look back on/think about it, I realize ...",
          sentences: [
            {
              Kor: "사람은 누구나 혼자라고 그러더군요 ",
              Eng: '[What do you mean "alone"?] Now that I think about it, we\'re all alone in this life. ',
            },
            {
              Kor: "야 그 사람 환장한 모양이더군 ",
              Eng: "Boy, the husband looked like he had gone berserk.",
            },
          ],
        },
        {
          entryName: "-더냐",
          typicalExample: "【오늘은 어떻더냐? 】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "Retrospective Plain Style question in -더냐. Sometimes also encountered as -드냐. ",
          sentences: [{ Kor: "오늘은 어떻더냐? ", Eng: "How'd it go today?" }],
        },
        {
          entryName: "-더니",
          typicalExample: "【나에게 버스값을 재촉하더니 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Retrospective sequential –더니: as now it has been observed that ...; when (now/then), since (now/then) ...; ... and now/then; ... but now/then. The form in -더니 usually implies a marked or abrupt contrast between the first and second clauses: whereupon. Often it highlights a startling close-up, with the effect of 'zoom in' cinematic shot. 'Standard Korean' requires that use of -더니 on past bases be reserved for first-person statements, but it is easy to find exceptions to this 'rule'. You will sometimes encounter this as -드니. ",
          sentences: [
            {
              Kor: "나에게 버스값을 재촉하더니 ",
              Eng: "She demanded the bus fare from me [whereupon she up and announced we'd arrived at the lake and pushed me out].",
            },
            {
              Kor: "장이 뛰어 내리더니 ",
              Eng: "The bus conductor jumped out of the bus [whereupon she threw me onto the bus as if I were a piece of luggage].",
            },
            {
              Kor: "포대기 밑에 손을 넣어 보더니 ",
              Eng: "Ater she put her hand under the quilt [she was at a loss, saying that the wind from outside was quite harsh ...]",
            },
            {
              Kor: "스웨터 주머니에 넣고 나더니 ",
              Eng: "She placed the money in her pocket [after which / whereupon ...]",
            },
            {
              Kor: "몇 마디 테스트를 하더니 ",
              Eng: "… took a test with just a few words on it, whereupon ... ",
            },
            { Kor: "조금 더 자라더니 ", Eng: "when he got a little older ..." },
            {
              Kor: "철그럭 하더니 ",
              Eng: "there was a clinking sound, whereupon ...",
            },
            {
              Kor: "한참 모자가 울더니 ",
              Eng: "after mother and son wept for a while …",
            },
            {
              Kor: "기다리라는 뜻을 표하더니 ",
              Eng: "Mr. Yi motioned for me to wait, whereupon ...",
            },
            { Kor: "절을 하더니 ", Eng: "bowing, she ..." },
            {
              Kor: "근심 걱정으로 짐이 무겁더니 ",
              Eng: "I was weighted down with many worries [but now] ...",
            },
            {
              Kor: "그가 둑 위로 올라서더니 ",
              Eng: "he went up and stood over the dike, whereupon he ...",
            },
            { Kor: "그가 멈춰서더니 ", Eng: "he stopped, and then ..." },
            {
              Kor: "눈발이 날리기 시작하더니 (삼포 14)",
              Eng: "lit.: snowflakes started flying, whereupon ...",
            },
            {
              Kor: "여자가 휙 돌아서더니 ",
              Eng: "the woman suddenly spun around, whereupon she ...",
            },
            {
              Kor: "어제까지는 개울 기슭에서 하더니 ",
              Eng: "up until yesterday she had done it just at the edge of the stream, but [now/today]…",
            },
            {
              Kor: "한참 세수를 하고 나더니 ",
              Eng: "after washing her face for quite some time [she suddenly ...]",
            },
            {
              Kor: "수숫단 속을 비집어 보더니 ",
              Eng: "spread open the inside of the sorghum sheaf, whereupon / and then ...",
            },
            {
              Kor: "눈을 똥그랗게 뜨더니 ",
              Eng: "opening her eyes wide (round) ...",
            },
            {
              Kor: "울 듯한 상이더니 ",
              Eng: "made a face as if she were about to cry ...",
            },
            {
              Kor: "한참을 말이 없더니 ",
              Eng: "said nothing for a time and then...",
            },
            {
              Kor: "조심스럽게 수근거리더니 ",
              Eng: "[the darkness] was whispering cautiously, and then ...",
            },
            {
              Kor: "일체 호랑이 얘길 않더니 ",
              Eng: "not saying a word about tigers, now ...",
            },
            {
              Kor: "눈여겨 살피더니 말을 걸어왔다 ",
              Eng: "… took a closer look at them and then began to talk.",
            },
            {
              Kor: "거리가 비는가 싶더니 ",
              Eng: "just when you thought the streets were becoming empty [suddenly/unexpectedly …]",
            },
            {
              Kor: "안 죽을 줄 알았더니 ",
              Eng: "I was sure that she wouldn't die, but ...",
            },
            {
              Kor: "그렇지만 뒷조사를 해보았더니 ",
              Eng: "when I did a little research, I found that ...",
            },
            {
              Kor: "법률책 좀 붙들고 앉아 있었더니",
              Eng: "sitting down with the law books and now ...",
            },
            {
              Kor: "잠깐 눈을 붙였더니 더욱 피로해졌던 것이다 ",
              Eng: "They took a snooze for a short while, after which they became even more exhausted.",
            },
            {
              Kor: "데릴사위를 들였더니 ",
              Eng: "[his only daughter] had moved in with his son-in-law and then ...",
            },
            {
              Kor: "하인에게 물어 봤더니 ",
              Eng: "… asked the servant, whereupon ...",
            },
            {
              Kor: "이튿날 출근을 하였더니 ",
              Eng: "showed up for work two days later, whereupon ...(In theory, the use of -더니 here on a past base throws the point of view on to the first person i.e., Maeng's point of view.)",
            },
          ],
        },
        {
          entryName: "-더니만 ",
          typicalExample: "【첫마디를 걸더니만 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "And NOW (or THEN); ...but NOW (or THEN) [implying a contrast or shift]. Built on -더니 as now it has been observed that...; when (now or then)..., since (now or then)...; ...and now (or then); ...but now (or then) + particle 만 but, however. The addition of particle 만 seems to intensify the abruptness and intensity of the 'zoom in' effect of just -더니. 'Standard Korean' requires that use of -더니만 on past bases is be reserved for first-person statements, but it is easy to find exceptions to this 'rule'. You will sometimes encounter this as  -드니만. ",
          sentences: [
            {
              Kor: "첫마디를 걸더니만 ",
              Eng: "He was the first to initiate a conversation, but [the Japanese didn't really engage]",
            },
            {
              Kor: "괴로운 한숨을 쉬더니만 ",
              Eng: "he sighed a pained sigh, and then/whereupon …",
            },
            {
              Kor: "다 건너가더니만 ",
              Eng: "… went all the way across [whereupon …]",
            },
            {
              Kor: "콩볶듯이 나더니만 ",
              Eng: "[the sound of gunfire] sounded like firecrackers going off, whereupon ...",
            },
            { Kor: "[Examples?]", Eng: "" },
          ],
        },
        {
          entryName: "-더라고 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation: "[Usage]",
          sentences: [
            { Kor: "[Examples?]", Eng: "" },
            { Kor: "[Examples?]", Eng: "" },
          ],
        },
        {
          entryName: "-더라고 그런다",
          typicalExample:
            '【"이 나라에 남은 마지막 미련까지 사라지더라고 그러드만." 】',
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-더라고 그런다: says (그러-) that one recalls or otherwise has good but not great evidence that ... . ",
          sentences: [
            {
              Kor: '"이 나라에 남은 마지막 미련까지 사라지더라고 그러드만." ',
              Eng: '"I think what he said was something like even his last remaining affections for this country had disappeared."',
            },
          ],
        },
        {
          entryName: "-더라는 ",
          typicalExample:
            "【그가 저녁에 오겠다고 하더라는 얘기를 이모가 내게 했다.】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contracted from an indirect quotation in -더라(고 하)는. ",
          sentences: [
            {
              Kor: "그가 저녁에 오겠다고 하더라는 얘기를 이모가 내게 했다.",
              Eng: "My aunt told me that she recalled he'd said he would come that evening.",
            },
          ],
        },
        {
          entryName: "-더라는 것이다 ",
          typicalExample: "【꼰대는 영 헬렐레더라는 것이다 】",
          categoryKorean: "관용구/준꼴 Contraction",
          categoryEnglish: "(관용구/준꼴 Contraction)",
          translation:
            "Contracted from -더라(고 하)는 것이다: I say it is a case of having done/been or I tell you it was/did = it really was/did; it was/did, you see.",
          sentences: [
            {
              Kor: "꼰대는 영 헬렐레더라는 것이다 ",
              Eng: "[According to Pindaettŏk] in the presence of Madam Kang, Kkondae was absolutely powerless. (NB: the verb here is the copula -이다.)",
            },
          ],
        },
        {
          entryName: "-더라는데 ",
          typicalExample: "【웃더라는데】",
          categoryKorean: "연결어미 Connective ending/준꼴 Contraction",
          categoryEnglish: "(연결어미 Connective ending/준꼴 Contraction)",
          translation:
            "Contracted from -더라[고 하]는데, i.e., a quotation of the retrospective assertive -더라: it has been observed that..., it is known that ..., as we (or I) know, I hear (have been told) that ..., I noticed that ..., I've discovered (found out) that ...",
          sentences: [
            { Kor: "웃더라는데", Eng: "they said that [she] was smiling ..." },
          ],
        },
        {
          entryName: "-더라니(까) ",
          typicalExample: "【“탯줄을 한 손에 잡고 왔더라니깐.”  】",
          categoryKorean: "해체의 종결 어미 Final ending in Intimate Style",
          categoryEnglish: "(해체의 종결 어미 Final ending in Intimate Style)",
          translation:
            "-더라니까(는) grammaticalized from -더라[고 하]니까:  I say it is/does or I tell you it is/ does = it really is/does (I insist/reiterate!) ; it is/does, you see [based on past experiences or other evidence I have] . The final 는 has been abbreviated to just ‘ㄴ.",
          sentences: [
            {
              Kor: "“탯줄을 한 손에 잡고 왔더라니깐.”  ",
              Eng: "“I‘m telling you—she was holding the umbilical cord in one hand.”",
            },
            {
              Kor: '"어쩐지 예감이 이상하더라니" ',
              Eng: "“I'm telling you, for some reason I had a feeling [about this girl] ...”",
            },
          ],
        },
        {
          entryName: "-더라도 ",
          typicalExample:
            "【설령 그녀에게 무언가 강한 의사 표현을 해야 할 때가 오더라도  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Even though (it has been observed that ...); even granted/supposing that. Most of the time, the retrospective sense of the -더- is completely bleached out and this pattern serves simply as a colloquial and/or more intense equivalent of just -어도.",
          sentences: [
            {
              Kor: "설령 그녀에게 무언가 강한 의사 표현을 해야 할 때가 오더라도  ",
              Eng: "even if the time should come when I have to express some opinion to her forcefully … ",
            },
            {
              Kor: "어디가 어떻게 잘못된 아이가 태어나더라도  ",
              Eng: "even should a child be born that was somehow in some way deformed …",
            },
            {
              Kor: "허랑한 학생 아니더라도 ",
              Eng: "even though he isn't some loose student ...",
            },
            {
              Kor: "일 년 안에 청장을 하더라도 ",
              Eng: "even should they settle accounts within the year …",
            },
            {
              Kor: "더위를 팔더라도 ",
              Eng: "despite the selling of the heat ...",
            },
            {
              Kor: "도중에 헤어지게 되더라도 ",
              Eng: "even if they get separated on the way ...",
            },
            {
              Kor: "암만 즈 아버지가 반대를 하더라도 ",
              Eng: "even if her father should oppose ... (Note also 즈 for 저의 = 자기의.)",
            },
            {
              Kor: "설혹 마음이 없더라도 ",
              Eng: "even if she is no longer interested in me ...",
            },
            {
              Kor: "하기야 청첩장을 보냈더라도 ",
              Eng: "anyway, even if you had sent an invitation ...",
            },
          ],
        },
        {
          entryName: "더러",
          typicalExample:
            "【날더러 단 만 원이라도 자본을 운동하면= 나(를) 더러단 만 원이라도 자본을 운동하면 】",
          categoryKorean: "부사격 조사 Particle ",
          categoryEnglish: "(부사격 조사 Particle )",
          translation:
            "Particle 더러 (orders, tells, shows) to (an inferior). This particle optionally allows a preceding pronoun to take the object marker: e.g., 날더러 to me. This is a lingering reflection of this particle's verbal origin, as it comes from the infinitive of the old form of the verb 데리다 (데려) is attended/accompanied by.",
          sentences: [
            {
              Kor: "날더러 단 만 원이라도 자본을 운동하면= 나(를) 더러단 만 원이라도 자본을 운동하면 ",
              Eng: "… said that if I could make interest with even just ten-dollar as my capital …",
            },
            {
              Kor: "소녀더러 병이 좀 낫거들랑 이사 가기 전에 한번 개울가로 나와 달라는 말을 못 해둔 것이었다.",
              Eng: "… [had forgotten to ask] the girl to come out to the streamside again once she was feeling better, before she moved away.",
            },
            {
              Kor: "해수병쟁이 영감더러 밤새도록 기침을 당나귀처럼 하니",
              Eng: "… [said] to the man with consumption that he coughed like a donkey all night ",
            },
            {
              Kor: "바우더러는 돌아왔구나 하며 ",
              Eng: '[She said] to Pau, "So you\'re back!"',
            },
            {
              Kor: "누가 제놈더러 달랬나 ",
              Eng: "Since when did I ask him to give me a meal?",
            },
            {
              Kor: "술이 나더러 여편네 아니라고 한다.  ",
              Eng: "The alcohol tells me I'm not a wife.",
            },
          ],
        },
        {
          entryName: "-더마",
          typicalExample: "【개 한 마리도 얼씬을 않더마 】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            "Dialect -더마 for standard -더만 ~ -더먼 or -드만 ~-드먼 with the same effect as -더구만 ~ -드구먼, i.e., a retrospective 'first realization' (Martin's 'apperceptive') form. The meaning is something like Now that I think back on it, gosh! (All our examples are from 현진건's \"고향,\" and appear to be in a form of North Kyŏngsang dialect.) ",
          sentences: [
            {
              Kor: "개 한 마리도 얼씬을 않더마 ",
              Eng: "[as I recall] not even a dog showed its face",
            },
            { Kor: "아무것도 없더마 ", Eng: "[as I recall] there was nothing" },
            {
              Kor: "고향이 통 없어졌더마 ",
              Eng: "[as I recall] the town had completely disappeared",
            },
            {
              Kor: "암만 찾아도 못 찾겠더마 ",
              Eng: "search as I might, I couldn't find it [as I recall]",
            },
          ],
        },
        {
          entryName: "더불어",
          typicalExample:
            "【초조와 더불어 연방 그런 구멍을 여새겨 보았었다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN과/와 더불어: together with NOUN, along with NOUN.",
          sentences: [
            {
              Kor: "초조와 더불어 연방 그런 구멍을 여새겨 보았었다. ",
              Eng: "Together with fretting … re-worked that possibility over and over again. ",
            },
            {
              Kor: "소리와 더불어 한 장한이 척 들어섰다. ",
              Eng: "Together with the sound, a strapping giant stepped inside. ",
            },
            {
              Kor: "일인(日人) 포수와 더불어 ",
              Eng: "together with a Japanese hunter ...",
            },
          ],
        },
        {
          entryName: "덕분에",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN덕분에 meaning thanks to NOUN.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "덕분이다",
          typicalExample: "【술 덕분이었을 것이다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN덕분이다 is thanks to NOUN, is owing to NOUN. Cf. the closely related NOUN덕택으로 and NOUN덕분에 thanks to NOUN, and NOUN덕이다 is thanks  to NOUN",
          sentences: [
            {
              Kor: "술 덕분이었을 것이다.  ",
              Eng: "It must have been because of ['thanks to'] the alcohol.",
            },
          ],
        },
        {
          entryName: "~ 덕(을) 본다",
          typicalExample: "【추워진 날씨 덕을 본 셈이다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN(의) 덕(을) 보-: to reap the benefits of NOUN.",
          sentences: [
            {
              Kor: "추워진 날씨 덕을 본 셈이다",
              Eng: "The cold turn in the weather was paying off.",
            },
          ],
        },
        {
          entryName: "덕이다",
          typicalExample:
            "【그녀가 나를 택한 것은 솔직히 나의 과감한 감투(敢鬪) 덕이다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN덕이다 is thanks to NOUN, is owing to NOUN. Cf. the closely related NOUN덕택으로 and NOUN덕분에 thanks to NOUN. ",
          sentences: [
            {
              Kor: "그녀가 나를 택한 것은 솔직히 나의 과감한 감투(敢鬪) 덕이다.  ",
              Eng: "The reason she had chosen me was frankly because of the bold fight I had put up.",
            },
            {
              Kor: "내 장사 수완 덕이지 뭐 ",
              Eng: "This business owes a lot to my resourcefulness, you know.",
            },
          ],
        },
        {
          entryName: "덕택으로",
          typicalExample: "【일본말 덕택으로 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN덕택으로: thanks to NOUN.",
          sentences: [
            {
              Kor: "일본말 덕택으로 ",
              Eng: "thanks to the [few words of] Japanese [she had learned] …",
            },
          ],
        },
        {
          entryName: "-던",
          typicalExample: "【물을 마시려고 자리에서 몸을 일으키던 나는 】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "-던 ... that has been observed to (be or do); ... that has been doing, ... that used to do; ... that was, ... that used to be; which used to ... ; which was in the habit of ... On a past base (-았던) the effect is to lend a more 'remote past' sense to the construction. Note that this is sometimes heard (and—in older texts—written) as -든.",
          sentences: [
            {
              Kor: "물을 마시려고 자리에서 몸을 일으키던 나는 ",
              Eng: "Just as I was about to get up to get a drink of water, I ...",
            },
            {
              Kor: "조바심으로 잠들지 못하던 지수 ",
              Eng: "Chisu, who was unable to sleep because of her fretfulness …",
            },
            {
              Kor: "멀건 스프와 마른 빵 일색이던 식탁 ",
              Eng: "their supper, which had consisted of the same old clear broth and dried bread",
            },
            {
              Kor: "그가 열 일곱 살 되던 해 ",
              Eng: "the year in which he [as I heard] turned 17 ",
            },
            {
              Kor: "오륙 전 하던 땅이 ",
              Eng: "land which had used to be worth 5 or 6 chŏn",
            },
            {
              Kor: "말만 듣던 그 안경화무용연구소 ",
              Eng: "the An Kyŏnghwa Dance Institute, of which he had heard before [but never seen—he had only ever heard of it] (since the protagonist had heard of the institute on more than one occasion, the author used -던 rather than -(으)ㄴ) ",
            },
            {
              Kor: "아무도 그런 소식을 전하기를 꺼려했던 것이다 ",
              Eng: "… shunned whomever spread that news.",
            },
          ],
        },
        {
          entryName: "-던가",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Modifier [-(으)ㄴ, -던, -었던, -었는, -는] + postmodifier 가: the question of/whether. ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-던가를",
          typicalExample: "【양씨가 왜 그리 나를 반기던가를 생각했다 】",
          categoryKorean: "Object",
          categoryEnglish: "(Object)",
          translation: "Question -던가 as object: wondered why ...",
          sentences: [
            {
              Kor: "양씨가 왜 그리 나를 반기던가를 생각했다 ",
              Eng: "I wondered why Mr. Yang was so happy to see me.",
            },
          ],
        },
        {
          entryName: "-던가 보다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier [-(으)ㄴ, -던, -었던, -었는, -는] + postmodifier 가 the question of/whether + 는 (i.e., the whole question as topic) + 보다 it seems, looks like.",
          sentences: [
            { Kor: "[Examples?]", Eng: "" },
            {
              Kor: "그옷이 그 여자의 맘에 든 옷이었던가 보다 ",
              Eng: "either that] or it seemed she fancied those clothes",
            },
          ],
        },
        {
          entryName: "-던가(요) ",
          typicalExample: "【아, 무슨 대학 운동선수라 했던가.  】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "-던가(요) retrospective question, often asked aloud of oneself without the 요: what had been the case ... I wonder?",
          sentences: [
            {
              Kor: "아, 무슨 대학 운동선수라 했던가.  ",
              Eng: "Oh—what sport was it that he was playing in college?",
            },
          ],
        },
        {
          entryName: "-던가 한다",
          typicalExample: "【여자에게 아이가 있었던가 하듯 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Here 하- is acting as a quoting verb: as if thinking/saying to oneself.",
          sentences: [
            {
              Kor: "여자에게 아이가 있었던가 하듯 ",
              Eng: "seemed to be wondering if the kid was the lady's",
            },
          ],
        },
        {
          entryName: "-던 걸(요)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            'This is an example of what Martin calls the use of the accusative to "mark an antithetical clause that ends in a modifier + 것." The meaning is contrary to what you might expect or think; despite contrary anticipations/reservations; although, but; (it\'s) too bad (that ...); in spite of the fact that ... ; anyway, so there! ',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-던 것이 기억(이) 난다",
          typicalExample: "【어른들이 어린 내게 속삭이던 게 기억난다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "One recalls/remembers somebody habitually doing something in the past.",
          sentences: [
            {
              Kor: "어른들이 어린 내게 속삭이던 게 기억난다.  ",
              Eng: "I recall the adults whispering to me when I was little.",
            },
          ],
        },
        {
          entryName: "-던 것이다",
          typicalExample: "【청백타 하던 것이었었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "청백타 하던 것이었었다 ",
              Eng: "[He] thought he was upright.",
            },
            {
              Kor: "파싹 늙어 있었던 것이다 ",
              Eng: "He was (in the state of being) emaciated.",
            },
          ],
        },
        {
          entryName: "-던 것만 같다",
          typicalExample: "【슬며시 잠이 들었던 것만 같다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier + 것만 같-: all it seems like is, definitely seems. The addition of particle 만 to the seem pattern in Modifier + 것 같다 lends an air of absolute certainly and insistence to the construction, as if to say the one and only thing that seemed [likely] was ...—that what seemed to be happening or about to happen was/is imminent, inevitable, or inescapable.",
          sentences: [
            {
              Kor: "슬며시 잠이 들었던 것만 같다 ",
              Eng: "It seemed like it was at the moment when the girl had taken the drugs that I had dozed off into sleep. ",
            },
          ],
        },
        {
          entryName: "-던 것처럼 ",
          typicalExample: "【호수까지 데려다 달랬던 것처럼 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "As if one had done or used to do ...",
          sentences: [
            {
              Kor: "호수까지 데려다 달랬던 것처럼 ",
              Eng: "[she acted sure of herself] as if I had already asked her to let me off at the lake",
            },
          ],
        },
        {
          entryName: "-던 까닭이다 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Was because ...; was the reason that ...",
          sentences: [
            { Kor: "[Examples?]", Eng: "" },
            {
              Kor: "말이 퍼지고 있었던 까닭이었다 ",
              Eng: "it was because the rumour had been spreading ",
            },
          ],
        },
        {
          entryName: "-던 끝에 ",
          typicalExample:
            "【쌩동거리고 하던 끝에 필경은 나오는 것이 그 뉴똥치마의 푸념이요 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "After ...-ing; at the end of having done.",
          sentences: [
            {
              Kor: "쌩동거리고 하던 끝에 필경은 나오는 것이 그 뉴똥치마의 푸념이요 ",
              Eng: "After complaining would come the grumbles about (not being able to get the) silk skirt. ",
            },
            {
              Kor: "청백을 뇌던 끝에 ",
              Eng: "after reiterating his uprightness ...",
            },
            { Kor: "[Examples?]", Eng: "" },
          ],
        },
        {
          entryName: "-던 대로",
          typicalExample: "【예상했던 대로 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "In accordance with the way one has done or used to do.",
          sentences: [{ Kor: "예상했던 대로 ", Eng: "just as I had expected" }],
        },
        {
          entryName: "-던데(요)",
          typicalExample: "【평소에 그렇지도 않던데 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Retrospective -더- + Imminent Elaboration in -(으)ㄴ데.",
          sentences: [
            {
              Kor: "평소에 그렇지도 않던데 ",
              Eng: "you never used to be like this normally ...",
            },
          ],
        },
        {
          entryName: "-던 듯(이) ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Like, as, giving the appearance of ...; appearing (to be), looking (like); seeming as if; as if/though. This pattern is often 'flagged' in front by the adverb 마치 which serves as a sort of warning to the speaker that a like/as if pattern in 듯이 or -ㄴ/-는 것 처럼 is coming up.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-던 때문이다",
          typicalExample: "【그는 마음의 정처를 방금 잃어버렸던 때문이었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "때문 as postmodifier.",
          sentences: [
            {
              Kor: "그는 마음의 정처를 방금 잃어버렸던 때문이었다 ",
              Eng: "It was because he had just lost the one fixed place in his heart.",
            },
          ],
        },
        {
          entryName: "-던 만큼1",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "To the extent that does/is (=-(으)ㄴ정도(로)); enough to do, as much as necessary for doing.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-던 만큼2 ",
          typicalExample: "【호랑이를 잊고 있었던 만큼 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "In as much as, in so far as, since (had done /been/used to be/used to do); to the extent that (had done /been/used to be/used to do).",
          sentences: [
            {
              Kor: "호랑이를 잊고 있었던 만큼 ",
              Eng: "to the extent that we forgot about the tiger ...",
            },
            {
              Kor: "야미꾼으로 추측했던만큼 ",
              Eng: "lit.: to the extent which I took him to be a blackmarketeer ",
            },
          ],
        },
        {
          entryName: "-던 모양(으로)",
          typicalExample: "【이야기가 있었던 모양으로 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "With an appearance, look, or air (signs, indications, symptoms way, manner) of having done, been doing or used to do/be.",
          sentences: [
            {
              Kor: "이야기가 있었던 모양으로 ",
              Eng: "with the appearance that they had talked...",
            },
          ],
        },
        {
          entryName: "-던 성싶다 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Seems/appears (to have been the case that/to have used to have been the case that), I guess (that).",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-던지",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Retrospective modifier -던 + postmodifier 지 for oblique questions: (nobody knows) the uncertain fact (since when).",
          sentences: [
            { Kor: "[Examples?]", Eng: "" },
            {
              Kor: "언제부터 학이 이 마을을 찾아오기 시작하였던지는 아무도 모른다 ",
              Eng: "No one knows when the cranes started to visit the village.",
            },
            {
              Kor: "강도가 다닐 시간은 아니다 싶었던지 ",
              Eng: "perhaps thinking that this isn't the hour in which burglars lurk ...",
            },
          ],
        },
        {
          entryName: "-던지 몰라도 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Even though one doesn't know ['the uncertain fact'] whether, what, how, why, etc. Usually in a bigger pattern of the type: I don't know (or nobody knows) A, but B ...",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-던지도 모른다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "(The uncertain fact) whether it will be or do; doesn't know whether perhaps.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-던지(요)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation: "Used as a polite indirect question.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-던지라(서) ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "As/since it does; it is a fact that.., grounds for, reason for (equivalent to colloquial  -드니, according to Roth). Usually for a ceremonious/solemn pronouncement.  ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-던 차(에) ",
          typicalExample: "【단정을 하던 차 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Just at the juncture when one had been ...-ing",
          sentences: [
            {
              Kor: "단정을 하던 차 ",
              Eng: "Just when all had concluded that he was as good as dead ...",
            },
            { Kor: "[Examples?]", Eng: "" },
          ],
        },
        {
          entryName: "-던 차라(서)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Postmodifier 차 followed by copular -(이)라(서): owing to the coincidence that ...; as an incidental consequence of ...; incidental (in addition) to; on the spur of ...",
          sentences: [
            { Kor: "[Examples?]", Eng: "" },
            {
              Kor: "피곤했던 차라 ",
              Eng: "[since people] already happened to be tired ...",
            },
          ],
        },
        {
          entryName: "-던 참이다 ",
          typicalExample:
            "【학원 안으로 들어오리라고는 생각지 못하고 있던 참이었기 때문이었다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-던 참이었다: is just (as coincidence would have it) in the process of doing/happening; is/was just at the juncture when ... ; had just right then at that moment been ...-ing. ",
          sentences: [
            {
              Kor: "학원 안으로 들어오리라고는 생각지 못하고 있던 참이었기 때문이었다. ",
              Eng: "It was because it had never occurred to me just then that he might walk into the cram school.",
            },
            {
              Kor: "조용히 시골 읍에서 수양하던 참인데 ",
              Eng: "I was just [in the process of] cultivating myself quietly in a country village ...",
            },
          ],
        },
        {
          entryName: "-던 판에 ",
          typicalExample: "【허 참, 나라가 망하던 판에 오죽해 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Postmodifier 판 (or 판국) + 에 in the [deplorable / unfortunate] situation where/that.",
          sentences: [
            {
              Kor: "허 참, 나라가 망하던 판에 오죽해 ",
              Eng: "Oh well, what do you expect on top of the country going to the dogs?",
            },
          ],
        },
        {
          entryName: "-던 편이다 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "On the ... side; ...-ish; direction, inclination, preponderance. ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-데",
          typicalExample: "【[Examples?]】",
          categoryKorean: "하게체의 종결 어미 Final ending in Familiar Style  ",
          categoryEnglish:
            "(하게체의 종결 어미 Final ending in Familiar Style  )",
          translation:
            "The retrospective ending in the Familiar or 하네 Style. This style (indicative 하네, suggestion 하세, question 하나, command 하게, retrospective statement 하데, retrospective question 하디 and optional -(으)ㄹ세 for the copula and adjectives (-이네, 좋을세), is used only among grown-ups, e.g., by a senior addressing a grown-up junior or between grown-up social equals, and is typically used by parents-in-law addressing their sons-in-law, or by a male supervisor to a male supervisee. It is widely used among adult male friends who are not close enough to use the Intimate Style, and less widely by (or to) women. To make a Familiar Style apperceptive (equivalent to -구나, -구먼요, etc. in other styles), you add -그려 or -구려 to -네.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-데만 ",
          typicalExample: "【아홉 수가 원래 사납다고 하데만】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Retrospective assertive -데 it has been observed + particle 만 'but'.",
          sentences: [
            {
              Kor: "아홉 수가 원래 사납다고 하데만",
              Eng: "they say that number nine is usually a wild number, but ... ",
            },
          ],
        },
        {
          entryName: "-덴",
          typicalExample: "【[Examples?]】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation: "From -더냐. ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "~도",
          typicalExample: "【그 무엇도 보이지 않았다.  】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "question word + 도 (+ negative): no(body, -thing, etc.) at all. ",
          sentences: [
            {
              Kor: "그 무엇도 보이지 않았다.  ",
              Eng: "She could see nothing at all. (The 그 in front of 무엇 makes the whole construction more emphatic, as well as more fancy/literary.) ",
            },
          ],
        },
        {
          entryName: "-도다",
          typicalExample: "【모지도다. 이셔방이 모지도다.】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "모지도다. 이셔방이 모지도다.",
              Eng: "It's harsh, Master Lee is harsh. (NB: On 모질다 here, the loss of before -ㄹ [and other endings beginning with ㅈ or ㄷ] was standard in an earlier stage of Korean, and here renders the expression even more archaic-sounding.)",
            },
          ],
        },
        {
          entryName: "도대체 ...-기에?",
          typicalExample: "【도대체 무슨 영화기에 하고 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Adverb 도대체 plus question word + ...-기에/-길래 renders a pattern that means like: WH- on earth is/does it (such that...).",
          sentences: [
            {
              Kor: "도대체 무슨 영화기에 하고 ",
              Eng: "wondering what on earth could be playing [such that so many people...]",
            },
          ],
        },
        {
          entryName: "도대체 ...-길래?",
          typicalExample: "【도대체 무슨 영화기에 하고 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Adverb 도대체 plus question word + ...-기에/-길래 renders a pattern that means something like: WH- on earth is/does it (such that...).",
          sentences: [
            {
              Kor: "도대체 무슨 영화기에 하고 ",
              Eng: "wondering what on earth could be playing [such that so many people...]",
            },
          ],
        },
        {
          entryName: "-도록",
          typicalExample:
            "【새벽에 들어와서 열두 시가 넘도록 늘어지게 잤던 것이다. 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Projective -도록 to the point where; until (a result); so that.",
          sentences: [
            {
              Kor: "새벽에 들어와서 열두 시가 넘도록 늘어지게 잤던 것이다. ",
              Eng: "… had come home in the wee hours of the morning and slept like a log until well past noon.",
            },
            {
              Kor: "연기가 사라지지 않도록 창문을 굳게 닫아걸고",
              Eng: "… shut the windows up tight so that the smoke would not disappear …",
            },
            {
              Kor: "오래도록 바라보았다. ",
              Eng: "… gazed at it for a long time.",
            },
            { Kor: "밤새도록", Eng: "all through the night; all night long …" },
            {
              Kor: "그날은 밤이 깊도록 ",
              Eng: "that day, deep into the night ...",
            },
            { Kor: "밤이 깊도록 ", Eng: "until deep/late in the night" },
            {
              Kor: "겨울이 다 지나도록 ",
              Eng: "until winter had completely passed ",
            },
            { Kor: "사흘이 지나도록 ", Eng: "until four days had passed" },
            {
              Kor: "그 후 삼사 년이 지나도록",
              Eng: "after this to the point of three or four years ...",
            },
            { Kor: "늦도록 마셨다 ", Eng: "drank until late" },
            {
              Kor: "계절이 다 늦도록 ",
              Eng: "[the cranes didn't return] even until late in the season",
            },
            {
              Kor: "벌써 일주일이 넘도록 ",
              Eng: "it's been more than a week ...",
            },
            { Kor: "밤 열두시가 넘도록 ", Eng: "after midnight ..." },
            {
              Kor: "끔찍하도록 섬세하고 ",
              Eng: "it was dreadfully detailed and ... (lit.: detailed to the point of being dreadful)",
            },
            {
              Kor: "불어 터지도록 ",
              Eng: "to the extent that a few of my toes broke ...",
            },
            {
              Kor: "성기를 아프도록 때리는 감촉",
              Eng: "a sensation to the point of pain being inflicted upon the penis...",
            },
            {
              Kor: "나의 손을 아프도록 쥐고 ",
              Eng: "pressing my hand to the extent that it hurt ...",
            },
            {
              Kor: "나는 아프도록 쑤시는 이마를 베개에 대었다 ",
              Eng: "I lay my forehead, which was throbbing to the point of making me sick, on the pillow.",
            },
            {
              Kor: "다리가 아프도록 쫓아다녀 보고 ",
              Eng: "… would chase after until his legs hurt, and …",
            },
            {
              Kor: "엄지손가락이 얄밉도록 밖으로만 쥐어져 있었다.",
              Eng: "To the point that it was provoking, the thumb always ended up on the outside when the hand was rolled into a fist.",
            },
            {
              Kor: "새로 두 점을 치도록 ",
              Eng: "until [the clock struck] two in the morning ",
            },
            { Kor: "밤 이슥하도록 ", Eng: "till late at night ..." },
            {
              Kor: "단번에 손쉽게 타도록 꾸미었을 것이다 ",
              Eng: "it was prepared in a way that it would burn easily all at once",
            },
            {
              Kor: "입안에서 풀이 되도록 ",
              Eng: "until it turned to paste in his mouth ...",
            },
            {
              Kor: "땀이 나도록 두껍게 옷을 입고 ",
              Eng: "to wear clothes that were so thick they'd make you sweat ...",
            },
            {
              Kor: "숨이 닿도록 ",
              Eng: "to the point where he couldn't breathe ...",
            },
            {
              Kor: "담배불이 손가락끝에 닿도록 쭈욱 빨아 넘겼다 ",
              Eng: "… drew on his cigarette to the point where the lighted part touched the tips of his fingers. (Note that 넘기다 here is for swallow; inhale.)  ",
            },
            {
              Kor: "입이 닳도록 ",
              Eng: "speaking to the point of wearing out one's mouth; speaking profusely",
            },
            {
              Kor: "털모자의 챙을 이마빡에 붙도록 척 올리면서 말했다 ",
              Eng: "spoke while lifting the strap of his fur hat up to his forehead in a somewhat dignified manner",
            },
            {
              Kor: "자기는 숨이 가쁘도록 ",
              Eng: "to him it was to the point of making him gasp for breath",
            },
            {
              Kor: "그 겨울 몇 십 인치씩 쌓이도록 눈이 내리고 ",
              Eng: "that winter snow fell to the point of piling up several tens of inches (a couple of feet)",
            },
            {
              Kor: "가닥이 있을 수 없도록 ",
              Eng: "so that there are no pieces left ...",
            },
            {
              Kor: "아침부터 지금 낮이 기울도록 ",
              Eng: "from morning until now as the day was waning ...",
            },
            {
              Kor: "졸리도록 따스한 봄 햇볕이 ",
              Eng: "the spring sunshine, which was warm to the point of inducing sleep ...",
            },
            {
              Kor: "학은 낮이 기울도록 안 보였다 ",
              Eng: "the cranes weren't seen even until late in the day",
            },
            {
              Kor: "서럽도록 보고 싶어졌다",
              Eng: "[it got to be so that] I missed [my husband] to the point where it saddened me",
            },
            {
              Kor: "평생 산에서 살도록 태어났는지도 모른다 ",
              Eng: "he wasn't sure whether perhaps he wasn't born to live out his whole life in the mountains",
            },
            {
              Kor: "미끄러지지 않도록 ",
              Eng: "[they had to hold her from both sides] so that she wouldn't slip",
            },
            {
              Kor: "아버지의 귀에 들리도록 ",
              Eng: "[I said it loudly enough] that it would reach my father's ears ...",
            },
            {
              Kor: "빨리 그를 보내도록 ",
              Eng: "do all you can to get rid of him ... ",
            },
          ],
        },
        {
          entryName: "-도록 마련이다",
          typicalExample: "【[Usage]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "[Usage]",
          sentences: [{ Kor: "[Usage]", Eng: "" }],
        },
        {
          entryName: "도무지 + Negative",
          typicalExample: "【도무지 그럴 기분이 아니었다】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 도무지 plus negative means nowhere near ... ; not at all ...",
          sentences: [
            {
              Kor: "도무지 그럴 기분이 아니었다",
              Eng: "it was not at all that kind of mood",
            },
            {
              Kor: "도무지 세상 밖으로 나올 줄 모르는 아이 ",
              Eng: "a child who for the life of it didn't know how to come out into the world",
            },
          ],
        },
        {
          entryName: "~도 NOUN-(이)지만",
          typicalExample: "【하숙집 주인도 주인이지만 나도 나죠 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN1도 NOUN1(이)지만: NOUN1 has a point, too or NOUN1 has its own problems or case, too...[but what about this other case?]",
          sentences: [
            {
              Kor: "하숙집 주인도 주인이지만 나도 나죠 ",
              Eng: "the landlady is one problem, but then, I'm just as guilty ... ",
            },
          ],
        },
        {
          entryName: "~도 않는다",
          typicalExample: "【그네는 꼼짝도 않고 서 있었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "VERBAL NOUN도 않는다 for VERBAL NOUN[하지]도 않는다.",
          sentences: [
            {
              Kor: "그네는 꼼짝도 않고 서 있었다 ",
              Eng: "she was standing there without budging ",
            },
          ],
        },
        {
          entryName: "~도 없이",
          typicalExample: "【별로 탓하는 기색도 없이 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN도 없이: Without even NOUN.",
          sentences: [
            {
              Kor: "별로 탓하는 기색도 없이 ",
              Eng: "without hardly a condemning expression ...",
            },
          ],
        },
        {
          entryName: "~도 ... NOUN-(이)려니와",
          typicalExample: "【불놀이도 불놀이려니와 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN1 도 NOUN1(이)려니와: the NOUN(S) is/are one thing, but ... [followed by something even more comment-worthy]. From -려니와 but; and anyway (what's more), moreover.",
          sentences: [
            {
              Kor: "불놀이도 불놀이려니와 ",
              Eng: "the fireworks were one thing ... ",
            },
          ],
        },
        {
          entryName: "~도 하다",
          typicalExample: "【내 자신이 불쌍도 하였다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERBAL NOUN도 하다 for VERBAL NOUN[하기]도 하다: sure is ...; most surely is a case of being ....",
          sentences: [
            {
              Kor: "내 자신이 불쌍도 하였다 ",
              Eng: "I sure was pathetic. (For 불쌍[하기]도 하- is also.)",
            },
            {
              Kor: "편벽된 마음을 의심도 하여보았다 ",
              Eng: "He also tried to doubt his eccentric heart. (For 의심[하기]도 하-.)",
            },
          ],
        },
        {
          entryName: "~도 하지 않다",
          typicalExample: "【남자는 미동도 하지 않았다.   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VN도 하지 않다 equivalent to VN하지도 않다 doesn't even ... ; isn't even ...",
          sentences: [
            {
              Kor: "남자는 미동도 하지 않았다.   ",
              Eng: "the man did not even budge",
            },
          ],
        },
        {
          entryName: "동시에 ",
          typicalExample: "【찬탄과 동시에 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN과/와 동시에: at the same time as NOUN, simultaneously as/with NOUN, while/as doing NOUN; together with NOUN",
          sentences: [
            {
              Kor: "찬탄과 동시에 ",
              Eng: "while I admired …; or, at the same time as my admiration ",
            },
          ],
        },
        {
          entryName: "돼",
          typicalExample: "【남편의 건강이 염려 돼 】",
          categoryKorean: "Alternative spelling",
          categoryEnglish: "(Alternative spelling)",
          translation:
            "Alternative spelling for 되어 becomes, gets to be, is (infinitive form of 되-).",
          sentences: [
            {
              Kor: "남편의 건강이 염려 돼 ",
              Eng: "as I became concerned about my husband's health ...",
            },
          ],
        },
        {
          entryName: "-되1",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Concessive -(으)되 According to Martin, the shape in -으되 is a dialect form except after ㅆ and ㅂㅅ The copular form is usually -이로되: although, (even though) it does/is.",
          sentences: [
            { Kor: "[Examples?]", Eng: "" },
            {
              Kor: "조금 성가시다 싶었으되 ",
              Eng: "although I found [him a bit annoying...]",
            },
          ],
        },
        {
          entryName: "-되2",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Concessive -(으)되 According to Martin, the shape in -으되 is a dialect form except after ㅆ and ㅂㅅ The copular form is usually -이로되: does/is and indeed (does/is).",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "두",
          typicalExample: "【나두 마찬가지라니깐 】",
          categoryKorean: "Particle/Colloquial/Substandard",
          categoryEnglish: "(Particle/Colloquial/Substandard)",
          translation:
            "Colloquial/substandard for particle 도 and, even, also. ",
          sentences: [
            { Kor: "나두 마찬가지라니깐 ", Eng: "Same for me, I tell ya!" },
            {
              Kor: "아는 이두 없을 거요 ",
              Eng: "There probably won't be anyone I know. (For 아는 이도 where 이 is person, people.)",
            },
          ],
        },
        {
          entryName: "~ 두고 ",
          typicalExample: "【오래오래 두고 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "TIME PERIOD(을) 두고: for/over (the duration of) TIME PERIOD. ",
          sentences: [
            { Kor: "오래오래 두고 ", Eng: "for a long time ..." },
            { Kor: "10년을 두고", Eng: "for 10 years" },
          ],
        },
        {
          entryName: "-드니",
          typicalExample: "【대대루 살아오던 집마저 남의 손에 넘기드니】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Retrospective sequential -드니 for what is now usually spelled -더니 as now it has been observed that ...; when (now/then) ... , since (now/then) ...; ... and now/then; ... but now/then. The form in -더니 usually implies a marked or abrupt contrast between the first and second clauses: whereupon.",
          sentences: [
            {
              Kor: "대대루 살아오던 집마저 남의 손에 넘기드니",
              Eng: "they already have to let go of the house where they've lived in for generations, and now [they have to suffer yet another disaster...]",
            },
          ],
        },
        {
          entryName: "-드라",
          typicalExample: "【여자들 그러는 속 모르겠드라 】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "Retrospective assertive -드라 it has been observed that..., it is known that..., as we (or I) know, I hear (have been told) that..., I noticed that..., I've discovered (found out) that... Nowadays spelled -더라. ",
          sentences: [
            {
              Kor: "여자들 그러는 속 모르겠드라 ",
              Eng: "I could never tell what women are thinking",
            },
          ],
        },
        {
          entryName: "-드라우",
          typicalExample: "【난 게 아니라, 들어왔드라우 】",
          categoryKorean: "종결 어미 Final ending/준꼴 Contraction",
          categoryEnglish: "(종결 어미 Final ending/준꼴 Contraction)",
          translation: "For -더라[고 하]오.",
          sentences: [
            {
              Kor: "난 게 아니라, 들어왔드라우 ",
              Eng: "It wasn't that he got out, he went in.",
            },
          ],
        },
        {
          entryName: "-드랍니다요",
          typicalExample: "【진짜 순사드랍니다요 】",
          categoryKorean: "종결 어미 Final ending/준꼴 Contraction",
          categoryEnglish: "(종결 어미 Final ending/준꼴 Contraction)",
          translation:
            "For 더라[고 하]ㅂ니다요: I say it is/does or I tell you it is/does = it really is/does; it is/does, you see. ",
          sentences: [
            {
              Kor: "진짜 순사드랍니다요 ",
              Eng: "I'm telling you, he was a real constable.",
            },
          ],
        },
        {
          entryName: "-드래요",
          typicalExample: "【열다섯 도랑꾸드래요 】",
          categoryKorean: "종결 어미 Final ending/준꼴 Contraction",
          categoryEnglish: "(종결 어미 Final ending/준꼴 Contraction)",
          translation:
            "It has been observed that ... [they say]. Now spelled -더래요 (contracted from -더라[고 ㅎ]ㅐ요).",
          sentences: [
            {
              Kor: "열다섯 도랑꾸드래요 ",
              Eng: "It has been observed that they have fifteen trunks ...",
            },
          ],
        },
        {
          entryName: "-드만",
          typicalExample:
            '【"이 나라에 남은 마지막 미련까지 사라지더라고 그러드만." 】',
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "-드만/-드먼 = -더만/ -더먼 or = -드구먼, a retrospective 'First Realization' Martin's 'apperceptive') form. The meaning is something like Now that I think back on it, gosh!",
          sentences: [
            {
              Kor: '"이 나라에 남은 마지막 미련까지 사라지더라고 그러드만." ',
              Eng: '"I think what he said was something like even his last remaining affections for this country had disappeared."',
            },
          ],
        },
        {
          entryName: "-든1",
          typicalExample: "【사내애 둘 있든 건】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "Retrospective modifier -든 for what is now usually spelled -던 ... that has been observed to (be or do); ... that has been doing, ... that used to do; ... that was, ... that used to be.",
          sentences: [
            { Kor: "사내애 둘 있든 건", Eng: "the two boys they used to have" },
          ],
        },
        {
          entryName: "-든2",
          typicalExample: "【어느 계곡을 가든 】",
          categoryKorean: "연결어미 Connective ending/준말 Abbreviation",
          categoryEnglish: "(연결어미 Connective ending/준말 Abbreviation)",
          translation:
            "An abbreviation of -든지. The endings -던 and -든 can be treated as spelling variants, although some Korean grammarians try to maintain an artificial (for Seoul, anyway) distinction: -던지 whether/ maybe one has been doing vs. -든지 (used with an interrogative-indefinite word) any at all, regardless of which, -(so)ever, (whatever) one may do, (how, what, etc.)-ever it (has been observed to) be. Nowadays the whatever meaning is usually spelled with -든지. This pattern can optionally be followed by 간에.",
          sentences: [
            { Kor: "어느 계곡을 가든 ", Eng: "whichever valley you go to" },
            { Kor: "어떻게 보든 ", Eng: "no matter how others regard it" },
            {
              Kor: "일만 년 후의 세계가 어떻게 되든  ",
              Eng: "no matter how the world turned out 10,000 years later [Yunho didn't care]",
            },
            {
              Kor: "어떤 말이든 주저함이 없다.  ",
              Eng: "He had no hesitation saying anything at all",
            },
            {
              Kor: "누구든 애태우고 싶다.  ",
              Eng: "I don't care who it is—I want to make him miserable.",
            },
            {
              Kor: "입술을 한번 깨물고 또 어떻게든 꾸려 나갔을 것이다.  ",
              Eng: "She would have bitten her lip once, and then kept on coping somehow.",
            },
            {
              Kor: "여주루 내려갔든 기노시다상넨 …",
              Eng: "Mr. Kinoshita and his family, who had moved to Yŏju…",
            },
          ],
        },
        {
          entryName: "-든 ... -든",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "VERB1 -든 (VERB2 -든) either VERB1 or VERB2  as an abbreviation for the pattern in VERB1 -든지 (VERB2 -든지) either VERB1 or VERB2.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-든가 ... VERB2-든가 하-",
          typicalExample:
            '【"아버지도 늦게 들어오시든가, 호텔에서 주무시든가 할걸요."】',
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "VERB1-든가 ... VERB2-든가 하- do either VERB1 or VERB2 (or the like). The -든가 here can be replaced by -든지. ",
          sentences: [
            {
              Kor: '"아버지도 늦게 들어오시든가, 호텔에서 주무시든가 할걸요."',
              Eng: '"And (contrary to what you might be assuming) my father will probably either come home late or else sleep at a hotel."',
            },
          ],
        },
        {
          entryName: "-든데요",
          typicalExample: "【알 날 자리를 보든데요 】",
          categoryKorean: "해요체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해요체의 종결 어미 Final ending in Plain Style)",
          translation:
            "Retrospective circumstantial -든데요 for what is usually nowadays spelled -던데요, in its sentence-final exclamatory usage: You see it's a case of (being found that ) ...!",
          sentences: [
            {
              Kor: "알 날 자리를 보든데요 ",
              Eng: "[it's already several days now since] it's been looking like it might lay an egg",
            },
          ],
        },
        {
          entryName: "-든지 ",
          typicalExample: "【어느 곳에 가든지 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "The endings -던지 and -든지 can be treated as spelling variants, although some Korean grammarians try to maintain an artificial (for Seoul, anyway) distinction: -던지 whether/maybe one has been doing vs. -든지 used with an interrogative-indefinite word, meaning any at all, regardless of which, ...-(so)ever, (whatever) one may do, however it (has been observed to) be, (how, what, etc.)-ever it (has been observed to) be. Nowadays the whatever (etc.) meaning is usually spelled with -든지. This pattern can optionally be followed by 간에.",
          sentences: [
            { Kor: "어느 곳에 가든지 ", Eng: "wherever you go ..." },
            { Kor: "그것이 무슨 일이든지 ", Eng: "whatever [people] do ..." },
            {
              Kor: "세상살이에 이런 어려움은 얼마든지 있으니 ",
              Eng: "since there are always any number of difficulties like this in life … ",
            },
            {
              Kor: "당신이 얼마든지 주무르고 어를 수 있는 여자예요, 하듯이",
              Eng: '… as if to say, "I am a woman that you can fondle and caress as much as you like."',
            },
            { Kor: "[Examples?]", Eng: "" },
          ],
        },
        {
          entryName: "-든지 ... -든지",
          typicalExample: "【그러든지, 순살 도루 댕기든지 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "VERB1-든지 ...VERB2-든지 (간에): whether one does VERB1 or VERB2-; do either VERB1 or VERB2- (implying no particular preference between the different options).",
          sentences: [
            {
              Kor: "그러든지, 순살 도루 댕기든지 ",
              Eng: "either do that or become a constable again",
            },
            {
              Kor: "더 늙기 전에 이민을 가든지 그것도 안 되면 시골 가서 농사나 짓든지  ",
              Eng: "before I get any older, I should either emigrate or, if that doesn't work, go to the countryside and farm or something",
            },
            {
              Kor: "불에 타 죽었든지 굶어 죽었든지 ",
              Eng: "I could have died on the island in the fires, or starved to death",
            },
          ],
        },
        {
          entryName: "들",
          typicalExample:
            "【안 쳐다보고 살 걸 남자들은 왜 그렇게들 예쁜 여자와 결혼하려고  】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "Floating/roaming particle 들 to indicate a plural subject, meaning (acting) severally. 들 can follow almost any word in a sentence except the final verb to indicate that the subject, stated or implied, is specifically plural. However, it tends to gravitate to the penultimate position—the word just before the final form in the clause or sentence.",
          sentences: [
            {
              Kor: "안 쳐다보고 살 걸 남자들은 왜 그렇게들 예쁜 여자와 결혼하려고  ",
              Eng: "[I can't figure out why men are so crazy to] marry pretty women if they live their lives never looking at their wives …",
            },
            { Kor: "잘들 자란다 ", Eng: "[the trees] grow successfully" },
            {
              Kor: "한때 잘들 해먹었으니 ",
              Eng: "at one time we had a good thing going…",
            },
            {
              Kor: "어떻게들 살아가나요? ",
              Eng: "So how do [the fifty or sixty thousand inhabitants] make their living? ",
            },
            {
              Kor: "감자 한 개씩으로 연명들 하다가 ",
              Eng: "they were barely surviving on a ration of one potato a day ...",
            },
            {
              Kor: "물끄러미 맞은편 산만 바라보고들 있었다 ",
              Eng: "They only looked vacantly at the mountain across from them.",
            },
            {
              Kor: "학들이 사라진 곳을 쏘아보고들 서 있었다 ",
              Eng: "[They] were standing there staring at the place where the cranes had disappeared to.",
            },
            {
              Kor: "더욱더 무거운 불안을 느끼고들 있었다 ",
              Eng: "Everyone was feeling a more serious uneasiness.",
            },
            {
              Kor: "집에 가서들 쉬죠 ",
              Eng: "[Others shut down the business and] go home to rest.",
            },
            {
              Kor: "학나무 밑에들 모여 앉아 ",
              Eng: "they gathered under the crane tree ...",
            },
            {
              Kor: "학나무 밑으로들 모였다 ",
              Eng: "They gathered under the crane tree.",
            },
            {
              Kor: "올해에도 평년작은 된다고들 ",
              Eng: "[people were overjoyed] that this year's harvest would be normal ...",
            },
            {
              Kor: "필시 그건 무슨 흉조라고들 하였다 ",
              Eng: "They all said it was without a doubt some ill omen.",
            },
            {
              Kor: "장가드셨다고들 하더군요 ",
              Eng: "They say that you married [really well]!",
            },
            {
              Kor: "제일 출세하셨다고들 하고 있어요 ",
              Eng: "People say that you have been the most successful.",
            },
            {
              Kor: "장거리에서들은 지금 피난을 간다고 야단들이야요 ",
              Eng: "People in the marketplace are all in an uproar about people taking refuge.",
            },
            { Kor: "어디까지들 가슈? ", Eng: "Where are you guys headed?" },
            { Kor: "뭣들 하느냐 ", Eng: "What do you two think you're doing?" },
            {
              Kor: "어서들 집으로 가거라 ",
              Eng: "You two get on home! (Here, the subject 'you guys' has been omitted, so the 들 has migrated to 어서.) ",
            },
            {
              Kor: "어디 일들 가슈? ",
              Eng: "Are you all heading off to work?",
            },
            {
              Kor: "전에 많이들 행악을 했대서? ",
              Eng: "Just because they say we had acted terribly in the past?",
            },
            {
              Kor: "식은관사에들 피었던 코스모스들이 ",
              Eng: "the cosmos flowers that had used to blossom [over the fences] of the Colonial Bank official residences where he would always pass by (NB: In an adnominalized sentence (as here), the plural reference is to the UNDERLYING subject: e.g., 산에들 있던 나무 must be taken as the trees on the mountain. But when the nature of the underlying subject does not permit a plural interpretation, the reference will be to some other noun: 산에들 있던 눈 the snow which was on the mountains. This represents a kind of loose reference, and some Koreans will prefer to rephrase: 산들에 있던 눈. In this particular example, the author could just as well have written 식은관사들에, since he also has 들 on cosmos flower to make that word specifically plural.)",
            },
            {
              Kor: "아파트 거실에 모인 동아리들 틈에서 자기 과시를 위해 큰 소리로 떠들어대었다.  ",
              Eng: "[In'gyu] chattered on in a loud voice so as to show off in front of the club members gathered in the apartment living room.",
            },
          ],
        },
        {
          entryName: "~들(이) 어느것이고 하나",
          typicalExample: "【할멈들이 어느것이고 하나는 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN들 어느것이고 하나: one or the other of ...",
          sentences: [
            {
              Kor: "할멈들이 어느것이고 하나는 ",
              Eng: "one or other of the grannies ...",
            },
          ],
        },
        {
          entryName: "들르- and 들리-",
          typicalExample: "【술집에 들러 = 술집에 들러(서) 】",
          categoryKorean: "Verb",
          categoryEnglish: "(Verb)",
          translation:
            "The verb drop in appears in two different shapes: 1) 들르다, 들러요 and 2) 들리다, 들려요.",
          sentences: [
            {
              Kor: "술집에 들러 = 술집에 들러(서) ",
              Eng: "dropped into a tavern, and ... ",
            },
          ],
        },
        {
          entryName: "-듯(이)",
          typicalExample: "【아이에게 구구셈을 익혀 주듯이】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "BASE + -듯(이) is like, is as (if). Martin treats this as an abbreviation of the pattern where 듯(이) is used as a postmodifier, i.e., -ㄴ 듯(이), -ㄹ 듯(이), -는/-던 듯(이) but in any case you should keep them distinct. In the pattern here, -듯(이) attaches directly to the base without any intervening modifier. ",
          sentences: [
            {
              Kor: "아이에게 구구셈을 익혀 주듯이",
              Eng: "in the same way one might teach a child the multiplication table  ",
            },
            {
              Kor: "중이 염불하듯 ",
              Eng: "like a Buddhist monk chants a prayer to the Buddha",
            },
            {
              Kor: "아이들이 불평을 하듯이 ",
              Eng: "like the complaining of children ",
            },
            {
              Kor: "누구나 그렇듯이 ",
              Eng: "as if it was obvious to just anybody ",
            },
            {
              Kor: "후배가 응얼거리듯이 말했다 ",
              Eng: "He mumbled a response.",
            },
            {
              Kor: "불타듯 뜨거워진 몸 ",
              Eng: "the body, heating up as if on fire ",
            },
            { Kor: "거의 장난치듯 ", Eng: "almost jokingly " },
            {
              Kor: "마누라를 개패듯 때려잡던데",
              Eng: "he beat his wife like you beat a dog ",
            },
            {
              Kor: "어느 제숫댁들이나 찾아오듯 ",
              Eng: "like when people visit their sister-in-law or something ",
            },
            {
              Kor: "자기 자식이나 나무라듯 ",
              Eng: "… would reprove them as if they were his own kids or something",
            },
            { Kor: "아버지와 대결하듯 ", Eng: "as if confronting my father" },
            {
              Kor: "젊은 공의는 변명하듯 말했다 ",
              Eng: "… the young doctor said, as if excusing himself.",
            },
            {
              Kor: "전혀 모르는 낯선 사람을 보듯 ",
              Eng: "as if one was looking at a total stranger",
            },
            {
              Kor: "오솔길의 시작과 끝을 알 수 없듯 ",
              Eng: "as though she had no way of knowing the beginning or end of the narrow path ",
            },
            {
              Kor: "도깨비와 씨름하듯 ",
              Eng: "as if she were wrestling with a goblin",
            },
            {
              Kor: "퇴근벨 소리에 밀리듯 화장실을 나왔다 ",
              Eng: "I came out of the powder room as if being pushed out by the bell signaling the end of the workday. ",
            },
            {
              Kor: "나는 내뱉듯이 말했다 ",
              Eng: "I spoke as if spitting my words out.",
            },
            {
              Kor: "그 소나무를 받들어 모시듯 ",
              Eng: "as if serving the pine tree with utmost sincerity ",
            },
            {
              Kor: "두 날개를 기지개를 켜듯 ",
              Eng: "[lifted] its wings as if stretching ",
            },
            {
              Kor: "긴 목에서 피를 토하듯 ",
              Eng: "as if vomiting blood out of its long neck ",
            },
            {
              Kor: "무엇을 고하듯 또 울었다 ",
              Eng: "[the crane] cried again as if proclaiming something to a higher authority",
            },
            {
              Kor: "잠들듯이 숨을 거두었다 ",
              Eng: "he drew his last breath, as if falling asleep",
            },
            { Kor: "미끄러지듯", Eng: "as if slipping " },
            {
              Kor: "내가 산승(山僧)이 아니듯 ",
              Eng: "just like a spiritual mountain monk, I ...",
            },
            {
              Kor: "여자에게 아이가 있었던가 하듯 ",
              Eng: "… seemed to be wondering if the kid was the lady's",
            },
            {
              Kor: "아내에게 복수라도 하듯 ",
              Eng: "as if to get back at her or the like ",
            },
            {
              Kor: "콩볶듯이 나더니만 ",
              Eng: "[the sound of gunfire] sounded like firecrackers going off, whereupon ...",
            },
            {
              Kor: "몇 시간 전에 조가 얘기했듯이 ",
              Eng: "what a few hours earlier Mr. Cho described as ...",
            },
          ],
        },
        {
          entryName: "-듯하다",
          typicalExample: "【늘 개보름 쇠듯 하였다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Base + -듯하다: is like, is as (if), is like, is as (if).",
          sentences: [
            {
              Kor: "늘 개보름 쇠듯 하였다 ",
              Eng: "he always spent his lunar year festival like a dog",
            },
            { Kor: "아낙을 타이르듯 한다 ", Eng: "as if advising his wife" },
            {
              Kor: "배추 밑 도리듯 하는 건가? = 하는 것인가?",
              Eng: '[when it comes to property] is it the case that [even the moral integrity appropriate for the relationship of parent and child] is like "scraping out the bottom of a Chinese cabbage"? ',
            },
          ],
        },
        {
          entryName: "등",
          typicalExample: "【갈라진 흙벽, 삐걱이는 마루장 등이 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "Postnoun (semiliteral) 등: etc., and the like, and so on; the above several.",
          sentences: [
            {
              Kor: "갈라진 흙벽, 삐걱이는 마루장 등이 ",
              Eng: "the cracking mud walls, the creaking wooden floors, etc. ",
            },
          ],
        },
        {
          entryName: "-디?",
          typicalExample: "【[Examples?]】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "The retrospective question ending in the Familiar or 하네 Style. This style (indicative 하네, suggestion 하세, question 하나, command 하게, retrospective statement 하데, retrospective question 하디 and optional -(으)ㄹ세 for the copula and adjectives (-이네, 좋을세), is used only among grown-ups, e.g., by a senior addressing a grown-up junior or between grown-up social equals, and is typically used by parents-in-law addressing their sons-in-law, or by a male supervisor to a male supervisee. It is widely used among adult male friends who are not close enough to use the Intimate Style, and less widely by (or to) women. To make a Familiar Style apperceptive (equivalent to -구나, -구먼요, etc. in other styles), you add -그려 or -구려 to -네.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-디",
          typicalExample: "【가재문 가디, 원, 것두 못 갈까 】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            "Dialect phonology: the Phyengan dialect reflex of standard -지. This -디 preserves Middle Korean -디, which developed into -지 in most dialects besides P'yongan ones, which never underwent the palatalization and affrication experienced by other Korean dialects. ",
          sentences: [
            {
              Kor: "가재문 가디, 원, 것두 못 갈까 ",
              Eng: "If you tell me to go, then sure, I'll go—what's so hard about that? (i.e., equivalent to standard 가자고 하면 가지, 원, [그]것도 ...)",
            },
            {
              Kor: "알아 있디? ",
              Eng: "Got it? (i.e., equivalent to standard 알았지. The dialect pattern in -아 있- preserves earlier/Middle Korean -어/-아 잇-, the source of the modern past tense in -았/-었.)",
            },
            {
              Kor: "자 꿰주디, 그 대신 응? ",
              Eng: "All right, then, I'll lend you the money, but in return ... ? (i.e., equivalent to what would be standard 꾸이어 주지.)",
            },
            {
              Kor: "에이구 칵 죽구나 말디 ",
              Eng: "Oh, drop dead! (The pattern here is 'eventual development of an action' in -고 말다: the standard equivalent would be 죽고 말지.)",
            },
          ],
        },
        {
          entryName: "-디-",
          typicalExample: "【허망감을 쓰디쓰게 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "VERB-디-VERB really is VERB, is ever so VERB, is quite VERB. More common with one-syllable bases than polysyllabic bases.",
          sentences: [
            {
              Kor: "허망감을 쓰디쓰게 ",
              Eng: "[it was ideal for contemplating] the feeling of emptiness, ever so bitterly",
            },
            { Kor: "잘디잔 에메랄드 ", Eng: "ever so tiny emeralds" },
          ],
        },
        {
          entryName: "따라(서)",
          typicalExample: "【양씨의 탈진함에 따라 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN 따라(서) in accordance with NOUN, in conformity to (with) NOUN, according to NOUN. Usually the preceding noun is marked with particle 에, but we also find examples with particle 을 ~ 를. ",
          sentences: [
            {
              Kor: "양씨의 탈진함에 따라 ",
              Eng: "in accordance with Mr. Yang's exhaustion ...",
            },
            {
              Kor: "그의 열띤 음성따라",
              Eng: "in conformity with his excited voice ...",
            },
            { Kor: "봉천을 따라서 ", Eng: "following the path of Pong creek" },
            {
              Kor: "혈기가 줆을 따라 ",
              Eng: "as a consequence of the decline in his youthful vigor",
            },
            {
              Kor: "여자의 손을 따라 지수의 몸 안, 어느 한쪽에 숨어 있던 무언가가 차례로 빠져나가는 듯 ",
              Eng: "in accordance with/following the woman's hands, as if something that had been hiding in a corner of Chisu's body was leaving in an orderly fashion",
            },
            { Kor: "걸음을 따라 ", Eng: "in accordance with his steps ..." },
          ],
        },
        {
          entryName: "따라2",
          typicalExample: "【집에 들어가니 그녀도 그날따라 기분이 안 좋다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN따라: NOUN of all NOUNs (but this seems to be restricted to just a few time words like 오늘, 그날, etc.). ",
          sentences: [
            {
              Kor: "집에 들어가니 그녀도 그날따라 기분이 안 좋다.  ",
              Eng: "For some reason, when I got home that day, she was not in the best of moods, either.",
            },
            {
              Kor: "민영이가 너무 보채고 민후도 오늘 따라 말썽만 피웠던 것이다. ",
              Eng: "Minyŏng was all fussy and Minhu, too, was acting out today, of all days.",
            },
            {
              Kor: "오늘따라 왜 이리 마음에 걸리는 게 많은지",
              Eng: "I wonder why today, of all today, there should be so many things on my mind",
            },
          ],
        },
        {
          entryName: "따위",
          typicalExample: "【졸색의 등긁개 따위에는 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "Postnoun 따위: (and) the like; (and) such; a thing or person of that sort, such a one, that kind of, that sort of. Often derogatory/pejorative. Sometimes rounds off a list, plus copula -(이-) plus -거나 whether ... or ... (shows indifference toward choice between two conflicting actions or states). When adnominal, this optionally takes the shape 따윗.",
          sentences: [
            {
              Kor: "졸색의 등긁개 따위에는 ",
              Eng: "the old man's back-scratcher (i.e., concubine)",
            },
            {
              Kor: "온천물의 효험 따위를 ",
              Eng: "[as I didn't have some belief] in the so-called effects of hotsprings water ...",
            },
            {
              Kor: "기생-갈보 따위가 사글셋방 한 간을 얻어 달래도 ",
              Eng: "even when kisaengs, prostitutes and the like asked him to find them a monthly rent room",
            },
            {
              Kor: "그따위 걸 말이라고 해? [= 그따위 것을 ] ",
              Eng: "You call [the feeble likes of] that talking?; or, You call that an opinion?",
            },
            {
              Kor: "안변 따위에 대면 ",
              Eng: "compared to a place like Anbyŏn...",
            },
            {
              Kor: "제 따위들이 뭐라구 ",
              Eng: "who do you guys think you are ...",
            },
            {
              Kor: "그 따위 술기운이 내 꼴을 내가 보는 자괴감을 마비시켜 줄 리는 없었다. ",
              Eng: "There was no way such an insignificant amount of alcohol could paralyze my sense of shame or me, for that matter, in my wretched state.",
            },
            {
              Kor: '"그따위 말이 어디 있어!" ',
              Eng: "How can you say something [so stupid as] that?",
            },
            {
              Kor: '"그만둬. 새 따위는 나오지도 않는다구." ',
              Eng: '"Drop it already. There ain\'t no birds or the like coming!"',
            },
            {
              Kor: "편미분과 중적분 따위와 씨름하면서 4년을 보냈다.  ",
              Eng: "I spent 4 years wrestling with differential calculus, integral calculus, and the like ",
            },
            {
              Kor: "그러니까 그때 영어나 수학 따위를 좀 잘했다고 해서  ",
              Eng: "I mean, just because you did well in English or Math or whatever at the time …",
            },
            {
              Kor: "머뭇거림이나 에둘러 말하기 따위는 없다.  ",
              Eng: "There was no beating around the bush or talking in circles and the like.",
            },
            {
              Kor: "초초함 따위거나 그것들에 관련된 어떤 행위들이었었다. ",
              Eng: "They were acts related in regards to anxiety or etc. and whatever.",
            },
            {
              Kor: "해방감 따위에 나를 맡기면서도 ",
              Eng: "even while entrusting myself to the feelings of languidness and freedom and the like ...",
            },
          ],
        },
        {
          entryName: "딴에는 ",
          typicalExample: "【내 딴에는 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "As, as for (oneself), on one's part, in one's own [humble] estimation (somewhat deprecating).",
          sentences: [{ Kor: "내 딴에는 ", Eng: "as for myself ..." }],
        },
        {
          entryName: "딴판으로",
          typicalExample: "【가깝게 보이던 것과는 딴판으로 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN과(는) 딴판으로: quite on the contrary to, completely different from, quite unrelated to. ",
          sentences: [
            {
              Kor: "가깝게 보이던 것과는 딴판으로 ",
              Eng: "quite different from the way it looked up close ...",
            },
          ],
        },
        {
          entryName: "때문이다",
          typicalExample: "【그렇다. 도마뱀 때문이다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN때문이다: it is because of NOUN.",
          sentences: [
            {
              Kor: "그렇다. 도마뱀 때문이다.  ",
              Eng: "That's it. It's because of the lizard.",
            },
          ],
        },
        {
          entryName: "땜에 ",
          typicalExample: "【백화 땜에】",
          categoryKorean: "Dialect morphology/Substandard ",
          categoryEnglish: "(Dialect morphology/Substandard )",
          translation: "Dialect or substandard for 때문에.",
          sentences: [
            { Kor: "백화 땜에", Eng: "because of Paekhwa ..." },
            { Kor: "뭣 땜에요? ", Eng: "Because of what?" },
            {
              Kor: "내야 천가 놈 패거리 땜에 ",
              Eng: "As for myself, [I can't go to the station] because of Mr. Ch'ŏn and his gang.",
            },
          ],
        },
        {
          entryName: "또래",
          typicalExample: "【제 나이또래의 학생들 】",
          categoryKorean: "Noun",
          categoryEnglish: "(Noun)",
          translation:
            "Quasi-free noun 또래: (of) the [same or similar] age, (of) the[same or similar] size. ",
          sentences: [
            {
              Kor: "제 나이또래의 학생들 ",
              Eng: "the students about the same age as himself ...",
            },
          ],
        },
        {
          entryName: "-ㄹ",
          typicalExample: "【집에서 이런 장살 하거든요 】",
          categoryKorean: "Object Marker",
          categoryEnglish: "(Object Marker)",
          translation:
            "Contracted form of the object marker 를 after a vowel in colloquial speech.",
          sentences: [
            {
              Kor: "집에서 이런 장살 하거든요 ",
              Eng: "Our family is in this business, you see?",
            },
            {
              Kor: "이살  해오는데 ",
              Eng: "… moved and now they are back ...",
            },
            { Kor: "여길  지났어요 ", Eng: "He had passed by here." },
            { Kor: "남이 인살  하면 ", Eng: "when you greet others ..." },
            {
              Kor: "늘 속사쓸 입구퍼 했어.",
              Eng: "I've always wanted to wear an undershirt… (Here, 사쓸 = 사쓰를 = 샤쓰를)",
            },
            { Kor: "연앨 했어요 ", Eng: "We went out/we dated." },
            {
              Kor: "호랑일  좇는다면 ",
              Eng: "if we go after the tiger … (Here, 호랑일 = 호랑이를)",
            },
            {
              Kor: "절  으찌우? ",
              Eng: "What are we to do about that? (Here, 절 으찌우?  = 저를 어찌(하)오)",
            },
          ],
        },
        {
          entryName: "ㄹ-extending verbs ",
          typicalExample: "【고생살이에 찌들은 한숨】",
          categoryKorean: "Verb type",
          categoryEnglish: "(Verb type)",
          translation:
            "Treated as \"ㄹ-irregular verbs\" in traditional Korean grammar, these often turn up in pre-Liberation stories and/or in dialogue in their colloquial, 'regularized' or reanalyzed forms. In other words, substandard or dialect treatment of an ㄹ-extending verb 'adds' (or 'keeps') the ㄹ where standard grammar requires it to drop.",
          sentences: [
            {
              Kor: "고생살이에 찌들은 한숨",
              Eng: "a sigh hardened by suffering ('Standard' Korean requires 찌든 from 찌드-ㄹ-.)",
            },
            {
              Kor: "또 알 우? ",
              Eng: "Who knows? (i.e., You never know...) (알우 here is dialect for 알오?— the 아줌마-ized Semiformal (하오) Style of 알다 know—which in standard would be 아오.)",
            },
            {
              Kor: '"나중에 날 을 수가 없게 되어 멸종당했어."  ',
              Eng: '"Later they lost the ability to fly and became extinct."',
            },
            {
              Kor: "서투른  솜씨로",
              Eng: "with unexperienced hands … (From the verb 서툴다, this would be 서툰 in 'standard' Korean.)",
            },
          ],
        },
        {
          entryName: "-ㄹ1",
          typicalExample:
            "【가만히 놓을  그릇도 뎅그렁 소리가 나게 내어던지었다 】",
          categoryKorean: "Modifer",
          categoryEnglish: "(Modifer)",
          translation:
            "Prospective modifier -(으)ㄹ in a pattern meaning by rights ought to/should. ",
          sentences: [
            {
              Kor: "가만히 놓을  그릇도 뎅그렁 소리가 나게 내어던지었다 ",
              Eng: "[He] would throw down with a clang the bowl that he would normally have set down quietly.",
            },
          ],
        },
        {
          entryName: "-ㄹ2",
          typicalExample: "【망할  계집애 】",
          categoryKorean: "Modifer",
          categoryEnglish: "(Modifer)",
          translation: "Prospective modifier -(으)ㄹ used in a 욕 epithet.",
          sentences: [
            {
              Kor: "망할  계집애 ",
              Eng: "lit.: little wench that deserves to go to rack and ruin",
            },
          ],
        },
        {
          entryName: "-ㄹ 거라고",
          typicalExample: "【십중팔구 감염이 되었을 거라고 … 】",
          categoryKorean: "관용구/준꼴 Contraction",
          categoryEnglish: "(관용구/준꼴 Contraction)",
          translation:
            "Abbreviated from -ㄹ 것이라고 I'm telling you (-(이)라고) that it is/will probably/likely/the case that (-(으)ㄹ 것이다) ... . ",
          sentences: [
            {
              Kor: "십중팔구 감염이 되었을 거라고 … ",
              Eng: "[saying that] the chances are you've been infected...",
            },
          ],
        },
        {
          entryName: "-ㄹ 거면",
          typicalExample: "【입구 벗을 거면  】",
          categoryKorean:
            "Colloquia/연결어미 Connective ending/준꼴 Contraction",
          categoryEnglish:
            "(Colloquia/연결어미 Connective ending/준꼴 Contraction)",
          translation:
            "Colloquial contraction of -(으)ㄹ 것이면 if it is the case that ... ",
          sentences: [
            {
              Kor: "입구 벗을 거면  ",
              Eng: "put on and take off ... (i.e., as long as you have clothes to wear ...)",
            },
          ],
        },
        {
          entryName: "-ㄹ 건지",
          typicalExample: "【그의 식탁에 마주 앉을건지 】",
          categoryKorean: "관용구/준꼴 Contraction",
          categoryEnglish: "(관용구/준꼴 Contraction)",
          translation:
            "An abbreviation of -(으)ㄹ 것인지. The form in -인지 is the indirect question pattern in modifier -(으)ㄴ + postmodifier 지: whether (or not) it might be or will likely be the case that ...",
          sentences: [
            {
              Kor: "그의 식탁에 마주 앉을건지 ",
              Eng: "she wondered how she came to be seated across his table (from him)",
            },
          ],
        },
        {
          entryName: "-ㄹ 걸",
          typicalExample:
            "【안 쳐다보고 살 걸  남자들은 왜 그렇게들 예쁜 여자와 결혼하려고  】",
          categoryKorean: "관용구/준꼴 Contraction",
          categoryEnglish: "(관용구/준꼴 Contraction)",
          translation:
            "-(으)ㄹ 걸 as an abbreviation of -(으)ㄹ 것을 for what Martin calls the 'antithetical accusative'—given a situation of blah-blah-blah [then something in the following clause is a mismatch]. ",
          sentences: [
            {
              Kor: "안 쳐다보고 살 걸  남자들은 왜 그렇게들 예쁜 여자와 결혼하려고  ",
              Eng: "[I can't figure out why men are so crazy to] marry pretty women if they live their lives never looking at their wives",
            },
            {
              Kor: '“거기서 받으면 될 걸  왜 그래요."',
              Eng: '"Why are you being like this when you could have just answered it in there?"',
            },
          ],
        },
        {
          entryName: "-ㄹ 걸 (그랬다)",
          typicalExample: "【진작에 떴을 걸  】",
          categoryKorean: "관용구/준꼴 Contraction",
          categoryEnglish: "(관용구/준꼴 Contraction)",
          translation:
            "Built on what Martin calls the accusative to \"mark an antithetical clause that ends in a modifier + 것,\" and in origin, a contraction from -(으)ㄹ 것을 (그랬다): should have done; wish I had done; I wish I had ..., but I didn't ... (regretting lost opportunities); I should have (but I didn't).",
          sentences: [
            { Kor: "진작에 떴을 걸  ", Eng: "I should have left earlier" },
            {
              Kor: "텃논의 참새를 봐야 할 걸  하는 생각이 든다 ",
              Eng: "The thought occurred to him that he should maybe/probably check on the sparrows in the rice paddy adjoining the house. (Here this pattern is quoted as a thought to oneself.)",
            },
            {
              Kor: "용녀가 만약 말을 안 들으면 밤중에 내려가 가만히 업어올 걸  ",
              Eng: "If by chance Yongnyŏ won't do as I say, I should just go down there in the middle of the night and take her away.",
            },
          ],
        },
        {
          entryName: "-ㄹ 걸 알았다",
          typicalExample: '【"난 네가 거기 안 갈 걸 알았어 ."  】',
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 걸 알았다: Knew that something would likely not happen/be the case.",
          sentences: [
            {
              Kor: '"난 네가 거기 안 갈 걸 알았어 ."  ',
              Eng: '"I knew you wouldn\'t go there."',
            },
          ],
        },
        {
          entryName: "-ㄹ 걸(요)",
          typicalExample:
            '【"아버지도 늦게 들어오시든가, 호텔에서 주무시든가 할걸요 ."】',
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            'In origin, a contraction from -(으)ㄹ 것을(요). This is an example of what Martin calls the use of the accusative to "mark an antithetical clause that ends in a modifier + 것." The meaning is contrary to what you might expect or think, despite contrary anticipations/reservations; although, but; (it\'s) too bad (that ...), in spite of the fact that ... ; anyway, so there!',
          sentences: [
            {
              Kor: '"아버지도 늦게 들어오시든가, 호텔에서 주무시든가 할걸요 ."',
              Eng: '"And (contrary to what you might be assuming) my father will probably either come home late or else sleep at a hotel."',
            },
            {
              Kor: "이따 네시 반에나 있을 걸요  ",
              Eng: "The next bus will be at about four-thirty, I guess.",
            },
            { Kor: "미칠 것 같을 걸요  ", Eng: "You will probably go crazy." },
            {
              Kor: "어딜 가도 대학시절과는 다를 걸요  ",
              Eng: "Anywhere you go will be different from how it was back in your high-school years (contrary to your expectations ...).",
            },
            {
              Kor: "서울까지 가게 될 걸  ",
              Eng: "(You'll) end up having to go to Seoul anyway (despite your contrary expectations).",
            },
            {
              Kor: "조오치 하지만 댁은 안될 걸  ",
              Eng: "Fine. But you probably won't be able to ...",
            },
            {
              Kor: "술이나 옆에서 쳐달랠 걸  ",
              Eng: "[If Paekhwa were here] I would ask her to sit down beside me and pour me a drink.",
            },
          ],
        },
        {
          entryName: "-ㄹ 걸 잘못 했다",
          typicalExample:
            "【소년은 제가 꺾어다 줄 것을 잘못했다고  뉘우친다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'Built on what Martin calls the accusative to "mark an antithetical clause that ends in a modifier + 것," and in origin, a contraction from -(으)ㄹ 것을 잘못 했다: made a mistake in not doing ...; screwed up in not doing ...; should have done. ',
          sentences: [
            {
              Kor: "소년은 제가 꺾어다 줄 것을 잘못했다고  뉘우친다 ",
              Eng: "The boy regretted not breaking them off for her; or, The boy regretted that he had erred in not breaking them off for her.",
            },
            {
              Kor: "기다릴 걸 잘못했나  봐요 ",
              Eng: "We seem to have made a mistake in not waiting ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 것 ",
          typicalExample: "【담배와 술을 먹지 못할 것  】",
          categoryKorean: "관용구/종결 어미 Final ending",
          categoryEnglish: "(관용구/종결 어미 Final ending)",
          translation:
            '-(으)ㄹ 것: for thou shalt ... Used to intone injunctions, commandments, and other stern-sounding orders or rules, usually in writing. All the examples below are from 이태준\'s "아담의 후예" and represent all the rules for the lodgers at the missionary hospice.',
          sentences: [
            {
              Kor: "담배와 술을 먹지 못할 것  ",
              Eng: "couldn't smoke or drink ...",
            },
            {
              Kor: "몇 시에 자고 몇 시에 일어날 것  ",
              Eng: "had to go to bed at a certain time, and get up at a certain time ...",
            },
            {
              Kor: "방과 뜰을 차례로 소제할 것  ",
              Eng: "had to take turns cleaning the room and the garden ...",
            },
            {
              Kor: "손을 대지 못할 것  ",
              Eng: "couldn't touch the (fruit trees or flowers) ...",
            },
            {
              Kor: "옷의 이를 잡을 것  ",
              Eng: "had to catch lice in (his) clothes ...",
            },
            { Kor: "이를 닦을 것  ", Eng: "had to brush their teeth ..." },
            {
              Kor: "일절 외출을 못할 것  ",
              Eng: "absolutely could not go out ...",
            },
            {
              Kor: "틈틈이 성경책을 볼 것  ",
              Eng: "had to read the bible whenever (he) got a chance ...",
            },
            {
              Kor: "눈먼 사람은 도와만 줄 것  ",
              Eng: "had to only help the blind man ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 것도 없다 ",
          typicalExample: "【무어 그리 안타까울 것도 서러울 것도 없었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Often preceded by 그리. There's no reason to be ... about it; there's nothing so ... about it.",
          sentences: [
            {
              Kor: "무어 그리 안타까울 것도 서러울 것도 없었다  ",
              Eng: "But then, there was nothing particularly sad or heartbreaking about it. ",
            },
          ],
        },
        {
          entryName: "-ㄹ 것만 같다",
          typicalExample: "【앞으로도 영원히 앉아 있을 것만 같은  사람 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 것만 같다: adding particle 만 to the seem patterns in 것 같다 ",
          sentences: [
            {
              Kor: "앞으로도 영원히 앉아 있을 것만 같은  사람 ",
              Eng: "a person who seems like he will be sitting in future, too, forever",
            },
            {
              Kor: "이러다가는 아이들이 깰 것만 같다 .  ",
              Eng: "If he carries on like that, the kids are bound to wake up.",
            },
            {
              Kor: "어느 순간 멎을 것만 같아  ",
              Eng: "seemed exactly as if/seemed for certain that it might stop one moment",
            },
            {
              Kor: "자신이 사라질 것만 같은  기억",
              Eng: "the memories of it seeming for certain that she would disappear",
            },
            {
              Kor: "금방 불길이 솟을 것만 같았다  ",
              Eng: "It seemed like any minute, the fire would come gushing out (Note also adverb 금방 at once, at any moment.)",
            },
            {
              Kor: "곧 뛸 것만 같은  마음 ",
              Eng: "his intention to sprint immediately ...",
            },
            {
              Kor: "곧 회복될 것만 같았다  ",
              Eng: "It seemed he would recover right away.",
            },
            {
              Kor: "꼭 거기 아직 같이 있을 것만 같은  모양이었다 ",
              Eng: "It seemed that [the crane] thought [the dead crane] would surely be there with him.",
            },
            {
              Kor: "꾸지람을 들을 것만 같다  ",
              Eng: "He was certain they would be chewed out.  ",
            },
          ],
        },
        {
          entryName: "-ㄹ 것 없이 ",
          typicalExample:
            "【성냥을 쓸 것 없이  마른 참나무 가지나 그외의 삭정이에다 불을 당겨가지고】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Without there being any need to... ; without any need for ...-ing.",
          sentences: [
            {
              Kor: "성냥을 쓸 것 없이  마른 참나무 가지나 그외의 삭정이에다 불을 당겨가지고",
              Eng: "without there being any need for matches, just kindle a fire to a dry twig from an oak tree or other dead branches of sorts...",
            },
            {
              Kor: "그러한 무시무시한 것까지 생각할 것 없이  나는 겨우 요만 정도의 땅 위에 불빛을 한참 바라보도 있다가도",
              Eng: "without even going as far as thinking about those horrible things, (even) just while watching the small flame/firelight present on this land before me...",
            },
            {
              Kor: "그러한 것은 생각할 것 없이  그냥 그 훌륭하고 아름다운 달을 우러러봤으면 하는 마음이 앞설뿐이다.",
              Eng: "Without there being a need to think about such things, all I could think about was to look up to the magnificent, beautiful moon.",
            },
          ],
        },
        {
          entryName: "-ㄹ 것을 알았다",
          typicalExample: '【"내가 떨어질 것을 미리 알고 있었어 ." 】',
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 것을 알았다: knew that something would likely be the case or happen.",
          sentences: [
            {
              Kor: '"내가 떨어질 것을 미리 알고 있었어 ." ',
              Eng: "I already knew that I would fail.",
            },
            {
              Kor: "윤호는 전문가들의 지도를 받을 때 이미 떨어질 것을 알았다 .  ",
              Eng: "As Yunho received instruction from the experts, he already knew that he would fail.",
            },
          ],
        },
        {
          entryName: "-ㄹ 것이 분명하다",
          typicalExample: "【돌아올 게 분명하지  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "It is clear/obvious that (something will happen or be the case).",
          sentences: [
            {
              Kor: "돌아올 게 분명하지  ",
              Eng: "it's obvious that they're going to turn back ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 것이 없다",
          typicalExample:
            "【그 계집애 줄 거 없네  = 그 계집애(한테) 줄 것이 없네  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "There is no reason/point/need/call to do, there is no need to do, one need not do.",
          sentences: [
            {
              Kor: "그 계집애 줄 거 없네  = 그 계집애(한테) 줄 것이 없네  ",
              Eng: "there's no need for you to give her [any money]",
            },
          ],
        },
        {
          entryName: "-ㄹ 것처럼",
          typicalExample:
            "【그 지하철이 끝없이 순환할 것처럼  느껴지게 만드는 사람】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 것처럼: like something will do/be ... ; as if will or might do/be ...",
          sentences: [
            {
              Kor: "그 지하철이 끝없이 순환할 것처럼  느껴지게 만드는 사람",
              Eng: "a person who makes you feel as though the subway train will go round and round forever ",
            },
            {
              Kor: "내일 당장에라두 집에 갈 것처럼  말해요 ",
              Eng: "[These women talk just as if] they would go home tomorrow at the drop of a hat.",
            },
            {
              Kor: "다시는 안 볼 것처럼  ",
              Eng: "as if (he) would never see them again",
            },
          ],
        },
        {
          entryName: "-ㄹ 게고",
          typicalExample: "【아무 일이 없을 게고  】",
          categoryKorean:
            "관용구/Dialect morphology/Substandard/준꼴 Contraction",
          categoryEnglish:
            "(관용구/Dialect morphology/Substandard/준꼴 Contraction)",
          translation:
            "As abbreviation (and perhaps dialect/substandard) for -(으)ㄹ 것이고.",
          sentences: [
            {
              Kor: "아무 일이 없을 게고  ",
              Eng: "there would be nothing (wrong with my son), and ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 게다",
          typicalExample: "【이제 무슨 좋은 일이 생길 게다  】",
          categoryKorean:
            "관용구/Dialect morphology/Substandard/준꼴 Contraction",
          categoryEnglish:
            "(관용구/Dialect morphology/Substandard/준꼴 Contraction)",
          translation:
            "As abbreviation (and perhaps dialect/substandard) for -(으)ㄹ 것이다: is probably the case that ...",
          sentences: [
            {
              Kor: "이제 무슨 좋은 일이 생길 게다  ",
              Eng: "now something good will happen",
            },
            {
              Kor: "아마 만족하고 있을 게다  ",
              Eng: "he probably is satisfied ",
            },
          ],
        },
        {
          entryName: "-ㄹ 게라 ",
          typicalExample: "【틀림없이 풍년일 게라  했다 】",
          categoryKorean: "Dialect morphology/Substandard ",
          categoryEnglish: "(Dialect morphology/Substandard )",
          translation:
            "Dialect/substandard rendition of  -(으)ㄹ 것이라[고] → -(으)ㄹ 거이라[고] → -(으)ㄹ 게라[고].",
          sentences: [
            {
              Kor: "틀림없이 풍년일 게라  했다 ",
              Eng: "They said that undoubtedly it was going to be a banner year",
            },
          ],
        },
        {
          entryName: "-ㄹ 게라고",
          typicalExample: "【반드시 무슨 참변이 있을 게라고  】",
          categoryKorean: "관용구/준꼴 Contraction",
          categoryEnglish: "(관용구/준꼴 Contraction)",
          translation: "Contraction from -ㄹ 것이라고.",
          sentences: [
            {
              Kor: "반드시 무슨 참변이 있을 게라고  ",
              Eng: "[believing that] there will surely be some terrible mishap, and …",
            },
          ],
        },
        {
          entryName: "-ㄹ 게 뻔하다",
          typicalExample: "【그녀가 보면 신경이 쓰일 게 뻔하다 . 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 게 뻔하다 = -(으)ㄹ 게 뻔하다 it is clear or obvious that something will likely happen or be the case.",
          sentences: [
            {
              Kor: "그녀가 보면 신경이 쓰일 게 뻔하다 . ",
              Eng: "It was obvious that if she witnessed it, she would get annoyed.",
            },
          ],
        },
        {
          entryName: "-ㄹ 게요",
          typicalExample: "【정말, 잊어버리지 않을 게요  】",
          categoryKorean:
            "종결 어미 Final ending/Dialect morphology/Substandard",
          categoryEnglish:
            "(종결 어미 Final ending/Dialect morphology/Substandard)",
          translation:
            "As abbreviation (and perhaps dialect/substandard) for -(으)ㄹ 것이요: will likely do or be.",
          sentences: [
            {
              Kor: "정말, 잊어버리지 않을 게요  ",
              Eng: "Really, I will never forget you.",
            },
          ],
        },
        {
          entryName: "-ㄹ 겐가",
          typicalExample: "【새 복이 올 겐가  하고 】",
          categoryKorean: "관용구/Dialect morphology/Substandard",
          categoryEnglish: "(관용구/Dialect morphology/Substandard)",
          translation:
            "As abbreviation (and perhaps dialect/substandard) for -(으)ㄹ 것인가: is it the case that will likely do or be ...?",
          sentences: [
            {
              Kor: "새 복이 올 겐가  하고 ",
              Eng: "I wondered whether they would really be blessed ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 겨를(이) 있다 ~ 없다",
          typicalExample: "【생각할 겨를이 없었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Have the leisure or spare time to ...; not have the leisure or spare time to ...",
          sentences: [
            { Kor: "생각할 겨를이 없었다  ", Eng: "no spare time to think " },
          ],
        },
        {
          entryName: "-ㄹ 겨를도 없다",
          typicalExample: "【미처 피할 겨를도 없이  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Not even have a spare moment to ...",
          sentences: [
            {
              Kor: "미처 피할 겨를도 없이  ",
              Eng: "without having ['even'] a moment to get out of the way ... (Note that adverb 미처 indicates that a pattern like -(으)ㄹ 수 없- is coming up.)",
            },
          ],
        },
        {
          entryName: "-ㄹ 겨를(도) 없이",
          typicalExample: "【말릴 겨를도 없이  아래층으로 달려 내려가  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 겨를(도) 없이: without so much as a spare moment to ... ; without a chance to ... ",
          sentences: [
            {
              Kor: "말릴 겨를도 없이  아래층으로 달려 내려가  ",
              Eng: "without even giving her a chance to stop him, he had raced down to the storey below and…",
            },
            {
              Kor: "지수 자매가 어떤 궁금증을 펼칠 겨를 없이  그 방의 불이 꺼졌다.  ",
              Eng: "Without even a chance for Chisu and her sister to give vent to any sort of concerns, the light in her room went out.",
            },
          ],
        },
        {
          entryName: "-ㄹ 결심이다 ",
          typicalExample: "【길수는 기술자가 될 결심이었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "It is one's resolve or decision to ...",
          sentences: [
            {
              Kor: "길수는 기술자가 될 결심이었다  ",
              Eng: "Kilsu was resolved to become a technician.",
            },
          ],
        },
        {
          entryName: "-ㄹ 겸",
          typicalExample: "【오래간만에 고향을 둘러보고 벌이를 구할 겸  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Prospective modifier -(으)ㄹ + postmodifer 겸 combining (one thing); (doing it) with something else; (doing) along the way. Cf. the adverbial phrase 겸사겸사 doing this, that and the other; killing a couple-three birds with one stone. ",
          sentences: [
            {
              Kor: "오래간만에 고향을 둘러보고 벌이를 구할 겸  ",
              Eng: "So as to both have a look around his hometown for the first time in a long while and to look for work; so as to combine in one stroke ...",
            },
            {
              Kor: "인사도 할겸 ",
              Eng: "also take the opportunity to say hello ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 경우",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "경우 situation, case used as a postmodifier: in cases when..., in circumstances when....",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄹ 기분이다",
          typicalExample: "【따라나설 기분이었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Feel like ...-ing, be in the mood to ...",
          sentences: [
            {
              Kor: "따라나설 기분이었다  ",
              Eng: "… was in the mood to follow him.",
            },
            {
              Kor: "도무지 그럴 기분 이 아니었다 ",
              Eng: "It was not at all that kind of atmosphere.",
            },
          ],
        },
        {
          entryName: "-ㄹ 기세로 ",
          typicalExample:
            "【전화는 언제까지고 그치지 않을 기세로  울리고 있었다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 기세로: with an indication/hint that it would/might ... ",
          sentences: [
            {
              Kor: "전화는 언제까지고 그치지 않을 기세로  울리고 있었다.  ",
              Eng: "The telephone was ringing, with the attitude that it would never stop, ever.",
            },
          ],
        },
        {
          entryName: "-ㄹ 길(이) 있다/없다",
          typicalExample: "【얻어 볼 길이 없다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Postmodifier 길 way, means, course with 있다/없다: has a way to ...; has to way to ... .",
          sentences: [
            { Kor: "얻어 볼 길이 없다  ", Eng: "has no way of getting" },
            {
              Kor: "쌀 나무 들일 길이  ",
              Eng: "had arrived at this unclear situation of having to buy rice and firewood",
            },
            {
              Kor: "나는 그 도달할 길 없는  거리를 보는 데 흘려서 ",
              Eng: "I was looking at the roadless passage that I arrived at and it became unclear ...",
            },
            {
              Kor: "헤아릴 길이 없었다  ",
              Eng: "There was no way to consider the ... ",
            },
          ],
        },
        {
          entryName: "-ㄹ까(요)?1",
          typicalExample: "【저게 뭘까 .  】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Question in -(으)ㄹ까 (in origin, the prospective modifier -(으)ㄹ plus question 가) and meaning (the question) whether it is to be; will it be/do?; will it likely be the case that ... ?; Might it be the case that ... ?",
          sentences: [
            { Kor: "저게 뭘까 .  ", Eng: "What might that thing might be?" },
            {
              Kor: "어디서 본 사람일까 .  ",
              Eng: "Where was it I had seen him [I wonder]?",
            },
            { Kor: "내가 나쁜 놈일까 .  ", Eng: "Am I a jerk?" },
            {
              Kor: "언제부터 시작됐을까 ",
              Eng: "When did it start (I wonder)?",
            },
            {
              Kor: "이제 스물 세살쯤 되었을까  ",
              Eng: "Has she turned twenty-three years old by now (I wonder)?",
            },
          ],
        },
        {
          entryName: "-ㄹ까(요)?2",
          typicalExample: '【"그 책, 좀 빌릴까요 ? "】',
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation: "Tentative question in -(으)ㄹ까(요)? Might I/we ...?",
          sentences: [
            {
              Kor: '"그 책, 좀 빌릴까요 ? "',
              Eng: "Mind if I borrow that book?",
            },
          ],
        },
        {
          entryName: "-ㄹ까도 한다",
          typicalExample: "【경찰을 부를까도  생각했었지만 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Is even/also thinking of doing.",
          sentences: [
            {
              Kor: "경찰을 부를까도  생각했었지만 ",
              Eng: "[She] even thought of calling the police but ...",
            },
          ],
        },
        {
          entryName: "-ㄹ까 보다",
          typicalExample: "【아까 그 방에서 한잠 잘까 봐요  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Is thinking of doing, maybe I'll ... Usually first person.",
          sentences: [
            {
              Kor: "아까 그 방에서 한잠 잘까 봐요  ",
              Eng: "I think I'll have a nap in that room [you just showed me].",
            },
          ],
        },
        {
          entryName: "-ㄹ까 봐(서)",
          typicalExample:
            '【"아빠는 너희와 함께 계시지 못한단다"는 말이 불행스러운 느낌을 줄까 봐  】',
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ까 봐(서): for fear lest ... ; for fear that ... ; (afraid/concerned) that ... ",
          sentences: [
            {
              Kor: '"아빠는 너희와 함께 계시지 못한단다"는 말이 불행스러운 느낌을 줄까 봐  ',
              Eng: "for fear that saying \"I'm afraid your daddy can't be with you\" might make them feel unhappy",
            },
            {
              Kor: '"이번에도 우주인이 올까봐  겁이 나." ',
              Eng: "I'm afraid the alien might come again.",
            },
          ],
        },
        {
          entryName: "-ㄹ까 생각 한다",
          typicalExample: "【학교 동창이 아닐까 생각해  보았다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "To think whether it is not true that ... ; to wonder whether it is perhaps the case that ...",
          sentences: [
            {
              Kor: "학교 동창이 아닐까 생각해  보았다 ",
              Eng: "I tried to think whether [he] was not a classmate [of mine] ...",
            },
          ],
        },
        {
          entryName: "-ㄹ까 싶다 ",
          typicalExample:
            "【어쩌면 그가 내 꿈을 들여다보는 것은 아닐까 싶을  때도 있다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ까 싶다: one wonders whether it might be the case that... The tentative question in -(으)ㄹ까 functions as a quoted thought. ",
          sentences: [
            {
              Kor: "어쩌면 그가 내 꿈을 들여다보는 것은 아닐까 싶을  때도 있다.  ",
              Eng: "There were also times when I wondered whether perhaps he wasn't peering into my dreams.",
            },
          ],
        },
        {
          entryName: "-ㄹ까 한다1",
          typicalExample: "【운수가 좀 피려는 거나 아닐까 하며  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ까 한다: one wonders whether it might be the case that... The tentative question in -(으)ㄹ까 functions as a quoted thought.",
          sentences: [
            {
              Kor: "운수가 좀 피려는 거나 아닐까 하며  ",
              Eng: 'Thinking to myself, "Maybe my luck is about to change for the better?"',
            },
          ],
        },
        {
          entryName: "-ㄹ까 한다2",
          typicalExample: "【“그래서 다른 곳으로 가볼까 해요 .”】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "-(으)ㄹ까 하-: is thinking of doing.",
          sentences: [
            {
              Kor: "“그래서 다른 곳으로 가볼까 해요 .”",
              Eng: '"So I\'m thinking of going some place else."',
            },
            {
              Kor: "바닷가에나 가볼까 하는데요  ",
              Eng: "I'm thinking of going to the beach or something.",
            },
            {
              Kor: "하룻밤 묵어갈까 하고  ",
              Eng: "I was thinking of staying one night ...",
            },
            {
              Kor: "파출소로 갈까 하다  = 하다가 ",
              Eng: "considered going to the police box [but then did something else]",
            },
            {
              Kor: "혹시나 돌아올까 해서  ",
              Eng: "because I was thinking that there is a chance he might return",
            },
            {
              Kor: "낭자는 마땅히 몸을 건지는 길을 택함이 옳을까 하오 ",
              Eng: "What I suggest as the rightful action is that you save yourself (first).",
            },
          ],
        },
        {
          entryName: "-ㄹ까 하는 생각이 든다 (드-ㄹ-) ",
          typicalExample:
            "【이 여자의 임종을 지켜 주기 위해서가 아니었을까 하는 생각이 들었다 . 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "The thought occurs to one that perhaps ...",
          sentences: [
            {
              Kor: "이 여자의 임종을 지켜 주기 위해서가 아니었을까 하는 생각이 들었다 . ",
              Eng: "The thought occurred to me that it might have been in order to protect this girl from facing death.",
            },
          ],
        },
        {
          entryName: "-ㄹ께",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation: "The Immediate Future/promise-like form in -(으)ㄹ께.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄹ꼬 ",
          typicalExample: "【대체 누굴꼬 ? 】",
          categoryKorean: "해체의 종결 어미 Final ending in Intimate Style",
          categoryEnglish: "(해체의 종결 어미 Final ending in Intimate Style)",
          translation:
            'A question ending built on the prospective modifier -(으)ㄹ + postmodifier 고 for \'WH-questions\', which contrasts with -(으)ㄹ까 for yes-no questions, itself a combination of prospective modifier -(으)ㄹ + postmodifier 가 (note that the prospective modifier -(으)ㄹ often tensifies following tensifiable consonants, hence the change from 가 to 까, and from 고 to 꼬). In Middle Korean and in some Korean dialects, verbs in "WH-questions" (i.e., questions which contain a question word like "what?" "how many" or the like, as opposed to yes-no questions) take a question ending in final -고 rather than -가 as in the modern standard language, so this can be considered either an archaism or a regionalism, depending on context. ',
          sentences: [
            {
              Kor: "대체 누굴꼬 ? ",
              Eng: "Who in the world could that be? (The verb here is the copula: 누구-이-ㄹ꼬.)",
            },
          ],
        },
        {
          entryName: "-ㄹ 놈의",
          typicalExample: "【망할 놈의  일기장. 】",
          categoryKorean: "욕 Epithet",
          categoryEnglish: "(욕 Epithet)",
          translation:
            "-(으)ㄹ 놈의 NOUN for a 욕-type cuss epithet in -(으)ㄹ, but further fortified by adnominalizing this to 놈, which in turn is adnominalized to the NOUN via particle 의—friggin' SOB of a NOUN that ought to be ...-ed!",
          sentences: [
            { Kor: "망할 놈의  일기장. ", Eng: "Wretched, friggin' diary!" },
          ],
        },
        {
          entryName: "-ㄹ는지",
          typicalExample: "【받아 넣기 얼말는지  모른다 】",
          categoryKorean: "어미 Word Ending",
          categoryEnglish: "(어미 Word Ending)",
          translation:
            "The uncertain fact whether it will be or do. In origin, the odd-looking sequence –ㄹ 는- comes from early modern Korean -ㄹ 이-이던지 → -ㄹ 이-이런지 → -릴런지 → -ㄹ런지 → -ㄹ넌지, and thus is not in origin a clumsy juxtaposition of prospective modifier + processive modifier, but the framers of the 1933 Hangul Unified Orthography were unaware of its origin, and legislated the odd spelling we see now. Speakers today, if anything, associate a more tentative flavor with what is perceived as the 'extra' or 'added' prospective ㄹ in this ending. When left hanging in mid-sentence, you can interpret the pattern as having left out a following (도) 모르지만 it may have been the case that or it is uncertain WH... [but...].",
          sentences: [
            {
              Kor: "받아 넣기 얼말는지  모른다 ",
              Eng: "… was uncertain as to how much he had taken in would be ... (Here on the copula: 얼마-이-ㄹ는지).",
            },
          ],
        },
        {
          entryName: "-ㄹ는지도 모른다",
          typicalExample: "【그녀의 상상일는지도 모를  그 소리】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The uncertain fact whether it will be or do followed by 도 모른다 to mean maybe, perhaps, may do/be. In origin, the odd-looking sequence -ㄹ는- comes from early modern Korean –ㄹ 이-이런지 → -릴런지 → -ㄹ런지 → -ㄹ넌지, and thus is not in origin a clumsy juxtaposition of prospective modifier + processive modifier, but the framers of the 1933 Hangul Unified Orthography were unaware of its origin, and legislated the odd spelling we see now. Speakers today, if anything, associate a more tentative flavor with what is perceived as the 'extra' or 'added' prospective ㄹ in this ending. ",
          sentences: [
            {
              Kor: "그녀의 상상일는지도 모를  그 소리",
              Eng: "the sounds, about which it was even uncertain whether they were in her  imagination...",
            },
            {
              Kor: "가장 큰 이유가 되었을는지도 모릅니다  ",
              Eng: "It may have become the biggest (main) reason.",
            },
          ],
        },
        {
          entryName: "-ㄹ는지요",
          typicalExample: "【무슨 일자리를 구할 수 있을는지요 】",
          categoryKorean: "해요체의 종결 어미 Final ending in Polite Style",
          categoryEnglish: "(해요체의 종결 어미 Final ending in Polite Style)",
          translation:
            "Oblique/indirect question based on modifier + postmodifier 지 (i.e., -(으)ㄴ, -던, -었던, -었는, -는, -ㄹ는 + 지) where postmodifier 지 means the uncertain fact whether; perhaps; because. When a -는지-type fragment is used independently and rounded off with polite 요, it has the effect of a polite and indirect question: I wonder whether perhaps ... . ",
          sentences: [
            {
              Kor: "무슨 일자리를 구할 수 있을는지요 ",
              Eng: "[Hmm,] I don't know / who knows what sort of work we could find (As if to say 무슨 일자리를 구할 수 있을는지 모르겠다, with the final 모르겠다 replaced by a polite 요.)",
            },
          ],
        },
        {
          entryName: "-ㄹ 대로 VERB",
          typicalExample: "【벌써 될 대루  되라는 식이어서 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB-ㄹ 대로 VERB: VERB as much as/as well as it is possible to VERB; to VERB to the utmost.",
          sentences: [
            {
              Kor: "벌써 될 대루  되라는 식이어서 ",
              Eng: 'because it was already in a manner of "whatever will be will be" ...',
            },
            { Kor: "썩을 대로  썩고 ", Eng: "as corrupt as can be..." },
            {
              Kor: "익을 대로  익은 제철의 열매가",
              Eng: "the fruit in season, just as ripe as can be ...",
            },
          ],
        },
        {
          entryName: "-ㄹ데(에)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Circumstantial -ㄹ데(에): with respect to . . . -ing; as far as . . . -ing is concerned, with respect to past tense.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄹ 도리(가) 없다 ",
          typicalExample: "【어떻게 막아볼 도리는 없었기  때문이다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "There is no way of/to ...; have no way (of doing, to do).",
          sentences: [
            {
              Kor: "어떻게 막아볼 도리는 없었기  때문이다 ",
              Eng: "Because there was no way of stopping him.",
            },
            {
              Kor: "배겨낼 도리가 없었다  ",
              Eng: "There would be no way [for Kilsu] to stand [the cold].",
            },
          ],
        },
        {
          entryName: "-ㄹ 듯도 하다",
          typicalExample: "【어찌어찌 알 듯도 하였다 . 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Also seems/does indeed seem that will ...",
          sentences: [
            {
              Kor: "어찌어찌 알 듯도 하였다 . ",
              Eng: "It seemed like, somehow or other, they would figure them out. ",
            },
          ],
        },
        {
          entryName: "-ㄹ 듯 말듯 ",
          typicalExample: "【보일 듯 말듯  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Gives the impression that ...[but then again doesn't]; barely does it, can hardly if it does. ",
          sentences: [
            {
              Kor: "보일 듯 말듯  ",
              Eng: "[her head shaking] is barely discernible ... ",
            },
          ],
        },
        {
          entryName: "-ㄹ 듯싶다 ",
          typicalExample: "【어미일 듯싶은  아낙네 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier clause in -(으)ㄹ + 듯 + 싶다: it feels/seems like, as if maybe it will or would do/be; think to oneself that it will/might do/be.",
          sentences: [
            {
              Kor: "어미일 듯싶은  아낙네 ",
              Eng: "woman who seemed as if she might be a mother...",
            },
            {
              Kor: "하늘의 별이 와르르 얼굴 위에 쏟아질 듯싶게  ",
              Eng: "the stars, as if threatening to come beating down on his face with a clatter ...",
            },
            {
              Kor: "금세 그대로 하늘에라도 오를 듯 싶다  ",
              Eng: "It's as if he could get up and fly up to the sky (or the like).",
            },
          ],
        },
        {
          entryName: "-ㄹ 듯(이) ",
          typicalExample: "【난로를 통째로 껴안을 듯이  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Like, as, giving the appearance of ...; appearing (to be), looking (like); seeming as if; as if/though. This pattern is often 'flagged' in front by the adverb 마치 which serves as a sort of warning to the speaker that a like/as if pattern in 듯이 or -ㄴ/-는 것 처럼 is coming up.",
          sentences: [
            {
              Kor: "난로를 통째로 껴안을 듯이  ",
              Eng: "as if to embrace the stove in its entirety",
            },
            { Kor: "눈물이 날 듯  ", Eng: "as if tears would gush forth..." },
            { Kor: "불이 일 듯  ", Eng: "like fire rising ..." },
            {
              Kor: "누구와 싸울 듯이  ",
              Eng: "like he was fighting with someone ...",
            },
            { Kor: "엎어질 듯이  ", Eng: "as if she fell down ..." },
            {
              Kor: "젖가슴에 찢어질 듯  팽팽하였다 ",
              Eng: "[The cotton top] was so tight that it seemed it would be torn by her breasts.",
            },
            { Kor: "부숴 버릴 듯이  ", Eng: "as if to smash it completely" },
          ],
        },
        {
          entryName: "-ㄹ 듯하다",
          typicalExample: "【울 듯한  낯으로 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier + 듯 (하다) ( i.e., -ㄴ 듯하다, -ㄹ 듯하다, -는 듯하다): is like, is as (if), is like, is as (if).",
          sentences: [
            {
              Kor: "울 듯한  낯으로 ",
              Eng: "with a face that looked like it was about to cry...",
            },
            {
              Kor: "무슨 말을 할 듯하는  것을 ",
              Eng: "as though about to say something",
            },
            {
              Kor: "튀어오를 듯한  분노 ",
              Eng: "lit.: his anger, which seemed as if it might come leaping out",
            },
            {
              Kor: "부서질 듯한  낙엽 ",
              Eng: "fallen leaves that seem as though they might break",
            },
            {
              Kor: "오드득 깨어질 듯한  맑은 하늘 ",
              Eng: "the clear sky, which looked as if it would shatter ...",
            },
            {
              Kor: "피 대신에 나무 진이 흐를 듯하다  ",
              Eng: "It's just as if instead of blood, tree resin would gush out.",
            },
            {
              Kor: "금방 소리를 지르며 달려 들듯한  모습 ",
              Eng: "appearing as if any second now [the trees] would cry out and attack him ... ",
            },
          ],
        },
        {
          entryName: "-ㄹ 따름이다",
          typicalExample: "【애인이니 조금의 쓸쓸함만을 남길 따름이다 . 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 따름이다: it is just/only that ... ; all/the only thing one does is ... .",
          sentences: [
            {
              Kor: "애인이니 조금의 쓸쓸함만을 남길 따름이다 . ",
              Eng: "Since he's my boyfriend, it only leaves a bit of sadness.",
            },
            {
              Kor: "코대답은 할 따름이요  ",
              Eng: "All he did was snort a response.",
            },
            {
              Kor: "대껄을 하고 할 따름이었다  ",
              Eng: "… would just treat her and stuff.",
            },
          ],
        },
        {
          entryName: "-ㄹ 때(가) 있다",
          typicalExample:
            "【도마뱀을 준 남자를 가끔 학원에서 마주칠 때가 있었다 .  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "-(으)ㄹ 때(가) 있다: there are times when . . . ",
          sentences: [
            {
              Kor: "도마뱀을 준 남자를 가끔 학원에서 마주칠 때가 있었다 .  ",
              Eng: "There were times occasionally at the cram school when I would bump into the man who had given me the lizard.",
            },
          ],
        },
        {
          entryName: "-ㄹ 때까지도 ",
          typicalExample: "【옹크린 몸속에 머리를 파묻을 때까지도   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 때까지도: even until the point/time when ... ; even (right) up to the time when.",
          sentences: [
            {
              Kor: "옹크린 몸속에 머리를 파묻을 때까지도   ",
              Eng: "even until they buried their heads in the curled-up bodies",
            },
          ],
        },
        {
          entryName: "-ㄹ 때도 ",
          typicalExample: "【사진을 찍으러 왔다는 얘길 했을 때도  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Even when; also when. From prospective modifier -(으)ㄹ + 때 time when + particle 도 even, too; although.",
          sentences: [
            {
              Kor: "사진을 찍으러 왔다는 얘길 했을 때도  ",
              Eng: "even when I said that he had come to take pictures of the tiger ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 때면",
          typicalExample:
            "【아빠의 숨소리가 거칠게 들릴 때면  나는 잠에서 깨어났다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 때면: when, while, whenever it would happen that . . . , built on the when pattern in -(으)ㄹ 때 and copular conditional -(이)면 to mark a topic: if / when it is/be.",
          sentences: [
            {
              Kor: "아빠의 숨소리가 거칠게 들릴 때면  나는 잠에서 깨어났다.  ",
              Eng: "Whenever the sound of dad's breathing grew heavy, I would wake up from my sleep.",
            },
            {
              Kor: "깜박 잠에 빠졌나 싶을 때면   ",
              Eng: "Just when she thought she had fallen asleep …",
            },
            { Kor: "들릴 때면  ", Eng: "whenever I heard ..." },
            {
              Kor: "손님들을 쫓아갈 때면  ",
              Eng: "whenever he would run after the customers …",
            },
            {
              Kor: "학나무 밑을 지날 때면  ",
              Eng: "whenever they walked under the crane tree ...",
            },
            {
              Kor: "옥수수 같은 것을 꺾어 나눠 먹을 때면  ",
              Eng: "whenever he would break off some corn or the like and share it with someone ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 때야 ",
          typicalExample: "【기억을 더듬어 주었을 때야 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Only when ...(finally).",
          sentences: [
            {
              Kor: "기억을 더듬어 주었을 때야 ",
              Eng: "only when he had searched his memory ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 때쯤 ",
          typicalExample: "【이야기가 거기에 이르렀을 때쯤 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "About the time when ...",
          sentences: [
            {
              Kor: "이야기가 거기에 이르렀을 때쯤 ",
              Eng: "about the time when the conversation came to that point ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 때쯤 해서는 ",
          typicalExample: "【작은 마을을 지날 때쯤 해서는】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "At about the time after ...",
          sentences: [
            {
              Kor: "작은 마을을 지날 때쯤 해서는",
              Eng: "about the time when they passed by the small village ...",
            },
          ],
        },
        {
          entryName: "-ㄹ라",
          typicalExample: "【소내기가 올라】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "Prospective adjunctive' (Martin's term) -(으)ㄹ라 lest, for fear that it will (introduces a command after a warning); careful/look out—something bad is going to happen!",
          sentences: [
            {
              Kor: "소내기가 올라",
              Eng: "[You two get on home ] there's going to be a shower.",
            },
            {
              Kor: "불쌍한 늙은이가 될라",
              Eng: "lest I become a pitiful old lady...",
            },
            {
              Kor: "얼굴을 들라 소리에 ",
              Eng: "lifting her head and at the sound... ",
            },
          ],
        },
        {
          entryName: "-ㄹ라(고)",
          typicalExample: "【사 드릴라구요 = 사 드리려고요 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "In colloquial and/or substandard Korean, the intentive pattern in -(으)려(고) 한다 is often rendered -(으)ㄹ라고 한다 or  -(으)ㄹ라구 한다.",
          sentences: [
            {
              Kor: "사 드릴라구요 = 사 드리려고요 ",
              Eng: "Now I mean to buy you one.",
            },
            {
              Kor: "그건 뭘 할라고? ",
              Eng: "What are you gonna do with that?",
            },
            { Kor: "딸 줄라고 ", Eng: "I'm gonna give it to my daughter." },
          ],
        },
        {
          entryName: "-ㄹ라구",
          typicalExample: "【산 사람이 돈으 쓸 데 없을라구 】",
          categoryKorean: "종결 어미 Final ending/Dialect morphology ",
          categoryEnglish: "(종결 어미 Final ending/Dialect morphology )",
          translation:
            "Dialect/substandard -(으)ㄹ라구 meaning as if ...(not likely!) (probably related historically to intentive/purposive -(으)려(고)).",
          sentences: [
            {
              Kor: "산 사람이 돈으 쓸 데 없을라구 ",
              Eng: "As if a living person wouldn't have somewhere to spend money—yeah, right!",
            },
          ],
        },
        {
          entryName: "-ㄹ라우?",
          typicalExample: "【삼포엘 같이 가실라우?】",
          categoryKorean:
            "하오체의 종결 어미 Final ending in Semiformal Style/Dialect morphology ",
          categoryEnglish:
            "(하오체의 종결 어미 Final ending in Semiformal Style/Dialect morphology )",
          translation:
            "Dialect/substandard for -(으)려고 하오? Note that the 하오 Style functions differently (often as an equivalent to Standard 해요 or Polite Style) in dialects.",
          sentences: [
            {
              Kor: "삼포엘 같이 가실라우?",
              Eng: "Would you like to join me on the way to Samp'o? (For 가실라고 하오? Note also that the speaker here is a woman, 백화, for whom -우/-수 is more appropriate than -오/-소.)",
            },
          ],
        },
        {
          entryName: "-ㄹ래(서) ",
          typicalExample: "【싸울래 싸운 것이 아니라 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "For -ㄹ려고 해(서). VERB1 -(으)ㄹ래(서) ...VERB1: it is not that one VERBed because one had originally intended to ... (but rather ...)",
          sentences: [
            {
              Kor: "싸울래 싸운 것이 아니라 ",
              Eng: "it is not that he fought because he challenged [him] to a fight",
            },
          ],
        },
        {
          entryName: "-ㄹ러",
          typicalExample: "【토끼 가질러 왔수】",
          categoryKorean: "연결어미 Connective ending/Substandard",
          categoryEnglish: "(연결어미 Connective ending/Substandard)",
          translation:
            "Substandard for purposive -(으)러: for/to . . . ; with the purpose of -ing.",
          sentences: [
            {
              Kor: "토끼 가질러 왔수",
              Eng: "I came for the rabbit. (For 가지러 왔소.)",
            },
          ],
        },
        {
          entryName: "-ㄹ려오",
          typicalExample: "【어디루 갈려오? 】",
          categoryKorean:
            "하오체의 종결 어미 Final ending in Semiformal Style/준꼴 Contraction  ",
          categoryEnglish:
            "(하오체의 종결 어미 Final ending in Semiformal Style/준꼴 Contraction  )",
          translation:
            'Contraction from  -(으)ㄹ려[고 하]오 = standard -(으)려[고 하]오: "intend to do something" (in the 하오 Semi-Authoritative/Blunt Style).',
          sentences: [
            { Kor: "어디루 갈려오? ", Eng: "Where ya headed?" },
            {
              Kor: "거기서 기차 탈려오? ",
              Eng: "Are you intending to catch the train there?",
            },
          ],
        },
        {
          entryName: "-ㄹ려우",
          typicalExample: "【왜 좀 놀다 갈려우? 】",
          categoryKorean:
            "하오체의 종결 어미 Final ending in Semiformal Style/준꼴 Contraction  ",
          categoryEnglish:
            "(하오체의 종결 어미 Final ending in Semiformal Style/준꼴 Contraction  )",
          translation:
            'Contraction from -(으)ㄹ려[고 하]우 = -(으)려[고 하]오 “intend to do something" (in the 하오 Style, modified into the "Ajumma-ese" 하우 Style).',
          sentences: [
            {
              Kor: "왜 좀 놀다 갈려우? ",
              Eng: "Why, don't you intend to have some fun before you go? ",
            },
          ],
        },
        {
          entryName: "-ㄹ 리(가) 만무하다 ",
          typicalExample: "【청주댁이 예사로 보였을 리 만무했다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "There is absolutely no chance/likelihood of ...ing.",
          sentences: [
            {
              Kor: "청주댁이 예사로 보였을 리 만무했다 ",
              Eng: "[For Yŏngdal] there was no chance that the woman from Ch'ŏngju would look like the girl next door.",
            },
          ],
        },
        {
          entryName: "-ㄹ 리(가) 없다",
          typicalExample: "【그들을 위하여 열려질 리 없었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Prospective modifier -(으)ㄹ+ postmodifier 리(가) + 있다 ~ 없다 means there is no reasonable expectation that ...; there is absolutely no reason to suppose that ... Literally, the pattern means either there exists no good reason to do/be, but it comes to mean there is/isn't a reasonable expectation that something will happen (Note that when this pattern contains 있다, it is usually in the form of a rhetorical question.)",
          sentences: [
            {
              Kor: "그들을 위하여 열려질 리 없었다 ",
              Eng: "There was absolutely no good reason that [the rich fields there] would magically open up for them.",
            },
            {
              Kor: "그런 설명이 먹혀들 리 없었다 ",
              Eng: "There was no reason to expect that he would buy into that explanation. ",
            },
            {
              Kor: "빈집에서 저절로 불이 날 리가 없는 것이다 ",
              Eng: "There was no reason to believe that this fire had spontaneously broken out in an empty house.",
            },
            {
              Kor: "그럴 리도 없겠지만 ",
              Eng: "There is no reason to believe that [the two of them ...]; or, There is no likelihood...",
            },
            {
              Kor: "제대로 될 리가 없지 않소?",
              Eng: "There is no reason for [things] to not go smoothly, is there?",
            },
            {
              Kor: "화려할 리는 없으나",
              Eng: "There was no reason that it should be splendid but ...",
            },
            {
              Kor: "곡조를 알 리 없건만 ",
              Eng: "Though there was no way for him to know the tunes ...",
            },
            {
              Kor: "감히 잡아먹을 리가 없지 않은가",
              Eng: "Is it not the case that there is reason to suspect she would have dared to eat it? ",
            },
            {
              Kor: "다른 사람 일기장이 그녀의 화장대 위에 놓여 있을 리가 없다. ",
              Eng: "There was no reason for somebody else's diary to be lying on her dressing table.",
            },
            {
              Kor: "그 따위 술기운이 내 꼴을 내가 보는 자괴감을 마비시켜 줄 리는 없었다. ",
              Eng: "There was no way such an insignificant amount of alcohol could paralyze my sense of shame or me in my wretched state.",
            },
            {
              Kor: "누나가 그것들을 알 리가 없었다.  ",
              Eng: "There was no way for his big sister to know [of his lofty thoughts].",
            },
          ],
        },
        {
          entryName: "-ㄹ 마음이 든다",
          typicalExample:
            "【그녀에게 강좌가 있다는 것을 말해 줄 마음이 들었다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 마음이 든다 feel like ...-ing; have/develop a mind to ... .",
          sentences: [
            {
              Kor: "그녀에게 강좌가 있다는 것을 말해 줄 마음이 들었다.  ",
              Eng: "I felt like letting her know that there were lectures.",
            },
          ],
        },
        {
          entryName: "-ㄹ 만치 ",
          typicalExample: "【알아낼 수 없을 만치 컸다 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation: "Same as -ㄹ 만큼 to the extent that ...",
          sentences: [
            {
              Kor: "알아낼 수 없을 만치 컸다 ",
              Eng: "lit.: they had grown to the extent that it was impossible to distinguish them [from their mother]",
            },
          ],
        },
        {
          entryName: "-ㄹ 만큼 ",
          typicalExample: "【그립지는 아니할 만큼 들어도 오고】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Prospective modifier -(으)ㄹ+ 만큼 meaning to the extent that will do/be (and equivalent to the pattern in -ㄹ 정도(로)); enough to do, as much as necessary for doing in as much as, in so far as, since (will do); to the extent that (will do).",
          sentences: [
            {
              Kor: "그립지는 아니할 만큼 들어도 오고",
              Eng: "They often turned up to the extent that they were never missed ...",
            },
            {
              Kor: "느껴지지 않을 만큼 ",
              Eng: "[the sound seemed to go on for so long that I] became numb to it",
            },
            {
              Kor: "간신히 알아볼 만큼 ",
              Eng: "to the extent that it was barely discernible",
            },
            {
              Kor: "폭발한 것이 아닌가 의아할 만큼 ",
              Eng: "to the extent that one wondered whether it wasn't an explosion ...",
            },
            {
              Kor: "그런 꿈을 꾼 날이면 아무 말도 하고 싶지 않을 만큼 피로했지만  ",
              Eng: "On days when I dreamt such dreams, I was so tired that I didn't want to speak, but…",
            },
            {
              Kor: "눈을 뜰 수 없을 만큼 엄습하던 두려움.  ",
              Eng: "A fear that came over her to the extent that she was unable to open her eyes.",
            },
            {
              Kor: "한 옴큼의 알약을 먹는 이유가 의아스러울 만큼  ",
              Eng: "to the extent that the reason for his taking a fistful of medicine was suspicious",
            },
            {
              Kor: "그 믿을 수 없을 만큼 강렬하던 통증 ",
              Eng: "the unbelievably intense pain",
            },
            {
              Kor: "필요 없을 만큼 그가 건강한 탓이기도 했다.  ",
              Eng: "It was also because he was healthy to the extent that it was unnecessary.",
            },
            {
              Kor: "생명이 깃들였다는 것을 믿을 수 없을 만큼  ",
              Eng: "to the extent that it was impossible to believe that there was life in it",
            },
            {
              Kor: "깜짝 놀랄 만큼 날카로웠다.  ",
              Eng: "… was surprisingly sharp. ",
            },
            {
              Kor: "진짜 도마뱀으로 착각할 만큼 정교했다.  ",
              Eng: "It was so exquisite as to make one take it for a real lizard.",
            },
            {
              Kor: "더위를 팔더라도 나는 장난삼아 껄껄대며 사가죠 하고 대답할 만큼",
              Eng: 'to the extent that I would have joked and answered, "I will buy it," even if someone had said he was selling me the hotness of summer  ...',
            },
            {
              Kor: "다른 것은 다 가져가도 이것은 그 자리에 그대로 둘 만큼 보기에도 신산스러운 물건이었다.",
              Eng: "These boxes looked like they had been freshly placed there to an extent that if someone were to take out everything in the room these would still be left alone in their places.",
            },
            {
              Kor: "누구에게나 믿게 할 만큼 유난스럽고 인상적인 것이었다.",
              Eng: "It was unusual and unforgettable to the extent that it was believable to just anybody.",
            },
            {
              Kor: "새옷을 얻어입을 수 있을 만큼 아버지의 벌이는 괜찮았다.",
              Eng: "[His father's earnings were] good enough for new clothes ...",
            },
            {
              Kor: "꿈을 꿈으로만 만족할 수 없을 만큼 세정(世情)에 예민한 속인이었다. ",
              Eng: "I was a common person who was sensitive to the ways of the world to the extent that I couldn't be satisfied with only a dream.",
            },
            {
              Kor: "믿을 수 없을 만큼 재빠르게 영달이의 앞가슴을 밀어냈다. ",
              Eng: "With an unbelievable swiftness, she pushed away Yŏngdal by his chest. ",
            },
            {
              Kor: "아주머니는 내가 불쾌할 만큼 굽실굽실 고마워했다. ",
              Eng: "She thanked me to the extent that she made me feel uncomfortable.",
            },
            {
              Kor: "술을 청할 만큼 당신과 친한 사람이예요, 하는 듯이.",
              Eng: "As if to say that he was close enough of a person to me to the extent that he can demand a drink.",
            },
            {
              Kor: "지적 분위기라고는 하지만, 그것 역시도 사생활의 평온이 침해당하는 것을 참을 만큼은 아니었다",
              Eng: "It can be referred to as the intellectual atmosphere, but it was not something that went so far as to disturb the peace of my private life.",
            },
          ],
        },
        {
          entryName: "-ㄹ 만하다",
          typicalExample: "【과객을 받아들일 만한 정신적인 유산 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Prospective modifier -(으)ㄹ followed by postmodifier adjectival noun 만 하다 meaning is sufficient (to do),  is well worth (doing),  is at the point (of doing),  has reached the stage (when it can do).",
          sentences: [
            {
              Kor: "과객을 받아들일 만한 정신적인 유산 ",
              Eng: "a spiritual inheritance worthy enough to accept the passerby  ",
            },
            {
              Kor: "그런대로 참을 만한 대우 ",
              Eng: "a treatment that is, as far as it goes, bearable enough ",
            },
            {
              Kor: "그들을 알아줄 만한 사람이면 ",
              Eng: "as for those people who were good enough to inform them ... ",
            },
            {
              Kor: "놀랄 만한 ",
              Eng: "worthy of being surprised at; worth being startled at",
            },
            {
              Kor: "어제까지는 견딜 만했다 ",
              Eng: "Up until yesterday [he] was able to stand it; It had been bearable up until yesterday.",
            },
            {
              Kor: "무섬증을 느낄 만한 괴이한 소리 ",
              Eng: "sounds eerie enough to instill fear ",
            },
            { Kor: "설득시킬 만한 언어", Eng: "convincing words " },
            {
              Kor: "소 달구지 한 대쯤 지날 만한 길 ",
              Eng: "a road which was only wide enough for an ox-cart to pass through ",
            },
            {
              Kor: "돕고 끌어 줄 만한 시기 ",
              Eng: "a point at which I can help and lead [my father]",
            },
            {
              Kor: "여부도 딱히 밝힐 만한 아무 근거도 없다 ",
              Eng: "There was absolutely no grounds to …",
            },
            {
              Kor: "마을 사람들이 다 모일 만해서 ",
              Eng: "around the time when the villagers had all gathered ...",
            },
            {
              Kor: "울음 울만한 그런 취홍 ",
              Eng: "an intoxication that is worthy of a good cry ",
            },
            {
              Kor: "땔 만한 것들을 끌어모아다가 ",
              Eng: "scraped together stuff that was suitable to build a fire with ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 만한 처지도 못된다",
          typicalExample: "【여자를 돈 주고 살 만한 처지도 못 됐어.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 만한 처지도 못된다  is not even in the less-than-enviable situation of being able to ...",
          sentences: [
            {
              Kor: "여자를 돈 주고 살 만한 처지도 못 됐어.  ",
              Eng: "… was in no position to buy a woman.",
            },
          ],
        },
        {
          entryName: "-ㄹ망정",
          typicalExample: "【넉넉지는 못할망정 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation: " Even though,  it's true that ..., but (of course).",
          sentences: [
            {
              Kor: "넉넉지는 못할망정 ",
              Eng: "while it's true it wasn't enough, nonetheless ... ",
            },
            {
              Kor: "헌옷일 망정 ",
              Eng: "even though they were old clothes ...; old clothes that they might be [he just wished they would at least fit properly] …",
            },
          ],
        },
        {
          entryName: "-ㄹ 모양(으로)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "A 모양 is  an appearance, look, or air (signs, indications, symptoms way, manner). Thus this pattern means  giving the looks/having an appearance like it will;  appearing as if it will/might. ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄹ 모양이다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "A 모양 is  an appearance, look, or air (signs, indications, symptoms way, manner). Thus, this pattern means  looks like it will,  appears as if it will/might. ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄹ 무렵부터",
          typicalExample: "【방그레 웃음을 배울 무렵부터  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 무렵부터  (starting) from the (approximate) time/occasion when . ",
          sentences: [
            {
              Kor: "방그레 웃음을 배울 무렵부터  ",
              Eng: "from about the time when it learned to give a broad smile",
            },
          ],
        },
        {
          entryName: "-ㄹ 무렵(에)",
          typicalExample: "【내 곁에서 달아나 버릴 무렵에 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 무렵(에)  (at) the time/occasion (when);  at about/around the time when ... ",
          sentences: [
            {
              Kor: "내 곁에서 달아나 버릴 무렵에 ",
              Eng: "around the time when my girl took off ",
            },
            {
              Kor: "마지막 잔을 비워갈 무렵 ",
              Eng: "as (I) was (getting on to) finishing my last glass ...",
            },
            {
              Kor: "어머니가 돌아가실 무렵에 ",
              Eng: "around the time (my) mother died",
            },
            {
              Kor: "마을 사람들은 해 뜰 무렵에 ",
              Eng: "around the time the sun rose, the villagers ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 무렵이다",
          typicalExample: "【해질 무렵이었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 무렵이다  it is (at) the time/occasion (when);  it is at about/around the time when ...",
          sentences: [
            { Kor: "해질 무렵이었다 ", Eng: "It was sunset." },
            { Kor: "날이 저물 무렵이었다 ", Eng: "It was getting dark." },
            {
              Kor: "참외와 옥수수가 한창일 무렵이었다 ",
              Eng: "It was about the time when the melons and corn were in peak season. ",
            },
          ],
        },
        {
          entryName: "-ㄹ 바(가) 아니다 ",
          typicalExample: "【우리들 소설가가 알 바 아니다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 바(가) 아니다is not the place (for somebody) to VERB;   (somebody) has no business VERBing with somewhat fancy and literary/bookish 바 for 것. On its own, the pattern in -(으)ㄹ 바(이)다 means something like  is a matter or occasion (바 = ‘place') in which one might or can ...  . For example, a polite but firm way to render English  It's none of your business is 당신이 상관할 바가 아닙니다 (i.e., It's not your 'place' to know).",
          sentences: [
            {
              Kor: "우리들 소설가가 알 바 아니다 ",
              Eng: "It is no concern to us novelists.",
            },
          ],
        },
        {
          entryName: "-ㄹ 바(가) 없다",
          typicalExample: "【내 일과는 다를 바가 없다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "내 일과는 다를 바가 없다. ",
              Eng: "There is no change in my daily routine.",
            },
          ],
        },
        {
          entryName: "-ㄹ 바에(는)",
          typicalExample: "【살 바에는 조촐한 것을 택하던 초시의 성미라 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            " As long as one is going to ... (one should rather...).",
          sentences: [
            {
              Kor: "살 바에는 조촐한 것을 택하던 초시의 성미라 ",
              Eng: "if one is obliged to buy one, it was Ch’osi's way to choose the modest/humble one",
            },
            {
              Kor: "남의 신세로 얻어먹고 살 바엔 마음대로 이런것 저런것 골고루나 얻어먹어 봐야지!",
              Eng: "As long as I am going to live in the debt of others, I might as well try to get as much as I can out of it! Like this and that and all sorts of things. ",
            },
          ],
        },
        {
          entryName: "-ㄹ 배짱은 없다",
          typicalExample: "【트집잡을 배짱은 없었다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 배짱은 없다  doesn't have the guts/bravado/kahunas to ... .",
          sentences: [
            {
              Kor: "트집잡을 배짱은 없었다.  ",
              Eng: "I didn't have the courage to speak up against it. ",
            },
          ],
        },
        {
          entryName: "-ㄹ 법(이) 있다 (없다)",
          typicalExample: "【뒤서서 돌아왔다고 의심받을 법은 없다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            " There is (no) reason to expect;  it is (not) reasonable that...;  it can (not) be expected that ...;  it ought (not) to be that ...",
          sentences: [
            {
              Kor: "뒤서서 돌아왔다고 의심받을 법은 없다 ",
              Eng: "Just because he came right behind her is no reason to be the object of doubt.",
            },
          ],
        },
        {
          entryName: "-ㄹ뻔 한다 ",
          typicalExample: "【그 아내 될 뻔한 댁 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            " Almost does it. Note that this pattern is sometimes flagging or presaged at the beginning of the sentence or clause with 자칫하면, 자칫했더라면 or 하마터면 meaning  at the slightest slip,  with the slightest provocation. ",
          sentences: [
            {
              Kor: "그 아내 될 뻔한 댁 ",
              Eng: "the woman who had almost become his wife",
            },
            {
              Kor: "어쩔 뻔했을까 모르겠어요 ",
              Eng: "[I'm telling you, if you hadn't come,] I would not have known what to do ... (NB: This could be paraphrased here as 어쩔 뻔 했을지 ...)",
            },
            {
              Kor: "자칫했더라면 물에 빠질 뻔했다 ",
              Eng: "… almost fell into the water ",
            },
          ],
        },
        {
          entryName: "-ㄹ 뿐 ",
          typicalExample:
            "【그저 그런 여자스러운 감상을 담고 있을 뿐 글재주 같은 건 없었다.】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "(으)ㄹ 뿐: prospective modifier -(으)ㄹ plus postmodifier 뿐.  Nothing but, only, constantly;  all it/one does/is is ... Left 'hanging' in mid-sentence like this and with suspensive intonation, the overall sense is one of contrast between the clauses, and an implication that one is leading up to what is usually an antithetical following clause:  does/did/is/was nothing but,  only or constantly does/did/is/was;  all it/one does/did/is/was is ...",
          sentences: [
            {
              Kor: "그저 그런 여자스러운 감상을 담고 있을 뿐 글재주 같은 건 없었다.",
              Eng: "[The few love letters she had written me] simply/only contained only the usual emotions typical of women, and not much of writing talent.",
            },
            {
              Kor: "엄지손가락을 빨고 얌전히 있을 뿐 보채거나 칭얼거리지 않았다. ",
              Eng: "He just sat there, well-behaved and sucking his thumb—he didn't even whimper and whine.",
            },
            {
              Kor: "얼굴을 찡그릴 뿐 소리내어 울지 않았다.",
              Eng: "All it did was frown—it never cried out loud.",
            },
            {
              Kor: "동화화한 풍경이나 동물을 즐겨 그릴 뿐 인물이 남편의 그림에 등장하는 걸 거의 본 적이 없는 나는 적이 놀랐다.",
              Eng: "My husband enjoyed painting only fairy-tale-ified natural scenes and animals, so I was very surprised upon seeing a person in his painting.",
            },
            {
              Kor: "흰 구름이 한 점 보일 뿐 학은  보이지 않았다.",
              Eng: "All that was seen was a wisp of white cloud and a crane was nowhere in sight. ",
            },
            {
              Kor: "얼굴만 빤히 건너다볼 뿐 대답이 없었다. ",
              Eng: "[She] just stared at Togi's face without giving a reply. ",
            },
            {
              Kor: "기사를 전적으로 신봉했을 뿐 산골 사람의 주장과 내 직감은 희망적 생각에 지나지 않는다고 매도했다.",
              Eng: "They thoroughly believed the reports, and denounced the statements of a mountain villager and my instincts as nothing but hopeful thinking. ",
            },
            {
              Kor: "얼마 전에 소녀 앞에서 한 번 실수를 했을 뿐 여태 큰길 가듯이 건너던 징검다리를 오늘은 조심성스럽게 건넌다.",
              Eng: "All he had done a little while ago was to make one mistake in front of the girl, and [now] he found himself carefully crossing the stepping stone bridge which he had used to cross as if he were walking down a big wide road.",
            },
            {
              Kor: "눈을 들어 한 번 쳐다보았을 뿐 소년이 하는  대로 잠자코 있었다.",
              Eng: "All she did were raising her eyes and looking [up] at him once, and leaving the situation to the boy.",
            },
            {
              Kor: "사진을 보내었을 뿐 아버지와의 관계에서 약삭빠르게 도망쳐 버렸다.",
              Eng: "She only sent a picture and squirmed her way fast out of any relationship with her father.",
            },
            {
              Kor: "약간씩 체중을 기대어 왔을 뿐 골절이 된 사람이라고는 할 수 없을 정도로 거의 혼자서 곧바로 발걸음을 옮겨 놓았다.",
              Eng: "For a person who was supposed to have a fractured ankle, he only leaned on me slightly and right away was almost able to take steps by himself. ",
            },
          ],
        },
        {
          entryName: "-ㄹ 뿐이다 ",
          typicalExample: "【또 하나의 불공정함일 뿐이다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Prospective modifier -(으)ㄹ plus postmodifier 뿐 (plus copula)  does/did/is/was nothing but...,  only or  constantly does/did/is/was;  all it/one does/did/is/was is ...",
          sentences: [
            {
              Kor: "또 하나의 불공정함일 뿐이다. ",
              Eng: "It is probably just another inequity.",
            },
            {
              Kor: "난 그냥 좀 바쁠 뿐인데 ",
              Eng: "It's just that I'm kind of busy …",
            },
            {
              Kor: "그렇다고 한들 어디를 보나 살림 사는 아줌마일 뿐인 그녀 ",
              Eng: "my wife, who, even supposing that is the case, is just a housewife no matter how you look at it",
            },
            {
              Kor: "이루어진 사랑은 이렇게 남루한 일상을 남길 뿐인가. ",
              Eng: "Does love that has been realized leave nothing but such a shabby daily existence as this?",
            },
            {
              Kor: "모르는 결에 그것을 느낄 뿐이다 ",
              Eng: "Though he did not realize it, that was the only thing he felt.",
            },
            {
              Kor: "수수께끼 같은 웃음을 띨 뿐이요, …",
              Eng: "All he did was to flash an enigmatic smile, and ...",
            },
            {
              Kor: "눈물을 자아낼 뿐이었다 ",
              Eng: "All it did was to bring them to tears.",
            },
            {
              Kor: "무위(無爲)에서 탈출해 보려는 습관일 뿐이었다 ",
              Eng: "It was nothing but a habit for attempting to escape from my ennui.",
            },
            {
              Kor: "아름다운 달을 우러러봤으면 하는 마음이 앞설뿐이다",
              Eng: "It was merely that all I could think about was to look up at the beautiful moon above; or What came foremost in my mind was to look up at the beautiful moon above.",
            },
            {
              Kor: "아늑한 장소일 뿐이지 ",
              Eng: "I was only picturing some cozy place ...",
            },
            {
              Kor: "그 결심을 더 굳혀 줄 뿐이었다 ",
              Eng: "[That kind of feeling] only strengthened his resolve.",
            },
            {
              Kor: "그것이 그의 가장 큰 두려움일 뿐이었다 ",
              Eng: "That was his only main fear.",
            },
            {
              Kor: "불 테두리를 빙빙 돌 뿐이었다 ",
              Eng: "He only circled the circumference of the fire.",
            },
            {
              Kor: "더 한층 어수선하게 여겨질 뿐이었다 ",
              Eng: "It was only that he considered [life on the streets] to be more chaotic.",
            },
            {
              Kor: "빌빌이가 있을 뿐이다 ",
              Eng: "Only Asskisser [was behind him].",
            },
            {
              Kor: "두 패 있을 뿐이었다 ",
              Eng: "There were only two couples.",
            },
            {
              Kor: "묵묵히 앉아 있을 뿐이었다 ",
              Eng: "He was just sitting quietly.",
            },
            {
              Kor: "하나 우뚝하니 서 있을 뿐이었다 ",
              Eng: "Only one lone [cracked earthenware jar] was left standing there, conspicuously.",
            },
            {
              Kor: "몸으로 느끼고 있었을 뿐이다.  ",
              Eng: "I was simply feeling it with my body.",
            },
            { Kor: "다만 듣고 있었을 뿐이오 ", Eng: "I was merely listening." },
            {
              Kor: "좀 딱해 보였을 뿐이지요 ",
              Eng: "It was only that she looked rather pitiful singing that song.",
            },
            {
              Kor: "딱 네번 보냈을 뿐이다 ",
              Eng: "He had sent [letters] exactly four times.",
            },
            {
              Kor: "그저 고개를 주억거렸을 뿐이었다 ",
              Eng: "All he did was nod his head.",
            },
            {
              Kor: "노루 두 마리만 카메라에 담았을 뿐이었다 ",
              Eng: "All he caught in his camera were two roe deer.",
            },
            {
              Kor: "섰을 뿐이었다 ",
              Eng: "All they would do was stand there. ",
            },
          ],
        },
        {
          entryName: "-ㄹ뿐더러 ",
          typicalExample:
            "【 누구도 내 환심을 사려 들지 않을 뿐더러 나 때문에 마음 졸이지 않는다. 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "-(을)뿐더러:  not only ... but (also);  moreover;  furthermore;  as well as;  besides, in addition, on top of that.",
          sentences: [
            {
              Kor: " 누구도 내 환심을 사려 들지 않을 뿐더러 나 때문에 마음 졸이지 않는다. ",
              Eng: " Not only does nobody attempt to curry favor with me, but nobody suffers heartache on my account.",
            },
            {
              Kor: "단 한 가지 소리일뿐더러 ",
              Eng: "It was not only this one sound, but also...",
            },
          ],
        },
        {
          entryName: "-ㄹ 뿐(만) 아니라 ",
          typicalExample: "【묵인할 뿐 아니라 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            " Not only..., but (also)...;  furthermore;  as well as, besides, in addition, on top of that. Addition of 만 is optional and simply reinforces the  not only ... sense. ",
          sentences: [
            {
              Kor: "묵인할 뿐 아니라 ",
              Eng: "not only did [the supervisor] look the other way, but …",
            },
            {
              Kor: "구분할 수 있을 뿐만 아니라 ",
              Eng: "not only could he tell the different between, but ...",
            },
            {
              Kor: "산길을 더듬을 수 없을 뿐만 아니라 ",
              Eng: "not only was it impossible to grope through the mountain pass, but ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 사이(에) ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: " In the interval/ time/ space of VERBing ...",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄹ 새(도) 없이",
          typicalExample: "【아침밥도 먹을 새 없이 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Without a spare moment to ...;  having no time to ...",
          sentences: [
            {
              Kor: "아침밥도 먹을 새 없이 ",
              Eng: "without time to even eat breakfast ",
            },
            {
              Kor: "내가 뭐랄 새도 없이 ",
              Eng: "I didn't have even a split second to say anything ",
            },
          ],
        },
        {
          entryName: "-ㄹ 생각(을) 한다",
          typicalExample: "【김을 맬 생각도 않고 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Use of 생각 as a postmodifier: -(으)ㄹ 생각(을) 하- plan on / think of ...ing. ",
          sentences: [
            {
              Kor: "김을 맬 생각도 않고 ",
              Eng: "[they] didn't even think of doing the weeding ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 성싶다 ",
          typicalExample: "【토끼를 설마 숙이가 잡아먹을 성싶지는 않았다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Seems/appears (to be the likely case that), I guess (that likely).",
          sentences: [
            {
              Kor: "토끼를 설마 숙이가 잡아먹을 성싶지는 않았다 ",
              Eng: "Surely Sugi would never catch and eat that rabbit, I thought.",
            },
          ],
        },
        {
          entryName: "-ㄹ세",
          typicalExample: "【나 서참윌세 = 서참위일세 】",
          categoryKorean: "하게체의 종결 어미 Final ending in Familiar Style  ",
          categoryEnglish:
            "(하게체의 종결 어미 Final ending in Familiar Style  )",
          translation:
            "Optional equivalent to the Familiar Style indicative ending in -네 for the copula -이다 and for descriptive verbs in the Familiar or 하네 Style. This style (indicative 하네, suggestion 하세, question 하나, command 하게, retrospective statement 하데, retrospective question 하디 and optional -(으)ㄹ세 for the copula and adjectives (-이네, 좋을세), is used only among grown-ups, e.g., by a senior addressing a grown-up junior or between grown-up social equals, and is typically used by parents-in-law addressing their sons-in-law, or by a male supervisor to a male supervisee. It is widely used among adult male friends who are not close enough to use the Intimate Style, and less widely by (or to) women. To make a Familiar Style apperceptive (equivalent to -구나, -구먼요, etc. in other styles), you add -그려 or -구려 to -네.",
          sentences: [{ Kor: "나 서참윌세 = 서참위일세 ", Eng: "It's me—Sŏ." }],
        },
        {
          entryName: "-ㄹ세라 ",
          typicalExample: "【코를 골며 잠든 남편이 깰세라  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "-(으)ㄹ세라: [archaic] there is a fear of, lest, there is a danger of; it will, it might; in a way so as to avoid. ",
          sentences: [
            {
              Kor: "코를 골며 잠든 남편이 깰세라  ",
              Eng: "for fear lest her snoring husband might wake up",
            },
          ],
        },
        {
          entryName: "-ㄹ 셈으로",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Postmodifier 셈 followed by the particle 으로: with the calculation, conjecture, supposition; accounting (for).",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄹ 셈이다",
          typicalExample: "【그래 이젠 어떡헐 셈요 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Prospective modifier -(으)ㄹ + postmodifier 셈: the calculation, conjecture, supposition; accounting (for). In other words: it is one's plan or calculation to (do). ",
          sentences: [
            {
              Kor: "그래 이젠 어떡헐 셈요 ",
              Eng: "So, what do you suppose you'll do?",
            },
          ],
        },
        {
          entryName: "-ㄹ 수 있는 대로",
          typicalExample: "【얻을 수 있는 대로 얻어 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB(으)ㄹ 수 있는 대로 VERB: lit.: VERBS to the extent that it is possible to VERB, i.e., VERBs as much as is possible.",
          sentences: [
            {
              Kor: "얻을 수 있는 대로 얻어 ",
              Eng: "got as many [free tickets] as he possible could",
            },
          ],
        },
        {
          entryName: "-ㄹ 수도 있다",
          typicalExample: "【자위를 했을 수도 있잖아? 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 수도 있다: could also be the case that ...; such-and-such a possibility also exists. In other words, the can-pattern in -(으)ㄹ 수(가) 있다 can do it; the possibility of it doing or being exists, with particle 도 also on 수 possibility.",
          sentences: [
            {
              Kor: "자위를 했을 수도 있잖아? ",
              Eng: "He could have masturbated, don't you think?",
            },
            {
              Kor: "평생 아이의 눈이 될 수도 있을 것이다.  ",
              Eng: "She could even act as her child’s eyes for its whole life.",
            },
          ],
        },
        {
          entryName: "-ㄹ수록 ",
          typicalExample:
            "【어지럼증을 느낄수록 지수는 더 바짝 말의 잔등에 달라붙었다.  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "-(으)ㄹ수록: increasingly with (being/doing); the more (better, bigger, etc.)... the more. This clause with -(으)ㄹ수록 can optionally be preceded by the same verb in the conditional -(으)면 form: VERB-(으)면 VERB-(으)ㄹ수록. ",
          sentences: [
            {
              Kor: "어지럼증을 느낄수록 지수는 더 바짝 말의 잔등에 달라붙었다.  ",
              Eng: "The dizzier she felt, the more Chisu clung tightly to the horse's back.",
            },
            {
              Kor: "눈물이 빠져나올수록 몸은 ... 무겁게 가라앉았다.  ",
              Eng: "The more her tears fell, the more her body ... sank.",
            },
            {
              Kor: "그러안을수록 그악스레 울어대는 아이 ",
              Eng: "a child who, the more one held it close, the more fiercely it kept crying",
            },
            {
              Kor: "날이 갈수록 어두워지는 얼굴 ",
              Eng: "her face that darkened as the days wore on",
            },
            {
              Kor: "덜썩 겁이 나고 밤이 깊어갈수록 공포심도 커갔다",
              Eng: "I was overcome with fear—the deeper the night became, the more frightened I was.",
            },
            {
              Kor: "소녀의 그림자가 뵈지 않는 날이 계속 될수록 소년의 가슴 한구석에는 어딘가 허전함이 자리잡는 것이었다.",
              Eng: "As the days on which the girl made no appearance grew in frequency.../or, As the days on which the girl was nowhere to be seen increased, feeling of emptiness emerged in the corner somewhere in his heart. ",
            },
            {
              Kor: "생각할수록 이것이 훌륭한, 그 무슨 그루터기가 될 것 같았다. ",
              Eng: "The more he thought about it, the more this seemed it would turn out to be a wonderful, as-it-were, tree-stump.",
            },
            {
              Kor: "책은 계단을 오를수록 무거워졌다",
              Eng: "The higher he went up the stairs, the heavier the books got.",
            },
            {
              Kor: "걸을수록 백화는 말이 많아졌고",
              Eng: "The more they walked, the more Paekhwa became talkative, and ...",
            },
            {
              Kor: "생각할수록 후회되었다 ",
              Eng: "The more he thought about it, the more he regretted it.",
            },
            {
              Kor: "무용이란 건 문명국일수록 벗구 한다네그려. ",
              Eng: "The more civilized the country, the more clothes are taken off in dancing—that is what I heard.",
            },
            {
              Kor: "혼자 사는 여자일수록 깔끔해야 한다는 지론으로",
              Eng: "...of the opinion that the more of a single woman one is, the more presentable she ought to make herself.",
            },
          ],
        },
        {
          entryName: "-ㄹ 수만 있다면 ",
          typicalExample: "【될 수만 있다면 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "If only it were possible ... for wistful wishes. Use of particle 만 emphasizes the meaning only, just—i.e., it accentuates the hypotheticality or at least shows that the speaker considers the possibility being entertained to be rather more hypothetical or out of reach than usual. ",
          sentences: [
            { Kor: "될 수만 있다면 ", Eng: "if only it were possible, ..." },
          ],
        },
        {
          entryName: "-ㄹ 수밖에 (없다)",
          typicalExample: "【내 표정은 더욱 유들유들해질 수밖에 없다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "One has no choice but to...; there is nothing for it but to ... ",
          sentences: [
            {
              Kor: "내 표정은 더욱 유들유들해질 수밖에 없다.  ",
              Eng: "There was nothing for it but for my expression to grow even more impudent.",
            },
            {
              Kor: "그리로 가 비를 그을 수밖에 ",
              Eng: "they had no choice but to go over there and take shelter from the rain (NB: The 없다 is optional, and omitting it as in this example from 소나기 has a chatty, colloquial flavor [the boy is thinking to himself]). ",
            },
            {
              Kor: "예사롭게 굴 수밖에 없었고 ",
              Eng: "[I] had no choice but to act as if nothing had happened, and … ",
            },
          ],
        },
        {
          entryName: "-ㄹ 수야 없다",
          typicalExample: "【산속에다 여자를 때놓구 갈 수야 없지 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The cannot do pattern in -ㄹ 수 없다 reinforced with particle 이야 only if it be; if (it be), when it comes to; even, indeed. ",
          sentences: [
            {
              Kor: "산속에다 여자를 때놓구 갈 수야 없지 ",
              Eng: "We can't just leave a woman behind in the middle of the mountains.",
            },
          ],
        },
        {
          entryName: "-ㄹ 심산이다",
          typicalExample: "【낚을 심산이었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "A 심산 is an intention, design, calculation, so this pattern means it is one's intention to ... ; one is planning/intending to ...",
          sentences: [
            {
              Kor: "낚을 심산이었다 ",
              Eng: "He intended to fish [for the other seven and a half pairs].",
            },
            {
              Kor: "뱉을 심산이었다 ",
              Eng: "[He] felt like spitting ...; or, [He] considered spitting ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 양으로 ",
          typicalExample: "【여관비에서 목욕값이라도 뺄 양으로 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "With a mind to, pretending to, as if to, as if one might ...",
          sentences: [
            {
              Kor: "여관비에서 목욕값이라도 뺄 양으로 ",
              Eng: "as if to at least get my money's worth in terms of the water bill ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 양이다",
          typicalExample: "【배는 차지 않을 양이었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Postmodifier 양: (1) as an abbreviation of 모양 appearance, a look, an air; signs, indications, symptoms a way, manner; (2) followed by 하다 to mean pretend to do.",
          sentences: [
            {
              Kor: "배는 차지 않을 양이었다 ",
              Eng: "It seemed his stomach would never fill.",
            },
          ],
        },
        {
          entryName: "-ㄹ 엄두(를) 못 낸다",
          typicalExample: "【가까이 갈 엄두는 못 냈지요 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Cannot even conceive of the idea of",
          sentences: [
            {
              Kor: "가까이 갈 엄두는 못 냈지요 ",
              Eng: "I can't even think of going near.",
            },
          ],
        },
        {
          entryName: "-ㄹ 여유(가) 없-",
          typicalExample: "【나한테 매달릴 여유가 없다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 여유 없-: have no room/leeway/space/time to ...",
          sentences: [
            {
              Kor: "나한테 매달릴 여유가 없다. ",
              Eng: "She had no spare time to bug me.",
            },
            {
              Kor: "피곤해서 내 몸을 돌볼 여유가 없다.  ",
              Eng: "I'm so tired I have no time to take care of my own body.",
            },
          ],
        },
        {
          entryName: "-ㄹ 염(이) 있- ",
          typicalExample: "【손대일 염도 없었던 것이다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Intend to, plan on, have a mind to. The 염 here is Sino-Korean 염/-념 념 thought; notion; concept.",
          sentences: [
            {
              Kor: "손대일 염도 없었던 것이다 ",
              Eng: "He had no desire to fool around with (the old man's back-scratcher [i.e., concubine].) (Note also old spelling 대이다 → 대다.)",
            },
          ],
        },
        {
          entryName: "-ㄹ 염려(가) 있다 ~ 없다",
          typicalExample: "【평생 병 걸릴 염려는 없겠습니다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "There is/isn't a worry/concern that ...will do/be",
          sentences: [
            {
              Kor: "평생 병 걸릴 염려는 없겠습니다 ",
              Eng: "You wouldn't have to worry about getting sick your whole life!",
            },
          ],
        },
        {
          entryName: "-ㄹ 의사(이)다",
          typicalExample: "【쫓아낼 의사였던 것이 확실하다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "It is one's intention to …",
          sentences: [
            {
              Kor: "쫓아낼 의사였던 것이 확실하다",
              Eng: "It is clear that it was [Kim's] intention to drive him away [from the beginning].",
            },
          ],
        },
        {
          entryName: "-ㄹ이 만큼",
          typicalExample: "【밭을 못 얻을이 만큼 인심과 신용을 잃고 말았다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ이 만큼 (prospective modifier + postmodifier 이 + particle) to the extent that will do/be (= -(으)ㄹ 정도(로) or -(으)ㄹ 만큼) to the extent that enough to do, as much as necessary for doing.",
          sentences: [
            {
              Kor: "밭을 못 얻을이 만큼 인심과 신용을 잃고 말았다 ",
              Eng: "… ended up losing trust and popularity to the extent that he couldn't even get a plot. ",
            },
            {
              Kor: "숨이 가쁠이 만큼 불러올랐다 ",
              Eng: "[His stomach] would be so full he would have trouble breathing.",
            },
            {
              Kor: "정신이 얼떨떨할이만큼 ",
              Eng: "it was enough to bewilder you ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 일(이) 아득하- ",
          typicalExample: "【동행도 없이 길을 갈 일이 아득했다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "The prospect of ... ing is daunting/overwhelming",
          sentences: [
            {
              Kor: "동행도 없이 길을 갈 일이 아득했다 ",
              Eng: "Without even a travel partner, the trip ahead of him was daunting. ",
            },
          ],
        },
        {
          entryName: "-ㄹ 작정이다",
          typicalExample: "【어떻게 할 작정이요? 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "It is the plan/ determination/ intent to ...",
          sentences: [
            { Kor: "어떻게 할 작정이요? ", Eng: "What do you intend to do?" },
            {
              Kor: "있는 힘을 다할 작정입니다 ",
              Eng: "I intend to do everything in my power ...",
            },
            {
              Kor: "여생을 웃으며 살 작정이었다 ",
              Eng: "It was his intention to live out his remaining days smiling.",
            },
            {
              Kor: "묘지까지 나갈 작정이었으나 ",
              Eng: "it was their intention to go as far as the cemetery, but ...",
            },
            {
              Kor: "밥까지 굶을 작정이냐? ",
              Eng: "Are you determined to skip the meal? ",
            },
            {
              Kor: "봄부터 감자와 귀리를 갈 작정이다 ",
              Eng: "In the spring, he planned to grow potatoes and oats.",
            },
            {
              Kor: "맘대로 10원을 떼먹을 작정인 것이다",
              Eng: "He intends to not pay the ten wŏn. ",
            },
            {
              Kor: "정말 앞으론 가지 않을 작정이예요 ",
              Eng: "I really don't intend to go there ever again.",
            },
            {
              Kor: "절 데리고 가시잖을 작정이시군요 ",
              Eng: "Oh, I see you are not planning to take me with you!",
            },
          ],
        },
        {
          entryName: "-ㄹ 적(에)",
          typicalExample: "【작은 다리를 건널 적에 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "At the time (when) ...",
          sentences: [
            {
              Kor: "작은 다리를 건널 적에 ",
              Eng: "as they were crossing a small bridge …",
            },
            {
              Kor: "눈알을 뒤굴릴 적에는 ",
              Eng: "when she would roll her eyes backwards ...",
            },
            {
              Kor: "우리를 찾아왔을 적에는 ",
              Eng: "when he came looking for us ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 적마다",
          typicalExample: "【늘 되풀이할 적마다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Same as -(으)ㄹ 때마다: whenever; every time",
          sentences: [
            {
              Kor: "늘 되풀이할 적마다 ",
              Eng: "every time he would recount this ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 적부터 ",
          typicalExample: "【어렸을 적부터 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Same as -(으)ㄹ 때부터: (time) when",
          sentences: [
            { Kor: "어렸을 적부터 ", Eng: "(ever) since I was young ..." },
          ],
        },
        {
          entryName: "-ㄹ 전에 ",
          typicalExample: "【씨 뿌리기 시작할 바로 전에 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "전 meaning before usually appears after summative -기, but here it is functioning as a postmodifier, after the prospective modifier -(으)ㄹ: -(으)ㄹ (바로) 전에 just/right before.",
          sentences: [
            {
              Kor: "씨 뿌리기 시작할 바로 전에 ",
              Eng: "just before (the beginning of) seed planting ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 정도(로)",
          typicalExample: "【아내를 핀잔 줄 정도는 아니게 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "To the point that, to the extent that",
          sentences: [
            {
              Kor: "아내를 핀잔 줄 정도는 아니게 ",
              Eng: "went as far as giving an empty glass to my wife in a manner which said 'no' ...",
            },
            {
              Kor: "턱이 덜그럭거릴 정도로 ",
              Eng: "to the extent that your chin bobs ...",
            },
            {
              Kor: "혼자 갔다올 정도였다 ",
              Eng: "[He had improved] to the point that he could go and come back on his own.",
            },
            {
              Kor: "할 수 없을 정도로 ",
              Eng: "[it was] to the point that it was hard to say [that here was a man with bone fractures]",
            },
          ],
        },
        {
          entryName: "-ㄹ 정도이다",
          typicalExample: "【날아다닐 수도 있을 정돕니다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "… is to/at the point that ...; ... is to the extent that ...",
          sentences: [
            {
              Kor: "날아다닐 수도 있을 정돕니다 ",
              Eng: "It will get to the point that I will even be able to fly.",
            },
            {
              Kor: "시체가 부패하는 냄새조차 나지 않았을 정도였지.  ",
              Eng: "It was to the extent that you couldn't smell the corpse rotting.",
            },
          ],
        },
        {
          entryName: "-ㄹ 제 ",
          typicalExample: "【억지로 버티어 갈 제】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Variant of -(으)ㄹ 적(에): the time (when) when; as.",
          sentences: [
            {
              Kor: "억지로 버티어 갈 제",
              Eng: "as they kept on forcing themselves to stick it out …",
            },
            {
              Kor: "병법을 익힐 제는 ",
              Eng: "when he was practicing his military tactics …",
            },
          ],
        },
        {
          entryName: "-ㄹ 줄(을) 모른다",
          typicalExample: "【남을 의심할 줄 모르는 시어머니 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Not to know how to do; be incapable of ...-ing",
          sentences: [
            {
              Kor: "남을 의심할 줄 모르는 시어머니 ",
              Eng: "her mother-in-law, who did not know how to mistrust others ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 줄 몰랐다",
          typicalExample: "【그녀가 혼자 술을 마시고 있을 줄은 몰랐다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 줄 몰랐다: had no idea that something would be the case",
          sentences: [
            {
              Kor: "그녀가 혼자 술을 마시고 있을 줄은 몰랐다.  ",
              Eng: "I had no idea that she would be drinking by herself.",
            },
            {
              Kor: "설마 당신 진짜로 일찍 들어올 줄 몰랐다는 둥  ",
              Eng: "[She kept talking—things like] or I didn't dream that you would really come home early tonight, etc.",
            },
          ],
        },
        {
          entryName: "-ㄹ 줄 안다 (아-ㄹ-)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The pattern in -(으)ㄹ 줄 안다 means presumes/thinks/assumes (often earnestly) that ...",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄹ 줄(로) 알았다",
          typicalExample: "【여우와 신포도 우화 같은 반응을 보일 줄 알았더니】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 줄(로) 알았다 -: thought that ...would/might, was under the impression that .. would/might ",
          sentences: [
            {
              Kor: "여우와 신포도 우화 같은 반응을 보일 줄 알았더니",
              Eng: "I had expected her to react like in the fable of the fox and the sour grapes, but …",
            },
            {
              Kor: "대충 누르다 갈 줄 알았는데 끈질기다.  ",
              Eng: "I thought he would ring the bell a couple of times and then go, but he's persistent.",
            },
            {
              Kor: "안 죽을 줄 알았더니 ",
              Eng: "I was sure that she wouldn't die, but ...",
            },
            {
              Kor: "그러고는 내가 평생 변함없을 줄 알고 나와 결혼했다. ",
              Eng: "After that, she assumed I would remain unchanged for the rest of my life and married me.",
            },
          ],
        },
        {
          entryName: "-ㄹ 즈음에야",
          typicalExample:
            "【사라져 갈 즈음에야 서참위는 그가 누구인 것을 깨달아낸 것이다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Only /finally at approximately the juncture when ... ; only at about the time when ...",
          sentences: [
            {
              Kor: "사라져 갈 즈음에야 서참위는 그가 누구인 것을 깨달아낸 것이다. ",
              Eng: "Only at the juncture when he was about to disappear had Sŏ finally figured out who he was.",
            },
          ],
        },
        {
          entryName: "-ㄹ 지경으로",
          typicalExample: "【해주지 못할 지경으로 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Being in [the unfortunately or lamentable] situation, condition, circumstance of ...",
          sentences: [
            {
              Kor: "해주지 못할 지경으로 ",
              Eng: "as (he) had never had the wherewithal to ...",
            },
            {
              Kor: "숨이 막힐 지경으로 ",
              Eng: "to the point where it made breathing difficult ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 지경이다",
          typicalExample: "【사람들끼리 서로 잡아먹게 될 지경인데 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Is on the verge of [something undesirable]; is about to [suffer something]",
          sentences: [
            {
              Kor: "사람들끼리 서로 잡아먹게 될 지경인데 ",
              Eng: "any minute people would start eating one another ...",
            },
            {
              Kor: "어쨌든 나중엔 거의 환장할 지경이었다 ",
              Eng: "Anyhow, Yŏngdal got to the point where he would, in the end, go berserk.",
            },
            {
              Kor: "머리가 아프고 호흡이 탁할 지경이었다 ",
              Eng: "It was a situation in which one's head would hurt and one's breathing obstructed.",
            },
          ],
        },
        {
          entryName: "-ㄹ지니라",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "In origin, the prospective modifier -(으)ㄹ + postmodifier 디 (→ 지) + copula -이- + modifier -ㄴ + copula -(이)라. In other words, one could paraphrase it in modern Korean as -ㄹ 바인 것이다. This seems clunky, and indeed, this patterns sounds ponderous and archaic to modern ears.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㄹ지(도) 모른다",
          typicalExample:
            "【내 속을 떠보려고 귀찮은 시비를 걸어 올지도 모른다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ지(도) 모른다 lit.: doesn't know whether it might perhaps do/be, hence, just perhaps might do/be ",
          sentences: [
            {
              Kor: "내 속을 떠보려고 귀찮은 시비를 걸어 올지도 모른다. ",
              Eng: "Who knows, she might pick an annoying fight just to peer into my inner thoughts.",
            },
            {
              Kor: "그에게는 산후의 정상적인 모습으로 비쳐질지도 모를 일이었다. ",
              Eng: "To him [for all she knew], it probably seemed like a normal appearance after childbirth.",
            },
            {
              Kor: "그리고 이도 옮을지 몰라.  ",
              Eng: "And you might get lice, too. ",
            },
            {
              Kor: "실제로는 한 번도 본 적이 없어서일지 모른다.  ",
              Eng: "In actual fact, it may be because I had never seen it even once.",
            },
            {
              Kor: "스테디한' 관계라고는 할 수 없을지 모른다. ",
              Eng: "Perhaps it can't be called a 'steady' relationship.",
            },
            {
              Kor: "윤호를 아주 약한 아이로 기억하고 있을지도 모를 일이다.  ",
              Eng: "For all he knew, [the girls] remembered him as a weakling. ",
            },
          ],
        },
        {
          entryName: "-ㄹ지라",
          typicalExample: "【누구나 다 알지라 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "In function, same as -(으)ㄹ지니라 meaning it is proper / right to do; surely will, must (archaic/poetic/fancy). In origin, the prospective modifier -(으)ㄹ + postmodifier 디 (→ 지) + copula -이라.",
          sentences: [
            { Kor: "누구나 다 알지라 ", Eng: "everyone must know ..." },
          ],
        },
        {
          entryName: "-ㄹ지라도 ",
          typicalExample: "【실패는 했을지라도 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "(설령) ... -(으)ㄹ지라도: even if it were so; granted that it were so. In origin, the prospective modifier -(으)ㄹ + postmodifier 디 (→ 지) + copular infinitive -이라 + particle 도, meaning even though, although, regardless of; even if it should be the case that ...",
          sentences: [
            {
              Kor: "실패는 했을지라도 ",
              Eng: "even if he had had a few failures …",
            },
            {
              Kor: "설령 도둑질이라고 할지라도 ",
              Eng: "even if it is called stealing ...",
            },
            {
              Kor: "눈에 보이는 모든 기관들이 정상이라 할지라도 ",
              Eng: "even though [one says that] all the organs visible to the eye are normal ….",
            },
          ],
        },
        {
          entryName: "-ㄹ지라도 모른다",
          typicalExample:
            "【어쩌면 울 수 없는 막힌 성대를 가진 아이일지라도 모를 일이었다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "어쩌면 ...-(으)ㄹ지라도 모르-: i.e., a bland of the patterns in (설령) ... -(으)ㄹ지라도 even if it were so; granted that it were so and (어쩌면) ...-(으)ㄹ지도 모르- (lit.: one doesn't know whether) maybe, perhaps it might be the case that. ",
          sentences: [
            {
              Kor: "어쩌면 울 수 없는 막힌 성대를 가진 아이일지라도 모를 일이었다. ",
              Eng: "It was the case that she couldn't tell whether it might be a child somehow with blocked vocal cords, one that couldn't cry.",
            },
          ],
        },
        {
          entryName: "-ㄹ지매",
          typicalExample: "【유감이 깊을지매 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "This form does not appear in any of the standard dictionaries, but appears to mean since, because. Presumable from prospective modifier -(으)ㄹ + postmodifier 디 (→ 지) + copular infinitive -이- + substantive -ㅁ + (old) locative 애. ",
          sentences: [
            {
              Kor: "유감이 깊을지매 ",
              Eng: "since she lamented so deeply ...",
            },
          ],
        },
        {
          entryName: "-ㄹ 참이다 ",
          typicalExample: "【인제 망월 (望月)을 할 참인 것이다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㄹ 참이다: is a juncture/crucial moment when, wouldn't you know it, something unanticipated happens; be on the verge of doing; be just about to do ",
          sentences: [
            {
              Kor: "인제 망월 (望月)을 할 참인 것이다",
              Eng: "It was now just about the time for moon-gazing.",
            },
            {
              Kor: "죄 없이 내 얼굴만 붉어질 참이다.  ",
              Eng: "All I could do was go innocently red in the face. ",
            },
          ],
        },
        {
          entryName: "-ㄹ 터이다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'Prospective modifier -(으)ㄹ + postmodifier 터 expectation, anticipation; intention, plan + -이다. The literal sense of this pattern is: it is the [reasonable] expectation that someone/something will do or be. When the subject of the verb is first person (I or we), the "plan/intention" meaning comes more into play.',
          sentences: [
            { Kor: "[Examples?]", Eng: "" },
            {
              Kor: "염소와 돼지와 닭을 칠 터 ",
              Eng: "he'll probably raise goats, pigs and chickens",
            },
          ],
        },
        {
          entryName: "-ㄹ 테다",
          typicalExample: '【"깨끗이 물러날 테야." 】',
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'Prospective modifier -(으)ㄹ + postmodifier 터 expectation, anticipation; intention, plan + -이다, with 터 + 이 contracted 테. Alternatively, you can think of the postmodifier as 테 + -(이)다. Completely identical with the pattern in -(으)ㄹ 터이다, but more colloquial. The literal sense of this pattern is: it is the [reasonable] expectation that someone/something will do or be. When the subject of the verb is first person (I or we), as in the following examples, the "plan/intention" meaning comes more into play.',
          sentences: [
            {
              Kor: '"깨끗이 물러날 테야." ',
              Eng: '"I promise to make a clean break [from her]."',
            },
            {
              Kor: '"네가 나를 피하는 이유를 말하기 전엔 안 갈 테야." ',
              Eng: "\"I'm not going until you tell me the reason you're avoiding me.\"",
            },
            {
              Kor: "안 줄 테야 ",
              Eng: "[Otherwise it is the expectation that] I won't give it to you.",
            },
            {
              Kor: "누그르 어쩔테야 ",
              Eng: "What can anybody do (to me) about it?",
            },
            {
              Kor: "살 테다 ",
              Eng: "I'm going to ['intending to'] buy [a house better than yours]",
            },
            {
              Kor: "그럴 테지",
              Eng: "You would expect/it would be reasonable to anticipate that he would say that, wouldn't you/isn't it?",
            },
            {
              Kor: "무려할 테지  ",
              Eng: "It will most likely be/I expect it will be no less than [5 hwan each].",
            },
            {
              Kor: "공사가 새 봄으로 연기될 테고 ",
              Eng: "The construction work would likely be postponed until the next (lit.: new) spring, and …",
            },
            {
              Kor: "누가 사지도 않을 테구 (← 테고) ",
              Eng: "It is likely that no one will buy it ...",
            },
            {
              Kor: "바우녀석이 와서 데려갔을 테지 ",
              Eng: "That scoundrel Pau must have come and taken them.",
            },
          ],
        },
        {
          entryName: "-ㄹ 테면",
          typicalExample: "【어디 먹을 테면 먹어봐 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "The expectation/intention pattern in -(으)ㄹ 테다 combined with conditional -(으)면 typically has the structure VERB-을테면 VERB-어 봐 and is a taunt meaning something like You want to VERB?—Go ahead and give it your best shot. ",
          sentences: [
            {
              Kor: "어디 먹을 테면 먹어봐 ",
              Eng: "lit.: If it's your intention to eat me, go ahead and try; i.e.: Ya wanna eat me? Just go ahead and try!",
            },
          ],
        },
        {
          entryName: "-ㄹ 테니(까)",
          typicalExample: '【"너를 포기할 테니까 답을 가르쳐달라고 했어." 】',
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            'The expectation/intention pattern in -(으)ㄹ 테다 combined with -(으)니(까) meaning, literally, since it is the expectation or anticipation that .... will do/be ... . When the subject of the verb is first person (I or we), the "plan/intention" meaning comes into play, usually followed by a command or request, and the first clause often the sense of a promise to be carried out conditional upon the other person\'s carrying out the command or request in the second clause.',
          sentences: [
            {
              Kor: '"너를 포기할 테니까 답을 가르쳐달라고 했어." ',
              Eng: '"He said he\'d give you up if I gave him the answers."',
            },
            {
              Kor: "그리고 그를 만져서도 안 돼. 그가 사라질 테니까.  ",
              Eng: "You also may not touch him, because he'll disappear.",
            },
            {
              Kor: "비밀은 내가 지킬 테니 나 하자는 대로 할까? ",
              Eng: "Since I intend to keep the secret, shall we do it the way I am suggesting? ",
            },
            {
              Kor: "수히 갚을 테니 백 원만… ",
              Eng: "Suhi, I'll pay you back, so can you lend me just a hundred wŏn?",
            },
            {
              Kor: "아무래도 월출서 기차를 탈테니까 정거장 목만 지키면 된다구.",
              Eng: "Because (when it comes down to it) she's likely to take the train at Wŏlch'ul, all we have to do is to guard around the (entrance of the) station. ",
            },
            {
              Kor: "여기서 지체하면 하룻밤 자게 될 테니",
              Eng: "If we dawdle here we'll end up having to spend the night. ",
            },
            { Kor: "가져 갈 테니 ", Eng: "because I'm going to take it back" },
            {
              Kor: "얼마 달아나지 못했을 테니 따라가서 머리채를 끌구 와요.",
              Eng: "Since she couldn't have gone that far, so drag her back here by her hair/yank her by her hair and drag her back here.",
            },
            {
              Kor: "앞으로 오빠라고 부를 테니까 절 서울로 데려다 주시겠어요?",
              Eng: "Since I will call you 'older brother' from now on, will you take me with you back to Seoul?",
            },
            {
              Kor: "출장 갔다고 할 테니까 오시지 않으셔두 돼요.",
              Eng: "I will tell (them you) went on a business trip (so you don't need to come)",
            },
            {
              Kor: "준비할 테니 드시고 가셨으면 …",
              Eng: "Since I'll be preparing it anyway, why don't you help yourself to a meal …?",
            },
            {
              Kor: "전무님이 되면 책임이 더 무거워질 테니 말야",
              Eng: "I mean, if you become the managing director, you'll probably have much more responsibility.  ",
            },
            {
              Kor: "당신은 피로하실 테니 제가 출장 가신 것을 잘 말씀드리겠어요.",
              Eng: "Since you must be exhausted, I will explain to him well that you've gone on a business trip. ",
            },
            {
              Kor: "당신은 피로하실 테니 제가 출장 갔다고 할 테니까 오시지 않으셔두 돼요.",
              Eng: "Since you will probably be tired I will tell him that you went on a business trip so you don't have to come. ",
            },
            {
              Kor: "다음과 같은 원인들 때문일 테니 그 점에 유의하셔서 저를 치료해 보십시오. ",
              Eng: "Because it will be due to the following reasons, you are welcome to try to cure me in careful regards to those points. ",
            },
          ],
        },
        {
          entryName: "-ㄹ 텐구 ",
          typicalExample: "【무얼 가지구 살림은 해나가랄 텐구?】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            '-ㄹ 텐고? is built on -(으)ㄹ 테expectation, anticipation; intention, plan and the dialect question marker in -(으)ㄴ고? In Middle Korean and in some Korean dialects, verbs in "WH-questions" (i.e., questions which contain a question word like "what?" "how many" or the like, as opposed to yes-no questions) take a question ending in final -고 rather than -가 as in the modern standard language.',
          sentences: [
            {
              Kor: "무얼 가지구 살림은 해나가랄 텐구?",
              Eng: "With what are we to go on living?",
            },
          ],
        },
        {
          entryName: "-ㄹ 텐데(요)",
          typicalExample: "【감천엔 어차피 밤에 떨어질텐데 】",
          categoryKorean: "연결어미 Connective ending/종결 어미 Final ending",
          categoryEnglish:
            "(연결어미 Connective ending/종결 어미 Final ending)",
          translation:
            "Prospective modifier -(으)ㄹ + postmodifier 터 expectation, anticipation; intention, plan + copula -이- + circumstantial (imminent elaboration) -ㄴ데. (Alternatively, one can think of this as The literal sense of this as prospective modifier -(으)ㄹ + postmodifier 테 + (dropped) copula -이- +  -ㄴ데.) The meaning is: it is the [reasonable] expectation that someone/something will do or be (and yet/but) ...; I suppose/fear ... is/will be.",
          sentences: [
            {
              Kor: "감천엔 어차피 밤에 떨어질텐데 ",
              Eng: "Since I expect that we're going to arrive at Kamch'ŏn at night …",
            },
            {
              Kor: "우린 뒷차를 탈텐데 ",
              Eng: "We'll be taking the next train ...",
            },
            {
              Kor: "막차루 돌아올텐데 ",
              Eng: "I suppose they'll be returning with the last bus ...",
            },
            {
              Kor: "짝을 채줘야 할 터인데 ",
              Eng: "We will have to find it a mate ...",
            },
            {
              Kor: "기운이 배나 들었을텐데 ",
              Eng: "You must have consumed twice as much energy ...",
            },
            {
              Kor: "시장하실 텐데",
              Eng: "[Are you still asleep, ma'am?] I should expect you're hungry.",
            },
            {
              Kor: "고통이 대단하실 텐데 ",
              Eng: "The pain must be significant ...",
            },
            {
              Kor: "항구로 발전할 수도 있었을 텐데요?",
              Eng: "As it is close to the sea, wouldn't you expect it to have been developed into a harbour?",
            },
            {
              Kor: "지금은 겨울 방학중일 텐데요 ",
              Eng: "He should be in the middle of his winter vacation right about now. ",
            },
          ],
        },
        {
          entryName: "-ㄹ 틈도 없다",
          typicalExample: "【손을 써볼 틈도 없었습니다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Have no room or spare time to ...; no space to ...",
          sentences: [
            {
              Kor: "손을 써볼 틈도 없었습니다 ",
              Eng: "There was no space to even put our foot in the door.",
            },
          ],
        },
        {
          entryName: "-ㄹ 틈도 없이 ",
          typicalExample: "【자랑스러워할 틈도 없이  바쁘다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Without a second to even ...; without even a spare moment to ...",
          sentences: [
            {
              Kor: "자랑스러워할 틈도 없이  바쁘다 ",
              Eng: "… so busy that there isn't even time to be arrogant",
            },
          ],
        },
        {
          entryName: "-ㄹ 판에",
          typicalExample: "【막연할 판에  이르렀다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "A 판 (= 판국) is a situation—something 'happening' or 'going down'—a state of affairs, and usually a somewhat less-than-satisfactory or high-class one. Thus, this pattern means: in the (somewhat shabby or embarrassing) situation where/that.",
          sentences: [
            {
              Kor: "막연할 판에  이르렀다",
              Eng: "… had arrived at this unclear state of affairs of having to buy rice and firewood.",
            },
          ],
        },
        {
          entryName: "-ㄹ 판이다",
          typicalExample: "【2원의 생돈을 물어야 될 판이다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "A 판 (= 판국) is a situation—something 'happening' or 'going down'—a state of affairs, and usually a somewhat less-than-satisfactory or high-class one. Thus, this pattern means: is the somewhat shabby or embarrassing situation where ...",
          sentences: [
            {
              Kor: "2원의 생돈을 물어야 될 판이다  ",
              Eng: "He was looking at the unsavory possibility of having to pay an additional two wŏn.",
            },
          ],
        },
        {
          entryName: "-ㄹ 필요가 있다 ~ 없다 ",
          typicalExample: "【이런 직업적 고층을 오래 생각할 필요는 없다 .】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "A 필요 is a need or necessity. Hence, this pattern means: It is necessary~unnecessary to ... ; there is ~ isn't a need to ...",
          sentences: [
            {
              Kor: "이런 직업적 고층을 오래 생각할 필요는 없다 .",
              Eng: "There is no need to dwell on occupational predicaments like these.",
            },
            {
              Kor: "그곳에 머물러 있을 필요가 없었다  ",
              Eng: "There was no more need stay any longer in that place.",
            },
          ],
        },
        {
          entryName: "-라고 그런다",
          typicalExample:
            "【간암 환자 빼고 그런 놈 있으면 나와 보라고 그래 .  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)라고 그런다: tells/order somebody to do something ",
          sentences: [
            {
              Kor: "간암 환자 빼고 그런 놈 있으면 나와 보라고 그래 .  ",
              Eng: "Liver cancer patients aside, if there's any asshole that fits that description, let him step forward.",
            },
          ],
        },
        {
          entryName: "-라구",
          typicalExample: "【댕기라구  그래라 = 다니라고 그래라】",
          categoryKorean: "Dialect morphology/ Substandard ",
          categoryEnglish: "(Dialect morphology/ Substandard )",
          translation: "Substandard/dialect for quoted commands in -(으)라고.",
          sentences: [
            {
              Kor: "댕기라구  그래라 = 다니라고 그래라",
              Eng: "Tell her to go back to running around doing the actress thing.",
            },
          ],
        },
        {
          entryName: "-라는1",
          typicalExample: "【온천장으로 가는 게 좋을 거라는  조언을 했다 】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation: 'For -(이)라[고 하-]는 the "quote"',
          sentences: [
            {
              Kor: "온천장으로 가는 게 좋을 거라는  조언을 했다 ",
              Eng: "He advised me that it would be good to go to a hot-spring.",
            },
          ],
        },
        {
          entryName: "-라는2",
          typicalExample:
            "【무엇을 사라는  소리가 = 무엇을 사라고 하는 소리 】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            'Contracted from -(으)라[고 하]는: telling/ordering somebody "do it!"',
          sentences: [
            {
              Kor: "무엇을 사라는  소리가 = 무엇을 사라고 하는 소리 ",
              Eng: 'sounds to the effect of, "Buy something!"',
            },
            {
              Kor: "댁은 오라는  데가 있어서 ",
              Eng: "[Are you here] because someone invited you? (데 here indicating a person, somebody.)",
            },
          ],
        },
        {
          entryName: "-라는 건 ",
          typicalExample: "【혼자라는 건  무슨 뜻? 】",
          categoryKorean: "관용구/준꼴 Contraction",
          categoryEnglish: "(관용구/준꼴 Contraction)",
          translation:
            "For -(이)라는 것은 the (putative) fact that, contracted from -(이)라[고 하]는 것은.",
          sentences: [
            { Kor: "혼자라는 건  무슨 뜻? ", Eng: "What do you mean 'alone'?" },
          ],
        },
        {
          entryName: "-라는 것 ",
          typicalExample: "【그녀의 화장이 퇴근 준비라는 것 을 】",
          categoryKorean: "관용구/준꼴 Contraction",
          categoryEnglish: "(관용구/준꼴 Contraction)",
          translation:
            "The putative or alleged fact that ... ,  contracted from -(이)라[고 하]는 것.",
          sentences: [
            {
              Kor: "그녀의 화장이 퇴근 준비라는 것 을 ",
              Eng: "[even though I knew] that the makeup was her end-of-the-day routine ... ",
            },
          ],
        },
        {
          entryName: "-라는 대로",
          typicalExample: "【하라는 대로  】",
          categoryKorean: "관용구/준꼴 Contraction",
          categoryEnglish: "(관용구/준꼴 Contraction)",
          translation:
            "Contracted quotation from -(으)라[고 하]는 combined with the pattern in -는 대로: just as, as, just as it is/was; in accordance with.",
          sentences: [
            { Kor: "하라는 대로  ", Eng: "doing it as she was told " },
          ],
        },
        {
          entryName: "-라는 소리",
          typicalExample:
            "【집에서 듣는 것만도 지겨운데 밖에서까지 그만 마시라는 잔소리  들어 가며 】",
          categoryKorean: "관용구/준꼴 Contraction",
          categoryEnglish: "(관용구/준꼴 Contraction)",
          translation:
            "-(으)라는 소리 ← -(으)라[고 하]는 소리: lit.: sounds telling/ordering one to ... ",
          sentences: [
            {
              Kor: "집에서 듣는 것만도 지겨운데 밖에서까지 그만 마시라는 잔소리  들어 가며 ",
              Eng: "It was bad enough hearing it at home, but to have to listen to her nagging about stopping my drinking even when we were out, too …",
            },
          ],
        },
        {
          entryName: "-라니?",
          typicalExample: "【아스라니? 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "The pattern in -(으)라니? belongs to a set of 'echoed quotations' which have the effect of throwing back, challenging, or reiterating/emphasizing something just said. The form -(으)라니? is an echoed command (← -으라[고 하]니) and queries the command made: Whadda ya mean, COMMAND? (How could you say that?) As an insistent statement, this form can be used to repeat a command to someone who appears to have ignored the first one you gave: 앉으라니까! I said sit down! (Are you deaf?) Note that in this latter usage, the sequential ending usually shows up in its extended shape -(으)니까—i.e., with the 까 added. ",
          sentences: [{ Kor: "아스라니? ", Eng: "Take it? Whaddaya mean?" }],
        },
        {
          entryName: "-라니(요)?",
          typicalExample: "【저라니? 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "The pattern in -(이)라니(요)? belongs to a set of 'echoed quotations\" which have the effect of throwing back, challenging, or reiterating/emphasizing something just said. The form -(이)라니(요)? is an echoed statement (← -(이)라[고 하]니) and queries the statement made: Whadda ya mean, STATEMENT? (How could you say that? or, What do you mean by that?) ",
          sentences: [
            {
              Kor: "저라니? ",
              Eng: "Whaddaya mean '저'? or, What do you mean (by saying) '저'—i.e.: What do you mean, \"uh...\"? (What is there to think about?)",
            },
          ],
        },
        {
          entryName: "-라니까1",
          typicalExample: '【"없다고 그러라니까요 ."】',
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "The pattern in -(으)라니까! belongs to a set of 'echoed quotations' which have the effect of reiterating/emphasizing something just said. The form -(으)라니까! is an echoed command (← -으라[고 하]니까) used to repeat a command to someone who appears to have ignored the first one given: 앉으라니까! I said sit down! (Are you deaf?) Note that in this usage, the sequential ending usually shows up in its extended shape of -(으)니까—i.e., with the 까 added. I told you—...!; I insist that you do as I said!; For crying out loud, I said ...",
          sentences: [
            {
              Kor: '"없다고 그러라니까요 ."',
              Eng: "I told you, tell her I'm not here!",
            },
            { Kor: "염려 마시라니까요  ", Eng: "I told you, don't worry!" },
            {
              Kor: "이것 좀 보라니까  ",
              Eng: "I told you to check this out for a second!",
            },
            {
              Kor: "빨리 가서 역을 지키라니까  ",
              Eng: "Get out there and keep a look out at the station, I tell you!",
            },
          ],
        },
        {
          entryName: "-라니까2",
          typicalExample: "【과객이라니까 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "The pattern in -(이)라니까! belongs to a set of 'echoed quotations' which have the effect of reiterating/emphasizing something just said. The form -(이)라니까! is an echoed copular statement (← -(이)라[고 하]니까) used to repeat a statement to someone who appears to have ignored the first one given: I told you—...!; For crying out loud, I said that it's ...  Note that in this usage, the sequential ending usually shows up in its extended shape of -(으)니까—i.e., with the 까 added.",
          sentences: [{ Kor: "과객이라니까 ", Eng: "A passerby, I told you." }],
        },
        {
          entryName: "-라니깐1",
          typicalExample: "【석방을 하라니깐  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "석방을 하라니깐  ",
              Eng: "since they're telling us to release...",
            },
            {
              Kor: "그래도 그 친구 크게 맘 쓴 거라니깐 ",
              Eng: "Still, I'm telling you she's gone to a lot of effort!",
            },
          ],
        },
        {
          entryName: "-라더니 ",
          typicalExample: "【아닌 밤중에 홍두깨라더니  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            'Contracted quotation ← -(이)라[고 하]더니. Retrospective sequential -더니 means as now it has been observed that ... ; when (now/then) since (now/then) ... ; ... and now/then; but now/then and usually implies a marked or abrupt contrast between the first and second clauses: says/thinks "QUOTE," whereupon...',
          sentences: [
            {
              Kor: "아닌 밤중에 홍두깨라더니  ",
              Eng: 'just like they say "a bolt from out of the blue", but ...',
            },
          ],
        },
        {
          entryName: "-락 ...-(으)락거리-",
          typicalExample: "【고르게 오르락  내리락거리는   남자의 가슴 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)락 ...-(으)락거리-. Cf. the pattern in -(으)락 ...-(으)락하다: alternate between two different actions or conditions. Here the alternating patterns or conditions are treated as one large mimetic and suffixed with -거리다",
          sentences: [
            {
              Kor: "고르게 오르락  내리락거리는   남자의 가슴 ",
              Eng: "the man's chest, rising up and down evenly",
            },
          ],
        },
        {
          entryName: "-락 ...-(으)락하다 ",
          typicalExample:
            "【도마뱀의 혀가 들락날락하면서 내는 소리가 아닌가 싶다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)락 ...-(으)락하다: alternate between two different actions or conditions ",
          sentences: [
            {
              Kor: "도마뱀의 혀가 들락날락하면서 내는 소리가 아닌가 싶다.  ",
              Eng: "I wonder if perhaps it isn't the sound of the lizard's tongue going in and out.",
            },
            {
              Kor: "들락  날락했다  ",
              Eng: "[the cold wind] swept freely in and out [of the crotch of his trousers at will]",
            },
          ],
        },
        {
          entryName: "-락 말락하다 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "One of two alternating actions or conditions.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-란1 ",
          typicalExample: "【노동숙박소란 것이 있지요 】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "Contracted quotation from -(이)라 [고 하-]ㄴ: which is/are called",
          sentences: [
            {
              Kor: "노동숙박소란 것이 있지요 ",
              Eng: "There are those places called 'Labourers' Quarters', I suppose.",
            },
          ],
        },
        {
          entryName: "-란2",
          typicalExample: "【칼날로 잘라내기란 …】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "Contracted quotation from -(이)라 [고 하-]ㄴ: that which is/are called, used to set up a generic definition or value judgement.",
          sentences: [
            {
              Kor: "칼날로 잘라내기란 …",
              Eng: "[The act of] cutting with a razor [was not an easy thing to do].",
            },
          ],
        },
        {
          entryName: "-란 말이다",
          typicalExample: "【손 좀 봐주란 말야   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Interjected 말야 ← 말이야 following a contracted QUOTE + verb of saying + modifier as a kind of post-modifier, and meaning it is to say = I mean, you know, you see, uh, that is. Here, the contraction is from a quoted command in -(으)라[고 하 느]ㄴ 말[이]야.",
          sentences: [
            {
              Kor: "손 좀 봐주란 말야   ",
              Eng: "I'm tellin' ya, smack him around a bit [as a lesson to the others].",
            },
            {
              Kor: "나는 어떡하란 말인가   ",
              Eng: "So what is it you're telling me to do?",
            },
          ],
        },
        {
          entryName: "-란 말야",
          typicalExample: "【어디란 말야 = 어디라고 하는 말이야? 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Interjected 말야 ← 말이야 following a contracted QUOTE + verb of saying + modifier as a kind of post-modifier, and meaning it is to say = I mean, you know, you see, uh, that is. Here, the contraction is from a quoted copular statement in -(이)라[고 하느]ㄴ 말[이]야.",
          sentences: [
            {
              Kor: "어디란 말야 = 어디라고 하는 말이야? ",
              Eng: "Just where [on earth] are you saying this place is?",
            },
            {
              Kor: "손 좀 봐주란 말야   ",
              Eng: "I'm tellin' ya, smack him around a bit [as a lesson to the others].",
            },
          ],
        },
        {
          entryName: "-랄 ",
          typicalExample: "【무얼 가지구 살림은 해나가랄 텐구? 】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation: "For -(으)라[고 하]ㄹ.",
          sentences: [
            {
              Kor: "무얼 가지구 살림은 해나가랄 텐구? ",
              Eng: "With what are we to go on living? (Built on -(으)ㄹ 테 expectation, anticipation; intention, plan and also includes the dialect question marker in -(으)ㄴ고?)",
            },
          ],
        },
        {
          entryName: "-래(요)1",
          typicalExample: "【그럼 누구 소개래요 ? 】",
          categoryKorean: "종결 어미 Final ending/Colloquial",
          categoryEnglish: "(종결 어미 Final ending/Colloquial)",
          translation:
            "Colloquial contracted quotation from NOUN-(이)라[고 ㅎ]ㅐ(요): says that is is NOUN (quoted copula)",
          sentences: [
            {
              Kor: "그럼 누구 소개래요 ? ",
              Eng: "So, by whose introduction (does he say he knows us)?",
            },
          ],
        },
        {
          entryName: "-래(요)2",
          typicalExample: "【[Examples?]】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Contracted quotation from -(으)라[고 ㅎ]ㅐ(요): tells (somebody) to ... (quoted command)",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-랴1 ",
          typicalExample: "【거친 땅을 풀 수가 있으랴   】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation: "Will it do or be? (usually rhetorical).",
          sentences: [
            {
              Kor: "거친 땅을 풀 수가 있으랴   ",
              Eng: "Is it possible to till this harsh earth? (answer: of course not)",
            },
            {
              Kor: "신신하랴  ",
              Eng: "Will it be/feel fresh and nice? (answer: of course not)",
            },
            {
              Kor: "이런 노다지가 또 어디 있으랴   ",
              Eng: "lit.: Is there ever going to be another jackpot like this? (There is never ...)",
            },
            {
              Kor: "아니냐다르랴  ",
              Eng: "Sure enough ... (Here equivalent to 아니나다를까 meaning just as was expected, as might have been expected, sure enough.)",
            },
            {
              Kor: "하지만 오지 않은들 그게 무슨 큰일이랴. ",
              Eng: "But even if he shouldn't come, what is the big deal in that?",
            },
          ],
        },
        {
          entryName: "-랴2 ",
          typicalExample: "【어디로 가랴 하는 타협 】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation: "Shall (should) I?",
          sentences: [
            {
              Kor: "어디로 가랴 하는 타협 ",
              Eng: "a compromise to the effect of “Where would it go?” (answer: nowhere)",
            },
            {
              Kor: "술 한잔 사주랴? ",
              Eng: "Would you like me to buy you a drink?",
            },
            {
              Kor: "운임은 얼마나 주랴 ",
              Eng: "How much should I pay you ['give you as a fee']?",
            },
          ],
        },
        {
          entryName: "-러",
          typicalExample: "【물을 길러 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Purposive -(으)러 for the purpose of ...-ing; in order to ... (usually with verbs of motion). Stems ending in vowels and -ㄹ- take -러, all others take -으러.",
          sentences: [
            {
              Kor: "물을 길러 ",
              Eng: "[The girls went to the well] to draw water. (Here on 긷다, 길어, 길러 but one would expect 길으러.)",
            },
          ],
        },
        {
          entryName: "-려거든 ",
          typicalExample: "【벼락을 치실려거든】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "From -(으)려(고) (하)거든 (i.e., intentive -려 + provisional -거든): provided that, if/when (usually followed by command, suggestion, or promise).",
          sentences: [
            {
              Kor: "벼락을 치실려거든",
              Eng: "if you intend to strike with thunder ...",
            },
          ],
        },
        {
          entryName: "-려(고) ",
          typicalExample: "【한번 위로해 주려 = 위로해 주려고 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Intentive -(으)려(고) [shape is -려 after vowels and the ㄹ of L-dropping bases]: with the thought in mind to do, with the intention of doing, with the desire to do; ready (prepared, willing) to do",
          sentences: [
            {
              Kor: "한번 위로해 주려 = 위로해 주려고 ",
              Eng: "intending/so as to comfort him once",
            },
            {
              Kor: "아픈 곳을 찌르려고 ",
              Eng: "intending to prick where it hurt",
            },
            {
              Kor: "돈을 벌려고 이 고생이다 ",
              Eng: "It is in order to make money that he is suffering through this.",
            },
          ],
        },
        {
          entryName: "-려(고) 든다",
          typicalExample:
            "【누구도 내 환심을 사려 들지 않을 뿐더러 나 때문에 마음 졸이지 않는다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)려(고) 든다 tries, attempts, has an intention to ... ; gets/is on the verge of doing, is about to, threatens to do, aims at doing, tries to, intends to (equivalent to -(으)려(고) 한다). ",
          sentences: [
            {
              Kor: "누구도 내 환심을 사려 들지 않을 뿐더러 나 때문에 마음 졸이지 않는다.  ",
              Eng: "Not only does nobody attempt to curry favor with me, but nobody suffers heartache on my account.",
            },
            {
              Kor: "달래려 들었던 이 과부 아주머니 ",
              Eng: "this widowed woman who had tried to ease [her enormous worry]",
            },
            {
              Kor: "고집을 굳이 세우려 들 줄을 몰랐다 ",
              Eng: "… didn't have it in him to be perversely stubborn.",
            },
          ],
        },
        {
          entryName: "-려고 마련이다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Or (으)ㄹ라고 마련이다 it is in the general state of things that ... Note also modifier clause in -(으)ㄴ 듯 + 싶- it feels/seems like, as if maybe; think to oneself that ...",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-려고만 하면",
          typicalExample: "【알려고만 들면 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "알려고만 들면 ",
              Eng: "if you only/just made the effort to know ...",
            },
          ],
        },
        {
          entryName: "-려(고) 한다",
          typicalExample: "【친구들은 애써 웃으며 들으려 한다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "intentive in -(으)려(고) 한다 [shape is -려 after vowels and the ㄹ of ㄹ -dropping bases] with the thought in mind to do, with the intention of doing, with the desire to do; ready (prepared, wiling) to do. In colloquial Korean, this is often rendered -(으)ㄹ라고 하다.",
          sentences: [
            {
              Kor: "친구들은 애써 웃으며 들으려 한다.  ",
              Eng: "Friends [like me] forced themselves to wear a smile as we listened.",
            },
            {
              Kor: "의사를 찾아가려 하지 않았다.  ",
              Eng: "She did not make an attempt to go see a doctor.",
            },
          ],
        },
        {
          entryName: "-려고 혈안이 된다",
          typicalExample: "【호랑이를 발견하려고 혈안이 돼있다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Be frantic trying to ...",
          sentences: [
            {
              Kor: "호랑이를 발견하려고 혈안이 돼있다 ",
              Eng: "There is a craze of attempting to discover tigers. ",
            },
          ],
        },
        {
          entryName: "-려나 보다",
          typicalExample: "【나의 심평이 차차 피려나부다 하였다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "From -(으)려[고 하]나 보- seems like it is fixin' to ... ; appears as though it is on the verge of ...-ing.",
          sentences: [
            {
              Kor: "나의 심평이 차차 피려나부다 하였다 ",
              Eng: "It seemed like my fate was gradually fixin' to improve.",
            },
          ],
        },
        {
          entryName: "-려나 한다",
          typicalExample: "【눈이 오려나 하는 막연한 기대 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Abbreviation of -(으)려(고 하)나 한다. ",
          sentences: [
            {
              Kor: "눈이 오려나 하는 막연한 기대 ",
              Eng: "with the vague expectation that it might (was fixing to/preparing to) snow …",
            },
          ],
        },
        {
          entryName: "-려는",
          typicalExample: "【모이를 쪼으려는 닭 ← 모이를 쪼으려고 하는 닭 】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "Contraction of intentive -(으)려[고 하]는 which is intending to ... ; with/having the intention to ...",
          sentences: [
            {
              Kor: "모이를 쪼으려는 닭 ← 모이를 쪼으려고 하는 닭 ",
              Eng: "a chicken about to peck at some feed",
            },
            {
              Kor: "나가려는 서참위 ← 나가려고 하는 서참위 ",
              Eng: "Mr. Sŏ, who was [lit.: is] just about to go out",
            },
            {
              Kor: "또 쫓아 내려는 개수작이다 ",
              Eng: "It's a cheap trick to chase him out again.",
            },
            {
              Kor: "사내 재미까지 보려는 눈치였다 ",
              Eng: "She seemed bent on going as far as to take pleasure in having men.",
            },
            {
              Kor: "뒤나 보려는 사람처럼 ← 보려고 하는 ",
              Eng: "like a person about to take a crap",
            },
            {
              Kor: "막 넘어가려는 5월의 햇살 ",
              Eng: "the May sun rays, scurrying to get over",
            },
            {
              Kor: "의식적으로 무시하려는 태도 ",
              Eng: "behaving as if consciously trying to ignore",
            },
            {
              Kor: "아줌마들을 주눅들여 책을 팔려는 얄팍한 상술이다.  ",
              Eng: "It's a shallow strategy to browbeat housewives in order to sell books.",
            },
          ],
        },
        {
          entryName: "-려는 게",
          typicalExample:
            "【허겁지겁 옷을 벗기고는 서둘러 삽입하려는 게 그의 방식이었다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)려는 게 ← -(으)려[고 하]는 것이 [the fact of] trying to/intending to ...",
          sentences: [
            {
              Kor: "허겁지겁 옷을 벗기고는 서둘러 삽입하려는 게 그의 방식이었다.  ",
              Eng: "Hurrying to strip off my clothes and then rushing to insert himself were the marks of his style.",
            },
          ],
        },
        {
          entryName: "-려는데",
          typicalExample: "【끓고 있는 기름에 새우를 집어 넣으려는데  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "-(으)려는데 just as one is getting ready to do something ...  or on the verge of ...-ing, just at the juncture when one is intending to ...  , contracted from purposive -(으)려(고) 하- plus the Imminent Elaboration pattern in -는데, and close in meaning and function to -(으)려는 참에 and -(으)려던 참에. ",
          sentences: [
            {
              Kor: "끓고 있는 기름에 새우를 집어 넣으려는데  ",
              Eng: "Just as I was about to put the shrimps into the boiling oil …",
            },
            {
              Kor: "다음 페이지를 넘기려는데 밖에서 문 따는 소리가 들려 왔다. ",
              Eng: "Just as I was about to turn to the next page, I heard the sound of somebody unlocking the door outside.",
            },
          ],
        },
        {
          entryName: "-려는 듯(이)",
          typicalExample: "【박훈장의 울음소리를 듣지 않으려는 듯이 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "From intentive -(으)려(고) 하는 + 듯(이) ( i.e.: -(으)ㄴ 듯(이), -(으)ㄹ 듯(이), -는 듯(이)) as (if) intending to ...",
          sentences: [
            {
              Kor: "박훈장의 울음소리를 듣지 않으려는 듯이 ",
              Eng: "as if not wanting to listen to Schoolmaster Pak's sobbing ",
            },
          ],
        },
        {
          entryName: "-려는 듯한 ",
          typicalExample: "【잇몸으로 호두알을 깨려는 듯한 무력감 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "From intentive -(으)려(고) 하는 + 듯 (하다) ( i.e.: -(으)ㄴ 듯, -(으)ㄹ 듯, -는 듯) as (if) intending to ...",
          sentences: [
            {
              Kor: "잇몸으로 호두알을 깨려는 듯한 무력감 ",
              Eng: "the feeling of powerlessness that one has when trying to crack a walnut with one's gums",
            },
          ],
        },
        {
          entryName: "-려는 순간 ",
          typicalExample: "【지수가 유리에 손을 대려는 순간  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)려는 순간 ← -(으)려[고 하]는 순간 just at the moment when one means to/is intending to ... ; just as one is about to ... ",
          sentences: [
            {
              Kor: "지수가 유리에 손을 대려는 순간  ",
              Eng: "Just at the moment when Chisu was about to extend her hand to the glass …",
            },
          ],
        },
        {
          entryName: "-려는지 ",
          typicalExample:
            "【그녀는 밥을 지으러 나가려는지 스웨터를 걸치는데  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "-(으)려는지 ← -(으)려[고 하]는지. A combination of the intentive pattern in -(으)려(고) 한다 and the 'perhaps because' pattern with modifier [-(으)ㄴ, -던, -었던, -었는, -는] + post-modifier 지the uncertain fact whether… When left hanging in mid-sentence, you can interpret the pattern as having left out a following -도 모르지만: it may be the case that…, i.e. perhaps [it is] because ...  ",
          sentences: [
            {
              Kor: "그녀는 밥을 지으러 나가려는지 스웨터를 걸치는데  ",
              Eng: "She is putting on a sweater, perhaps to go out to the veranda to make rice.",
            },
          ],
        },
        {
          entryName: "-려는 참에",
          typicalExample: "【아이들에게 줄 유인물을 복사하려는 참에  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)려는 참에  from -(으)려(고 하)는 참(에) just as one is about to or on the verge of ...-ing, just at the juncture when one is intending to ...",
          sentences: [
            {
              Kor: "아이들에게 줄 유인물을 복사하려는 참에  ",
              Eng: "Just as I was about to xerox some handouts for the students ...",
            },
          ],
        },
        {
          entryName: "-려니 ",
          typicalExample: "【아마 저러다가 멎으려니 했으나 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Plus verb of thinking (often omitted), this pattern has the same sense as tentative sequential -거니 with the likely fact or reason that... + verb of quotation: (says/thinks) that [quote] is most likely the case that..., is probably the case that.... The main difference between the patterns with -(으)려니 and -거니 is that the former is explicitly forward-looking/future in meaning, being in origin a combination of the prospective modifier -(으)ㄹ + the quasi-free noun 이 thing + -거니: *-(으)ㄹ 이 + -이거니 → *-(으)ㄹ 이거니 → *-(으)리거니 → *(으)리어니 → -(으)려니, with the loss of ㄱ after 이 typical of an earlier stage of Korean.",
          sentences: [
            {
              Kor: "아마 저러다가 멎으려니 했으나 ",
              Eng: "I thought that surely, she would stop after a while, but ...",
            },
            {
              Kor: "손님을 기다리려니 ",
              Eng: "as I waited for a guest to come [I had a hard time of it]",
            },
            {
              Kor: "다시 한번 세상에 부딪쳐 보려니 믿어졌다 ",
              Eng: "He came to believe that he would again get his innings with the world once more.",
            },
            {
              Kor: "절름발이 천가 여편네 노릇을 하려니 ",
              Eng: "for her to play the role of wife for this limping Mr. Ch'ŏn ...",
            },
            {
              Kor: "나눠 주려니 하고 기다렸다 ",
              Eng: "[He] waited thinking that (surely/likely) she would give the three of them their share of the money.",
            },
            {
              Kor: "잘만 먹으면 다 되려니 생각하였다 ",
              Eng: "I thought that it will probably be OK if only it will eat well.",
            },
            {
              Kor: "이십 리 길을 부지런히 걸으려니 ",
              Eng: "He considered the prospect of walking twenty li, but ...",
            },
            {
              Kor: "옛날처럼 살려니 됩니까 ",
              Eng: "Is it possible for me to try and live as I did in the past? ",
            },
          ],
        },
        {
          entryName: "-려니(까)",
          typicalExample:
            "【거울에 바싹 붙어 서 있으려니 젖꼭지가 세면대에 닿는다.  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "-(으)려니(까): when or just as one attempts to ... one then finds/discovers that ... .",
          sentences: [
            {
              Kor: "거울에 바싹 붙어 서 있으려니 젖꼭지가 세면대에 닿는다.  ",
              Eng: "As I try to stand up close to the mirror, my nipple touches the sink.",
            },
          ],
        },
        {
          entryName: "-려니와 ",
          typicalExample: "【국민학교 교장은 물론이려니와 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation: "But; and anyway (what's more), moreover",
          sentences: [
            {
              Kor: "국민학교 교장은 물론이려니와 ",
              Eng: "Of course, he was the principal of an elementary school, but what's more ...",
            },
            {
              Kor: "보는 것도 처음이려니와 ",
              Eng: "It was also the first time they had seen it, but ...",
            },
            {
              Kor: "더 나아진 것도 없으려니와 ",
              Eng: "Not only was there no improvement, but what's more ...",
            },
          ],
        },
        {
          entryName: "-려다 만다 (마-ㄹ-) ",
          typicalExample: "【농담에 웃으려다 말고 입을 다물었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Base + intentive -(으)려 + transferentive -다(가) followed by 말다 cease, desist meaning stops in mid-action, or drops what one is doing in favor of a new action.",
          sentences: [
            {
              Kor: "농담에 웃으려다 말고 입을 다물었다 ",
              Eng: "I was about to laugh at her joke, but I held back (lit.: closed my mouth).",
            },
          ],
        },
        {
          entryName: "-려던 ",
          typicalExample: "【단념하려던 딸의 생각도 】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "From intentive -(으)려(고 하)던 which one had been about to or on the verge of ...",
          sentences: [
            {
              Kor: "단념하려던 딸의 생각도 ",
              Eng: "thoughts of his daughter which he was about to give up on ...",
            },
          ],
        },
        {
          entryName: "-려던 것이 ",
          typicalExample:
            "【이루어 보려던 것이 그만 어떤 일본 사람 가게 앞에서 낚싯대 도적으로 붙들린 것이었다.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The or one time/occasion when...; one time when (sets up an 'ended up ...-ing' pattern in the following clause).",
          sentences: [
            {
              Kor: "이루어 보려던 것이 그만 어떤 일본 사람 가게 앞에서 낚싯대 도적으로 붙들린 것이었다.",
              Eng: "One time when he attempted to realize this desire, he ended up getting caught as a fishing rod thief in front of some Japanese guy's store.",
            },
          ],
        },
        {
          entryName: "-려던 것이다",
          typicalExample: "【청진까지 가보려던 것이었노라 한다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Was about to/on the verge of…",
          sentences: [
            {
              Kor: "청진까지 가보려던 것이었노라 한다 ",
              Eng: "He claimed he had just been about to go all the way to Ch'ŏngjin.",
            },
          ],
        },
        {
          entryName: "-려던 참(에)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Just at the juncture when one was about to ...",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-려던 참이다",
          typicalExample: "【신마이루 기리까이하려던 참이었어 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "From -(으)려(고 하)던 참이다: had been about to or on the verge of..., intending to ...",
          sentences: [
            {
              Kor: "신마이루 기리까이하려던 참이었어 ",
              Eng: "I was on the verge of having her exchanged for a new face.",
            },
          ],
        },
        {
          entryName: "-려면",
          typicalExample: "【산성(山性)을 가지려면 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Intentive -(으)려면 ← -(으)려[고 하]면: [shape is -려 after vowels and the ㄹ of L-dropping bases] with the thought in mind to do, with the intention of doing, with the desire to do; ready (prepared, willing) to do. In colloquial Korean, this is often rendered -(으)ㄹ려면 or -ㄹ라면.",
          sentences: [
            {
              Kor: "산성(山性)을 가지려면 ",
              Eng: "in order to achieve a mountain character ...",
            },
          ],
        },
        {
          entryName: "-려무나 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "Martin calls this the cajolative: do please come now (for an endearing command), and says that this longer version in -(으)려무나 is softer than the shorter -(으)렴.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-려야 ",
          typicalExample: "【돈을 모으려야 모을 수 없고 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Even if one tries/intends to... [usually followed by a negative or frustrated result]. In origin, the pattern is just an abbreviation of the intentional pattern in -(으)려[고] followed by particle (이)야 only if it be (marks a reinforced contingency or prerequisite for a main clause, one or the other of which is unlikely, unexpected, or anticipated to be difficult or unpleasant). ",
          sentences: [
            {
              Kor: "돈을 모으려야 모을 수 없고 ",
              Eng: "Even if he had wanted to, there was no way to save any money, i.e. Try as he might, it was impossible to save money.",
            },
          ],
        },
        {
          entryName: "-려 하면 ",
          typicalExample: "【출국장을 나서려 하면  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)려 하면 ← -(으)려[고] 하면: if/when one intends to/is about to ... ",
          sentences: [
            {
              Kor: "출국장을 나서려 하면  ",
              Eng: "when she intended to/made to step into the departures area",
            },
          ],
        },
        {
          entryName: "-련만",
          typicalExample: "【달장간이 되련만 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "-(으)련만: I had hoped that …, but ...; I would have expected that …, but ...; it should have ... , but ...; I wish that ... , but ...",
          sentences: [
            {
              Kor: "달장간이 되련만 ",
              Eng: "It had already been about a month, but ...",
            },
            {
              Kor: "아가씨와 몇 마디 주고받는 게 아무 일도 아니련만 ",
              Eng: "It ought to be no big deal to share a few words with a girl, but …",
            },
            {
              Kor: "직접으로 회사에 소작료를 바치게나 되었으면 그래도 나으련만",
              Eng: "It would, nonetheless, have been preferable if we had simply ended up paying tenant fees to the company, but... ",
            },
            {
              Kor: "이 집에 네 가구가 있으련만 ",
              Eng: "There were four families in this house, but ...",
            },
            {
              Kor: "하늘이 가까우련만 ",
              Eng: "You'd expect that the sky would be close, but ...",
            },
          ],
        },
        {
          entryName: "-렴",
          typicalExample: "【더 깊이 들어가주렴. 나는 도마뱀에게 애원한다.  】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "-(으)렴: Martin calls this the cajolative: won't you please ... ?; Why don't you go ahead and ... ?; Do please come now (for an endearing command), and says that the longer version in -(으)려무나 is softer.",
          sentences: [
            {
              Kor: "더 깊이 들어가주렴. 나는 도마뱀에게 애원한다.  ",
              Eng: "Go on, go in deeper. I plead to the lizard.",
            },
            {
              Kor: "나가렴",
              Eng: "Get out of here, will you? (the force of the command here is probably somewhat sarcastic) ",
            },
          ],
        },
        {
          entryName: "-렷다",
          typicalExample: "【아이가 나면 소같이 산같이 튼튼하게 자라렷다 】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "Intentive assertive -(으)렷다 [old-fashioned]. Nowadays usually spelled -으렸다 it is sure/bound/agreed to happen, surely it will happen; you WILL do it (understand? → suggesting a threat).",
          sentences: [
            {
              Kor: "아이가 나면 소같이 산같이 튼튼하게 자라렷다 ",
              Eng: "If we have a baby, it will surely grow to be as sturdy as the ox and the mountains.",
            },
          ],
        },
        {
          entryName: "로",
          typicalExample: "【금지분교로 옮긴 지 아홉달 만에 】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "Directional (으)로: (the general direction) toward(s), to, (heading) for.",
          sentences: [
            {
              Kor: "금지분교로 옮긴 지 아홉달 만에 ",
              Eng: "after only nine months since his transfer to the local school in Kŭmji …",
            },
            {
              Kor: "처연한 걸음으로 집으로 들어왔다.  ",
              Eng: "… entered the house with sorrowful steps.",
            },
            {
              Kor: "남편의 음성이 불안으로 떨렸다.  ",
              Eng: "Her husband's voice trembled with anxiety.",
            },
            {
              Kor: "분명한 울음으로 태어나  ",
              Eng: "… was born with a clear cry, and …",
            },
            {
              Kor: "막무가내로",
              Eng: "in the style of 'can't do anything about it' (Here on a Sino-Korean holophrase: 막무가내 莫無可奈).",
            },
            {
              Kor: "오후 차로 들어가거라 ",
              Eng: "Take the afternoon bus back home.",
            },
            {
              Kor: "구원받은 느낌으로 ",
              Eng: "with the feeling of having been saved...",
            },
            { Kor: "그 즉시로", Eng: "in that very instant" },
            {
              Kor: "어머니의 결혼반지로 ",
              Eng: "it being my mothers wedding ring...",
            },
            {
              Kor: "그의 방 열쇠는 두 개로 ",
              Eng: "there being two sets of keys to the apartment, ...",
            },
            { Kor: "중년 사내로", Eng: "a middle-aged man " },
            {
              Kor: "세 영감 중의 하나로 ",
              Eng: "being/as one of the three old men …",
            },
            { Kor: "교사는 세 명으로 ", Eng: "being three teachers ..." },
            {
              Kor: "5급 공무원으로",
              Eng: "as a fifth-level civil servant ...",
            },
            {
              Kor: "절망의 대상으로 나 ",
              Eng: "I, as the object of despair ...",
            },
            {
              Kor: "언제나 완전무결하신 분으로 ",
              Eng: "being as always the perfectionist that he is ...",
            },
            {
              Kor: "주인들은 나를 옛날의 나로 대해 주었고 ",
              Eng: "the landlord treated me as the old me and ...",
            },
            {
              Kor: "차양모와 운동화 차림으로 ",
              Eng: "dressed in a cap and exercise attire ",
            },
            {
              Kor: "아들로 인정하기를 늘 거부하시죠 ",
              Eng: "He refuses to acknowledge me as his son.",
            },
            {
              Kor: "숲으로 둘러싸인 사이 ",
              Eng: "in the space between the enclosing forest",
            },
            {
              Kor: "화재로 잿더미가 된 것 ",
              Eng: "turned to ashes through conflagration",
            },
            { Kor: "심한 낭패감으로 ", Eng: "due to my deep frustration " },
            {
              Kor: "일 안하고 품삯 많이 받는 인부의 한 사람으로 되었다",
              Eng: "[She] became one of those who got paid a lot without having to work.",
            },
            {
              Kor: "바람은 무수히 작은 입자(粒子)로 되어 있고 ",
              Eng: "The wind was made up of countless tiny particles, and … (lit.: The wind was in a state resulting from being made up of countless tiny particles, and …)",
            },
          ],
        },
        {
          entryName: "로나",
          typicalExample: "【가정으로나 숨어 버리기 전에는 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Directional (으)로 + -(이)나 or the like, like, or something, or so; the likes of, any; at least, anyway, just (used in gently/vaguely urging way), even if it is not of particular interest. ",
          sentences: [
            {
              Kor: "가정으로나 숨어 버리기 전에는 ",
              Eng: "Before you go hide yourself in a family ...",
            },
          ],
        },
        {
          entryName: "로나마 ",
          typicalExample: "【쉽게 행할 수 있는 것으로나마 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "By means of at least..., by means of (a trifling) ... from instrumental (으)로 plus -(이) 나마 although it is (only the likes of) ...; at least, anyway; even.",
          sentences: [
            {
              Kor: "쉽게 행할 수 있는 것으로나마 ",
              Eng: "by means of this easily preformed task ...",
            },
          ],
        },
        {
          entryName: "로는 ",
          typicalExample: "【골짜기에서의 생각으로는 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation: "As for (its being) as/with… on the basis of...",
          sentences: [
            {
              Kor: "골짜기에서의 생각으로는 ",
              Eng: "as for what he had thought back in the valley ...",
            },
          ],
        },
        {
          entryName: "로도 ",
          typicalExample: "【내 빠른 걸음으로도 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation: "As/with… also/even/either",
          sentences: [
            {
              Kor: "내 빠른 걸음으로도 ",
              Eng: "even with my fast walking pace ...",
            },
          ],
        },
        {
          entryName: "로 대신하고",
          typicalExample: "【일자리를 내어주는 것으로 대신하고 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN으로 대신하고: instead of ...; in the place of ...",
          sentences: [
            {
              Kor: "일자리를 내어주는 것으로 대신하고 ",
              Eng: "instead of handing out a job ... ",
            },
          ],
        },
        {
          entryName: "로라도",
          typicalExample: "【지나가는 말로라도  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "(으)로라도 even if it be by way of/by means of—i.e.: a combination of instrumental (으)로 + pseudo-particle -(이)라도 (1) even if (it be) (2) just, (or the) like (= 이나); or even any, some. ",
          sentences: [
            { Kor: "지나가는 말로라도  ", Eng: "even just in passing" },
            {
              Kor: "안되면 힘으로라도 끌어내지 ",
              Eng: "If not, I'll even have to drag him out by force.",
            },
          ],
        },
        {
          entryName: "로만",
          typicalExample:
            "【연애 시절 소주집으로만 끌고 다니는 내게 불만을 말한 적은 없다.  】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "(으)로만 = directional (으)로 + 만: only in the direction of; only to ... ; only of ... ; only from ...; only to ..., by means of only ...",
          sentences: [
            {
              Kor: "연애 시절 소주집으로만 끌고 다니는 내게 불만을 말한 적은 없다.  ",
              Eng: "She had never voiced any discontent to me when we were dating and I had used to drag her around to soju bars.",
            },
            {
              Kor: "강냉이로만 빚은 순곡주 ",
              Eng: "a pure drink brewed only of corn ...",
            },
          ],
        },
        {
          entryName: "로 말하면 ",
          typicalExample: "【일본으로 말하면 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN(으)로 말하면: speaking about ..., speaking for/of ...; as for ...",
          sentences: [
            { Kor: "일본으로 말하면 ", Eng: "speaking of Japan" },
            { Kor: "역둔토로 말하면 ", Eng: "speaking of Yŏktunt'o ..." },
            {
              Kor: "나로 말하면",
              Eng: "as for me ...; or, speaking for myself ...",
            },
          ],
        },
        {
          entryName: "로 미루어",
          typicalExample: "【또 밭의 곡식이 된 품으로 미루어 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN으로 미루어: judging by NOUN",
          sentences: [
            {
              Kor: "또 밭의 곡식이 된 품으로 미루어 ",
              Eng: "judging from the quality of the crops in the fields ...",
            },
          ],
        },
        {
          entryName: "로밖에 ( + negative)",
          typicalExample: "【그런 형태로밖에 자기의 재능을 실현할 길이 없는 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN(으)로밖에: other than as NOUN, except for as NOUN",
          sentences: [
            {
              Kor: "그런 형태로밖에 자기의 재능을 실현할 길이 없는 ",
              Eng: "… who had no other way to realize his abilities other than in this form ",
            },
          ],
        },
        {
          entryName: "로 보인다",
          typicalExample: "【절대자로 보였고 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN으로 보인다: it seems to be; it appears to be looks like",
          sentences: [
            {
              Kor: "절대자로 보였고 ",
              Eng: "He appeared to be a perfectionist, and ...",
            },
            {
              Kor: "정물로 보인다",
              Eng: "[He] looks like an inanimate object.",
            },
          ],
        },
        {
          entryName: "로 봐(서)",
          typicalExample: "【뭇 사정으로 보아 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN(으)로 봐(서): judging by NOUN on the basis of NOUN, from what can see in the way of NOUN",
          sentences: [
            {
              Kor: "뭇 사정으로 보아 ",
              Eng: "Looking at it from many situations ...",
            },
          ],
        },
        {
          entryName: "로부터1",
          typicalExample: "【지금으로부터 9년 전 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation: "From; on the part of",
          sentences: [
            {
              Kor: "지금으로부터 9년 전 ",
              Eng: "nine years ago [lit.: nine years back from now]",
            },
            {
              Kor: "먼 곳에 있는 것으로부터 ",
              Eng: "from things in a distant place",
            },
            {
              Kor: "서울에서의 실패로부터 ",
              Eng: "from some failure in Seoul ",
            },
            {
              Kor: "깊은 밤에 악몽으로부터 깨어나서 ",
              Eng: "waking up in the middle of the night from a nightmare ...",
            },
            { Kor: "당국자로부터", Eng: "from the authorities" },
            { Kor: "변공으로부터", Eng: "from his highness " },
            {
              Kor: "먼 촌으로부터 등교하기 위하여 ",
              Eng: "from the far village, [students] who were coming to attend school ...",
            },
            {
              Kor: "사람으로부터 칼을 빼앗듯이 ",
              Eng: "as if taking a knife from a person ",
            },
          ],
        },
        {
          entryName: "로부터2",
          typicalExample: "【그릇된 사회구조로부터 박해받는 사람 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "By/on the part of (to mark the by-phrase in a passive or passive-like structure). ",
          sentences: [
            {
              Kor: "그릇된 사회구조로부터 박해받는 사람 ",
              Eng: "people suffering oppression from a flawed social structure",
            },
            {
              Kor: "온 마을 사람들로부터 주정뱅이라고 손가락질 받고 ",
              Eng: "He was pointed at and called a drunk by the entire village, and ...",
            },
          ],
        },
        {
          entryName: "로 삼는다",
          typicalExample: "【육체의 아름다움을 주무기로 삼고 있는 듯 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN1을 NOUN2(으)로 삼-: take/consider NOUN1 as NOUN2.",
          sentences: [
            {
              Kor: "육체의 아름다움을 주무기로 삼고 있는 듯 ",
              Eng: "as if making/considering their physical beauty their main weapon",
            },
            { Kor: "아내로 삼겠다고 ", Eng: "take her as my wife ..." },
          ],
        },
        {
          entryName: "로서(는)",
          typicalExample: "【그보다 이십년이나 위로서 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "NOUN(으)로서: as; for; in the capacity of; being ... An emphatic synonym of (으)로 in certain of its meanings: (means) with, by, by means of, through, using; (constituency, content) made of, formed from, full of/with (made) out of; (cause) for, as, with, from, because of, due to; (result) as a consequence of, with, in accordance with; (state, status) as, being; (manner) as, in, with.",
          sentences: [
            {
              Kor: "그보다 이십년이나 위로서 ",
              Eng: "being some twenty older than she",
            },
            {
              Kor: "부녀 사이의 그것으로서 이해할 수 있었으나 ",
              Eng: "A (soft, warm and satisfying) rapport between a loving father and daughter—that I could understand, but ...",
            },
            { Kor: "주인으로서는", Eng: "being the head of the household ..." },
            {
              Kor: "이제 내 생활의 일부로서 ",
              Eng: "being that [the newspaper] had become an integral part of my life ...",
            },
            { Kor: "세 사람은 남자로서 ", Eng: "the three men ..." },
            {
              Kor: "한 사람은 여자로서 ",
              Eng: "one of them being a woman ...",
            },
            { Kor: "교육가로서의 사명 ", Eng: "his mission as an educator " },
            {
              Kor: "그의 초연한 교육자로서의 품위 ",
              Eng: "his dignity as an excellent educator",
            },
            {
              Kor: "그로서는 참기 어려운 일 ",
              Eng: "for him (being as he was), enduring was a difficult thing...",
            },
            { Kor: "사랑하는 여자로서 ", Eng: "as a loving woman …" },
            {
              Kor: "총중의 한 포기로서 넉넉히 짐작할 수 있다 ",
              Eng: "As one of the bunch, I can pretty much guess [what the trees are saying, thinking].",
            },
            { Kor: "이 꼴로서야 ", Eng: "in this pitiful state" },
            {
              Kor: "아무 지식이 없는 나로서는 ",
              Eng: "as for me, who has no knowledge [about jobs]",
            },
            {
              Kor: "지금 길수로서는 ",
              Eng: "as far as Kilsu was concerned ...",
            },
            { Kor: "지금으로서는", Eng: "as for now..." },
            {
              Kor: "아버지로서는 지수의 방 ",
              Eng: "For her father/as far as her father was concerned, Chisu's room …",
            },
            {
              Kor: "자신을 학대했던 그녀로서는  ",
              Eng: "For her, who had tormented herself …",
            },
            {
              Kor: "얘기를 나눌 시간도 별로 없는 나로서는  ",
              Eng: "For me, who doesn't have all that much time to chit-chat with her …",
            },
            {
              Kor: "침으로서 방울을 만들어서 ",
              Eng: "gathering gobs of spit ...",
            },
            {
              Kor: "이것으로서 족하리라고 생각되었다 ",
              Eng: "With this, he thought it would be sufficient.",
            },
          ],
        },
        {
          entryName: "로서도 ",
          typicalExample: "【필자로서도 물론 무어라 말하기 어렵다】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "Even ... as; even for; even in the capacity of; even being...",
          sentences: [
            {
              Kor: "필자로서도 물론 무어라 말하기 어렵다",
              Eng: "Of course, even for me, the author, it is hard to know what to write.",
            },
          ],
        },
        {
          entryName: "로서야",
          typicalExample:
            "【이 꼴로서야 문화주택이 암만 서기로 내게 무슨 상관이며 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "NOUN(으)로서야: as; for; in the capacity of; being ... An emphatic synonym of (으)로 in certain of its meanings: (means) with, by, by means of, through, using; (constituency, content) made of, formed from, full of/with (made) out of; (cause) for, as, with, from, because of, due to; (result) as a consequence of, with, in accordance with; (state, status) as, being; (manner) as, in, with. Reinforced even further with particle (이)야 only if it be (marking a reinforced contingency or prerequisite for a main clause, one of the other of which is unlikely or unexpected, or anticipated to be difficult or unpleasant).",
          sentences: [
            {
              Kor: "이 꼴로서야 문화주택이 암만 서기로 내게 무슨 상관이며 ",
              Eng: "In this pitiful state, regardless how many housings are constructed, they won't matter anything to me …",
            },
          ],
        },
        {
          entryName: "로써 ",
          typicalExample: "【뇌물 받는 것으로써 】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "An emphatic synonym of (으)로 in certain of its meanings: (means) with, by, by means of, through, using; (constituency, content) made of, formed from, full of/ with, (made) out of; (cause) for, as, with, from, because of, due to; (result) as a consequence of, with, in accord(ance) with; (state, status) as, being; (manner) as, in, with.",
          sentences: [
            {
              Kor: "뇌물 받는 것으로써 ",
              Eng: "because of accepting bribes ...",
            },
            {
              Kor: "사람들의 힘으로써는 ",
              Eng: "through/by the efforts of people …",
            },
            {
              Kor: "나는 거짓말로써 그를 위로했다 ",
              Eng: "lit.: I consoled him with a lie.",
            },
            {
              Kor: "과학책에서 배운 바로써가 아니라",
              Eng: "it is not something that was learned from a science textbook, but ...",
            },
            { Kor: "이렇게 글로써 ", Eng: "in this way through writing" },
            {
              Kor: "의지로써 고통을 억압하고 있는 것일까 ",
              Eng: "Is it through will power that he is repressing the pain?",
            },
          ],
        },
        {
          entryName: "로 안다 (아-ㄹ-) ",
          typicalExample: "【죽어지는지도 모를 일로 알았다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN1(을) NOUN2(으)로안다: deems it, considers it as, reckons it to be, thinks of it as",
          sentences: [
            {
              Kor: "죽어지는지도 모를 일로 알았다 ",
              Eng: "… thought of it as something which [if she did it] she might die.",
            },
          ],
        },
        {
          entryName: "로 인하여(서)",
          typicalExample: "【이 계집의 허영으로 인하여 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN(으)로 인하여(서): on account of",
          sentences: [
            {
              Kor: "이 계집의 허영으로 인하여 ",
              Eng: "on account of this woman's vanity...",
            },
            {
              Kor: "까마귀를 먹이게 되었다는 고사로 인하여",
              Eng: "on account of the sacrifice to feed him crow as a meal …",
            },
          ],
        },
        {
          entryName: "로 인해(서)",
          typicalExample: "【숲과 안개로 인해 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN(으)로 인해(서): on account of",
          sentences: [
            {
              Kor: "숲과 안개로 인해 ",
              Eng: "on account of the forest and fog ...",
            },
          ],
        },
        {
          entryName: "로 친다",
          typicalExample: "【그 집을 흉가로 치는 모양이었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN1를 NOUN2(으)로 친다: counts NOUN1 as NOUN2, considers NOUN1 as NOUN2, takes NOUN1 for NOUN2",
          sentences: [
            {
              Kor: "그 집을 흉가로 치는 모양이었다 ",
              Eng: "It seems that people considered the house to be haunted.",
            },
          ],
        },
        {
          entryName: "로 하여(서)",
          typicalExample: "【학으로 하여 즐거운 이야기는 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN(으)로 하여(서): due/owing to, on account of",
          sentences: [
            {
              Kor: "학으로 하여 즐거운 이야기는 ",
              Eng: "The joyous story on account of the cranes ...",
            },
          ],
        },
        {
          entryName: "로 하여금 ",
          typicalExample:
            "【사람들로 하여금 해를 바람을 간절히 부르게 하는 무진의 안개 】",
          categoryKorean: "Causative",
          categoryEnglish: "(Causative)",
          translation:
            "Letting, making, forcing (marks the causee in causative constructions)",
          sentences: [
            {
              Kor: "사람들로 하여금 해를 바람을 간절히 부르게 하는 무진의 안개 ",
              Eng: "the fog of Mujin which made people pray earnestly for the sun and wind ",
            },
            {
              Kor: "직접 나로 하여금 느끼게 하는 것 ",
              Eng: "the fact that he forced me to feel it directly ",
            },
            {
              Kor: "나로 하여금 그토록 기꺼이 그 사내를 맞아들이게 한 것이나 아니었을까 ",
              Eng: "I wondered if perhaps it hadn't been that it forced me to happily usher in the guy.",
            },
          ],
        },
        {
          entryName: "로 해서",
          typicalExample: "【또 그로 해서 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN(으)로 해서: for NOUN's part; as far as NOUN is/was concerned",
          sentences: [
            {
              Kor: "또 그로 해서 ",
              Eng: "and as far as that was concerned ...; or, for that reason ...",
            },
          ],
        },
        {
          entryName: "로 향한다",
          typicalExample: "【나의 청각이 문득 외부로 향하면 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Heads/turns in the direction of, toward",
          sentences: [
            {
              Kor: "나의 청각이 문득 외부로 향하면 ",
              Eng: "if I suddenly turn my attention (lit.: my ears) to the outside ...",
            },
            { Kor: "그의 집으로 향하여 ", Eng: "headed towards his house ..." },
            { Kor: "발코니로 향한 문 ", Eng: "the door facing the balcony" },
          ],
        },
        {
          entryName: "론 ← 로는",
          typicalExample: "【정말 앞으론 가지 않을 작정이예요 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Particle (으)로 plus particle 는, with the latter abbreviated to just ㄴ.",
          sentences: [
            {
              Kor: "정말 앞으론 가지 않을 작정이예요 ",
              Eng: "I really don't intend to go there ever again.",
            },
          ],
        },
        {
          entryName: "-롭다",
          typicalExample: "【(완전히) 자유로울 수는 없습니다 】",
          categoryKorean: "Word-formation element",
          categoryEnglish: "(Word-formation element)",
          translation:
            "Post-nominal adjective in NOUN-로w- (-롭다, -로워요) is (characterized) by. Cf. 해롭다 harmful, 이롭다 profitable, 외롭다 lonely, 괴롭다 distressing, 새롭다 new, etc. ",
          sentences: [
            {
              Kor: "(완전히) 자유로울 수는 없습니다 ",
              Eng: "One cannot be completely free.",
            },
            {
              Kor: "여유로운 양씨의 말이었다 ",
              Eng: "Those were Mr. Yang's relaxed words.",
            },
          ],
        },
        {
          entryName: "루 ",
          typicalExample: "【어디루 갈려오? 】",
          categoryKorean: "Particle/Dialect morphology ",
          categoryEnglish: "(Particle/Dialect morphology )",
          translation: "Dialect/substandard (으)루 for standard (으)로.",
          sentences: [
            { Kor: "어디루 갈려오? ", Eng: "Where ya headed?" },
            {
              Kor: "옷이 옥상옷만 도랑꾸루 열다섯 도랑꾸드래요.",
              Eng: "Counting the 옥상옷s in trunks alone—there are fifteen trunks of those.",
            },
            {
              Kor: "불한당질루 장만한 거 아냐? ",
              Eng: "Are you sure you didn't get all this by thievery?  ",
            },
          ],
        },
        {
          entryName: "-루다 ",
          typicalExample: "【인장표루다】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation:
            "Colloquial/substandard for -(이)로다, a poetic/exclamatory form of the copula. Other forms of the copula with -로- are: suspective -이로지, familiar -이로세, sequential -이로니, adversative -이로나, concessive -이로되, literary or quoted -이로라, apperceptive -이로군.",
          sentences: [{ Kor: "인장표루다", Eng: "It's an Injang brand! " }],
        },
        {
          entryName: "를",
          typicalExample: "【뒷덜미를 틀어잡혀 】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "Adversity passive with subject marker. In the examples, the object is typically an inalienable possession or something otherwise closely associated with the affected person (e.g., his hard-earned money). ",
          sentences: [
            {
              Kor: "뒷덜미를 틀어잡혀 ",
              Eng: "he was caught in the act (lit: he was nabbed/grabbed by the back of the neck)",
            },
            {
              Kor: "목덜미를 잡혀 ",
              Eng: "he was grabbed by the back of the neck",
            },
            {
              Kor: "10원을 떼일 수는 없는 일이다 ",
              Eng: "He cannot/shouldn't be robbed of those ten wŏn.",
            },
            {
              Kor: "10원을 떼이는 것이다 ",
              Eng: "He is being robbed of ten wŏn.",
            },
            {
              Kor: "현장에서 덜미를 잡혔던 것이었다 ",
              Eng: "lit.: [He] was grabbed, by the nape of the neck, in the act.",
            },
            {
              Kor: "맨주먹으로 살던 집을 쫓겨났다 ",
              Eng: "… suffered the indignity of being driven away empty-handed out of the house he was living in.",
            },
            {
              Kor: "살을 베이면 ",
              Eng: "if one suffers getting their flesh cut  ...",
            },
            {
              Kor: "딸 하나 있는 것을 데릴사위를 들였더니",
              Eng: "took in an indentured husband for his only daughter...",
            },
            { Kor: "이십 오 년 동안을 ", Eng: "for 25 years ..." },
            {
              Kor: "이태 동안을 사는 것이 아니라",
              Eng: "They weren't living for two years, [rather, they were just surviving] (NB: One could also have said 이태를 사는 것이.)",
            },
          ],
        },
        {
          entryName: "르 ",
          typicalExample: "【그거 나르 주오 】",
          categoryKorean: "Particle/Dialect morphology ",
          categoryEnglish: "(Particle/Dialect morphology )",
          translation: "Northeastern (Hamkyeng) for particle 를.",
          sentences: [
            { Kor: "그거 나르 주오 ", Eng: "Give that to me." },
            {
              Kor: "술장사르 한다는 년이",
              Eng: "a girl who's supposed to be selling booze",
            },
            {
              Kor: "고기가 너르 잡겠다 ",
              Eng: "The fish are going to catch you.",
            },
          ],
        },
        {
          entryName: "를 ~ 을",
          typicalExample: "【그러기를 몇 해를 지나 】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "Accusative of duration, marking the duration of a verb. ",
          sentences: [
            {
              Kor: "그러기를 몇 해를 지나 ",
              Eng: "after passing a few years doing it like this",
            },
            {
              Kor: "이십 오 년 동안을 자는 시간만 빼고는",
              Eng: "for 25 years, except for the time I spent sleeping ...",
            },
            {
              Kor: "이태 동안을 사는 것이 아니라",
              Eng: "They weren't living for two years, [rather, they were just surviving] (NB: One could also have said 이태를 사는 것이.)",
            },
            {
              Kor: "그녀는 한참을 그냥 그대로 서 있다.  ",
              Eng: "She just stands there for a long time.",
            },
            {
              Kor: "남편과 지수는 한참을 마주보고 서 있었다. ",
              Eng: "Chisu and her husband stood looking at each other for some time.",
            },
          ],
        },
        {
          entryName: "를 걷는다",
          typicalExample: "【아침 햇살 속을 걸어서 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN을 걷는다: walks inside ... ",
          sentences: [
            {
              Kor: "아침 햇살 속을 걸어서 ",
              Eng: "I walked inside the morning sun rays ... ",
            },
          ],
        },
        {
          entryName: "를 넘어",
          typicalExample: "【열 번을 넘어 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Numeral expression + 을~를 넘어 more than, in excess of.",
          sentences: [
            {
              Kor: "열 번을 넘어 ",
              Eng: "[even if one went around it] more than ten times ...",
            },
          ],
        },
        {
          entryName: "를 다닌다 ",
          typicalExample: "【다른데로 자금 운동을 다니나 보다 하였다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Same as NOUN을 하러 다닌다 goes (around) ...-ing. More examples: 구경을 다녀요 goes sightseeing, goes around sightseeing; 출장을 다녀요 goes on a business trip.",
          sentences: [
            {
              Kor: "다른데로 자금 운동을 다니나 보다 하였다 ",
              Eng: '… thought to himself "he must be/seems to be running around someplace else scaring up funds.',
            },
          ],
        },
        {
          entryName: "-리 ",
          typicalExample: "【낸들 그대를 어찌 하리】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "A literary, somewhat archaic or poetic future form (as if shortened from -(으)리다) also used in rhetorical questions.",
          sentences: [
            {
              Kor: "낸들 그대를 어찌 하리",
              Eng: "What am I to do [about you]?",
            },
            {
              Kor: "설마 어떠리 ",
              Eng: "How bad could [returning to work] be?",
            },
          ],
        },
        {
          entryName: "-리다 ",
          typicalExample: "【만 원 내리다 】",
          categoryKorean:
            "하오체의 종결 어미 Final ending in Semiformal Style  ",
          categoryEnglish:
            "(하오체의 종결 어미 Final ending in Semiformal Style  )",
          translation:
            "Prospective assertive I (or we) will gladly do. When quoted, this converts to -리라 (고).",
          sentences: [
            { Kor: "만 원 내리다 ", Eng: "I'll gladly give you 10,000 wŏn. " },
          ],
        },
        {
          entryName: "-리라",
          typicalExample: "【이제 곧 남편 일행을 태운 배가 돌아오리라.  】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "Sentence-final prospective assertive -(으)리라 will probably be/do I bet/I reckon that ... is likely the case. Often used to imply internal thoughts, or to 'get inside a protagonist's head'. A fancy equivalent to -(으)ㄹ 것이다, and in origin the quoted version of -(으)리다.",
          sentences: [
            {
              Kor: "이제 곧 남편 일행을 태운 배가 돌아오리라.  ",
              Eng: "The boat carrying her husband and his pals will come back any time now.",
            },
            {
              Kor: "서울에 내리면 아침이 되리라 했다.  ",
              Eng: "When I disembark in Seoul, it will be morning, she thought to herself.",
            },
            {
              Kor: "아마 그건 사실이리라 ",
              Eng: "That just might be the case.",
            },
            {
              Kor: "부부가 아니라 창부와 그 여자의 손님이리라 ",
              Eng: "Not husband and wife, but probably a prostitute and her john.",
            },
            {
              Kor: "넘치는 젊음의 활력 탓이리라 ",
              Eng: "It's probably due to the overflowing vitality of youth.",
            },
            {
              Kor: "얼굴이라도 비추어 보는 것이리라 ",
              Eng: "She must ['probably'] be looking at her reflection ['at the reflection of her face'] or something.",
            },
            {
              Kor: "신작로 길가에서 파온 것이리라 ",
              Eng: "It's probably [soil] dug up from the road construction site [I reckon].",
            },
            {
              Kor: "저쯤 갈밭 머리로 소녀가 나타나리라 ",
              Eng: "Thought to himself, She'll probably appear just over there at the top/crest of the field of rushes. ",
            },
            {
              Kor: "그러나 내리지 않으리라 ",
              Eng: "But I'm not going to fall off [he thought to himself].",
            },
            {
              Kor: "내 심장 위에서 지워져 버리리라 ",
              Eng: "… will probably be erased from my heart.",
            },
            {
              Kor: "아름다운 여자들이 그의 아파트에 드나들리라 ",
              Eng: "Beautiful women likely (probably) frequented his apartment.",
            },
            {
              Kor: "내 혼자의 환몽일 수도 있으리라 ",
              Eng: "It might be that I am the only one with this empty dream, I thought to myself.",
            },
            {
              Kor: "끌려 들어가고 있으리라 ",
              Eng: "[Their whispers] will probably get sucked into the whirlpool.",
            },
            {
              Kor: "또 수근거리고 있으리라 ",
              Eng: "Their [whispering] will probably go on and on.",
            },
            {
              Kor: "어디선가 부부들은 교합 (交合)하리라 ",
              Eng: "Somewhere, a husband and his wife must be having sex.",
            },
            {
              Kor: "달려온 그의 누이였으리라 ",
              Eng: "It must have been his younger sister [I reckoned].",
            },
            {
              Kor: "이를테면 그들의 삼층장이었으리라 ",
              Eng: 'Maybe this was one of those so-called "three-level wardrobes".',
            },
            {
              Kor: "실의(失意)의 무진행은 없었으리라 ",
              Eng: "I would not have gone the disappointing road towards Mujin.",
            },
            {
              Kor: "눈물이 쏟아졌음이리라 ",
              Eng: "It must have been the tears flowing.",
            },
            {
              Kor: "나이가 시킴이리라 ",
              Eng: "It's probably just his age (lit.: it is probably a case of his age making him do it).",
            },
          ],
        },
        {
          entryName: "-리라(고)",
          typicalExample: "【그는 하나하나 보리라고 다짐한다 】",
          categoryKorean: "Quotative",
          categoryEnglish: "(Quotative)",
          translation:
            "Non-final, quoted prospective assertive -(으)리라 will probably be/do I bet/I reckon that ... is likely the case. Often used to quote internal thoughts. A fancy equivalent to -(으)ㄹ 것이라(고), and in origin the quoted version of -(으)리다.",
          sentences: [
            {
              Kor: "그는 하나하나 보리라고 다짐한다 ",
              Eng: "He pledges to look at them one by one.",
            },
            {
              Kor: "도망가리라고 생각했었기 때문에 ",
              Eng: "Because he had thought [at first] that he would run away [from the room] ...",
            },
            {
              Kor: "살금살금 움직이리라고 마음먹고 ",
              Eng: "[He] made up his mind to move quietly, and ...",
            },
            {
              Kor: "스위치 있는 쪽으로 가리라고 결심했다 ",
              Eng: "He decided/resolved to go toward the switch.",
            },
            {
              Kor: "온 방안의 물건을 자세히 보리라고 다짐하고는 ",
              Eng: "He vowed to look carefully at all the objects in the room, and then ...",
            },
            {
              Kor: "이것으로서 족하리라고 생각되었다 ",
              Eng: "With this, he thought it would be sufficient.",
            },
            {
              Kor: "하루종일 무릎을 끓고 있으리라고는 믿어지질 않았다.",
              Eng: "She [couldn't believe that] prisoners were down on their knees all day long.",
            },
            {
              Kor: "애매한 머슴을 내쫓았음을 뉘우치리라고도 생각되었다 ",
              Eng: "It occurred to him that [the old man] might regret having driven him off his obscure farm-hand existence.",
            },
            {
              Kor: "학원 안으로 들어오리라고는 생각지 못하고 있던 참이었기 때문이었다. ",
              Eng: "It was because it had never occurred to me just then that he might walk into the cram school.",
            },
            {
              Kor: "떠오르리라고 믿기 때문이다 ",
              Eng: "because he knew it would probably come to his mind ...",
            },
            {
              Kor: "당장 떠나리라 마음 먹었다 ",
              Eng: "I made up my mind to take off immediately.",
            },
            {
              Kor: "기쁘게 해드리리라 마음먹었다 ",
              Eng: "I decided I would make her happy.",
            },
            {
              Kor: "고향으로 출발하리라 작정하죠 ",
              Eng: "I make up my mind to leave for my hometown. ",
            },
            {
              Kor: "집을 다시 지으리라 생각했다 ",
              Eng: "[Togi] thought that he might rebuild his home.",
            },
            {
              Kor: "죽었으리라 생각했던 손자가 ",
              Eng: "[Their] grandsons whom they had presumed dead ...",
            },
            {
              Kor: "먹으리라 하고 ",
              Eng: "lit.: thought to himself that he would like to consume … ",
            },
            {
              Kor: "훌륭한 과학자가 되리라 했다 ",
              Eng: "[He] thought that he would likely become a great scientist.",
            },
            {
              Kor: "호랑이는 우리들 곁으로 다가오리라 믿었다 ",
              Eng: "I believed that the tiger would come over to us.",
            },
          ],
        },
        {
          entryName: "-리라는",
          typicalExample: "【오래 머물 수 없으리라는 것 】",
          categoryKorean: "Modifie/준꼴 Contraction",
          categoryEnglish: "(Modifie/준꼴 Contraction)",
          translation:
            "From -(으)리라[고 하]는—i.e., the pattern in -(으)리라 quoted and adnominalized to 것, etc. (-(으)리라[고 생각하]는 NOUN, etc.).",
          sentences: [
            {
              Kor: "오래 머물 수 없으리라는 것 ",
              Eng: "the fact he (probably) wouldn't be able to stay for long",
            },
            {
              Kor: "끝나지는 않으리라는 것 ",
              Eng: "the fact that it was probably not the end ",
            },
            {
              Kor: "이 학마을에서는 살 수 없으리라는 말이 ",
              Eng: "lit.: words to the effect that it was probably not possible to live in this village ",
            },
            {
              Kor: "암행어사가 오리라는 소문 ",
              Eng: "a rumor that a secret policeman was probably coming",
            },
            {
              Kor: "거기가서는 조그마한 가겟방을 보게 되리라는 것이었다 ",
              Eng: "lit.: It was a matter of them [the adults] reckoning that they [the girl's family] would end up running a little shop there, i.e.: They reckoned the girl's family would probably open a little store there.",
            },
            {
              Kor: "일만 구천 원이 되리라는 속셈 = 되리라고 하는 속셈 ",
              Eng: "mental arithmetic to the effect that it would come out as 19,000 wŏn",
            },
            {
              Kor: "공표가 있으리라는 것이다 = 있으리라고 하는 것이다 ",
              Eng: "lit.: It is a matter of thinking, There is [probably] going to be a public announcement.",
            },
            {
              Kor: "나도 오늘 안으로 서울로 가리라는 결정을 ",
              Eng: "[I decided in an instant that] I would also return to Seoul today.",
            },
          ],
        },
        {
          entryName: "-ㅁ1",
          typicalExample: "【그의 말에서 느낀 어떤 만만찮음  】",
          categoryKorean: "Noun-formation Element",
          categoryEnglish: "(Noun-formation Element)",
          translation: "Substantive -(으)ㅁ the act (or fact) of having/being",
          sentences: [
            {
              Kor: "그의 말에서 느낀 어떤 만만찮음  ",
              Eng: "the stubbornness that was felt in his speech ",
            },
            { Kor: "어쩔수없는 약함  ", Eng: "inevitable weakness" },
            {
              Kor: "지극히 비현실적인 우아함 이기도 했다 ",
              Eng: "It was, indeed, an extremely unreal elegance.",
            },
            {
              Kor: "그의 눈이 번들번들함 은 ",
              Eng: "The glistening in his eyes [must have been tears].",
            },
            { Kor: "주변 없음 ", Eng: "lack of resourcefulness " },
            { Kor: "대갚음  ", Eng: "repayment" },
            { Kor: "겪음   ", Eng: "fine times / good times " },
            {
              Kor: "우편배달부를 기다리던 초초함 ",
              Eng: "the fretfulness of waiting for the postman ",
            },
            {
              Kor: "그런 속삭임  속에서 ",
              Eng: "in the midst of such whispering ...",
            },
            { Kor: "수근거림 의 소용돌이 ", Eng: "the whirlpool of whispers " },
            {
              Kor: "목소리의 갈라짐 이 없었고 ",
              Eng: "There was no cracking in her voice ...",
            },
            { Kor: "청승맞음 이 없었다 ", Eng: "There were no omens." },
            {
              Kor: "귀를 기울이고 있을 때의 안타까움  ",
              Eng: "the distress felt when turning your ear towards ...",
            },
            { Kor: "시간의 지루함  ", Eng: "the tediousness of time " },
            {
              Kor: "이마를 손바닥으로 닦으며 느끼는 허전함  ",
              Eng: "the emptiness felt when wiping your brow with your palm ...",
            },
            { Kor: "그 국어의 어색함  ", Eng: "the clumsiness of the word " },
            {
              Kor: "서울에서와 같은 조급함 , 답답함  ",
              Eng: "the kind of anxiety and frustration that one would have also felt in Seoul ",
            },
            { Kor: "더할수 없는 수줍음  ", Eng: "a shyness beyond measure " },
            {
              Kor: "무엇으로도 채울 수없는 굶주림 ",
              Eng: "an insatiable hunger (← 굶주리- starve)",
            },
            {
              Kor: "막연한 기대와 설레임  ",
              Eng: "the intangible hope and uneasiness",
            },
            {
              Kor: "가을날의 나비같은 스산함 ",
              Eng: "the bleakness of a butterfly in the fall ",
            },
            { Kor: "막막함 ", Eng: "the dreariness/bleakness" },
            { Kor: "취기가 주는 나른함  ", Eng: "the drunken languidness" },
            { Kor: "길을 택함   ", Eng: "choosing the way " },
            {
              Kor: "정렬도 한갓 뜬구름 부질없는 사로잡힘 이오. ",
              Eng: "Passion is [also] a mere meaningless capture.",
            },
            {
              Kor: "그의 다스림이 포악무도했는지 여부도 딱히 밝힐 만한 아무 근거도 없다.",
              Eng: "There is no proof whatsoever that his rule was truly ruthless and brutal. ",
            },
            {
              Kor: "변공을 두둔하려 함 이 아님을 말할 것도 없거니와 ",
              Eng: "Not intending to support his highness should already be a given. ",
            },
            {
              Kor: "죄를 인정함은 모순이다. ",
              Eng: "Acknowledging [one’s] sins would be an inconsistency. ",
            },
            { Kor: "이 모든 술렁임이 ", Eng: "all of this commotion ..." },
            {
              Kor: "작부들이 부르는 그것에서 들을 수 있는 것과 같은 꺾임 이 없었고",
              Eng: "There was no curve in the tone of the waitress’ voice when she sang the song, and ...",
            },
            {
              Kor: "아빠와 함께하는 즐거움 을 알게 해주고 싶어서 ",
              Eng: "Because I want to let them know the joy of being together with their dad …",
            },
            {
              Kor: "애인이니 조금의 쓸쓸함 만을 남길 따름이다. ",
              Eng: "Since he's my boyfriend, it only leaves a bit of sadness.",
            },
            {
              Kor: "이기고 싶은 안타까움에서  ",
              Eng: "stemming from a frustrated will to win",
            },
            {
              Kor: "이상한 설레임을  느끼면서 읽어내려갔다. ",
              Eng: "I began to read the letter, feeling an odd fluttering (in my heart). ",
            },
            {
              Kor: "일개 필부에 지나지 않음을  일러 주었음에도 ",
              Eng: "Though I've already reminded him that he does not rise above the status of a commoner ...",
            },
            {
              Kor: "관장의 지엄함을  보여야 할 것이라고 그는 생각하였다.",
              Eng: "He thought that he should demonstrate the strictness of the magistrate. ",
            },
            {
              Kor: "최대한의 공정함을  베푼 다음에 ",
              Eng: "After he had shown the utmost fairness …",
            },
            {
              Kor: "누가 그 그릇됨을  고쳐줄 것 같지도 않고 ",
              Eng: "It also seems that nobody is going to correct that misconception for him, and ...",
            },
            {
              Kor: "부자유스러움을  생각하고 ",
              Eng: "I thought of the awkwardness, and ...",
            },
          ],
        },
        {
          entryName: "-ㅁ2",
          typicalExample: "【27일회의참석필요, 급상경바람 영 】",
          categoryKorean: "Noun-formation Element",
          categoryEnglish: "(Noun-formation Element)",
          translation:
            "Substantive -(으)ㅁ the act (or fact) of doing/being function in what Martin calls the Documentary Style.  ",
          sentences: [
            {
              Kor: "27일회의참석필요, 급상경바람 영 ",
              Eng: "Meeting on the 27th requires your attendance. Please hurry back to Seoul. (Here the verb base is 바라- wish; desire.)",
            },
          ],
        },
        {
          entryName: "-ㅁ에는 ",
          typicalExample: "【나를 찾아왔음에는  】",
          categoryKorean: "Noun-formation Element",
          categoryEnglish: "(Noun-formation Element)",
          translation: "In or with regard to ...ing.",
          sentences: [
            {
              Kor: "나를 찾아왔음에는  ",
              Eng: "[With regard to] the fact that it had come looking for me ...",
            },
          ],
        },
        {
          entryName: "-ㅁ에도 (불구하고)",
          typicalExample: "【그것이 자기 머리 속에서 지어낸 공상임에도  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "-(으)ㅁ에도 (불구하고) means in spite of the fact . . . ",
          sentences: [
            {
              Kor: "그것이 자기 머리 속에서 지어낸 공상임에도  ",
              Eng: "In spite of the fact that it was a fantasy which she had concocted inside her own head …",
            },
            {
              Kor: "학원 쪽으로 오고 있는 것 보았음에도  나는 놀랐다.  ",
              Eng: "I was startled, even though I had seen him coming toward the cram school.",
            },
          ],
        },
        {
          entryName: "-ㅁ에 따라(서)",
          typicalExample: "【양씨의 탈진함에 따라  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "In accordance with ...-ing, in conformity to (with) ...-ing. Usually the preceding substantivized from in -(으)ㅁ is marked with particle 에, but we also find examples with particle 을 ~를. ",
          sentences: [
            {
              Kor: "양씨의 탈진함에 따라  ",
              Eng: "in accordance with Mr. Yang's exhaustion ...",
            },
          ],
        },
        {
          entryName: "-ㅁ에 틀림없다",
          typicalExample: "【일종의 과객임에 틀림없었습니다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "There is no doubting that ...; there is no question but that ...",
          sentences: [
            {
              Kor: "일종의 과객임에 틀림없었습니다  ",
              Eng: "There is no question that they are a type of passerby.",
            },
            {
              Kor: "보내주는 것임에 틀림없다  ",
              Eng: "There is no doubt that [money] is the thing that is being sent to you.",
            },
            {
              Kor: "아내의 모습을 되살렸음에 틀림없다  ",
              Eng: "[My husband] must have been reminded of his first wife.",
            },
          ],
        },
        {
          entryName: "-ㅁ으로써",
          typicalExample:
            "【이윽고 긴 한숨을 내쉼으로써  호흡은 조절했지만  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㅁ으로써, i.e., particle 으로(써) on a substantive in -(으)ㅁ, here meaning through or on account of/by ...-ing. By itself, (으)로써 is an emphatic synonym of -(으)로 in certain of its meanings:  (means) with, by, by means of, through, using etc., and is not to be confused with -(으)므로(서), the substantive in -(으)ㅁ + instrumental (으)로(서), meaning with (as, etc.) the doing or being; since (because of) the doing or being. ",
          sentences: [
            {
              Kor: "이윽고 긴 한숨을 내쉼으로써  호흡은 조절했지만  ",
              Eng: "After a little while I was able to control my breathing by letting out a big sigh, but …",
            },
          ],
        },
        {
          entryName: "-ㅁ을 ...-ㄴ다  ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The cognate object pattern; cf. 잠(을)잔다 sleeps, 춤(을) 춘다 dances, 꿈(을) 꾼다 dreams, etc.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-ㅁ을 강조한다",
          typicalExample: "【농담이 아님을  재차 강조했다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Emphasizes that ...",
          sentences: [
            {
              Kor: "농담이 아님을  재차 강조했다  ",
              Eng: "… emphasized again that it was no joke.",
            },
          ],
        },
        {
          entryName: "-ㅁ을 걱정(을) 한다",
          typicalExample: "【돈 없음을 걱정하며  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Worries about ...ing; worries about the fact that ...",
          sentences: [
            {
              Kor: "돈 없음을 걱정하며  ",
              Eng: "Worrying about the fact that I had no money ...",
            },
          ],
        },
        {
          entryName: "-ㅁ을 깨닫는다",
          typicalExample: "【익숙하지 않은 동작임을  스스로도 깨달으며 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㅁ을 깨닫는다, 깨달아: realizes that ... ; comes to a realization that ... (based on the substantive in -(으)ㅁ the act (or fact) of doing/being).",
          sentences: [
            {
              Kor: "익숙하지 않은 동작임을  스스로도 깨달으며 ",
              Eng: "Even I realize that it is an unaccustomed gesture …",
            },
            {
              Kor: "참으로 고마운 동물임을  비로서 깨달았다  ",
              Eng: "I realized for the first time that the animal was a very precious thing.",
            },
            {
              Kor: "문득 배가 허출함을 깨달았다  ",
              Eng: "All of a sudden, he realized that he was hungry. ",
            },
          ],
        },
        {
          entryName: "-ㅁ을 뉘우친다",
          typicalExample:
            "【애매한 머슴을 내쫓았음을 뉘우치리라고도  생각되었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Regrets doing or having done ...",
          sentences: [
            {
              Kor: "애매한 머슴을 내쫓았음을 뉘우치리라고도  생각되었다 ",
              Eng: "It occurred to him that [the old man] might regret having driven him off his obscure farm-hand existence.",
            },
          ],
        },
        {
          entryName: "-ㅁ을 느낀다",
          typicalExample: "【열정이 맥없이 스러지고 있음을 느꼈다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Feel/sense/perceive the act (or fact) of doing/being.",
          sentences: [
            {
              Kor: "열정이 맥없이 스러지고 있음을 느꼈다  ",
              Eng: "I felt my dedication collapsing.",
            },
            {
              Kor: "여늬 스푼이 아님을 느낀다  ",
              Eng: "It did not feel like an ordinary spoon.",
            },
            {
              Kor: "중실은 제 몸이 스스로 별이 됨을 느꼈다  ",
              Eng: "Chungsil felt his body turning into a star.",
            },
            {
              Kor: "정욕이 끓어오름을 느꼈다  ",
              Eng: "I felt desire welling up inside of me.",
            },
            {
              Kor: "나는 심한 부끄러움을 느꼈다  ",
              Eng: "I felt an intense shame.",
            },
            {
              Kor: "날카로워짐을 느끼었다  ",
              Eng: "… felt [his upper and lower teeth] getting sharp (← 날카로워지- get/become sharp)",
            },
            {
              Kor: "얼굴이 확 달아오름을 느꼈다  ",
              Eng: "He felt his face flushing.",
            },
            {
              Kor: "여자는 행동의 불편함을 느꼈다  ",
              Eng: "The woman felt discomfort in her movements.",
            },
            {
              Kor: "더운 피가 얼굴로 몰림을  여자는 느꼈다  ",
              Eng: "She could feel a flush of warm blood in her face.",
            },
            {
              Kor: "그를 볼 길이 없음을  여자는 느꼈다  ",
              Eng: "She felt as though there would be no way for her to see him.",
            },
          ],
        },
        {
          entryName: "-ㅁ을 말해 준다",
          typicalExample: "【몇 시간 동안이나 반복된 것임을 말해 준다 . 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㅁ 을 말해 준다: tells that/reveals that/gives away the fact that... (based on the substantive in -(으)ㅁ the act (or fact) of doing/being).",
          sentences: [
            {
              Kor: "몇 시간 동안이나 반복된 것임을 말해 준다 . ",
              Eng: "… suggest that this has been repeating itself over the course of several hours.",
            },
          ],
        },
        {
          entryName: "-ㅁ을 발견한다",
          typicalExample: "【타고 있음을 발견한다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Discovers that ... ; finds out/learns that ...",
          sentences: [
            {
              Kor: "타고 있음을 발견한다  ",
              Eng: "[he] discovers that [the new cigarette] is burning",
            },
            {
              Kor: "얼굴임을 발견하였다  ",
              Eng: "discovered ['the fact'] that it was a face [which...]",
            },
          ],
        },
        {
          entryName: "-ㅁ(을) 생각한다",
          typicalExample: "【가랑잎에 싸둔 개꿀이 있음을 생각하고  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Realize that ..., think that ...",
          sentences: [
            {
              Kor: "가랑잎에 싸둔 개꿀이 있음을 생각하고  ",
              Eng: "His thoughts turned to the fresh honey hidden away in the dead leaves, and ...",
            },
          ],
        },
        {
          entryName: "-ㅁ을 알았다",
          typicalExample:
            "【환상 때문에 잠 못 이루는 것이 자신 뿐만은 아님을 알았다 .  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)ㅁ을 알았다: discovered that ...; found out/learned that ...; understtod that ...; knew that ...; realized that ... (on substantive -(으)ㅁ the act (or fact) of having/being).",
          sentences: [
            {
              Kor: "환상 때문에 잠 못 이루는 것이 자신 뿐만은 아님을 알았다 .  ",
              Eng: "She realized that she was not the only one could not get to sleep because of hallucinations.",
            },
            {
              Kor: "이제는 늙고 힘없는 산이 돼 있음을 알았다  ",
              Eng: "Now I understood that the mountain had become old and feeble.",
            },
            {
              Kor: "여태껏 그가 아내 생각을 하고 있었음을 알았다  ",
              Eng: "I knew that all this time he had been thinking about his wife.",
            },
          ],
        },
        {
          entryName: "-ㅁ을 알아차린다",
          typicalExample: "【물건임을 알아차렸고  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Realizes that ...",
          sentences: [
            {
              Kor: "물건임을 알아차렸고  ",
              Eng: "[She] (finally) realized that it was an object [which was not much use] ...",
            },
          ],
        },
        {
          entryName: "-ㅁ을 의식한다",
          typicalExample: "【갇혀 있음을 의식한다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Feels/senses/perceives the act (or fact) of doing or being.",
          sentences: [
            {
              Kor: "갇혀 있음을 의식한다  ",
              Eng: "[He] recognizes that he is confined.",
            },
          ],
        },
        {
          entryName: "-ㅁ이겠다",
          typicalExample: "【이서방 모친의 일을 못마땅해 하심이겠지  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "이서방 모친의 일을 못마땅해 하심이겠지  ",
              Eng: "It is probably the case that [mother] doesn't like Mr. Yi's mother's actions.",
            },
          ],
        },
        {
          entryName: "-ㅁ이다",
          typicalExample: "【이서방 모친의 일을 못마땅해 하심이겠지  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "It is a case of ... based on substantive -(으)ㅁ: the act (or fact) of having/being + copula -이다.",
          sentences: [
            {
              Kor: "이서방 모친의 일을 못마땅해 하심이겠지  ",
              Eng: "It is probably the case that [mother] doesn't like Mr. Yi's mother's actions.",
            },
          ],
        },
        {
          entryName: "-ㅁ이려니",
          typicalExample: "【새싹이 잘 돋아나오게 함이려니  추측됐다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Substantive -(으)ㅁ + -(으)려니 + verb of thinking. This pattern has the same sense as tentative sequential -거니 (with the likely fact or reason that...) + verb of quotation: (says/thinks) that [quote] is most likely the case that, is probably the case that ...",
          sentences: [
            {
              Kor: "새싹이 잘 돋아나오게 함이려니  추측됐다 ",
              Eng: "It was reckoned this would make new sprouts grow better in the spring.",
            },
          ],
        },
        {
          entryName: "-ㅁ이리라",
          typicalExample: "【나이가 시킴이리라  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Substantive -(으)ㅁ + prospective assertive -(으)리라 is probably a case of ...-ing; I bet/I reckon that ... is likely the case. Often used to quote internal thoughts, or to 'get inside a protagonist's head'. By itself, -(으)리라  is afancy equivalent to -(으)ㄹ 것이다, and formally the quoted version of -(으)리다.",
          sentences: [
            {
              Kor: "나이가 시킴이리라  ",
              Eng: "It's probably just his age (lit.: It is probably a case of his age making him do it)",
            },
            {
              Kor: "눈물이 쏟아졌음이리라  ",
              Eng: "Must have been tears flowing.",
            },
          ],
        },
        {
          entryName: "-ㅁ이 분명하다",
          typicalExample: "【폐가임이 분명했다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "It is clear/obvious that ...",
          sentences: [
            {
              Kor: "폐가임이 분명했다  ",
              Eng: "It was clear that it was an abandoned house.; or, It was clear that the house had been abandoned.",
            },
            {
              Kor: "그 순간 그가 떠올리고 있었음이 분명했다  ",
              Eng: "At that moment, it was clear that he was conjuring up [the image].",
            },
          ],
        },
        {
          entryName: "-ㅁ인지",
          typicalExample: "【무엇을 생각했음인지 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "This is composed of the substantive in -(으)ㅁ (the act (or fact) of having/being) + copula -이- + modifier -ㄴ + postmodifier 지 the uncertain fact whether.... When left hanging in mid-sentence, you can interpret the pattern as having left out a following (도) 모르지만 it may have been the case that or it is uncertain WH... [but...].",
          sentences: [
            {
              Kor: "무엇을 생각했음인지 ",
              Eng: "Heaven knows what she had in mind ...",
            },
          ],
        },
        {
          entryName: "-ㅁ일 수는 없다",
          typicalExample: "【그의 두려움일 수는 없었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Substantive in -(으)ㅁ + copula -이- + -ㄹ 수는 없다 cannot be NOUN; ...is not possible for it be/ that it is NOUN.",
          sentences: [
            {
              Kor: "그의 두려움일 수는 없었다 ",
              Eng: "lit.: It could not be his fear. (He feared none of those.)",
            },
          ],
        },
        {
          entryName: "-ㅁ직 하다",
          typicalExample: "【어디선가 들어봤음직한  ... 음률이다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "It is/seems likely to be/do), it is possible/acceptable to (do), it is all right to (do); it is acceptably/rather/somewhat/quite ...; it is worth doing = (I.e., similar in function to the pattern in -(으)ㄹ만 하다).",
          sentences: [
            {
              Kor: "어디선가 들어봤음직한  ... 음률이다. ",
              Eng: "It was music that I had likely heard somewhere.",
            },
            {
              Kor: "그 거칠고 큼직한  발자국 소리 ",
              Eng: "the sounds of their rough, well-sized footsteps ",
            },
            {
              Kor: "굴고 하염직은 한 노릇이었다",
              Eng: "It was worth acting this way.",
            },
          ],
        },
        {
          entryName: "마냥 ",
          typicalExample: "【놀이 나온 아이들마냥  】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation: "Particle 마냥 = 처럼 like.",
          sentences: [
            {
              Kor: "놀이 나온 아이들마냥  ",
              Eng: "like children that have come out to play",
            },
          ],
        },
        {
          entryName: "마따나 ",
          typicalExample: "【안상 말씀 말마따나  】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation: "NOUN마따나: just like NOUN.",
          sentences: [
            {
              Kor: "안상 말씀 말마따나  ",
              Eng: "Just like you (Mr. An) had said ...",
            },
          ],
        },
        {
          entryName: "마련이다 ",
          typicalExample: "【먹구 살게는 마련야  = 먹고 살게(는) 마련이야  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Usually preceded by adverbative -게, the pattern in 마련이다 means one cannot help doing/being; one inevitably does/is. The same pattern also occurs as both -기 마련이다 and -도록 마련이다 with the same meaning. Samuel Martin flags -게 마련이다 as dialect, but it appears to be pretty standard.",
          sentences: [
            {
              Kor: "먹구 살게는 마련야  = 먹고 살게(는) 마련이야  ",
              Eng: "Life is such that you always survive somehow.",
            },
            {
              Kor: "그럼 사무실은 비게 마련이다 ",
              Eng: "In that case, the offices are bound to be empty.",
            },
            {
              Kor: "손님이 끓게 마련이고  ",
              Eng: "Customers would inevitably crowd into [the tabang], and ...; or, It was natural that [the tabang] would be crowded with customers [on a cold day like today], and … ",
            },
            {
              Kor: "대개 여기서 끝나게 마련이다  ",
              Eng: "[The deal] usually ends here. ",
            },
          ],
        },
        {
          entryName: "마음뿐이지",
          typicalExample: "【그런데두 마음뿐이지 , 몇 년이 흘러요.】",
          categoryKorean: "Stock phrase",
          categoryEnglish: "(Stock phrase)",
          translation: "Stock phrase.",
          sentences: [
            {
              Kor: "그런데두 마음뿐이지 , 몇 년이 흘러요.",
              Eng: "It's easy enough to think about it (but living it is another matter), years going by just like that. ",
            },
          ],
        },
        {
          entryName: "마자",
          typicalExample: "【마자  먹읍시다】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 마자 or (more recently/standardly) 마저 + VERB: finish VERBing. This is an extension in usage of the particle 마저 meaning going to the limit (extreme) of; (including) even; also (on top of everything or everyone else); all the way; with everything else; with all the rest.",
          sentences: [
            { Kor: "마자  먹읍시다", Eng: "Let's finish drinking it." },
          ],
        },
        {
          entryName: "마저(도)",
          typicalExample: "【집(을) 마저  팔았다】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "Delimiter 마저(도) going to the extreme/limit of; (including) even; including (even the extreme case of); also (on top of everything/everyone else); with all the rest.",
          sentences: [
            {
              Kor: "집(을) 마저  팔았다",
              Eng: "He went so far as to sell his house (or, He even had to sell his house as the final outcome of his misfortunes).",
            },
            {
              Kor: "정씨에게는 풍문마저  낯설었다 ",
              Eng: "To Mr. Chŏng, even the hint of it was unfamiliar.",
            },
            {
              Kor: "고향집마저  남의 손에 넘기게 된 모양이었다",
              Eng: "It looks like even their old homestead has fallen into other's hands.",
            },
            {
              Kor: "잠꼬대 같은 대화마저  끊어졌다",
              Eng: "Even this 'sleep-talkish' dialogue was cut off.",
            },
            { Kor: "박훈장마저 ", Eng: "Even Schoolmaster Pak ..." },
            {
              Kor: "내 방 호마이카상 위에 켜진 촛불마저도  ",
              Eng: "Even the candle light on top of the formica table in my room ...",
            },
            {
              Kor: "희미한 촛불마저도  ",
              Eng: "including even faint candle-light ...",
            },
            {
              Kor: "정씨마저도  불가로 다가앉아 ",
              Eng: "Even Mr. Chŏng sat closer to the bonfire ...",
            },
          ],
        },
        {
          entryName: "마찬가지(이)다",
          typicalExample: "【일 년 전의 윤호는 아이와 마찬가지였다 .  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN과 마찬가지(이)다: is the same as NOUN; is no different than NOUN; amounts to the same thing as NOUN. In origin, 마찬가지 ← 마치 한 가지 just as if one and the same kind.",
          sentences: [
            {
              Kor: "일 년 전의 윤호는 아이와 마찬가지였다 .  ",
              Eng: "The Yunho of one year ago was no different than a child.",
            },
          ],
        },
        {
          entryName: "마찬가지(로) ",
          typicalExample:
            "【인규는 다른 아이들과 마찬가지로  앉아서 춤을 추었다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN과 마찬가지(로): in a way that is the same as NOUN; in a way no different than NOUN; amounting to the same thing as NOUN. In origin, 마찬가지 ← 마치 한 가지 just as if one and the same kind.",
          sentences: [
            {
              Kor: "인규는 다른 아이들과 마찬가지로  앉아서 춤을 추었다. ",
              Eng: "In'gyu, just like the others, was dancing sitting down.",
            },
          ],
        },
        {
          entryName: "마치",
          typicalExample:
            "【마치  영화를 보거나 소설을 읽는 것처럼  느껴진다.  】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb meaning like, as, giving the appearance of; appearing (to be), looking (like); seeming as if; as if/though. Like 흡사, 마치 signals that a pattern with (-)듯이, 같다 or 처럼 is coming up.",
          sentences: [
            {
              Kor: "마치  영화를 보거나 소설을 읽는 것처럼  느껴진다.  ",
              Eng: "It feels just like watching a movie or reading a novel.",
            },
            {
              Kor: "마치  유령인 것처럼 느껴지는 사람.",
              Eng: "A person who feels just like a ghost.",
            },
            {
              Kor: "마치 도마뱀이 벽을 타고 기어 내려오고 있는 것처럼  보이지.  ",
              Eng: "It looks just like a lizard crawling down the wall.",
            },
            {
              Kor: "마치  무엇인가가 다시 나에게 찾아드는 것처럼 ",
              Eng: "[I felt] as if 'something' had come to mind again ...",
            },
            {
              Kor: "안방은 마치  동굴 속처럼  ",
              Eng: "the main room was [dim and cozy] just like the inside of a cave",
            },
            {
              Kor: "마치  죽는 날까지 놓여날 수 없는 업보처럼  ",
              Eng: "like retributions [for her sins in her former life] from which she could not be released until the day she died ",
            },
            { Kor: "마치  선생처럼  ", Eng: "as if he were a schoolteacher " },
            {
              Kor: "마치  날개 뜯긴 날벌레처럼  ",
              Eng: "just like a fly that's had its wings clipped off",
            },
            {
              Kor: "마치  숨바꼭질하는 요정들처럼  고왔다",
              Eng: "It was as lovely as elves playing hide-and-seek.",
            },
            {
              Kor: "숲은 마치  잿빛 강물이 흐르는 것처럼  보였다 ",
              Eng: "The forest looked just like a flowing ashen river.",
            },
            {
              Kor: "마치  ... 이 순간을 기다리기라도 한 듯  ",
              Eng: "just as if ... had been waiting [or something] for this instant [or something]",
            },
            {
              Kor: "마치  한 꾸리의 실타래가 풀리듯   ",
              Eng: "just like a skein of thread unraveling",
            },
            {
              Kor: "마치  나의 눈이 점점 정확해져 가고 있는 듯이  ",
              Eng: "just as if they were running around outside my window ",
            },
            {
              Kor: "마치  자기네 어린애의 재롱을 자랑하듯  하였다 ",
              Eng: "They were proud [of the chicks' feats] as if they were praising their own children's antics.",
            },
            {
              Kor: "마치  처음이자 마지막 기회라도 되는 듯이  ",
              Eng: "as if ... this were my first and last opportunity ...",
            },
            {
              Kor: "마치 발악이라도 하듯이 ",
              Eng: "just as if she were raving or something ",
            },
            {
              Kor: "결혼도 안 했으면서 마치  내 아이 같은  느낌이다. ",
              Eng: "It feels just as if they were children borne out of wedlock. ",
            },
            {
              Kor: "마을은 마치  그 철책의 끝에 간신히 매어 달려 있는 것 같았다 ",
              Eng: "The village looked as if it was just barely hanging at the end of the iron fence.",
            },
          ],
        },
        {
          entryName: "마치 ... 것이나 ",
          typicalExample:
            "【마치  한때 미국 사회가 묵크래커스를겁냈던 것이나  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Like, as, giving the appearance of ...; appearing (to be), looking (like); seeming as if; [just] as if/though. The use of -(이)나 here is reminiscent of the pattern in NOUN이나 마찬가지다 is no different from, is just the same as NOUN. ",
          sentences: [
            {
              Kor: "마치  한때 미국 사회가 묵크래커스를겁냈던 것이나  ",
              Eng: "Just like the period when American society was fearful (or something like that) of muckrakers …",
            },
          ],
        },
        {
          entryName: "마침",
          typicalExample: "【내가 마침  지금 돈이 없는데 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb meaning just now; just then; as (good) luck would have it,  just in the nick of time, fortunately, opportunely, coincidentally.",
          sentences: [
            {
              Kor: "내가 마침  지금 돈이 없는데 ",
              Eng: "I don't have any money just right now …",
            },
            {
              Kor: "마침  장이 섰었는지",
              Eng: "Perhaps because, as luck would have it, a market had been set up. ",
            },
            {
              Kor: "마침  날이 저물어 하룻밤 묵어갈까 하고 . . .",
              Eng: "Just as it started to get dark, I thought maybe I could stay the night...",
            },
          ],
        },
        {
          entryName: "만 = 만 해도",
          typicalExample:
            "【집에서 듣는 것만도  지겨운데 밖에서까지 그만 마시라는 잔소리 들어 가며】",
          categoryKorean: "보조사 (Particle)",
          categoryEnglish: "(보조사 (Particle))",
          translation:
            "NOUN만 for NOUN만 해도 lit.: even just saying, i.e. to mention only. ",
          sentences: [
            {
              Kor: "집에서 듣는 것만도  지겨운데 밖에서까지 그만 마시라는 잔소리 들어 가며",
              Eng: "It was bad enough hearing it at home, but to have to listening to her nagging me to stop drinking even when we were out, too … ",
            },
            {
              Kor: "차 타는 시간만  세 시간 가까이 걸렸으니",
              Eng: "The time in the car alone amounted to nearly three hours …",
            },
          ],
        },
        {
          entryName: "~만 ...-면",
          typicalExample: "【방문만 열면 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN만 VERB(으)면 or NOUN + particle + 만 ... VERB(으)면: no sooner does it/one do or be, than...; as soon as ...then ...; if only. More hypothetical than just -(으)면.",
          sentences: [
            { Kor: "방문만 열면 ", Eng: "The moment you open the door ..." },
            {
              Kor: "호랑이가 있다는 사실만  확인되면 ",
              Eng: "If only the fact that there is a tiger is confirmed ...",
            },
            {
              Kor: "호랑이만  찾을 수 있다면  ",
              Eng: "If only you could find a tiger ...",
            },
            {
              Kor: "호랑이 생각만  나면  ",
              Eng: "As soon as he thinks of tigers ...",
            },
            {
              Kor: "산기슭에만  오르면  ",
              Eng: "If one just alights the foot of the mountain ...",
            },
            {
              Kor: "한번 산에만  들어오면  별 수 없지",
              Eng: "Once she enters the mountain, she'll be done for it.",
            },
          ],
        },
        {
          entryName: "만도",
          typicalExample: "【좌우간 바닷가까지만도  몇백리 길이요】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "For NOUN만 해도: lit.: even just saying, i.e., to mention only.",
          sentences: [
            {
              Kor: "좌우간 바닷가까지만도  몇백리 길이요",
              Eng: "Anyway, it's several hundred li's journey [from here] just to get to the sea.",
            },
          ],
        },
        {
          entryName: "만도 아니다",
          typicalExample:
            "【선생들의 평가 기준이 꼭 공정했던 것만도 아닐 것이다 .  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN만도 아니다: and it is not just a matter of NOUN; and it isn't just a case of NOUN.",
          sentences: [
            {
              Kor: "선생들의 평가 기준이 꼭 공정했던 것만도 아닐 것이다 .  ",
              Eng: "Nor was it likely the case that the teachers' marking standards were exactly fair.",
            },
          ],
        },
        {
          entryName: "만 빼놓고",
          typicalExample: "【자는 시간만 빼놓고  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN[만] 빼놓고: except for NOUN",
          sentences: [
            {
              Kor: "자는 시간만 빼놓고  ",
              Eng: "except only for the time she is sleeping",
            },
          ],
        },
        {
          entryName: "만약",
          typicalExample:
            "【만약  그녀가, 그럼 어떡해요 당신도 없는데, 했다면  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Conditional in 만약  ...-는/(으)ㄴ다면, -다면, -(이)라면 built original on a quotation—-는/(으)ㄴ다[고 하]면 , -다[고 하]면, -(이)라[고 하]면—and hence more hypothetical (alleged, putative) than plain -(으)면.",
          sentences: [
            {
              Kor: "만약  그녀가, 그럼 어떡해요 당신도 없는데, 했다면  ",
              Eng: 'If she had said, "What was I supposed to do; you weren\'t home," …',
            },
          ],
        },
        {
          entryName: "만에",
          typicalExample: "【학원 강사 생활 1년만에   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "ELAPSED TIME/Time word: only after (marking a passage of time); (only) after (such and such) a time/interval ",
          sentences: [
            {
              Kor: "학원 강사 생활 1년만에   ",
              Eng: "after working for one year as a lecturer at the cram school",
            },
            {
              Kor: "십오 년 만에  딸을 한국에 보내며  ",
              Eng: "sending his daughter to Korea for the first time in 15 years",
            },
            { Kor: "이틀 만에  ", Eng: "[only] after two days" },
            {
              Kor: "그가 떠나간 지 일 년 만에  ",
              Eng: "barely a year after he left",
            },
            {
              Kor: "이틀 만에 호랑이를 따라잡았다고 했다",
              Eng: "He said that after only two days, he followed and caught a tiger.",
            },
            {
              Kor: "옥바라지 두 달만에  ",
              Eng: "after two months of taking care [of the prisoners]",
            },
            { Kor: "한참 만에  ", Eng: "only after some time" },
            {
              Kor: "아흔 아홉 번만에  못 캐었대도 ",
              Eng: "Even though he couldn't pry it out after ninety-nine times... ",
            },
            {
              Kor: "모처럼만의  만단정회",
              Eng: "all sorts of fond recollections, only after much delay ...",
            },
          ],
        },
        {
          entryName: "만에야",
          typicalExample: "【한참 만에야  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "ELAPSED TIME만에: to/at/for/just/barely/exactly ELAPSED TIME; only after; after a passage of + particle 야, alternant of 이야 only if it be; if (it be), when it comes to; even, indeed, marking a reinforced contingency. In other words, a more emphatic version of just ELAPSED TIME만에.",
          sentences: [
            {
              Kor: "한참 만에야  ",
              Eng: "only after quite some time; only after quite a while",
            },
          ],
        },
        {
          entryName: "만으로",
          typicalExample:
            "【같이 자자고 요구하는 남자에게 눈물만으로  사랑을 확인해 달라며  】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "NOUN만으로: by means of only NOUN; only with NOUN; only by way of just NOUN; only on the basis of just NOUN",
          sentences: [
            {
              Kor: "같이 자자고 요구하는 남자에게 눈물만으로  사랑을 확인해 달라며  ",
              Eng: "[While] asking, with only tears, a man who has demanded sex from me to confirm his love for me …",
            },
          ],
        },
        {
          entryName: "만으로도",
          typicalExample: "【그것만으로도  신이 되기엔 충분하지.  】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "NOUN만으로도: even by way of just NOUN; even on the basis of just NOUN ",
          sentences: [
            {
              Kor: "그것만으로도  신이 되기엔 충분하지.  ",
              Eng: "Even on the basis of just that, it was sufficient to make [him] god.",
            },
          ],
        },
        {
          entryName: "만은 ",
          typicalExample: "【호기심만은  숨기질 않았다 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Delimiter 만 + particle 은 denoting contrast (usually in anticipation of a negative verb).",
          sentences: [
            {
              Kor: "호기심만은  숨기질 않았다 ",
              Eng: "Only his curiosity was not hidden.",
            },
            {
              Kor: "죽길 기를 쓰고 흔들어 대던 도리질만은  ",
              Eng: "Only the head-shaking, which she had done with all her strength … (The 죽길 here functions like 죽어라고 [lit.: as if to die])",
            },
          ],
        },
        {
          entryName: "만을",
          typicalExample: "【흙먼지만을 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Delimiter 만 plus particle 을 only/just (etc.) [as object]",
          sentences: [
            { Kor: "흙먼지만을 ", Eng: "only the dust " },
            {
              Kor: "빈 지게만을  짊어지고",
              Eng: "Simply threw the A-frame on his back ...",
            },
            {
              Kor: "왕밤만을  골라 봉네를 주곤 하였다",
              Eng: "He would select and give her only the big chestnuts. ",
            },
            {
              Kor: "그녀가 오기만을  기다리며 나는 하루종일을 보내고 있다. ",
              Eng: "I am spending the whole day waiting only for her to come.",
            },
            {
              Kor: "애인이니 조금의 쓸쓸함만을  남길 따름이다. ",
              Eng: "Since he's my boyfriend, it only leaves a bit of sadness.",
            },
            {
              Kor: "정액으로 칠갑한 채 죽어있는 남자만을  남겨두고  ",
              Eng: "Leaving behind only the man covered in semen …",
            },
          ],
        },
        {
          entryName: "만(을) 제외하고(는)",
          typicalExample: "【떨리는 손가락만을 제외하곤  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN만(을) 제외하고: except for NOUN. Note also 하곤 for 하고는.",
          sentences: [
            {
              Kor: "떨리는 손가락만을 제외하곤  ",
              Eng: "except for my trembling fingers (With 제외하곤 contracted from 제외하고는)",
            },
          ],
        },
        {
          entryName: "만이",
          typicalExample: "【그들에겐 영원히 현재만이  존재해. 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation: "Delimiter 만+ particle 이 only just [as subject].",
          sentences: [
            {
              Kor: "그들에겐 영원히 현재만이  존재해. ",
              Eng: "For them, only the present is neverending.",
            },
            {
              Kor: "나는 용감한 자만이  미인을 얻는다며 짐짓 비장해 했다.  ",
              Eng: "I made a point of playing the tragi-heroic, claiming that only the very brave get the good-looking women.",
            },
            {
              Kor: "은희는 여자만이  갖는 직감으로 윤호에게는 어머니가 없었구나 생각했다.  ",
              Eng: "With the instincts that only women have, Ŭnhŭi thought to herself, 'Say, he never had a mother!'",
            },
            { Kor: "흉년만이  계속되었다 ", Eng: "Famine simply continued." },
            {
              Kor: "그저 흰 수염만이  그들의 턱에서 가늘게 떨리고 있었다.",
              Eng: "Only their white beards [were quivering on their chins]. ",
            },
            {
              Kor: "한 마리만이  그 바로 윗가지에서 ",
              Eng: "Only one of the cranes [was there, bending its leg] is on the branch just above ...",
            },
            {
              Kor: "학의 둥우리만이  빈 채 달려 있었다",
              Eng: "Only the crane's nest hung empty in the tree.",
            },
          ],
        },
        {
          entryName: "만이다",
          typicalExample: "【며칠 만인지  모른다.  】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "Time word + particle 만이다 marking the passage of time: only after is/occurs after an elapsed time of ...",
          sentences: [
            {
              Kor: "며칠 만인지  모른다.  ",
              Eng: "I have no idea how many days it's been [since taking a bath].",
            },
            {
              Kor: "몇 년만입니까 ? ",
              Eng: "How many years has it been? (lit.: After how many years?)",
            },
          ],
        },
        {
          entryName: "만(이) 아니다",
          typicalExample: "【그밤에 달이 없었다는 뜻에서만이 아니다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN만(이) 아니다: it isn't just NOUN; it isn't just a matter of NOUN. ",
          sentences: [
            {
              Kor: "그밤에 달이 없었다는 뜻에서만이 아니다  ",
              Eng: "It was not simply to mean that the night was moonless.",
            },
          ],
        },
        {
          entryName: "만치 ",
          typicalExample:
            "【저만치  대문이 열리고 문전이 정갈한 [여인숙]이란 간판이 붙은 집이 보였다. 】",
          categoryKorean: "Particle/Dialect morphology/Substandard",
          categoryEnglish: "(Particle/Dialect morphology/Substandard)",
          translation:
            "Substandard/dialect particle 만치 equivalent to standard 만큼. ",
          sentences: [
            {
              Kor: "저만치  대문이 열리고 문전이 정갈한 [여인숙]이란 간판이 붙은 집이 보였다. ",
              Eng: 'I saw over there in the distance a house—its gate was open; it was tidy about the gate; and it had a sign on which its read "Inn". ',
            },
          ],
        },
        {
          entryName: "만침이나",
          typicalExample: "【그만침이나  청백했기 망정이지】",
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation: "Dialect for 만큼이나. ",
          sentences: [
            {
              Kor: "그만침이나  청백했기 망정이지",
              Eng: "You're lucky you are upright, however [piddling/insignificant/measly] much ...",
            },
          ],
        },
        {
          entryName: "만큼",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation: "Particle 만큼: as much as, equal to",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "만큼씩",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Particle 만큼 as much as, equal to + distributive particle 씩 about ... apiece, approximately ...each.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "만큼씩이나",
          typicalExample: "【버스는 몇 분만큼씩이나  있나요?】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Particle 만큼 as much as, equal to + distributive 씩 about ...apiece, approximately ...each + approximative -(이)나), implying that the speaker thinks the amount is substantial or more than expected.",
          sentences: [
            {
              Kor: "버스는 몇 분만큼씩이나  있나요?",
              Eng: "How often does the bus run? (lit.: About how many minutes between buses?)",
            },
          ],
        },
        {
          entryName: "만큼이나",
          typicalExample: "【소중해했던 것만큼이나  정나미가 떨어지면서】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "Particle 만큼이나 as (unreasonably or surprisingly) much as ... from particle 만큼 as much as, equal to + approximative -(이)나), implying that the speaker thinks the amount  is substantial or more than expected.",
          sentences: [
            {
              Kor: "소중해했던 것만큼이나  정나미가 떨어지면서",
              Eng: "I felt as disgusted by it as I was affectionate toward it …",
            },
            {
              Kor: "이 온천장 거리만큼이나 생소하고 싫었다",
              Eng: "The feelings of unfamiliarity and disgust were [as strong as was] the distance to this hot-spring. ",
            },
            {
              Kor: "부자가 되기를 바라는 것만큼이나  허황된 꿈이었다 ",
              Eng: "It was a dream as unattainable as [hoping to become a rich man]. ",
            },
          ],
        },
        {
          entryName: "만 하여도",
          typicalExample: "【오늘 아침만 하여도  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN만 하여도(해도): to mention only; even/only just ... Slightly fancier and more bookish than the colloquial NOUN만 해도.",
          sentences: [
            { Kor: "오늘 아침만 하여도  ", Eng: "only just this morning " },
          ],
        },
        {
          entryName: "만 해도",
          typicalExample: "【보험료만 해두  = 해도 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN 만 해도 lit.: even just saying, i.e. to mention only. The newer, more colloquial version of older and slightly fancier, more bookish NOUN만 하여도.",
          sentences: [
            {
              Kor: "보험료만 해두  = 해도 ",
              Eng: "Just the insurance payments alone [amounted to 3 wŏn 80 chŏn per month].",
            },
            { Kor: "빚만 해두  ", Eng: "The debt alone [is more than ...]" },
            {
              Kor: "어제만 해도  그렇다. ",
              Eng: "It was like that only yesterday.",
            },
            {
              Kor: "그때만 해도  윤호는 너무 모르는 것이 많았다.  ",
              Eng: "Even then, there were so many things that Yunho didn't know.",
            },
          ],
        },
        {
          entryName: "만하다",
          typicalExample: "【백 원 동전 만한  흰 원】",
          categoryKorean: "Adjective",
          categoryEnglish: "(Adjective)",
          translation:
            "Post-nominal adjective 만하다 is to the extent of, is as big/little as, is the size of.",
          sentences: [
            {
              Kor: "백 원 동전 만한  흰 원",
              Eng: "lit: a white circle the size of a 500-wŏn coin",
            },
            {
              Kor: "온천장 거리는 손바닥만했다  ",
              Eng: "The road near the hot-springs was no larger than the palm of your hand.",
            },
            {
              Kor: "손바닥만한  마당",
              Eng: "a courtyard the size of the palm of a hand ",
            },
            {
              Kor: "주먹만한  고구마",
              Eng: "a sweet potato the size of a fist",
            },
            { Kor: "조막만하고  ", Eng: "… the size of a fist, and ..." },
          ],
        },
        {
          entryName: "말야",
          typicalExample: '【"그래서 집을 복덕방에 내놔 버린다고 말야." 】',
          categoryKorean: "해체의 종결 어미 Final ending in Intimate Style",
          categoryEnglish: "(해체의 종결 어미 Final ending in Intimate Style)",
          translation:
            "Interjected 말야 ← 말이야 (말 words, talk + Intimate Style copula -이야): Lit.: it is saying ... ; it is words to the effect that... can follow a noun or just about any free-standing phrase or clause to function like the English interjections: I mean, you know, you see, uh, that is. ",
          sentences: [
            {
              Kor: '"그래서 집을 복덕방에 내놔 버린다고 말야." ',
              Eng: '"And so, I mean they apparently put their places up for sale through realtors."',
            },
            {
              Kor: "그 남자의 아랫도리가 벗겨져 있었는데 말야 .  ",
              Eng: "And like, the guy's lower torso was stripped off.",
            },
            { Kor: "보통 여자들이 말야 ", Eng: "I mean normal women." },
            {
              Kor: "저러니 언제 한번 온전한 살림 살겠나 말야 ! ",
              Eng: "Judging from your attitude, you are never going to lead a proper [married] life, you know!",
            },
            {
              Kor: "책임이 더 무거워질 테니 말야  ",
              Eng: "If you become the managing director, you'll probably have much more responsibility, I mean.",
            },
          ],
        },
        {
          entryName: "말요",
          typicalExample: "【하지만 집이 워낙에 커서 말요 】",
          categoryKorean:
            "하오체의 종결 어미 Final ending in Semiformal Style  ",
          categoryEnglish:
            "(하오체의 종결 어미 Final ending in Semiformal Style  )",
          translation:
            "Interjected 말요 ← 말이오 (말 words, talk + Semi-Formal/Blunt/Authoritative Style copula -이오): Lit.: it is saying ... ; it is words to the effect that... can follow a noun or just about any free-standing phrase or clause to function like the English interjections:  I mean, you know, you see, uh, that is. ",
          sentences: [
            {
              Kor: "하지만 집이 워낙에 커서 말요 ",
              Eng: "But you know, the workshop is so big. ",
            },
          ],
        },
        {
          entryName: "말우 ",
          typicalExample: "【말두 말우  】",
          categoryKorean:
            "Dialect morphology/Substandard/하오체의 종결 어미 Final ending in Semiformal Style   ",
          categoryEnglish:
            "(Dialect morphology/Substandard/하오체의 종결 어미 Final ending in Semiformal Style   )",
          translation:
            "For 말오, dialect/substandard for 마오, the 하오 Style of 말다. Don't (do it)!",
          sentences: [
            { Kor: "말두 말우  ", Eng: "Don't even mention it!; Say no more!" },
          ],
        },
        {
          entryName: "말이다",
          typicalExample:
            "【떠나고 싶었다. 언제나 나를 굽어보는 십자가가 없는 곳으로 말이다 .  】",
          categoryKorean: "해라체의 종결 어미 Final ending in Plain Style",
          categoryEnglish: "(해라체의 종결 어미 Final ending in Plain Style)",
          translation:
            "Interjected 말이다 (말 words, talk + Plain Style copula -이다): it is to say = I mean, you-know, you-see, now see here, let me tell ya, uh, that is, I'm tellin' ya. ",
          sentences: [
            {
              Kor: "떠나고 싶었다. 언제나 나를 굽어보는 십자가가 없는 곳으로 말이다 .  ",
              Eng: "I wanted to leave. To a place where there is no cross looking down on me, I mean.",
            },
            {
              Kor: "증거가 어디 있냐 말이다  ",
              Eng: "(I'm asking ya / I mean) where is the proof?",
            },
            {
              Kor: "그 쪽 기가 펄럭대고 있었으니 말이다  ",
              Eng: "I mean, their side's flag was still fluttering.",
            },
            { Kor: "그건 말이다 , 음", Eng: "As for that, well, um ..." },
            {
              Kor: "당당한 군청 산림계장이 됐으니 말이다  ",
              Eng: "Because, I mean, I have become an imposing forester in the county office.",
            },
            {
              Kor: "별수도 없었겠지만 말이다  ",
              Eng: "I wouldn't have had any special luck, see what I mean? ",
            },
            {
              Kor: "돈이면 안되는 것이 없으니 말이다  ",
              Eng: "lit.: Because, as for money, there is nothing that can't be done, I'm tellin' ya.",
            },
          ],
        },
        {
          entryName: "말이야",
          typicalExample:
            "【그 남자를 감싸안겠지. 그 남자의 온 감각에 스며들면서 말이야 .  】",
          categoryKorean: "해체의 종결 어미 Final ending in Intimate Style",
          categoryEnglish: "(해체의 종결 어미 Final ending in Intimate Style)",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "그 남자를 감싸안겠지. 그 남자의 온 감각에 스며들면서 말이야 .  ",
              Eng: "She would embrace him, of course—while seeping into his every sense, that is.",
            },
          ],
        },
        {
          entryName: "말이지",
          typicalExample: "【사는 건 말이지 , 영화 같은 거야.  】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "NOUN은 말이지 used to interject or bring up a new topic. I mean—NOUN (as topic). Interjected 말이지 (말 words, talk + Suspective Style copula -이지): it is to say = I mean, you-know, you-see, now see here, let me tell ya, uh, that is, I'm tellin' ya. ",
          sentences: [
            {
              Kor: "사는 건 말이지 , 영화 같은 거야.  ",
              Eng: "I mean, living—it's like a movie.",
            },
            {
              Kor: "그 요지만 산단 말이지  ← 산다고 하는 말이지  ",
              Eng: "What I mean is that we would only be buying those important plots of land.",
            },
            {
              Kor: "고기두 얼마든지 잡을 수 있구 말이지  ",
              Eng: "I mean, you can catch as many fish as you want.",
            },
            {
              Kor: "장하게 해야 말이지  = 장하게 해야 [돼] 말이지 ",
              Eng: "You better do [the funeral] in grand style, ya hear?",
            },
          ],
        },
        {
          entryName: "말인가?",
          typicalExample: "【나하고 친하게 지내던 애 말인가? 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "Interjected 말인가? (말 words, talk + copular question in -인가?) Do you mean ...? Do you mean to say NOUN?",
          sentences: [
            {
              Kor: "나하고 친하게 지내던 애 말인가? ",
              Eng: "Do you mean Cho, the guy I used to be close with?",
            },
          ],
        },
        {
          entryName: "말입니까?",
          typicalExample: "【[Examples?]】",
          categoryKorean: "하십시오체의 종결어미 Final ending in Formal Style",
          categoryEnglish:
            "(하십시오체의 종결어미 Final ending in Formal Style)",
          translation:
            "Interjected 말입니까 (말 words, talk + Formal Style copular question -입니까): is to say  ...? = Do you mean ...? Do you mean to say ...? ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "말입니다",
          typicalExample: "【자기성취 같은 것을 말입니다  】",
          categoryKorean: "하십시오체의 종결어미 Final ending in Formal Style",
          categoryEnglish:
            "(하십시오체의 종결어미 Final ending in Formal Style)",
          translation:
            "Interjected 말입니다 (말 words, talk + Formal Style copula -입니다): it is to say = I mean, you-know, you-see, now see here, let me tell ya, uh, that is, I'm tellin' ya. ",
          sentences: [
            {
              Kor: "자기성취 같은 것을 말입니다  ",
              Eng: "I mean something like one's personal accomplishments.",
            },
            {
              Kor: "공연히 주눅들어하는 것처럼  말입니다  ",
              Eng: "I mean, like pointlessly losing their nerve.",
            },
          ],
        },
        {
          entryName: "-매 ",
          typicalExample: "【양반이 그릇 되 매  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "In origin, the substantive in -(으)ㅁ + locative particle 에/애 (the latter shape being an older, vowel-harmonic alternate form of what today is just one-shape 에): upon doing, as/when it is/does;since, because, given the fact that. Similar in function to -(으)므로.",
          sentences: [
            {
              Kor: "양반이 그릇 되 매  ",
              Eng: "Because he fell from being a yangban ...",
            },
            {
              Kor: "잘 받아 주지 않 으매  ",
              Eng: "Since he wasn't very receptive ...",
            },
            {
              Kor: "뱃속에서 풀어지 매  ",
              Eng: "Because it dissolved inside me ...",
            },
            {
              Kor: "홋카이도에서 나오 매 ",
              Eng: "When he returned from Hokkaido ...",
            },
            {
              Kor: "불이 크게 나 매  ",
              Eng: "Because the fire had grown so big ...",
            },
            {
              Kor: "직접 불놀이를 해보 매  ",
              Eng: "When I went with him to the fields to see the fire up close ...",
            },
          ],
        },
        {
          entryName: "매일같이",
          typicalExample: "【거의  매일같이  들르는 그 남자 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Not so much a pattern as a special idiomatic use of  같이.",
          sentences: [
            {
              Kor: "거의  매일같이  들르는 그 남자 ",
              Eng: "the man who dropped by practically every day",
            },
          ],
        },
        {
          entryName: "-며",
          typicalExample: "【반색을 하 며  나를 불렀다】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Non-final ending -(으)며 as a literary equivalent to -(으)면서 while; at the same time; (doing) simultaneously. ",
          sentences: [
            {
              Kor: "반색을 하 며  나를 불렀다",
              Eng: "Showing great joy, they called out to me.",
            },
            { Kor: "미소를 지으 며  물었다", Eng: "[He] asked, smiling." },
            {
              Kor: "악의를 보이 며  대답했다",
              Eng: "I answered, exhibiting my ill will.",
            },
            {
              Kor: "허리춤을 추슬려 올리 며  말했다 ",
              Eng: "He spoke while pulling up his trousers.",
            },
            {
              Kor: "다시 불을 붙이 며  말했다 ",
              Eng: "[I] was talking at the same time as I relit (my cigarette).",
            },
            {
              Kor: "그 이별을 생각하 며  말했다 ",
              Eng: "I was thinking of the separation while talking.",
            },
            {
              Kor: "나는 고개를 끄덕이 며  말했다 ",
              Eng: "I said while nodding. ",
            },
            {
              Kor: "오징어를 씹 으며  말했다",
              Eng: "He said, chewing on dried squid.",
            },
            {
              Kor: "자세를 바로하 며  말했다 ",
              Eng: "He sat himself straight and spoke.",
            },
            {
              Kor: "진지한 얼굴이 되 며  말했다",
              Eng: "He said with an earnest face.",
            },
            { Kor: "우물거리 며  대답했다", Eng: "He mumbled his response. " },
            {
              Kor: "정씨가 하늘을 올려다보 며  감탄했다 ",
              Eng: "Mr. Chŏng raised his gaze to the sky, admiring [the snowflakes].",
            },
            {
              Kor: "그 여자는 더듬거리 며  물었다",
              Eng: "The woman asked, stammering.",
            },
            {
              Kor: "금방 소리를 지르 며  달려 들듯한 모습",
              Eng: "appearing as if any second now [the trees] would cry out and attack him ",
            },
            {
              Kor: "아주머니가 안채에서 반색을 하 며  나타났다 ",
              Eng: "The woman, wearing a delighted look, appeared from the main wing. ",
            },
            {
              Kor: "대답하 며  나는 밤하늘을 올려봤다",
              Eng: "I answered, looking up at the night sky.",
            },
            {
              Kor: "여자는 나를 쳐다보 며  방긋 웃었다",
              Eng: "The girl looked up at me, flashing a beaming smile.",
            },
            {
              Kor: "쿵쿵 소리를 내 며  급하게 뛰고 있는 심장 ",
              Eng: "your racing heart, pounding violently ",
            },
            {
              Kor: "천수답과 비탈밭을 일구 며  산다 ",
              Eng: "…make a living while tilling and cultivating the rice paddies and steppes.",
            },
            {
              Kor: "달음박질 소리를 들 으며  나는 ",
              Eng: "Listening to the sounds of their pattering feet, I ...",
            },
            {
              Kor: "양씨가 퍼쓱 웃 으며  ",
              Eng: "Mr. Yang, letting out a short laugh ...",
            },
            {
              Kor: "혼자서 황소를 끌고 다니 며  ",
              Eng: "Going from place to place alone dragging his cow with him  ...",
            },
            {
              Kor: "눈을 빛내 며  나를 쳐다봤다",
              Eng: "Eyes twinkling, he stared at me.",
            },
            {
              Kor: "낙엽을 헤치 며  앞서 걸어나갔다 ",
              Eng: "Clearing away the dead leaves, he took the lead.",
            },
            {
              Kor: "담배 꽁초를 건네주 며  영달이가",
              Eng: "While passing the cigarette butt over (to the other guy) Yŏngdal ...",
            },
            {
              Kor: "정씨가 쓴웃음을 지으 며  고개를 저었다 ",
              Eng: "Mr. Chŏng put on a bitter smile while shaking his head.",
            },
            {
              Kor: "무릎을 치 며  웃었다",
              Eng: "He laughed, slapping his knee.",
            },
            {
              Kor: "거울면을 거침없이 밟 으며  훼손시키는 것으로 ",
              Eng: "(by means of) smudging the glassy surface of the gallery floor as they stepped across",
            },
            {
              Kor: "그가 고개를 저으 며  웃었다",
              Eng: "Shaking his head, he laughed.",
            },
            {
              Kor: "나는 비명을 지르 며  손가락을 보았다",
              Eng: "I shrieked, looking down at my finger.",
            },
            {
              Kor: "긴 머리채를 흔들 며  달아나면서도",
              Eng: "Even while scurrying off, shaking her long hair ...",
            },
            {
              Kor: "눈물이 징 소리를 내 며  괴어 올랐다",
              Eng: "The tears welled up with a stinging sensation. ",
            },
            {
              Kor: "입김을 길게 내뿜 으며  껄껄 웃어제꼈다",
              Eng: "[He] guffawed while blowing out a long steamy breath.",
            },
            {
              Kor: "간격을 두었다가 나를 보 며  말했다 ",
              Eng: "He placed some space between us and then looked at me and spoke.",
            },
            {
              Kor: "호랑이 길도 살펴 두었 으며 ",
              Eng: "He had also scanned over the tiger trail ...",
            },
          ],
        },
        {
          entryName: "-며부터 ",
          typicalExample: "【그런데 웬일인지 철들 며부터  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation: "Ever since ...-ing.",
          sentences: [
            {
              Kor: "그런데 웬일인지 철들 며부터  ",
              Eng: "But for some unknown reason, ever since puberty ['ever since she had matured']…",
            },
          ],
        },
        {
          entryName: "-며 지낸다",
          typicalExample:
            "【놀이터에서 그네를 타거나 상가 여기저기를 쏘다니 며 지냈다 .】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-(으)며 지낸다: gets along while ...-ing; gets along ...-ing; passes the time ...-ing, where -(으)며 is equivalent in function to -(으)면서 while, at the same time, (doing) simultaneously. ",
          sentences: [
            {
              Kor: "놀이터에서 그네를 타거나 상가 여기저기를 쏘다니 며 지냈다 .",
              Eng: "I spent my time swinging on the swing at the playground or cruising around the shops on the main road.",
            },
          ],
        },
        {
          entryName: "-면",
          typicalExample: "【안 떨어지 면 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation: "Conditional -(으)면: if; if and when; when supposing. ",
          sentences: [
            {
              Kor: "안 떨어지 면 ",
              Eng: "lit.: if [the cards] don't fall [his way], i.e., if he's not in luck",
            },
          ],
        },
        {
          entryName: "-면 고만이지",
          typicalExample: "【제 좋 으면 고만이지  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "VERB-(으)면 고만이지: if one VERBs, that's all that matters. ",
          sentences: [
            {
              Kor: "제 좋 으면 고만이지  ",
              Eng: "If she's happy, that's all that matters.",
            },
          ],
        },
        {
          entryName: "-면 그만이다",
          typicalExample: "【비위 좋게 생각하 면 그만이었다  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The best option is to ... ;  ...-ing is the best option, the best way to go... The expression 그만이- means the best (in the world), the most, tops, superb.",
          sentences: [
            {
              Kor: "비위 좋게 생각하 면 그만이었다  ",
              Eng: "It was best to just think of it in a good way.",
            },
          ],
        },
        {
          entryName: "-면 ...-ㄹ 수록",
          typicalExample: "【씹 으면  씹 을수록  고약해서】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "The more (better, bigger, etc.) ... the more ...",
          sentences: [
            {
              Kor: "씹 으면  씹 을수록  고약해서",
              Eng: "The more I mulled over it (lit.: chewed on it), the more bitter it felt ...",
            },
          ],
        },
        {
          entryName: "-면 몰라두 (도) ",
          typicalExample: "【조용한 데 가서 한코 달라 면 몰라두 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Literally: even though I wouldn't be sure if one were to do it..., hence: though I might be favorably inclined to ...",
          sentences: [
            {
              Kor: "조용한 데 가서 한코 달라 면 몰라두 ",
              Eng: "[Though I might be favorably inclined] if you had asked me to go somewhere quiet and to give you a fair share ...",
            },
          ],
        },
        {
          entryName: "-면서1 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Non-final ending -(으)면서 for simultaneity: while ...-ing (even) while ...-ing; at the same as one ...-es. Note that both clauses usually have the same subject. ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-면서2",
          typicalExample: "【11월에 들어서 면서 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "In what functions like an abbreviation of -(으)면서부터 ever since ...; starting from the time when ...",
          sentences: [
            {
              Kor: "11월에 들어서 면서 ",
              Eng: "[Ever] since the beginning of November ...",
            },
            { Kor: "병이 들 면서  ", Eng: "Ever since he got sick ..." },
          ],
        },
        {
          entryName: "-면서3 = -(으)면서도",
          typicalExample:
            "【결혼도 안 했 으면서  마치 내 아이 같은 느낌이다.】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "-(으)면서 functioning as concessive -(으)면서도 while ... yet; although ... also; at the very same time that. ",
          sentences: [
            {
              Kor: "결혼도 안 했 으면서  마치 내 아이 같은 느낌이다.",
              Eng: "It feels just as if they were my children without my ever having gotten married.",
            },
          ],
        },
        {
          entryName: "-면서도 ",
          typicalExample: "【나는 얼떨떨하면서도   】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Even though, though; (even) while; whilst, while ... yet; although ... also; at the very same time that. Cf. -어도, which can have different subjects for the two clauses; -(으)면서도 usually has the same subject for both.",
          sentences: [
            {
              Kor: "나는 얼떨떨하면서도   ",
              Eng: "Even though I was stupefied ...",
            },
            {
              Kor: "약간 허황되다고 느끼면서도   ",
              Eng: "While feeling that it was a little unbelievable ...",
            },
            {
              Kor: "뇌물을 먹지 아니한 것이 아니면서도   ",
              Eng: "Even though it wasn't exactly not bribery ...",
            },
            {
              Kor: "손으로 잡을 수 없으면서도  ",
              Eng: "Even though it couldn't be grabbed with your hand …",
            },
            {
              Kor: "그렇게 해야 된다고 생각하면서도   ",
              Eng: "Even though he knew that he had to do it ...",
            },
            {
              Kor: "강마담이 싫으면서도   ",
              Eng: "Even though he disliked Madam Kang ...",
            },
            {
              Kor: "안전하면서도  ",
              Eng: "[skills, which] at the same time they were safe/posed no danger, [and also made good money]",
            },
            {
              Kor: "그녀의 화장이 퇴근 준비라는 것을 알면서도   ",
              Eng: "Even though I knew her putting on make-up is to prepare for getting off from work...",
            },
            {
              Kor: "나와 함께 화랑 순례를 하면서도   ",
              Eng: "Although he went to galleries with me...",
            },
            { Kor: "나를 맡기면서도   ", Eng: "Even as it took over me..." },
            {
              Kor: "삼십릿길을 같이 걸어 오면서도   ",
              Eng: "Even while walking these thirty li together ...",
            },
            {
              Kor: "긴 머리채를 흔들며 달아나면서도   ",
              Eng: "Even while scurrying off, shaking her long hair ...",
            },
            {
              Kor: "손가락들이 도톰해지는 것을 보면서도    ",
              Eng: "Even as she watched its fingers growing more pudgy …",
            },
            {
              Kor: "그는 천연색 슬라이드를 보면서도   은희를 생각했을 것이다. ",
              Eng: "Even while watching the color slides, he had probably thought of Ŭnhŭi.",
            },
            {
              Kor: "아이를 낳고 살아가면서도   ",
              Eng: "Even while carrying on living after having a baby …",
            },
            {
              Kor: "고열에 시달리면서도    ",
              Eng: "Even though/While she was suffering from a fever/high temperature …",
            },
            { Kor: "[Examples?]", Eng: "" },
          ],
        },
        {
          entryName: "-면서부터 ",
          typicalExample: "【거리에 나 앉게 되면서부터   】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Ever since ... -ing; starting from the time when ...; from the moment that ...; starting when ..., ever since ...-ing.",
          sentences: [
            {
              Kor: "거리에 나 앉게 되면서부터   ",
              Eng: "from the moment one becomes homeless …",
            },
            {
              Kor: "미국군의 손에 들어가면서부터   ",
              Eng: "ever since [the islands, etc.] had fallen into the hands of the Americans ...",
            },
            {
              Kor: "시작하면서부터  ",
              Eng: "ever since he started [working as a shoe-fisherman] ...",
            },
            {
              Kor: "그녀와 함께 근무하면서부터   ",
              Eng: "from the time I started working with this woman ...",
            },
            {
              Kor: "섣달 접어들면서부터   ",
              Eng: "starting from the beginning of December [by the lunar calendar] ...",
            },
            {
              Kor: "등산로가 닦여지면서부터   ",
              Eng: "ever since the mountain hiking path was cleared out ...",
            },
          ],
        },
        {
          entryName: "-면야【그만하면야~】",
          typicalExample: "【돈만 가지면야   좀 좋은 세상인가!】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Conditional -(으)면 reinforced with particle 이야 (abbreviated here to just 야, the shape it normally takes after vowels)  only if it be: marks a reinforced contingency or prerequisite for a main clause, one of the other of which is unlikely, unexpected, or anticipated to be difficult or unpleasant. Hence, only if ...",
          sentences: [
            {
              Kor: "돈만 가지면야   좀 좋은 세상인가!",
              Eng: "Does one have to have only money [for life to be good]?",
            },
          ],
        },
        {
          entryName: "-면 좋겠다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "One wishes that …, one hopes that ... Expresses a wish or a polite request.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-면 한다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "I wish that ...; I hope that ... Expresses a wish or a polite request.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "몇 번이고 ",
          typicalExample:
            "【발가락이 몇 번이고   불어 터지도록 걸어서 내려왔고】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Time and time again. As a generalizer, each and every.",
          sentences: [
            {
              Kor: "발가락이 몇 번이고   불어 터지도록 걸어서 내려왔고",
              Eng: "I walked down the road, each and every toe swollen and bleeding many times over.",
            },
            {
              Kor: "눈물을 몇 번이고   손등으로 닦았다.",
              Eng: "Time and time again, I wiped away my tears with the back of my hand.",
            },
          ],
        },
        {
          entryName: "몰라도 ",
          typicalExample:
            '【"외국 가서 살면 외롭지 않을까, 몇 년 갔다오는 것은 몰라도  "  】',
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN은 몰라도 lit.: even though I'm not sure about NOUN, hence, though I might concede the possibility of NOUN.",
          sentences: [
            {
              Kor: '"외국 가서 살면 외롭지 않을까, 몇 년 갔다오는 것은 몰라도  "  ',
              Eng: '"Wouldn\'t it be lonely to go and live abroad? Though going for a few years might be OK."',
            },
          ],
        },
        {
          entryName: "무렵",
          typicalExample: "【퇴근 무렵  이 되자】",
          categoryKorean: "Noun",
          categoryEnglish: "(Noun)",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "퇴근 무렵  이 되자",
              Eng: "When it was about quitting time ...",
            },
          ],
        },
        {
          entryName: "무릅쓰고",
          typicalExample: "【피곤을 무릅쓰고   목욕을 또 하고 또 했다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN(을) 무릅쓰고: risking NOUN, defying NOUN, in the face of NOUN ",
          sentences: [
            {
              Kor: "피곤을 무릅쓰고   목욕을 또 하고 또 했다",
              Eng: "Despite my exhaustion, I had one bath after another.",
            },
          ],
        },
        {
          entryName: "무스게",
          typicalExample: "【무스게요?  】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation: "Northeastern dialect for 무엇 what? ",
          sentences: [
            { Kor: "무스게요?  ", Eng: "What? or, What is it?" },
            {
              Kor: "저것들이 송장이 앙이구 무스겐고?  ",
              Eng: "If these aren't corpses, then what are they? (앙이구 is dialect for 아니고, and 무스겐고 is dialect for 무엇인가)",
            },
          ],
        },
        {
          entryName: "무슨?",
          typicalExample: "【무슨   세월에 】",
          categoryKorean: "Alternative Use",
          categoryEnglish: "(Alternative Use)",
          translation: "Use of 무슨 for sarcastic or rhetorical questions. ",
          sentences: [
            {
              Kor: "무슨   세월에 ",
              Eng: "Idiom: Since when does one have the time for something like that? (lit.: In what time and tide [will one accomplish such a thing]?)",
            },
          ],
        },
        {
          entryName: "무어라 ",
          typicalExample: "【무어라   형용할 수가 없었다】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contracted from 뭐라[고] (lit.: saying something = 무어라고).",
          sentences: [
            {
              Kor: "무어라   형용할 수가 없었다",
              Eng: "There was no way to express [his feelings]. ",
            },
          ],
        },
        {
          entryName: "무얼",
          typicalExample: "【무얼   잡혀서 = 무엇을   잡혀서】",
          categoryKorean: "Colloquial/Substandard/준꼴 Contraction",
          categoryEnglish: "(Colloquial/Substandard/준꼴 Contraction)",
          translation: "Colloquial contracted from 무엇을.",
          sentences: [
            {
              Kor: "무얼   잡혀서 = 무엇을   잡혀서",
              Eng: "pawned something or other (Here with 무엇 in its indefinite usage: something) ",
            },
          ],
        },
        {
          entryName: "무엇인가 ",
          typicalExample: "【무엇인가   붙어 있는 것을 발견했다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Something or other; a certain ' je ne sais quoi'. Composed of 무엇 what? + copula modifier -인 + question marker 가.",
          sentences: [
            {
              Kor: "무엇인가   붙어 있는 것을 발견했다",
              Eng: "[He] discovered something stuck to [the mirror].",
            },
            {
              Kor: "부녀 이상의 비밀스러운 무엇인가  가 있었다",
              Eng: "(But) there was a secretive something which went beyond that of father and daughter.",
            },
          ],
        },
        {
          entryName: "-문",
          typicalExample: "【계집이문   = 계집이면   】",
          categoryKorean: "Dialect morphology/Substandard ",
          categoryEnglish: "(Dialect morphology/Substandard )",
          translation: "Substandard/dialect for conditional -(으)면.",
          sentences: [
            { Kor: "계집이문   = 계집이면   ", Eng: "if it's a woman" },
            {
              Kor: "다시 앙이 오문   그망이지",
              Eng: "If I don't return, that's it. (오문 for오면, 그망이지 for 그만이지)",
            },
          ],
        },
        {
          entryName: "~ 물론",
          typicalExample: "【신문, 방송은 물론   】",
          categoryKorean: "Noun",
          categoryEnglish: "(Noun)",
          translation:
            "NOUN은 물론 ( -이다): not to mention NOUN; also NOUN, to be sure; NOUN goes without saying, of course, and ...",
          sentences: [
            {
              Kor: "신문, 방송은 물론   ",
              Eng: "not to mention the newspapers and broadcast media ",
            },
            {
              Kor: "우스갯거리나 비난거리가 되는 것은 물론   ",
              Eng: "of course, it goes without saying they became subjects of ridicule or criticism",
            },
            {
              Kor: "인객군들은 물론   ",
              Eng: "of course, the guys from the inns who rounded up travelers ",
            },
            {
              Kor: "군교육계에는 물론   ",
              Eng: "not to mention in the county education system",
            },
          ],
        },
        {
          entryName: "물론이다",
          typicalExample: "【서울의 큰 신문사는 물론이고   】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN은 물론이다: not to mention NOUN; also NOUN, to be sure",
          sentences: [
            {
              Kor: "서울의 큰 신문사는 물론이고   ",
              Eng: "Not to mention the big newspapers in Seoul, but also ...",
            },
          ],
        },
        {
          entryName: "-은 물론이려니와",
          typicalExample: "【마을 사람들에게는 물론이려니와   박훈장도】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN은/는 물론이려니와: NOUN goes/went without saying; even ...",
          sentences: [
            {
              Kor: "마을 사람들에게는 물론이려니와   박훈장도",
              Eng: "Of course the villagers, but even Schoolmaster Pak ...; or, Even Schoolmaster Pak, not to mention the villagers ...",
            },
          ],
        },
        {
          entryName: "뭐라구 ",
          typicalExample: "【제 따위들이 뭐라구   】",
          categoryKorean: "Dialect morphology/Substandard ",
          categoryEnglish: "(Dialect morphology/Substandard )",
          translation:
            "Dialect/substandard for 뭐라고 ← 무엇이라고 lit.: saying that it is what?",
          sentences: [
            {
              Kor: "제 따위들이 뭐라구   ",
              Eng: "Who do you guys think you are? ",
            },
          ],
        },
        {
          entryName: "뭐랄 ",
          typicalExample: "【내가 뭐랄  새도 없이】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "Contracted from 뭐라[고 하]ㄹ ← 무엇이라[고 하]ㄹ: saying [QUOTE]; saying anything; saying what?",
          sentences: [
            {
              Kor: "내가 뭐랄  새도 없이",
              Eng: "I didn't have even a split second to say anything ...",
            },
          ],
        },
        {
          entryName: "뭘",
          typicalExample:
            "【업은 가져 뭘   하나 = 업은 가져서 무엇을   하나?】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation: "Contracted from 무엇을.",
          sentences: [
            {
              Kor: "업은 가져 뭘   하나 = 업은 가져서 무엇을   하나?",
              Eng: "lit.: As for a job/trade, what is it good for? (Having got one, what are you going to do with it?)",
            },
          ],
        },
        {
          entryName: "뭬",
          typicalExample: "【[Examples?]】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation: "[Usage]",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-므로(서)",
          typicalExample:
            "【아빠 없는 어린애는 생겨날 수 없으므로 그 아이들은 물론 아빠가 있다. 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Although the Korean spelling somewhat obscures the origin, this is a substantive in -(으)ㅁ + instrumental (으)로(서), and means with (as, etc.) the doing; or, being since (because of) the doing/being",
          sentences: [
            {
              Kor: "아빠 없는 어린애는 생겨날 수 없으므로 그 아이들은 물론 아빠가 있다. ",
              Eng: "Insofar as it is impossible for kids to come into this world without a father, the children, of course, have a dad.",
            },
            {
              Kor: "중국과의 관계가 미묘해지므로 ",
              Eng: "Since relations with China were getting more intimate [delicate] …",
            },
            {
              Kor: "눈에 덜 뜨일 것이므로",
              Eng: "As he was not recognized ...",
            },
            {
              Kor: "종일 음식타령이므로 ",
              Eng: "Because people go on and on about food the whole day ...",
            },
            {
              Kor: "퇴근길의 손님이 밀리는 때이므로 ",
              Eng: "Because it was the time when customers came crowding in on their way home from work …",
            },
            {
              Kor: "산 모퉁이를 돌므로 ",
              Eng: "Going around the mountain bend ...",
            },
            {
              Kor: "딱 마주 서서 여자를 보고 있으므로 ",
              Eng: "Because/As he was standing right across from here and looking at her …",
            },
            {
              Kor: "여자가 다른 손님을 대하고 있으므로 ",
              Eng: "Since the lady was waiting on another customer …",
            },
            { Kor: "날씨가 더우므로", Eng: "Because the weather was hot …" },
            {
              Kor: "그의 주소도 이름도 모르므로 ",
              Eng: "Since (she) didn't know his name or address …",
            },
            {
              Kor: "그들을 극히 미워하므로 ",
              Eng: "Since she hated them so much ...",
            },
            {
              Kor: "밥도 안 먹고 앓으므로 ",
              Eng: "Because she was sick and wasn't even eating ...",
            },
            {
              Kor: "나는 몸에서 힘을 빼고 있었으므로 ",
              Eng: "Because I had drained the strength from my body ...",
            },
            {
              Kor: "얼굴은 냇물을 향하고 있었으므로 ",
              Eng: "Because of the fact that the corpse's face was in the direction of the stream …",
            },
            {
              Kor: "비밀리에 매수되었으므로 ",
              Eng: "Since they had been bought up in secret …",
            },
            {
              Kor: "한평생 산 속에서만 살았으모로 ",
              Eng: "Because he lived in the mountains his whole life ...",
            },
            {
              Kor: "양씨는 술을 끊고 있다 했으므로 ",
              Eng: "Because Mr. Yang said that (Mr. Ha) was getting off the wagon ...",
            },
            {
              Kor: "손톱을 갓 깎은 후였으므로 ",
              Eng: "Because he had just clipped his fingernails ...",
            },
            { Kor: "시계였으므로 ", Eng: "Because it was a clock ..." },
            {
              Kor: "매우 좋아했던 것이었으므로 ",
              Eng: "Because it was something she had liked very much ...",
            },
            { Kor: "배가 고팠으므로 ", Eng: "Because [he was] hungry ..." },
            {
              Kor: "뿌리가 깊었으므로 ",
              Eng: "Because its roots were deep ...; Because it was deeply seated/rooted ...",
            },
            {
              Kor: "감촉이 없었으므로 ",
              Eng: "[Because his leg] was numb ...",
            },
            {
              Kor: "수려하기 짝이 없었으므로 ",
              Eng: "Because there was none second to its beauty ...",
            },
            {
              Kor: "소리가 빠져 나갈 구멍이 없었으므로 ",
              Eng: "Because there was no hole through which his voice could escape ...",
            },
            {
              Kor: "그는 좀 무안해졌으므로 ",
              Eng: "Since he felt a little embarrassed ...",
            },
            {
              Kor: "싫증이 났으므로 ",
              Eng: "Since she had become bored with it ...",
            },
          ],
        },
        {
          entryName: "-므로(써)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "가표제어",
          categoryEnglish: "(가표제어)",
          translation:
            "Although the Korean spelling somewhat obscures the origin, this is a substantive in -(으)ㅁ + instrumental (으)로(써), and means with (as, etc.) the doing; or, being since (because of) the doing/being",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-믄",
          typicalExample: "【멀믄】",
          categoryKorean: "Dialect morphology/Substandard ",
          categoryEnglish: "(Dialect morphology/Substandard )",
          translation: "Dialect/substandard for conditional -(으)면.",
          sentences: [
            { Kor: "멀믄", Eng: "if it be far" },
            {
              Kor: "이 아즈마니 만나믄 ",
              Eng: "whenever I run into this lady",
            },
          ],
        },
        {
          entryName: "-믄서",
          typicalExample: "【돈벌이만 잘 허믄서 】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation: "Dialect for -(으)면서. ",
          sentences: [
            { Kor: "돈벌이만 잘 허믄서 ", Eng: "While making a good living …" },
          ],
        },
        {
          entryName: "미처",
          typicalExample: "【미처 피난을 못 가서】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 미처 flags in advance that a pattern denoting either inability (-(으)ㄹ 수 없다; 못) or before even ...-ing (-기도 전에) is coming up.",
          sentences: [
            {
              Kor: "미처 피난을 못 가서",
              Eng: "[Her husband, who] was unable to take refuge ...",
            },
            {
              Kor: "미처 먹이를 댈 수 없으면 ",
              Eng: "[Sparrows] when they are unable to feed [their young] ...",
            },
            {
              Kor: "미처 무슨 말을 찾기도 전에 ",
              Eng: "Before [he] could even find a word [to say] ...",
            },
            {
              Kor: "미처 피할 겨를도 없이",
              Eng: "without having ['even'] a moment to get out of the way",
            },
            {
              Kor: "움푹한 배꼽 속에 미처 빠져나오지 않은 무언가가  ",
              Eng: "Something/Some je ne sai quois, inside her caved in navel that had not yet made it out …",
            },
          ],
        },
        {
          entryName: "-웁게도(ㅂ~w verbs)",
          typicalExample: "【놀라웁게도】",
          categoryKorean:
            "Word-formation Element/Dialect morphology/Substandard ",
          categoryEnglish:
            "(Word-formation Element/Dialect morphology/Substandard )",
          translation:
            "Dialect/substandard treatment of ㅂ~w verbs. On the basis of modifier forms like 놀라 운 from 놀랍다, some Korean speakers reanalyze ㅂ~w verbs as having an extra 우 in them, but still keep the ㅂ before consonants. This is not standard usage.",
          sentences: [{ Kor: "놀라웁게도", Eng: "to his astonishment ..." }],
        },
        {
          entryName: "-ㅂ네 【학생입네 하면서~】",
          typicalExample: "【금년엔 흉년입네 】",
          categoryKorean: "Dialect morphology/종결 어미 Final ending ",
          categoryEnglish: "(Dialect morphology/종결 어미 Final ending )",
          translation:
            "Dialect ending in -ㅂ네 (related historically to the -ㅂ니- of -ㅂ니다).",
          sentences: [
            {
              Kor: "금년엔 흉년입네 ",
              Eng: "I guess this year is a poor harvest!",
            },
          ],
        },
        {
          entryName: "-ㅂ네까",
          typicalExample: "【왜 그릅네까? 】",
          categoryKorean: "Dialect morphology/종결 어미 Final ending ",
          categoryEnglish: "(Dialect morphology/종결 어미 Final ending )",
          translation:
            "Dialect morphology. P'yŏngan dialect has -ㅂ네까 corresponding to standard -ㅂ니까.",
          sentences: [{ Kor: "왜 그릅네까? ", Eng: "Why? (How so?)" }],
        },
        {
          entryName: "-ㅂ네다",
          typicalExample: "【살릴 길이 없읍네다 】",
          categoryKorean: "Dialect morphology/종결 어미 Final ending ",
          categoryEnglish: "(Dialect morphology/종결 어미 Final ending )",
          translation: "Dialect for -(으)ㅂ니다.",
          sentences: [
            { Kor: "살릴 길이 없읍네다 ", Eng: "There is no way to live." },
          ],
        },
        {
          entryName: "-ㅂ네 맙네 한다",
          typicalExample: "【이혼을 합네 마네 투닥거리기도 했다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Blah-blah-blah'합네 마네 한다: grouses/blabs, saying 'I'm going to do this' and 'I'm going to do that' (implying a kind of mindless and endless nagging or harping). ",
          sentences: [
            {
              Kor: "이혼을 합네 마네 투닥거리기도 했다. ",
              Eng: "She even groused about divorcing me.",
            },
          ],
        },
        {
          entryName: "-ㅂ디까",
          typicalExample: "【반가와하는 사람이 있읍디까 】",
          categoryKorean:
            "하오체의 종결 어미 Final ending in Semiformal Style  ",
          categoryEnglish:
            "(하오체의 종결 어미 Final ending in Semiformal Style  )",
          translation:
            "Formal retrospective question in -(으)ㅂ디까? (spelling variant/alternant to –습디까 after ㅆ and ㅄ), meaning Has it been observed that ...? Is it known that ...? Did you hear (have you been told) that ...? Did you notice that ...? Have you found that ...?",
          sentences: [
            {
              Kor: "반가와하는 사람이 있읍디까 ",
              Eng: "Was there anybody there to greet you? ",
            },
            {
              Kor: "그래서 맛이 어떻습디까? ",
              Eng: "So what did it taste like?",
            },
          ],
        },
        {
          entryName: "-ㅂ디다 ",
          typicalExample: "【활개 펴구 삽디다 】",
          categoryKorean:
            "하오체의 종결 어미 Final ending in Semiformal Style  ",
          categoryEnglish:
            "(하오체의 종결 어미 Final ending in Semiformal Style  )",
          translation:
            "Formal retrospective assertive -ㅂ디다/-습디다 it has been observed that...; it is known that...; as we all (or I) know...; I hear (have been told) that...; I noticed that...; I've discovered (found out) that ...",
          sentences: [
            {
              Kor: "활개 펴구 삽디다 ",
              Eng: "lit.: live with limbs spread out (relaxed, at ease)",
            },
            { Kor: "드물게 날씬합디다 ", Eng: "[She] was uncommonly slender." },
          ],
        },
        {
          entryName: "-ㅂ쇼",
          typicalExample: "【안녕헙쇼】",
          categoryKorean: "하십시오체의 종결어미 Final ending in Formal Style",
          categoryEnglish:
            "(하십시오체의 종결어미 Final ending in Formal Style)",
          translation: "Contracted from -(으)ㅂ시오 (now an archaic form)",
          sentences: [
            { Kor: "안녕헙쇼", Eng: "Hi there!" },
            {
              Kor: "많이 좀 가르켜 줍쇼, 나리 ",
              Eng: "Well, please teach me many things, sir. (Note 나리 for 나으리)",
            },
          ],
        },
        {
          entryName: "바",
          typicalExample: "【온천물의 효험 따위를 믿어온 바도 없거늘】",
          categoryKorean: "Postmodifier",
          categoryEnglish: "(Postmodifier)",
          translation:
            "Postmodifier 바 the case that; a way, means; a thing, that (which). In literary prose, postmodifier 바 often functions as a fancy equivalent to 것 in the colloquial language. ",
          sentences: [
            {
              Kor: "온천물의 효험 따위를 믿어온 바도 없거늘",
              Eng: "As it wasn't the case that I had some belief in the efficacy of spring water ...",
            },
            {
              Kor: "그토록 두려워한 바로 ",
              Eng: "as it was frightening to that extent ...",
            },
            { Kor: "제 손금이 아닌 바에야 ", Eng: "it's not [one's own] lot " },
            {
              Kor: "내가 하고 있는 바와 같은 가정과 질문을 어렴풋이나마 하고 있었고",
              Eng: "The questions and assumptions like the ones I am making, and ...",
            },
            {
              Kor: "몽룡의 처지를 짐작 못 하는 바는 아니지만 잘 왔소 한마디 써 보내지 않는 심사는 헤아릴 길이 없었다.",
              Eng: "It wasn't that she couldn't guess as to Mongnyong's situation, but she simply could not understand the reason behind why he would not send her letter asking a simple \"How are you?\" ",
            },
            { Kor: "[Examples?]", Eng: "" },
          ],
        },
        {
          entryName: "바람에",
          typicalExample: "【그 바람에 】",
          categoryKorean: "Postmodifier",
          categoryEnglish: "(Postmodifier)",
          translation:
            "NOUN 바람에: (in) conjunction (with); (in) the process (of); (as a) consequence (of); (as a) result (of); impetus, momentum, influence.",
          sentences: [
            { Kor: "그 바람에 ", Eng: "on account of that; as a result" },
          ],
        },
        {
          entryName: "바람으로",
          typicalExample: "【조는 런닝셔츠 바람으로 】",
          categoryKorean: "Postmodifier",
          categoryEnglish: "(Postmodifier)",
          translation: "NOUN 바람으로: in NOUN, wearing (only) NOUN ",
          sentences: [
            {
              Kor: "조는 런닝셔츠 바람으로 ",
              Eng: "Cho, wearing only his undershirt ...",
            },
          ],
        },
        {
          entryName: "반대로",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Postmodifier",
          categoryEnglish: "(Postmodifier)",
          translation: "NOUN와/과 반대로: opposite to/in contrast to NOUN ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "보다1",
          typicalExample: "【무름에 보다 강도 높은 악의】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "Particle 보다: than (used after the second member of a comparison, usually accompanied by 더); rather than ",
          sentences: [
            {
              Kor: "무름에 보다 강도 높은 악의",
              Eng: "an ill will much more intense than [the innocence of] his question",
            },
          ],
        },
        {
          entryName: "보다2",
          typicalExample: "【보다 소설가로서의 호기심이 컸다】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "Forward-looking 보다. In this usage, it can be taken as short for 그것보다 more than that ...",
          sentences: [
            {
              Kor: "보다 소설가로서의 호기심이 컸다",
              Eng: "It was more because as a writer, I was blessed with a healthy curiosity.",
            },
            {
              Kor: "비극의 보다 진실한 모습",
              Eng: "He saw past the tragedy and then saw her honesty ...",
            },
            {
              Kor: "보다 대중적으로는",
              Eng: "Rather, speaking in terms of the general public ...",
            },
          ],
        },
        {
          entryName: "보다도",
          typicalExample: "【지수의 손가락 길이보다도 짧은 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation: "Even (more so) than; than also/ either.",
          sentences: [
            {
              Kor: "지수의 손가락 길이보다도 짧은 ",
              Eng: "… [that was] shorter even than the length of Chisu‘s fingers",
            },
            {
              Kor: "어떠한 약보다도 ",
              Eng: "even more than any of the medicines [in all the showcases of all the pharmacies]",
            },
            {
              Kor: "지난 3월에 뵈었을 때보다도 ",
              Eng: "even more than when I saw him last March",
            },
            {
              Kor: "마을의 인총보다도 많고 ",
              Eng: "even more than the entire population",
            },
            {
              Kor: "사람의 성보다도 종자가 흔하다",
              Eng: "[The trees'] seeds are more abundant than people's last names.",
            },
            {
              Kor: "짚북더기보다도 부드러운 나뭇잎 ",
              Eng: "leaves even softer than refuse",
            },
            {
              Kor: "꽃보다도 비단결보다도 무지개보다도 맨드라미보다도 곱고 장하다",
              Eng: "It was more beautiful and magnificent than even the flowers, the feel of silk, the rainbow or the coxcomb.",
            },
          ],
        },
        {
          entryName: "보다도 못하다",
          typicalExample: "【종보다도 못한 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN보다도 못하-: not even up to NOUN, not even close to NOUN.",
          sentences: [
            {
              Kor: "종보다도 못한 ",
              Eng: "lit.: not even close to [the status of] a slave",
            },
          ],
        },
        {
          entryName: "부터",
          typicalExample: "【이런 주접스러운 생각부터 했다 】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "Particle 부터: (order) beginning with, first, starting from ",
          sentences: [
            {
              Kor: "이런 주접스러운 생각부터 했다 ",
              Eng: "I began (first of all) to wonder selfishly. ",
            },
            {
              Kor: "나는 울컥 혐오감부터 느꼈다 ",
              Eng: "The first thing I felt was a sense of loathing welling up inside me.",
            },
            {
              Kor: "돈 계산부터 츱츱하게 하면서 ",
              Eng: "The first thing I did was to calculate, shamelessly, the money she would make ...",
            },
            {
              Kor: "우선 궁금한 것부터 물었다 ",
              Eng: "I first began with asking about the things I was curious about.",
            },
            {
              Kor: "대꾸부터 엉뚱했다 ",
              Eng: "[For starters] even his answer was preposterous.",
            },
            {
              Kor: "소년은 가슴부터 두근거렸다 ",
              Eng: "The boy's heart immediately started pounding (or, The first thing that happened was that his heart started pounding.)",
            },
            {
              Kor: "예산에부터 들지 못하는 모양이었다 ",
              Eng: "For starters, it seemed as though [the money] couldn't even make it into the budget [let alone finding its way into his hands].",
            },
            {
              Kor: "박희완 영감부터 ",
              Eng: "[realized] that, starting with Pak Hŭiwan, they had been duped; or, [realized] that everyone, starting with Pak Hŭiwan, had been cheated",
            },
            {
              Kor: "빌기부터 먼저 했다 ",
              Eng: "He would start with pleading; or, The first thing he would do was plead.",
            },
            {
              Kor: "학의 동정부터 보았다 ",
              Eng: "[The villagers] first looked at the state (condition) of the cranes.",
            },
            {
              Kor: "바우의 얼굴부터 살폈다 ",
              Eng: "First, [the villagers] looked at Pau's face.",
            },
          ],
        },
        {
          entryName: "불과하다 ",
          typicalExample:
            "【그러나 이제 시작에 불과하다는 것을 윤호는 알았다.  】",
          categoryKorean: "Adjective",
          categoryEnglish: "(Adjective)",
          translation: "NOUN에 불과하다: is nothing more than a NOUN",
          sentences: [
            {
              Kor: "그러나 이제 시작에 불과하다는 것을 윤호는 알았다.  ",
              Eng: "But Yunho knew that this was merely the beginning.",
            },
          ],
        },
        {
          entryName: "비로소 ",
          typicalExample: "【덕이는 비로소 눈을 들었다 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Do for the first time (after something has happened); not ... until ...; only once [something happens] does one finally ...",
          sentences: [
            {
              Kor: "덕이는 비로소 눈을 들었다 ",
              Eng: "For the first time, Togi raised his eyes.",
            },
            {
              Kor: "비로소 학이 새끼를 물어 내버린 뜻을 알 것 같았다 ",
              Eng: "For the first time it seemed that they could understand the reason the cranes had tossed the chick out.  ",
            },
            {
              Kor: "몇백 리나 밖으로 나가야만 비로소 수평선이 보이는 진짜 바다다운 바다가 나오는 곳이니까요 ",
              Eng: "Only if you go as many as several hundred li [will the horizon finally be seen].",
            },
            {
              Kor: "영을 내려와 비로소 학이 돌아온 것을 본 이장영감과 박 훈장은",
              Eng: "The old village headman and the village schoolmaster who were not able to see that the crane had returned, not until they had come down from the ridge …",
            },
            {
              Kor: "수준에 올라야 비로소 ",
              Eng: 'Not till you rise to this level do you ... (The 비로소 strengthens/reinforces the contingency of "only if," i.e., then finally, at last.)',
            },
          ],
        },
        {
          entryName: "비록",
          typicalExample: "【비록 두 번 밖에 만나지 않았지만  】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 비록 means although, even though, despite the fact that, etc., and flags an upcoming concessive in -지만, -어도, etc. ",
          sentences: [
            {
              Kor: "비록 두 번 밖에 만나지 않았지만  ",
              Eng: "I had only met him twice, but . . .",
            },
          ],
        },
        {
          entryName: "비록 ...-(으)ㄴ다 해도",
          typicalExample:
            "【비록 모양틀 안에서 똑같은 얼음으로 얼려진다 해도 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "비록 ...-(으)ㄴ다 해도 ← -(으)ㄴ다[고] 해도: even supposing one says that ..., hence even granted that ...; even supposing .... ",
          sentences: [
            {
              Kor: "비록 모양틀 안에서 똑같은 얼음으로 얼려진다 해도 ",
              Eng: "Even if one concedes that [life] gets frozen into one set frame like so many ice-cubes … ",
            },
          ],
        },
        {
          entryName: "빌어먹을",
          typicalExample: "【이별이라니, 빌어먹을 】",
          categoryKorean: "욕 Epithet",
          categoryEnglish: "(욕 Epithet)",
          translation: "욕-type epithet, the wretched ...",
          sentences: [
            {
              Kor: "이별이라니, 빌어먹을 ",
              Eng: "Farewell?/Go our separate ways?—that little bitch.",
            },
          ],
        },
        {
          entryName: "빌어서",
          typicalExample: "【나는 취기를 빌어 】",
          categoryKorean: " ",
          categoryEnglish: "( )",
          translation:
            "NOUN(을) 빌어서: invoking NOUN, relying on, appealing to NOUN ",
          sentences: [
            {
              Kor: "나는 취기를 빌어 ",
              Eng: "appealing to my inebriation ...",
            },
          ],
        },
        {
          entryName: "빼고(는)",
          typicalExample: "【눈물을 조금 흘린것을 빼고는 】",
          categoryKorean: " ",
          categoryEnglish: "( )",
          translation:
            "NOUN 빼고(는): aside from NOUN, besides NOUN, with the exception of NOUN ",
          sentences: [
            {
              Kor: "눈물을 조금 흘린것을 빼고는 ",
              Eng: "aside from the few tears falling ",
            },
            {
              Kor: "간암 환자 빼고 그런 놈 있으면 나와 보라고 그래.  ",
              Eng: "Liver cancer patients aside, if there's any asshole that fits that description, let him step forward.",
            },
          ],
        },
        {
          entryName: "빼놓고(는)",
          typicalExample: "【강마담이 설칠때를 빼놓고는 】",
          categoryKorean: " ",
          categoryEnglish: "( )",
          translation:
            "NOUN(을) 빼놓고는: except for NOUN, other than NOUN, with exception of NOUN",
          sentences: [
            {
              Kor: "강마담이 설칠때를 빼놓고는 ",
              Eng: "Except for the times when Madam Kang had her way/was running amok ...",
            },
          ],
        },
        {
          entryName: "뿐만 아니라 ",
          typicalExample: "【그뿐만 아니라 그 늙은 부인네는】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN 뿐만 아니라: not only NOUN...but (also); moreover; furthermore; as well as; besides, in addition, on top of that",
          sentences: [
            {
              Kor: "그뿐만 아니라 그 늙은 부인네는",
              Eng: "Not only that, but that old lady ...",
            },
            {
              Kor: "미신뿐만 아니라 ",
              Eng: "not only a superstition, but ...",
            },
            {
              Kor: "환상 때문에 잠 못 이루는 것이 자신 뿐만은 아님을 알았다.  ",
              Eng: "She realized that she was not the only one could not get to sleep because of  hallucinations.",
            },
          ],
        },
        {
          entryName: "뿐 아니라 ",
          typicalExample: "【감옥뿐 아니라 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN 뿐 아니라: not only NOUN...but (also); moreover; furthermore; as well as; besides, in addition, on top of that",
          sentences: [
            {
              Kor: "감옥뿐 아니라 ",
              Eng: "not only the prison [but also our life]",
            },
            {
              Kor: "내게뿐 아니라 아이들에게도",
              Eng: "[such difficulties exist] not only for me but also for the children",
            },
          ],
        },
        {
          entryName: "뿐이다",
          typicalExample:
            "【오로지 졸리다는 생각 뿐 밥 생각 같은 건 전연 없었으나 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN + postmodifier 뿐 (plus copula): nothing but, only, constantly; all it/one does/is is ...",
          sentences: [
            {
              Kor: "오로지 졸리다는 생각 뿐 밥 생각 같은 건 전연 없었으나 ",
              Eng: "The only thought I had was that I was sleepy, and the thoughts of getting a meal was the least important thing in my mind ...",
            },
            {
              Kor: "도와 드려야겠다는 생각뿐이에요 ",
              Eng: "The only thought I have is that I should help her.",
            },
            {
              Kor: "그뿐인가",
              Eng: "And is that all? (i.e., rhetorical: And that's not all!)",
            },
            {
              Kor: "개구리 울음소리뿐이거든요 ",
              Eng: "It’s nothing but the cries of the frogs, you see?",
            },
            {
              Kor: "오전 수업뿐이예요 ",
              Eng: "I have nothing except for my morning classes.",
            },
            {
              Kor: "책임, 책임뿐입니다 ",
              Eng: "Responsibility—responsibility is all it is.",
            },
            {
              Kor: "흙질을 한 벽뿐이다 ",
              Eng: "Just bare walls with nary a piece of newspaper plastered to them .",
            },
            {
              Kor: "커피는 안 되고 식사와 음료뿐입니다 ",
              Eng: "We don't serve coffee, just food and drinks. ",
            },
            {
              Kor: "얻는 것은 빈 주먹뿐이었다",
              Eng: "All they got was an empty fist.",
            },
            {
              Kor: "내 심장에 남을 수 있는 것은 그것뿐이었다 ",
              Eng: "That was the only thing left in my heart.",
            },
            {
              Kor: "없어졌으면 좋겠다는 마음뿐이었던 것이다 ",
              Eng: "I only felt like I wished he would disappear.",
            },
            {
              Kor: "행정전화뿐이었다",
              Eng: "It was only the administrative telephone.",
            },
            {
              Kor: "오전 근무뿐인 토요일이었고",
              Eng: "It was just a half-day Saturday at work and ...",
            },
            { Kor: "그와 나뿐이었다 ", Eng: "It was only him and me." },
            { Kor: "그 길 뿐이었다 ", Eng: "It was the only way. " },
            { Kor: "변학도뿐이었다", Eng: "It was only Pyŏn Hakto." },
            { Kor: "이날뿐이었다", Eng: "This was the only day. " },
            {
              Kor: "따로 있다는 것뿐이겠지 ",
              Eng: "It’s only that there is a different place [for classical and pop songs to be sung].",
            },
            {
              Kor: "또 가끔씩 매운탕집에서 화투도 치고 그러는 것뿐인데.  ",
              Eng: "And besides, all I do is sometimes play hwat'u at the maeunt'ang place and stuff.",
            },
            {
              Kor: "아버지가 한 말은 그것뿐이었다.  ",
              Eng: "That was all her father had said. ",
            },
            {
              Kor: "늘 울고 싶은 마음뿐이었다.  ",
              Eng: "He had only ever felt like crying; or, All he had ever felt like was crying.",
            },
            {
              Kor: "바다를 거니는 사람들뿐이었는데. ",
              Eng: "They were just people strolling along the sea.",
            },
            {
              Kor: "그 여자를 볼 수 있는 곳은 그 두 곳뿐이었다. ",
              Eng: "The places where she could see the woman were just those two.",
            },
            {
              Kor: "모두 쇠로 된 것들뿐이었다. ",
              Eng: "They [his tools] were all made of metal.",
            },
          ],
        },
        {
          entryName: "삼아(서)",
          typicalExample: "【노마 순사를 하인삼아 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN(을) 삼아(서): by way of NOUN, for NOUN, for the sake of NOUN, as NOUN. From 삼- makes (something) of (a person or thing), makes one/it into (being); treat NOUN1 as NOUN2.",
          sentences: [
            {
              Kor: "노마 순사를 하인삼아 ",
              Eng: "treating Noma as his servant ...",
            },
            {
              Kor: "시험 삼아서 맛 좀 본 거예요",
              Eng: "I tried it out to see what it tasted like [lit.: As a test, I tried it out to see what it tasted like].",
            },
            { Kor: "연습삼아 ", Eng: "by way of practice; for practice" },
            { Kor: "운동삼아", Eng: "by way of exercise; for exercise" },
            {
              Kor: "장난삼아",
              Eng: "I took it as a joke …; or, I took it lightly …",
            },
            {
              Kor: "산책 삼아 밭에 나섰는데 ",
              Eng: "… came out to the fields in order to take a walk ...",
            },
            {
              Kor: "두 팔을 베개삼아 ",
              Eng: "using his two arms as a pillow ...",
            },
            {
              Kor: "영달이는 변명삼아 혼잣말 비숫이 중얼거렸다 ",
              Eng: "Yŏngdal mumbled to himself by way of making an excuse.",
            },
            {
              Kor: "응석을 삼아서도 ",
              Eng: "[even] for the sake of playing on his affections ...",
            },
          ],
        },
        {
          entryName: "새-",
          typicalExample: "【새빨개져서】",
          categoryKorean: "Prefix",
          categoryEnglish: "(Prefix)",
          translation:
            "Verbal prefix 새-, the LIGHT ISOTOPE (i.e., vowel harmonic alter ego) of 시- vivid, deep, intense (used to make intensified versions of color adjectives). E.g. 새까맣다 is jet-black, 새하얗다 is snow-white, 새말갛다 is limpid. In colloquial, lively speech, one also hears 샛-. Note also that further nuances of intensity and color are created by exploiting the three-way contrast in the consonants ㄱ-ㅋ-ㄲ, ㅂ-ㅍ-ㅃ, ㄷ-ㅌ-ㄸ, and ㅈ-ㅊ-ㅉ: 새까맣다 - 새카맣다, etc.",
          sentences: [
            { Kor: "새빨개져서", Eng: "urned bright red, and then ..." },
            {
              Kor: "머리때까지 새까맣게 절어",
              Eng: "even the filth on his hair had been soiled jet-black … (Here as an adverb)",
            },
            {
              Kor: "새까만 ← 새까맣다 ",
              Eng: "jet-black (Here as a modifier)",
            },
          ],
        },
        {
          entryName: "서",
          typicalExample: "【이러한 데서 오는 반감은 】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "Abbreviation for particle 에서 in its meanings of both from and happening in a place.",
          sentences: [
            {
              Kor: "이러한 데서 오는 반감은 ",
              Eng: "An antipathy coming from such a thing ...",
            },
            { Kor: "그는 조선서 떠나자", Eng: "As soon as he left Korea ..." },
            { Kor: "찬샘 바닥서 ", Eng: "in the area of Ch'ansaem" },
            {
              Kor: "원산서 성진, 청진으로 ",
              Eng: "from Wŏnsan to Sŏngjin or Ch'ŏngjin ",
            },
            { Kor: "원산서", Eng: "from Wŏnsan" },
            { Kor: "상해서 오셨는데", Eng: "(She) came from Shanghai ..." },
            {
              Kor: "서울서 무슨 공장엘 다니다 왔노라는 바우는",
              Eng: "Pau, who claimed he had returned after working in some factory in Seoul ...",
            },
            {
              Kor: "외나무대리서 만났구려?",
              Eng: "lit.: So we meet [again] on this log bridge. [or, So we meet again]",
            },
          ],
        },
        {
          entryName: "서로를 ",
          typicalExample: "【그들이 서로를 부르고 대답한다】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation: "Each other.",
          sentences: [
            {
              Kor: "그들이 서로를 부르고 대답한다",
              Eng: "They call for and answer each other.",
            },
            { Kor: "[Examples?]", Eng: "" },
            { Kor: "[Examples?]", Eng: "" },
          ],
        },
        {
          entryName: "서부터 ",
          typicalExample: "【바람 소리가 먼 데서부터 몰아쳐서】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation: "Abbreviated from 에서부터 from (a place).",
          sentences: [
            {
              Kor: "바람 소리가 먼 데서부터 몰아쳐서",
              Eng: "The sound of the wind swept in from a faraway place and ...",
            },
          ],
        },
        {
          entryName: "서야",
          typicalExample: "【이런 데서야 반반한 애 하나면】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Particle 서 (abbreviated from 에서) + particle 이야 only if it be; if (it be), when it comes to; even, indeed. ",
          sentences: [
            {
              Kor: "이런 데서야 반반한 애 하나면",
              Eng: "When it comes to a place like this, just one attractive girl [would be enough]. ",
            },
          ],
        },
        {
          entryName: "설령",
          typicalExample: "【설령 그를 내보낸다 해도】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 설령 even though; even supposing that ... often sets up a concessive in -어도, -(으)ㄹ지라도, -ㄴ다 해도, etc: even if/ though, although, granting [admitting, supposing] that ...",
          sentences: [
            {
              Kor: "설령 그를 내보낸다 해도",
              Eng: "even if I were to send him away ...",
            },
            {
              Kor: "설령 나에 대한 나쁜 여론을 조성한다 해도",
              Eng: "even supposing it contributes to a negative public opinion of myself ...",
            },
            {
              Kor: "설령 도둑질이라고 할지라도",
              Eng: "even if it is called stealing ...",
            },
            {
              Kor: "설령 아내가 진짜로 다른 남자와 새 연애를 하고 싶어한다고 치자.  ",
              Eng: "even supposing that my wife really wants to have an affair with another man …",
            },
            {
              Kor: "설령 그녀에게 무언가 강한 의사 표현을 해야 할 때가 오더라도",
              Eng: "even if the time should come when I have to express some opinion to her forcefully … ",
            },
          ],
        },
        {
          entryName: "설마",
          typicalExample: "【설마 어떠리 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 설마 is used to set up rhetorical questions with a meaning like Surely ... [not]?  or How could it be the case that ... ? ",
          sentences: [
            {
              Kor: "설마 어떠리 ",
              Eng: "How bad could it (returning to work) be?",
            },
            {
              Kor: "토끼를 설마 숙이가 잡아먹을 성싶지는 않았다",
              Eng: "Surely, Sugi would never catch and eat that rabbit, I thought.",
            },
            {
              Kor: "설마 당신 진짜로 일찍 들어올 줄 몰랐다는 둥  ",
              Eng: "[She kept talking—things like] I didn't dream that you would really come home early tonight, etc.",
            },
            {
              Kor: "한동안 의아하게 쳐다보더니 다음 순간 '설마, 꿈이겠지' 하는 표정으로  ",
              Eng: "after staring at me with a dubious expression for a while, the next moment, with an expression as if to say, 'Must be a dream', ...",
            },
          ],
        },
        {
          entryName: "설사 ",
          typicalExample: "【설사 무슨 변이 있다 해도】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 설사 even though, no matter whether usually flags an upcoming concessive pattern like -어도, -(으)ㄹ지라도, -ㄴ다 해도, etc: even if/ though, although, granting [admitting, supposing] that ...",
          sentences: [
            {
              Kor: "설사 무슨 변이 있다 해도",
              Eng: "even though something awful should happen ...",
            },
          ],
        },
        {
          entryName: "설혹",
          typicalExample: "【설혹 마음이 없더라도】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 설혹 (optionally) sets up a following concessive ending in, e.g.,  -더라도 even though (it has been observed that...); even granted/supposing that ...",
          sentences: [
            {
              Kor: "설혹 마음이 없더라도",
              Eng: "even if she is no longer interested in me ...",
            },
          ],
        },
        {
          entryName: "섰- ",
          typicalExample: "【늙은 소나무가 섰고 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contracted from 서있-, a resultant state in -어 있다 is in the state of resulting from, is done. ",
          sentences: [
            {
              Kor: "늙은 소나무가 섰고 ",
              Eng: "[In the middle of the village] stood an old pine tree, and ...",
            },
            {
              Kor: "한 다리를 자로 구부리고 섰고",
              Eng: "[One of them] was standing with one leg bent at the knee ...",
            },
            {
              Kor: "그가 섰는 창공",
              Eng: "the blue sky under which he was standing ",
            },
            {
              Kor: "포플러들이 줄을 지어 섰는 게 보였다",
              Eng: "The line of poplar trees came in to view. ",
            },
            {
              Kor: "밖에 섰는 사람들",
              Eng: "the people who were (in a state resulting from) standing outside",
            },
            {
              Kor: "벙벙히 섰는 숙이",
              Eng: "Sugi who was standing there dumbfounded ",
            },
            { Kor: "잠자코 섰는 나무들", Eng: "the trees, standing silently " },
            {
              Kor: "떨고 섰는 것이나",
              Eng: "… was standing there trembling, but ...",
            },
            {
              Kor: "그는 멍청히 섰다가 ",
              Eng: "He was standing, oblivious [whereupon]...",
            },
            {
              Kor: "마침 장이 섰었는지 ",
              Eng: "Perhaps because, as luck would have it, a market had been set up. ",
            },
          ],
        },
        {
          entryName: "-세",
          typicalExample: "【어서 들어가세 】",
          categoryKorean: "하게체의 종결 어미 Final ending in Familiar Style  ",
          categoryEnglish:
            "(하게체의 종결 어미 Final ending in Familiar Style  )",
          translation:
            '-(으)세: The suggestion ("let\'s . . . ." ) from in the Familiar or 하네 Style. This style (indicative 하네, suggestion 하세, question 하나, command 하게, retrospective statement 하데, retrospective question 하디 and optional -(으)ㄹ세 for the copula and adjectives (-이네, 좋을세), is used only among grown-ups, e.g., by a senior addressing a grown-up junior or between grown-up social equals, and is typically used by parents-in-law addressing their sons-in-law, or by a male supervisor to a male supervisee. It is widely used among adult male friends who are not close enough to use the Intimate Style, and less widely by (or to) women. To make a Familiar Style apperceptive (equivalent to -구나, -구먼요, etc. in other styles), you add -그려 or -구려 to -네.',
          sentences: [
            { Kor: "어서 들어가세 ", Eng: "Let's go inside quickly. " },
          ],
        },
        {
          entryName: "-소【이만 가겠소.】",
          typicalExample: "【허공을 헤매는 몸이 되었소】",
          categoryKorean:
            "하오체의 종결 어미 Final ending in Semiformal Style  ",
          categoryEnglish:
            "(하오체의 종결 어미 Final ending in Semiformal Style  )",
          translation:
            "Semi-formal/Blunt/Authoritative Style final ending -소/-오 is/does. The shape –소 occurs after bases ending in consonants, and -오 after bases ending in vowels.",
          sentences: [
            {
              Kor: "허공을 헤매는 몸이 되었소",
              Eng: "I have become someone that wanders the empty skies.",
            },
            {
              Kor: "낙상하기 쉽소 ",
              Eng: "It is easy to fall and hurt oneself. ",
            },
          ],
        },
        {
          entryName: "-쇼",
          typicalExample:
            "【역으루 나가잖구 왜 일루 가쇼? ← 역으로 나가지 않고 일로 가시오? 】",
          categoryKorean: "종결 어미 Final ending/준꼴 Contraction",
          categoryEnglish: "(종결 어미 Final ending/준꼴 Contraction)",
          translation:
            "Contraction from -(으)시오, i.e., honorific -(으)시- + Semi-authoritative/Blunt Style -오. ",
          sentences: [
            {
              Kor: "역으루 나가잖구 왜 일루 가쇼? ← 역으로 나가지 않고 일로 가시오? ",
              Eng: "Why are you coming this way instead of going to the station?",
            },
          ],
        },
        {
          entryName: "-수 ",
          typicalExample: "【뱃속은 무척 편하겠수 】",
          categoryKorean:
            "Dialect morphology/Substandard/하오체의 종결 어미 Final ending in Semiformal Style   ",
          categoryEnglish:
            "(Dialect morphology/Substandard/하오체의 종결 어미 Final ending in Semiformal Style   )",
          translation:
            "Dialect or substandard for Authoritative/Semi-formal/Blunt Style -소 is/does. This particular ending is highly gendered, and tends to mark the speech of women—especially middle-aged, working class 아줌마s.",
          sentences: [
            { Kor: "뱃속은 무척 편하겠수 ", Eng: "Your mind will be at ease." },
            {
              Kor: "뼈가 부서져 죽수",
              Eng: "Its bones will break and it will die.",
            },
            { Kor: "당신 미쳤수?", Eng: "Have you gone mad?" },
            {
              Kor: "이거 어서 났수? ",
              Eng: "Where did this come from? (For 어디에서 났소?)",
            },
          ],
        },
        {
          entryName: "-쉐다",
          typicalExample: "【오늘은 많이 못 벌었쉐다 】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            "Northwest dialect morphology. The ending in -쉐다 is more or less equivalent to standard -습니다, and is cognate with the obsolete -소이다 (= -습니다).",
          sentences: [
            {
              Kor: "오늘은 많이 못 벌었쉐다 ",
              Eng: "Today I didn't make much.",
            },
          ],
        },
        {
          entryName: "-슈",
          typicalExample: "【평안하슈?】",
          categoryKorean:
            "Dialect morphology/Substandard/종결 어미 Final ending ",
          categoryEnglish:
            "(Dialect morphology/Substandard/종결 어미 Final ending )",
          translation:
            "Dialect/substandard contracted equivalent of Semi-formal Honorific Style -시오. Note that this particular ending, like just -수/-우, tends to be gendered, and often marks the speech of women—especially middle-aged, working class 아줌마s.",
          sentences: [
            { Kor: "평안하슈?", Eng: "Hello." },
            { Kor: "버리슈", Eng: "Throw it away!" },
            { Kor: "옥토끼 내놓슈 ", Eng: "Give me my rabbit!" },
            {
              Kor: "어디 일들 가슈? ",
              Eng: "Are you all heading off to work?",
            },
            { Kor: "어디까지들 가슈? ", Eng: "Where are you guys headed? " },
          ],
        },
        {
          entryName: "-스러이",
          typicalExample: "【맹수를 비밀스러이 숨기고 있는 산이라야 했다】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverbial -스러이 like, as from -스럽다, -스러워 in a ...manner. (In origin, from –스러위 with loss of -ㅜ-). Also occurs in contracted form as -스레. ",
          sentences: [
            {
              Kor: "맹수를 비밀스러이 숨기고 있는 산이라야 했다",
              Eng: "It had to be a mountain that is secretly hiding a savage beast. ",
            },
          ],
        },
        {
          entryName: "-스러하고",
          typicalExample:
            "【친근스러하고 안심한 얼굴로 대하고 하여야 할 것인데】",
          categoryKorean: "Colloquial ",
          categoryEnglish: "(Colloquial )",
          translation:
            "Colloquial shortening of  -스러워 하고, a third-person sensory verb pattern in -어하- from the pattern in -스럽다, -스러워.",
          sentences: [
            {
              Kor: "친근스러하고 안심한 얼굴로 대하고 하여야 할 것인데",
              Eng: "He should have dealt with him with intimate and reassuring face, but...",
            },
          ],
        },
        {
          entryName: "-스런 ",
          typicalExample: "【날래고 영험스런 산짐승】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "Contraction from -스러운, the modifier from of-스럽다: like, as.",
          sentences: [
            {
              Kor: "날래고 영험스런 산짐승",
              Eng: "swift, magical mountain beast ",
            },
            { Kor: "비밀스런 존재자 ", Eng: "a secretive being " },
            {
              Kor: "가살스런 첩의 행실",
              Eng: "the concubine's stuck-up behavior ",
            },
          ],
        },
        {
          entryName: "-스럽다 ",
          typicalExample: "【그저 그런 여자스러운 감상을 담고 있을 뿐】",
          categoryKorean: "Word-formation element",
          categoryEnglish: "(Word-formation element)",
          translation:
            "NOUN-스럽다, NOUN스러W: is, is like, gives the impression of, seems, suggests, is suggestive of ",
          sentences: [
            {
              Kor: "그저 그런 여자스러운 감상을 담고 있을 뿐",
              Eng: "[The few love letters she had written me] simply/only contained the usual emotions typical of women.",
            },
            {
              Kor: "미련스럽게 그걸 혼자 했어? 라고만.",
              Eng: 'All I had said was, "You did something stupid like that when you were alone?"',
            },
            {
              Kor: "짜증스러워서 나는 거짓말을 하곤 했다. ",
              Eng: "It was so annoying that I would always lie.",
            },
            {
              Kor: "세 번째 남자는 섹스 자체를 공포스러워했다.  ",
              Eng: "The third guy was terrified of sex, full stop.",
            },
          ],
        },
        {
          entryName: "-스레",
          typicalExample: "【파도가 모래톱을 조심스레 핥다 물러가곤 했다. 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb in -스레 (contracted from -스러이) from a descriptive verb in -스럽다, -스러워 meaning like, as; in a like manner.",
          sentences: [
            {
              Kor: "파도가 모래톱을 조심스레 핥다 물러가곤 했다. ",
              Eng: "The waves would carefully lick the tops of the sand then retreat.",
            },
            { Kor: "조심스레 열었다. ", Eng: "… opened it cautiously." },
            {
              Kor: "요란스레 앰뷸런스 소리가 울렸다.",
              Eng: "The sound of the ambulance rang out noisily.",
            },
            {
              Kor: "조심스레 중절을 권할 때면  ",
              Eng: "Whenever [they] cautiously suggested terminating [the pregnancy] …",
            },
            {
              Kor: "그러안을수록 그악스레 울어대는 아이 ",
              Eng: "a child who, the more one held it close, the more fiercely it kept crying",
            },
            {
              Kor: "죽을 줄을 몰랐다고 태연스레 말했다는 남자. ",
              Eng: "The man about whom they said he had spoken calmly that he didn’t realize it would die.",
            },
            {
              Kor: "온몸을 뻗대며 그악스레 터뜨리는 울음",
              Eng: "the crying that [the baby] would burst into ferociously, extending its entire body",
            },
            {
              Kor: "조심스레 방문을 닫고",
              Eng: "having closed the door carefully, and …",
            },
            { Kor: "능청스레 대답했다", Eng: "I answered slyly." },
            {
              Kor: "이상스레 한데 어울리면서 녹아 있었다",
              Eng: "strangely gathered together and melted into one",
            },
            { Kor: "조심스레 ", Eng: "ever so carefully" },
            {
              Kor: "갑작스레 호랑이를 찾대요",
              Eng: "All of a sudden he says that he is looking for tigers.",
            },
          ],
        },
        {
          entryName: "-습니다만",
          typicalExample: "【그것도 맞습니다만  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "The Formal Style equivalent of non-final ending -지만 is/does but ... In other words, concessive 만 can attach to certain sentence final endings with the meaning but: e.g., -오만 (Blunt or Semi-formal Style), -(으)나만, -어요만, etc.",
          sentences: [
            { Kor: "그것도 맞습니다만  ", Eng: "That is also true, but ..." },
            {
              Kor: "떠도는 목적을 들었습니다만",
              Eng: "I heard about your goal of wandering, but ...",
            },
            {
              Kor: "성함은 들었습니다만 ",
              Eng: "I heard your last name, but ...",
            },
            {
              Kor: "늘 생각은 하고 있었습니다만 ",
              Eng: "I'm always thinking about it, but ...",
            },
            {
              Kor: "알리고 싶었습니다만 ",
              Eng: "I wanted to inform you, but ...",
            },
          ],
        },
        {
          entryName: "-습니다요 ",
          typicalExample: "【영감님 길 좀 묻겠습니다요 】",
          categoryKorean:
            "하오체의 종결 어미 Final ending in Semiformal Style  ",
          categoryEnglish:
            "(하오체의 종결 어미 Final ending in Semiformal Style  )",
          translation:
            "Formal indicative assertive -습니다 + Polite 요 to soften it up.",
          sentences: [
            {
              Kor: "영감님 길 좀 묻겠습니다요 ",
              Eng: "Excuse me, sir, we'd like to ask for directions. ",
            },
          ],
        },
        {
          entryName: "-습디다",
          typicalExample: "【그 지난번에 부르던 거 참 좋습디다 】",
          categoryKorean:
            "하오체의 종결 어미 Final ending in Semiformal Style  ",
          categoryEnglish:
            "(하오체의 종결 어미 Final ending in Semiformal Style  )",
          translation:
            "Formal retrospective assertive -습디다 it has been observed that ...; it is known that ...; as we all (or I) know ...; I hear (have been told) that ...; I noticed that ...; I've discovered (found out) that ... ",
          sentences: [
            {
              Kor: "그 지난번에 부르던 거 참 좋습디다 ",
              Eng: "What you sang last time—that was really good.",
            },
          ],
        },
        {
          entryName: "시-",
          typicalExample: "【마누라가 시퍼런 지전을 내어 세는 것을 볼 때】",
          categoryKorean: "Prefix",
          categoryEnglish: "(Prefix)",
          translation:
            "Verbal prefix 시-, the HEAVY ISOTOPE (i.e., vowel harmonic alter ego) of 새- vivid, deep, intense (used to make intensified versions of color adjectives). E.g. 시꺼멓다 is jet-black, 시허옇다 is snow-white. In colloquial, lively speech, one also hears 싯-. Note also that further nuances of intensity and color are created by exploiting the three-way contrast in the consonants ㄱ-ㅋ-ㄲ, ㅂ-ㅍ-ㅃ, ㄷ-ㅌ-ㄸ, and ㅈ-ㅊ-ㅉ: 시꺼멓다 -시커멓다, etc.",
          sentences: [
            {
              Kor: "마누라가 시퍼런 지전을 내어 세는 것을 볼 때",
              Eng: "Whenver I see my wife counting the vivid blackish-blue paper money … (Here as a modifier)",
            },
            {
              Kor: "시커멓게 무르녹고 말았다. ",
              Eng: "It had over-ripened into a jet-black mush. (Here as an adverb)",
            },
            {
              Kor: "얼굴이 시뻘건 서참위도 한 마디 없을 수 없다는 듯이 나섰다.",
              Eng: "Sŏ, whose face had become vivid red, stepped up as if he had to have a say in this as well. (Here as a modifier)",
            },
          ],
        },
        {
          entryName: "-시게(요) ",
          typicalExample: "【구두 닦으시게요? 】",
          categoryKorean: "종결 어미 Final ending",
          categoryEnglish: "(종결 어미 Final ending)",
          translation:
            "-(으)시게(요): honorific adverbative used as a familiar question or suggestion.",
          sentences: [
            { Kor: "구두 닦으시게요? ", Eng: "Care for a shoe-shine?" },
            {
              Kor: "그까짓 10원 아껴 뭐 하시게요 ",
              Eng: "What would you do with the ten wŏn you're saving? ",
            },
            {
              Kor: "이걸 옮기시게요? ",
              Eng: "Would you like to have these carried somewhere?",
            },
          ],
        },
        {
          entryName: "-시잖구요",
          typicalExample: "【왜, 선생님이 가시잖구요 】",
          categoryKorean: "관용구/준꼴 Contraction",
          categoryEnglish: "(관용구/준꼴 Contraction)",
          translation:
            "-(으)시잖구요: abbreviated/reduced from -(으)시지 않고요.",
          sentences: [
            {
              Kor: "왜, 선생님이 가시잖구요 ",
              Eng: "And why aren't you going?",
            },
          ],
        },
        {
          entryName: "시키-",
          typicalExample: "【잔모래들을 하얗게 표백시키며 부서지고 】",
          categoryKorean: "Word-formation element",
          categoryEnglish: "(Word-formation element)",
          translation:
            "VERB NOUN시키- as a hypertransivitized equivalent to just VERB NOUN하-.",
          sentences: [
            {
              Kor: "잔모래들을 하얗게 표백시키며 부서지고 ",
              Eng: "crushed and bleached white the fine grains of sand",
            },
          ],
        },
        {
          entryName: "싶다",
          typicalExample: "【간직돼 있었구나 싶자】",
          categoryKorean: "Verb",
          categoryEnglish: "(Verb)",
          translation:
            "Plain Style Quote + verb 싶- it feels like, as if maybe; think to oneself that; think, reckon, feel seem, look, appear. Note that, while the clause in front of 싶- looks like and feels like a quote, you can't put a quotative -고 after it.",
          sentences: [
            {
              Kor: "간직돼 있었구나 싶자",
              Eng: "as soon as I realized that (this woman) was treasured ...",
            },
            {
              Kor: "우선 한잠 자 놓고 볼 일이다 싶게 ",
              Eng: "in such a way as if to think/it was a feeling of, 'First, let me just take a nap, and then whatever' ",
            },
            {
              Kor: "남편이 가 있는 곳을 실토하면 어쩌나 싶어서였다 ",
              Eng: "It was because she was afraid that her mother-in-law might blurt out her husband's hiding place.",
            },
            {
              Kor: "저렇게 비굴하게 구나 싶었다",
              Eng: '[I wondered to myself] "[Why] is she behaving in such a humiliating way?"',
            },
            {
              Kor: "무슨 일이 있는 게 아닌가 싶어",
              Eng: "wondering if perhaps something hadn't happened ...",
            },
            {
              Kor: "조금 성가시다 싶었으되 ",
              Eng: "I found him a bit annoying, but ... ",
            },
            {
              Kor: "어디냐 싶었던 것이다",
              Eng: 'lit.: It was a matter of thinking "Where is it?" i.e., He believed/thought that even 100 wŏn would have been the best he could hope for. ',
            },
            {
              Kor: "가을 하늘은 언제 그랬는가 싶게 ",
              Eng: 'lit.: as if/in such a way [to say] "When did it ever happen?," i.e., [there wasn\'t a cloud to be seen in the] fall sky; as if the cloudburst had never happened',
            },
            {
              Kor: "좀 괜찮다 싶은 강산 ",
              Eng: "a river or mountain that appears to be alright",
            },
            {
              Kor: "좀 길다 싶은 머리 ",
              Eng: "thought (to herself) that his hair was a bit long; or, his hair which struck her as a bit long ",
            },
            {
              Kor: "거리가 비는가 싶더니 ",
              Eng: "just when you thought the streets were becoming empty [, suddenly/unexpectedly ...]",
            },
            {
              Kor: "강도가 다닐 시간은 아니다 싶었던지",
              Eng: "perhaps thinking that this isn't the hour in which burglars lurk ...",
            },
            {
              Kor: "얼마나 남겠다고 저렇게 굽신대나 싶어 ",
              Eng: 'lit.: I wondered to myself, "How much was she making such that she\'s being so subservient?"',
            },
          ],
        },
        {
          entryName: "씨팔 ",
          typicalExample: "【씨팔】",
          categoryKorean: "욕 Epithet",
          categoryEnglish: "(욕 Epithet)",
          translation: "From 씹할, very vulgar 욕 epithet.",
          sentences: [{ Kor: "씨팔", Eng: "lit.: fucking..." }],
        },
        {
          entryName: "씩",
          typicalExample: "【아주 가끔씩 】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation: "Distribute particle 씩",
          sentences: [
            {
              Kor: "아주 가끔씩 ",
              Eng: "only every once in a while; on a very irregular basis; very sporadically",
            },
          ],
        },
        {
          entryName: "-아【대성아/유미야】 ",
          typicalExample:
            '【양 씨가 퍼쓱 웃으며 하 씨의 어깨를 쳤다.  "사람아, 용하고 코끼리는 없어." 】',
          categoryKorean: "호격 조사",
          categoryEnglish: "(호격 조사)",
          translation:
            "Vocative -아/-야 Hey! Say! Oh! The shape -아 comes after forms ending in a consonant, and -야 after forms ending in a vowel. ",
          sentences: [
            {
              Kor: '양 씨가 퍼쓱 웃으며 하 씨의 어깨를 쳤다.  "사람아, 용하고 코끼리는 없어." ',
              Eng: 'Yang snorted as he slapped Ha on the back,  "Hey, there aren\'t any dragons or elephants."',
            },
            {
              Kor: "이 되놈 죽어라, 이놈, 나 때렸니! 이놈아, 아이구 사람 죽이누나.  ",
              Eng: '"You dirty Chink! You bastard! Strike me, would you! You bastard! Ah, God, I\'m being killed."',
            },
            {
              Kor: '나에겐 그 도리질이 “몰라요 몰라요”가 아니라 “며늘아, 태식이 녀석에겐 아무 일도 없어…"  ',
              Eng: "To me the waving was not \"I don't know, I don't know\" but, rather, it seemed more like \"Daughter, there's nothing wrong with T'aeshik…\"",
            },
            {
              Kor: "내 돈 10원 내, 이 도둑놈아, 내 돈 10원 내.",
              Eng: '"Gimme my 10 won!  You thief, gimme my money!"  ',
            },
            { Kor: "이놈의 엄지손가락아, 안으로 좀 들어가아, 젠장.", Eng: "" },
            {
              Kor: '"에이구...... 후우우 상전 (손자아이의 이름) 아, 상전아, 너는 어디 갔니…" ',
              Eng: '"Oh, my...oh...Sangjŏn! Sangjŏn! Where have you gone?..."',
            },
          ],
        },
        {
          entryName: "아냐 ",
          typicalExample: '【"농담이 아냐."】',
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "아냐 condensed from negative copula 아니야 (cf. colloquial -야 condensed from copula -이야).",
          sentences: [
            {
              Kor: '"농담이 아냐."',
              Eng: '"It\'s not a joke." or, "I\'m not kidding."',
            },
          ],
        },
        {
          entryName: "-아니",
          typicalExample: "【처녀들은 불빛에 빨가니 단 얼굴을 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb in -어니 ~-아니. For 하-, one encounters both 허니 and 하니 (= 하게). Cf. 일찌거니 a bit earlier, 슬그머니 surreptitiously, 멀거니 with a far-off look, absentmindedly, etc.",
          sentences: [
            {
              Kor: "처녀들은 불빛에 빨가니 단 얼굴을 ",
              Eng: "The lasses [covered] their faces, flushed red by the fire ...",
            },
            {
              Kor: "그래서 처음으로 나아가 대문 빗장을 따고 떡하니 뒷짐을  ",
              Eng: "For the first time, I went out, unlocked the front gate, firmly clasped my hands behind my back and…",
            },
            {
              Kor: "똥파리가 어깨를 두들겨주고 휑하니 찬바람 속을 달려갔다.",
              Eng: "Shitfly slapped Kilsu on the shoulder and then ran off into the cold wind.",
            },
          ],
        },
        {
          entryName: "아니",
          typicalExample: "【젊은이들은 술을 마셔서는 아니 된다는 것 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation: "아니 is the older form of modern 안 for negation. ",
          sentences: [
            {
              Kor: "젊은이들은 술을 마셔서는 아니 된다는 것 ",
              Eng: "The fact that youngsters were not allowed to drink ... (Use of older 아니 here makes this injunction sound somewhat archaic, austere or solemn.)",
            },
          ],
        },
        {
          entryName: "아니 ...-고서(는) Or 안 VERB-고서 ",
          typicalExample: "【사형을 아니 받구서】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Unless; as long as [doesn't/not]",
          sentences: [
            {
              Kor: "사형을 아니 받구서",
              Eng: "as long as I don't get the death penalty ...",
            },
          ],
        },
        {
          entryName: "아니더라면",
          typicalExample: "【붉은 점이 아니더라면 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN(이) 아니더라면: had it not been for NOUN; were it not for NOUN",
          sentences: [
            {
              Kor: "붉은 점이 아니더라면 ",
              Eng: "if not for that red spot ...",
            },
            {
              Kor: "볼때기에 있는 붉은 점이 아니더라면, 얼굴 같은 딴사람인가 하였을 것이었다. ",
              Eng: "If it hadn't been for the red mark on their face, I would have thought that they were just someone who looked like them.  ",
            },
          ],
        },
        {
          entryName: "아니라",
          typicalExample: "【임자가 옷이 많다는 게 아니라 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "A가 아니라 B: it is not the case that A, (but rather B); not A but B ",
          sentences: [
            {
              Kor: "임자가 옷이 많다는 게 아니라 ",
              Eng: "[I'm] not saying you (of all people) have lots of clothes ...",
            },
            {
              Kor: "이장을 찾는 것이 아니라 박동무를 찾았다 ",
              Eng: "[They] were not looking for the village head but for comrade Pak.",
            },
            {
              Kor: "밥값을 떼고 달아나서가 아니라 ",
              Eng: 'it was not because he ran away without paying for his meal [but because] … (Note that "A" here is a verb form in -어서.)',
            },
            {
              Kor: "낚으나마나가 아니라 ",
              Eng: "it is not just a matter of whether or not he fished [the two pairs of shoes] ...",
            },
            {
              Kor: "“이건, 불랙 홀이 아니라 화이트 홀이군.”",
              Eng: "So—this isn't not a black hole, but a white hole! [I see]",
            },
            {
              Kor: "그 앞산이라는 것도 솟아오르는 산이 아니라 흘러내리는 자락이어서 흠 될 것이 없어 보였다",
              Eng: "It didn't seem like the hill in front would be a flaw since it wasn't a peak but rather the skirts of a mountain.  ",
            },
          ],
        },
        {
          entryName: "아니라도",
          typicalExample: "【아까 아낙이 하던 말이 아니라도 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN 아니라도: even if/though (you say) it is not/were not for... NOUN. ",
          sentences: [
            {
              Kor: "아까 아낙이 하던 말이 아니라도 ",
              Eng: "Even were it not for what his old lady had just said ...",
            },
          ],
        },
        {
          entryName: "아니요 ",
          typicalExample:
            "【사람의 일이 아니요, 짐승의 하는 것쯤으로만 알고 있었다.】",
          categoryKorean: "어미",
          categoryEnglish: "(어미)",
          translation:
            "Mid-sentence 아니요 = 아니고, analogous to mid-sentence -(이)요 = -이고, with a literary flourish and a sense of insistence and/or emphasis. ",
          sentences: [
            {
              Kor: "사람의 일이 아니요, 짐승의 하는 것쯤으로만 알고 있었다.",
              Eng: "No person could have done something like that. It could only be the work of a beast.",
            },
            {
              Kor: "자식이고 계집이고 간에 귀여우면, 흉이 흉이 아니요 ",
              Eng: "Whether it be a child or a woman, if they're cute, their flaws are not flaws at all.",
            },
            {
              Kor: "안초시는 벌써 아니요, 안초시의 시체일 뿐",
              Eng: "Mr. An was gone and only his corpse remained.",
            },
            {
              Kor: "특별한 지병(持病)이 있는 것도 아니요, 또",
              Eng: "I did not have a chronic disease, nor [did I believe in ...]",
            },
          ],
        },
        {
          entryName: "아닌가",
          typicalExample: "【소녀가 앉아 있는게 아닌가 】",
          categoryKorean: "어미",
          categoryEnglish: "(어미)",
          translation:
            "Negative rhetorical question for literary effect: is it not the case that ... ? = Wouldn't you know it—thus-and-such is the case!",
          sentences: [
            {
              Kor: "소녀가 앉아 있는게 아닌가 ",
              Eng: "Wouldn't you know it—the girl is sitting [on this side of the bank of the stream]; or, And whadda ya know—the girl is sitting ...; or, Whoa! She's sittin on this side of the stream!",
            },
          ],
        },
        {
          entryName: "아닌가(요)?",
          typicalExample: "【그나마 다행 아닌가.】",
          categoryKorean: "어미",
          categoryEnglish: "(어미)",
          translation:
            "NOUN 아닌가(요)? Is it not NOUN (I wonder?) = the negative copula 아니- plus question ending -(으)ㄴ가(요)?",
          sentences: [
            {
              Kor: "그나마 다행 아닌가.",
              Eng: "That in itself is fortunate, is it not?",
            },
            {
              Kor: "사실 영업부 일이라는 게 다 그런 거 아닌가.",
              Eng: "Actually, isn't that the way it is with all business departments?",
            },
          ],
        },
        {
          entryName: "아닌가 의아한다",
          typicalExample: "【폭발한 것이 아닌가 의아할 만큼 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN(이) 아닌가 의아하-: doubts/wonders whether it is NOUN",
          sentences: [
            {
              Kor: "폭발한 것이 아닌가 의아할 만큼 ",
              Eng: "to the extent that one wondered whether it wasn't an explosion",
            },
          ],
        },
        {
          entryName: "아무 ...-(이)나 ",
          typicalExample: "【아무 판이나 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "아무 ... NOUN이나: any NOUN whatever; any old NOUN ...",
          sentences: [
            { Kor: "아무 판이나 ", Eng: "any [old] record " },
            {
              Kor: "아무나 붙들고 이 근처에 어디 구경할 만한 명승고적이 없냐고 물었다.  ",
              Eng: "I grabbed anyone I saw and asked them if there were any scenic spots nearby that were worth seeing.",
            },
            {
              Kor: "다들 바위 위에 아무렇게나 주저앉았다.  ",
              Eng: "They sat on the rock in any which way they pleased. ",
            },
            {
              Kor: "어디 아무 집이나 찾아가서 사정을 해보죠.  ",
              Eng: "Why don't you try going to any old house and asking them for help? ",
            },
          ],
        },
        {
          entryName: "아무 ...(도) ",
          typicalExample: "【아무 생각도 안 해. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "아무 NOUN도: no NOUN at all (always followed by a negative).",
          sentences: [
            {
              Kor: "아무 생각도 안 해. ",
              Eng: "I don't think about anything.",
            },
            { Kor: "아무 걱정 마십쇼", Eng: "Don't worry about me one bit." },
            {
              Kor: "아무 것도, 그 무엇도 하려 하지 않는 둘째 아이. ",
              Eng: "The second child who would never do anything, not anything at all.",
            },
            {
              Kor: "본명은 아무에게도 가르쳐 주지 않아.",
              Eng: "I don't tell my real name to anyone.   ",
            },
            {
              Kor: "벗은 웃통에 아무것도 걸치지 않고 여자의 가게에 같은 시간대에 들렀다 ",
              Eng: "",
            },
            {
              Kor: "차장은 나를 힐끔 보고는 아무 대꾸도 하지 않았다.",
              Eng: "The driver shot a quick glance at me but didn’t reply.",
            },
            {
              Kor: "이젠 부기도 많이 빠지고 정말 교장선생님 말씀처럼 아무 일도 없게 되겠는데요.....   ",
              Eng: "The swelling had subsided and maybe nothing would happen, just as the principal had said… ",
            },
            { Kor: "왕서방은 아무 말도 못하였다. ", Eng: "" },
            {
              Kor: "며늘아, 태식이 녀석에겐 아무 일도 없어, 글쎄 아무 일도 없다니까. ",
              Eng: "",
            },
          ],
        },
        {
          entryName: "아무런 NOUN + neg",
          typicalExample: "【아무런 문제도 없었던 아이】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "아무런 NOUN + Negative: doesn't/didn't ... any NOUN. Sometimes (as here) the NOUN can be reinforced with particle 도, but this is not obligatory. ",
          sentences: [
            {
              Kor: "아무런 문제도 없었던 아이",
              Eng: "a child who had had absolutely no problems",
            },
            {
              Kor: "아무런 감염의 징후를 찾을 수 없었다.",
              Eng: "couldn't find any indications of infection",
            },
          ],
        },
        {
          entryName: "아무런 ...도 + negative",
          typicalExample: "【아무런 조심스러움도 없는 여자 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "아무런, modifier form of ㅎ-irregular verb 아무렇다. ",
          sentences: [
            {
              Kor: "아무런 조심스러움도 없는 여자 ",
              Eng: "a woman with absolutely no caution/discretion",
            },
            {
              Kor: "그 남자와의 섹스에서 나는 여전히 아무런 느낌도 받을 수 없었다.  ",
              Eng: "During sex with him, I was still unable to derive any feeling.",
            },
          ],
        },
        {
          entryName: "아무리",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 아무리 (like 암만) presages a following concessive ending.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "아무리 ...-기로  ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Even though (if), even given (admitted) that, it's true that ... but. Cf. the adverbs 암 만해도 and 아무래도 (from 아무리 해도) do what you might, say what you wish; nonetheless, all the same, no matter what one does or says. ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "아무리 ...-(으)ㄴ대야 ",
          typicalExample: "【아무리 단장한대야 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Adverb 아무리 flags an upcoming concessive form, which here is contracted from -(으)ㄴㄷ[ㅏ고 ㅎ]ㅐ야 no matter how much one might VERB  ...; VERB as one might, (nonetheless) ... Cf. the adverbs 암만해도 and 아무래도 (from 아무리 해도) do what you might, say what you wish; nonetheless, all the same, no matter what one does or says. ",
          sentences: [
            {
              Kor: "아무리 단장한대야 ",
              Eng: "no matter how much you say that it is neat ...",
            },
          ],
        },
        {
          entryName: "아무리 ...-ㄴ/는다지만 ",
          typicalExample: "【아무리 돈에 운다지만 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Adverb 아무리 flags an upcoming concessive form, which here is contracted from -(으)ㄴ/는다[고 하]지만 no matter how much one might VERB ...; VERB as one might, (nonetheless) ... ; no matter how (much one might, much NOUN is) ... Cf. the adverbs 암만해도 and 아무래도 (from 아무리 해도) do what you might, say what you wish; nonetheless, all the same, no matter what one does or says. ",
          sentences: [
            {
              Kor: "아무리 돈에 운다지만 ",
              Eng: "no matter how much people might cry over money ...",
            },
          ],
        },
        {
          entryName: "아무리 ...-어도 ",
          typicalExample: "【그것이 아무리 어려운 일이어도  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Adverb 아무리 flags an upcoming concessive form, which here is just -어도 no matter how much one might VERB  ...; VERB as one might. Note that 아무리 can optionally be preceded by 제. Cf. the adverbs 암만해도 and 아무래도 (from 아무리 해도) do what you might, say what you wish; nonetheless, all the same, no matter what one does or says. ",
          sentences: [
            {
              Kor: "그것이 아무리 어려운 일이어도  ",
              Eng: "no matter how difficult a thing it might be …",
            },
            {
              Kor: "그런데 아무리 벨을 눌러도  ",
              Eng: "no matter how long I pushed the buzzer …",
            },
            {
              Kor: "아무리 기다려도 멎지는 않았다",
              Eng: "But no matter how long I waited, she did not stop.",
            },
            {
              Kor: "아무리 학문이 뛰어나고 지혜가 넘쳐도",
              Eng: "no matter how learned and outstandingly wise ...",
            },
            {
              Kor: "아무리 말려도 ",
              Eng: "no matter how much we tried to stop him …",
            },
            {
              Kor: "아무리 헤매도 ",
              Eng: "no matter how much he wandered ...",
            },
            {
              Kor: "아무리 포달을 떨고 볶아 대고 구박을 하고 하여도, 좀처럼 맞서서 언성을 높여 탄 하고 싸우고 하는 법이 없었다 ",
              Eng: "No matter how much she abused, harassed and nagged, she didn't confront and criticize in a harsh tone or argue like she used to. ",
            },
          ],
        },
        {
          entryName: "아뭏- ",
          typicalExample: "【아무튼지 ←  아뭏든지 】",
          categoryKorean: "Adjective",
          categoryEnglish: "(Adjective)",
          translation:
            "A short form equivalent to 아무렇- is any way, is whatever way, is any-which-way. 아무튼지 can be treated as an abbreviation of 아무렇든지 no matter what, in any case (event), anyway, at all events, anyhow. You will also encounter this as just 아무튼, or as 아무튼(지) 간에.",
          sentences: [{ Kor: "아무튼지 ←  아뭏든지 ", Eng: "in any case ..." }],
        },
        {
          entryName: "아스세요 ",
          typicalExample: "【앗으세요】",
          categoryKorean: "Adjective",
          categoryEnglish: "(Adjective)",
          translation:
            "For 앗으세요. This is the Polite Honorific (하세요) Style version of the expression (interjection) 앗어(라), 앗아(라) (oh) no!; ugh, gee, gosh; stop! cut it out, Knock it off; now, now! come, come! come now! (shows sudden displeasure, disgust, or prohibition).",
          sentences: [{ Kor: "앗으세요", Eng: "Knock it off!" }],
        },
        {
          entryName: "안 ...-고는 ",
          typicalExample: "【술 안< 마시고는 사회 생활이 안 돼요? 】",
          categoryKorean: "Adjective",
          categoryEnglish: "(Adjective)",
          translation:
            '안 ...-고는 equivalent to -지 않고서는. Placing 는 after -고 forces the "and [then] ..." reading of -고, as opposed to the "and [also]..." reading. In other words, it means "when ... , ... then," but emphasizing the consequence. On a negative, the consequence implied or stated is usually dire—if one doesn\'t do something, look out, because something unpleasant will/might happen, in which case "unless" is a good translation.',
          sentences: [
            {
              Kor: "술 안< 마시고는 사회 생활이 안 돼요? ",
              Eng: "You can't have a social life without drinking?",
            },
          ],
        },
        {
          entryName: "안 ...-고서(는) ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Colloquial ",
          categoryEnglish: "(Colloquial )",
          translation:
            "Newer, more colloquial version of 아니 VERB-고서: unless; as long as [doesn't/not].",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "안 ..-(으)ㄹ 수(가) 없다 ",
          typicalExample: "【목이 타서라도 술을 안 마실 수가 없었다. 】",
          categoryKorean: "Colloquial ",
          categoryEnglish: "(Colloquial )",
          translation:
            "안 ..-(으)ㄹ 수(가) 없다: has no choice or alternative but to ...; cannot help but to ...; can't help ...-ing. Same as -지 않을 수(가) 없다.",
          sentences: [
            {
              Kor: "목이 타서라도 술을 안 마실 수가 없었다. ",
              Eng: "We had to drink, even if it meant that our throats would burn up.",
            },
          ],
        },
        {
          entryName: "않는다1",
          typicalExample: "【이만 일로 고자질 같은 건 않겠는데 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contraction of 안 한다, 안 하겠다, etc., to 않는다, 않겠다, etc.",
          sentences: [
            {
              Kor: "이만 일로 고자질 같은 건 않겠는데 ",
              Eng: "… wouldn't go and tattle or the like on account of such a trifling matter",
            },
          ],
        },
        {
          entryName: "않는다2 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "For examples of elision (optional dropping) of 하지 in long negation patterns after a processive verbal noun.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "암만",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 암만 (like 아무리) presages a following concessive ending.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "암만 ...-기로 ",
          typicalExample: "【암만 사람이 변하기로 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Adverb 암만 presages a following concessive pattern, in this case -기로 even though (if), even given (admitted) that, it's true that ... but. Cf. the adverbs 암만해도 and 아무래도 (from 아무리 해도) do what you might, say what you wish; nonetheless, all thesame, no matter what one does or says. ",
          sentences: [
            {
              Kor: "암만 사람이 변하기로 ",
              Eng: "even granted that people change … (how could she change so much, so drastically?)",
            },
            {
              Kor: "지금 이 꼴로서야 문화주택이 암만 서기로 내게 무슨 상관이며 자동차 비행기가 개미떼나 파리떼처럼 퍼지기로 나와 무슨 인연이 있는 것이냐",
              Eng: "Even if cultured housing should spring up and cars and airplanes spread like swarms of ants or flies, what has it all got to do with me?   ",
            },
          ],
        },
        {
          entryName: "암만 ...-더라도",
          typicalExample: "【암만 즈 아버지가 반대를 하더라도 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "암만 즈 아버지가 반대를 하더라도 ",
              Eng: "Even if her father should oppose … (Note also 즈 for 저의 = 자기의.)",
            },
          ],
        },
        {
          entryName: "암만 ...-어도",
          typicalExample: "【집에 숨겨 놓는다는 게 암만해도 불안했다.】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 암만 presages a following concessive pattern, in this case -어도 even though (if), even given (admitted) that, it's true that ... but. Cf. the adverbs 암만해도 and 아무래도 (from 아무리 해도) do what you might, say what you wish; nonetheless, all thesame, no matter what one does or says. ",
          sentences: [
            {
              Kor: "집에 숨겨 놓는다는 게 암만해도 불안했다.",
              Eng: "Hiding him at home was risky nonetheless.  ",
            },
            {
              Kor: "암만 찾아도 못 찾겠더마.  ",
              Eng: "No matter how much I searched I couldn't find it. ",
            },
            {
              Kor: "나는 이렇게 눈에 불이 번쩍 나서 밖으로 뛰어왔으나 암만해도 알 수 없는 일이다.",
              Eng: "Inspite of my anger and rage, there was no way that I would find out what had happened.",
            },
          ],
        },
        {
          entryName: "-았는, -았던, etc.",
          typicalExample: "【단정히 앉았는 한 노파 】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            'What looks like an unusual/disallowed placing of the processive modifier -는 on a past base to modify a noun (as opposed to a postmodifier, where it is allowed) is in fact an abbreviation of the "resultant state" pattern in -어 있다: 앉아 있는. Ditto for -았던, which is a contraction for -아 있던 i.e., resultant state -어 있- is in the state of resulting from, is done.',
          sentences: [
            {
              Kor: "단정히 앉았는 한 노파 ",
              Eng: "an old woman sitting in a neatly fashion / with decorum ...",
            },
            {
              Kor: "꼿꼿이 앉았는 모습에 ",
              Eng: "in her erect sitting posture ...",
            },
            {
              Kor: "앉았는 것이었다 = 앉아 있는 것이었다",
              Eng: "was sitting, was seated",
            },
            {
              Kor: "그러면 가만히 듣고 앉았던 노인들은 ",
              Eng: "Then the elders, who had remained quiet listening ...",
            },
          ],
        },
        {
          entryName: "앙이",
          typicalExample: "【딸이 앙이 왔소?】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            'Dialect for 아니. The "ㅇ" is an attempt to capture a nasalized vowel. All the examples below are from 이태준\'s "아담의 후예," and thus from Hamkyeng dialect.',
          sentences: [
            { Kor: "딸이 앙이 왔소?", Eng: "Didn't your daughter come?" },
            { Kor: "앙이!", Eng: "No!" },
            {
              Kor: "다시 앙이 오문 그망이지",
              Eng: "If I don't return, that's it. (오문 for오면, 그망이지 for 그만이지.)",
            },
            {
              Kor: "저것들이 송장이 앙이구 무스겐고? ",
              Eng: "If these aren't corpses, then what are they? (앙이구 is dialect for 아니고, and 무스겐고 is dialect for 무엇인가.)",
            },
            {
              Kor: "앙잉게앙이라 ",
              Eng: "Sure enough ... (Dialect for 이닌게아니라.)",
            },
          ],
        },
        {
          entryName: "앞두고",
          typicalExample: "【요즈음 세번째 개인전을 앞두고 】",
          categoryKorean: "Dialect morphology",
          categoryEnglish: "(Dialect morphology)",
          translation:
            "NOUN(을) 앞두고: in anticipation of NOUN, with NOUN to look forward to or just around the bend/coming up. ",
          sentences: [
            {
              Kor: "요즈음 세번째 개인전을 앞두고 ",
              Eng: "lately, in anticipation of his [upcoming] third art exhibition",
            },
            {
              Kor: "예비고사를 며칠 앞두고 전문가들로부터 마지막 점검을 받았다. ",
              Eng: "With just a few days to go until the pre-exam, he had had his last check from the experts.",
            },
            {
              Kor: "평생을 국민학교 교사로 보낸 아버지는 이제 정년을 석 달 앞두고 있는 노인이고  ",
              Eng: "My father, who had been an elementary school teacher for his whole life, was now an old man who would be retiring in a few months...",
            },
            {
              Kor: "남편은 요즈음 세 번째 개인전을 앞두고 그 준비 때문에 집에 들어오지 않고 ",
              Eng: "My husband hasn't come home at all these days because he is preparing for his third private exhibition...",
            },
          ],
        },
        {
          entryName: "-야",
          typicalExample: "【무슨 상관야, 내 발루 내가 가는데 】",
          categoryKorean: "Colloquial/준꼴 Contraction",
          categoryEnglish: "(Colloquial/준꼴 Contraction)",
          translation:
            "Colloquial contraction, after a consonant, of what should be written -이야, the Intimate Style copula.",
          sentences: [
            {
              Kor: "무슨 상관야, 내 발루 내가 가는데 ",
              Eng: "lit.: What business is it of yours—I’m walk with my own feet!; or, They're my feet and I can go wherever I want!",
            },
            {
              Kor: '"동물의 생태를 연구하는 클럽야." ',
              Eng: '"It\'s a club for studying animal ecology."',
            },
            {
              Kor: '"그럼 둘 다 불합격야?" ',
              Eng: '"So both of you didn\'t make the grade?"',
            },
            { Kor: '"이게 네 방야?" ', Eng: '"Is this your room?"' },
            {
              Kor: '"그 사람은 감옥에까지 갔었던 사람야." ',
              Eng: "He's somebody who even went to jail once way back when.",
            },
            {
              Kor: '"그게 내 공부와 무슨 상관야?" ',
              Eng: "What's that got to do with my studying?",
            },
            {
              Kor: "굵은 호두야 많이 떨어져라",
              Eng: "Come on, you big fat walnuts, fall on down here!",
            },
            {
              Kor: "오 환씩야 = 오 환씩이야 ",
              Eng: "[at the very least] 5 hwan each (Here the particle is reinforcing the sense of the preceding 최소한도 at least and the approximative meaning of following 무려하- as many as, no less than.)",
            },
          ],
        },
        {
          entryName: "야말로 【너야말로/남편이야말로 정말 친절하다】",
          typicalExample: '【"나두 걱정야말루 그 걱정이로세"】',
          categoryKorean: "Colloquial/준꼴 Contraction",
          categoryEnglish: "(Colloquial/준꼴 Contraction)",
          translation:
            "Colloquial contraction after a consonant of what should properly be written NOUN 이야말로 NOUN indeed. ",
          sentences: [
            {
              Kor: '"나두 걱정야말루 그 걱정이로세"',
              Eng: "“Worried? Of course I'm worried about that.” (The -루 here is dialect.)",
            },
          ],
        },
        {
          entryName: "-어 간다",
          typicalExample: "【태도로 변하여 갈 때도 있었다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Gradually (goes on doing); keeps growing (getting to be, becoming), goes on, progressively; continues to do (from now on), keeps (on) doing. This pattern shows that an action or state goes forward in time away from the speaker and is the opposite of the pattern in -어 오- which shows that an action or state comes toward the speaker in time.",
          sentences: [
            {
              Kor: "태도로 변하여 갈 때도 있었다",
              Eng: "There were also times when my behavior would gradually turn into ...",
            },
            {
              Kor: "억지로 버티어 갈 제",
              Eng: "as they kept on forcing themselves to hang on/stick it out",
            },
            {
              Kor: "늙어 가는 것이 원통하였다",
              Eng: "He hated growing older (lit.: The fact of growing older was hateful for him.)",
            },
            {
              Kor: "딴사람으로 변해 가는 것 같았다",
              Eng: "He seemed to be gradually changing ...",
            },
            {
              Kor: "회색으로 흐려 가는 하늘",
              Eng: "the sky which was getting darker and darker ",
            },
            {
              Kor: "가을 햇살만이 말라가는 풀 냄새",
              Eng: "the smell of the grass ['keeping on'] drying in the [hot] autumn sun's rays",
            },
            {
              Kor: "나의 뜨거워져 가는 머리 ",
              Eng: "my head, getting hotter and hotter",
            },
            {
              Kor: "택하고 싶어해 가는 것을 알고 있었기 때문이다",
              Eng: "because she knew that I wanted more and more to choose ...",
            },
            {
              Kor: "술병이 점점 늘어갔다 ",
              Eng: "More and more empty bottles appeared (on the table).",
            },
            {
              Kor: "앉는 곳이 멀어져 갔다 ",
              Eng: "The place they alighted grew ever more distant.",
            },
            {
              Kor: "점점 쇠진해 갔다 ",
              Eng: "kept growing weaker/more and more exhausted",
            },
            {
              Kor: "개울물은 날로 여물어갔다 ",
              Eng: "The water in the stream was getting shallower and shallower with each passing day.",
            },
            {
              Kor: "눈발이 차츰 걷혀 갔다 ",
              Eng: "The snowflakes gradually cleared.",
            },
            {
              Kor: "말없는 긴장이 날로 깊어갔다 ",
              Eng: "This unspoken tension grew deeper with each passing day.",
            },
            {
              Kor: "그 싸이렌에 흡수되어 갔다 ",
              Eng: "gradually extinguished by that siren",
            },
            {
              Kor: "잿바람을 들여마셔 가며 ",
              Eng: "breathing in the ash-laden air ...",
            },
            {
              Kor: "오만상을 찌푸려가며 손짓을 해가며 ",
              Eng: "[situations in which two men] distort their faces [while talking, and] gesturing with their hands ...",
            },
            { Kor: "침을 삼켜가며 ", Eng: "as they continued to salivate ..." },
            {
              Kor: "정신을 잃어가고 있었다",
              Eng: "[He] was gradually losing consciousness.",
            },
            {
              Kor: "늙어가고 있다 ",
              Eng: "... is progressively getting older.",
            },
            {
              Kor: "나는 그 여자보다 훨씬 손아래지만 지금 남편 옆에서 볼품없는 꼴로 늙어 가는데 ",
              Eng: "I am much younger than her, yet here I am, an unseemly woman, aging next to my husband,   ",
            },
            {
              Kor: "목숨을 이어가는 데 해어쯤이 없으면 어떨까도 생각되었다.",
              Eng: "I wondered if it would matter that we didn't have any fish, when we're just trying to survive. ",
            },
            {
              Kor: "어느새 바닥이 비어 간다며 양 씨가 미안한 표정을 지었다",
              Eng: '"It\'s [the alcohol] disappearing so quickly," Mr. Yang said with a sorry look on his face.  ',
            },
            {
              Kor: "모진 목숨을 이어가는 중",
              Eng: "as they continued to eke out their miserable existence … (The verb here is the 's-dropping' base 잇다, 이어요.)",
            },
            {
              Kor: "인정이 휙 변해가는 판인데",
              Eng: "Nowadays when people's sentiments are changing so fast ...",
            },
          ],
        },
        {
          entryName: "-어 가지고(는)",
          typicalExample:
            "【조르다, 조르다, 성이 나가지고는 이를 북북 갈면서】",
          categoryKorean: "Colloquial ",
          categoryEnglish: "(Colloquial )",
          translation:
            "With (the accomplishment of) doing (and keeping it) for oneself. This pattern functions as a colloquial equivalent of -어서.",
          sentences: [
            {
              Kor: "조르다, 조르다, 성이 나가지고는 이를 북북 갈면서",
              Eng: "After whining incessantly, he got angry and while gnashing his teeth he...",
            },
            {
              Kor: "사오십 전씩 얻어 가지고는 번번이 담뱃값으로 다 내어보내고",
              Eng: "He received forty or fifty cents, spent it all on several cigarette purchases and then… (Here 얻어 가지고는 = 얻어서는)",
            },
            {
              Kor: "자기도 이래 가지고는 못 견디겠다　는 각오를 했다",
              Eng: "She resolved that she couldn't keep living like this. ",
            },
            {
              Kor: "팔아 가지고는 어둡기 전에 다시 산으로 돌아와야 할 것이다 ",
              Eng: "We'd better sell it and return to the mountain before it gets dark. ",
            },
          ],
        },
        {
          entryName: "-어 갖고",
          typicalExample:
            "【저는 당신이 얼마든지 주무르고 어를 수 있는 여자예요, 하듯이 다소곳해져 갖고 그 이를 맞았다 】",
          categoryKorean: "Colloquial ",
          categoryEnglish: "(Colloquial )",
          translation:
            "-어 갖고 / -어 가지고, roughly equivalent to -어서 in both its senses (sequential; reason). ",
          sentences: [
            {
              Kor: "저는 당신이 얼마든지 주무르고 어를 수 있는 여자예요, 하듯이 다소곳해져 갖고 그 이를 맞았다 ",
              Eng: "I got all lovey-dovey and sent him off with a kiss as if to say that I was a woman that he could control and play with whenever he pleased.  ",
            },
          ],
        },
        {
          entryName: "-어 나간다",
          typicalExample: "【열심히 지워 나갔다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Proceed to ...; proceeds with ...-ing; to progressively does it. ",
          sentences: [
            {
              Kor: "열심히 지워 나갔다 ",
              Eng: "I proceeded to vigorously put [my father] out of my mind.",
            },
          ],
        },
        {
          entryName: "-어 낸다",
          typicalExample: "【냄새를 씻어 낼 수 있다고 믿었던 것일까. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Does all the way (to the very end, thoroughly, through and through); does to the point of obtaining the result sought; manages to do (to get it done).",
          sentences: [
            {
              Kor: "냄새를 씻어 낼 수 있다고 믿었던 것일까. ",
              Eng: "Was it perhaps the case that he had believed it could wash away the smell?",
            },
            {
              Kor: "한번 알리기를 거부한 걸 기억해내고 ",
              Eng: "Remembering that he had once refused to enlighten me [about his past] ...",
            },
            {
              Kor: "복사해 낸 사람이니까 ",
              Eng: "Since he's the person who made [or, did a thorough job of making] copies [of the blueprints...]",
            },
            {
              Kor: "끓는 물에 데쳐낸 것처럼",
              Eng: "like something thoroughly parboiled in boiling water",
            },
            {
              Kor: "발견해낼 수 없었다 ",
              Eng: "He was unable to discover ...",
            },
            {
              Kor: "역력히 번역해 낼 수 있다 ",
              Eng: "I can clearly translate [what they are doing, feeling] all the way.",
            },
            {
              Kor: "거침없이 해내곤 했던 것이다 ",
              Eng: "I used to (have thoughts) without (any embarrassment) or hesitation.",
            },
          ],
        },
        {
          entryName: "-어 놓는다1",
          typicalExample: "【미이라에다 옷을 입혀 놓은 것처럼 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Does for later, does in advance, gets it ready beforehand; does it and leaves it that way; does it for now.",
          sentences: [
            {
              Kor: "미이라에다 옷을 입혀 놓은 것처럼 ",
              Eng: "as if somebody had dressed up a mummy",
            },
            {
              Kor: "칼을 풀어 놓았고 ",
              Eng: "lit.: He had taken off [and put away] his sword.",
            },
            {
              Kor: "라디오 음악을 크게 틀어 놓았다",
              Eng: "She turned the radio up loud.",
            },
            { Kor: "발걸음을 옮겨 놓았다 ", Eng: "He moved his footsteps. " },
            { Kor: "사람들을 떼어 놓았다 ", Eng: "People were separated." },
            {
              Kor: "아버지하고 저하고 다 꾸며 놓을께요 ",
              Eng: "Father and I will take care of everything.",
            },
            { Kor: "약을 비치해 놓고 있었다", Eng: "… was applying medicine." },
            {
              Kor: "인자가 비워 놓고 간 희망 ",
              Eng: "the hope abandoned by Inja",
            },
            {
              Kor: "심지를 낮추어 놓고 ",
              Eng: "I turned down the light, and ...",
            },
            {
              Kor: "퇴근 시간을 20분 남겨놓고 ",
              Eng: "With (lit.: having set aside) twenty minutes to go before quitting time …",
            },
            {
              Kor: "강당에 집합시켜 놓고 ",
              Eng: "gathered everyone in the auditorium, and ...",
            },
            {
              Kor: "방문을 안으로 잠궈 놓고 ",
              Eng: "When I was with my friends we even locked the door from the inside and [drank soju].",
            },
            {
              Kor: "그는 몇 걸음 남겨 놓고 ",
              Eng: "He stopped a few steps short and ...",
            },
            { Kor: "써 놓은 쪽지 ", Eng: "the note jotted down" },
            {
              Kor: "붙여 놓는 것이었다",
              Eng: "lit.: It was the case that his wife [regularly] attached [gum to the mirror].",
            },
            {
              Kor: "주임을 뒤에 남겨 놓은 채 도서관을 나왔다 ",
              Eng: "I came out of the library leaving the librarian behind [as she was].",
            },
            {
              Kor: "칼을 풀어 놓고 말았다",
              Eng: "ended up taking off his sword ...",
            },
            { Kor: "한 짐을 채워 놓아야 ", Eng: "… must pack a full load." },
            {
              Kor: "안영감은 자기가 주운 것을 모조리 내어놓지는 않았다.  ",
              Eng: "Mr. An would not give up all the things that he had found.  ",
            },
            {
              Kor: "임시로 지어 놓은 단간 함석집 안에는 안영감보다 먼저 들어 있는 두 늙은이가 있었다. ",
              Eng: "In the make-shift house with a zinc-roof were two seniors who had come before Mr. An.",
            },
          ],
        },
        {
          entryName: "-어 놓는다2 [-어 놓아 (서)]",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-어 놓- (usually only ever in the form -어 놓아서) means is in a state of being and is permanently so, is institutionalized, fixed for good, is left being, remains.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-어놔서 ",
          typicalExample: "【우린 습관이 되어 놔서 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "For -어 놓-: is in a state of being and is permanently so, is institutionalized, fixed for good, is left being, remains",
          sentences: [
            {
              Kor: "우린 습관이 되어 놔서 ",
              Eng: "lit.: Since this has become our habit … i.e., Since we're used to this ...",
            },
            {
              Kor: "우리두 기술이 좀 있어 놔서 ",
              Eng: "Since we also have some skills ...",
            },
            { Kor: "재가 있어놔서", Eng: "Being that there is a cliff ..." },
          ],
        },
        {
          entryName: "어느 ...-(이)나 ",
          typicalExample: "【어느 때나 푸르고】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "어느 NOUN-(이)나: any and every NOUN",
          sentences: [
            { Kor: "어느 때나 푸르고", Eng: "it's always green ..." },
            {
              Kor: "땅을 치며, 어느 때까지나 울고만 있을 것처럼 통곡을 계속하였다.",
              Eng: "Hitting the ground, she kept on wailing as though she'd never stop.  ",
            },
            {
              Kor: "배가 고파 어찌할 수 없을 때엔 어느 제숫댁들이나 찾아오듯 다시 그 할멈들의 함지 앞으로 어슬렁어슬렁 나타났고  ",
              Eng: "When he was hungry without any means of getting food, he would show up leisurely in front of the old ladies as if he was visiting his (relative), and …",
            },
          ],
        },
        {
          entryName: "어느 ...에고 ",
          typicalExample: "【하루씩 어느 마을에고 들어가 쉬었다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "어느 NOUN에고 ← from 어느 NOUN (+ particle) + -(이)고: some NOUN or other",
          sentences: [
            {
              Kor: "하루씩 어느 마을에고 들어가 쉬었다",
              Eng: "Every day they would enter some village and rest.",
            },
          ],
        },
        {
          entryName: "어느 ...도 ",
          typicalExample: "【어느 누구에게도 제 모습을 내보이지 않으면서도】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Any ... whatsoever, any which ....",
          sentences: [
            {
              Kor: "어느 누구에게도 제 모습을 내보이지 않으면서도",
              Eng: "while not showing itself to anyone ...",
            },
            {
              Kor: "하지만 그 어느 쪽도 완전히 다른 쪽의 특성에서 자유로울 수는 없습니다. ",
              Eng: "However, neither group could be free of the characteristics of the other.",
            },
            {
              Kor: "다른 어느 곳에서도 하지 않았던 엉뚱한 생각을, 나는 무진에서는 아무런 부끄럼 없이, 거침없이 해내곤 했던 것이다. ",
              Eng: "Foolish thoughts that had never crossed my mind before, seemed to make their way into my mind without shame or hesitation.",
            },
            {
              Kor: "해방 이후 우리나라 어느 산에서도 호랑이가 발견된 적이 없다 ",
              Eng: "After Liberation there were no tigers to be found on any mountain in the entire country.",
            },
            {
              Kor: "한나절을 기다렸지만 어느 비탈에서도 멧돼지 한 마리 발견할 수가 없었다. ",
              Eng: "Although I had waited half the day, I could not find a single wild boar on any slope.  ",
            },
          ],
        },
        {
          entryName: "-어니",
          typicalExample:
            "【아빠의 머리 위에 나무 십자가가 덩그러니 걸려 있었다. 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb in -어니 ~-아니. For 하-, one encounters both 허니 and 하니 (= 하게). Cf. 일찌거니 a bit earlier, 슬그머니 surreptitiously, 멀거니 with a far-off look, absentmindedly, etc.",
          sentences: [
            {
              Kor: "아빠의 머리 위에 나무 십자가가 덩그러니 걸려 있었다. ",
              Eng: "Above dad's head the wooden cross was hanging imposingly.",
            },
            {
              Kor: "벽에 도마뱀 한 마리가 덩그러니 걸려 있다. ",
              Eng: "There is a lizard hanging imposingly from the wall.",
            },
          ],
        },
        {
          entryName: "-어 다(가)",
          typicalExample: "【나를 아랫목으로 끌어다 앉히고】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Infinitive + copular transferentive -(이)다가, emphasizing shift of location, purpose, direction, benefit, etc.",
          sentences: [
            {
              Kor: "나를 아랫목으로 끌어다 앉히고",
              Eng: "She led me to the warm spot on the floor and sat me down ...",
            },
            {
              Kor: "내가 양씨를 건너다봤다 ",
              Eng: "I looked across at Mr. Yang.",
            },
            {
              Kor: "나를 태워다 줄까 망서린다",
              Eng: "Is he hesitating thinking (wondering) if he should give her a ride?",
            },
            {
              Kor: "병을 집어다 여자 앞 카운터에 딱 놓았다 ",
              Eng: "… picked up the bottle and [moved it over to where the woman was and] placed it on the counter in front of her.",
            },
            {
              Kor: "땔 만한 것들을 끌어모아다가 ",
              Eng: "scraped together stuff that was suitable to build a fire with, and  ...",
            },
          ],
        },
        {
          entryName: "-어다(가)",
          typicalExample: "【가져다 쓰기도 여러 번이었다】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Infinitive -어/-아 + transferentive -다(가), where the latter emphasizes a shift of place in the action occurring. Martin analyzes the latter as -(이)다가, I.e., a copular transferentive with the copular -이- dropped after the preceding (infinitive) vowel.",
          sentences: [
            {
              Kor: "가져다 쓰기도 여러 번이었다",
              Eng: "He would then take the money and use it. It happened several times.",
            },
            {
              Kor: "쌀, 나무, 고기, 생선, 술 모두 다 그립지는 아니할 만큼 들어도 오고, 청해다 먹기도 하고 하였다 ",
              Eng: "There was an abundant supply of rice, wood, meat, fish and liquor and he was often invited out to eat as well.  ",
            },
            {
              Kor: "집어다 눌러 보았다 = 집어다가 눌러 ",
              Eng: "picked it up [shifting its location toward himself] and squeezed it",
            },
            {
              Kor: "안경다리를 고치겠다고 돈 일 원만 달래도 일 원짜리를 굳이 바꿔다가 오십 전 한 닢만 주었다 ",
              Eng: "If I ask for even a dollar to help fix my glasses, they would exchange their dollar and only give me fifty cents.  ",
            },
            {
              Kor: "상등 털사쓰를 사다 입히구(= 사다(가) 입히고)",
              Eng: "We'll buy the best quality woolen sweater, dress him in it and...",
            },
            {
              Kor: "새벽에 길어다 놓은 것은",
              Eng: "[water] drawn early this morning ...",
            },
            {
              Kor: "옷을 한가지씩 빼어다 따루 보따리를 싸놨던 모양이라",
              Eng: "It seems she took out her clothes one by one and packed them in another bag.",
            },
            {
              Kor: "모자라면 꿔다 앉히구 ",
              Eng: "If one isn't enough, we can just bring in more girls and sit them down ...",
            },
            {
              Kor: "옆의 수숫단을 날라다 덧세운다",
              Eng: "carried over the neighbouring sheaf and stood it up against it",
            },
            {
              Kor: "남자는 술병을 집어다 ",
              Eng: "The man picked out a bottle of wine and then …",
            },
            {
              Kor: "바우를 앞에 내다세웠다 ",
              Eng: "[They] ['produced and'] stood Pau in front of them.",
            },
            {
              Kor: "잡아다 달래던데",
              Eng: "She asked us to catch you ... (Note 달래던데 for 달라고 하던데.)",
            },
            { Kor: "못 길어다 놓았소", Eng: "Didn't you even draw the water?" },
            { Kor: "이서방을 청해다…", Eng: "invite Mr. Yi, and ..." },
            {
              Kor: "안줏거리를 날라다 놓곤 하였다 ",
              Eng: "[T'ansil] would bring in (from time to time) the appetizer fixings ...",
            },
            {
              Kor: "나무를 베어다 껍질째 도막을 내",
              Eng: "He'll cut down the trees, break the bark into pieces, and ...",
            },
            {
              Kor: '"남편들은 이 눈치 저 눈치 봐가며 뼈빠지게 벌어다 주면 …” ',
              Eng: "While the husbands are working themselves to the bones, forever having to watch their backs, to bring home the bacon …",
            },
            {
              Kor: "여러 번 얻어다 준 것이었었다 ",
              Eng: "several times received [fabric] and then gave it to [his wife]",
            },
            {
              Kor: "꺾어다 줄 것 ",
              Eng: "break off and give [with a shift taking in place from the picking to the giving]",
            },
            { Kor: "내 불러다 주께", Eng: "I'll get them [for you] ..." },
          ],
        },
        {
          entryName: "-어 달라며",
          typicalExample:
            "【같이 자자고 요구하는 남자에게 눈물만으로 사랑을 확인해 달라며】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "-어 달라며 / -어 달라[고 하]며: requesting that somebody do a one[self] the favor of ...-ing (with -(으)며 as an abbreviation for -(으)면서 while, at the same time).",
          sentences: [
            {
              Kor: "같이 자자고 요구하는 남자에게 눈물만으로 사랑을 확인해 달라며",
              Eng: "[While] asking, with only tears, a man who has demanded sex from me to confirm his love for me …",
            },
          ],
        },
        {
          entryName: "-어 댄다",
          typicalExample: "【대충 그런 식의 얘기를 네댓 시간 떠들어대니  】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "(Does) terribly, awfully, a lot, like mad/anything (gives iterative and/or emphatic force to a preceding verb infinitive). ",
          sentences: [
            {
              Kor: "대충 그런 식의 얘기를 네댓 시간 떠들어대니  ",
              Eng: "after carrying on with this sort of conversation for three or four hours",
            },
            {
              Kor: "그러안을수록 그악스레 울어대는 아이",
              Eng: "a child who, the more one held it close, the more fiercely it kept crying",
            },
            {
              Kor: "철망을 긁으며 짖어대던 강아지들이 ",
              Eng: "The puppies, who were scratching at their metal wire and barking continuously …",
            },
            {
              Kor: "어린 아이들이 이집 저집에서 울어대었다.  ",
              Eng: "Young children were bawling here and there in the houses.",
            },
            {
              Kor: "이집 저집에서 여전히 아이들이 울어대었다. ",
              Eng: "Small children were bawling here and there in the houses, as ever.",
            },
            {
              Kor: "그의 고향집 문을 박차고 들어오면서 마구 총을 쏘아 대었다.  ",
              Eng: "[The military police] just kept on shooting everywhere as they kicked open the door to his ancestral home and barged in.",
            },
            {
              Kor: "생맥주집에서 무슨 얘기를 그렇게 떠들어대고 그걸로 모자라  ",
              Eng: "I don’t know why we had so much to yak about, but whatever we had at the beer parlor wasn’t enough, so …",
            },
            {
              Kor: "지치지도 않고 떠들어댔다 ",
              Eng: "[imagining the shock] we didn't even get tired and pressed on noisily",
            },
            {
              Kor: "허구한 날 도리질이나 해 대는 걸",
              Eng: 'The landlady, who called her mother-in-law\'s constant head-shaking her "undertaking" … ',
            },
            { Kor: "볶아 대고 ", Eng: "pestered terribly and ..." },
            {
              Kor: "그 여자를 놀려대고 있는",
              Eng: "[the shoeshines, who were] teasing her terribly ...",
            },
            {
              Kor: "하도 호랑이 호랑이 해대니까 ",
              Eng: "because he goes on and on about tigers ...",
            },
            {
              Kor: "싹싹 비벼대며 ",
              Eng: "smiling or immediately rubbing his palms together incessantly ...",
            },
            {
              Kor: "두 손을 내려뜨리고 비벼대면서 ",
              Eng: "he lowered his hands and rubbed them vigorously ...",
            },
            {
              Kor: "씨부려대는 허풍쟁이다 ",
              Eng: "He is a bragging show-off.",
            },
            {
              Kor: "하염없이 불러대는 ",
              Eng: "[the song] she'd go on and on, singing endlessly ...",
            },
            { Kor: "꽝꽝 짖어댄다 ", Eng: "bark loudly repeatedly ..." },
            {
              Kor: "두들겨대고 있었다",
              Eng: "[He] was beating [the door] incessantly.",
            },
            {
              Kor: "이 억배로 마셔 대는 탁배기",
              Eng: "This booze that was consumed in large quantities ...",
            },
            {
              Kor: "둘이 떠들어대고 있었다",
              Eng: "The two were chatting up a storm.",
            },
            {
              Kor: "그 자리에서 바우는 또 떠들어대었다 ",
              Eng: "There he harangued them again at great length. ",
            },
            {
              Kor: "욕을 퍼대며 ",
              Eng: "as he heaped on the curses ... (퍼 is the infinitive of 푸다).",
            },
            {
              Kor: "이러쿵 저러쿵 떠들어 대는 대로의 온천물의 효험",
              Eng: "some sort of power in the water, as touted by those who go on about such things",
            },
            {
              Kor: "찔끔대며 웃어댔다 ",
              Eng: "… laughed with tears in our eyes.",
            },
            {
              Kor: "그는 유쾌해 죽겠다는 듯이 웃어대었다 ",
              Eng: "He was laughing as if he was delighted to death.",
            },
          ],
        },
        {
          entryName: "-어도 VERB",
          typicalExample: "【[Examples?]】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Sometimes you will encounter cases where the -어/아 infinitive in a compound verb (or main verb + auxiliary verb construction) is followed by particle 도 as a kind of lazy short-cut equivalent to -기도 하-  meaning also does/is. The pattern, then, is: VERB1 -어도 VERB2 for VERB1 -어 VERB2 -기도 하-. E.g. 먹어도 보고 for 먹어보기도 하고, 들어도 오고 for 들어오기도 하고.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-어도",
          typicalExample:
            "【어떤 악조건과 최악의 상황을 갖다 붙여도 좋았을 나이에 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation: "Question Word+ -어도: WH-ever one does/is",
          sentences: [
            {
              Kor: "어떤 악조건과 최악의 상황을 갖다 붙여도 좋았을 나이에 ",
              Eng: "at an age when it would have been OK to confront me with any bad conditions or the worst possible situation …",
            },
          ],
        },
        {
          entryName: "-어도 본다",
          typicalExample: "【규슈 탄광에 있어도 보고 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Exploratory pattern in -어 보- emphasized with 도: also/even tries doing; tries doing something else; too/even. This pattern can be paraphrased as -어 보기도 한다.",
          sentences: [
            {
              Kor: "규슈 탄광에 있어도 보고 ",
              Eng: "he also tried staying at a coal mine in Kyushu",
            },
            {
              Kor: "턱에다 받쳐도 보고 하였다",
              Eng: "I also held him up against my chin and stuff.",
            },
            {
              Kor: "침대도 털어도 보았다 ",
              Eng: "He also tried to shake out the bed.",
            },
            {
              Kor: "다시 학나무 위에 와 앉아도 보았다 ",
              Eng: "[The crane] even perched again on the crane tree.",
            },
          ],
        },
        {
          entryName: "-어도 안 ...",
          typicalExample: "【가져도 안 가고】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "The pattern in -지도 않- doesn't/isn't even... in compound verbs can be abbreviated by placing the 도 on the infinitive of the first member of the compound.",
          sentences: [
            {
              Kor: "가져도 안 가고",
              Eng: "never even took any over (For 가져 가지도 안 하고.)",
            },
            {
              Kor: "인사는 커녕 쳐다도 안 보고 ",
              Eng: "She didn't even look at him, much less greet him ... (쳐다도 안 보고 = 쳐다 보지도 않고 (-어(다)도 안 보-  = -어보지도 않-).",
            },
          ],
        },
        {
          entryName: "-어 둔다",
          typicalExample: "【호랑이 길도 살펴 두었으며 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Infinitive in -어 + auxiliary 두다: does something to get it out of the way (perhaps as a precaution); gets it done, finishes it up (so it will be out of the way), does it once and for good, does thoroughly/carefully now (so it will not have to be done again; does for later/future use or reference. Same as -어 놓다. ",
          sentences: [
            {
              Kor: "호랑이 길도 살펴 두었으며 ",
              Eng: "he had also scanned over the tiger trail ...",
            },
            {
              Kor: "한 마디 두 마디 배워 두었던 일본말 ",
              Eng: "the one or two phrases of Japanese she had managed to learn",
            },
            {
              Kor: "낮에 봐 두었던 나무",
              Eng: "the tree he had scouted out earlier in the day",
            },
            {
              Kor: "나와 달라는 말을 못해 둔 것이었다 ",
              Eng: "He hadn't had a chance to ask her ['ahead of time / in good time'] to come out.",
            },
            { Kor: "간직하여 둔 노루고기 ", Eng: "a venison, stored away ..." },
            {
              Kor: "가랑잎에 싸둔 개꿀이 있음을 생각하고 ",
              Eng: "his thoughts turned to the fresh honey hidden away in the dead leaves, and ...",
            },
          ],
        },
        {
          entryName: "어따 ",
          typicalExample: "【이 안에서 어따 돈을 쓰오?】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation: "Abbreviated from 어디다 = 어디(에)다(가)",
          sentences: [
            {
              Kor: "이 안에서 어따 돈을 쓰오?",
              Eng: "Where would you use the money in here?",
            },
          ],
        },
        {
          entryName: "어떻게 된 게",
          typicalExample: "【어떻게 된 게 】",
          categoryKorean: "Stock phrase",
          categoryEnglish: "(Stock phrase)",
          translation:
            "Stock phrase meaning whadda ya know—surprise!; the next thing you know ...; for some reason.",
          sentences: [
            { Kor: "어떻게 된 게 ", Eng: "the next thing they knew ..." },
          ],
        },
        {
          entryName: "어떻게 해서나",
          typicalExample: "【어떻게 해서나 】",
          categoryKorean: "Stock phrase",
          categoryEnglish: "(Stock phrase)",
          translation: "Stock phrase (built with -어(서) + -(이)나).",
          sentences: [
            {
              Kor: "어떻게 해서나 ",
              Eng: "one way or another; by hook or by crook (lit.: no matter how it was done)",
            },
          ],
        },
        {
          entryName: "-어라 ",
          typicalExample: "【마을 사람들은 '꺾어져라' 목을 뒤로 젖혔다 】",
          categoryKorean: "Stock phrase",
          categoryEnglish: "(Stock phrase)",
          translation:
            "Use of Plain Style imperative in -어라/-아라 as an emphatic adverb.",
          sentences: [
            {
              Kor: "마을 사람들은 '꺾어져라' 목을 뒤로 젖혔다 ",
              Eng: "The villagers craned their necks back to the breaking point.",
            },
            {
              Kor: "죽어라고 닦아논 차",
              Eng: 'the car which I cleaned with all my might ...(lit.: saying, "[Till you] drop!") ',
            },
            {
              Kor: "하늘이 울려라 고함을 쳤다 ",
              Eng: "He shouted to the high heavens. (For 울려라(고).)",
            },
            {
              Kor: "여자의 눈이 지수를 뚫어져라 쳐다보았다.  ",
              Eng: "The woman's eyes stared at Chisu like they would bore through her.",
            },
            {
              Kor: "그렇게 나를 뚫어져라 쳐다보니 ",
              Eng: "After staring at me like this as if she might bore a hole through me.",
            },
          ],
        },
        {
          entryName: "-어 마지 않는다",
          typicalExample: "【칭찬해 마지않았다 】",
          categoryKorean: "Stock phrase",
          categoryEnglish: "(Stock phrase)",
          translation:
            "Does something to no end; does something ceaselessly/endlessly",
          sentences: [
            { Kor: "칭찬해 마지않았다 ", Eng: "praised him ceaselessly" },
            {
              Kor: "임종하는 자리에까지 내내 미련겨워하기를 마지아니하는 것이 항용 아녀 자의 넓지 못한 속",
              Eng: "Her unchanging cold-heartedness showed even on her deathbed when she did not show any hints of regret.   ",
            },
          ],
        },
        {
          entryName: "-어만 준다",
          typicalExample: "【눈먼 사람은 도와만 줄 것 】",
          categoryKorean: "Stock phrase",
          categoryEnglish: "(Stock phrase)",
          translation:
            "Particle 만 acting with the benefactive pattern in -어 주-. ",
          sentences: [
            {
              Kor: "눈먼 사람은 도와만 줄 것 ",
              Eng: "had to only help the blind man ...",
            },
          ],
        },
        {
          entryName: "-어 먹는다",
          typicalExample: "【대구 유곽에 팔아먹은 것이었다 】",
          categoryKorean: "Stock phrase",
          categoryEnglish: "(Stock phrase)",
          translation:
            "Infinitive in -어 + auxiliary 먹다 as a kind of vulgarizing, pejorative finalizer (more vulgar equivalent of -어 버리-). Cf. 잊어먹다, 까먹다, 게을러 먹다, etc.",
          sentences: [
            {
              Kor: "대구 유곽에 팔아먹은 것이었다 ",
              Eng: "They had sold her off to a brothel in Taegu.",
            },
            {
              Kor: "맘대로 10원을 떼먹을 작정인 것이다 ",
              Eng: "He is determined not to pay the ten wŏn (Contracted here from 떼어먹- .)",
            },
            {
              Kor: "또 할멈들도　저놈의 첨지, 공을 모르는 첨지, 빌어먹어 싼 첨지.  ",
              Eng: 'Once again the old ladies muttered, “That ..., clueless..., worthless ..."',
            },
            {
              Kor: "한 백 호 남짓한 그곳 주민은 전부가 역둔토를 파먹고 살았는데  ",
              Eng: "All the tenants living past suite 100 made their living off of selling … ",
            },
            {
              Kor: "그 썅년!  제년도 낯짝 팔아먹는 주제에  ",
              Eng: "That wretched girl!  Being the likes of a prositute... ",
            },
            {
              Kor: "처음  선생님이 가시고 난 뒤에는 잊어먹어 버린 듯 일체  호랑이 얘길 않더니 ",
              Eng: "Right after the teacher had left, I stopped telling the tiger story altogether as though I'd forgotten all about it.",
            },
            {
              Kor: "한 나무에 열리는 감 같이 따먹으면서 삽시다.  ",
              Eng: "Let's live picking the persimmons together off the same tree.  ",
            },
            {
              Kor: "나라가 그렇게 생겨먹었어 ",
              Eng: "That's how it is in this awful country.  ",
            },
          ],
        },
        {
          entryName: "-어 버린다",
          typicalExample: "【가정으로나 숨어 버리기 전에는】",
          categoryKorean: "Stock phrase",
          categoryEnglish: "(Stock phrase)",
          translation:
            "Does completely, gets it (all) done, disposes of (the job: (does it) all/up/down/away/off; does to one's disappointment or regret; does it to one's relief",
          sentences: [
            {
              Kor: "가정으로나 숨어 버리기 전에는",
              Eng: "before you go hide yourself in a family ...",
            },
            { Kor: "사라져버리고", Eng: "vanished, and ..." },
            {
              Kor: "두 사람은 갑자기 노인으로 변해 버렸다 ",
              Eng: "Suddenly the two men had turned into old men.",
            },
            {
              Kor: "눈 덮인 길의 고랑에 빠져 버렸다 ",
              Eng: "[She] fell into the ditch beside the snow-covered road.",
            },
            {
              Kor: "약삭 빠르게 도망쳐 버렸다 ",
              Eng: "She (totally) escaped in a flash.",
            },
            { Kor: "북북 찢어 버렸다 ", Eng: "I tore it to shreds." },
            { Kor: "던져 버렸다 ", Eng: "[He] threw it (completely) away." },
            {
              Kor: "쓰러져 버릴 것 같았다 ",
              Eng: "It seemed as if (he) would just collapse.",
            },
            {
              Kor: "잠이 들어 버리면 ",
              Eng: "Once [I] fall asleep ... ; Once I conk out …",
            },
            { Kor: "쾅 닫아 버렸다 ", Eng: "[He] slammed the door shut." },
            {
              Kor: "그는 오랫동안 그 작업을 하였다.  그래서 그는 더욱 지쳐 버렸다. ",
              Eng: "He worked on it for a long time and became even more exhausted.  ",
            },
            {
              Kor: "넣어 버린다",
              Eng: "[He] shoves in [more than ten spoonfuls of sugar].",
            },
            {
              Kor: "춤의 신발을 신어 버린 소녀 ",
              Eng: "a girl who has put on her dancing shoes ",
            },
            {
              Kor: "체념해 버렸다 ",
              Eng: "[He] resigned himself completely; [He] gave up completely.",
            },
            { Kor: "안심해 버렸다 ", Eng: "[She] felt relieved (finally)." },
            {
              Kor: "모든 곡식은 바삭바삭 말라 버렸다 ",
              Eng: "All the grains had completely dried up.",
            },
            {
              Kor: "돌아 버렸대",
              Eng: "She just turned around and came back, they say.",
            },
            { Kor: "부숴 버릴 듯이 ", Eng: "as if to smash it completely" },
            {
              Kor: "홱 비켜 지나가 버리는 것이었다",
              Eng: "She would step aside and pass him by.",
            },
            {
              Kor: "딴사람에게 떠넘겨버리는 것이다",
              Eng: "… sold it off to the other person.",
            },
            {
              Kor: "유배당해 버리고 없었다 ",
              Eng: "had suffered a complete banishment and was not there (Note also -어 버리다 does completely, gets it (all) done, disposes of (the job: does it all/up/down/away/off); does to my disappointment or regret; does it to my relief.)",
            },
          ],
        },
        {
          entryName: "-어 보다",
          typicalExample: "【너무 나이가 많아 보았다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Appear to be; look as if; look like. I.e., eqiuivalent to -어 보이다.",
          sentences: [
            {
              Kor: "너무 나이가 많아 보았다 ",
              Eng: "He looked too old. (Here 보- is used for 보이-.)",
            },
          ],
        },
        {
          entryName: "-어 보이다",
          typicalExample: "【지수가 잃어버렸던 것과는 달라 보였다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Appear to be; look as if; look like",
          sentences: [
            {
              Kor: "지수가 잃어버렸던 것과는 달라 보였다.  ",
              Eng: "It looked different from the one that Chisu had lost.",
            },
            {
              Kor: "가까워 보이는 푸른 잔디  ",
              Eng: "the green lawn which looked close by",
            },
            { Kor: "외로워 보여요 ", Eng: "You seem lonel. " },
            {
              Kor: "이미테이션 같아 보여요? ",
              Eng: "It appears to be an imitation.",
            },
            {
              Kor: "네, 꼭 고드름 같아 보여요 ",
              Eng: "Yes, you look just like an icicle.",
            },
            {
              Kor: "서른 댓 되어 보이는 사내였다",
              Eng: "He was a guy that looked to be around thirty five.",
            },
            {
              Kor: "붉은 흙이 질척해 보였다 ",
              Eng: "The red dirt looked muddy.",
            },
            { Kor: "더 나이들어 보였다 ", Eng: "He looked older." },
            {
              Kor: "원체가 혈색이 건강해 보였다 ",
              Eng: "His complexion looked healthy, by nature. ",
            },
            {
              Kor: "굶주림이 있어 보였다",
              Eng: "[Her husband] seemed as if he had [an insatiable] hunger.",
            },
            {
              Kor: "정육점 같아 보였다 ",
              Eng: "[The bathroom] looked like a butcher shop.",
            },
            { Kor: "바보 같아 보였다", Eng: "[He] looked like an idiot." },
          ],
        },
        {
          entryName: "-어 본다",
          typicalExample: "【고놈을 입에 대보고 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Exploratory pattern in -어 보- does it to see what it's like; does it to see what happens has a go at ...-ing; tries ...-ing.",
          sentences: [
            {
              Kor: "고놈을 입에 대보고 ",
              Eng: "brought the little fellow up to my mouth, and … (← 대어 보- to touch to, bring into contact with.)",
            },
          ],
        },
        {
          entryName: "-어봤자 ",
          typicalExample: "【가봤자......아는 이두 없을 거요】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Even assuming one did it ...; even supposing ...",
          sentences: [
            {
              Kor: "가봤자......아는 이두 없을 거요",
              Eng: "Even if I went, I wouldn't know anyone. ",
            },
            {
              Kor: "그래봤자 지가 똥갈보라 ",
              Eng: "No matter what she does, she's still a prostitute.  ",
            },
          ],
        },
        {
          entryName: "-어 뵈다 ",
          typicalExample: "【그 어려 뵈는 죄수의 손에】",
          categoryKorean: "Dialect morphology/Substandard ",
          categoryEnglish: "(Dialect morphology/Substandard )",
          translation:
            "Dialect/substandard for -어 보이- seem like, seem to be. ",
          sentences: [
            {
              Kor: "그 어려 뵈는 죄수의 손에",
              Eng: "in the hands of the young-looking prisoners ...",
            },
          ],
        },
        {
          entryName: "-어 빠진다",
          typicalExample:
            "【아버지가 물려준 못나 빠진 야산이 돌산이라 떼부자가 되었다.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Gets/becomes, through and through; utterly, quite, thoroughly, to the core, all the way.",
          sentences: [
            {
              Kor: "아버지가 물려준 못나 빠진 야산이 돌산이라 떼부자가 되었다.",
              Eng: "Because the scraggly piece of shit hill his dad left him turned out to be a quarry, he had become rich overnight.",
            },
            {
              Kor: "서랍을 뒤지다가 말라빠진 담배꽁초를 발견했다",
              Eng: "While rummaging through the drawers, he found a dried out cigarette butt.",
            },
            {
              Kor: "내가 어디서 굴러온 줄도 모르는 말라빠진 음악선생 ",
              Eng: "the bony teacher that showed up for me from who-knows-where ...",
            },
            {
              Kor: "그년두 육개월이면 찬샘 바닥서 진이 모조리 빠진 거예요. ",
              Eng: "That year, all the gin from Chansem was smuggled out within six months.",
            },
            {
              Kor: "어느 상점의 스피커에서는 느려 빠진 유행가가 흘러나왔다  ",
              Eng: "A very slow pop song could be heard from the speakers of one of the stores.  ",
            },
            { Kor: "아, 이 약아빠진 놈아  ", Eng: "You sly, sneaky jerk!" },
            {
              Kor: "허구한 날 시키는 일은 안 허고 잘나빠진 마술이나 부리겠다고? ",
              Eng: "You're telling me that you're going to waste time on stupid magic tricks instead of doing work that I assign?  ",
            },
            {
              Kor: "아버지 앞에서 나는 언제나 맥없이 나자빠진 패자에 지나지 않았다.",
              Eng: "I never was more than a weak, defeated failure to my father.",
            },
          ],
        },
        {
          entryName: "-어(서) 【늦어서~】",
          typicalExample: "【낡은 솜이 돼 그런지 = 낡은 솜이 되어서 그런지】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "'Naked' infinitive in -어 or -아 where particle 서 has been deleted. Whenever you encounter an infinitive form in 해, -어/-아/-여/-이라 like this in mid-sentence, it is usually safe to expand it as 해서, -어서/-아서/-여서/-이라서. ",
          sentences: [
            {
              Kor: "낡은 솜이 돼 그런지 = 낡은 솜이 되어서 그런지",
              Eng: "Perhaps because it was (had become) old cotton.",
            },
            {
              Kor: "눈이 뻘개 물었다 = 눈이 뻘개서 물었다",
              Eng: "lit.: His eyes having turned red, he asked.",
            },
            {
              Kor: "속아 떨어진 것이었다 = 속아서 떨어진 것이었다",
              Eng: "lit.: It had been a case of being cheated and taken in.",
            },
            {
              Kor: "숨죽여 가늘게 떨린다",
              Eng: "… die out and flutter thinly.",
            },
            {
              Kor: "가득 채워 나왔는데 ",
              Eng: "filled up to the top, but ...",
            },
            {
              Kor: "그의 안색이 불안스러워 내가 물었다 ",
              Eng: "I asked because his face looked so anxious.",
            },
            { Kor: "소리내어 웃었다 ", Eng: "He laughed out loud." },
            {
              Kor: "능선 마루에 앉아 사방을 살폈다",
              Eng: "We sat at the clearing on the ridge and looked around.",
            },
            {
              Kor: "죽지 못해 사는 것이었다",
              Eng: "It was a case of living because he couldn't die.",
            },
            {
              Kor: "지방공무원 채용시험에 응시하여 합격하는 일이 그다지 어려운 일은 아니었지만 ",
              Eng: "Although it wasn't that hard to pass the exam to be a public service worker ... ",
            },
            {
              Kor: "지난 3월에 뵈었을 때보다도 백발이 더 성성하여 순간적으로 나는 콧마루가 찡하니 아파왔다. ",
              Eng: "There was a lot more grey hair than when I had seen him in March and at that moment I felt a pang of sadness. ",
            },
            {
              Kor: "불의의 사고를 당하여 고통 속에서 와르르 무너져내린 아버지의 모습을 기대하고 있었다 ",
              Eng: "I was looking forward to seeing my father crushed by the pain from an unexpected accident.",
            },
            {
              Kor: "제법 자리잡혀 나가서",
              Eng: "I went out and secured myself a decent position ...",
            },
            { Kor: "떨어져 내렸다", Eng: "fell down" },
            { Kor: "흔들어 깨우셨다 ", Eng: "… shook me awake." },
            {
              Kor: "강단은 난방이 안 되어 추웠다",
              Eng: "Since the auditorium wasn't heated, it was cold.",
            },
            {
              Kor: "다투어 밀리고 있었다",
              Eng: "[The people were] jostling each other [as they pushed toward the entrance].",
            },
            {
              Kor: "자리는 반도 차지 않아 더욱 썰렁하고 ",
              Eng: "It was all the more chilly with not even half of the seats filled …",
            },
            {
              Kor: "누군가 다가와 말했다 ",
              Eng: "Someone approached and said ...",
            },
            { Kor: "코에 와 닿았다", Eng: "… reached (her) nose." },
            {
              Kor: "노인을 안으로 청해 들였다",
              Eng: "… invited the old man in.",
            },
            {
              Kor: "꼭 젊은 일꾼들이 앓아 누웠다 ",
              Eng: "The young workers [without exception] fell sick and lay in bed.",
            },
            {
              Kor: "산에 가 나무를 해지고",
              Eng: "… went into the mountains, got firewood and ...",
            },
            {
              Kor: "낙엽 속에 파묻혀 앉아 ",
              Eng: "The fallen leaves were nestled into the mountain ...",
            },
            {
              Kor: "중실은 벌떡 뛰어 일어났다",
              Eng: "Chungsil jumped to his feet. ",
            },
            {
              Kor: "나뭇가지에 익어 시든 아그배",
              Eng: "crab-apples, fully ripened and wilting on the trees ...",
            },
            {
              Kor: "불 붙는 곳으로 끌려 들어갔다",
              Eng: "He dragged himself into the place where the fire was.",
            },
            {
              Kor: "나머지 등걸불을 발로 비벼 끄니 ",
              Eng: "after putting out the left over stump fire with his foot, ...",
            },
            {
              Kor: "입이 뒤바뀌어 수효가 틀려지면",
              Eng: "If his tongue would twist and his counting would go off, then ...",
            },
            {
              Kor: "펑퍼짐한 산허리를 일궈 밭을 만들고",
              Eng: "He'll cultivate the softly curving mountain side and create rice patties, and ... ",
            },
            {
              Kor: "내게서 프로그램을 빌어 갔다",
              Eng: "… borrowed and took off with my program. [This example should not be confused with the pattern in -어 가다 gradually (goes on doing); keeps growing (getting to be, becoming)].",
            },
            {
              Kor: "마을 사람들은 산에 가 나무를 해다 며칠에 한 번씩 장거리로 지고 나갔다. ",
              Eng: "They went to the mountains to gather firewood and every few days they carried it to the marketplace.",
            },
            {
              Kor: "영을 내려와 비로소 학이 돌아온 것을  본 이장영감과 박 훈장은 얼싸안고 엉엉 울 었다. ",
              Eng: "Not until they had come down from the ridge did Mr. Lee and Mr. Park see the returning crane, throw themselves into each other's arms and weep. ",
            },
            {
              Kor: "우리의 그림자에 놀라 흩어져 버렸다",
              Eng: "Startled at our shadows, they scattered.",
            },
            {
              Kor: "수모도 받아 싸지",
              Eng: "It serves us right to be scorned ...",
            },
            {
              Kor: "불을 쪼여 담배를 피우니 ",
              Eng: "holding his body up to the fire and smoking his cigarette ... (From 쪼이다 = 쬐다.)",
            },
            {
              Kor: "골짜기로 타내려갔다 ",
              Eng: "The flames burned down into the valley.",
            },
            {
              Kor: "아직 추운 삼월이라  불에 둘러앉은 젊은이들은...",
              Eng: "Since it was still the cold month of March, the group of young people sitting around the fire... ",
            },
            {
              Kor: "높은 산등이라 하늘이 가까우련만 마을에서 볼 때와 일반으로 멀다.",
              Eng: "From the high mountain ridge the sky seemed so close compared to the everyday view from the village.  ",
            },
            {
              Kor: "이미 죽은 후의 고기라 중실은 그것을 짊어지고 산으로 돌아갔다. ",
              Eng: "Since it was already dead meat Chungshil carried it on his shouldar and headed back towards the mountain.",
            },
            {
              Kor: "한참 쉬인 뒤라 팔에는 기운이 남았다.",
              Eng: "He still had energy in his arms because it was after he had a proper rest. (Note also old spelling at 쉬이다 for what is now spelled 쉬다.)",
            },
            {
              Kor: "나는 하도 오랜만에 맡아보는 술내음이라 달아날 수가 없었다.",
              Eng: "I couldn't get away because it had been such a long time since I had last smelled alcohol.",
            },
            {
              Kor: "워낙 관광객이 드문 한사(寒寺)라  ",
              Eng: "Because it was such a cold temple bereft of visitors ...",
            },
          ],
        },
        {
          entryName: "-어서가 아니다",
          typicalExample: "【시장이 반찬이어서가 아니었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Sequential -어서 negated before 아니다.",
          sentences: [
            {
              Kor: "시장이 반찬이어서가 아니었다 ",
              Eng: "It wasn't because he was starving.",
            },
            {
              Kor: "사촌이 논을 사서가 아니라 거들먹거리는 품이 아니꼬워서다. ",
              Eng: "It wasn't because the proverbial cousin had bought a paddy, but because the way they swaggered made me want to puke.",
            },
          ],
        },
        {
          entryName: "-어서는",
          typicalExample: '【"거기 가서는 뭐 주인 행세하고 살 수 있대요?"】',
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Sequential -어서는 or -어선 does and then ...",
          sentences: [
            {
              Kor: '"거기 가서는 뭐 주인 행세하고 살 수 있대요?"',
              Eng: '"Does he say he can go over there and live life as his own boss?"',
            },
            {
              Kor: "집에 들어와서는 어멈 아범한테 매맞기가 일이요 하였다",
              Eng: "Going home and getting a thrashing from my parents was my job. ",
            },
            {
              Kor: "몇 해 지나지 않아서는 창동(倉洞) 근처에 땅을 장만하기 시작하였다 ",
              Eng: "Before a few years had passed, they started to prepare the land near Ch’angdong. ",
            },
            {
              Kor: "머리채를 질질 끌구 마당에 나와선 차구 짓밟구.....",
              Eng: "He dragged him by the hair out to the yard and kicked and stepped on him...",
            },
            {
              Kor: "이번에 추석 지내선 집을 내주게 됐다",
              Eng: "It's come about that we have to sell the house after ['passing/celebrating'] this Ch'usŏk.",
            },
            {
              Kor: "그러나 원산까지 와서는 벌써 두 여름이 되는 동안",
              Eng: "In the two summers that already passed since she had made it as far as Wŏnsan …",
            },
            {
              Kor: "나가서는 돌아오지 않았다 ",
              Eng: "… left and then didn't return.",
            },
            {
              Kor: "이 학마을을 떠나서는 살 수 없을 것만 같았던 것이었다 ",
              Eng: "It seemed that if he left Crane Village he wouldn't be able to live anywhere else.",
            },
          ],
        },
        {
          entryName: "-어서는 안 된다",
          typicalExample:
            "【그렇지만 그 남자는 절대로 그 여자를 건드려서는 안 돼.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "One must not do, one shouldn't do",
          sentences: [
            {
              Kor: "그렇지만 그 남자는 절대로 그 여자를 건드려서는 안 돼.  ",
              Eng: "But he must not touch her under any circumstances.",
            },
            {
              Kor: "감히 누려서는 안 될 행운처럼 여겨지는 것이었다.",
              Eng: "It was the case that it was considered by her like/as some good fortune that she dare not enjoy.",
            },
            {
              Kor: "소리를 질러서는 안 돼. ...그리고 그를 만져서도 안 돼. ",
              Eng: "I mustn‘t cry out ... And I mustn’t touch him, either.",
            },
            {
              Kor: "더 이상 얌전해서는 안된다 ",
              Eng: "He must not be any more gentle [than he was until now].",
            },
            {
              Kor: "있지 않아선 안 될 것이다",
              Eng: "I was going to have to have [a hope of my own].",
            },
            {
              Kor: "그 옛날 탄실이어서는 안 된다 했다 ",
              Eng: "He said to himself that [Pongne] should not become like the T'ansil of old.",
            },
            {
              Kor: "텅 빈 것이 되어서는 안 된다",
              Eng: "[Togi's life] should not be something empty. ",
            },
            {
              Kor: "젊은이들은 술을 마셔서는 아니 된다는 것 ",
              Eng: "the fact that youngsters were not allowed to drink ... (The negative adverb 아니 is the older form of 안, and here makes this injunction sound somewhat archaic, austere or solemn.)",
            },
            {
              Kor: "끌려가서는 안된다는 생각 밖에 없었다 ",
              Eng: "The only thought [Kilsu had] was that he mustn't be dragged away.  ",
            },
          ],
        },
        {
          entryName: "-어(서) 다행이다",
          typicalExample: "【영화, 네가 있어 다행이다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-어(서) 다행이다 good thing that ... ; lucky thing that ... —with naked infinitive in -어: 있어(서).",
          sentences: [
            {
              Kor: "영화, 네가 있어 다행이다.  ",
              Eng: "Yŏnghwa, good thing you're here.",
            },
          ],
        },
        {
          entryName: "-어서도",
          typicalExample: "【들판에 척 걸터앉아서도 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-어서도: even after ...-ing; even though/while it might be ... ",
          sentences: [
            {
              Kor: "들판에 척 걸터앉아서도 ",
              Eng: "even after he plopped himself down in the field ...",
            },
            { Kor: "자리에 누워서도 ", Eng: "even after he had gone to bed …" },
            {
              Kor: "거추장스러워서도 잘 주고 갔다",
              Eng: "Bothersome though it was, they would give it to him.",
            },
            {
              Kor: "그리고 이 바람 부는 겨울에 들판에 척 걸터앉아서도 만사 태평인 꼴이었다. ",
              Eng: "Even as he was plopped down on the field in the windy winter weather, it seemed like all was well with the world. ",
            },
          ],
        },
        {
          entryName: "-어서도 안 된다",
          typicalExample:
            "【소리를 질러서는 안 돼. ...그리고 그를 만져서도 안 돼.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "-어서도 안 된다: and one mustn't . . . , either",
          sentences: [
            {
              Kor: "소리를 질러서는 안 돼. ...그리고 그를 만져서도 안 돼.  ",
              Eng: "I mustn‘t cry out ... And I mustn’t touch him, either.",
            },
          ],
        },
        {
          entryName: "-어서라도",
          typicalExample: "【군화까지 팔아서라두 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Sequential -어서 by way of doing plus pseudo-particle -(이)라도: (1) even if (it be), (2) just, (or the) like.",
          sentences: [
            {
              Kor: "군화까지 팔아서라두 ",
              Eng: "[It was to the extent that, in order to drink] they would even go so far as to sell their army boots.",
            },
            {
              Kor: "기어서라도 스위치 있는 쪽으로 가리라고 결심했다",
              Eng: "He decided to go towards the light switch, even if it meant having to crawl. ",
            },
            {
              Kor: "걸어서라도 딸을 찾아 ",
              Eng: "[He] thought he would find his daughter, even if he had to go on foot.",
            },
            {
              Kor: "목이 타서라도 술을 안 마실 수가 없었다.",
              Eng: "There was no way not to drink, even if it meant our throats burning up.",
            },
          ],
        },
        {
          entryName: "-어서만 ",
          typicalExample: "【다시 매부 집에 가서만 살게 되자 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Sequential -어서 followed by delimiter 만: only after having ...",
          sentences: [
            {
              Kor: "다시 매부 집에 가서만 살게 되자 ",
              Eng: "only after having gone to live with the brother-in-law ...",
            },
            {
              Kor: "무쇠같은 그의 가슴에 첫사랑이 뻘겋게 달아오르던 곳이래서만은 아니었다.  ",
              Eng: "It wasn't only because this was where his cast iron heart had burned bright red from his first love.",
            },
          ],
        },
        {
          entryName: "-어서부터 ",
          typicalExample: "【어려서부터 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Since, ever since ... Cf. also 젊어서부터.",
          sentences: [
            { Kor: "어려서부터 ", Eng: "ever since I was little ..." },
            {
              Kor: "덕이는 어려서부터 봉네가 좋았다",
              Eng: "Ever since he was young, Togi had liked Pongne.",
            },
            {
              Kor: "밤고구마를 먹지 못하게 된 것은 아버지가 돌아가시고 나서부터였다.  ",
              Eng: "It was after my father's death that I stopped eating chestnuts and sweet potatoes.",
            },
          ],
        },
        {
          entryName: "-어서(이)다",
          typicalExample: "【아냐, 남자한테서 채여서야 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "It is because ... ",
          sentences: [
            {
              Kor: "아냐, 남자한테서 채여서야 ",
              Eng: "Naw, it's because she was snatched away from some guy.",
            },
            {
              Kor: "밤새로 한 두시쯤 되어서다 ",
              Eng: "It was about [the time when it became] two a.m. ",
            },
            {
              Kor: "미스 김 누나가 있어서다 ",
              Eng: "It is because Miss Kim was there.",
            },
            {
              Kor: "오래 전에 헤어진 남자가 찾아와서였다",
              Eng: "It was because a man with whom I had broke up long ago came to see me.",
            },
            {
              Kor: "불리를 두려워해서였습니다 ",
              Eng: "Because they were afraid of being at a disadvantage ...",
            },
            {
              Kor: "생각이 있어서였다 ",
              Eng: "It was because the thought arose.",
            },
            {
              Kor: "빈 속이어서일까 ",
              Eng: "Maybe it was because I was [drinking on an] empty stomach?",
            },
            {
              Kor: "날씨가 나빠서인지 ",
              Eng: "perhaps it was because the weather was bad; it was probably because the weather was bad ",
            },
            {
              Kor: "자선음악회라는 것을 감안해서인지 비교적 대중적인 것들이었다",
              Eng: "The pieces (of music) were relatively popular ones, considering that it was a charity benefit concert.  ",
            },
            {
              Kor: "실제로는 한 번도 본 적이 없어서일지 모른다.",
              Eng: "In actual fact, it may be because I had never seen it even once.",
            },
          ],
        },
        {
          entryName: "-어서야 ",
          typicalExample: "【그날 밤도 나는 자정이 다 되어서야 집에 왔다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Only after ..., only after having done.",
          sentences: [
            {
              Kor: "그날 밤도 나는 자정이 다 되어서야 집에 왔다.  ",
              Eng: "That night, too, I didn't come home until after midnight.",
            },
            {
              Kor: "병원에 와서야 집에 전화를 해서",
              Eng: "only after getting to the hospital did she call home, and then …",
            },
            {
              Kor: "이거 원 날씨가 이렇게 추워서야 ",
              Eng: "Geez, it's so cold ... ; or, I can't go out in this cold! ",
            },
            {
              Kor: "저녁 때가 거의 되어서야 ",
              Eng: "only after it was almost evening ...",
            },
            {
              Kor: "색시 많이 두면 공연히 번거러워요. 이런 데서야 반반한 애 하나면 실속이 있죠. ",
              Eng: "If you have too many wives it becomes more troublesome for nothing. It is best to just get a comely looking girl from a place like this.  ",
            },
            {
              Kor: "고개를 한참 뒤로 젖혀서야 꼭대기가 보이는 맘보빌딩. ",
              Eng: "Only when you tilt your head back quite a ways do you see the top of the Mambo Building. ",
            },
            {
              Kor: "그제서야 그는 잠이 들었다.",
              Eng: "At that moment, he finally fell asleep.",
            },
            {
              Kor: "되풀이 되풀이 떠올리고 나서야 겨우 조금 놓여날 수 있었다. ",
              Eng: "Only after I thought about it again and again did I start to feel a little relieved.",
            },
            {
              Kor: "저녁 때가 거의 다 되어서야 그들은 산에서 내려왔다.",
              Eng: "It was almost night when they came down from the mountain.",
            },
          ],
        },
        {
          entryName: "-어(도) 싸다",
          typicalExample: "【빌어먹어 싼 첨지 】",
          categoryKorean: "욕 Epithet",
          categoryEnglish: "(욕 Epithet)",
          translation:
            "Epithet pattern in -어(도) 싸- lit.: even if you..., it's cheap/getting off cheaply. I.e., ...-ing is too good for you.",
          sentences: [
            {
              Kor: "빌어먹어 싼 첨지 ",
              Eng: "You good-for-nothing begging old bum.",
            },
            {
              Kor: "수모도 받아 싸지 ",
              Eng: "It serves us right to be scorned ...",
            },
          ],
        },
        {
          entryName: "-어 쌓는다 ",
          typicalExample: "【노파는 여전히 도리질을 해 쌓았다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Does to a great extent, extremely; does(or is) to a sufficient extent, more than enough, ample; does (or is) in throngs, in great/frequent numbers; does repeatedly, does (all too) often; does to excess (and much to my irritation). ",
          sentences: [
            {
              Kor: "노파는 여전히 도리질을 해 쌓았다 ",
              Eng: "The old woman was still constantly shaking her head from side to side.",
            },
            {
              Kor: "마당에 쌓아놓은 장작 밑에서",
              Eng: "Beneath the firewood piled on the verandah...",
            },
            { Kor: "바다에 방둑을 쌓아 놓구.  ", Eng: "levee/dyke" },
            {
              Kor: "벌레들을 쓸어모아 그의 알몸 위에 쌓아올렸다 ",
              Eng: "I swept up all the bugs and piled them on top of his naked body.",
            },
          ],
        },
        {
          entryName: "-어야",
          typicalExample: "【무슨 그루터기가 있어야 비비지!】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "Infinitive -어 + particle 야: only if (marks a reinforced contingency or prerequisite for a main clause, one or the other of which is unlikely, unexpected, or anticipated to be difficult or unpleasant).",
          sentences: [
            {
              Kor: "무슨 그루터기가 있어야 비비지!",
              Eng: "Proverb (lit.): You have to have some sort of tree-stump if you want to rub against something, i.e., One needs a foundation, opportunity or excuse if one is to achieve self-gratification.",
            },
            {
              Kor: "찬샘까진 가야 밥술이라두 먹게 될 거요",
              Eng: "Only if we go all the way to Ch'ansaem will we be able to eat.",
            },
            {
              Kor: "자정이 다 되어야 퇴근하기 때문이다.",
              Eng: "It's because I can't knock off work until midnight.",
            },
            {
              Kor: "저 산을 넘어야 찬샘골인데",
              Eng: "Only by crossing that mountain will we reach Ch'ansaem ...",
            },
            {
              Kor: "세 번쯤 비누칠을 해서 닦아 내야 개운할 습관이었으나 대강대강 비누칠을 하고 물기를 닦았다 ",
              Eng: "It was habitual for her to feel clean only after soaping down three times, she only roughly soaped her hands and washed it off.  ",
            },
            {
              Kor: "장하게 해야 말이지 = 장하게 해야 [돼] 말이지",
              Eng: "You better do [the funeral] in grand style, ya hear?",
            },
            {
              Kor: "낭중 커야 알지요! ",
              Eng: "Once it grows up, we'll know!(Note also dialect/substandard 낭중에 for 나중에.)",
            },
            {
              Kor: "여름 장마 때나 돼야 물이 흐르다가 마는 이름만 개울이지",
              Eng: "It's called a pond but the only time it actually is one is when there is a temporary burst of water flow during the summer rainy spells.",
            },
          ],
        },
        {
          entryName: "-어야 비로소",
          typicalExample: "【여섯 달이 지나야 비로소  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            '-어야 비로소: only after/only when ... does something finally happen. The 비로소 strengthens/reinforces the contingency of -어야 "only if," i.e., then finally, at last.',
          sentences: [
            {
              Kor: "여섯 달이 지나야 비로소  ",
              Eng: "only after six months had passed, did [for the first time]...",
            },
            {
              Kor: "수준에 올라야 비로서 ",
              Eng: "not till you rise to this level do you ...",
            },
          ],
        },
        {
          entryName: "-어야 쓴다 ",
          typicalExample: "【문견이 넓어야 쓰는 것인데】",
          categoryKorean: "관용구/Dialect",
          categoryEnglish: "(관용구/Dialect)",
          translation:
            "Have to pattern in -어야 쓰-. This is a dialect equivalent of standard -어야 한다. ",
          sentences: [
            {
              Kor: "문견이 넓어야 쓰는 것인데",
              Eng: "One's experience must be broad …",
            },
          ],
        },
        {
          entryName: "-어야만 ",
          typicalExample: "【우체국에 와야만 걸 수 있는 전화니까】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "The only if pattern in -어야 reinforced by delimiter 만: only if one ...; the only option is for one to ... ",
          sentences: [
            {
              Kor: "우체국에 와야만 걸 수 있는 전화니까",
              Eng: "It's a phone call you can only make if you came to the post office ...",
            },
            {
              Kor: "그저 몇천 번이라도 바늘을 돌려야만 겨우 교정되는 시계였으므로 ",
              Eng: "Since the time on the clock could be corrected only after you spun the needle a few thousand times …  ",
            },
            {
              Kor: "그런 얕은 바다를 몇백 리나 밖으로 나가야만 ",
              Eng: "Only if you go as many as several hundred li from the ocean will the horizon finally be seen.",
            },
            {
              Kor: "사람은 늙어야만 죽는 줄 알았는데 ",
              Eng: "I thought, one has to be old before one dies ...",
            },
          ],
        },
        {
          entryName: "-어야만 한다",
          typicalExample: "【그렇게 매일 같이 마셔야만 해요?  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-어야만 한다: use of particle 만 to emphasize the have to pattern in -어야 하-, rendering absolutely has to, absolutely must. I.e., the have to/must pattern in –어야 한다 strengthened with 만. ",
          sentences: [
            {
              Kor: "그렇게 매일 같이 마셔야만 해요?  ",
              Eng: "Do you really have to drink like this every day?",
            },
            {
              Kor: "꼭 해야만 하는 가족의 시중에 밀려",
              Eng: "caught up in all the family matters that absolutely have to be looked after",
            },
            {
              Kor: "애정을 반드시 저와같이 전시해야만 할까 ",
              Eng: "Do they absolutely have to display their affection like that?",
            },
            {
              Kor: "꼭 그러한 방법을 취해야만 한다는 것은 아니나 ",
              Eng: "Of course it is not the case that he absolutely must choose this method ...",
            },
            {
              Kor: "비를 맞아야만 했다 ",
              Eng: "[The boy] had no choice but to get rained on.",
            },
            {
              Kor: "제일 나이 많은 남자가 이장 일을 보아야만 했고 ",
              Eng: "The oldest male should assume the role of village head, and ...",
            },
            {
              Kor: "모든 새로운 타입의 인물을 붙잡아야만 할 것이다 ",
              Eng: "I must cling to the same new type of people. ",
            },
          ],
        },
        {
          entryName: "-어야지",
          typicalExample:
            "【무슨 수를 내야지 매일 아침 회사 들어가기가 죽기만큼 싫다.】",
          categoryKorean: "해체의 종결 어미 Final ending in Intimate Style",
          categoryEnglish: "(해체의 종결 어미 Final ending in Intimate Style)",
          translation:
            "-어야지 ← -어야 [하]지: has to/must do [of course/don't ya know] . In other words, the have to/must pattern in -어야 한다 combined with the pattern in -지(요) yields -어야 하지요has to/must [of course, don't you know, etc.] . This pattern, in turn, can undergo contraction by deleting the 하- of 하지(요), yielding the highly colloquial pattern in -어야지요, sometimes spelled -어야죠. A similar contraction is also widespread for the pattern in -어야 하겠다shall have to, probably ought to, which contracts to -어야겠다, -어야겠지(요), etc. In our example here, this pattern overlaps with Gentle Rebuke pattern in -지(요) (그래요) Why don't you ... ? or Shouldn't you ... ?  This latter pattern has one of two intonations: a) In mid-sentence, it has the suspensive, I'm-not-finished-yet intonation of the Imminent Elaboration pattern in -(으)ㄴ데/-는데. b) Sentence-finally or rounded off with 그러- (그래요, 그러세요, 그러시지요, etc.) the intonation is not much different from the -지(요) pattern in which it originates, but here the intonation can sound rather reproachful.",
          sentences: [
            {
              Kor: "무슨 수를 내야지 매일 아침 회사 들어가기가 죽기만큼 싫다.",
              Eng: "I've got to come up with something—I hate going to the office every morning like death itself.",
            },
            {
              Kor: "나야 뭐 하는 일이 있어야지 ",
              Eng: "if only I had something to do ...",
            },
          ],
        },
        {
          entryName: "-어야 할 ",
          typicalExample: "【빙산이라고 해야 할 얼음 덩어리】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "-어야 할 NOUN: a NOUN which one must (should, has got to, ought to) do.",
          sentences: [
            {
              Kor: "빙산이라고 해야 할 얼음 덩어리",
              Eng: "a lump of ice you would have to call an iceberg",
            },
          ],
        },
        {
          entryName: "-어야 할 텐데",
          typicalExample:
            "【도마뱀의 꼬리가 그의 눈에 띄지 않아야 할 텐데.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-어야 할 텐데: I sure hope that...; it better (not) turn out that...",
          sentences: [
            {
              Kor: "도마뱀의 꼬리가 그의 눈에 띄지 않아야 할 텐데.  ",
              Eng: "I just hope the lizard's tail doesn't catch his eye.",
            },
          ],
        },
        {
          entryName: "-어 온다",
          typicalExample: "【밝아 오는 나뭇잎 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Gradually comes in doing; comes up (along); starts (has started) becoming/doing; has/had been doing. ",
          sentences: [
            {
              Kor: "밝아 오는 나뭇잎 ",
              Eng: "leaves getting/looking brighter ",
            },
            {
              Kor: "나를 서서히 압도해 오는 ",
              Eng: "… gradually overwhelming me",
            },
            {
              Kor: "딸의 눈치만 보아 오다 = 보아 오다가 ",
              Eng: "after having kept close tabs on his daughter's mood and likely reaction for a while …",
            },
            {
              Kor: "점심때가 가까워 오는데 ",
              Eng: "It was nearing lunchtime ...",
            },
            {
              Kor: "숲에서 들려 오는",
              Eng: "the [sounds] approaching from the forest ",
            },
            {
              Kor: "밝아오는 아침 햇볕",
              Eng: "the gradually brightening morning sunlight ",
            },
            {
              Kor: "목교를 건너오는 여교사",
              Eng: "the female teacher, coming toward me across the bridge",
            },
            {
              Kor: "교장으로 근무해 온 아버지 ",
              Eng: "my father, who proceeded until now to work as a school principal",
            },
            {
              Kor: "몸이 차차 푸근하여 온다 ",
              Eng: "His body is slowly getting comfortable and downy.",
            },
            {
              Kor: "두 달이 가까와 오는데 ",
              Eng: "It had gotten two months closer ...",
            },
            {
              Kor: "바람결에 풍겨 온가? ",
              Eng: "Is it scattered by the wind?",
            },
            {
              Kor: "늙어 온 한 노인이 있다",
              Eng: "There is an old man who grew old.",
            },
            {
              Kor: "울컥 비위에 거슬려 왔다 ",
              Eng: "It made me feel sick to my stomach.",
            },
            {
              Kor: "어깨가 자꾸 무거워 왔다 ",
              Eng: "His shoulders got heavier and heavier (i.e., He felt more and more burdened with responsibility).",
            },
            {
              Kor: "관심의 세계에 참여해 왔다 ",
              Eng: "lit.: He slowly began to participate in my world of interests (i.e., He began to fascinate me)",
            },
            {
              Kor: "내 전신을 흥건히 적셔 왔던 것이다",
              Eng: "My entire body was gradually being drowned.",
            },
            {
              Kor: "심서가 불안해져 왔다 ",
              Eng: "My thoughts were getting more and more troubled.",
            },
            {
              Kor: "벌써 오래 전부터 갈망해 왔는지도 모른다 ",
              Eng: "It may be the case that I have been long engaged in coping with [this image of my father].",
            },
            { Kor: "꿈꾸어 왔어요 ", Eng: "I've always dreamed ..." },
            {
              Kor: "삼년 동안 살아왔지만",
              Eng: "[We] have been living here for three years but ...",
            },
            {
              Kor: "아버지를 부축하여 숙소로 모실 때 약간씩 체중을 기대어 왔을 뿐",
              Eng: "He only began leaning slightly on me as I gave him an arm and helped him to the inn …",
            },
            {
              Kor: "개 짖는 소리가 들려왔다 ",
              Eng: "The sound of a dog barking came ringing in from somewhere.",
            },
            {
              Kor: "시계 소리가 나직히 들려왔다",
              Eng: "The sound of the clock was softly heard. ",
            },
            { Kor: "불티가 픽 날아왔다 ", Eng: "The embers came flying." },
            {
              Kor: "향기가 번뜩번뜩 풍겨 왔다",
              Eng: "The fragrance spread in spurts.",
            },
            {
              Kor: "그같은 타입의 남자를 여자는 경멸해 왔었다",
              Eng: "The woman had always despised men of that type.",
            },
            {
              Kor: "눈여겨 살피더니 말을 걸어왔다",
              Eng: "... took a closer look at them and began to talk.",
            },
          ],
        },
        {
          entryName: "어인 ",
          typicalExample: "【어인 일인지 】",
          categoryKorean: "Dialect morphology/Substandard ",
          categoryEnglish: "(Dialect morphology/Substandard )",
          translation: "Dialect/substandard for 웬, i.e., 웬 일인지. ",
          sentences: [
            {
              Kor: "어인 일인지 ",
              Eng: "for some reason or other (or, he knew not why)",
            },
          ],
        },
        {
          entryName: "-어 있다",
          typicalExample: "【무엇인가 붙어 있는 것을 발견했다】",
          categoryKorean: "Dialect morphology/Substandard ",
          categoryEnglish: "(Dialect morphology/Substandard )",
          translation:
            "Resultant state in -어 있다: is in the state of resulting from...",
          sentences: [
            {
              Kor: "무엇인가 붙어 있는 것을 발견했다",
              Eng: "[He] discovered something stuck to [the mirror].",
            },
            {
              Kor: "여러 채 붙어 있었다",
              Eng: "Several of them [drinking establishments] were crowded together.",
            },
            { Kor: "얼어붙어 있었다 ", Eng: "It was [stuck] frozen." },
            {
              Kor: "강물은 꽁꽁 얼어붙어 있었다 ",
              Eng: "The river was frozen solid.",
            },
            { Kor: "비어 있었다 ", Eng: "[The bathtub] was empty." },
            {
              Kor: "열람실도 거의 비어 있었다 ",
              Eng: "The reading room also was (in a state resulting from being) empty.",
            },
            {
              Kor: "뻐스 안의 좌석들은 많이 비어 있었다",
              Eng: "Many of the seats in the bus were empty.",
            },
            {
              Kor: "명시되어 있는 시계 ",
              Eng: "the clock with [date and day 'in a state resulting from having been'] marked",
            },
            { Kor: "고용되어 있었다 ", Eng: "… was employed." },
            {
              Kor: "이제는 늙고 힘없는 산이 돼 있음을 알았다 ",
              Eng: "Now I understood that the mountain had become old and feeble.",
            },
            {
              Kor: "바람은 무수히 작은 입자(粒子)로 되어 있고 ",
              Eng: "The wind was made up of countless tiny particles, and … (lit.: The wind was in a state resulting from being made up of countless tiny particles, and …)",
            },
            {
              Kor: "완전히 잿더미가 돼 있었다 ",
              Eng: "It had all turned to ashes.",
            },
            {
              Kor: "똑같은 입장이 되어 있었다 ",
              Eng: "[In no time at all] Mr. Chŏng was in the same position as Yŏngdal.",
            },
            {
              Kor: "그럴 조건이 되어 있는 것도 아닙니다",
              Eng: "Nor is it the case that the conditions are right for doing that.",
            },
            {
              Kor: "전무님이 되어 있을 게 아니예요?",
              Eng: "Won't you already be the managing director?",
            },
            {
              Kor: "방아실에 숨어 있었다 ",
              Eng: "[He] was hiding in the mill room.",
            },
            {
              Kor: "무진의 골방 속에 숨어 있었다 ",
              Eng: "I was hiding out in the small room in Mujin.",
            },
            {
              Kor: "방을 얻어 있던 집이 있으니까",
              Eng: "because the room that I used to rent is there ...",
            },
            {
              Kor: "가을은 우리가 떠나온 곳보다 더욱 깊어 있었다 ",
              Eng: "Autumn was much deeper than the place we had left.",
            },
            {
              Kor: "여전히 술에 취해 있었다",
              Eng: "As usual, he was in a state of drunkenness.",
            },
            {
              Kor: "풍성하던 맛은 한결 가셔 있었다 ",
              Eng: "The taste of abundance was even more rinsed away.",
            },
            {
              Kor: "완전하게 비켜나 있는 심정이었다 ",
              Eng: "It was a state of mind completely removed from this world.",
            },
            {
              Kor: "샛길에 접어들어 있었다",
              Eng: "He had turned into the side road.",
            },
            { Kor: "조로해 있었다 ", Eng: "She was prematurely old." },
            {
              Kor: "주위에 코스모스가 만발해 있어 ",
              Eng: "The cosmos flowers were blooming all around [the barracks].",
            },
            {
              Kor: "속에는 맑은 꿀이 차 있었다 ",
              Eng: "The inside was brimming with clear honey.",
            },
            {
              Kor: "의자마다 가득차 있었다 ",
              Eng: "Every seat was (in the state of being) occupied.",
            },
            {
              Kor: "잡초 속에서 튀어나와 있었다 ",
              Eng: "lit.: was in a state having resulted from protruding up out of the weeds",
            },
            {
              Kor: "건물이 여러 개 잇달아 있고 ",
              Eng: "There were quite a few buildings lined up ...",
            },
            {
              Kor: "커다랗게 서 있는 청량음료 광고판 ",
              Eng: "an advertisement for soft drinks, standing impressively",
            },
            {
              Kor: "서 있는 이몽룡 ",
              Eng: "Yi Mongnyong, who was standing there ...",
            },
            { Kor: "기다리고 서 있었다 ", Eng: "I was standing and waiting." },
            {
              Kor: "그네는 꼼짝도 않고 서 있었다 ",
              Eng: "She was standing there without budging.",
            },
            {
              Kor: "묵묵히 앉아 있을 뿐이었다 ",
              Eng: "He was just sitting quietly.",
            },
            {
              Kor: "그렇게 마주앉아 있기를 ",
              Eng: "He was in a state resulting from sitting across from her ...",
            },
            {
              Kor: "물속에 가라앉아 있는 사람들처럼 ",
              Eng: "like people sunk in water",
            },
            {
              Kor: "수많은 풍선이 비누거품처럼 떠 있었다 ",
              Eng: "So many balloons floating [in the air] like soap bubbles.",
            },
            {
              Kor: "술집에 남아있는 건 ",
              Eng: "The bar was in a state of having [only him and me] left in it.",
            },
            {
              Kor: "컵에는 술이 반쯤 남아 있었다 ",
              Eng: "The glass was about half full.",
            },
            { Kor: "녹아 있었다 ", Eng: "[The residue] had melted." },
            {
              Kor: "뜨겁게 달아 있으나 ",
              Eng: "lit.: [The sugar water] was already in a state resulting from having been heated up warmly, but ...",
            },
            {
              Kor: "물건들은 정좌해 있다 ",
              Eng: "The objects are sitting quietly.",
            },
            {
              Kor: "죽어 있었다 ",
              Eng: "[The clock] had (was in the state of having) stopped.",
            },
            {
              Kor: "복숭아꽃이 활짝 피어 있었다 ",
              Eng: "The peach flowers were in full bloom.",
            },
            { Kor: "눈물이 글썽 괴어 있었다 ", Eng: "Tears were welling up. " },
            {
              Kor: "파싹 늙어 있었던 것이다 ",
              Eng: "He was (in the state of being) emaciated.",
            },
            {
              Kor: "오랜 동안 말라 있던 나무라 ",
              Eng: "because the wood had been dry for a long time ...",
            },
            {
              Kor: "한참 추수기에 이르러 있었고",
              Eng: "They were in the height of the harvest season, and ... (Note that the verb 이르다 reach, get as far as has an irregular infinitive: 이르러.)",
            },
            {
              Kor: "마을은 눈 속에 잠겨 있었다 ",
              Eng: "The village was submerged in snow.",
            },
            {
              Kor: "목교가 놓여 있었다",
              Eng: "There lay a wooden suspension bridge.",
            },
            {
              Kor: "비단의 방석이 놓여 있고 ",
              Eng: "Silk cushions were laid out on the floor ...",
            },
            {
              Kor: "코 끝에 땀이 맺혀 있었다 ",
              Eng: "Sweat was gathering on the tip of her nose.",
            },
            {
              Kor: "눈이 한꺼풀 덮여 있었다 ",
              Eng: "… was covered by a layer of snow.",
            },
            {
              Kor: "여기 적혀 있는데 ",
              Eng: "It's [in a state resulting from having been] written down here …",
            },
            {
              Kor: "바뀌어 있었다 ",
              Eng: "It was in a state having resulted from changing. ",
            },
            {
              Kor: "갇혀 있는 옥방 ",
              Eng: "the room that was in a state resulting from being locked ",
            },
            {
              Kor: "떨어진 고엽에 뒤덮여 있었다 ",
              Eng: "It was covered by the fallen leaves.",
            },
            { Kor: "달려 있다 ", Eng: "[the zipper] was hanging" },
            {
              Kor: "분이 하얗게 발리어 있었다 ",
              Eng: "She had white powder smeared all over her face. (lit.: Powder was in a state resulting from having been applied whitely [to her face]) (The verb is 발리다 ← 바르다 apply, smear.) ",
            },
            {
              Kor: "낫이 한 자루 들리어 있었다 ",
              Eng: "In her hand was held a scythe. (lit.: A scythe was in the state resulting from being held) (The verb is 들리다 ← 들다 hold.) ",
            },
            {
              Kor: "굴러져 있다 ",
              Eng: "lit.: is in a state resulting from getting rolled/rolling [of its own accord] (Compare: 좀 떨어진 곳에 굴러 있었다 (무진 21) → was blowing away ...)",
            },
            { Kor: "풀어져 있었다 ", Eng: "was relieving [my mood]" },
            {
              Kor: "부뚜막 위에 놓여져 있는 것이었다 ",
              Eng: "The water jar had been left on top of the stove. ",
            },
            {
              Kor: "꽃무더기처럼 흩어져 있는 산 ",
              Eng: "the mountains, scattered about like heaps of flowers",
            },
            {
              Kor: "새 흙이 흩어져 있었다 ",
              Eng: "The soil was scattered about.",
            },
            {
              Kor: "궤짝이 두 개 포개져 있으며 ",
              Eng: "Two boxes were stacked one on top of the other ...",
            },
            {
              Kor: "바위산 쪽으로 이어져 있었다 ",
              Eng: "It continued in the direction of the mountain.",
            },
            {
              Kor: "영달이의 거친 말솜씨에 몹시 토라져 있었다 ",
              Eng: "She was sulking because of Yŏngdal's coarse manner of speaking.",
            },
            {
              Kor: "찢어져 있었다 ",
              Eng: "lit.: was in a state resulting from having been torn, i.e., was torn up",
            },
            {
              Kor: "얼굴이 해쓱해져 있었다 ",
              Eng: "Her face had become pale.",
            },
            {
              Kor: "금사슬이 채워져 있었으며 ",
              Eng: "The chains were [in a state resulting from having been] attached .",
            },
            {
              Kor: "한 줄의 금사슬이 늘어져 있는 것을 보았다 ",
              Eng: "She saw one of the chains dangling [lit.: … in a state resulting from having been/become dangled]. ",
            },
            {
              Kor: "터틀 넥 스웨터로 깊이 감추어져 있었다 ",
              Eng: "… was in the state of being deeply covered by (his) turtleneck sweater.",
            },
            {
              Kor: "욕조안엔 담겨져 있었다 ",
              Eng: "lit.: [Dirty water] was in a state resulting from having been stored in the bathtub; or, [Dirty water] filled the bathtub.",
            },
          ],
        },
        {
          entryName: "-어 잡수신다",
          typicalExample: "【10원을 잃어잡수셨으니까 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Ironic or sarcastic equivalent to -어 먹- as a kind of vulgarizing, pejorative finalizer (more vulgar equivalent of -어 버리다).",
          sentences: [
            {
              Kor: "10원을 잃어잡수셨으니까 ",
              Eng: "because you lost ten wŏn ...",
            },
          ],
        },
        {
          entryName: "-어 제낀다",
          typicalExample: "【입김을 길게 내뿜으며 껄껄 웃어제꼈다 】",
          categoryKorean: "Dialect morphology/Substandard ",
          categoryEnglish: "(Dialect morphology/Substandard )",
          translation:
            "The verb 제끼다 is dialect/substandard for 저치다 or 제치다 fling aside, and can function as an auxiliary similar to 버리다 in the pattern -어 버린다. ",
          sentences: [
            {
              Kor: "입김을 길게 내뿜으며 껄껄 웃어제꼈다 ",
              Eng: "guffawed while blowing out a long steamy breath",
            },
          ],
        },
        {
          entryName: "-어 준다",
          typicalExample: "【그는 정말 반가와 해주었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Benefactive -어 주- meaning to do something for somebody else's benefit; do someone a favor do somebody the favor of ...-ing.",
          sentences: [
            {
              Kor: "그는 정말 반가와 해주었다 ",
              Eng: "He graced me with a warm welcome.",
            },
            {
              Kor: "학은 늘 하늘과 그들 사이에 있어 주었었다 ",
              Eng: "The cranes always stayed ['did the villagers the favor of staying'] between them and the sky; or, The cranes were always there for them, between them and the sky. ",
            },
          ],
        },
        {
          entryName: "-어 죽겠다",
          typicalExample: "【그는 유쾌해 죽겠다는 듯이 웃어대었다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The pattern in -어 죽겠다 means is so ... that I could die; is ... to death (often used figuratively). Note that auxiliary is almost always in the -겠- form.",
          sentences: [
            {
              Kor: "그는 유쾌해 죽겠다는 듯이 웃어대었다",
              Eng: "He was laughing as if he was delighted to death.",
            },
          ],
        },
        {
          entryName: "-어지이다 ",
          typicalExample: "【내 딸 춘향 살려지이다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Desiderative. I wish/hope that..., please let... Used in prayers, and rather archaic-sounding.",
          sentences: [
            {
              Kor: "내 딸 춘향 살려지이다 ",
              Eng: "Please save my daughter Ch'unhyang.  ",
            },
          ],
        },
        {
          entryName: "-어 진다1",
          typicalExample: "【그녀의 귀는 가청 범위가 점차 커져】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "With descriptive verbs, the pattern in infinitive -어 + 지- means gets to be or becomes. ",
          sentences: [
            {
              Kor: "그녀의 귀는 가청 범위가 점차 커져",
              Eng: "the hearing radius/range of her ears gradually grew larger",
            },
            {
              Kor: "머릿속이 하얗게 바래지고 ",
              Eng: "The inside of her head (hairs?) would get bleached white, and … (The verb here is 바래다 fade, discolor.)",
            },
            {
              Kor: "못 미더워지기 시작했다 ",
              Eng: "She started doubting [even her own mother-in-law]. ",
            },
            {
              Kor: "오지증이 더 심해진 것 같았다",
              Eng: "Problems with his [childhood] physical development seemed to get worse.",
            },
            { Kor: "어두워진 개울", Eng: "the stream that was getting darker" },
          ],
        },
        {
          entryName: "-어 진다2",
          typicalExample: "【모두 나 때문인 듯이 여겨졌던 것이다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'The pattern in infinitive -어 + 지-, besides meaning gets to be or becomes with descriptive verbs, can have the effect of a "get-passive" in English or of middles or reflexives in languages which have these. The implication is that the subject/agent is (sometimes adversely) affected by the action, has no control over the action of the verb, and/or is unknown/immaterial. Compatible with verbs of thinking/ forgetting/knowing; morphological passives; long negatives.',
          sentences: [
            {
              Kor: "모두 나 때문인 듯이 여겨졌던 것이다 ",
              Eng: "It felt as though it were somehow all my fault.",
            },
            {
              Kor: "더 한층 어수선하게 여겨질 뿐이었다 ",
              Eng: "It was only that he considered [life on the streets] to be more chaotic.",
            },
            {
              Kor: "큰 허탕을 친 것처럼 억울하게 여겨졌다 ",
              Eng: "I felt it unfair and unjust, as if I all I had done had been in vain.",
            },
            {
              Kor: "서럽도록 보고 싶어졌다 ",
              Eng: "[It got to be so that] I missed [my husband] to the point that it saddened me.",
            },
            {
              Kor: "내 손을 포개보고 싶어졌다 ",
              Eng: "I [got to be so that I] wanted to join in and place my hand on top [of the hands of this mother-in-law and daughter-in-law].",
            },
            {
              Kor: "어리광이라도 부리고 싶어졌다 ",
              Eng: "I had an urge to want to be childish. ",
            },
            {
              Kor: "치악산이 보고 싶어졌다 ",
              Eng: "I came to miss Mt. Ch'iak.",
            },
            {
              Kor: "그들이 선남선녀로 느껴지는 순간이었다 ",
              Eng: "These were the moments when she felt [and she was powerless over the sensation] that they were like otherworldly beings. (lit.: These were the moments when they were felt/perceived [by her] as otherworldly beings.)",
            },
            {
              Kor: "나는 왠지 신기하게만 느껴졌다 ",
              Eng: "For some reason, it only felt amazing.",
            },
            {
              Kor: "느껴지는 뿌듯한 생명감 ",
              Eng: "the overwhelming/tremendous feeling of liveliness that he was feeling",
            },
            {
              Kor: "호랑이의 존재는 어김없이 느껴졌던 것이다",
              Eng: "I came to feel the tiger's existence as infallibly real.",
            },
            {
              Kor: "여자는 갑자기 어찌해야 좋을지 몰라지는 것이었다 ",
              Eng: "Suddenly she came to somehow not know whether if it would be good or bad; or, She was at a loss.",
            },
            {
              Kor: "헛산 것 같은 허탈감으로 이어졌다 ",
              Eng: "gave way to (became a) despondence from a life lived in vain",
            },
            {
              Kor: "경건하게 보태졌다 ",
              Eng: "… was [naturally/of its own accord/as if it had a mind of its own] added on top, reverently.",
            },
            {
              Kor: "이미 엎질러진 물이었다 ",
              Eng: "It was already too late; or, It was already water under the bridge.",
            },
            { Kor: "절로 내쉬어졌다 ", Eng: "spontaneously sighed " },
            {
              Kor: "여행자에게 주어지는 그 자유 ",
              Eng: "the freedom that often comes (lit.: is given) a traveller's way",
            },
            {
              Kor: "내게 주어진 한정된 책임 ",
              Eng: "the limits of the responsibility that has been given me  ",
            },
            {
              Kor: "길가에 세워진 하얀 팻말을 보았다",
              Eng: "I saw a white sign had been set up.",
            },
            {
              Kor: "이틀 후 새 남자와 함께 다시 자취를 감추었다는 소문이 이곳으로 전하여져 왔다",
              Eng: "Two days later we heard rumours of her disappearing with a new man. ",
            },
            {
              Kor: "헛디뎌졌다",
              Eng: "[His legs became unsteady and] he lost his footing.",
            },
            {
              Kor: "능선 위에 펼쳐진 순청의 하늘 ",
              Eng: "The pure blue sky spread over the mountain ridge ...",
            },
            {
              Kor: "검정 운동화의 찢어진 틈새로 삐져나온 때묻은 발가락이 그의 열띤 음성따라 바쁘게 까딱거려지고 있었다. ",
              Eng: "One of his dirty toes stuck out of a rip in his black running shoes and was tapping busily to match his rapid speech.",
            },
            {
              Kor: "원고지 한 장에 천 원도 치어지지 않는 고료 ",
              Eng: "a fee which came to no more than one thousand wŏn for a piece of the manuscript paper",
            },
            {
              Kor: "언덕의 그림자나 숲의 그늘로 가려진 곳에서는",
              Eng: "In the places concealed by the shadow of the hill and the shade from the woods …",
            },
            {
              Kor: "말 소리가 끊어지자 ",
              Eng: "As soon as the sound of the words was cut off ...",
            },
            {
              Kor: "시내로 나가는 버스도 끊어졌다 ",
              Eng: "The bus going into town had also stopped running.",
            },
            {
              Kor: "불티 튕겨지는 소리 ",
              Eng: "the sound of sparks crackling  ",
            },
            {
              Kor: "불 밝힌 방안에서 새어나오는 아낙네의 말소리에 걸음이 멎어졌다",
              Eng: "The footsteps stopped at the faint sound of voices coming from the lit room.",
            },
            {
              Kor: "내 심장 위에서 지워져 버리리라 ",
              Eng: "… will probably be erased from my heart.",
            },
            {
              Kor: "그의 손에 쥐어진 손잡이 ",
              Eng: "the handle clasped in his hand",
            },
            {
              Kor: "그어져내렸다",
              Eng: "[Ten-finger-streaks] wound their way down. (lit.: got drawn in a downward fashion)",
            },
            {
              Kor: "햇볕에 뿌려진 조개껍질 ",
              Eng: "a clam shell, sprinkled with sunshine  ",
            },
            {
              Kor: "죽어지는지도 모를 일로 알았다 ",
              Eng: "She thought of it as something which [if she did it] might be her death. (Presumably, 죽어지다 implies an even more drastic lack of control (implied by the -어 지다 pattern) than just 죽다, and means something like drop dead.)",
            },
            {
              Kor: "등산로가 닦여지면서부터",
              Eng: "Ever since the mountain hiking path was cleared out ...",
            },
            {
              Kor: "별들로 바뀌어져 있는 것을 느끼곤 했었다",
              Eng: "I felt from time to time as if the [sounds of the frogs] had transformed into countless stars.",
            },
            {
              Kor: "살라만다는 불 속에서 산다고 믿어졌지.  ",
              Eng: "It was believed that salamanders lived in fire.",
            },
            {
              Kor: "늘어뜨려진 머리채",
              Eng: "drooping hair trusses; hair trusses that drooped",
            },
            {
              Kor: "베개 위에 던져진 듯한 그 핏기 없는 얼굴 ",
              Eng: "the bloodless face that looked as if it had been tossed on top of the pillow",
            },
            {
              Kor: "시험에 주어지는 문제들은 어려운 것들이다.",
              Eng: "The problems that get given out on the test are difficult ones.",
            },
            {
              Kor: "옷이 조금이라도 찢어지면 생명을 잃는다.  ",
              Eng: "If your clothing gets even slightly torn you lose your life.",
            },
            {
              Kor: "그건 정말 한마디로 말해질 수 없는 어떤 것이다.  ",
              Eng: "It is truly a certain je ne sais quois which cannot be spoken of in just one word.",
            },
            {
              Kor: "감히 누려서는 안 될 행운처럼 여겨지는 것이었다.  ",
              Eng: "It was the case that it was considered by her like/as some good fortune that she dare not enjoy.",
            },
            {
              Kor: "셔터가 내려진 애견 가게 ",
              Eng: "a pet store, with its shutter down ",
            },
            {
              Kor: "그에게는 산후의 정상적인 모습으로 비쳐질지도 모를 일이었다. ",
              Eng: "To him [for all she knew], it probably seemed like a normal appearance after childbirth.",
            },
            {
              Kor: "얼굴 위에 붙여졌다",
              Eng: "[The tissue] had become attached to his face.",
            },
            {
              Kor: "코에 끼얹혀졌다 ",
              Eng: "[The boy's scent] rushed up her nose.",
            },
            {
              Kor: "내 마음 속에서 잊혀질 수 있다고 ",
              Eng: "[The telegram was telling me that all things] could be forgotten in my mind ...",
            },
            {
              Kor: "잊혀지지는 않았던지",
              Eng: "lit.: Perhaps because [what he had said] would not get itself forgotten, i.e., Perhaps because she was powerless against the unforgettable nature of [what he had said].",
            },
            {
              Kor: "나쁜 장면들은 내버려두면 잊혀지잖아?  ",
              Eng: "If you leave out the bad scenes, they get forgotten, isn't that right?",
            },
            {
              Kor: "말갛게 닦여진 신호등 ",
              Eng: "traffic lights that had been polished clear",
            },
            {
              Kor: "텅 빈 굴속에 혼자 남겨진 지수 ",
              Eng: "Chisu, left all alone in an empty cave",
            },
            {
              Kor: "환하게 불이 밝혀진 빵집 ",
              Eng: "a bakery with its lights shining brightly ",
            },
            {
              Kor: "남편의 집으로 옮겨졌다 ",
              Eng: "[Her corpse] was transferred/moved to her husband's house.",
            },
            { Kor: "열려진 차창 ", Eng: "the open(ed) bus window" },
            {
              Kor: "수은이 벗겨져 얼룩진 거울",
              Eng: "the mirror, blotchy from the peeling quicksilver",
            },
            {
              Kor: "녹이 슬고 벗겨져 ",
              Eng: "It had rusted and peeled off ...",
            },
            {
              Kor: "매여져 있는 새끼줄 ",
              Eng: "the straw rope which was tied [to the eaves] ",
            },
            { Kor: "널리 알려져서 ", Eng: "[was] widely known" },
            {
              Kor: "노인은 자기가 엿들은 것이 알려졌을까봐 황급해서 얼른 발소리를 죽여 방으로 들어왔다",
              Eng: "The old man quickly walked silently back to his room for fear that it might be discovered that he had overheard.",
            },
            {
              Kor: "안영감'이라 불려진 것이었다 ",
              Eng: "… had come to be called Old Man An.",
            },
            {
              Kor: "더 많은 서류들이 그의 미결함(未決函)에 쌓여졌다",
              Eng: "Pending documents were piling up in his to-do box.",
            },
            {
              Kor: "놓여진 것을 보았으나",
              Eng: "saw the thing that had been placed there, but ...",
            },
            {
              Kor: "갓이 뒤로 벗겨지는 줄도 모르고 ",
              Eng: "unaware of the fact that their kat (horsehair hats) were coming off ...",
            },
            {
              Kor: "머리채가 늘어져 있었다. ",
              Eng: "Her hair was hanging down/out.",
            },
            {
              Kor: "그 남자의 아랫도리가 벗겨져 있었는데 말야.  ",
              Eng: "And like, the guy's lower torso was stripped off.",
            },
            {
              Kor: "화투짝이 흩어져 있었다 ",
              Eng: "On top, cards were scattered.",
            },
            {
              Kor: "모습이 담겨져 있었다 ",
              Eng: "[The imposing] image was reflected ['contained']. ",
            },
            {
              Kor: "죽 굵게 그어져 있었다 ",
              Eng: "A [scar] had been incised/etched, long and thick.",
            },
            {
              Kor: "밖으로만 쥐어져 있었다 ",
              Eng: "[His fist] was clenched such that his thumb was always on the outside [and there was nothing he could do about it].",
            },
            {
              Kor: "신작로가 두 갈래로 갈라져 있었던 것이다 ",
              Eng: "The highway split into two.",
            },
            {
              Kor: "굳어져 오는 다리 ",
              Eng: "[his] legs that were (gradually) becoming stiff",
            },
            {
              Kor: "날개짓이 느껴져 오곤 했다",
              Eng: "… would regularly feel the urge to flap my wings.",
            },
            {
              Kor: "차가운 이물감이 전류처럼 전해져오는 것을 느낀다.  ",
              Eng: "I feel the cold sensation of a foreign body sweeping over me like an electric current.",
            },
            {
              Kor: "천신되어지지를 아니하였다 ",
              Eng: "just could not for the life of him ...",
            },
            {
              Kor: "좀처럼 끊어지지 않는다 ",
              Eng: "They just won't break off [no matter how hard she tries].",
            },
            {
              Kor: "입 밖으로 나와지지 않았다 ",
              Eng: "… would not come out of her mouth.  ",
            },
            {
              Kor: "입이 닫혀지지 않았다 ",
              Eng: "My mouth just wouldn't shut.",
            },
            {
              Kor: "조금도 바꾸어지지 않은 것처럼",
              Eng: "[The furniture looked] as if it had not been changed one bit. ",
            },
            {
              Kor: "사실이 믿어지지 않았다. ",
              Eng: "couldn't believe the fact that... [lit.: the fact just couldn't be believed]",
            },
            {
              Kor: "나는 어쩐지 기분이 끝내 유쾌해지지가 않았다. ",
              Eng: "For my part, somehow my mood just couldn't loosen up all evening.",
            },
            {
              Kor: "그 장면이 하나도 괴이쩍게 여겨지지 않았다는 것이다. ",
              Eng: "The thing was that that scene did not seem the least bit off.",
            },
            {
              Kor: "기도는 받아들여지지 않아.  ",
              Eng: "Prayers don't get answered.",
            },
            {
              Kor: "그 믿어지지 않는 행운",
              Eng: "this stroke of good fortune that she couldn't bring herself to believe ",
            },
          ],
        },
        {
          entryName: "어쩌고",
          typicalExample:
            "【“긴상 도꼬에 유끼이 마쑤까.” 어쩌고를 외고 있는 것이다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Blah-blah-blah or yadda yadda—used to round off a litany (or implied litany) of quotations as if to say 'etc., etc.'",
          sentences: [
            {
              Kor: "“긴상 도꼬에 유끼이 마쑤까.” 어쩌고를 외고 있는 것이다 ",
              Eng: 'He was reciting things like, "Where are you going, Mr. Kim?" and the like.',
            },
          ],
        },
        {
          entryName: "어쩌면 ...-(으)ㄹ지라도 모르다",
          typicalExample:
            "【어쩌면 울 수 없는 막힌 성대를 가진 아이일지라도 모를 일이었다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "어쩌면 ...-(으)ㄹ지라도 모르다, i.e., a bland of the patterns in (설령) ...  -(으)ㄹ지라도 even if it were so; granted that it were so and (어쩌면) ...-(으)ㄹ지도 모른다 (lit.: one doesn't know whether) maybe, perhaps it might be the case that.... The 어쩌면 does two things: 1) it flags the upcoming perhaps pattern and 2) can also attenuate the maybe~perhaps (somehow~who knows?) meaning.",
          sentences: [
            {
              Kor: "어쩌면 울 수 없는 막힌 성대를 가진 아이일지라도 모를 일이었다. ",
              Eng: "It was the case that she couldn't tell/one never knew whether it might be a child somehow with blocked vocal cords, one that couldn't cry.",
            },
            {
              Kor: "어쩌면 눈을 뜨지 못할지도 … 모를 일이었다 ",
              Eng: "It was the case that she couldn't tell/one never knew whether it might not be able somehow to open its eyes.",
            },
          ],
        },
        {
          entryName: "어찌(나)...-던지(요).",
          typicalExample: "【어찌 그리 아름답던지 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "It was so ...!!",
          sentences: [
            { Kor: "어찌 그리 아름답던지 ", Eng: "How beautiful is was!" },
          ],
        },
        {
          entryName: "어치",
          typicalExample: "【삼천원어치 땅】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation: "Postnoun 어치 worth.",
          sentences: [{ Kor: "삼천원어치 땅", Eng: "land worth 3000 wŏn" }],
        },
        {
          entryName: "-어 한다",
          typicalExample:
            "【나는 용감한 자만이 미인을 얻는다며 짐짓 비장해 했다.  】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "Third-person sensory statement. Used with descriptive verbs that refer to human feelings (liking, fearing, reacting to, ...) when the experiencer is a third person.",
          sentences: [
            {
              Kor: "나는 용감한 자만이 미인을 얻는다며 짐짓 비장해 했다.  ",
              Eng: "I made a point of playing the tragi-heroic, claiming that only the very brave get the good-looking women.",
            },
            {
              Kor: "마음은 전혀 그렇지 않은데도 그녀가 들떠 하는 것이 이상하게 못마땅했다.  ",
              Eng: "Even though I wasn't feeling that way in the slightest, her animation was annoying.",
            },
            {
              Kor: "나는 속으로 떨떠름해 한다.  ",
              Eng: "I think to myself, resignedly",
            },
            {
              Kor: "경찰은 궁금해했어.  ",
              Eng: "The police officer was curious.",
            },
            {
              Kor: "오히려 나는 도마뱀이 더 빨리 내려오지 않는다고 답답해했던 것 같다.  ",
              Eng: "Actually, it seems I was annoyed that the lizard did not come down faster.",
            },
            { Kor: "혼자 신명겨워했다 ", Eng: "entertaining himself" },
            {
              Kor: "무명화가를 불쌍해하다가 ",
              Eng: "At first, I took pity on this struggling (lit.: unknown) artist, but with time ...",
            },
            {
              Kor: "소년이 나를 불쌍해할 것 같아 ",
              Eng: "As it seemed the boy might feel pity for me …",
            },
            {
              Kor: "어쩔 줄을 몰라했다 ",
              Eng: "She didn't know what to do (i.e., She was at a loss).",
            },
            {
              Kor: "굽실굽실 고마와 했다 ",
              Eng: "She showed her gratitude to me so subserviently.",
            },
            {
              Kor: "K는 미심쩍어하면서도 ",
              Eng: "Even though K was doubtful ...",
            },
            { Kor: "떨떠름해 하시는 것 ", Eng: "her leeriness" },
            {
              Kor: "그것처럼 분한 건 없어하였다 ",
              Eng: "There was nothing quite so aggravating [to/for him] as that.",
            },
            {
              Kor: "속으로 측은해하며 ",
              Eng: "feeling this kind of compassion ...",
            },
            { Kor: "무척 기뻐하셨다 ", Eng: "She was very happy." },
            {
              Kor: "무안해 하면서 ",
              Eng: "Feeling embarrassed [he searched his pocket].",
            },
            { Kor: "신기해 한다 ", Eng: "He is amazed/marvels." },
            {
              Kor: "남편이 놀라다 못해 신기해했다 ",
              Eng: "My husband, not quite capable of mustering surprise, asked only in a bemused way; or, My husband was so surprised that he began to wonder [why I wanted to travel]. ",
            },
            {
              Kor: "해가 쪼일 때에 즐겨하고 ",
              Eng: "basking in the setting sun ...",
            },
            {
              Kor: "불리를 두려워해서였습니다 ",
              Eng: "Because they were afraid of being at a disadvantage. ",
            },
            {
              Kor: "공연히 주눅들어하는 것처럼 말입니다 ",
              Eng: "I mean, like pointlessly losing their nerve.",
            },
            {
              Kor: "제 아내를 잃은 듯 섭섭해 했다 ",
              Eng: "He looked as sad as though it was his own wife he had lost.",
            },
            {
              Kor: "이서방 모친의 일을 못마땅해 하심이겠지 ",
              Eng: "It is probably the case that [mother] doesn't like Mr. Yi's mother's actions.",
            },
          ],
        },
        {
          entryName: "얼마나 ...-ㄴ지 모른다",
          typicalExample:
            "【그나마 키스라도 하는 게 얼마나 다행스러운지 몰랐다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "얼마나 ...-ㄴ지 모른다: you have no idea how much...; something is so very/ incredibly... ",
          sentences: [
            {
              Kor: "그나마 키스라도 하는 게 얼마나 다행스러운지 몰랐다.  ",
              Eng: "Thank God he at least kissed me a bit, for what it was worth.",
            },
          ],
        },
        {
          entryName: "-었거나",
          typicalExample: "【그냥 시어머니와 마주쳤거나 마주친 김에】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation: "-거나 on a past base.",
          sentences: [
            {
              Kor: "그냥 시어머니와 마주쳤거나 마주친 김에",
              Eng: "it was either that they bumped into her mother-in-law or having chanced to bump into her, they figured they might as well ...",
            },
            {
              Kor: "유산을 많이 했거나 또... ",
              Eng: "if they have had a lot of abortions, or ...",
            },
          ],
        },
        {
          entryName: "-었겠-",
          typicalExample:
            "【이런 산골에 처박혀있기엔 아무래도 과했던 그년이 이 산중에 따라온 게 결국 뭐 보고였겠어요 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation:
            "\"Past-future\" with 'future-presumptive' -겠- on a past base meaning must be the case that something happened; I'll bet that [something happened]; I'll bet (-겠-) it was the case that ... (-었-). ",
          sentences: [
            {
              Kor: "이런 산골에 처박혀있기엔 아무래도 과했던 그년이 이 산중에 따라온 게 결국 뭐 보고였겠어요 ",
              Eng: "What else do you think would have been enough motivation for her to do something as unreasonable as live cooped up on this mountain?  ",
            },
            { Kor: "퍽 변했겠지요 ", Eng: "It must have changed a lot." },
            {
              Kor: '"예비고사야 잘 봤겠지." ',
              Eng: "Well, you must have done well on the preparatory exam.",
            },
            {
              Kor: "그쯤에서 말을 돌려 버렸겠지. ",
              Eng: "And at that point would have changed the subject.",
            },
          ],
        },
        {
          entryName: "-었나 했다",
          typicalExample: "【침을 맞아서 다 나았나 했는데  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "... -았나 했다: thought to oneself, 'is it the case that (something happened)?', with the question in -나 functioning as quoted thought.",
          sentences: [
            {
              Kor: "침을 맞아서 다 나았나 했는데  ",
              Eng: "I got acupuncture and thought I was all better, but …",
            },
          ],
        },
        {
          entryName: "-었는 걸(요)",
          typicalExample: "【장까지 들어섰는 걸 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'This is an example of what Martin calls the use of the accusative to "mark an antithetical clause that ends in a modifier + 것." The meaning is contrary to what you might expect or think, despite contrary anticipations/reservations; although, but; (it\'s) too bad (that ...), in spite of the fact that ... ; anyway, so there! Note that in speech, this has a rising, question-like intonation at the end.',
          sentences: [
            {
              Kor: "장까지 들어섰는 걸 ",
              Eng: "Even a market has sprung up! (contrary to what you might have expected)",
            },
            {
              Kor: "진작에 떴을 걸.  이젠 겨울에 움직일 생각이 안 납니다.  ",
              Eng: "I should have left already.  I don't even want to think about leaving in the winter time.  ",
            },
            {
              Kor: "나는 그것들이 왜 한결같이 된장에 버무려져 있었던 걸까를 알지 못했다.  ",
              Eng: "I could never figure out why those things were always mixed in with the soybean paste.",
            },
          ],
        },
        {
          entryName: "-었는가",
          typicalExample: "【그동안 지탱 못 하겠는가 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Question in -었는가. ",
          sentences: [
            {
              Kor: "그동안 지탱 못 하겠는가 ",
              Eng: "Do you think I won't be able to take care of myself? ",
            },
            {
              Kor: "살림 사는 아줌마일 뿐인 그녀에게 무슨 기회가 오겠으며 그럴 능력이나 있겠는가  ",
              Eng: "Does she even have the ability to succeed or would the opportunity ever come to her when she is just a housewife? ",
            },
            {
              Kor: "자기 아내와 자식 귀하지 않은 놈 있겠는가. ",
              Eng: "There couldn't be a man who doesn't value their wife and children.",
            },
            {
              Kor: "무슨 일이 일어날 수 있겠는가. ",
              Eng: "What could possibly go wrong? ",
            },
            {
              Kor: "놀라지 않을 수 있겠는가.  ",
              Eng: "Do you think you'll be able to stay calm? ",
            },
            {
              Kor: "자네 심정을 내가 알겠네.  이 지경에 별 도리 있겠는가  ",
              Eng: "I can understand how you feel.  At this point, there's not much you can do.",
            },
            { Kor: "[Examples?]", Eng: "" },
          ],
        },
        {
          entryName: "-었는데(요)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "For Imminent Elaboration.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-었는데다(가)",
          typicalExample: "【꽃들을 볼 수 있었는데다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Past base + -는데(에) + -(이)다가 not only ... but in addition; on top of that. ",
          sentences: [
            {
              Kor: "꽃들을 볼 수 있었는데다 ",
              Eng: "not only was it possible to see flowers, (but in addition ...)",
            },
          ],
        },
        {
          entryName: "-었는데도",
          typicalExample: "【나를 예의 바르게 반겼는데도 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Even though, in spite of; given the circumstance that it is/does, even though, though. This pattern can usually be taken as an abbreviation of -는데도 불구하고 and is equivalent in function to -(으)ㅁ에도 (불구하고).",
          sentences: [
            {
              Kor: "나를 예의 바르게 반겼는데도 ",
              Eng: "even though [the two of them] welcomed me politely ...",
            },
            {
              Kor: "밤이 깊지 않았는데도 ",
              Eng: "even though it wasn't late ...",
            },
            {
              Kor: "겨우 마흔을 넘겼는데도 ",
              Eng: "despite of the fact that he was barely past forty ...",
            },
            {
              Kor: "한나절을 헤맸는데도 ",
              Eng: "despite having wandered for the better part of the day ...",
            },
            {
              Kor: "온종일을 헤맸는데도 ",
              Eng: "even though we had wandered the whole day ...",
            },
            {
              Kor: "때가 자났는데도",
              Eng: "even though it was way past the time ...",
            },
          ],
        },
        {
          entryName: "-었는지",
          typicalExample: "【알아들었는지 못 알아들었는지】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Oblique/indirect question in -었는지. Modifier [-(으)ㄴ, -던, -었던, -었는, -는] + postmodifier 지 the uncertain fact whether; perhaps; because. When left hanging in mid-sentence, you can interpret the pattern as having left out a following (도) 모르지만: it may have been the case that or it is uncertain WH... [but...]; perhaps because ...",
          sentences: [
            {
              Kor: "알아들었는지 못 알아들었는지",
              Eng: "whether she understood or not, ...",
            },
            {
              Kor: "등 뒤에서 민영이는 잠이 들었는지  ",
              Eng: "Perhaps because Minyŏng had fallen asleep on my back.",
            },
            {
              Kor: "발이라도 삐었는지 ",
              Eng: "perhaps because she had sprained her foot or something ...",
            },
            {
              Kor: "K는 저 혼자 낙담을 했는지 ",
              Eng: "perhaps because he was all disappointed ...",
            },
            {
              Kor: "마침 장이 섰었는지 ",
              Eng: "perhaps because, as luck would have it, a market had been set up ...",
            },
            {
              Kor: "포악무도했는지 ",
              Eng: "whether it was wickedly immoral ...",
            },
            {
              Kor: "돌부리에 채였는지 ",
              Eng: "perhaps because she had been pricked by a jagged stone ...",
            },
            {
              Kor: "반짐작은 갔는지 ",
              Eng: "perhaps because they did engage in a lot of guessing ...",
            },
            {
              Kor: "아내가 목욕을 했는지 ",
              Eng: "perhaps his wife had taken a bath, [because] ...",
            },
            {
              Kor: "후배는 내 말에 용기를 얻었는지 ",
              Eng: "It might have been because he gained courage from my words, …",
            },
            {
              Kor: "여자는 어딘가 불안했는지 ",
              Eng: "the woman seemed to feel uneasy/anxious about something ... (Also note 어딘가 for 어디에서 인가 or 무엇인가.)",
            },
            {
              Kor: "다음날 학교 수업을 어떻게 받았는지 생각이 나지 않았다.",
              Eng: "He couldn't remember how he managed classes the next day.",
            },
            {
              Kor: "어느새 깨어났는지 남자가 ... ",
              Eng: "She couldn't tell/Lord knows when he had woken up, but the man ...",
            },
            {
              Kor: "어디가 잘못되었는지 확실히 알 수라도 있는 아이가 태어난다면 ",
              Eng: "If it were the case that a child should be born about whom one could know for certain how/where it was deformed …",
            },
            {
              Kor: "무슨 생각을 했는지 홱 일어나 ",
              Eng: "Perhaps/Maybe because he had thought of something, he suddenly jumped up, and …",
            },
            {
              Kor: "무엇을 생각했는지 ",
              Eng: "perhaps/maybe she thought of something; or, who knows what she [suddenly] thought of, but ...",
            },
            {
              Kor: "언제 그렇게 고였는지 ",
              Eng: "I don't know when / Lord knows when [her eyes] got so full [of tears] ...",
            },
            {
              Kor: "아버지가 언제 돌아왔는지 ",
              Eng: "lit.: it was unclear when his father had returned, but [he had, and ...], i.e., his father must have returned in the meantime, because he heard him say ...",
            },
            {
              Kor: "어디서 주웠는지 ",
              Eng: "picked up somewhere or other ...",
            },
            {
              Kor: "어디가 피를 토하고 자빠졌는지. ",
              Eng: "For all I know, he's buggered off somewhere, spit blood and collapsed ... [Here the author is responding to the other man's question in the line above: What sort of work would we be able to find if we went now?]",
            },
          ],
        },
        {
          entryName: "-었는지도 모른다",
          typicalExample: "【평생 산에서 살도록 태어났는지도 모른다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Perhaps/maybe it was the case that ... did/was.",
          sentences: [
            {
              Kor: "평생 산에서 살도록 태어났는지도 모른다 ",
              Eng: "He wasn't sure whether perhaps he wasn't born to live out his whole life in the mountains.",
            },
            {
              Kor: "달랄 작정이었는지도 모른다 ",
              Eng: "Their intentions might well have been to ask [for some clothing or food].",
            },
            {
              Kor: "그녀의 삶 전체가 썩은 냄새를 풍기는 것이었는지도 몰랐다.  ",
              Eng: "It may well have been the case that her entire life was giving off a rotten smell.",
            },
            {
              Kor: "지섭이 그곳에 가 무슨 일을 했는지도 아무도 몰랐다.  ",
              Eng: "And nobody knew what Chisŏp had been doing there, either. ",
            },
            {
              Kor: "도저히 움직일 수 없는 곤경에 처한 아버지의 모습을 나는 벌써 오래 전부터 갈망해 왔는지도 모른다  ",
              Eng: "It could be that I had been eagerly waiting for a long time to see my dad in this sad plight, completely paralyzed.  ",
            },
            {
              Kor: "이 세번째 이유가 모든 토호들이 과객을함부로 대접하지 못하게 한 가장 큰 이유가 되었을는지도 모릅니다.  ",
              Eng: "The third reason, that the wealthy farmers were not allowed to entertain wayfarers, might have been the biggest reason of all.  ",
            },
            {
              Kor: "그의 말처럼 내가 달라졌는지도 모르겠다.  ",
              Eng: "It might be true that I've changed, just like he said.  ",
            },
            {
              Kor: "평생 산에서 살도록 태어났는지도 모른다.  ",
              Eng: "It could be that I was born to live in the forest my whole life.  ",
            },
          ],
        },
        {
          entryName: "-었는지 몰라도 ",
          typicalExample: "【관(官)에서는 뭐라고 이름지었는지 몰라도 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Even though one doesn't know ['the uncertain fact'] whether, what, how, why, etc. Usually in a bigger pattern of the type: I don't know (or nobody knows) A, but B ...",
          sentences: [
            {
              Kor: "관(官)에서는 뭐라고 이름지었는지 몰라도 ",
              Eng: "Nobody knew what name the county office [had given to the village], but ...",
            },
          ],
        },
        {
          entryName: "-었는지(요)",
          typicalExample: "【어떻게 절 알고 오셨는지? 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Oblique/indirect question in -었는지 (I.e., modifier [-(으)ㄴ, -던, -었던, -었는, -는] + postmodifier 지 the uncertain fact whether; perhaps; because, rounded off with polite 요 to make a polite indirect question.",
          sentences: [
            {
              Kor: "어떻게 절 알고 오셨는지? ",
              Eng: "How did you know about me so as to come here?",
            },
            {
              Kor: "오늘따라 왜 이리 마음에 걸리는 게 많은지, 망할 놈의 일기장.  ",
              Eng: "Curse this diary.  Why are there so many things to confess today?",
            },
          ],
        },
        {
          entryName: "-었다(가) ",
          typicalExample:
            "【여자는 아침이면 아버지와 함께 나갔다가 저녁거리를 안고 돌아와 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "On a past base, the transferentive ending in -다(가) has the same meaning as when it appears on a Plain Base—does/did (is/was) and then; does/did (is/was) but; when—i.e., it indicates a change or shift in action or state. But on a Past Base, -다(가) implies that the action designated by the verb has already happened/is completed, whereas on a Plain Base that action has been interrupted and cut short before completion. Moreover, on a Past Base, -다가 can represent a reversal, an undoing, a nullification, or an unanticipated, unrelated and undesirable consequence.",
          sentences: [
            {
              Kor: "여자는 아침이면 아버지와 함께 나갔다가 저녁거리를 안고 돌아와 ",
              Eng: "In the mornings, the woman would go out with their father, and then come back with an armload of supper fixings.",
            },
            {
              Kor: "발목을 억세게 움켜잡았다 놓기를 반복하던 손길 ",
              Eng: "his touch, which had repeatedly grabbed her ankle firmly and released it",
            },
            {
              Kor: "좁은 가게 안이 드러났다 다시 ",
              Eng: "The confined interior of the shop came into view then once again ...",
            },
            {
              Kor: "나무하러 왔다 낮잠을 잔 적도 여러 번이었다",
              Eng: "There were also many occasions when, after setting off on his way to collect firewood, he took a nap.",
            },
            {
              Kor: "학교에 갔다 들어서는 것을 보고 = 학교에 갔다가",
              Eng: "seeing him returning home from [having gone to] school",
            },
            {
              Kor: "간격을 두었다가 나를 보며 말했다 ",
              Eng: "He placed some space between us and then looked at me and spoke.",
            },
            {
              Kor: "조가 안으로 들어 갔다가 나왔다. 잠시 후에 술상이 나왔다.   ",
              Eng: "Cho went inside and came back out.  A moment later a table was set up with food and liquor.  ",
            },
          ],
        },
        {
          entryName: "-었다(가) 한다",
          typicalExample: "【슬쩍 다리를 구부렸다 하자 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The transferentive on a past base: did it (and stuff) and then ...",
          sentences: [
            {
              Kor: "슬쩍 다리를 구부렸다 하자 ",
              Eng: "once it had deftly bent its leg ...",
            },
            {
              Kor: "학교에 다닌다는 것, 학생들을 가르친다는 것, 사무소에 출근했다가 퇴근한다는 이 모든 것이 실없는 장난이라는 생각이 든 것이다. ",
              Eng: "It occurred to me that going to school, teaching students, and going to work at the tax office and then coming home were all senseless hobbies.  ",
            },
            {
              Kor: "나중에는 수비 부대의 후보로 강제적인 군사훈련을 받았다 한다  ",
              Eng: "Later on they were forced to go through military drills as guards in training.",
            },
            {
              Kor: "하늘의 별이 와르르 얼굴 위에 쏟아질 듯싶게 가까웠다 멀어졌다 한다.  ",
              Eng: "The stars in the sky would come so close that it seemed like they were showering her face and then moved away.  ",
            },
          ],
        },
        {
          entryName: "-었다가는1",
          typicalExample: "【물오리떼가 내려 앉았다가는 날아오르곤 했다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "On a past base, the transferentive ending in -다(가) has the same meaning as when it appears on a Plain Base—does/did (is/was) and then; does/did (is/was) but; when—i.e., it indicates a change or shift in action or state. But on a Past Base, -다(가) implies that the action designated by the verb has already happened/is completed, whereas on a Plain Base that action has been interrupted and cut short before completion. Moreover, on a Past Base, -다가 can represent a reversal, an undoing, a nullification, or an unanticipated, unrelated and undesirable consequence. Like in -고는, the presence of 는 on -었다가는 reinforces the and THEN reading. ",
          sentences: [
            {
              Kor: "물오리떼가 내려 앉았다가는 날아오르곤 했다 ",
              Eng: "A flock of ducks would land [on the field] and then take off again. ",
            },
            {
              Kor: "튀어 올라갔다가는 떨어져 내렸다",
              Eng: "sprung upwards and then dropped downwards",
            },
            {
              Kor: "숲속에서 산새떼가 돌팔매같이 튀어 올라갔다가는 떨어져 내렸다 ",
              Eng: "A flock of mountian birds shot up from inside the forest and came back down like a stone thrown into the air.  ",
            },
          ],
        },
        {
          entryName: "-었다가는2",
          typicalExample:
            "【호두송이를 맨손으로 깠다가는 옴이 오르기  쉽다는 말 같은 건 아무렇지도 않았다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Warning pattern: if one keeps on doing then (unfortunately) ... On a Past Base, -다가는 implies that the action designated by the verb has already happened/is completed, whereas on a Plain Base that action has been interrupted and cut short before completion.",
          sentences: [
            {
              Kor: "호두송이를 맨손으로 깠다가는 옴이 오르기  쉽다는 말 같은 건 아무렇지도 않았다. ",
              Eng: "Warnings about the possibility of getting a rash if you peeled walnut burrs with your bare hands had no effect on me. ",
            },
          ],
        },
        {
          entryName: "-었다(가) . . . -었다(가) 한다",
          typicalExample: "【자리에서 엉덩이를 들었다 놓았다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "A series of two or more Past Base Transferentives represents intermittent or alternating events. ",
          sentences: [
            {
              Kor: "자리에서 엉덩이를 들었다 놓았다 ",
              Eng: "I got my butt in and out of my seat; or, I was restless in my seat.",
            },
            {
              Kor: "산냄새가 끊겼다 이어졌다 하면서 ",
              Eng: "as the mountain fragrance wafted and disappeared ...",
            },
            {
              Kor: "하늘의 별이 와르르 얼굴 위에 쏟아질 듯싶게 가까웠다 멀어졌다 한다. ",
              Eng: "The stars in the sky would come so close that it seemed like they were showering her face and then moved away. ",
            },
          ],
        },
        {
          entryName: "-었다는 게",
          typicalExample:
            "【학교 다닐 때 공부를 잘하고 모범생이었다는 게 무슨 내세울 일이나 되는가. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-었다는 게 ← -었다는 것이 with -다는 것 (cf. also -는다는 것, -(으)ㄴ다는 것, -(이)라는 것) from the indirect quotation pattern in -다[고 하]는 것, etc., meaning the putative or alleged fact that... setting up a comment about the (often unusual or noteworthy) topic marked by -는 것. ",
          sentences: [
            {
              Kor: "학교 다닐 때 공부를 잘하고 모범생이었다는 게 무슨 내세울 일이나 되는가. ",
              Eng: "Since when is the fact that you studied hard and were a model student in school something to brag about?",
            },
          ],
        },
        {
          entryName: "-었다더군(요)",
          typicalExample: "【결혼하셨다더군요? 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Abbreviated from a quotation in -었다[고 하]더군요 I see that they say that ...!",
          sentences: [
            {
              Kor: "결혼하셨다더군요? ",
              Eng: "So, I've heard that you got married?",
            },
          ],
        },
        {
          entryName: "-었다면",
          typicalExample: "【그 언약을 잊지 않았다면 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Conditional in -었다면/-았다면 contracted from -었다[고 하]면 Throwing the conditional into a contracted quotation like this makes the expression more hypothetical than just plain -(으)면 or -었으면.",
          sentences: [
            {
              Kor: "그 언약을 잊지 않았다면 ",
              Eng: "if and supposing she hasn't forgotten that pledge ...",
            },
          ],
        },
        {
          entryName: "-었다뿐[이지]",
          typicalExample:
            "【흔들어 대던 도리질만은 그 때 같은 박력만 가셨다뿐 멈출 줄 모르는 고질병이 되고 말았다 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Followed by a rhetorical question with (usually future) copula, it is merely that ...",
          sentences: [
            {
              Kor: "흔들어 대던 도리질만은 그 때 같은 박력만 가셨다뿐 멈출 줄 모르는 고질병이 되고 말았다 ",
              Eng: "The head shaking became a chronic condition—even after the initial shock of the event wore off.",
            },
          ],
        },
        {
          entryName: "-었대니까 ",
          typicalExample: "【알았대니까】",
          categoryKorean: "Colloquial ",
          categoryEnglish: "(Colloquial )",
          translation:
            "Colloquial equivalent of -었다니까 for insistent, reproachful reiterations.",
          sentences: [{ Kor: "알았대니까", Eng: "OK, OK, I got it!" }],
        },
        {
          entryName: "-었대두",
          typicalExample: "【글쎄 알았대두 그른다 】",
          categoryKorean: "Dialect morphology/Substandard/준꼴 Contraction ",
          categoryEnglish: "(Dialect morphology/Substandard/준꼴 Contraction )",
          translation: "Dialect/substandard contraction for -었다고 해도.",
          sentences: [
            {
              Kor: "글쎄 알았대두 그른다 ",
              Eng: "I just said OK, and you still go on like this. (With 그른다 = 그런다.)",
            },
          ],
        },
        {
          entryName: "-었댔자 ",
          typicalExample: "【여기서 버텨 봤댔자 】",
          categoryKorean: "연결어미 Connective ending",
          categoryEnglish: "(연결어미 Connective ending)",
          translation: "Even supposing [that] ... (still/nonetheless).",
          sentences: [
            {
              Kor: "여기서 버텨 봤댔자 ",
              Eng: "even supposing those bastards tried to resist here ...",
            },
          ],
        },
        {
          entryName: "-었던",
          typicalExample: "【냄새를 씻어 낼 수 있다고 믿었던 것일까. 】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "-었던, I.e., the retrospective modifier -던 on a past base. By itself, the -던 means ... that has been observed to (be or do); ... that has been doing, ... that used to do; ... that was, ... that used to be. Combined with the past base, the sense is of more complete-and-done and/or more remote in the past.",
          sentences: [
            {
              Kor: "냄새를 씻어 낼 수 있다고 믿었던 것일까. ",
              Eng: "Was it perhaps the case that he had believed it could wash away the smell?",
            },
            {
              Kor: "낚시 도구를 챙기느라 분주했던 남편 ",
              Eng: "her husband, who had been busy preparing the fishing tackle",
            },
            {
              Kor: "지금까지 함께 잤던 몇몇 여자아이들 ",
              Eng: "the few girls he had slept with thus far",
            },
            {
              Kor: "끝이 좋았던 기억이 하나도 없다.  ",
              Eng: "He had not a single memory of anything turning out well.",
            },
            {
              Kor: "지수가 잃어버렸던 것과는 달라 보였다.  ",
              Eng: "It looked different from the one that Chisu had lost.",
            },
            {
              Kor: "옆자리에 있는 것조차 잊어버렸던 남자가 말했다. ",
              Eng: "… said the man beside her, about whom she had forgotten completely.",
            },
            {
              Kor: '"십칠세기말까지 인도양 모리티우스섬에 살았던 새다 …"',
              Eng: "“It's a bird that used to live on the island of Mauritius until the end of the 17th century ...”",
            },
            {
              Kor: "그 아버지의 딸이었던 날들 ",
              Eng: "the days when she was her father's little girl",
            },
          ],
        },
        {
          entryName: "-었던가",
          typicalExample: "【꿈에서 봤던가, 현실에서 봤던가 】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "Past base + modifier [-(으)ㄴ, -던, -었던, -었는, -는] + postmodifier 가 the question of/whether; was it the case, I wonder (does anybody have evidence for it)?",
          sentences: [
            {
              Kor: "꿈에서 봤던가, 현실에서 봤던가 ",
              Eng: "whether I saw [the old woman] in my dream or in real life ...",
            },
          ],
        },
        {
          entryName: "-었던가(요)?",
          typicalExample:
            "【조금 전까지 이 몸이 어떻게 여자의 몸으로 의식되었던가?  】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "-었던가(요)? Was it the case, I wonder (-ㄴ가), that something had been observed as having taken place in the past?",
          sentences: [
            {
              Kor: "조금 전까지 이 몸이 어떻게 여자의 몸으로 의식되었던가?  ",
              Eng: "How was it that this body was taken to be a woman's body until not long ago?",
            },
          ],
        },
        {
          entryName: "-었던 것만 같-",
          typicalExample: "【슬며시 잠이 들었던 것만 같다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Modifier + 것만 같- means all it seemed like is/was; definitely seemed like .... The addition of particle 만 to the seem pattern in Modifier + 것 같- lends an air of absolute certainly and insistence to the construction, as if to say the one and only thing that seemed [likely] was ....",
          sentences: [
            {
              Kor: "슬며시 잠이 들었던 것만 같다 ",
              Eng: "It seemed like it was just at the moment when the girl had taken the drugs that I had dozed off into sleep. ",
            },
          ],
        },
        {
          entryName: "-었던 것이다",
          typicalExample: "【그 일본집에 있게 되었던 것이었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-었던 것이다: is a case of having used to do/be in the past; the fact of the matter is that (something) was or used to be the case in the past (and is now finished).",
          sentences: [
            {
              Kor: "그 일본집에 있게 되었던 것이었다 ",
              Eng: "[it had been a matter of ending up = -던 것이다] She had ended up in that Japanese family.",
            },
            {
              Kor: "민영이가 너무 보채고 민후도 오늘 따라 말썽만 피웠던 것이다. ",
              Eng: "Minyŏng was all fussy and Minhu, too, was acting out today, of all days.",
            },
            {
              Kor: "그때 나는 화계사 바로 앞에 살았는데 그녀의 집이 잠실이었던 것이다. ",
              Eng: "At the time I lived right in front of Hwagye Temple and her place was in Chamsil.",
            },
          ],
        },
        {
          entryName: "-었던 대로",
          typicalExample: "【지섭이 형이 책에서 읽었던 대로야.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-었던 대로 in accordance with/just like what had used to be the case ",
          sentences: [
            {
              Kor: "지섭이 형이 책에서 읽었던 대로야.  ",
              Eng: "It's just like Chisŏp had read in his books. (Here followed by Intimate Style copula -(이)야.)",
            },
          ],
        },
        {
          entryName: "-었던들 ",
          typicalExample:
            "【희(姬)만 그대로 내곁에 있어 주었던들 실의(失意)의 무진행은 없었으리라 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Granted that/even though it was/had been observed to be the case [often followed by a rhetorical question].",
          sentences: [
            {
              Kor: "희(姬)만 그대로 내곁에 있어 주었던들 실의(失意)의 무진행은 없었으리라 ",
              Eng: "Even granted that Hŭi had agreed to stay by my side, I still might have gone on my trip to Mujin dejected.   ",
            },
          ],
        },
        {
          entryName: "-었던 듯(이) ",
          typicalExample: "【겨울이 아닌 철엔 호경기를 누렸던 듯 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Like, as, giving the appearance of...; appearing (to be), looking (like); seeming as if; as if/though.",
          sentences: [
            {
              Kor: "겨울이 아닌 철엔 호경기를 누렸던 듯 ",
              Eng: "As if in the high-season outside of winter [it enjoyed a successful business]. ",
            },
          ],
        },
        {
          entryName: "-었던 성싶다 ",
          typicalExample: "【아마 옛날 이서방 부부의 침실이었던 성싶다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Seems/appears (to have been the case that), I guess (that).",
          sentences: [
            {
              Kor: "아마 옛날 이서방 부부의 침실이었던 성싶다 ",
              Eng: "It appeared to be Mr. and Mrs. Yi's bedroom.",
            },
          ],
        },
        {
          entryName: "-었던지",
          typicalExample: "【얼굴을 덮기에는 조금 딱딱했던지  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Oblique/indirect question in -던지 on a past base. Modifier [-(으)ㄴ, -던, -었던, -었는, -는] + postmodifier 지 means the uncertain fact whether; perhaps; because. When left hanging in mid-sentence, you can interpret the pattern as having left out a following (도) 모르지만 it may have been the case that or it is uncertain WH... [but...]; perhaps because ...",
          sentences: [
            {
              Kor: "얼굴을 덮기에는 조금 딱딱했던지  ",
              Eng: "perhaps because it had been a bit hard for covering his face",
            },
            {
              Kor: "답답한 제 신세를 생각했던지 ",
              Eng: "perhaps because he had thought of his frustrated lot",
            },
            {
              Kor: "잡상인이나 낮털이 강도가 다닐 시간은 아니다 싶었던지  ",
              Eng: "Perhaps it was because they thought it wasn't the time when merchants and thieves should be about.",
            },
            {
              Kor: "딸은 즉석에서는 가부를 말하지 않았으나 그의 머리 속에서도 이내 잊혀지지는 않았던지/",
              Eng: "Perhaps it wouldn't go away from his thoughts quickly because his daughter was not able to say yes or no. ",
            },
            {
              Kor: "몸 가누기가 힘들었던지 쪼그리고 앉으면서 등으로 돌담을 졌다  ",
              Eng: "Perhaps it had been too tiring to take care of his body because he sat crouched with his back to a stone wall.  ",
            },
            {
              Kor: "폭음이었던지",
              Eng: "perhaps because it was a surprising commotion/explosive sound",
            },
          ],
        },
        {
          entryName: "-었던지 몰라도 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Even though one doesn't know ['the uncertain fact'] whether, what, how, why, etc. Usually in a bigger pattern of the type: I don't know (or nobody knows) A, but B ...",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-었던지도 모른다",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "(The uncertain fact) whether it will be or do; doesn't know whether perhaps it had been the case that ...",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-었던지(요)",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Oblique/indirect question in -었던지 (I.e., modifier [-(으)ㄴ, -던, -었던, -었는, -는] + postmodifier 지 the uncertain fact whether; perhaps; because, rounded off with polite 요 to make a polite indirect question.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-었던지라(서) ",
          typicalExample: "【하도 착실한 학생이었던지라 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "As/since it does; it is a fact that.., grounds for, reason for (equivalent to colloquial -드니, according to Roth). Usually for a ceremonious/solemn pronouncement.  ",
          sentences: [
            {
              Kor: "하도 착실한 학생이었던지라 ",
              Eng: "but being as (he) was such a solid student, ...",
            },
          ],
        },
        {
          entryName: "-었던 편이다 ",
          typicalExample: "【항상 무진을 잊고 있었던 편이다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "On the ... side, -ish; direction, inclination, preponderance.",
          sentences: [
            {
              Kor: "항상 무진을 잊고 있었던 편이다 ",
              Eng: "I was almost always inclined to forget Mujin.",
            },
          ],
        },
        {
          entryName: "-었어도 ",
          typicalExample: "【발목이 나아 다시 움직일 수 있게 되었어도  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-었어도 even after doing, even after having done; and yet, but, while, though. I.e., concessive (even though) -어도 on a past base.",
          sentences: [
            {
              Kor: "발목이 나아 다시 움직일 수 있게 되었어도  ",
              Eng: "even though her ankle had healed and she had become able to move again",
            },
          ],
        },
        {
          entryName: "-었었",
          typicalExample: "【스쳐가는 이성의 매력을 느껴 보았었다 】",
          categoryKorean: "선어말 어미",
          categoryEnglish: "(선어말 어미)",
          translation:
            "Past-past or double past in -았었-/-었었-. Usually implies a more remote past event that just -었-, or else (with the case of verbs of motion) that the trip has been completed and the person in motion is now back. In the former, 'remote past' usage, it tends to equate with (and may well be somewhat calqued on) English 'past perfect' (had done, etc.)",
          sentences: [
            {
              Kor: "스쳐가는 이성의 매력을 느껴 보았었다 ",
              Eng: "She had [once] felt the attraction of a passing member of the opposite sex.",
            },
            {
              Kor: "그같은 타입의 남자를 여자는 경멸해 왔었다 ",
              Eng: "The woman had always despised men of that type.",
            },
            {
              Kor: "어제는 우물가에까지 날았었다 ",
              Eng: "Yesterday they had flown even as far as the well.",
            },
            {
              Kor: "지난 봄엔 그 여잘 데리고 절엘 한번 갔었지 ",
              Eng: "Last spring, I took her once to a temple.",
            },
            {
              Kor: "남자를 흘끔거리며 투덜대는 일행에게 남편이 말했었다.  ",
              Eng: "… said her husband to his pals who were grumbling and glaring at him.",
            },
            {
              Kor: "작년 예비고사일에도 눈이 내렸었다.  ",
              Eng: "Last year on the day of the pre-exam it had snowed, too.",
            },
            {
              Kor: "이 썩은 냄새! 라고 소리쳤었다  ",
              Eng: 'He had yelled, "This rotten smell!"',
            },
            {
              Kor: '"어마! 그랬었구나." ',
              Eng: "“Oh my goodness—so that's what happened!”",
            },
            {
              Kor: "누나가 말했었다. ",
              Eng: "That's what his older sister had said.",
            },
            {
              Kor: "딸은 팬지꽃이 피어 있는 두어 뼘 꽃밭가에서 줄 끊어진 기타를 쳤었다. ",
              Eng: "The [dwarf's] daughter had played her guitar with the broken strings beside the tiny flower garden with the blooming pansies.",
            },
            {
              Kor: "여자이이들과의 일을 이야기했을 때 은희는 눈물이 글썽글썽해졌었다.  ",
              Eng: "When [Yunho] had spoken of matters with other girls, Ŭnhŭi had gotten all teary-eyed.",
            },
            {
              Kor: "어젯밤 김치를 썰었었나? 손톱 밑에 고춧가루가 끼여 있다.  ",
              Eng: "Had she been cutting up kimch'i last night? There are bits of red pepper powder wedged under her fingernails.",
            },
            {
              Kor: "그는 꼭 한 번 잡혔었다.  ",
              Eng: "He had been caught exactly once. ",
            },
          ],
        },
        {
          entryName: "-었었던",
          typicalExample: '【"그 사람은 감옥에까지 갔었던 사람야." 】',
          categoryKorean: "선어말 어미",
          categoryEnglish: "(선어말 어미)",
          translation:
            "-었었던, the retrospective modifier -던 on a 'double past', which has the combined effect of placing the event in a quite remote time frame. ",
          sentences: [
            {
              Kor: '"그 사람은 감옥에까지 갔었던 사람야." ',
              Eng: "“He's somebody who even went to jail once way back when.”",
            },
          ],
        },
        {
          entryName: "-었으면 ...",
          typicalExample: "【학만 있었으면... 】",
          categoryKorean: "선어말 어미",
          categoryEnglish: "(선어말 어미)",
          translation:
            "If only ...; if only I could... An elliptical means of expressing a fervent wish or a polite request. The wish or request can be made more explicit by rounding off the expression with either 좋겠다 or 한다.",
          sentences: [
            { Kor: "학만 있었으면... ", Eng: "if only the cranes were here …" },
            {
              Kor: "발이나 녹이구 갔으면... ",
              Eng: "I wish [I could at least] thaw out my feet before we head out ...",
            },
            {
              Kor: "나는 그가 도마뱀을 발견하지 않았으면 하고 바란다.",
              Eng: "I hope to myself he won't discover the lizard.",
            },
            {
              Kor: "드시고 가셨으면... ",
              Eng: "I'd like it for you to have some [before you leave] ...",
            },
          ],
        },
        {
          entryName: "-었으면 싶다",
          typicalExample: "【낚시질을 자기가 한번 해봤으면 싶었다 】",
          categoryKorean: "선어말 어미",
          categoryEnglish: "(선어말 어미)",
          translation:
            "The pattern in -(으)면 싶- expresses a desire or wish which can be made more tentative and/or more explicitly counter-factual be putting it on a past base: -었으면 싶다 wish/want /desire to do... ",
          sentences: [
            {
              Kor: "낚시질을 자기가 한번 해봤으면 싶었다 ",
              Eng: "[He] wanted to try it once himself.",
            },
            {
              Kor: "우선은 말동무라도 있었으면 싶었다 ",
              Eng: "He thought to himself that, above all, he would at least like to have someone to talk to [during the journey]. ",
            },
            {
              Kor: "좀 보아 줬으면 싶었다 ",
              Eng: "He wished somebody [were beside him] to see it [but nobody was].",
            },
          ],
        },
        {
          entryName: "-었으면 좋겠다",
          typicalExample: "【네 성기가 좀 더 차가웠으면 좋겠어.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "One wishes that..., one hopes that... Expresses a fervent wish or a polite request. More hypothetical than the closely related -(으)면 좋겠다.",
          sentences: [
            {
              Kor: "네 성기가 좀 더 차가웠으면 좋겠어.  ",
              Eng: "I wish my genitals were more unfeeling.  ",
            },
            {
              Kor: "야아 그럼, 거기 가서 아주 말뚝을 박구 살아 버렸으면 좋겠네  ",
              Eng: "Wouldn't it be great if we could just mark our territory and settle down there?",
            },
            {
              Kor: "노여움보다는 어서 그가 이 산중에서 없어졌으면 좋겠다는 마음뿐이었던 것이다.  ",
              Eng: "More than being angry I just wished that he would leave the mountains.  ",
            },
            {
              Kor: "몸을 가릴 데가 있어 줬으면 좋겠다.  ",
              Eng: "I wish there was someplace that would keep me covered.  ",
            },
          ],
        },
        {
          entryName: "-었으면 한다",
          typicalExample: "【달을 우러러봤으면 하는 마음 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "One wishes that..., one hopes that... Expresses a fervent wish or a polite request. More hypothetical than the closely related -(으)면 한다.",
          sentences: [
            {
              Kor: "달을 우러러봤으면 하는 마음 ",
              Eng: "the simple wish to look up at the magnificent moon",
            },
            {
              Kor: "이게 꿈이었으면 했다 ",
              Eng: "I hoped I was dreaming it all.",
            },
          ],
        },
        {
          entryName: "-었을",
          typicalExample: "【나에게 주려고 샀을 주스병 】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "-었을 (prospective modifier -(으)ㄹ on a past base): which likely was...; which probably had done...",
          sentences: [
            {
              Kor: "나에게 주려고 샀을 주스병 ",
              Eng: "a bottle of juice that she had probably bought to give to me",
            },
          ],
        },
        {
          entryName: "-었을 거고 ",
          typicalExample: "【나는, 사람을 좀 쓰지, 했을 거고  】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "-었을 거고 ← -었을 것이고: it is probably/likely (-을) the case (것) that did or was (었-), and ...",
          sentences: [
            {
              Kor: "나는, 사람을 좀 쓰지, 했을 거고  ",
              Eng: 'I would have said, "Why don\'t you hire someone to help out?"',
            },
          ],
        },
        {
          entryName: "-었을 것이다 ",
          typicalExample:
            "【그는 천연색 슬라이드를 보면서도 은희를 생각했을 것이다.  】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "-었을 것이다: is the case that likely did/was; is likely the case that would have happened",
          sentences: [
            {
              Kor: "그는 천연색 슬라이드를 보면서도 은희를 생각했을 것이다.  ",
              Eng: "Even while watching the color slides, he had probably thought of Ŭnhŭi.",
            },
            {
              Kor: "지섭이 있었다면 이야기를 했을 것이다.  ",
              Eng: "If Chisŏp had been there he would have told him. ",
            },
            {
              Kor: "입술을 한번 깨물고 또 어떻게든 꾸려 나갔을 것이다.  ",
              Eng: "She would have bitten her lip once, and then kept on coping somehow.",
            },
          ],
        },
        {
          entryName: "-었을 경우 ",
          typicalExample: "【낚았을 경우 】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation: "The case/in cases when one has done...",
          sentences: [
            {
              Kor: "낚았을 경우 ",
              Eng: "in the case where he manages to fish [70 pairs of shoes]",
            },
          ],
        },
        {
          entryName: "-었을 때부터",
          typicalExample:
            "【아버지가 지섭을 쫓아냈을 때부터 윤호는 길을 잘못 들었다. 】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "-었을 때부터: (ever) since the time when somebody had done something",
          sentences: [
            {
              Kor: "아버지가 지섭을 쫓아냈을 때부터 윤호는 길을 잘못 들었다. ",
              Eng: "Ever since his father had driven out Chisŏp, Yunho had taken the wrong course.",
            },
          ],
        },
        {
          entryName: "-었을 뿐 (+ -이다) ",
          typicalExample:
            "【이야기를 들었을 뿐 처음 보는 주인집 아들이었다.  】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "-었을 뿐 (+ -이다): nothing but, only, constantly; all it/one dis/was is...",
          sentences: [
            {
              Kor: "이야기를 들었을 뿐 처음 보는 주인집 아들이었다.  ",
              Eng: "I had only ever heard of him—it is was the first time I had seen the landlady's son.",
            },
          ],
        },
        {
          entryName: "-었을 지도 모른다",
          typicalExample:
            "【조금이라도 순수한 면을 지녔다면 윤호는 그를 용서했을지도 모른다. 】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "-었을 지도 모르-: may perhaps have done; possibly might have done ",
          sentences: [
            {
              Kor: "조금이라도 순수한 면을 지녔다면 윤호는 그를 용서했을지도 모른다. ",
              Eng: "If [In'gyu] had had even the slightest purity of character, Yunho might have forgiven him.",
            },
            {
              Kor: "누구라도 달리 할말을 찾지 못했을지도 모른다.  ",
              Eng: "For all she knew, anybody else would have been unable to find anything different to say.",
            },
          ],
        },
        {
          entryName: "에 ",
          typicalExample: "【어느 먼 곳의 겨울 풍경에 그리움을 느꼈다 】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation: "(Cause) for, because, since, from, on account of.",
          sentences: [
            {
              Kor: "어느 먼 곳의 겨울 풍경에 그리움을 느꼈다 ",
              Eng: "I felt a yearning for a far-away winter's scenery.",
            },
            {
              Kor: "뉴똥치마 하나 못 해준 주변에 ",
              Eng: "this resourcefulness (of yours) that has never given (me) a silk dress ...",
            },
            {
              Kor: "뚜벅뚜벅 파출소 안으로 들어서는 소리에 ",
              Eng: "at the sound of someone tramping into the police station ...",
            },
            {
              Kor: "다른 네 놈도 이런 생각에 다방을 들쑤시고 다닐지 모른다.",
              Eng: "You and others like you might go poking around the coffee shop because of these thoughts. ",
            },
            {
              Kor: "사방에서 불어오는 꽃의 방향(芳香)에 머리가 아프고 호흡이 탁할 지경이었다 ",
              Eng: "My head hurt and I felt like I was going to suffocate from the floral fragrance drifting in from all directions. ",
            },
            {
              Kor: "혼자서 아무것도 안 든 채 버스를 탄 내 모습에 차장은 이상한 눈치를 보였다",
              Eng: "The bus driver gave me a weird look when he saw that I was getting on the bus alone and empty-handed.",
            },
            {
              Kor: "산림계장이라는 직책에 은연중 만족하는 때문인지 나는 그렇지가 않았다 ",
              Eng: "Perhaps it was because he was secretly reveling in the satisfaction of taking on the responsibility of head forester, but that wasn't the case for me.  ",
            },
            {
              Kor: "생각에 지친 그녀 ",
              Eng: "worn out from thinking, she ...",
            },
            {
              Kor: "그때 춘향모가 부르는 소리에 그녀는 아쉬운 꿈에서 깨었다 ",
              Eng: "At that moment Chunyang woke up from a bad dream at the sound of her mother singing.",
            },
            {
              Kor: "이상한 예감에 가슴이 울렁거렸다",
              Eng: "His heart thumped from a strange sense of foreboding. ",
            },
            {
              Kor: "방안 짐이 여덟 개에 ",
              Eng: "and eight pieces of furniture ...",
            },
            {
              Kor: "손틀 발틀 두 개에 ",
              Eng: "two of them, a hand operated one and a treadle one ...",
            },
            { Kor: "까무잡잡한 얼굴에 ", Eng: "with her darkish face ..." },
            {
              Kor: "바람에 흔들리는지",
              Eng: "perhaps because they were being shaken by the wind",
            },
            {
              Kor: "궤짝에 치여 죽은 덕이 아버지였다 ",
              Eng: "It was Togi's father, crushed to death by a crate. ",
            },
            { Kor: "착각에 휘몰리며", Eng: "struck by the illusion ..." },
            {
              Kor: "우리의 그림자에 놀라 흩어져 버렸다",
              Eng: "Startled at our shadows, they scattered.",
            },
            { Kor: "고함 소리에 쫓겨 ", Eng: "chased by howls ..." },
            {
              Kor: "9시 출근, 6시 퇴근에 갇혀 ",
              Eng: "locked up by the 9:00 to 6:00 routine",
            },
            {
              Kor: "불길한 예감에 사로잡혔다 ",
              Eng: "… was seized with/by a sense of gloomy foreboding.",
            },
            { Kor: "푸른 숲속에 싸여", Eng: "surrounded by a lush forest ..." },
            {
              Kor: "전신을 가시와 나뭇가지에 할키우며 ",
              Eng: "our entire bodies getting clawed at by the thorns and branches ... (Note also dialect/substandard passive 할키우- related to standard 할퀴-.)",
            },
            { Kor: "말발굽에 차여  ", Eng: "kicked up by the horse hooves" },
            {
              Kor: "그런 것들로 이루어진 집이라는 일상에 갇혀 살기에는  ",
              Eng: "[He is too accustomed to his freedom] to live all cooped in the everyday life called 'home' made up of things like these.",
            },
            {
              Kor: "누나는 수돗물에 씻겨 내려가는 보도 타일 위의 피를 보며 말했었다. ",
              Eng: "… his older sister had said, as she watched the blood on top of the pavement tiles being washed away in the water from the tap.",
            },
          ],
        },
        {
          entryName: "~에 ... ~에",
          typicalExample: "【우리넨, 목공에 용접에】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation: "Tedious lists in NOUN1에, NOUN2에, etc.",
          sentences: [
            {
              Kor: "우리넨, 목공에 용접에",
              Eng: "as for me, I know how to work as a carpenter, a welder ...",
            },
            {
              Kor: "옷값에 약값에 식비에 ",
              Eng: "money for clothes, for medicine, for food ...",
            },
            {
              Kor: "인절미에, 우동에... ",
              Eng: "rice cakes and udon, and ...",
            },
          ],
        },
        {
          entryName: "에게1 ",
          typicalExample: "【왜놈들에게 병정으로 끌려 】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "Particle 에게 marking the agent of a passive: by, by/at the hands of...",
          sentences: [
            {
              Kor: "왜놈들에게 병정으로 끌려 ",
              Eng: "[those who had been] dragged off by the Japs to be a soldier ...",
            },
            {
              Kor: "우리 군대에게 쫓겨 ",
              Eng: "[they were ] driven [north] by our own army ...",
            },
            {
              Kor: "누에에게 먹히는 뽕잎같이",
              Eng: "like mulberry leaves being eaten by a silkworm ...",
            },
            {
              Kor: "B부인에게 불려가 ",
              Eng: "[he] would be called in to see Mrs. B … (불리- is the passive from 부르- + 가 get called over/in to.)",
            },
          ],
        },
        {
          entryName: "에게까지 ",
          typicalExample: "【그 녀석에게까지 무슨 일이 있겠니】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Dative particle 에게 + particle 까지: even to/unto (him)",
          sentences: [
            {
              Kor: "그 녀석에게까지 무슨 일이 있겠니",
              Eng: "[What sins have we committed that] would be so serious as to cause him to suffer?",
            },
            {
              Kor: "그가 마을 아낙네들에게까지 ",
              Eng: "[the words he spoke] even to the village womenfolk ...",
            },
          ],
        },
        {
          entryName: "에게까지도 ",
          typicalExample: "【이름 없는 잡지사의 기자에게까지도 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation: "Even to/up to, as far as (and including).",
          sentences: [
            {
              Kor: "이름 없는 잡지사의 기자에게까지도 ",
              Eng: "even to reporters from nameless magazines ...",
            },
          ],
        },
        {
          entryName: "에게나 ",
          typicalExample:
            "【창백하고 아름다운 소녀에게나 어울리는 병이었다.  】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Particle 에게 plus -(이)나: or the like, like, or something, or so; the likes of, any; at least, anyway, just (used in gently/vaguely urging way), even if it is not of particular interest",
          sentences: [
            {
              Kor: "창백하고 아름다운 소녀에게나 어울리는 병이었다.  ",
              Eng: "It was a design appropriate for the likes of a pale, beautiful little girl.",
            },
            {
              Kor: "그 친구에게나 가볼까? ",
              Eng: "Shall we go over [to visit] that friend tonight, or something like that?",
            },
          ],
        },
        {
          entryName: "에게다(가)",
          typicalExample:
            "【남에게다 나의 의견이나 고집을 굳이 세우려 들 줄을 몰랐다.】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "To (a person). I.e., dative particle 에게 + transferentive -(이)다(가).",
          sentences: [
            {
              Kor: "남에게다 나의 의견이나 고집을 굳이 세우려 들 줄을 몰랐다.",
              Eng: "I didn't know that I would try so adamantly to enforce my opinions on others and have my way.",
            },
          ],
        },
        {
          entryName: "에게 대한",
          typicalExample: "【그에게 대한 반감】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "The pattern in NOUN에 대하- be about, regarding (with respect to inanimate objects) becomes NOUN에게 대하- with humans/animates.",
          sentences: [
            { Kor: "그에게 대한 반감", Eng: "[my] aversion to him" },
            {
              Kor: "바우의 봉네에게 대한 숨은 정 ",
              Eng: "the secret affection Pau had for Pongne ...",
            },
          ],
        },
        {
          entryName: "에게로 【너에게로 다가간다】",
          typicalExample: "【나에게로 향하며】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "In the direction of; toward/to (an animate thing). The 로 emphasizes the directionality. ",
          sentences: [
            { Kor: "나에게로 향하며", Eng: "turned to face me and ..." },
            {
              Kor: "나의 시선이 박에게로 갔을 때",
              Eng: "when my gaze moved to Pak ...",
            },
            {
              Kor: "새끼줄을 정씨에게로 권했다",
              Eng: "He passed the rope to Mr. Chŏng.",
            },
            { Kor: "그들에게로 걸어 나왔다", Eng: "She walked toward them." },
            { Kor: "바우에게로 되돌아 왔다", Eng: "It echoed back to Pau." },
            {
              Kor: "이장 영감은 마을 사람들에게로 돌아섰다",
              Eng: "The village head turned toward the villagers.",
            },
            {
              Kor: "그들에게로 다가오기를 꺼려하며",
              Eng: "she hesitated to approach them ...",
            },
          ],
        },
        {
          entryName: "에게서",
          typicalExample: "【여자에게서는 희미한 향수 ... 냄새가 났다. 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation: "From (a person or living thing)",
          sentences: [
            {
              Kor: "여자에게서는 희미한 향수 ... 냄새가 났다. ",
              Eng: "A faint scent of perfume came from the woman.",
            },
            {
              Kor: "경욱에게서 비롯된 것이었다 ",
              Eng: "(Going to galleries) started from (when I was seeing) Kyŏnguk.",
            },
            {
              Kor: "도야지고기 장사 할멈은 아들에게서 온 편지 피봉을 내어보이고",
              Eng: "The porkmongering granny took out from its envelope the letter that the children had brought her, and ...",
            },
            {
              Kor: "이장영감은 덕이에게서 그 말을 듣고는 놀라지 않았다.  ",
              Eng: "Mr. Lee was not surprised when he heard the words from Dŏki. ",
            },
            {
              Kor: "또 그에게서 풍기는 지적(知的) 분위기라고는 하지만 ",
              Eng: "You could say that he had an intellectual air about him but...",
            },
            {
              Kor: "지금까지 단 한 번도 남자에게서 만족을 얻어본 적이 없다.",
              Eng: "I have never even once been satisfied by a man.  ",
            },
            {
              Kor: "나는 도마뱀에게서 시선을 거둔다.  ",
              Eng: 'I shifted my gaze away from the "lizard".  ',
            },
            {
              Kor: "일선의 친구에게서 군사우편이 오기라도 하면 나 몰래 그것을 찢어 버리곤 하였었다  ",
              Eng: "If any military mail came from my friend in Ilsan, my mother would open and read it behind my back.",
            },
            {
              Kor: "그러다가 박희완 영감에게서 들은 말이었다. ",
              Eng: "That is how I came to hear those words from Mr. Park.  ",
            },
            {
              Kor: "초시는 이날 저녁에 박희완 영감에게서 들은 이야기를 딸에게 하였다. ",
              Eng: "That same night Mr. Cho told his daughter all he had heard from Mr. Park.",
            },
            {
              Kor: "찬 약주를 그대로 마시는 것은 일 년 내 남에게서 추잡하지 않고 좋은 말만 들으라는 축수이며 ",
              Eng: "Drinking cold rice wine is an invocation that for the next year I won't hear any indecent comments but only good things from my boyfriend.   ",
            },
          ],
        },
        {
          entryName: "에게 비하여 ",
          typicalExample: "【새끼 학들은 어미에게 비하여 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "In comparison to (a person), compared with (a person).",
          sentences: [
            {
              Kor: "새끼 학들은 어미에게 비하여 ",
              Eng: "the chicks, compared to their mother ...",
            },
          ],
        },
        {
          entryName: "에게 있어서",
          typicalExample: "【여자에게 있어서 그는 없는 사람이었다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "LIVING THING에게 있어서: as far as LIVING THING is concerned, with respect to LIVING THING, as regards LIVING THING. Formal, bookish.",
          sentences: [
            {
              Kor: "여자에게 있어서 그는 없는 사람이었다",
              Eng: "As far as she was concerned, he did not exist.",
            },
          ],
        },
        {
          entryName: "(에) 겹다",
          typicalExample: "【그는 취흥에 겨워서 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN(에) 겹다, 겨워요: is too much for one, is extreme, excessive",
          sentences: [
            {
              Kor: "그는 취흥에 겨워서 ",
              Eng: "unable to control his drunken excitement",
            },
            { Kor: "혼자 신명겨워했다 ", Eng: "He entertained himself." },
            {
              Kor: "내내 미련겨워하기를 마지아니하는 것이 항용 아녀자의 넓지 못한 속  ",
              Eng: "Her unchanging cold-heartedness showed even on her deathbed when she did not show any hints of regret. ",
            },
          ],
        },
        {
          entryName: "에까지 ",
          typicalExample: "【천리 밖의 사람들에까지 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Directional particle 에 + 까지: up to, to; right up to and including; even as",
          sentences: [
            {
              Kor: "천리 밖의 사람들에까지 ",
              Eng: "as far as to people more than 1000 li away ...",
            },
            {
              Kor: "임종하는 자리에까지 ",
              Eng: "to the point of waiting for someone on their deathbed ...",
            },
            {
              Kor: "우리는 그 여자의 집 앞에까지 왔다",
              Eng: "We arrived at her house.",
            },
            { Kor: "중앙에까지 ", Eng: "all the way up to Chungang ..." },
            {
              Kor: "아득히 영마루에까지 ",
              Eng: "even as far as the distant mountain ridge...",
            },
            {
              Kor: "건너편 낭에까지 날았다 ",
              Eng: "[The baby cranes] flew as far as the opposite cliff. ",
            },
            {
              Kor: "둥우리 밖에까지 빼내는 것이었다",
              Eng: "[They] would stretch [their necks] even beyond the nest.",
            },
            {
              Kor: "어제는 우물가에까지 날았었다",
              Eng: "Yesterday they had flown even as far as the well.",
            },
            {
              Kor: '"그 사람은 감옥에까지 갔었던 사람야."',
              Eng: "“He's somebody who even went to jail once way back when.”",
            },
          ],
        },
        {
          entryName: "에까지도 ",
          typicalExample: "【집안에까지도 여행 목적을 숨기고】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "에까지 up to, to plus particle 도 even. Particle 도 renders the delimiter 까지 even more emphatic.",
          sentences: [
            {
              Kor: "집안에까지도 여행 목적을 숨기고",
              Eng: "[Mr. K and myself] hid out travel plans even from our very homes (families) ...",
            },
          ],
        },
        {
          entryName: "에나 ",
          typicalExample: "【읍내에나 있는】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Locative 에 + -(이)나: or the like, like, or something, or so; the likes of, any; at least, anyway, just (used in gently/vaguely urging way), even if it is not of particular interest ",
          sentences: [
            {
              Kor: "읍내에나 있는",
              Eng: "[a 'Seoul Restaurant'] of the sort that one finds only in a town ...",
            },
            {
              Kor: "바닷가에나 가볼까 하는데요",
              Eng: "I'm thinking of going to the beach or something.",
            },
          ],
        },
        {
          entryName: "에다(가)1",
          typicalExample: "【배낭을 한쪽 어깨에다 걸쳐메면서 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Particle 에 + copula transferentive -(이)다(가) on top of, in addition to, thrown into the bargain. Shows movement or transfer of one thing into or onto another or on top of, to boot, in addition to.",
          sentences: [
            {
              Kor: "배낭을 한쪽 어깨에다 걸쳐메면서 ",
              Eng: "throwing the backpack over one shoulder, he ...",
            },
            {
              Kor: "여자가 큰 솥에다 우거지국을 끓이고 있었고",
              Eng: "A woman was cooking up a cabbage-leaf soup in a big pot, and ...",
            },
            {
              Kor: "백화는 소매가 해진 헌 코트에다 무릎이 튀어나온 바지를 입었고",
              Eng: "In addition to the old coat with worn-out sleeves, Baekhwa was wearing pants that bared her knees.   ",
            },
            {
              Kor: "불가에다 쬐고 있던 잠바",
              Eng: "the jumper that [he was] warming by the fire",
            },
            {
              Kor: "공사판 사람들에다 ",
              Eng: "on top of the construction workers ...",
            },
            {
              Kor: "음식에다 집어넣고 ",
              Eng: "put [the wine] into the food, and ...; add [the wine] to the food, and…",
            },
            {
              Kor: "이장 영감은 눈에다 힘을 주고",
              Eng: "The village chief Yi strained his eyes ...",
            },
            {
              Kor: "바우는 마을에다 들리도록",
              Eng: "[shouted] at the village so as to be heard ...",
            },
            {
              Kor: "연령답잖게 건장한 체구에다 ",
              Eng: "on top of a sturdy physique not typical for his age ...",
            },
          ],
        },
        {
          entryName: "에다(가)2",
          typicalExample: "【토요일 오후에다 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Particle 에 + copula transferentive -(이)다(가) on top of, in addition to, thrown into the bargain. In this usage, the function is equivalent to -(이)ㄴ데다가 on top of being...",
          sentences: [
            {
              Kor: "토요일 오후에다 ",
              Eng: "on top of it being Saturday afternoon ...",
            },
            {
              Kor: "월말에다가 토요일이 되어서 좀 바쁘다",
              Eng: "On top of its being the end of the month, it’s also Saturday, so I'm a little busy.",
            },
          ],
        },
        {
          entryName: "에다(가) ",
          typicalExample: "【그런 절박한 친구에다 대면 = 친구에다가 대면 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Transferentive -다(가) on directional 에 to stress a shift in place.",
          sentences: [
            {
              Kor: "그런 절박한 친구에다 대면 = 친구에다가 대면 ",
              Eng: "whenever he ran across (or: compared himself with) such a hard-up friend ...",
            },
          ],
        },
        {
          entryName: "에(다) 대고",
          typicalExample:
            "【그의 모친이 우리집 우물을 먼저 퍼가고 대문간 흙을 몰래 파가는 것은커녕 우리집에다 대고 더위를 팔더라도  】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "NOUN에(다) 대고: with respect to NOUN, vis a vis NOUN; in the direction of NOUN, facing NOUN",
          sentences: [
            {
              Kor: "그의 모친이 우리집 우물을 먼저 퍼가고 대문간 흙을 몰래 파가는 것은커녕 우리집에다 대고 더위를 팔더라도  ",
              Eng: "Even if his mother comes and takes the water from our well first, steals the dirt from beneath our front gate and goes as far as to argue with us that it's because of the heat... ",
            },
            { Kor: "이것은 달에다 대고 ", Eng: "do this facing the moon ..." },
            {
              Kor: "영달이는 그의 뒤통수에다 대고 말했다",
              Eng: "Yŏngdal spoke to the back of his head. ",
            },
            {
              Kor: "문을 열고 나오는 그들의 뒷덜미에다 대고 여자가 소리쳤다. ",
              Eng: "The woman yelled at the backs of the people who were coming out the door. ",
            },
          ],
        },
        {
          entryName: "에(다) 대면 ",
          typicalExample: "【트럭도에서 자기가 본 불에다 대면 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "NOUN에(다) 대면: [when] compared to NOUN, in contrast with NOUN, juxtaposed with NOUN",
          sentences: [
            {
              Kor: "트럭도에서 자기가 본 불에다 대면 ",
              Eng: "in comparison with the fires he saw on the Truc islands ...",
            },
            {
              Kor: "안변 따위에 대면 ",
              Eng: "compared to a place like Anbyon...",
            },
          ],
        },
        {
          entryName: "에 대하여(서) ",
          typicalExample: "【노인들에 대하여 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "More literary form of colloquial NOUN에 대해(서): referring (to), concerning, concerned (with), about; toward, to, against, vis a vis",
          sentences: [
            { Kor: "노인들에 대하여 ", Eng: "concerning the old folks ..." },
          ],
        },
        {
          entryName: "에 대해(서)",
          typicalExample: "【윤호는 그 책에 대해 별로 알려고 하지 않았다. 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "NOUN에 대해(서): with respect to NOUN; about NOUN; concerning NOUN",
          sentences: [
            {
              Kor: "윤호는 그 책에 대해 별로 알려고 하지 않았다. ",
              Eng: "Yunho didn't make any particular efforts to learn about that book. ",
            },
          ],
        },
        {
          entryName: "에 대한",
          typicalExample: "【재능에 대한 보수입니다】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "NOUN에 대한 (modifier form of NOUN에 대하여(서)): referring to, about; concerning, vis a vis with respect to",
          sentences: [
            {
              Kor: "재능에 대한 보수입니다",
              Eng: "It's in consideration of their abilities.",
            },
            {
              Kor: "판소리 적벽가(赤壁歌)에 대한 댓가로",
              Eng: 'as compensation for performing "Chokpyŏk’ka," a p\'ansori ...',
            },
          ],
        },
        {
          entryName: "에도 ",
          typicalExample: "【어떤 사회에도 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation: "Particle 에 + particle 도: to/at/in...also/even/either",
          sentences: [
            { Kor: "어떤 사회에도 ", Eng: "in whichever society ..." },
            {
              Kor: "배꼽까지 단추를 풀어 헤친 털이 부얼부얼한 가슴에도 한 줄의 금사슬이 늘어져 있는 것을 보았다  ",
              Eng: "I could see a gold chain necklace amidst the unruly chest hair that was spilling out from his unbuttoned shirt.    ",
            },
            {
              Kor: "여자는 그것이 자기 머리속에서 지어낸 공상임에도 생각을 해볼 수가 없었다  ",
              Eng: "The woman couldn't think about it even if it was through daydreams in her head.  ",
            },
            {
              Kor: "옆자리의 그들은 연주가 시작된 뒤에도 쉬지 않고 지껄였고 여자는 연신 춥다고 발을 동동 굴렀다.  ",
              Eng: "The couple sitting next to me chatted throughout the entire performance and the woman continuously stomped her feet, complaining that she was cold.",
            },
            {
              Kor: "이렇게 아버지의 권위에 대하여 처음으로 야유한 사람은 뜻밖에도 인자였다.  ",
              Eng: "The first person to banter my father on his authority was unexpectedly Inja.  ",
            },
            {
              Kor: "안채에도 불은 환했으나 기척은 없었다.",
              Eng: "The lights were on in the main part of the house but no signs of anybody being there. ",
            },
            {
              Kor: "탄실이가 시집을 가던  해에도 그랬다.  ",
              Eng: "It had been that way for Tansil's wedding too. ",
            },
            {
              Kor: "마을 사람들의 눈에도 눈물이 글썽 괴어 있었다",
              Eng: "There were tears glistening even in the eyes of the villagers.  ",
            },
            {
              Kor: "한방의사의 손에도 십 원짜리 두 장이 갔다. ",
              Eng: "Two 10 wŏn bills went to the Chinese herbal doctor as well.",
            },
            {
              Kor: "나는 밥값에도 못 미치는 돈을 냈을 뿐이었다.",
              Eng: "I hadn't even paid enough money for the food, let alone the rent. ",
            },
            {
              Kor: "규슈 탄광에 있어도 보고,  오오사까 철공장에도 몸을 담아 보았다.  ",
              Eng: "I've worked in the coal mines in Kyushu and I've also tried my hand at working in the ironworks industry in Osaka.  ",
            },
            {
              Kor: "그런 중에도 몸만 떠나왔다  ",
              Eng: "Even then only my body left and my heart...",
            },
            {
              Kor: "그 남자는 대학을 졸업하고 아무 곳에도 취직하지 못한 채  ",
              Eng: "After graduating from university, he wasn't able to get a job anywhere...",
            },
            {
              Kor: "지수를 바라보기에도 지쳤다는 듯 눈을 감고",
              Eng: "He closed his eyes, as though he was too tired to even look at Chisu...",
            },
            {
              Kor: "어제 저녁 서울역에서 기차를 탈 때에도  ",
              Eng: "Even when I was getting on the train last night at the station in Seoul...",
            },
            {
              Kor: "나처럼 대학엔 문전에도 가보지 못한 사람은 서러워서 살겠어요?  ",
              Eng: "How is someone like me, who hasn't even gone to the front gates of a university, supposed to feel? ",
            },
            {
              Kor: "20대에도 애인 없던 시절이 있었는데 뭘. ",
              Eng: "There were periods in my twenties when I wasn't dating anybody, anyways.  ",
            },
            {
              Kor: "그런데다 오늘 아침에도 밥은 역시 딱 한 공기씩이었다. ",
              Eng: "On top of everything we only had one bowl of rice each for breakfast again.",
            },
          ],
        },
        {
          entryName: "에 따라(서)",
          typicalExample: "【양씨의 탈진함에 따라 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "NOUN(에) 따라(서): in accordance with NOUN, in conformity to (with) NOUN, according to NOUN. Usually the preceding noun is marked with particle 에, but we also find examples with particle 을 ~ 를. ",
          sentences: [
            {
              Kor: "양씨의 탈진함에 따라 ",
              Eng: "in accordance with Mr. Yang's exhaustion ...",
            },
            {
              Kor: "주변 환경에 따라 색깔을 바꾸는 카멜레온 ",
              Eng: "the chameleon, who changes colour according to his surroundings",
            },
            {
              Kor: "듣기에 따라서는 으시시할 수도 있는 말이었지만 나는 왠지 신기하게만 느껴졌다.  ",
              Eng: "Depending on how you understood it, the words could be eerie but I was simply amazed.  ",
            },
          ],
        },
        {
          entryName: "에라도",
          typicalExample:
            "【그도 돈벌이 좀 잘하는 사람의 집에라도 간간 찾아 가면】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Particle 에 + pseudo-particle -(이)라도: even if (it be) just (or the) like (= -(이)나); or even any, some even if it be [just/only] even just. ",
          sentences: [
            {
              Kor: "그도 돈벌이 좀 잘하는 사람의 집에라도 간간 찾아 가면",
              Eng: "if she went along occasionally to the home of a reasonably well-off man [she, too, could...] (Here on directional particle 에. Lit.: even if it be just to a slightly well-off man's house...)",
            },
            { Kor: "당장에라도", Eng: "any minute ..." },
            {
              Kor: "시방에라도 보일 듯했다",
              Eng: "… as if [the tiger] would appear any second.",
            },
            {
              Kor: "그녀는 아이들을 데리고 시장에라도 간 모양이었다.",
              Eng: "She appeared to have taken the children to the market or something.",
            },
            {
              Kor: "금세 그대로 하늘에라도 오를 듯 싶다",
              Eng: "It's as if he could get up and fly up to the sky (or the like).",
            },
          ],
        },
        {
          entryName: "에라두",
          typicalExample: "【내일 당장에라두 집에 갈 것처럼 말해요】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "내일 당장에라두 집에 갈 것처럼 말해요",
              Eng: "[These women talk just as if] they would go home tomorrow at the drop of a hat.",
            },
          ],
        },
        {
          entryName: "에만",
          typicalExample: "【그래서 사실에만 충실했다.  】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation: "에만, i.e., particle 에 emphasized with particle 만. ",
          sentences: [
            {
              Kor: "그래서 사실에만 충실했다.  ",
              Eng: "And so he remained faithful to just facts.",
            },
          ],
        },
        {
          entryName: "에 불과하다 ",
          typicalExample: "【한 예에 불과하고 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Be no more than; be nothing more than...",
          sentences: [
            { Kor: "한 예에 불과하고 ", Eng: "this was only one example" },
            {
              Kor: "그러나 이제 시작에 불과하다는 것을 윤호는 알았다.  ",
              Eng: "Yunho knew that this was nothing but the beginning.",
            },
          ],
        },
        {
          entryName: "에 비하면",
          typicalExample:
            "【죽으나 사나 이 놈의 회사에 모가지 붙들려 있는 것에 비하면  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN에 비하면: compared to/with NOUN ",
          sentences: [
            {
              Kor: "죽으나 사나 이 놈의 회사에 모가지 붙들려 있는 것에 비하면  ",
              Eng: "compared to hanging by the neck for dear life at this wretched company",
            },
          ],
        },
        {
          entryName: "에 비해(서)",
          typicalExample: "【장안빌딩에 비해 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN에 비해(서): as compared with (to); in comparison with...",
          sentences: [
            {
              Kor: "장안빌딩에 비해 ",
              Eng: "compared to the Changan building ",
            },
          ],
        },
        {
          entryName: "(에)서",
          typicalExample:
            "【무슨 일루 관청서 사는진 모르거든 = 사는지(는) 모르거든 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "Institutional/impersonal oblique subject in (에)서. ",
          sentences: [
            {
              Kor: "무슨 일루 관청서 사는진 모르거든 = 사는지(는) 모르거든 ",
              Eng: "I have no idea why [on account of what matter] the government office is buying, you see.",
            },
            {
              Kor: "그것은 '최후의 시장' 에서 사온 것이었다.  ",
              Eng: "It had been purchased from the flea market.",
            },
            {
              Kor: "노인은 널평상에서 일어서면서 화제를 바꾸었다  ",
              Eng: "The old man changed the topic as he stood up off the wooden bench.  ",
            },
            {
              Kor: "마지막 남은 담배에 불을 당기었는데도 방 안쪽에서는 소식이 없었다",
              Eng: "Even after he'd put out his last cigarette there were no signs of his wife from inside the room.",
            },
            {
              Kor: "벌써 며칠째 소녀는  학교서 돌아오는 길에 물장난이었다.  ",
              Eng: "For several days already the girl stopped to play in the water on her way from school.",
            },
            {
              Kor: "소녀는 개울에다 손을 잠그고 물장난을 하고 있는 것이다.  서울서는 이런 개울물을 보지 못하기나 한 듯이.  ",
              Eng: "The girl was playing with the stream water as though she had never seen any stream like it in Seoul.  ",
            },
            {
              Kor: "해발 1천 2백에 다다르는 운흥산은 영남에서도 손꼽히는 거산이다.  ",
              Eng: "Mt. Unhŭng, standing at 1200m above sea level, is a grand mountain acknowledged even in Yŏngnam.",
            },
            {
              Kor: "불은 산등에서 산등으로 들러붙어 골짜기로 타내려갔다.",
              Eng: "The fire burned from the mountain peaks and spread down into the valley.   ",
            },
            {
              Kor: "가지만 남은 나무들이 수십여 그루씩 들판가에서 바람에 흔들렸다.  ",
              Eng: "Tens of bare trees on the field path swayed in the wind.",
            },
            {
              Kor: "영마루에서 내려다 보면 꼭 새 둥우리  같았다.",
              Eng: "Looking down from the top of the mountain pass it looked just like a straw basket.",
            },
          ],
        },
        {
          entryName: "에서까지",
          typicalExample: "【왜놈들은 끝내 이 두메에서까지 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "PLACE에서까지: dynamic locative particle 에서 + 까지, even happening in PLACE; even at PLACE",
          sentences: [
            {
              Kor: "왜놈들은 끝내 이 두메에서까지 ",
              Eng: "the Japs [had managed to conscript soldiers] even in this remote mountain village",
            },
            {
              Kor: "집에서 듣는 것만도 지겨운데 밖에서까지 그만 마시라는 잔소리 들어 가며 ",
              Eng: "it was bad enough hearing it at home, but to have to listen to her nagging me to stop drinking even when we were out, too, …",
            },
          ],
        },
        {
          entryName: "에서까지도",
          typicalExample: "【혼인 잔치에서까지도 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Dynamic locative 에서 + 까지도: till also/ even; indeed even, even the very. The particle 도 here renders the delimiter 까지 even more emphatic.",
          sentences: [
            {
              Kor: "혼인 잔치에서까지도 ",
              Eng: "indeed even at wedding feasts ...",
            },
          ],
        },
        {
          entryName: "에서라도 ",
          typicalExample: "【처가 편에서라도 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Particle 에서 + pseudo-particle -(이)라도: (1) even if (it be) (2) just, (or the) like ( = 이나)",
          sentences: [
            {
              Kor: "처가 편에서라도 ",
              Eng: "at least from my wife's side ...",
            },
          ],
        },
        {
          entryName: "에서만이라도",
          typicalExample: "【마지막 라운드에서만이라도 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Particle sequence 에서만 + pseudo-particle -(이)라도: even if (it be); just (or the) like (= -(이)나); or even any, some even if it be [just/only] even just",
          sentences: [
            {
              Kor: "마지막 라운드에서만이라도 ",
              Eng: "at least in the final round",
            },
          ],
        },
        {
          entryName: "에서부터",
          typicalExample: "【이층에서부터는】",
          categoryKorean: "Complex Particle",
          categoryEnglish: "(Complex Particle)",
          translation: "Complex particle 에서부터 from (a place)",
          sentences: [
            { Kor: "이층에서부터는", Eng: "from the second floor …" },
            {
              Kor: "누구의 입에서부터인지 퍼져 나왔다",
              Eng: "… weren't sure from whose mouth it had originated, but it had spread; or,  it had spread, though heaven knows who started the rumor (followed by copula -이- + -ㄴ + postmodifier 지  for the uncertain fact of being; whether it is, wonder whether [how, what...])",
            },
            {
              Kor: "그의 손이 내 발등에서부터 무릎을 거쳐 허벅지를 천천히 애무한다. ",
              Eng: "His hand slowly caresses me, from my feet, crossing my knee, and over my thigh.",
            },
          ],
        },
        {
          entryName: "에서야",
          typicalExample:
            "【이 속에서야 무얼 보고 들을 수가 있나!　하고 다시금 한탄하였다】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Particle 에서 + particle 이야: only if it be; if (it be), when it comes to; even, indeed ",
          sentences: [
            {
              Kor: "이 속에서야 무얼 보고 들을 수가 있나!　하고 다시금 한탄하였다",
              Eng: '"What is there to see and hear in this place?" I sighed again.',
            },
          ],
        },
        {
          entryName: "에서의 ",
          typicalExample: "【이 땅에서의 일은  】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Compound particle sequence in 에서의, where 의 adnominalizes what precedes it (allows what precedes it to modify what follows it).",
          sentences: [
            { Kor: "이 땅에서의 일은  ", Eng: "Things here in this place …" },
            {
              Kor: "밖에서의 생활은 끔찍했다. ",
              Eng: "He hated life outside.",
            },
          ],
        },
        {
          entryName: "에서였다",
          typicalExample: "【어딜 가면 살 수 있겠는가' 하는 생각에서였다 】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation: "NOUN에서였다: it was from or owing to NOUN",
          sentences: [
            {
              Kor: "어딜 가면 살 수 있겠는가' 하는 생각에서였다 ",
              Eng: "t came from the thought that he would not be able to live anywhere. (Note also the pattern 'QUOTE'하는 생각 the thought to the effect that 'QUOTE'.)",
            },
          ],
        },
        {
          entryName: "에야",
          typicalExample:
            "【이번에야 빈터만 남은 고향을 구경하고 돌아오는 길에】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "Particle 에 + particle 이야: only if it be; if (it be), when it comes to; even, indeed",
          sentences: [
            {
              Kor: "이번에야 빈터만 남은 고향을 구경하고 돌아오는 길에",
              Eng: "On the way back from finally visiting my hometown, which had nothing left but vacant land... ",
            },
            {
              Kor: "주인 되는 자가 특별히 빚을 탕감해 주고, 작년 가을에야 놓아 준 것이었다 ",
              Eng: "The woman's master made an exception to write off her debts and let her go last fall.  ",
            },
            {
              Kor: "그 얼마 전에야 식탁 모퉁이에 끼어앉은 아내가 그에게 불쑥 물었다",
              Eng: "His wife, who had been sitting right at the corner of the table only a moment ago, suddenly asked...",
            },
            {
              Kor: "이미 정오가 가까운 시각에야 ",
              Eng: "only when it started to approach midday ...",
            },
            {
              Kor: "그때에야 바우를 찾는 줄을 알았다 ",
              Eng: "Only then did they realize that they were looking for Pau.",
            },
            {
              Kor: "그 유리병과 간장통을 외는 소리가 골목 안으로 사라져 갈 즈음에야 ",
              Eng: "When the sound of glass bottles and soy sauce cans could barely be heard from the alleys … ",
            },
            {
              Kor: "나는 그날 아침에야 출근하면서 넌지시 운을 떼었다.",
              Eng: "That morning, finally, as I was leaving for work, I piped up.",
            },
          ],
        },
        {
          entryName: "에 의하여(서) ",
          typicalExample:
            "【산들도 안개에 의하여 보이지 않는 먼 곳으로 유배당해 버리고 없었다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "On account of...; by (marking the agent in a passive).",
          sentences: [
            {
              Kor: "산들도 안개에 의하여 보이지 않는 먼 곳으로 유배당해 버리고 없었다",
              Eng: "Due to the fog, the mountains had been banished to a far place and could not be seen.",
            },
            {
              Kor: "모든 것이 세월에 의하여 내 마음 속에서 잊혀질 수 있다고 전보는 말하고 있었다 ",
              Eng: "The telegram implied that all things, on account of time, could be erased from my heart.",
            },
          ],
        },
        {
          entryName: "에 의해서",
          typicalExample: "【어머니에 의해서 골방에 처박혀졌고 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "On account of...; by (marking the agent in a passive).",
          sentences: [
            {
              Kor: "어머니에 의해서 골방에 처박혀졌고 ",
              Eng: "I was stuffed into a small room by my mother ...",
            },
          ],
        },
        {
          entryName: "에 있어서",
          typicalExample:
            "【그와 나와는 비교하여 보면, 과거에 있어서 가정적으로 내가 그보다 퍽 행복스러웠던 것은 사실이나 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN에 있어서: with respect to NOUN, as regards NOUN; as far as NOUN is concerned. Formal, bookish.",
          sentences: [
            {
              Kor: "그와 나와는 비교하여 보면, 과거에 있어서 가정적으로 내가 그보다 퍽 행복스러웠던 것은 사실이나 ",
              Eng: "If we compare our pasts, its true that my family life has been much happier than his but...",
            },
            {
              Kor: "유부녀 공갈에 있어서도 불소급의 원칙이 있는 것인즉 ",
              Eng: "Even with regards to pretending to be a married woman there are rules …",
            },
            {
              Kor: "그가 없을 때 그를 생각해 본 일이 없었으며 눈에서 멀어지면 마음에서 멀어진다는 속담처럼 여자에게 있어서 그는 없는 사람이었다. ",
              Eng: "She had never thought about him when he wasn't around and according to the saying \"out of sight, out of mind\", he didn't exist to her. ",
            },
          ],
        },
        {
          entryName: "에 지나지 않는다",
          typicalExample: "【냉수에 지나지 않는 듯했다】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN에 지나지 않-: be nothing but NOUN, be only NOUN, merely NOUN; nothing more than NOUN",
          sentences: [
            {
              Kor: "냉수에 지나지 않는 듯했다",
              Eng: "[the booze seemed to be] no more than a drink of cold water [to him]",
            },
            {
              Kor: "남쪽에 있는 큰 산 하나에 지나지 않았다 ",
              Eng: "It was nothing but another big mountain in the south.",
            },
            {
              Kor: "나는 언제나 맥없이 나자빠진 패자에 지나지 않았다 ",
              Eng: "I was always nothing more than a loser who feebly fell on his back.",
            },
            {
              Kor: "밀교의 교주에 지나지 않아요 ",
              Eng: "He is nothing more than a high priest of his esoteric religion.",
            },
            {
              Kor: "결코 야산에 지나지 않고 ",
              Eng: "It is not any different from any old hill ...",
            },
            {
              Kor: "영락한 선비에 지나지 않습니다 ",
              Eng: "… is nothing from than an old-style scholar down on his luck. ",
            },
          ],
        },
        {
          entryName: "엘 ",
          typicalExample: "【일본엘 갔다 나와 보니 】",
          categoryKorean: "Abbreviation",
          categoryEnglish: "(Abbreviation)",
          translation: "An abbreviation of -에를 to (marks goal of motion).",
          sentences: [
            {
              Kor: "일본엘 갔다 나와 보니 ",
              Eng: "having returned from going to Japan ...",
            },
            {
              Kor: "그의 집엘 당도하여 보니",
              Eng: "arriving at his house ...",
            },
            {
              Kor: "매부의 집엘 가서 만났다",
              Eng: "I went and met Mr. Yi at his brother-in-law's house.",
            },
            {
              Kor: "장터엘 나가려면",
              Eng: "as I had walked the dike on my way to the market ...",
            },
            {
              Kor: "아무리 죄 많이 진 놈이라도 천당엘 보내주는 것임에 틀림없다 ",
              Eng: "No matter how many sins the man had committed, he was sure that he would be sent to heaven.",
            },
            { Kor: "치악산엘 갑니다 ", Eng: "I'm going to Mt. Ch'iak." },
            {
              Kor: "면(面)엘 다녀왔다",
              Eng: "[Pau] visited the myŏn office. ",
            },
            {
              Kor: "자동차 길엘 가재도",
              Eng: "even ['if one were to suggest'] travelling by car (가재도 is an abbreviation of 가자고 해도.)",
            },
            {
              Kor: "서울서 무슨 공장엘 다니다 왔노라는 바우는",
              Eng: "Pau, who claimed he had returned after working in some factory in Seoul ...",
            },
            {
              Kor: "지난 봄엔 그 여잘 데리고 절엘 한번 갔었지 ",
              Eng: "Last spring, I took her once to a temple.",
            },
          ],
        },
        {
          entryName: "-(이)여 ",
          typicalExample: "【친구여】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "Poetic, archaic elegant vocative particle: 이여 after consonants, 여 after vowels. Martin treats -(이)여 as the heavy alternate of vocative -아/-야. There is an honorific version in -이시여.",
          sentences: [
            { Kor: "친구여", Eng: "Oh dear friends!" },
            { Kor: "새여", Eng: "Oh, my feathered friends!" },
            { Kor: "전보여", Eng: "Hey! Telegram! " },
            {
              Kor: '"남편이여, 아내를 사랑하라"라는 캐치프레이즈 ',
              Eng: 'the catch-phrase, "Dear Husband, love thy wife!"',
            },
            {
              Kor: "불을 피워 <망월이여어>를 하겠다는 것이었다  ",
              Eng: "He lit the pile on fire and said something about viewing the moon. ",
            },
          ],
        },
        {
          entryName: "-여",
          typicalExample: "【자네가 뉘기여? 】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "Dialect/substandard for the copula infinitive -여 ← -이어 (the standard sentence-final shape is -야). But this shape is allowed in standard Korean before 서: -여서.",
          sentences: [{ Kor: "자네가 뉘기여? ", Eng: "Who are you?" }],
        },
        {
          entryName: "여간(만) + NEGATIVE",
          typicalExample: "【여간 반갑지 않으셨겠지요 】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "여간(만) plus NEGATIVE (or implied negative) means very; not a little, to no small extent, quite, extremely, terribly. Adding the particle 만 heightens the sense of extremity.",
          sentences: [
            {
              Kor: "여간 반갑지 않으셨겠지요 ",
              Eng: "You must have been really happy to see her.",
            },
            {
              Kor: "어린것이 여간 잔망스럽지가 않어 ",
              Eng: "For such a young kid, she's amazingly wise and sentimental.",
            },
            {
              Kor: "바우는 여간 유식해진 것이 아니었다 ",
              Eng: "Pau had become very learned, indeed.",
            },
            {
              Kor: "그건 여간만 고통스러운 게 아니었다 ",
              Eng: "It was extremely painful.",
            },
            {
              Kor: "여간 반가와하는 품이 아니었다",
              Eng: "He was very happy to see me.",
            },
          ],
        },
        {
          entryName: "-여서",
          typicalExample: "【가게를 시작한 지 얼마 안된때여서 】",
          categoryKorean: "연결어미 Connective ending/준꼴 Contraction",
          categoryEnglish: "(연결어미 Connective ending/준꼴 Contraction)",
          translation:
            "Copula -이- plus infinitive -어 (→ -여) plus 서. In other words, the verb is contracted from -이어서.",
          sentences: [
            {
              Kor: "가게를 시작한 지 얼마 안된때여서 ",
              Eng: "because it was a time not long after they had started the store …",
            },
            {
              Kor: "하나 둘 줄기 시작하여서 마지막에 복녀를 판 팔십 원이 그의 마지막 재산이었다  ",
              Eng: "His wealth kept decreasing bit by bit and in the end all he had was the 80 wŏn that he had gotten by selling the girl.",
            },
            {
              Kor: "화도 나고 고국산천이 그립기도 하여서 훌쩍 뛰어나 왔다가 ",
              Eng: "I came out in a hurry because I was angry and I also longed for the landscape of my hometown.",
            },
            {
              Kor: "기록 같은 것을 죄다 대신 하여 주기가 성가시기는 하였으나  ",
              Eng: "Although it was angering to have to record everything for him...",
            },
            {
              Kor: "유치장 안에서 담배를 달라고 야료를 하여서, 낮번을 하던 간수가 점심과 저녁을 굶겼다.  ",
              Eng: "Due to the demands for cigarettes from those inside the police cell, Gansu took away their lunch and dinner rations.  ",
            },
            {
              Kor: "“아냐, 남자한테서 채여서야”  ",
              Eng: '"Nah, it\'s because she was dumped by a man."',
            },
            {
              Kor: "딸이 평양으로 대구로 다니며 지방 순회까지 하여서  ",
              Eng: "Because their daughter went on a provincial tour visiting Pyŏngyang and Taegu on the way...",
            },
            {
              Kor: "내가 앞으로 좀더 큰 소설가 노릇을 하기 위하여서는  ",
              Eng: "In order to become an even greater novelist in the future...",
            },
            {
              Kor: "얼굴 맞댈 스님이라곤 주지승밖에 없는 정도여서   ",
              Eng: "Since the only monk who would show his face was just Ju Jisŭng... ",
            },
            {
              Kor: "톱니바퀴처럼 맞물리면서 돌아가는 게 미국살이여서요.  ",
              Eng: "Living in America is just like cog wheels grinding together.  ",
            },
          ],
        },
        {
          entryName: "-여야 한다",
          typicalExample: "【선비여야합니다】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contracted after a vowel from NOUN-이어야 한다: must be NOUN ",
          sentences: [
            { Kor: "선비여야합니다", Eng: "He must be a gentleman." },
          ],
        },
        {
          entryName: "영 + NEGATIVE",
          typicalExample: "【영 어찌되는 셈판인지 알수가 없는 일이었다】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "영 plus a following negative means absolutely no...; not any...",
          sentences: [
            {
              Kor: "영 어찌되는 셈판인지 알수가 없는 일이었다",
              Eng: "[Even in Schoolmaster Pak's opinion], there was absolutely no way to figure out that nonsense.",
            },
            {
              Kor: "몸이 영 안 좋아, 라고 하기에",
              Eng: "When he says 'I'm really not feeling well,' …",
            },
            {
              Kor: "자꾸만 으실으실 추운 게 영 발동이 걸리질 않는다.  ",
              Eng: "The car just wouldn't start in the shivering cold weather. ",
            },
          ],
        },
        {
          entryName: "예서",
          typicalExample: "【예서 하룻밤 푹 묵었다 가시구려】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation: "Colloquial/substandard for 여기서.",
          sentences: [
            {
              Kor: "예서 하룻밤 푹 묵었다 가시구려",
              Eng: "Why don't you guys stay the night and take a rest before you go?",
            },
          ],
        },
        {
          entryName: "-오",
          typicalExample: "【미안하오 】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation: "[Usage]",
          sentences: [
            { Kor: "미안하오 ", Eng: "I'm sorry." },
            { Kor: "그것 나르 주오.  ", Eng: "Could you carry that for me?" },
            {
              Kor: "낭자는 마땅히 몸을 건지는 길을 택함이 옳을까 하오.  춘향은 대답하였다.",
              Eng: "The virgin decided that it best to pick the path that would allow her to survive.",
            },
            {
              Kor: "나는 그가 아버님과 집안 얘기를 하길래 다만 듣고 있었을 뿐이오.  ",
              Eng: "Since you and your father were talking about family matters, I thought I'd just listen.",
            },
            { Kor: "내 고향이오  ", Eng: "This is my hometown.  " },
            { Kor: "“여보시오“  ", Eng: "Excuse me, sir. " },
          ],
        },
        {
          entryName: "-오나",
          typicalExample: "【황공하오나】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation:
            "Archaic/old fashioned for -(으)나, where the -오- marks the form as humble (used to a superior). ",
          sentences: [{ Kor: "황공하오나", Eng: "dreaded it, but..." }],
        },
        {
          entryName: "오너라 ",
          typicalExample: "【닦아오너라】",
          categoryKorean: "Plain Style Command",
          categoryEnglish: "(Plain Style Command)",
          translation: "Irregular Plain Style Command for 오- come.",
          sentences: [
            { Kor: "닦아오너라", Eng: "Polish them ['and come back']!" },
          ],
        },
        {
          entryName: "-오니",
          typicalExample:
            "【그러하오니 일각지체를 부당하며.  편지를 툭 떨어뜨리고 이몽룡은 넋나간 듯이 허공을 노려보았다. 】",
          categoryKorean: "Plain Style Command",
          categoryEnglish: "(Plain Style Command)",
          translation:
            "Archaic/old-fashioned for -(으)니, where the -오- marks the form as humble (used to a superior). ",
          sentences: [
            {
              Kor: "그러하오니 일각지체를 부당하며.  편지를 툭 떨어뜨리고 이몽룡은 넋나간 듯이 허공을 노려보았다. ",
              Eng: "",
            },
          ],
        },
        {
          entryName: "오로지",
          typicalExample: "【오로지 졸리다는 생각 뿐】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "The adverb 오로지 only, merely; it is just (that); alone, solely, exclusively often sets up the reader for a following pattern with 만 or 뿐.",
          sentences: [
            {
              Kor: "오로지 졸리다는 생각 뿐",
              Eng: "The only thought I had was that I was sleepy ...",
            },
            {
              Kor: "오로지 위엄과 신비로 자신을 위장하고 ",
              Eng: "He merely camouflages himself with majesty and mystery ...",
            },
            {
              Kor: "몸 안에 오로지 통증밖에는 다른 아무 것도 남아 있지 않는 듯한 순간 ",
              Eng: "Just when he felt that there was nothing left in his body except pain...",
            },
            {
              Kor: "오로지 그런 점에 유의하여 결혼한 남편이 그런 사람이 아닌 것을 이제와서 여자는 이상히 여겼다.",
              Eng: "She now found it strange that her husband did not actually have the qualities that she had valued when she married him.",
            },
            {
              Kor: "미간 언저리에 지는 구름짱 같은 신경질이나 입 근처에 서리는 못마땅의 표정은 오로지 나에게만 보이는 얼굴인가.  ",
              Eng: "Is the cloud of annoyance hovering above his brow or the look of distaste expressions that are only expressed to me?   ",
            },
            {
              Kor: "어떤 남자와 오로지 그 장면에 나타나 있는 그길 위에 서보는 것이었다. ",
              Eng: "[She wanted] to stand on the path where she and that man appeared.",
            },
          ],
        },
        {
          entryName: "-오만",
          typicalExample: "【하오만】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "만 however, but on the 하오 (Semiformal, or Blunt) Style.",
          sentences: [{ Kor: "하오만", Eng: "(do) but..." }],
        },
        {
          entryName: "-옵니다",
          typicalExample: "【여전하옵니다】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "For 합니다 where the -오- marks the form as humble (used to a superior). ",
          sentences: [{ Kor: "여전하옵니다", Eng: "it was as usual..." }],
        },
        {
          entryName: "-옵소서",
          typicalExample: "【천지지신 일월성신은 화위동심하옵소서.  】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Archaic -옵-소서 please do. Ending -소서 on its own sounds archaic/Biblical and fancy/high-fallutin'. Addition of -옵- makes the form humble (and even obsequious).",
          sentences: [
            {
              Kor: "천지지신 일월성신은 화위동심하옵소서.  ",
              Eng: "Dear Heavenly Spirits, dear Sun, Moon and Stars, please have pity on me!",
            },
            {
              Kor: "그는 이상한 설레임을 느끼면서 읽어내려갔다.  한양성 이공은 보옵소서. ",
              Eng: "As he read it through, he experienced a strange trembling—My dear Master Yi in Hanyang...",
            },
          ],
        },
        {
          entryName: "-와 ... -와 (-과 ...  -과)",
          typicalExample: "【노파와 나와의 인사 소개】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "NOUN1과 NOUN2과 where modern Korean usually drops the second 과.",
          sentences: [
            {
              Kor: "노파와 나와의 인사 소개",
              Eng: "the introduction between the old woman and myself",
            },
          ],
        },
        {
          entryName: "외로",
          typicalExample: "【예상 외로 이른 시각】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation: "NOUN 외로...: above and beyond NOUN",
          sentences: [
            {
              Kor: "예상 외로 이른 시각",
              Eng: "an earlier time than expected ",
            },
            {
              Kor: "그 마을의 뒷산에 상상외로 많은 꽃들이 피어 있다는 사실을 알고 난 뒤였다. ",
              Eng: "It was after he had discovered that the mountain behind the village had more flowers than one could imagine, that he... ",
            },
            {
              Kor: "두물머리의 음식점 같은 데 들어가 보면 아는 사람들을 의외로 많이 만나요  ",
              Eng: "When I go to restaurants in Tumulmuli, I see many more familiar faces than in other places.    ",
            },
          ],
        },
        {
          entryName: "요",
          typicalExample: "【요 영리한 게】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation: "Diminutive 요 for 이 this.",
          sentences: [
            { Kor: "요 영리한 게", Eng: "the brave thing ..." },
            { Kor: "요만 정도", Eng: "even this little bit ..." },
            { Kor: "요 속에는", Eng: "inside here ..." },
            { Kor: "요놈은", Eng: "this li'l guy ..." },
            { Kor: "요 개야 ", Eng: "Hey dog!" },
            {
              Kor: "요 큰길루 가실 꺼유?",
              Eng: "Are you going to take this big road? (Dialect for 가실 거이오?)",
            },
            { Kor: "서울을요?", Eng: "To Seoul, you say?" },
          ],
        },
        {
          entryName: "-요",
          typicalExample: "【몇 년만에 가는 거요? 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Abbreviated equivalent of -이오? I.e., copular -이- in the 하오 Style. Note that the 하오 Style—termed variously the Authoritative Style, Blunt Style, or Semi-Formal Style because of the way it sounds when used nowadays in standard Korean, functions differently in dialect—(often as an equivalent to Standard 해요 or Polite Style).",
          sentences: [
            {
              Kor: "몇 년만에 가는 거요? ",
              Eng: "How many years has it been since you've been there?",
            },
            { Kor: "그대루가 뭐요? ", Eng: 'Whadda ya mean "as before"?' },
          ],
        },
        {
          entryName: "-요 = -이요 = -이고.",
          typicalExample:
            "【그 시대의 지식인이요, 영락한 감은 있지만 엘리뜨의 일종입니다. 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "In mid-sentence, -(이)요 is equivalent to (and derived from) -이고, but with a literary flourish and a sense of insistence and/or emphasis. ",
          sentences: [
            {
              Kor: "그 시대의 지식인이요, 영락한 감은 있지만 엘리뜨의 일종입니다. ",
              Eng: "They used to be the intellectuals of their time, or in other words, fallen elites.  ",
            },
            {
              Kor: "바로 정보요 여론의 원천이 되었죠",
              Eng: "That was information, and also the source of public opinion.",
            },
            {
              Kor: "그 목청이요, 그 모습이었다",
              Eng: "It was the same old voice, the same old appearance [just as he remembered it].",
            },
          ],
        },
        {
          entryName: "-우【미안하우.】",
          typicalExample: "【내 토끼 도루 내주 】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation: "Dialect/substandard for Semi-Authoritative/Blunt -오. ",
          sentences: [
            {
              Kor: "내 토끼 도루 내주 ",
              Eng: "Give me back my rabbit. (For 도로 내 주우 ← 내 주오.)",
            },
            {
              Kor: "삼포엘 같이 가실라우? ",
              Eng: "Would you like to join me on the way to Samp'o? (For 가실라고 하오? Note also that the speaker here is a woman—백화—for whom -우/-수 is more appropriate than -오/-소.)",
            },
            {
              Kor: "왜 좀 놀다 갈려우? ",
              Eng: "Why, don't you intend to have some fun before you go? ",
            },
            {
              Kor: "절 으찌우? ",
              Eng: "What are we to do about that? (For 저를 어찌(하)오.)",
            },
            { Kor: "헐 말이 무슨 헐 말이우? ", Eng: "What is there to say?" },
          ],
        },
        {
          entryName: "-웁게도",
          typicalExample: "【놀라웁게도】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "Dialect/substandard treatment of ㅂ~w verbs. On the basis of modifier forms like 놀라운 from 놀랍다, some Korean speakers reanalyze ㅂ~w verbs as having an extra 우 in them, but still keep the ㅂ before consonants. This is not standard usage.",
          sentences: [{ Kor: "놀라웁게도", Eng: "to his astonishment ..." }],
        },
        {
          entryName: "워낙 ",
          typicalExample:
            "【워낙 고단했는지 오늘은 날짜만 써놓고 빈칸이었다. 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "워낙: ...'perhaps because' with modifier [-(으)ㄴ, -던, -었던, -었는, -는] + post-modifier 지 the uncertain fact whether. When left hanging in mid-sentence, you can interpret the pattern as having left out a following –도 모르지만: it may be the case that, i.e. perhaps [it is] because....",
          sentences: [
            {
              Kor: "워낙 고단했는지 오늘은 날짜만 써놓고 빈칸이었다. ",
              Eng: "She must have been quite exhausted, as today's entry is empty except for the date.",
            },
          ],
        },
        {
          entryName: "웬",
          typicalExample: "【처음엔 웬 애인인가 싶어 의아했다.】",
          categoryKorean: "Adjective",
          categoryEnglish: "(Adjective)",
          translation: "웬 NOUN: some (sort of) NOUN; some NOUN (or other)",
          sentences: [
            {
              Kor: "처음엔 웬 애인인가 싶어 의아했다.",
              Eng: "At first I was dubious as to who the boyfriend was.",
            },
            {
              Kor: "그녀답지 않게 웬 신랄함일까?",
              Eng: "What's with the seriousness, so unlike her?",
            },
            {
              Kor: "화장대 위에 웬 노트가 놓여 있는 걸 보았다. ",
              Eng: "I saw some sort of notebook lying on top of the dressing table.",
            },
            { Kor: "웬걸(요) ", Eng: "Goodness no!" },
            { Kor: "웬걸요", Eng: "Are you kidding? [What breakfast?]" },
            { Kor: "웬만한 일에는", Eng: "In most matters ..." },
            {
              Kor: "웬일인지 ",
              Eng: "goodness knows why ... ; or, for some unknown reason ...",
            },
          ],
        },
        {
          entryName: "위하- ",
          typicalExample: "【실패는 다음의 성공을 위한 밑거름이라고도 했다. 】",
          categoryKorean: "Adjective",
          categoryEnglish: "(Adjective)",
          translation:
            "NOUN(을) 위하-: be for the sake of NOUN (here in the modifier form) ",
          sentences: [
            {
              Kor: "실패는 다음의 성공을 위한 밑거름이라고도 했다. ",
              Eng: "And he also said that failure was fertilizer for the next success. ",
            },
          ],
        },
        {
          entryName: "위함이다",
          typicalExample: "【아내를 위로하고 만족시켜 주기 위함이었다 】",
          categoryKorean: "Adjective",
          categoryEnglish: "(Adjective)",
          translation: "NOUN(을) 위함이다: is for the sake of NOUN",
          sentences: [
            {
              Kor: "아내를 위로하고 만족시켜 주기 위함이었다 ",
              Eng: "Since it is all for the sake of comforting and satisfying my wife.",
            },
          ],
        },
        {
          entryName: "으",
          typicalExample: "【손으 대지 마라】",
          categoryKorean: "Dialect/Particle",
          categoryEnglish: "(Dialect/Particle)",
          translation:
            "으 is northeastern dialect for 'accusative' particle 을.",
          sentences: [
            { Kor: "손으 대지 마라", Eng: "Don't touch them." },
            {
              Kor: "한 그릇으 멕이거든 고마운 줄 모르구서리",
              Eng: "… doesn't know how to be thankful for the bowl he was fed. (With 멕이거든 as dialect for 먹이거든 and 모르구서리 for 모르고서.)",
            },
          ],
        },
        {
          entryName: "-으이",
          typicalExample: "【넉살두 좋으이 】",
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation:
            "A dialect equivalent of the Semiformal (하오) Style, which usually occurs only with adjectives. Some descriptions claim this style is equivalent to standard Familiar (하게) Style. Sometimes spelled as -의. ",
          sentences: [
            { Kor: "넉살두 좋으이 ", Eng: "You really are impudent!" },
          ],
        },
        {
          entryName: "은(요)",
          typicalExample: "【몇 분은요 】",
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation:
            'Rejoinder in NOUN + 은/는(요)? Whadda ya mean "NOUN"?  When the phrase being thrown back to the interlocutor in the rejoinder contains a verb, this comes out as VERB기는요? Whadda ya mean "VERB"?',
          sentences: [
            {
              Kor: "몇 분은요 ",
              Eng: "How many minutes??! [i.e., You must be joking!]",
            },
          ],
        },
        {
          entryName: "~은 NOUN인데",
          typicalExample: "【꿈은 꿈인데 아주 생생한 꿈이었다.】",
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation:
            "NOUN은 NOUN인데: it is/was a NOUN, and yet...; NOUN though it is/was, nonetheless... ",
          sentences: [
            {
              Kor: "꿈은 꿈인데 아주 생생한 꿈이었다.",
              Eng: "It was just a dream, but such a vivid one.",
            },
          ],
        },
        {
          entryName: "은 하-",
          typicalExample: "【막막은 하나 】",
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation:
            'VERBAL NOUN은 하-. Particles 은, 도, and 만 can sometimes intervene between an "inseparable" verbal noun and its following dummy verb 하다. Normally, descriptive  made up of a descriptive verbal noun + 하다 are inseparable, but the verbal noun can be separated from 하다 by 은, 도, or 만 for stylistic effect.',
          sentences: [
            {
              Kor: "막막은 하나 ",
              Eng: "is desolate, but ... (from 막막하다)",
            },
            {
              Kor: "원통은 하였으나 ",
              Eng: "mortified he was, but ...  (from 원통하다)",
            },
          ],
        },
        {
          entryName: "은 하다",
          typicalExample: "【막막은 하나 】",
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation:
            "Particle 은/는 하- on inseparable verbal noun—VN(은, 도, 만) + 하. Normally, descriptive verbs made up of a descriptive verbal noun + 하다 are inseparable, but the verbal noun can be separated from 하다 by 은, 도, or 만 for stylistic effect.",
          sentences: [
            { Kor: "막막은 하나 ", Eng: "is desolate, but ..." },
            { Kor: "원통은 하였으나 ", Eng: "mortified he was, but ..." },
          ],
        },
        {
          entryName: "을 ~ 를",
          typicalExample: "【그러기를 몇 해를 지나 】",
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation:
            "Accusative of duration, marking the duration of a verb. ",
          sentences: [
            {
              Kor: "그러기를 몇 해를 지나 ",
              Eng: "after passing a few years doing it like this …",
            },
            { Kor: "이십 오 년 동안을 ", Eng: "for 25 years ..." },
            {
              Kor: "이태 동안을 사는 것이 아니라",
              Eng: "They weren't living for two years, [rather, they were just surviving]. (NB: One could also have said 이태를 사는 것이.)",
            },
          ],
        },
        {
          entryName: "의 ",
          typicalExample: "【짐승의 하는 것】",
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation:
            "Use of 의 to mark the subject of a subordinate clause. Modern Korean is more likely now to mark this with 이/가.",
          sentences: [{ Kor: "짐승의 하는 것", Eng: "something beasts do" }],
        },
        {
          entryName: "-이",
          typicalExample: "【헛되이 학나무만 쳐다보았다】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation: "Adverb -이.",
          sentences: [
            {
              Kor: "헛되이 학나무만 쳐다보았다",
              Eng: "They just stared in vain at the crane tree. (Here, same as 헛되게.)",
            },
          ],
        },
        {
          entryName: "이",
          typicalExample: "【농부 하나이 】",
          categoryKorean: "Subject marker",
          categoryEnglish: "(Subject marker)",
          translation:
            "Archaic subject marker 이 on a noun ending in a vowel. Whereas modern Korean as two different shapes for the subject particle 이 and 가, Middle Korean had just 이. The noun 하나 'one' in Middle Korean actually ended in a final ㅎ, now lost. Thus, 하나이 is an archaic (and thus rather literary-sounding) equivalent of 하나가.",
          sentences: [
            { Kor: "농부 하나이 ", Eng: "a farmer" },
            {
              Kor: "욕망을 주체하지 못하는 이들이  ",
              Eng: "those who are unable to control their urges",
            },
            { Kor: "먼저 간 이가 ", Eng: "those who had gone first" },
            {
              Kor: "김삿갓 같은 이에게서 ",
              Eng: "Someone like Kim Sakkat ...",
            },
            {
              Kor: "그 모씨라는 이만 ",
              Eng: "only that guy, whatever his name was …",
            },
            { Kor: "돌아간 이 ", Eng: "the deceased (person)" },
            {
              Kor: "먼저 만난 이는 하씨였다 ",
              Eng: "The first person I met was Mr. Ha.",
            },
            {
              Kor: "입을 여는 이는 아무도 없었다",
              Eng: "lit.: No one opened their mouth; or, No one spoke a word.",
            },
            {
              Kor: "아는 이두 없을 거요",
              Eng: "There probably won't be anyone I know. (For 아는 이도 where 이 is person, people.)",
            },
          ],
        },
        {
          entryName: "-이-",
          typicalExample: "【곳곳에서 지수를 향해 번득였다.】",
          categoryKorean: "Subject marker",
          categoryEnglish: "(Subject marker)",
          translation:
            "Mimetic + -이- similar to -대다 and –거리다: does repeatedly/continuously; does and does; keeps doing, does on and on, does (keeps doing) it away; does again and again, does over and over again",
          sentences: [
            {
              Kor: "곳곳에서 지수를 향해 번득였다.",
              Eng: "… sparkled toward Chisu from here and there.",
            },
          ],
        },
        {
          entryName: "-(이)거나 ",
          typicalExample:
            "【자신을 그 구조의 핵심 밖으로 밀어낸 사회 체제에 대한 소극적인 반항이거나 나름의 자기성취 같은 것을 말합니다. 】",
          categoryKorean: "Subject marker",
          categoryEnglish: "(Subject marker)",
          translation:
            "Whether it is, is indifferently, (whatever) it is ( = -이든지, 이나). I.e., copula -이- + -거나.",
          sentences: [
            {
              Kor: "자신을 그 구조의 핵심 밖으로 밀어낸 사회 체제에 대한 소극적인 반항이거나 나름의 자기성취 같은 것을 말합니다. ",
              Eng: "I'm talking about the passivity or resistance to the social structure that has been pushed or one's personal accomplishments.  ",
            },
          ],
        },
        {
          entryName: "-(이)고1 ",
          typicalExample:
            "【나는 서울에서 무진까지의 천여리(千餘里) 길을 발가락이 몇 번이고 불어 터지도록 걸어서 내려왔고 】",
          categoryKorean: "Subject marker",
          categoryEnglish: "(Subject marker)",
          translation:
            "As a generalizer, in collocation with a WH-question word: whatever NOUN it might be, no matter what kind of NOUN each and every NOUN. In origin, this is probably the old Middle Korean (and contemporary Kyengsang dialect) question marker –고 reserved for WH-questions, but here attached directly to the copula -이- rather than to the modifier form of the copula in -인). ",
          sentences: [
            {
              Kor: "나는 서울에서 무진까지의 천여리(千餘里) 길을 발가락이 몇 번이고 불어 터지도록 걸어서 내려왔고 ",
              Eng: "I walked the 1000 or so li road from Seoul to Mujin, despite my repeatedly blistering toes.",
            },
            {
              Kor: "그들은 자꾸자꾸 솟아나오는 눈물을  몇 번이고 손등으로 닦았다. ",
              Eng: "They used the back of their hands to wipe away the endless stream of tears.",
            },
            {
              Kor: "그리고 어떤 구두고 한 켤레를 닦는 데 5분이 넘지 않았다. ",
              Eng: "It took no longer than 5 minutes to shine a pair of shoes, no matter what kind they were.  ",
            },
            {
              Kor: "전화는 언제까지고 그치지 않을 기세로 울리고 있었다. ",
              Eng: "The phone continued to ring off the hook, with no signs of stopping.",
            },
          ],
        },
        {
          entryName: "-(이)고2",
          typicalExample: "【핫도그고 고기만두 】",
          categoryKorean: "Subject marker",
          categoryEnglish: "(Subject marker)",
          translation: "NOUN이고 meaning and (NOUN하고).",
          sentences: [
            { Kor: "핫도그고 고기만두 ", Eng: "hot-dogs and meat dumplings" },
            {
              Kor: "언제부터 짐승 좇는 포수고 몰이꾼이었나요",
              Eng: "Since when did he become a beast chaser and hunter? (i.e., He had never been a beast chaser or a hunter.)",
            },
          ],
        },
        {
          entryName: "-(이)고 뭐고 (간에)",
          typicalExample: "【나는 번역이고 뭐고 다 때려치우고 】",
          categoryKorean: "Subject marker",
          categoryEnglish: "(Subject marker)",
          translation:
            "NOUN-(이)고 뭐고 [간에]: you can say what you like about NOUN and stuff, but...; NOUN, schmoun...",
          sentences: [
            {
              Kor: "나는 번역이고 뭐고 다 때려치우고 ",
              Eng: "I'd drop the translation and everything and ...",
            },
          ],
        },
        {
          entryName: "-(이)고 보매",
          typicalExample: "【그런 남편이고 보매 】",
          categoryKorean: "Subject marker",
          categoryEnglish: "(Subject marker)",
          translation:
            "NOUN(이)고 보매 = -고 보니: seeing as, seeing how, considering how.",
          sentences: [
            {
              Kor: "그런 남편이고 보매 ",
              Eng: "seeing as he was that sort of a husband ...",
            },
          ],
        },
        {
          entryName: "-(이)나1",
          typicalExample: "【그것도 안 되면 시골 가서 농사나 짓든지 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "Or the like; like; or something; or so; the likes of; any; at least; anyway; just (used in gently/vaguely urging)…even if it is not of particular interest.",
          sentences: [
            {
              Kor: "그것도 안 되면 시골 가서 농사나 짓든지 ",
              Eng: "Before I get any older, I should either emigrate or, if that doesn't work, go to the countryside and farm or something.",
            },
            {
              Kor: "곁다리로나 등장하는 것으로 ",
              Eng: "as a secondary thing or as something that just appears ...",
            },
            {
              Kor: "서투를 수 있다고나 할까? ",
              Eng: "Can one say that this is the extent to which [this place is so] amateurish? [or the like?]",
            },
            {
              Kor: "뭘 쥐뿔이나 안다구 그래?",
              Eng: "Why do you claim to know worthless things?",
            },
            {
              Kor: "금시발복이나 된 듯",
              Eng: "as if he had suddenly become a millionaire or something",
            },
            {
              Kor: "혹시 내가 심장마비로 졸도나 안할까",
              Eng: "I thought this would surely give me a heart attack (or something).",
            },
            {
              Kor: "해장술이나 먹으리라 하고 부지런히 내려와 보니",
              Eng: "He diligently came down, thinking he would probably at least get some hair-of-the-dog-that-bit-you alcohol or something but...",
            },
            {
              Kor: "양씨나 찾아달라고 ",
              Eng: "asking him to go look for Mr. Yang or something ...",
            },
            {
              Kor: "어디 가서 여승이나 됐으면 ",
              Eng: "I'd like to go somewhere and become a Buddhist nun or the like ...",
            },
            {
              Kor: "비나 몹시 쏟아져 ",
              Eng: "(the days) when it poured down rain ...",
            },
            {
              Kor: "손님에게 물건이나 내주고 돈이나 받는 것 같지만",
              Eng: "seemed like [nothing more than] giving customers their goods and taking their money, but …",
            },
            {
              Kor: "집에 가서 찌게나 좀 끓여놓지 ",
              Eng: "Why don't you just go home and put the stew on [or the likes]?",
            },
            {
              Kor: "소태나 먹은 것처럼 ",
              Eng: "as if he had eaten some sumac (i.e., something bitter) or something",
            },
            {
              Kor: "무슨 변고나 나지 않았나",
              Eng: "I worried whether perhaps there hadn't been accidents. ",
            },
            {
              Kor: "몸에 맞기나 했으면 좋겠다 ",
              Eng: "He just wished they would at least fit.",
            },
            {
              Kor: "골고루나 얻어먹어 봐야지! ",
              Eng: "I should eat by sponging off everybody equally! (lit.: in more or less a fair and equal fashion)",
            },
            {
              Kor: "누가 앓지나 않나 하고 ",
              Eng: "I worried whether perhaps there hadn't been illnesses. ",
            },
            {
              Kor: "혹 그새 배가 들어오지나 않을까 ",
              Eng: "wondering if maybe a ship might come in while he was away...",
            },
          ],
        },
        {
          entryName: "-(이)나2",
          typicalExample:
            "【몇 번이나 포대기를 풀어 아이를 단단히 업어야 했다.】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "As much as, as many as (implying that the speaker finds the amount more than expected).",
          sentences: [
            {
              Kor: "몇 번이나 포대기를 풀어 아이를 단단히 업어야 했다.",
              Eng: "I had to untie and the p'odaegi several times and secure him tight on my back.",
            },
            { Kor: "9년 동안이나 ", Eng: "for as many as 9 years" },
            {
              Kor: "몇 시간 동안이나 반복된 것임을 말해 준다. ",
              Eng: "... suggest that this has been repeating itself over the course of several hours.",
            },
            {
              Kor: "우리 이야기는 그 친구를 둘러싸고 한참이나 이어졌다.",
              Eng: "Our conversation continued for some time, centered on them.",
            },
            {
              Kor: "빚이 60원이나 남았었는데",
              Eng: "She still had as much as 60 wŏn worth of debts remaining …",
            },
            { Kor: "10년 동안이나 ", Eng: "for as long as 10 years" },
            { Kor: "한참 동안이나 ", Eng: "for quite a while ..." },
            {
              Kor: "하늘과 땅만큼이나 ",
              Eng: "as much as (the amount of) heaven and earth ...",
            },
            {
              Kor: "그래서 그는 분노를 느끼며 숫제 오분 동안이나 초인종에 손을 밀착시키고 ",
              Eng: "Angrily, he kept his finger on the doorbell for as long as five minutes...",
            },
            {
              Kor: "그는 한참이나 물속에 자신을 맡긴 채 껌을 씹으면서 함부로 몸을 굴리고 있었다. ",
              Eng: "He kept the shower running over his body for quite some time, rotating his body while chewing gum.  ",
            },
            {
              Kor: "두 켤레나 맡았구나",
              Eng: "You've gathered as many as two pairs, I see! ",
            },
            {
              Kor: "한 나절이나 ",
              Eng: "[for] as long as the better part of a day",
            },
            { Kor: "한 움큼이나 ", Eng: "[took] a whole handful [of incense]" },
            {
              Kor: "행렬을 언제까지나 바라보고 서 있었다",
              Eng: "[He] stood there so long, watching the procession.",
            },
          ],
        },
        {
          entryName: "-(이)나3",
          typicalExample: "【얼마동안이나 견딜 수 있을까가 한 시험도 되었다】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "For approximatives: about, around, approximately. In conjunction with a question word, makes an approximative: about how much?",
          sentences: [
            {
              Kor: "얼마동안이나 견딜 수 있을까가 한 시험도 되었다",
              Eng: "It became a test of just how long he was going to be able to stand it.",
            },
            {
              Kor: "그 신세도 내 신세만이나 하구마 ",
              Eng: "Her situation was about the same as mine.",
            },
          ],
        },
        {
          entryName: "-(이)나4",
          typicalExample: "【도마뱀은 어디에나 있어.  】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "Q-word + (이)나 meaning WH- ...-ever; any-WH and every-WH. Cf. 누구나 anybody and everybody; 언제나 any time and all the time; always. ",
          sentences: [
            {
              Kor: "도마뱀은 어디에나 있어.  ",
              Eng: "There are lizards everywhere.",
            },
          ],
        },
        {
          entryName: "-(이)나4... -(이)나4",
          typicalExample:
            "【그러니까 그때 영어나 수학 따위를 좀 잘했다고 해서】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "NOUN1(이)나 NOUN2: NOUN1 or NOUN2; Both ... and ...; (n)either ... (n)or ....",
          sentences: [
            {
              Kor: "그러니까 그때 영어나 수학 따위를 좀 잘했다고 해서",
              Eng: "I mean, just because you did well in English or Math or whatever at the time …",
            },
            {
              Kor: "회사에서나 집에서나   ",
              Eng: "whether at the office or at home",
            },
            {
              Kor: "자기를 과부나 독신으로 여기고 사는 거? ",
              Eng: "That she lives her life thinking of herself as a widow or an unmarried woman?",
            },
            {
              Kor: "너나 내나 = 너이나 나이나 ",
              Eng: "[whether it be] you or me [there's no difference]",
            },
            {
              Kor: "혹시 딸이 이 배에나 저 배에나",
              Eng: "He'd think, mightn't my daughter be on this ship or maybe that ship?",
            },
            { Kor: "술이나 먹고", Eng: "drinking booze or the like" },
            {
              Kor: "남의 집에 가서나 내 집에서나",
              Eng: "whether at home or at the neighbours'",
            },
            { Kor: "예나 지금이나 ", Eng: "before or now" },
          ],
        },
        {
          entryName: "-(이)나 다름 없다",
          typicalExample: "【송장이나 다름없지 뭔가? 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "NOUN(이)나 다름 없다: be no different from a NOUN; be just the same as a NOUN",
          sentences: [
            {
              Kor: "송장이나 다름없지 뭔가? ",
              Eng: "is no different from a corpse; is as good as dead",
            },
            {
              Kor: "거긴 벽지나 다름 없잖소 ",
              Eng: "that place [Samp'o] is nothing other than/no more than a desolate/remote place",
            },
            {
              Kor: "뜸이 안든 밥맛이나 다를 게 없었다 ",
              Eng: "tasted no different from rice which hadn't been simmered properly",
            },
            {
              Kor: "폐사(廢寺)나 다름없는 환음사 ",
              Eng: "Hwanŭm temple, no different from a rundown temple ",
            },
          ],
        },
        {
          entryName: "-(이)나 다를 게 없다",
          typicalExample: "【뜸이 안든 밥맛이나 다를 게 없었다 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "NOUN(이)나 다름 없다, NOUN(이)나 다를 게 없다: be no different from a NOUN; be just the same as a NOUN",
          sentences: [
            {
              Kor: "뜸이 안든 밥맛이나 다를 게 없었다 ",
              Eng: "tasted no different from rice which hadn't been simmered properly",
            },
          ],
        },
        {
          entryName: "-(이)나 다를 바(가) 없다",
          typicalExample: "【신선이나 다를 바 없었다】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "NOUN(이)나 다를 바 없-: be no different from, be just like",
          sentences: [
            {
              Kor: "신선이나 다를 바 없었다",
              Eng: "[Mr. Ha] seemed no different from a mountain wizard.",
            },
          ],
        },
        {
          entryName: "-(이)나 마찬가지(이)다",
          typicalExample: "【모두 한 사람이나 마찬가지였거든요 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation: "Is no different than NOUN.",
          sentences: [
            {
              Kor: "모두 한 사람이나 마찬가지였거든요 ",
              Eng: "They were all like one and the same person.",
            },
          ],
        },
        {
          entryName: "-(이)나마",
          typicalExample: "【그나마 다행 아닌가.】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "Copula -이- + extended adversative -(이)나마 functioning as a kind of pseudo-particle: ... though it may be; ... such as it is; although it is (only the likes of).... Often with a slightly stylistic flair.",
          sentences: [
            {
              Kor: "그나마 다행 아닌가.",
              Eng: "That in itself is fortunate, is it not?",
            },
            {
              Kor: "그나마 키스라도 하는 게 얼마나 다행스러운지 몰랐다. ",
              Eng: "Thank God he at least kissed me a bit, for what it was worth.",
            },
            {
              Kor: "삼 칸이나마 몽땅 태워버리고 말았다",
              Eng: "The three rooms—such as they were—burned to the ground.",
            },
            { Kor: "한 시절이나마 ", Eng: "even for one time ..." },
            {
              Kor: "의식도 삼베중이에 조밥이나마 굶고 ",
              Eng: "When it came to food and clothing, I wore decent hemp clothes and occasionally had to eat millet rice (instead of white rice), and ...",
            },
            {
              Kor: "순간순간이나마",
              Eng: "despite the fact that it was but for an instant here and then ...",
            },
            { Kor: "빈 둥우리나마 ", Eng: "the empty nest, such as it was..." },
            { Kor: "막연한 것이나마 ", Eng: "vague though it was ..." },
            {
              Kor: "작은 것이나마 ",
              Eng: "although they were small ... ; ... small though they were ...",
            },
            { Kor: "그것이나마", Eng: "although he did ..." },
            {
              Kor: "게으른 그에게는 막벌이나마 역시 되지 않았다 ",
              Eng: "For a lazy bum like him, even [something as trifling as] odd-jobs were a washout.",
            },
            {
              Kor: "뭇다락이 먹던 턱찌꺼기나마 ",
              Eng: "Leftovers of the hoi poloi they may be, but ...",
            },
            {
              Kor: "힘 안 들고 손쉬운 일을 행해서나마 ",
              Eng: "Even though it is only a effortless act on my part ...",
            },
            {
              Kor: "흐릿하게나마 ",
              Eng: "however vaguely it may have been...",
            },
            {
              Kor: "내가 짧게나마 환음사(歡音寺)에 머물고 있던 때였다",
              Eng: "It was the time when, short though it was, I stayed at Hwanum Temple.",
            },
            {
              Kor: "일방적으로나마 지탱해 보려는 안간힘",
              Eng: "[It came from a] self-restraint of trying to maintain [the relationship] even on a one-sided basis ...",
            },
            {
              Kor: "쉽게 행할 수 있는 것으로나마 ",
              Eng: "by means of this easily preformed task ...",
            },
            {
              Kor: "어렴풋이나마 하고 있었고",
              Eng: "the dimness with which I am asking ",
            },
            {
              Kor: "어렴풋이나마",
              Eng: "although it is vague ...; vague thought it may be",
            },
          ],
        },
        {
          entryName: "-(이)나 아닌가? ",
          typicalExample:
            "【나로 하여금 그토록 기꺼이 그 사내를 맞아들이게 한 것이나 아니었을까 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "Pseudo-particle -(이)나 reinforcing the sense of uncertainty inherent in 아닌가, 아니었을까, etc.: wondering if maybe it hadn't been...",
          sentences: [
            {
              Kor: "나로 하여금 그토록 기꺼이 그 사내를 맞아들이게 한 것이나 아니었을까 ",
              Eng: "I wondered if perhaps it hadn't been that it forced me to happily usher in the guy.",
            },
          ],
        },
        {
          entryName: "-(이)네 ...-(이)네 한다",
          typicalExample:
            "【당신들은 뭘 앉아서 콩이네 팥이네 하고 있는 거예요? 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "Goes on and on about BLAH-BLAH-BLAH. Nowadays probably only ever encountered in the stock phrase 콩이네 팥이네 하- meaning literally 'Says it's a black bean, says it's a red bean', i.e., 'argues about trivialities, discuss unimportant details'. ",
          sentences: [
            {
              Kor: "당신들은 뭘 앉아서 콩이네 팥이네 하고 있는 거예요? ",
              Eng: "What are you guys doing sitting around and talking about the price of bananas? (Go out there are do something about it!)",
            },
          ],
        },
        {
          entryName: "이놈의 ",
          typicalExample:
            "【죽으나 사나 이 놈의 회사에 모가지 붙들려 있는 것에 비하면】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation: "이놈의 NOUN: this wretched NOUN ",
          sentences: [
            {
              Kor: "죽으나 사나 이 놈의 회사에 모가지 붙들려 있는 것에 비하면",
              Eng: "Compared to hanging by the neck for dear life at this wretched company …",
            },
          ],
        },
        {
          entryName: "-(이)니 ... -(이)니",
          typicalExample:
            "【온성환(穩城丸)이니 진주환 (晋州丸)이니 하는 굽도리 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation: "Tedious list in NOUN-(이)니 ... NOUN-(이)니. ",
          sentences: [
            {
              Kor: "온성환(穩城丸)이니 진주환 (晋州丸)이니 하는 굽도리 ",
              Eng: "the Onsong-maru or the Chinju-maru, two local ships that stopped in every port (丸(환) is a Japanese term maru for vessel names.)",
            },
            {
              Kor: "장에 삭힌 깻잎이니풋고추, 더덕 등",
              Eng: "such as marinated sesame leaves, unripened hot peppers, todok, etc. ... ",
            },
          ],
        },
        {
          entryName: "-(이)니 ... (이)니 한다",
          typicalExample: "【충치니 풍치니 하던 것은 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "A이니 B이니 하- equivalent to A느니 B느니 하- to carry on/worry about [the question of] A vs. B/A or B. Martin treats this as a variant of question -냐.",
          sentences: [
            {
              Kor: "충치니 풍치니 하던 것은 ",
              Eng: "The times when he used to worry about caries, tooth decay, and the like …",
            },
            {
              Kor: "착취니 반동이니 영웅적이니 붉은 기니 하는 따위 말들은 ",
              Eng: "Words like exploitation, reactionary, heroic, red flag and such ...",
            },
            {
              Kor: "사람들은 사탄이니 뱀이니 도망이니 하는 얘기를 했던 것 같다.  ",
              Eng: "It seems they used to talk about Satan, snakes, fleeing, and the like.",
            },
          ],
        },
        {
          entryName: "이다/-(이)다 뭐다 한다 ",
          typicalExample:
            "【결혼하기 전 내 친구들은 생일이다 뭐다 하면서 애인을 데리고 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "NOUN(이)다 뭐다 한다: says NOUN and what-not, etc.; says 'it's a NOUN', 'it's whatever', etc.; claims first this, then claims that, etc. ",
          sentences: [
            {
              Kor: "결혼하기 전 내 친구들은 생일이다 뭐다 하면서 애인을 데리고 ",
              Eng: "Before I got married, my friends would bring their girlfriends over, whether the occasion was a birthday or whatever, and …",
            },
          ],
        },
        {
          entryName: "-(이)다 ... -(이)다 한다",
          typicalExample: "【가수다 코메디안이나 개그맨이다 해서 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "NOUN이다 NOUN이다 하-: go on and on about A, B and C; A, B and C and what have you ",
          sentences: [
            {
              Kor: "가수다 코메디안이나 개그맨이다 해서 ",
              Eng: "Now everybody gets excited about singers, comedians, stand-up entertainers, and so forth …",
            },
            {
              Kor: "성악(聲樂)이다 미술이다 연극이다 해서 ",
              Eng: "even those who are in the vocal arts, fine arts or theater ...",
            },
            {
              Kor: "오늘날 외교관이다 의사다 해서 ",
              Eng: "Nowadays, you have to be a 'diplomat' or a 'doctor', ...",
            },
          ],
        },
        {
          entryName: "-(이)던",
          typicalExample:
            "【무슨 보험이던= 무슨 보험이던지 = 무슨 보험이든지】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "Short form of -이던지 = -이든지. The endings -던 and -든 can be treated as spelling variants, although some Korean grammarians try to maintain an artificial (for Seoul, anyway) distinction: -던지 whether/maybe one has been doing vs. -든지 (used with an interrogative-indefinite word) any at all, regardless of which, ...-(so)ever, (whatever) one may do, (how, etc.) -ever it (has been observed to) be. Nowadays the whatever meaning is usually spelled with -든지, and this is the meaning intended in this passage. This pattern can optionally be followed by 간에.",
          sentences: [
            {
              Kor: "무슨 보험이던= 무슨 보험이던지 = 무슨 보험이든지",
              Eng: "whatever insurance it is (I don't care)",
            },
          ],
        },
        {
          entryName: "-(이)든 ... -(이)든 (간에)",
          typicalExample: "【가정이든 자식이든 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "Abbreviation of -(이)든지 ... -(이)든지. -든지 used with an interrogative-indefinite word means any at all, regardless of which, ...-(so)ever, (whatever) one may do, (how, etc.) -ever it (has been observed to) be. When paired with one or more other forms in -(이)든지 the sense is either ... or ... (implying the speaker doesn't have a preference for the options enumerated). This pattern can optionally be followed by 간에.",
          sentences: [
            {
              Kor: "가정이든 자식이든 ",
              Eng: "whether it is about family or children …",
            },
          ],
        },
        {
          entryName: "-(이)든지",
          typicalExample: "【무엇으로든지】",
          categoryKorean: "접속 조사",
          categoryEnglish: "(접속 조사)",
          translation:
            "WH- ...-(이)든지 (i.e., used with an interrogative-indefinite word): any at all, regardless of which, -(so)ever, (whatever) one may do, (how, etc.)-ever it (has been observed to) be, whether it is, is indifferently, (whatever) it is.",
          sentences: [
            { Kor: "무엇으로든지", Eng: "whatever [he could possibly do] ..." },
            {
              Kor: "누구의 어깨 너머로든지 ",
              Eng: "over whoever's shoulders [it happened to be] ...",
            },
            {
              Kor: "발리나 타히티, 아니면 카리브 해 쪽이든지...",
              Eng: "Bali or Tahiti, or in the direction of the Caribbean...",
            },
          ],
        },
        {
          entryName: "-(이)ㄹ세",
          typicalExample: "【내려오는 길일세 】",
          categoryKorean: "접속 조사",
          categoryEnglish: "(접속 조사)",
          translation: "Familiar (허네) Style indicative copula.",
          sentences: [
            { Kor: "내려오는 길일세 ", Eng: "I'm on my way down here ..." },
            { Kor: "이서방일세", Eng: "It is Mr. Yi." },
            {
              Kor: "자네 참 호살세 호사야 = 호사일세, 호사이야 ",
              Eng: "You've really gone out in style.  (호사: luxury, extravagance, sumptuousness)",
            },
          ],
        },
        {
          entryName: "-(이)라",
          typicalExample: "【지가 똥갈보라 】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "Dialect/substandard (or literary and archaic) version of standard -이다, the Plain Style copula. ",
          sentences: [{ Kor: "지가 똥갈보라 ", Eng: "She's a prostitute." }],
        },
        {
          entryName: "-(이)라2 = -(이)라고",
          typicalExample: "【국어. 국어라.  】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "The infinitive of the copula (-이어) can take the irregular shape -이라 before 서, 도, 야 and quotative -고. The shape -이라 also functions sometimes as a literary equivalent of -이다.",
          sentences: [
            { Kor: "국어. 국어라.  ", Eng: "'Korean. Korean, you say'." },
            {
              Kor: "이루어진 사랑의 남루한 일상이라. ",
              Eng: "'The shabby daily existence of love achieved', she says. ",
            },
            {
              Kor: "화전위복이라 할까 ",
              Eng: "Shall we call it a case of 'turning sorrow to fortune'?",
            },
            { Kor: "스물 아홉이라... ", Eng: "Twenty-nine, you say ..." },
            { Kor: "천이라? ← 천이라고? ", Eng: "A thousand, you say?" },
            {
              Kor: "우리들 앞으로 달려갈 것이라 믿었다 ",
              Eng: "I believed that it would come running in front of us.",
            },
            {
              Kor: "자기보다 훨씬 영리한 놈이라 생각하였다 ",
              Eng: "[Togi] thought [Oksoe] was a much more clever guy than himself. ",
            },
            { Kor: "[Examples?]", Eng: "" },
          ],
        },
        {
          entryName: "-(이)라3 = -(이)라서",
          typicalExample:
            "【그가 갖고 있는 생각은 높은 선반에 얹혀 있는 형상이라 】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "The infinitive of the copula (-이어) can take the irregular shape -이라 before 서, 도, 야 and quotative -고. The shape -이라 also functions sometimes as a literary equivalent of -이다. Usually when you see just -(이)라 in non-final position, you can assume it is a 'naked infinitive', I.e., an abbreviation of -(이)라서.",
          sentences: [
            {
              Kor: "그가 갖고 있는 생각은 높은 선반에 얹혀 있는 형상이라 ",
              Eng: "because the ideas he cherished were of the type that are placed high on a shelf …",
            },
            {
              Kor: "아버지가 물려준 못나 빠진 야산이 돌산이라 떼부자가 되었다.  ",
              Eng: "Because the scraggly piece of shit hill his dad left him turned out to be a quarry, he had become rich overnight.",
            },
            {
              Kor: "아침거리 저녁거리 걱정이라 ",
              Eng: "because they were worried about breakfast fixings and supper fixings …",
            },
            {
              Kor: "사오는 이십이라 ← 이십이라서 = 이십이어서 ",
              Eng: "because 4 times 5 is twenty …",
            },
            {
              Kor: "성미라 = 성미이라서 ",
              Eng: "[because it was Ch'osi's] disposition to ...",
            },
            {
              Kor: "안초시라 = 안초시(이)라서 ",
              Eng: "because it was An Ch'osi who …",
            },
            {
              Kor: "산속에 사는 사람이 사는 집이라 ",
              Eng: "as the home of someone who lives in the mountains ...",
            },
            {
              Kor: "오랜 동안 말라 있던 나무라 ",
              Eng: "because the wood had been dry for a long time ...",
            },
            {
              Kor: "아직 초저녁이라 ",
              Eng: "because it was still early evening ...",
            },
            {
              Kor: "술병의 위치와 가격 같은 것을 파악하지 못하고 있던 때라 ",
              Eng: "because it was the time when she still hadn't figured out things like the location and prices of the different bottles …",
            },
          ],
        },
        {
          entryName: "-(이)라고1",
          typicalExample: "【복덕방이라고 쓴 베 발 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "Verbatim or direct quotation in -(이)라고. Note that Korean editors and writers do not always alert the reader with quotation signs. ",
          sentences: [
            {
              Kor: "복덕방이라고 쓴 베 발 ",
              Eng: "the hemp cloth blind with “복덕방” written on it",
            },
            {
              Kor: "문득 아 지겨워 라고 중얼거리며 ",
              Eng: 'while I mumbled "Damn, this is tedious", suddenly ...',
            },
            {
              Kor: "눈이 오잖아, 라고 말했다",
              Eng: '… said, "But it\'s snowing!"',
            },
            {
              Kor: "혼자 사는 게 좀 불편해?라고 ",
              Eng: '…would always say, "Isn\'t it a drag living alone?"',
            },
            {
              Kor: "이건 내가 사는 거예요 라고 말하며 ",
              Eng: 'I said, "This one\'s on me" and...',
            },
            {
              Kor: "이것은 어제의 더운 물이 아니다라고 ",
              Eng: '[He thought,] "This is not the warm water from yesterday."',
            },
            {
              Kor: "나는 안다라고 그는 생각한다 ",
              Eng: '"I know as much,"[he thought].',
            },
            {
              Kor: "부끄러운 일이다라고 그는 생각했다 ",
              Eng: "He thought that being frightened by inanimate objects] was something to be ashamed of.",
            },
            {
              Kor: "난 당신 포기했어, 라고 스스로 공언하기까지 했다. ",
              Eng: "She herself even went so far as to pronounce once, 'I've given up on you.'",
            },
            {
              Kor: "몸이 영 안 좋아, 라고 하기에 ",
              Eng: "When he says 'I'm really not feeling well' … ",
            },
            {
              Kor: "의사는 활동성 결핵, 이라고 ... 말했다. ",
              Eng: 'The doctor said, "Active TB."',
            },
            {
              Kor: "이 썩은 냄새! 라고 소리쳤었다  ",
              Eng: 'He had yelled, "This rotten smell!"',
            },
            {
              Kor: "할 수 없다는 듯이, 나가 봐야겠는데, 라고 작게 말한다.  ",
              Eng: 'As if to say I have no choice in the matter, I say in a small voice, "Looks like I have to go out."',
            },
            {
              Kor: '부인인 듯한 아줌마가 남편을 쿡 찌르며 "누구 찾아왔어"라고 했다.  ',
              Eng: 'The ajumma who looked to be his wife jabbed her husband in the ribs and said, "She\'s looking for someone."',
            },
          ],
        },
        {
          entryName: "-(이)라고2",
          typicalExample: "【무어라고 지껄이고= 무엇이라고 지껄이고】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "Indirect quotation with the copula: NOUN(이)다 becomes NOUN(이)라고 called 'NOUN' ",
          sentences: [
            {
              Kor: "무어라고 지껄이고= 무엇이라고 지껄이고",
              Eng: "chattered/said something or other ",
            },
            { Kor: "안경화라고", Eng: 'called/named "An Kyŏnghwa"' },
          ],
        },
        {
          entryName: "-(이)라고3",
          typicalExample: "【[Examples?]】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation: 'Lit.: saying, "Quote." Used to give a reason.',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-(이)라고4",
          typicalExample: "【조반이라고】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            'Quoted copula -(이)라고 to express IRONY or IRONIC SURPRISE. Example: 이것을 잡지라고 샀더니 읽을 것이 하나도 없네 I bought this "magazine" (as they pretend it to be) and can\'t find a thing in it to read. ',
          sentences: [
            {
              Kor: "조반이라고",
              Eng: '[had a few spoonfuls of] so-called "breakfast"[but...]',
            },
          ],
        },
        {
          entryName: "-(이)라고 해도",
          typicalExample:
            "【댁을 만난 것이 이 해의 마지막 장식이 될 거예요.  아니 시작이라고 해도 좋을까요. 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            'Even supposing... Lit.: saying, "Quote." used to give a reason.',
          sentences: [
            {
              Kor: "댁을 만난 것이 이 해의 마지막 장식이 될 거예요.  아니 시작이라고 해도 좋을까요. ",
              Eng: "Meeting you will probably be the final celebration of the year, or perhaps the first of many to come?  ",
            },
            {
              Kor: "군교육장을 하라고 해도 다 뿌리치고 군내의 벽지만을 골라 다니며 교장으로 근무해 온 아버지  ",
              Eng: "My father had even been offered the directorship of the county school board but he rejected it all, choosing instead to serve as a principal in remote villages.",
            },
            {
              Kor: "오빠라고 해도 제 큰오빠뻘이나 되실텐데요",
              Eng: "From the looks of it, you'd probably be an older brother with many years on me.",
            },
          ],
        },
        {
          entryName: "-(이)라고 (해서) ",
          typicalExample:
            "【서참위의 수입이 없는 달이라고 쌀값이 밀리거나 나뭇값에 졸릴 형편은 아니다 = 달이라고 해서 쌀값이 밀리거나 나뭇값에 졸릴 형편은 아니다】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "Followed by a negative clause, a quoted phrase like this means nevertheless/having said that (but) just because A, B is not necessarily the case; just because (one could say that ...), [doesn't mean that ...]. ",
          sentences: [
            {
              Kor: "서참위의 수입이 없는 달이라고 쌀값이 밀리거나 나뭇값에 졸릴 형편은 아니다 = 달이라고 해서 쌀값이 밀리거나 나뭇값에 졸릴 형편은 아니다",
              Eng: "Even if Sŏ Ch'amwi goes for a month without income, it wasn't the case that he would starve.",
            },
            {
              Kor: "그러면 마누라들이라고 가만히 있지 않았다 ",
              Eng: "Just because they were women there was no reason to expect that they would stay quiet.",
            },
            {
              Kor: "그의 어조는 반드시 누이동생이라고 생각하지 않으며  ",
              Eng: "",
            },
            {
              Kor: "웬일인지 복덕방이라고 쓴 베발이 아직 내어걸리지 않았다",
              Eng: "",
            },
            {
              Kor: "이 것으로 미루어보아도 어떤 정도의 것이라고 짐작되지 않느냐 하는 것이었다.  ",
              Eng: "",
            },
          ],
        },
        {
          entryName: "-(이)라고는1",
          typicalExample: "【인기척이라곤 없는데】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "From -(이)라고 하는 것은 as for (the one that is called), literary equivalent of topic 은/는, usually used to set up a definition or else followed by a negative verb to emphasize that whatever is being defined didn't exist in great quantities or otherwise doesn't/didn't amount to much.",
          sentences: [
            {
              Kor: "인기척이라곤 없는데",
              Eng: "(All of these places) were deserted, but ...",
            },
            {
              Kor: "주인댁의 라디오 소리도 꺼지고 들리는 거라곤 개구리 울음소리뿐이거든요. ",
              Eng: "When the landlord turns off his radio, all you can hear is the sound of frogs croaking. ",
            },
            {
              Kor: "증손이라곤 기집애 그 애 하나뿐이었지요?",
              Eng: "As far as grandchildren go, isn't that girl their only one? ",
            },
            {
              Kor: "손잡이라곤 아무데도 없는 트렁크 뚜껑에 제아무리 손바닥을 찰싹 붙였지만",
              Eng: "With the palms of his hands he tried to hold on to the car trunk which had nothing that could serve as a grip, but ...",
            },
            {
              Kor: "골절이 된 사람이라고는 할 수 없을 정도로 거의 혼자서 곧바로 발걸음을 옮겨 놓았다",
              Eng: "By the way he could walk almost completely on his own, it was hard to tell that he was suffering bone fractures.",
            },
            {
              Kor: "아버지는 오점이라고는 하나도 없는 절대자였다. ",
              Eng: "My father was the perfect man with no faults at all.  ",
            },
          ],
        },
        {
          entryName: "-(이)라고는2",
          typicalExample: "【스테디한' 관계라고는 할 수 없을지 모른다.】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "-(이)라고는: particle 는 anticipating a negative verb; for contrast or downplaying effect on copular quotative -(이)라고: It is indeed true enough, admittedly (as opposed to some other appellation).",
          sentences: [
            {
              Kor: "스테디한' 관계라고는 할 수 없을지 모른다.",
              Eng: "Perhaps it can't be called a 'steady' relationship.",
            },
            {
              Kor: "임을 상실한 이 세계에 남은 것이라고는 없다.  ",
              Eng: "There is nothing left in this world where I have lost my beloved.",
            },
            {
              Kor: "지적(知的) 분위기라고는 하지만 ",
              Eng: 'an atmosphere that can be called "intellectual," but ...',
            },
          ],
        },
        {
          entryName: "-(이)라고도 ",
          typicalExample: "【실패는 다음의 성공을 위한 밑거름이라고도 했다. 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation: "-(이)라고도: saying also that ...",
          sentences: [
            {
              Kor: "실패는 다음의 성공을 위한 밑거름이라고도 했다. ",
              Eng: "And he also said that failure was fertilizer for the next success. ",
            },
          ],
        },
        {
          entryName: "-(이)라고만",
          typicalExample: "【미련스럽게 그걸 혼자 했어? 라고만.  】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            '-(이)라고만 consists of the verbatim (direct) quotative marker -(이)라고 + particle 만: said only "QUOTE."',
          sentences: [
            {
              Kor: "미련스럽게 그걸 혼자 했어? 라고만.  ",
              Eng: 'All I had said was, "You did something stupid like that when you were alone?"',
            },
          ],
        },
        {
          entryName: "-(이)라곤 ",
          typicalExample: "【얼굴 맞댈 스님이라곤 주지승밖에 없는 정도여서 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "Abbreviated from 이라고[는], which in turn can be treated as an abbreviation from -(이)라고 하는 것은 the only NOUN [as topic], usually followed by a negative.",
          sentences: [
            {
              Kor: "얼굴 맞댈 스님이라곤 주지승밖에 없는 정도여서 ",
              Eng: "The only monk that one had to confront was Chu Chi-sŭng … ",
            },
          ],
        },
        {
          entryName: "-(이)라기보다 ",
          typicalExample: "【대답이라기보다 혼잣말처럼】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "NOUN1(이)라기보다 NOUN2: more as N2 than as N1, more as what you would call N2 than N1; it's not so much that N1, rather... In origin, an abbreviated indirect quotation: NOUN1(이)라기보다 ← NOUN1(이)라[고 하]기보다 [something else]이다 rather (or more) than say that it is NOUN1, it is [actually/rather] [something else].",
          sentences: [
            {
              Kor: "대답이라기보다 혼잣말처럼",
              Eng: "more than answering he sort of (mumbled) to himself ...",
            },
            {
              Kor: "올이 굵고 하얀 눈썹은 눈썹이라기보다는 흰털을 한줌 쥐어 붙여놓은 것 같았다.",
              Eng: "Eyebrows with thick white strands seemed more like a handful of white hairs had just been stuck on.",
            },
          ],
        },
        {
          entryName: "-(이)라나 ",
          typicalExample: "【신식 상복이라나 = 신식 상복이라고 하나? 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "From quoted copula -(이)라(고) followed by [하-]나? Is it called ... (I wonder?), often to express IRONY or IRONIC SURPRISE.",
          sentences: [
            {
              Kor: "신식 상복이라나 = 신식 상복이라고 하나? ",
              Eng: "[… came out in front of the coffin wearing]—was it the so-called modern style mourning clothes?—[a jet-black attire made of what seemed like unpatterned silk.] ",
            },
            {
              Kor: "나도 그러기를 바라나?  ",
              Eng: "Is that what you want me to do as well?  ",
            },
            {
              Kor: "어린애들은 도마뱀이 자신의 배와 다리 위를 기어다니는 것을 보면서 자라나. ",
              Eng: "I wonder if children grow up seeing lizards crawling all over their bellies and legs.  ",
            },
          ],
        },
        {
          entryName: "-(이)라나 보다 ",
          typicalExample: "【\"'남편 기 살리기' 라나 봐.\" 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "-(이)라나 보다 ? -(이)라[고 하]나 보다: seems somebody says it is ....",
          sentences: [
            {
              Kor: "\"'남편 기 살리기' 라나 봐.\" ",
              Eng: "\"I think it was something like 'Reviving your Husband's Morale'.\"",
            },
          ],
        },
        {
          entryName: "-(이)라뇨",
          typicalExample: "【미신이라뇨? 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contracted from -(이)라니(요) and used in a rejoinder to raise doubt about or query/question the interlocutor's statement.",
          sentences: [
            {
              Kor: "미신이라뇨? ",
              Eng: "Superstition?; 'Superstition', you say?; What do you mean, ‘superstition’?",
            },
          ],
        },
        {
          entryName: "-(이)라느니",
          typicalExample: "【끝끝내 마음을 잡고 있었을 것이라느니 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "QUOTE1 느니 QUOTE2 느니: The claim that QUOTE1 and QUOTE2 ….  In origin, a contracted quotation from -(이)라[고 하]느니.",
          sentences: [
            {
              Kor: "끝끝내 마음을 잡고 있었을 것이라느니 ",
              Eng: "some claimed that she would have stuck it out to the end ...",
            },
            {
              Kor: "제 팔자를 고치기 위함이라느니 ",
              Eng: "some claimed it was done in order to change her fate ...",
            },
          ],
        },
        {
          entryName: "-(이)라는1 ",
          typicalExample:
            "【그런 것들로 이루어진 집이라는 일상에 갇혀 살기에는 …】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "The [thing, etc.] about which they [or whoever] say; the [thing, etc.] about which it is said that, abbreviated from an indirect quotation in -(이)라[고 하]는, modifying the following thing, etc.",
          sentences: [
            {
              Kor: "그런 것들로 이루어진 집이라는 일상에 갇혀 살기에는 …",
              Eng: "[He is too accustomed to his freedom] to live all cooped in the everyday life called 'home' made up of things like these.",
            },
            {
              Kor: "한 시간 거리라는 산호섬 ",
              Eng: "a coral island that they say is one hour away",
            },
            {
              Kor: "국어라는 단어가 갑자기 생경하게 들렸다. ",
              Eng: "Suddenly the word 'national language' sounded alien.",
            },
          ],
        },
        {
          entryName: "-(이)라는2",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "[The person, etc.] who says/claims that... abbreviated from an indirect quotation in -(이)라[고 하]는, modifying the following person, etc.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-(이)라는3",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "The putative/alleged [fact, claim, words, etc.] to the effect that QUOTE, abbreviated from an indirect quotation in -(이)라[고 하]는, modifying the following fact, claim, words, etc.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-(이)라는 걸",
          typicalExample:
            "【나는 알아챘다. 그녀가 애인이라고 표현한 것이 바로 나라는 걸. 】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "NOUN(이)라는 걸 ← NOUN(이)라는 것을: the putative or alleged fact that... (here as object). In origin, from the indirect quotation pattern in -(이)라[고 하]는 것. ",
          sentences: [
            {
              Kor: "나는 알아챘다. 그녀가 애인이라고 표현한 것이 바로 나라는 걸. ",
              Eng: "I figured it out—that what she meant by the expression 'boyfriend' was none other than me.",
            },
          ],
        },
        {
          entryName: "-(이)라는 것",
          typicalExample:
            "【그래서 시방은 순사 적이라는 것이 이미 옛말같이 된 터이었지만】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "Also -는다는 것, -다는 것, abbreviated from -는다[고 하]는 것, etc.: the claim that ...; the alleged (putative)fact that ... to set up a generic definition or value judgement (like-(이)란). Used to set up a comment about the often unusual/noteworthy) topic marked by -는 것.",
          sentences: [
            {
              Kor: "그래서 시방은 순사 적이라는 것이 이미 옛말같이 된 터이었지만",
              Eng: "Although my days as a constable are long gone...",
            },
            {
              Kor: "징용이라는 것을 당해",
              Eng: "I was drafted (lit.: I was a victim to this thing called “conscription”.)",
            },
            {
              Kor: "자선음악회라는 것을 감안해서인지 비교적 대중적인 것들이었다",
              Eng: "Perhaps it was on account of it being a charity benefit concert the songs were all popular tunes.  ",
            },
            {
              Kor: "결혼식이라는 것을 올리고  ",
              Eng: "experienced ['rendered up'] this thing called a 'wedding ceremony'",
            },
            {
              Kor: "빗방울 듣는 소리라는 것을 알고도  ",
              Eng: "even after she realized that it was the sound of rain drops falling …",
            },
            {
              Kor: "그 남자가 오직 알마덴만을 사가며 그 가격은 세금 포함해서 이 불 삼십이 전이라는 것을 저절로 외우게 되었다.  ",
              Eng: "She came to remember that the man always bought Almaden and that it cost two dollars and thirty cents with tax.  ",
            },
            {
              Kor: "도지사 표창을 받았으니 한턱 내라는 것이었다.  ",
              Eng: "They wanted him to treat them all in celebration of receiving an award from the provincial governor.  ",
            },
            {
              Kor: "그리고 또  바우가 그들과 한패라는 것도 알았다.  ",
              Eng: "He also knew that Pau was part of that group.",
            },
            {
              Kor: "그러나 그의 마음속에는 막연하나마 도덕이라는 것에 대한 기품을 가지고 있었다.  ",
              Eng: "In his heart, his remote but existent morals gave him joy.  ",
            },
            {
              Kor: "그는 열다섯 나던 해에 동네 홀아비에게 팔십 원에 팔려서 시집이라는 것을 갔다.  ",
              Eng: "The year she turned 15 she was sold for eighty wŏn to a widower living nearby.  ",
            },
            {
              Kor: "그 시절에는 무지라는 것이 유행이었다.  ",
              Eng: "In those days, ignorance was the trend.  ",
            },
            {
              Kor: "그게 거짓말이라는 것 난 이미 알고 있었다.",
              Eng: "I already knew that it was a lie.  ",
            },
            {
              Kor: "사람들이 여행을 할 틈이 없어서라는 것이었다.  ",
              Eng: "It was because people didn't have any spare time to take a vacation. ",
            },
          ],
        },
        {
          entryName: "-(이)라는 것을 모른다",
          typicalExample:
            "【그가 매일매일의 단골손님이라는 것을 모르는 듯했다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Abbreviated from -(이)라[고 하]는 것을, etc.: isn't aware of/doesn't know the claim that ...; doesn't know (the alleged/putative fact) that ... ",
          sentences: [
            {
              Kor: "그가 매일매일의 단골손님이라는 것을 모르는 듯했다. ",
              Eng: "It was as if he [the husband] didn't know [that Almaden] was a regular customer. ",
            },
          ],
        },
        {
          entryName: "-(이)라는 것을 인정한다",
          typicalExample:
            "【살아갈 수 있는 한 방법이라는 것을 나는 인정해야한다. 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN(이)라는 것을 인정한다: concedes/recognizes ['the putative or alleged fact'] that... (here as object). In origin, -(이)라는 것 is from the indirect quotation pattern in -(이)라[고 하]는 것.",
          sentences: [
            {
              Kor: "살아갈 수 있는 한 방법이라는 것을 나는 인정해야한다. ",
              Eng: "I have to recognize that it is a means of survival.",
            },
          ],
        },
        {
          entryName: "-(이)라는 것이다",
          typicalExample: "【모두 사기라는 것이었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Abbreviated from -(이)라[고 하]는 것이다, etc.: Lit.: it is/was a case of claiming that QUOTE ",
          sentences: [
            {
              Kor: "모두 사기라는 것이었다 ",
              Eng: "She said/The claim was that the whole thing was a sham.",
            },
            {
              Kor: "사람들이 여행을 할 틈이 없어서라는 것이었다 ",
              Eng: "It was because people claimed they had no chance to travel.",
            },
          ],
        },
        {
          entryName: "-(이)라는 게",
          typicalExample: "【사실 영업부 일이라는 게 다 그런 거 아닌가.】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN(이)라는 게 ← NOUN(이)라[고 하]는 것이: a thing/something/this thing called NOUN, here function to set up a generic topic (a function usually handled by its relative, NOUN(이)란). ",
          sentences: [
            {
              Kor: "사실 영업부 일이라는 게 다 그런 거 아닌가.",
              Eng: "Actually, isn't that the way it is with all business departments?",
            },
          ],
        },
        {
          entryName: "-(이)라는 둥 ",
          typicalExample:
            "【몇 만 원 집어먹고서 소위 팔자를 고친다는 둥, 허리띠를 푼다는 둥의 수준에 올라야 비로서 문제가 되는 것이었었다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "From -(이)라[고 하-]는 둥, with postmodifier 둥 for what is originally 등 in its meaning (following a list or enumeration) of et cetera, and so forth, and so, and the like, and all that, and such like for a list of (usually quoted) hypothetical options or choices.",
          sentences: [
            {
              Kor: "몇 만 원 집어먹고서 소위 팔자를 고친다는 둥, 허리띠를 푼다는 둥의 수준에 올라야 비로서 문제가 되는 것이었었다.  ",
              Eng: "Things like this were counted as corruption or a crime only when they rose to the level of tens of thousands of wŏn—hitting the jackpot and changing one's life-style. ",
            },
            {
              Kor: "조선 사람은 고추를 끔찍이 많이 먹는다는 둥, 일본 음식은 너무 싱거워서 처음에는 속이 뉘엿거린다는 둥, 횡설수설 지껄이다가  ",
              Eng: "He carried on about...how Koreans eat incredible amounts of hot pepper, how Japanese food was so bland that at first he felt nauseated, and so on. ",
            },
            {
              Kor: "그녀는 오늘따라 반찬이 없다는 둥 설마 당신 진짜로 일찍 들어올 줄 몰랐다는 둥 말을 많이 한다.  ",
              Eng: "She kept on talking—things like 'Todayof all days we don't have any panch'an', 'I didn't dream that you would really come home early tonight', etc.  ",
            },
          ],
        },
        {
          entryName: "-(이)라는 듯(이)",
          typicalExample: "【늘 돌아오는 곳이라는 듯  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-다는 듯(이), -(이)라는 듯(이), based on -는 듯(이) like, as, giving the appearance of ...; appearing (to be), looking (like); seeming as if; as if/though plus an abbreviated indirect quotation in -(이)라[고 하-] so: as if to say ...",
          sentences: [
            {
              Kor: "늘 돌아오는 곳이라는 듯  ",
              Eng: "as if to say that this was a place she always came back to",
            },
            {
              Kor: "좋은 일이라는 듯이 = 일이라[고 하]는 듯이 ",
              Eng: 'as if to say, "Gee, it\'s good work"',
            },
            { Kor: "의외라는 듯이 ", Eng: "as if it is unexpected" },
          ],
        },
        {
          entryName: "-(이)라는 생각 ",
          typicalExample:
            "【그래서 한참을 보니 신기하게도 저것이 더러운 똥이라는 생각이 안 든다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Thoughts of...; the thought that...; thought[s] to the effect that QUOTE. Built on an abbreviated indirect quotation in -(이)라[고 하-]는 modifying the following 생각 thought.",
          sentences: [
            {
              Kor: "그래서 한참을 보니 신기하게도 저것이 더러운 똥이라는 생각이 안 든다.  ",
              Eng: "After staring at it for awhile, I thought that curiously enough, it could be dirty feces.  ",
            },
            {
              Kor: "이 모든 것이 실없는 장난이라는 생각이 든 것이다.  ",
              Eng: "It occurred to me that this was all frivolous play.",
            },
            {
              Kor: "오늘 같은 날은 일찌감치 집으로 돌아가 집안일을 도와야 한다는 생각을 잊어버리기라도 하려는 듯이  ",
              Eng: "It was as if she actually forgotten that she should be going home early and helping with chores on a day like today...",
            },
            {
              Kor: "나는 이런 운흥산 어느 골에 정말 호랑이가 살고 있을지도 모른다는 생각을 불현듯 가졌다.  ",
              Eng: "I suddenly got the feeling that there could be tigers living in a cave somewhere on Unhŭng Mountain.  ",
            },
            {
              Kor: "운흥산 호랑이를 산 아래로 끌어내고 싶다는 생각은 그래서 가졌다.  ",
              Eng: "That's why I thought that I wanted to bring down some Unhŭng tigers from the mountain.  ",
            },
            {
              Kor: "착실하게 돈을 모으면 그렇게 될 수 있다는 생각이 언제부턴가 마음 깊이 자리 잡기 시작했다.  ",
              Eng: "He began to think that he could one day be like that if he faithfully saved up his money.",
            },
            {
              Kor: "도마뱀과 말하고 싶다는 생각도 한다.  ",
              Eng: "Sometimes I even think that I want to talk to the lizard.",
            },
          ],
        },
        {
          entryName: "-(이)라는 이유로 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "On account of the (alleged) reason that... with an abbreviated indirect quotation in -(이)라는 ← -(이)라[고 하]는 modifying 이유 reason.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-(이)라는 인상 ",
          typicalExample:
            "【엄마의 재혼은 아버지의 사망 못지않은 충격일 것이라는 인상을 나는 받았다.  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Impression to the effect that...; impression to the effect that QUOTE. Built on an abbreviated indirect quotation in -(아)라[고 하-] modifying 인상 impression.",
          sentences: [
            {
              Kor: "엄마의 재혼은 아버지의 사망 못지않은 충격일 것이라는 인상을 나는 받았다.  ",
              Eng: "I got the impression that my mother's remarriage would be no less shocking as my father's death to those kids.  ",
            },
          ],
        },
        {
          entryName: "-(이)라니(요)",
          typicalExample: "【까닭이라니】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'NOUN(이)라니(요)?: What to do you (by saying) "NOUN"? I.e., What do you mean, "NOUN"? Queries a statement just made by throwing it back as a question. Used in a rejoinder to raise doubt about or query/question the interlocutor\'s statement. Belongs to a set of "echoed quotations" which have the effect of throwing back, challenging, or reiterating/emphasizing something just said. ',
          sentences: [
            { Kor: "까닭이라니", Eng: "What do you mean, 'prior connection'?" },
            {
              Kor: "그런데, 그런 나도 못 견딜 외로움이라니!  ",
              Eng: "But even as I say this, the loneliness is so unbearable!",
            },
            {
              Kor: "집에서 애 둘을 키우는 여자가 직장이라니? ",
              Eng: "'Work' for a woman raising two kids at home?",
            },
            { Kor: "과객이라니", Eng: "“A 'passerby'”, he says." },
            {
              Kor: "여기저기서라니",
              Eng: "What do you mean by 'here and there'?",
            },
            { Kor: "조라니", Eng: "'Cho'?" },
            { Kor: "젊은 여자라니요…", Eng: "What do you mean, 'young lady'?" },
            {
              Kor: "젊은 여자라니 ",
              Eng: "What did [he] mean young woman? [It was probably because of the dark.]",
            },
            { Kor: "이별이라니", Eng: "What am I saying—'broke up' ..." },
            {
              Kor: "이서방이라니 올체 이풍헌 아들 이서방인가?",
              Eng: "Would that be young Master Yi, as in the son of Yi P'unghŏn?  ",
            },
          ],
        },
        {
          entryName: "-(이)라니까",
          typicalExample: "【나두 마찬가지라니깐 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "For insistent, reproachful reiterations.",
          sentences: [
            { Kor: "나두 마찬가지라니깐 ", Eng: "Same for me, I tell ya! " },
            {
              Kor: "과객이라니까. 실은 나도 조금 전에 처음 알았소.",
              Eng: "I'm telling you, he's a wayfarer.  I just found out a moment ago.    ",
            },
            {
              Kor: "얘, 구두! 이것 좀 보라니까.  ",
              Eng: "Hey, Shoes!  Come here and look at this.",
            },
            {
              Kor: "염려 마시라니까요.  돈만 많이 주세요.  ",
              Eng: "Don't worry about a thing.  Just make sure to pay me well.   ",
            },
            {
              Kor: "아, 뭘 해요?  빨리 가서 역을 지키라니까.  ",
              Eng: "What are you doing?  I told you to hurry up and keep watch at the station.",
            },
            {
              Kor: '"없다고 그러라니까요."  ',
              Eng: "I told you to tell them I'm not here.  ",
            },
          ],
        },
        {
          entryName: "-(이)라 더구나",
          typicalExample:
            "【금칠 어메게 들으니까 숙이가 그 토끼를 잡아먹었다더구나!  】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            'From an abbreviated quote in -(이)라[고 하]더구나! with a retrospective apperceptive in -더구나, -더군: Now I realize that somebody said/claimed that ... !  Apperceptives in - 군, -구나, -군요, -구려, etc., carry the meaning of "first realization."',
          sentences: [
            {
              Kor: "금칠 어메게 들으니까 숙이가 그 토끼를 잡아먹었다더구나!  ",
              Eng: "Now that it think of it, I heard from Gŭmch’il's mom that Suki caught the rabbit and ate it!  ",
            },
          ],
        },
        {
          entryName: "-(이)라던 ",
          typicalExample:
            "【장가들이고 집 사고 살림을 내준다던 것도 헛소리였다.  】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "For -(이)라[고 하]던, an abbreviated indirect quotation + retrospective modifier, meaning something like: [NOUN] about which it used to be said that QUOTE. ",
          sentences: [
            {
              Kor: "장가들이고 집 사고 살림을 내준다던 것도 헛소리였다.  ",
              Eng: "His intentions of getting married and buying a home were all meaningless talk.  ",
            },
            {
              Kor: "세상에 모르는 것이 없고 무서운 것이 없다던 분의 그 위풍당당하던 풍모는 남아 있었다.",
              Eng: "He still had that grand, stately appearance of a man that knew about all the things of the world and feared nothing. ",
            },
          ],
        },
        {
          entryName: "-(이)라데",
          typicalExample: "【밭이라데 = 밭이라고 하데】",
          categoryKorean: "Modifier/준꼴 Contraction",
          categoryEnglish: "(Modifier/준꼴 Contraction)",
          translation:
            "Contracted quotation with the Familiar Style retrospective statement ending in –데 from -(이)라[고 하]데.",
          sentences: [
            {
              Kor: "밭이라데 = 밭이라고 하데",
              Eng: "They say it is [all] dry fields [I recall].",
            },
          ],
        },
        {
          entryName: "-(이)라도1",
          typicalExample: "【옷이 조금이라도 찢어지면 생명을 잃는다.  】",
          categoryKorean: "Pseudo-particle",
          categoryEnglish: "(Pseudo-particle)",
          translation:
            "Pseudo-particle -(이)라도: even if (it be) just (or the) like (= -(이)나); or even any, some even if it be [just/only] even just.",
          sentences: [
            {
              Kor: "옷이 조금이라도 찢어지면 생명을 잃는다.  ",
              Eng: "If your clothing gets even slightly torn, you lose your life.",
            },
            {
              Kor: "한가지라도 실수로 잘못하면 ",
              Eng: "even if you get just one of them wrong …",
            },
            {
              Kor: "한번 호령만 하고 보면 산천이라도 물러설 것 같던, 그 기개와 오늘의 자기 ",
              Eng: "With one command it seemed that even the mountains and streams might move out of the way—that's what it was like between the present you and that machine.",
            },
            {
              Kor: "그런 날이라도 미리 주워 두었던 담배 깜부기만 넉넉하면 한결 그것이 벗이 되었다 ",
              Eng: "Even on days like this, a pocketful of collected cigarette butts were all the more a good friend.    ",
            },
            {
              Kor: "잠시라도 눈을 붙이시지요? ",
              Eng: "Why don't you close your eyes just for a moment?",
            },
            {
              Kor: "지금이라두 쉽지",
              Eng: "That's easy for the time being ...",
            },
            {
              Kor: "무슨 약을 먹었는지 모르지만 지금이라도 어쩌면...",
              Eng: "I don't know what kind of drugs he took but even now, what if...",
            },
            {
              Kor: "물론입니다. 지금 당장이라도 나서고 말구요. ",
              Eng: "Of course. I'm going to get involved, even if it means right now.   ",
            },
            {
              Kor: "내일 당장이라도 그는 그런 제안을 할 것 같아서 ",
              Eng: "since it seemed that as soon as it was tomorrow he would promptly make such a proposition …",
            },
            {
              Kor: "우선은 말동무라도 있었으면 싶었다 ",
              Eng: "He thought to himself that, above all, he would at least like to have someone to talk to [during the journey]. ",
            },
            {
              Kor: "어머님 고기라도 사다 드리면 ",
              Eng: "[it is nice] to at least buy some meat for my mother-in-law ...",
            },
            {
              Kor: "그것이 무슨 잘못이라도 되는 것처럼 ",
              Eng: "as if it was something to be ashamed of ...",
            },
            {
              Kor: "그것만이라도 꿀떡이었다 ",
              Eng: "Even those clothes were the best [one could hope for]. ",
            },
            {
              Kor: "고구마라도 한 개 먹었으면 ",
              Eng: "if only he could eat a sweet potato [or the like] ...",
            },
            {
              Kor: "한 입이라도 더 줄여야 ",
              Eng: "they had to reduce the mouths to feed by at least one [if they were to ...]",
            },
            {
              Kor: "위로받고 싶었다. 아니면 따끈한 커피 한잔이라도. ",
              Eng: "I wanted to be comforted. If not that, then I at least wanted a cup of steaming coffee.",
            },
            {
              Kor: "고개만이라도 숙이라고 ",
              Eng: "even if it just be your head, please bow ...",
            },
            {
              Kor: "발이라도 삐었는지 ",
              Eng: "perhaps because she had sprained her foot or something ...",
            },
            {
              Kor: "농담이라도 하는 게 아닌가 의심이 들었다. ",
              Eng: "I wondered whether he wasn't jesting or something. ",
            },
            {
              Kor: "아내에게 복수라도 하듯 ",
              Eng: "as if to get back at her or the like ...",
            },
            {
              Kor: "마치 발악이라도 하듯이 ",
              Eng: "just as if she were raving or something ...",
            },
            {
              Kor: "나는 그 아주머니를 보자 내 집에 온 것처럼 마음이 놓이고 어리광이라도 부리고v싶어졌다. ",
              Eng: "The moment I saw that lady I felt relaxed as if I was at home and I wanted to act childish.",
            },
            {
              Kor: "하도 착실한 학생이었던지라 만의 하나라도 무슨 일이 있는 게 아닌가 싶어 알리는 거니",
              Eng: "I'm only letting you know in case there is even a one-in-ten-thousand chance that something happened to him, since he was such a trust-worthy student...",
            },
            {
              Kor: "어떻게 목돈이라도 만지게 되면 도망간 부인을 되찾을 수 있을지도 모른다고요.  ",
              Eng: "I might be able to reunite with my wife who had run away if I can get my hands on even a good sum of money. ",
            },
            {
              Kor: "면장갑이라도 끼지 않고서는 배겨낼 도리가 없었다.",
              Eng: "There was no way to endure the cold without putting on a pair of cotton gloves or something. ",
            },
            {
              Kor: "하지만 그는 더러워서 정말 더러워서, 침이라도 뱉을 심산이었다. ",
              Eng: "It was so filthy, so disgusting that he wanted to spit on it. ",
            },
            {
              Kor: "삼간초옥이라도 후락하고 다 쓰러져가는 아주 오막살이 삼 칸이다",
              Eng: "Even though it was called a three-room thatched roof cottage, it was really a decaying old shack on the verge of collapse.  ",
            },
          ],
        },
        {
          entryName: "-(이)라도2",
          typicalExample: "【누구라도 달리 할말을 찾지 못했을지도 모른다.】",
          categoryKorean: "Pseudo-particle",
          categoryEnglish: "(Pseudo-particle)",
          translation:
            "W-word + (이)라도 where pseudo-particle -(이)라도 functions like -(이)나 : any ... at all; any and every WH- ",
          sentences: [
            {
              Kor: "누구라도 달리 할말을 찾지 못했을지도 모른다.",
              Eng: "For all she knew, anybody else would have been unable to find anything different to say.",
            },
            {
              Kor: "필요하면 언제라도 떠날 수 있는 학원 강사 생활 ",
              Eng: "the life of the cram school lecturer, which one can always leave behind if necessary",
            },
          ],
        },
        {
          entryName: "-(이)라도3",
          typicalExample: "【몇천 번이라도 】",
          categoryKorean: "Pseudo-particle",
          categoryEnglish: "(Pseudo-particle)",
          translation:
            "Pseudo-particle -(이)라도: even if (it be) just (or the) like, functioning like 이나 as many as (implying that the speaker finds the amount in question more than expected).",
          sentences: [
            {
              Kor: "몇천 번이라도 ",
              Eng: "as many as several thousand times ",
            },
          ],
        },
        {
          entryName: "-이라두 ",
          typicalExample: "【의심나는 것이라두 있습니까. 】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation:
            "Colloquial/substandard for –이라도: just (or the) like (= -이나); or even any, some.",
          sentences: [
            {
              Kor: "의심나는 것이라두 있습니까. ",
              Eng: "Is there something you are doubtful about [or something]?",
            },
            {
              Kor: "고무신이라두 하나 사 신어야겠어. ",
              Eng: "You'll have to buy rubber shoes (or the like). ",
            },
          ],
        },
        {
          entryName: "-(이)라든가",
          typicalExample:
            "【무진에 가면 내게 새로운 용기라든가 새로운 계획이 술술 나오기 때문도 아니였었다.】",
          categoryKorean: "접속 조사",
          categoryEnglish: "(접속 조사)",
          translation:
            "As an abbreviation from -(이)라 [고 하-]든가 with much the same meaning as -(이)라[고 하-]든지 lit.: (the question) whether it be said to be, i.e., the likes of ...or [similar such things]. ",
          sentences: [
            {
              Kor: "무진에 가면 내게 새로운 용기라든가 새로운 계획이 술술 나오기 때문도 아니였었다.",
              Eng: "It wasn't because of some newfound courage or the like that I got from going to Mujin.",
            },
            {
              Kor: "밤이면 문 밖에 나가 앉아 하염없이 불러대는 <흑산도 아가씨>라든가, 어쨌든 나중엔 거의 환장할 지경이었다.  ",
              Eng: 'At night she would go outside, sit down and sing unceasingly "The Girl from Hŭksando" or some similar tune. Anyways, after awhile I was on the verge of going crazy.  ',
            },
            {
              Kor: "거리낌없는 애정 표현이라든가 ",
              Eng: "things like uninhibited expressions of love, or …",
            },
            {
              Kor: "남자의 가슴털이라든가 젖꼭지",
              Eng: "[things like] a man's nipples or chest hair",
            },
            {
              Kor: "진지한 음성이라든가 시계를 찬 팔목",
              Eng: "[things like] the watch hanging from his wrist or a sincere voice",
            },
          ],
        },
        {
          entryName: "-(이)라메- ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "접속 조사",
          categoryEnglish: "(접속 조사)",
          translation:
            'From -는다(고 하)며 meaning: 1) while saying that "QUOTE," ..., and 2) Is it true that "QUOTE?"',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-(이)라며",
          typicalExample: "【누구든지 다 반동이라며 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            'From -는다(고 하)며 meaning: 1) while saying that "QUOTE," ..., and 2) Is it true that "QUOTE?"',
          sentences: [
            {
              Kor: "누구든지 다 반동이라며 ",
              Eng: "[Pau shouted,] claiming that everyone was a counter-revolutionary ...",
            },
            {
              Kor: "사업을  방해하는 자는 누구든지 다 반동이라며  큰 소리를 질렀다.  ",
              Eng: "They screamed that anyone who gets in the way of business are all reactionaries.",
            },
            {
              Kor: "같이 자자고 요구하는 남자에게 눈물만으로 사랑을 확인해 달라며 폼잡고 싶다.  ",
              Eng: "I wished I could put on airs and to a man who wanted to sleep with me, I would ask him through tears to tell me he loved me.",
            },
          ],
        },
        {
          entryName: "-(이)라면 ",
          typicalExample: "【이름까지 알고 온 것이라면 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "From -(이)라(고) 하면: if it (be said to be) be..., if it were (the case) that.... More putative/hypothetical than -이면.",
          sentences: [
            {
              Kor: "이름까지 알고 온 것이라면 ",
              Eng: "if it is the case that he came even knowing [my] name ...",
            },
            {
              Kor: "꿈의 짐승, 전설의 짐승이라면 ",
              Eng: "if it is the tiger of my dreams, the mythological tiger ...",
            },
            {
              Kor: "진짜라면 좀 보여 주세요 ",
              Eng: "If (as you claim) it is real, then let's have a look at it. ",
            },
            {
              Kor: "왜 여자아이라면 사족을 못 쓰고  ",
              Eng: "Why it was that, when it came to boys, they went all soft in the knees?",
            },
            { Kor: "눈먼 아이라면  ", Eng: "if it were a blind child …" },
            {
              Kor: "남편이라면 내게 오지 않는 것이 상처를 주겠지만 ",
              Eng: "If he were my husband, not coming to me would leave a wound, but …",
            },
            {
              Kor: "제 오빠와 함께가 아니라면  ",
              Eng: "if it wasn't together with her older brother … (Here on 아니다, which explains the particle 가 on 함께.)",
            },
            {
              Kor: "수술을 해야 하는 어떤 불구아라면  ",
              Eng: "if it were some sort of disabled child that had to be operated on …",
            },
          ],
        },
        {
          entryName: "-(이)라면서 ",
          typicalExample: "【[Examples?]】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            'From -(이)라[고 하]면서 meaning while saying that "QUOTE," ...',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-(이)라면서(요)",
          typicalExample:
            "【총 맞아서 죽으나 술 마셔서 죽으나 마찬가지라면서  】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            'From -(이)라[고 하]면서 meaning Is it true that "QUOTE?"',
          sentences: [
            {
              Kor: "총 맞아서 죽으나 술 마셔서 죽으나 마찬가지라면서  ",
              Eng: "I thought you said that its same whether you get killed by a gunshot or by excessive drinking?",
            },
            {
              Kor: "절대적이라면서요?  ",
              Eng: "I thought you said it was unconditional? ",
            },
          ],
        },
        {
          entryName: "-(이)라면 지긋지긋해요",
          typicalExample: "【인젠 술하구 밥이라면 지긋지긋해요 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN이라면 지긋지긋해요: just the mere mention of NOUN is loathsome/detestable/weary ",
          sentences: [
            {
              Kor: "인젠 술하구 밥이라면 지긋지긋해요 ",
              Eng: "Now, when it comes to booze or food, I'm sick and tired of it all.",
            },
          ],
        },
        {
          entryName: "-(이)라서",
          typicalExample: "【팔 년이나 다닌 경험자라서 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The infinitive of the copula (-이어) can take the irregular shape -이라 before 서, 도, 야 and quotative -고. It is... and so; since because it is. Same as -이어서.",
          sentences: [
            {
              Kor: "팔 년이나 다닌 경험자라서 ",
              Eng: "since he was a person with eight or so years experience...",
            },
          ],
        },
        {
          entryName: "-(이)라야1",
          typicalExample: "【맹수를 비밀스러이 숨기고 있는 산이라야 했다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "The infinitive of the copula (-이어) can take the irregular shape -이라 before 서, 도, 야 and quotative -고. Thus, equivalent to -이어야 only if it be (usually followed by a negative or a desiderative).",
          sentences: [
            {
              Kor: "맹수를 비밀스러이 숨기고 있는 산이라야 했다 ",
              Eng: "It had to be a mountain that is secretly hiding a savage beast. ",
            },
          ],
        },
        {
          entryName: "-(이)라야2 ",
          typicalExample: "【돈이라야 삼 원밖에 없었다. 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "The infinitive of the copula (-이어) can take the irregular shape -이라 before 서, 도, 야 and quotative -고. Thus, equivalent to -이어야 only if it be (usually followed by a negative or a desiderative). However, in the usage here, -(이)라야 has been grammaticalized as an emphatic equivalent to particle -(이)야.",
          sentences: [
            {
              Kor: "돈이라야 삼 원밖에 없었다. ",
              Eng: "And for money, he had no more than three wŏn.",
            },
          ],
        },
        {
          entryName: "-(이)라지 않아 ",
          typicalExample: "【학은 장생불사(長生不死)라지 않아? 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation: "Contracted from -(이)라[고 하]지 않아.",
          sentences: [
            {
              Kor: "학은 장생불사(長生不死)라지 않아? ",
              Eng: '"Aren\'t cranes considered to be immortal?"; or, "Don\'t they say that cranes are immortal?”',
            },
          ],
        },
        {
          entryName: "-(이)라 하나",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Abbreviation",
          categoryEnglish: "(Abbreviation)",
          translation:
            "Abbreviated indirect quotation from -(이)라[고] 하나: They say that... but ... ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-(이)라 한다",
          typicalExample: "【아직도 오리무중이라 한다 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation: "Contracted from -(이)라[고] 한다.",
          sentences: [
            {
              Kor: "아직도 오리무중이라 한다 ",
              Eng: "It is said that they are still in utter bewilderment.",
            },
          ],
        },
        {
          entryName: "-(이)라 해도 ",
          typicalExample: "【초가을이라 해도 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "The quoted copula (-(이)라[고] + 해도: even considering that; even if one concedes that ...",
          sentences: [
            {
              Kor: "초가을이라 해도 ",
              Eng: "even though it was early fall...",
            },
          ],
        },
        {
          entryName: "-(이)라 해서 ",
          typicalExample: "【가장 가까운 마을이 변곡리라 해서 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation: "Contracted from -(이)라[고] 해서.",
          sentences: [
            {
              Kor: "가장 가까운 마을이 변곡리라 해서 ",
              Eng: "The closest village was Pyŏngok village, and ...",
            },
          ],
        },
        {
          entryName: "-(이)란1",
          typicalExample: "【무슨 무슨 유원지란 간판 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "NOUN(이)란 as a contraction of NOUN(이)라는, itself a contraction from NOUN(이)라[고 하]는 as modifier: a ... called NOUN.",
          sentences: [
            {
              Kor: "무슨 무슨 유원지란 간판 ",
              Eng: "a sign that read so-and-so recreational area ",
            },
            {
              Kor: "남편 맹 순사란 위인이 ",
              Eng: "her husband, the man called constable Maeng",
            },
            {
              Kor: "무용이란 건 문명국일수록 벗구 한다네그려. (무용이란 건 = 무용이라고 하는 것은)",
              Eng: "I heard that with dancing, the more civilized a country is, the more dancers bare skin.",
            },
          ],
        },
        {
          entryName: "-(이)란2",
          typicalExample:
            "【대화란 항상 의외의 방향으로 나가 버리기를 좋아하기 때문에 이렇게 글로써 알리는 바입니다. 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "NOUN(이)란: generic topic in -(이)란 for -(이)라고하는 것은 that is (called), as for (the one that is called). Used to make topics, especially in generic pronouncements or universalizing/generalizing statements.",
          sentences: [
            {
              Kor: "대화란 항상 의외의 방향으로 나가 버리기를 좋아하기 때문에 이렇게 글로써 알리는 바입니다. ",
              Eng: "Because conversations are things that always seem to take unexpected turns, I'm telling you this through a letter.  ",
            },
            {
              Kor: "호반들의 기개란 = 호만들의 기개라고 하는 것은 ",
              Eng: "The spirit/moral courage of the military nobilitywas...",
            },
            {
              Kor: "그 대신 10층까지 오르내리기란 보통 힘드는 것이 아니었다. ",
              Eng: "On the other hand, the task of going up and down ten floors proved to be quite difficult.",
            },
            {
              Kor: "역시 젊음이란 좋은 거야 ",
              Eng: "Youth is indeed a wonderful thing.",
            },
            {
              Kor: "역시 집이란 즐겁고 아늑한 곳이군 하고 그는 중얼거렸다. ",
              Eng: '"Just as I expected, [the place called] home really is amusing and cozy," he murmured.',
            },
            {
              Kor: "밥 짓는 일이란 머슴의 할 일이 못 된다.",
              Eng: "The work of a farmhand, which is that of cooking rice, will not do.",
            },
            {
              Kor: "김을 훅훅 뿜어내가며 식혀 먹는 맛이란 ",
              Eng: "Oh the taste of cooling off a piping hot sweet potato with puffs of your breath and then eating it, it was...",
            },
            {
              Kor: "나의 직장 일이란 아이 둘을 돌보고 한 집안의 살림을 꾸려 가는 일이다. ",
              Eng: "My job consists of taking care of two children and managing the household.",
            },
            {
              Kor: "그가 그런 말을 해야 하는 경우란  ",
              Eng: "but situations/cases where he had to say such things … [as a sweeping/generalizing topic]",
            },
            {
              Kor: "매일 마주하기란 간단한 일이 아니었다. ",
              Eng: "Facing them every day was not an easy matter.",
            },
          ],
        },
        {
          entryName: "-(이)란3",
          typicalExample: "【문이란 문은 】",
          categoryKorean: "구 단위 기능 조사",
          categoryEnglish: "(구 단위 기능 조사)",
          translation:
            "NOUN(이)란 NOUN: any and all NOUNS to speak of, any NOUNS worthy of the name",
          sentences: [{ Kor: "문이란 문은 ", Eng: "all the doors ..." }],
        },
        {
          entryName: "-(이)란다",
          typicalExample: "【저게 네 가정교사란다. 】",
          categoryKorean: "구 단위 기능 조사",
          categoryEnglish: "(구 단위 기능 조사)",
          translation:
            "-(이)란다: Abbreviated plain style quotations in -(이)란다, -ㄴ단다, -는단다 from -다[고 하-]ㄴ다, etc., are used (typically by children) as insistent or taunting statements, as proud/impudent boasts, or else as somewhat self-conscious pronouncements.",
          sentences: [
            {
              Kor: "저게 네 가정교사란다. ",
              Eng: "That's your private tutor, by the way.",
            },
            {
              Kor: "도리질을 하는 게 일이란다. ",
              Eng: "Her mother-in-law's head shaking was a daily routine. ",
            },
          ],
        },
        {
          entryName: "-(이)란 말",
          typicalExample: "【그 소박이란 말이 내 마음을 무겁게 한다. 】",
          categoryKorean: "구 단위 기능 조사",
          categoryEnglish: "(구 단위 기능 조사)",
          translation:
            "NOUN(이)란 말: from NOUN(이)라고 하는 → NOUN(이)라[고 하]ㄴ that is (called)... adnominalized to 말. Hence: words to the effect that QUOTE.",
          sentences: [
            {
              Kor: "그 소박이란 말이 내 마음을 무겁게 한다. ",
              Eng: "The word 'desertion' weighs heavy on my heart.",
            },
          ],
        },
        {
          entryName: "-(이)란 말야, etc.",
          typicalExample: "【대관절 어디란 말야 거기가?】",
          categoryKorean: "구 단위 기능 조사",
          categoryEnglish: "(구 단위 기능 조사)",
          translation:
            "An abbreviated indirect quotation in -(이)란 ← -(이)라[고 하-]ㄴ as modifier to following 말: words to the effect that QUOTE, where 말 (말야 ← 말이야, 말입니다, etc.= 말 followed by a form of the copula -이다), meaning I mean; you know; you see; uh, that is, etc.",
          sentences: [
            {
              Kor: "대관절 어디란 말야 거기가?",
              Eng: "Where the hell are you? ",
            },
            {
              Kor: "딴 놈들 물들지 않게 시범조로 손 좀 봐주란 말야. ",
              Eng: "I'm gonna set you up as an example to make sure no one else gets any ideas! Kick his ass!",
            },
          ],
        },
        {
          entryName: "-(이)랄까",
          typicalExample: "【육감으로서랄까】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contraction from an indirect quotation in -(이)라[고 하]ㄹ까: Might one call it ...?, Shall we say..?",
          sentences: [
            {
              Kor: "육감으로서랄까",
              Eng: "Shall I call it a sixth sense? (Here on particle -(으)로서 as, for, in the capacity of; being.)",
            },
            {
              Kor: "처음에 뵈었을 때, 뭐랄까요…  ",
              Eng: "When I first met you, how should I say this, ...",
            },
          ],
        },
        {
          entryName: "-(이)랍니다",
          typicalExample: "【뭐 이런 거랍니다 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "NOUN-(이)랍니다 contracted from -(이)라[-고 하-]ㅂ니다: I say it is/does or I tell you it is/does; it really is/does; it is/does, you see (mind you).",
          sentences: [
            { Kor: "뭐 이런 거랍니다 ", Eng: "You know, that kind of thing." },
            {
              Kor: "저런 여자들이 먹는 건 청산가랍니다 ",
              Eng: "What those girls take is called hydrocyanic acid.",
            },
            {
              Kor: "흥, 뻐젓이 사령장꺼정 받은 진짜 순사드랍니다요.   ",
              Eng: "Psh...they say they are actual constables with even obtained a real written order.",
            },
            {
              Kor: "저런 여자들이 먹는 건 청산가랍니다.   ",
              Eng: "The things that women like that eat is called cyanide.    ",
            },
          ],
        },
        {
          entryName: "-(이)랍시고 ",
          typicalExample: "【제 물건이랍시고 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Saying to oneself (somewhat facetiously or in a way that seems comical or unfitting to onlookers) that it is...",
          sentences: [
            {
              Kor: "제 물건이랍시고 ",
              Eng: "as if to say 'These are my things,' ...",
            },
          ],
        },
        {
          entryName: "-(이)랑이",
          typicalExample: "【불쌍한 늙인이랑이 】",
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation: "Dialect for  -(이)라니.",
          sentences: [
            { Kor: "불쌍한 늙인이랑이 ", Eng: "the poor old man..." },
          ],
        },
        {
          entryName: "이래, etc.",
          typicalExample: "【이래도 산길 백리는 한 나절에 달릴 수 있습니다.】",
          categoryKorean: "Infinitive",
          categoryEnglish: "(Infinitive)",
          translation:
            "The infinitive of 이렇- be like this and 이러- do/say like this is 이래. ",
          sentences: [
            {
              Kor: "이래도 산길 백리는 한 나절에 달릴 수 있습니다.",
              Eng: "Even so, I can run 100 li up the mountain path in half a day. (From 이렇- + -어+ 도 even though it be like this...)",
            },
            {
              Kor: "이래뵈도",
              Eng: "even though it appears to be like this (Contracted from 이래, infinitive from 이렇- + -어, plus 보여도 even though it appears to be like this...)",
            },
          ],
        },
        {
          entryName: "-(이)래도",
          typicalExample: "【입이 꽝우리 구멍이래도 】",
          categoryKorean: "Colloquial/Substandard/준꼴 Contraction",
          categoryEnglish: "(Colloquial/Substandard/준꼴 Contraction)",
          translation:
            "Colloquial/substandard for -이라도 just (or the) like (= 이나); or even any, some. In origin, a contracted quotation: -(이)라[고 ㅎ]ㅐ도.",
          sentences: [
            {
              Kor: "입이 꽝우리 구멍이래도 ",
              Eng: "even if my mouth were as big as a wicker basket ...",
            },
            {
              Kor: "한낱 가쾌(家쾌)로 복덕방 영감으로 기생 갈보 따위가 사글셋방 한 간을 얻어 달래도",
              Eng: "",
            },
          ],
        },
        {
          entryName: "-(이)래두",
          typicalExample: "【무기증역이래두 살다가 】",
          categoryKorean: "Colloquial/Substandard/준꼴 Contraction",
          categoryEnglish: "(Colloquial/Substandard/준꼴 Contraction)",
          translation:
            "Colloquial/substandard for -이라도 just (or the) like (= 이나); or even any, some. In origin, a contracted quotation: -(이)라[고 ㅎ]ㅐ두 = -(이)라[고 ㅎ]ㅐ두.",
          sentences: [
            {
              Kor: "무기증역이래두 살다가 ",
              Eng: "even if I get life [in prison] ...",
            },
          ],
        },
        {
          entryName: "-(이)래(요)",
          typicalExample: '【"걔 딸이 학교 안 간다고 울고 난리래." 】',
          categoryKorean: "Colloquial ",
          categoryEnglish: "(Colloquial )",
          translation:
            "-(이)래(요): the colloquial abbreviation of -(이)라고 해(요) for hearsay reporting, meaning they say that QUOTE or I hear that QUOTE. Cf. also -ㄴ대(요), -는대(요), -대(요). ",
          sentences: [
            {
              Kor: '"걔 딸이 학교 안 간다고 울고 난리래." ',
              Eng: '"Apparently his daughter is making a big fuss and refusing to go to school."',
            },
            { Kor: "“열여덟이래요.” ", Eng: "“They say she's 18.\"" },
            { Kor: "많이 벌었쉐다가레. 한 댓 냥 꿰주소고래.  ", Eng: "" },
            {
              Kor: "저 사람이 아버님과 우리 집안을 알고 있단 말예요? 그럼 누구 소개래요/?  ",
              Eng: "Are you saying that person knows Father and our family? Then who sent him?  ",
            },
            {
              Kor: "옷이 옥상옷만 도랑꾸루 열다섯 도랑꾸드래요  ",
              Eng: "They say his wife's clothes fill fifteen trunks. ",
            },
          ],
        },
        {
          entryName: "-(이)래서만(은) 아니다 ",
          typicalExample:
            "【첫사랑이 뻘겋게 달아오르던 곳이래서만은 아니었다 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contraction from -이라[고 해]서 plus particle 만 to emphasize the negation pattern in -만은 아니다 not just because... From pattern A-다고 해서 B아니다 meaning just because A, it is not the case that B.",
          sentences: [
            {
              Kor: "첫사랑이 뻘겋게 달아오르던 곳이래서만은 아니었다 ",
              Eng: "It was not just because it was the place where his first love had burned red hot. ",
            },
          ],
        },
        {
          entryName: "-(이)래야",
          typicalExample: "【빚이래야 그치들이 빨아먹은 나머지구요. 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "이래야 equivalent to -(이)라야 and contracted from -이라[고 해]야 only if it be; only if one calls it... (Equivalent in function to just -(이)야?)",
          sentences: [
            {
              Kor: "빚이래야 그치들이 빨아먹은 나머지구요. ",
              Eng: "As for any so-called 'debt', it's what's left of the money after those fellows have leeched off me.  ",
            },
            {
              Kor: "사는 것이래야 남보기엔 죽지 못해 사는 것이었다. ",
              Eng: "He might have called it living, but it was more like still being alive because he had not died yet.",
            },
          ],
        },
        {
          entryName: "이렇다 할 ",
          typicalExample: "【이렇다 할 평야가 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "For 이렇다[고] 할 NOUN. Lit.: a NOUN of/about which one could/would say something; i.e., worth talking about/noteworthy. Usually followed by a negative. ",
          sentences: [
            {
              Kor: "이렇다 할 평야가 ",
              Eng: "There is nothing there worth calling/you would call a plain ",
            },
          ],
        },
        {
          entryName: "-(이)로군(요)",
          typicalExample: "【허, 그 참 흉한 일이로군 】",
          categoryKorean: "Apperceptive Form",
          categoryEnglish: "(Apperceptive Form)",
          translation:
            "Apperceptive form of the copula for poetic or exclamatory effect (as opposed to just -이군[아, 요]. Other 'fancy' (poetic/exclamatory) forms of the copula with -이로-: FANCY REGULAR copula-이로다 = -이다; suspective-이로지 = -이지; familiar -이로세 = -일세; sequential-이로니 = -이니; adversative-이로나 = -이나; concessive –이로되 = -이되; literary or quoted-이로라(고) = -이라(고).",
          sentences: [
            {
              Kor: "허, 그 참 흉한 일이로군 ",
              Eng: "Oh my! That's a bad omen! ",
            },
            {
              Kor: "녹두 빈자떡을 부치는 게로군.  흥..... ← 것이로군 ",
              Eng: "I see you're making mung bean cakes.  Hmm...",
            },
          ],
        },
        {
          entryName: "-(이)로다 ",
          typicalExample: "【아매도 꿈이로다 】",
          categoryKorean: "Plain Style",
          categoryEnglish: "(Plain Style)",
          translation:
            "Plain style indicative form of the copula for poetic or exclamatory effect (as opposed to just -이다. Other 'fancy' (poetic/exclamatory) forms of the copula with -이로-: apperceptive copula-이로군[아, 요] = -이군; suspective-이로지 = -이지; familiar -이로세- = -일세; sequential-이로니 = -이니; adversative-이로나 = -이나; concessive -이로되 = -이되; literary or quoted-이로라(고) = -이라(고).",
          sentences: [
            { Kor: "아매도 꿈이로다 ", Eng: "Perhaps it is a dream!" },
            {
              Kor: "무가내하로다",
              Eng: "lit.: It was inevitable. (무가내하 is Sino-Korean 無可奈何 inevitability, having no alternative.)",
            },
          ],
        },
        {
          entryName: "-(이)로세",
          typicalExample: "【그 걱정이로세 】",
          categoryKorean: "Familiar Style",
          categoryEnglish: "(Familiar Style)",
          translation:
            "Familiar form of the copula for poetic or exclamatory effect (as opposed to just -(이)ㄹ세. Other 'fancy' (poetic/exclamatory) forms of the copula with -이로-: FANCY REGULAR copula-이로다 = -이다; suspective-이로지 = -이지; apperceptive -이로군- = -이군[아, 요]; sequential-이로니 = -이니; adversative-이로나 = -이나; concessive -이로되 = -이되; literary or quoted-이로라(고) = -이라(고).",
          sentences: [{ Kor: "그 걱정이로세 ", Eng: "it is my worry" }],
        },
        {
          entryName: "이르러",
          typicalExample: "【한참 추수기에 이르러 있었고】",
          categoryKorean: "Irregular Infinitive",
          categoryEnglish: "(Irregular Infinitive)",
          translation:
            "Note that the verb 이르- reach, get as far as has an irregular infinitive: 이르러.",
          sentences: [
            {
              Kor: "한참 추수기에 이르러 있었고",
              Eng: "It was/They were in the height of the harvest season, and ...",
            },
            {
              Kor: "그들은 옥에 이르러 기왕에 통해 놓은 사정이 자리를 비켜 주어서 ",
              Eng: "When they arrived at the jail, the lackey they had been in cahoots with stepped aside [and they made their way to Ch'unhyang's cell].",
            },
          ],
        },
        {
          entryName: "이만 ",
          typicalExample: "【둘이만 친하고 싶은 눈치가 역력했다】",
          categoryKorean: "Irregular Infinitive",
          categoryEnglish: "(Irregular Infinitive)",
          translation:
            "What looks like subject marker 이 + delimiter 만 only as an equivalent of what one normally encounters as 만이 is in fact related to the special subject marker (이)서. Cf. 둘이서 했다 they did it together, the two of them. ",
          sentences: [
            {
              Kor: "둘이만 친하고 싶은 눈치가 역력했다",
              Eng: "It was clear that the two of them wanted their intimacy to themselves.",
            },
          ],
        },
        {
          entryName: "-(이)며",
          typicalExample:
            "【학교 다니던 때 등교길이며 선생님이며 철봉이며에 대해 다퉈가며  】",
          categoryKorean: "접속 조사",
          categoryEnglish: "(접속 조사)",
          translation:
            "NOUN1(이)며 NOUN2, etc. meaning NOUN1 and NOUN2, etc., for long lists and litanies. Here -(이)며 functions like a quasi-particle with the meaning and, or, and/or.",
          sentences: [
            {
              Kor: "학교 다니던 때 등교길이며 선생님이며 철봉이며에 대해 다퉈가며  ",
              Eng: "arguing about the path we took on the way to school, the teachers, the exercise bars …",
            },
            {
              Kor: "칭얼대고 보챌 때마다 참기름이며 달걀이 묻은 손을 씻고  ",
              Eng: "Every time she fussed and whined, I washed my hands stained with sesame oil and egg, and …",
            },
            {
              Kor: "약봉지며 체온계며 대야, 수건 같은 것이",
              Eng: "Things like a medicine packet, a thermometer, and a towel …",
            },
            {
              Kor: "감자(고구마)며 배추를 도둑질하러 ",
              Eng: "[… would go] to steal potatoes and cabbages ",
            },
            {
              Kor: "신도며 고시생 ",
              Eng: "devotees and students preparing for the bar exams ",
            },
            {
              Kor: "잡초며 퇴락한 단청",
              Eng: "weeds and dilapidated frescos ",
            },
            { Kor: "쌀 나무며 찬거리", Eng: "rice and firewood and groceries" },
          ],
        },
        {
          entryName: "-(이)면1",
          typicalExample: "【잠이 오지 않는 밤이면  】",
          categoryKorean: "접속 조사",
          categoryEnglish: "(접속 조사)",
          translation:
            "Copular conditional -(이)면 to mark a topic if/when it is/be. The function is to create a more generalizing topicalizer: when it comes/came to...; as for ... In other words, in somewhat literary discourse, NOUN + -(이)면 has more or less the same function as NOUN + 은/는.",
          sentences: [
            {
              Kor: "잠이 오지 않는 밤이면  ",
              Eng: "On nights when she couldn't get to sleep …",
            },
            {
              Kor: "새벽 기도나 철야 기도가 있는 날이면 더했다.  ",
              Eng: "On days when there was dawn prayer or midnight prayer, it was even worse.",
            },
            {
              Kor: "나는 도마뱀을 본 날이면 그의 말을 떠올렸다.  ",
              Eng: "On days when I had seen the lizard, I recalled his words.",
            },
            {
              Kor: "그런 꿈을 꾼 날이면 아무 말도 하고 싶지 않을 만큼 피로했지만",
              Eng: "On the days when I dreamt such dreams, I was so tired that I didn't want to speak, but …",
            },
            {
              Kor: "밤이면 아무 일 없는 듯 ",
              Eng: "nights/at nighttime, as if nothing were the matter",
            },
            {
              Kor: "여자는 아침이면 아버지와 함께 나갔다가 저녁거리를 안고 돌아와",
              Eng: "In the mornings, the woman would go out with their father, and then come back with an armload of supper fixings.",
            },
            {
              Kor: "아침이면 제 방에서 말짱한 얼굴로 나오겠지만",
              Eng: "She'll come out of her room in the morning with an innocent face, but …",
            },
            {
              Kor: "그런 충동을 참을 수 없는 날이면  ",
              Eng: "On days when she could not bear/control this impulse",
            },
            {
              Kor: "밤이면 문 밖에 나가 앉아 하염없이 불러대는 <흑산도 아가씨>라든가 ",
              Eng: 'At night she would go outside, sit down and sing unceasingly "The Girl from Hŭksando" or some similar tune.',
            },
            {
              Kor: "연인들이 저녁이면 레스토랑에 들고가서 마실 포도주를 샀다",
              Eng: "In the evenings, lovers would buy wine to take with them to a restaurant [and drink].",
            },
            {
              Kor: "그런 밤이면 포도주를 한잔쯤 마시고 누워 여자는 ",
              Eng: "When it was one of those nights, the woman drank about a glass of wine and lying down, she would... ",
            },
            {
              Kor: "아침이면 자신에게 성을 내며 여자는 일어났다",
              Eng: "When it was morning, she would arise tired and angry with herself.",
            },
            {
              Kor: "밝는 날이면 유배지로 떠나는 승지 이공의 얼굴에는 아무 빛도 없었다 ",
              Eng: "Minister Yi would be going to his place of exile at daybreak. His face showed no emotion.",
            },
          ],
        },
        {
          entryName: "-(이)면2",
          typicalExample: "【부채면 부채, 담뱃갑이면 담뱃갑】",
          categoryKorean: "접속 조사",
          categoryEnglish: "(접속 조사)",
          translation:
            "NOUN(이)면 NOUN: whether it be NOUN or whatever—no matter ",
          sentences: [
            {
              Kor: "부채면 부채, 담뱃갑이면 담뱃갑",
              Eng: "be it a fan or a pack of cigarettes [he would snatch up his things and ...]",
            },
          ],
        },
        {
          entryName: "이상",
          typicalExample: "【백 원 이상 】",
          categoryKorean: "접속 조사",
          categoryEnglish: "(접속 조사)",
          translation:
            "NOUN이상: 1) more than, above, over, upward of NOUN, 2) beyond, past, more than; further, 3) since, now that, seeing that, 4) that is all, that is the end.",
          sentences: [
            { Kor: "백 원 이상 ", Eng: "more than a hundred wŏn" },
            {
              Kor: '"그이상의 협조는 바라지도 않아."',
              Eng: '"Any cooperation above and beyond that I don\'t even expect."',
            },
            {
              Kor: "지섭의 할아버지는 십 년 이상 고향을 떠나 살았다. ",
              Eng: "Chisŏp's grandfather had lived away from his hometown from more than 10 years.",
            },
            {
              Kor: "십 년 이상 바람 찬 땅에서 그가 쫓기며 싸워 얻은 것은 ",
              Eng: "All he had to show for himself after more than ten years of scrapping and being chased around in a cold, windblown land was...",
            },
            {
              Kor: "가수가 인사를 하러 나올 때쯤 반 이상의 사람들은 입구를 향해 다투어 밀리고 있었다. ",
              Eng: "By the time the singer came out for their final bow, more than half of the audience were pushing and shoving their way towards the exits.",
            },
            {
              Kor: "세 개를 따는데 15초 이상 걸리지 않았다. ",
              Eng: "Opening three cans did not take more than fifteen seconds.  ",
            },
            {
              Kor: "교감은...부녀 이상의 비밀스러운 무엇인가가 있었다 ",
              Eng: "",
            },
            {
              Kor: "더구나 벌이는 배 이상이 아닌가.  ",
              Eng: "Besides, the money was more than double. ",
            },
            {
              Kor: "최소한도로 오십 배 이상의 순이익이 날 것이라 장담 장담하였다.  ",
              Eng: "It's been predicted that the net profit will be at least fifty times what you invested.  ",
            },
            {
              Kor: "도시 젊은이에 대한 필요 이상의 호의며 관심을 내보이지 않은 채",
              Eng: "[He didn't seem interested,] only showing him as much kindness as necessary to a city boy.",
            },
          ],
        },
        {
          entryName: "이상으로",
          typicalExample: "【박희완 영감에게 묻던 이상으로 】",
          categoryKorean: "접속 조사",
          categoryEnglish: "(접속 조사)",
          translation:
            "NOUN이상: 1) more than, above, over, upward of NOUN, 2) beyond, past, more than; further, 3) since, now that, seeing that, 4) that is all, that is the end.",
          sentences: [
            {
              Kor: "박희완 영감에게 묻던 이상으로 ",
              Eng: "above and beyond what [Ch'osi] had asked Pak Hŭiwan",
            },
          ],
        },
        {
          entryName: "~ 이상의 NOUN ",
          typicalExample: "【필요 이상의 자신 】",
          categoryKorean: "접속 조사",
          categoryEnglish: "(접속 조사)",
          translation:
            "NOUN이상: 1) more than, above, over, upward of NOUN, 2) beyond, past, more than; further, 3) since, now that, seeing that, 4) that is all, that is the end.",
          sentences: [
            {
              Kor: "필요 이상의 자신 ",
              Eng: "a confidence no more than what is necessary ",
            },
            {
              Kor: "필요 이상의 호의 ",
              Eng: "no more than the basic show of goodwill ",
            },
          ],
        },
        {
          entryName: "-(이)서 ",
          typicalExample: "【두 분이서 먼저 가면 될거 아녜요】",
          categoryKorean: "접속 조사",
          categoryEnglish: "(접속 조사)",
          translation: "More than one subject acting together.",
          sentences: [
            {
              Kor: "두 분이서 먼저 가면 될거 아녜요",
              Eng: "You two can just go on ahead then.",
            },
            {
              Kor: "남자 둘이서 오만상을 찌푸려가며 이야기하거나 ",
              Eng: "The two men either spoke with faces clouded over and frowning or...",
            },
            {
              Kor: "일본 우동집에 들어가서 둘이서 정종만 열 병 따라 뉘고  ",
              Eng: 'They went to a Japanese "udong" house and poured ten bottles of rice wine between the two of them...',
            },
          ],
        },
        {
          entryName: "-(이)시여",
          typicalExample: "【하나님 아버지시여 】",
          categoryKorean: "호격 조사",
          categoryEnglish: "(호격 조사)",
          translation:
            "Literary honorific vocative -(이)시여 (to address super-honorific individuals).",
          sentences: [
            {
              Kor: "하나님 아버지시여 ",
              Eng: "Our Father who art in Heaven; or Our Heavenly Father  ",
            },
            {
              Kor: "하나님 아버지시여, 벼락을 치실려거든 돈벼락이나 쳐주십시오",
              Eng: "Oh Father in Heaven, if you are going to strike me, may I be able to strike it rich instead...",
            },
          ],
        },
        {
          entryName: "-(이)야1",
          typicalExample: "【집안일이 지겹고 힘들다는 거야 나도 알지. 】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "Particle 이야 only if it be; if (it be), when it comes to; even, indeed. (Marks a reinforced contingency or prerequisite for a main clause, one or the other of which is unlikely, unexpected, or anticipated to be difficult or unpleasant).",
          sentences: [
            {
              Kor: "집안일이 지겹고 힘들다는 거야 나도 알지. ",
              Eng: "Of course I know that she finds household chores tiresome and difficult.",
            },
            {
              Kor: '자기야 근무 여건이 좋은 편이지, 안 그래?" ',
              Eng: '"Her own working conditions are pretty decent, don\'t you think?"',
            },
            {
              Kor: '"예비고사야 잘 봤겠지."',
              Eng: "“Well, you must have done well on the preparatory exam.”",
            },
            {
              Kor: "신랑깜이야 수두룩하긴 하지만",
              Eng: "As for potential grooms, there are indeed many more [in Seoul], but ...",
            },
            {
              Kor: "만 원 하나야 어디로 가랴 하는 타협이 생기어서",
              Eng: "Since we're concerned about where only a one-thousand wŏn bill is going... ",
            },
            {
              Kor: "남이야 어떻게 보든 우선 나 자신이 재취라는 것은 무언가 찜찜하고 신선하지 않았고 ",
              Eng: "Regardless of what others might think, remarriage was somewhat awkward and didn't feel new, and …",
            },
            {
              Kor: "하늘에 떠 있지 않는 것이야 없을 것이나 ",
              Eng: "it is not the case that [the moon] doesn't appear in the sky [just because of a particular time of the year], but ...",
            },
            {
              Kor: "여자야 그만이었죠 ",
              Eng: "When it comes to women, there was no one like her. ",
            },
            {
              Kor: "부자집처럼 고깃국이야 먹여 줄라구 하는 생각",
              Eng: 'the thought of "As if she would feed me meat broth like they do in rich homes"',
            },
            {
              Kor: "터야 안 남았는기오",
              Eng: "[The house we used to live in,] not even the foundation was remaining (The question here is a rhetorical one: Wouldn't you know it, ...?)",
            },
            {
              Kor: "나야 뭐 하는 일이 있어야지",
              Eng: "If only I had something to do.",
            },
            {
              Kor: "제 따위들이 뭐라구 잡아가구 말구야. ",
              Eng: "Who do they think they are? To think that they can stand there and discuss whether or not to come after me? ([A고 B고]야: a matter of ...-ing or not.)",
            },
          ],
        },
        {
          entryName: "-(이)야",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Intimate Style",
          categoryEnglish: "(Intimate Style)",
          translation:
            "Intimate Style copula -(이)야, which takes the shapes -이야 after consonants and -야 after vowels. ",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-(이)야2",
          typicalExample: "【양씨 어른이야 】",
          categoryKorean: "Abbreviation",
          categoryEnglish: "(Abbreviation)",
          translation:
            "Short/abbreviated version of NOUN(이)야말로 NOUN indeed; NOUN certainly.",
          sentences: [{ Kor: "양씨 어른이야 ", Eng: "Mr. Yang, indeed. " }],
        },
        {
          entryName: "-(이)야(말로) ",
          typicalExample: "【이 사람이야말로 알 수 없는 사람이다.】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation: "NOUN(이)야말로: NOUN indeed; NOUN certainly",
          sentences: [
            {
              Kor: "이 사람이야말로 알 수 없는 사람이다.",
              Eng: "This guy, indeed, is an inscrutable person.",
            },
            {
              Kor: "나는 어쩌면 이 아주머니야말로 대사업을 하고 있는 게 아닌가 하는 생각이 들면서 ",
              Eng: "As I thought that perhaps it may be this woman who is accomplishing great things...",
            },
            {
              Kor: "오늘이야말로 강마담의 반반한 쌍판이 당수 2단의 주먹에 으스러지는구나",
              Eng: "I guess today will certainly be the day that Madam Kang's pretty little face will get crushed by a second degree karate punch. ",
            },
            {
              Kor: "우리 양 씨 어른이야말로 사방 오백 리 안에서는 알아주는 몰이꾼이구 ",
              Eng: "Our Mr. Yang is indeed a hunter who is acknowledged by everyone within as far as a 500 li radius",
            },
            {
              Kor: "이제야말로 내가 아버지보다 우위에 서서 그를 돕고...",
              Eng: "Finally indeed, I'm standing at a taller height than my father and helping him, and …",
            },
          ],
        },
        {
          entryName: "-(이)야말루",
          typicalExample: "【나두 걱정야말루 그 걱정이로세. 】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "Dialect/substandard for NOUN(이)야말로: NOUN indeed; NOUN certainly",
          sentences: [
            {
              Kor: "나두 걱정야말루 그 걱정이로세. ",
              Eng: "Worried? I certainly am worried about that. (The -루 here is dialect.)",
            },
          ],
        },
        {
          entryName: "-(이)야요",
          typicalExample: '【"그게 무슨 말야요?" 】',
          categoryKorean: "Polite Style",
          categoryEnglish: "(Polite Style)",
          translation:
            "In the first half of this century, the Polite Style of the copula (-이다) was often pronounced and written as -이야요, a reasonable thing to do if one considers the analogy of many other verbs where the Polite Style was simply the infinitive (해) + 요. The copula had competing infinitive forms in -이어 and -이야 (? -이아), and eventually the former won out in the Polite Style, giving -이어요 then -이에요.",
          sentences: [
            {
              Kor: '"그게 무슨 말야요?" ',
              Eng: "What's that supposed to mean?",
            },
            { Kor: '"누나야요." ', Eng: "It's your older sister." },
            {
              Kor: "가리실 처지야요? = 처지예요? ",
              Eng: "Are you in a position to be finicky about good and bad quality?",
            },
            {
              Kor: "간이보험이야요 = 간이보험이에요",
              Eng: "It's postal life insurance.",
            },
            { Kor: "아니야요 = 아니에요 ", Eng: "No." },
            {
              Kor: "장거리에서들은 지금 피난을 간다고 야단들이야요 ",
              Eng: "People in the marketplace are all in an uproar about people taking refuge.",
            },
          ],
        },
        {
          entryName: "-(이)어서  ",
          typicalExample: "【근무를 하라는 영이어서 】",
          categoryKorean: "Polite Style",
          categoryEnglish: "(Polite Style)",
          translation:
            "Because/since it is/was. The other -어서 form for the copula is irregular: -(이)라서.",
          sentences: [
            {
              Kor: "근무를 하라는 영이어서 ",
              Eng: "as it was an order to serve...",
            },
            {
              Kor: "거리는 토요일 오후에다 연말이어서 마냥 붐비고 있었다  ",
              Eng: "",
            },
            {
              Kor: "시장백화점 등은 연말인 데다 토요일이어서 악마구리 끓듯 할 것이다  ",
              Eng: "",
            },
            { Kor: "빈 속이어서일까. 첫잔에 머리가 비잉 돌았다.  ", Eng: "" },
            { Kor: "워낙 기벽이 유다른 사람이어서 ", Eng: "" },
            { Kor: "하기는 대중 없는 일이어서 첫손에 찾아도  ", Eng: "" },
            { Kor: "시장이 반찬이어서가 아니었다  ", Eng: "" },
            { Kor: "봉네는 그 옛날 탄실이어서는 안된다 했다.  ", Eng: "" },
            { Kor: "나 또한 무슨 대단한 혜택을 받는 계층이어서  ", Eng: "" },
            {
              Kor: "거기에 갔을 땐 벌써 될대루 되라는 식이어서 겁나는 것두 없었구요.  ",
              Eng: "",
            },
            { Kor: "불이 너무 갑자기 들어온 기분이어서  ", Eng: "" },
            { Kor: "솟아오르는 산이 아니라 흘러내리는 자락이어서  ", Eng: "" },
          ],
        },
        {
          entryName: "-(이)요",
          typicalExample: "【그곳에 모여 있는 사람들의 정업은 거지요, … 】",
          categoryKorean: "Polite Style",
          categoryEnglish: "(Polite Style)",
          translation:
            "Mid-sentence -(이)요 = -이고, but with a literary flourish and a sense of insistence and/or emphasis. ",
          sentences: [
            {
              Kor: "그곳에 모여 있는 사람들의 정업은 거지요, … ",
              Eng: "The main line of work for everyone gathered there was beggary...",
            },
            {
              Kor: "쫑쫑대고 쌩동거리고 하던 끝에 필경은 나오는 것이 그 뉴똥치마의 푸념이요, …  ",
              Eng: "After all her ranting and bitching came the ravings for the silk dress... ",
            },
            {
              Kor: "이른바 신경질이요, 요망스런 부류의 여자였다.",
              Eng: "She had a nervous temperament. She was what you would call a fickle kind of woman.  ",
            },
            {
              Kor: "그런 소리가 다 말 같지도 아니한 소리요 억지엣발명이었다 ",
              Eng: "Those words didn't make sense at all, they were all crap.",
            },
            {
              Kor: "좋게 말하면 원만이요, 사실대로 말하면 반편스럽고...",
              Eng: "Nicely put, he was amicable—but in reality, he was slow-witted, and…",
            },
            {
              Kor: "술대접이나 받고 하는 것은, 아무나 예사로 하는 일이요, … ",
              Eng: "Receiving a liquor table and the like are things that anyone would do regularly …",
            },
            {
              Kor: "모자도 정복도 패검도 다 옛것이요,… ",
              Eng: "The hat, the uniform and the sword worn on the side were all things of the past …",
            },
            {
              Kor: "깃대도 받아 주고 삐라도 뿌려 주고 하는 것이 일이요,… ",
              Eng: "He carried [advertisement] banners and handed out flyers...",
            },
            {
              Kor: "진실로 나의 원수요, 나의 친구의 원수요,...  ",
              Eng: "Truthfully the one who is my enemy, my friend's enemy...is...",
            },
            {
              Kor: "눈에 바라다보이는 곳곳에 불을 놓는 사람들이요 불길이며, 하늘까지 올라가는 연기였다 ",
              Eng: "People were setting fire everywhere as far as I could see, with the flames reaching up to the sky.   ",
            },
            {
              Kor: "휘엿한 산등은 누워 있는 황소의 등어리요,… ",
              Eng: "That curved razer-edge is the back of an ox, lying down...",
            },
            {
              Kor: "두 발은 뿌리요, 두 팔은 가지다 ",
              Eng: "My two legs are roots, my arms the branches.",
            },
            {
              Kor: "그것은 처음부터 계책한 억지요 졸색의 등긁개 따위에는 손대일 염도 없었던 것이다 ",
              Eng: "It had been a scheme that Old Kim had put in place from the very beginning. Laying hands on that plug-ugly back-scratcher was the furthest thing from Chŏng-sil's mind.",
            },
            {
              Kor: "그 목청이요, 그 모습이었다 ",
              Eng: "It was the same old voice, the same old appearance [just as he remembered it].",
            },
            {
              Kor: "고층건축 들이요, 동네마다 느느니 그림 같은 문화주택들이다",
              Eng: "There were high-rise buildings and picturesque modern houses scattered throughout every city...  ",
            },
            {
              Kor: "하나는 안영감보다 칠 년이나 위인 해수병쟁이요, … ",
              Eng: "One of them was a navy captain with consumption who was seven years or so his senior, and also...",
            },
            {
              Kor: "남원으로 들어서는 박석고개를 올라서니 산도 예 보던 산이요,… ",
              Eng: "When he reached Paksŏk Pass leading over to Namwŏn, he recognized the same familiar hills...",
            },
            {
              Kor: "춘향의 일건을 풍류남아로서 <스타일 구겼다[아래아]>고 생각했던 것이요,… ",
              Eng: "From his point of view, the whole Ch'unhyang affair was merely an embarrassing but minor setback…",
            },
            {
              Kor: "한 사람은 장모 눈치 보는 기둥 서방이요,… ",
              Eng: "The one was a kept man under his mother-in-law's thumb, [and the other...]",
            },
          ],
        },
        {
          entryName: "-(이)요 한다",
          typicalExample: "【공박이요 하였던 것 】",
          categoryKorean: "Polite Style",
          categoryEnglish: "(Polite Style)",
          translation:
            "This pattern is analogous to VERB1고 plus (VERB2고) 하- does/is and stuff, also beloved by 채만식. ",
          sentences: [
            {
              Kor: "공박이요 하였던 것 ",
              Eng: "the thing of having always harassed and stuff",
            },
            {
              Kor: "남편의 항차 후취요 하니 ",
              Eng: "considering she had become, still less, her husband's second wife and stuff ...",
            },
            {
              Kor: "매맞기가 일이요 하였다 ",
              Eng: "...made a habit and stuff of getting beaten.",
            },
            {
              Kor: "지조 없고 무능이요 하였다 ",
              Eng: "… lacked ambition and had no talents and stuff. ",
            },
          ],
        },
        {
          entryName: "이왕(에)",
          typicalExample: "【이왕에 이렇게 되었으니 】",
          categoryKorean: "Polite Style",
          categoryEnglish: "(Polite Style)",
          translation:
            "Already, now that, as long as, since. Usually followed by -는/-ㄴ 김에, but sometimes by -(으)니.",
          sentences: [
            {
              Kor: "이왕에 이렇게 되었으니 ",
              Eng: "since it ended up this way ...",
            },
          ],
        },
        {
          entryName: "-이유",
          typicalExample: "【어쩐 일이유 】",
          categoryKorean: "Dialect/Semi-formal Style",
          categoryEnglish: "(Dialect/Semi-formal Style)",
          translation:
            "Dialect -이유 for -이우 = -이오 (Semi-formal/Blunt Style copula).",
          sentences: [{ Kor: "어쩐 일이유 ", Eng: "What do you want?" }],
        },
        {
          entryName: "-이자",
          typicalExample: "【마치 처음이자 마지막 기회라도 되는 듯이】",
          categoryKorean: "Dialect/Semi-formal Style",
          categoryEnglish: "(Dialect/Semi-formal Style)",
          translation: "NOUN1(이)자 NOUN2: [is] both NOUN1 and NOUN2",
          sentences: [
            {
              Kor: "마치 처음이자 마지막 기회라도 되는 듯이",
              Eng: "as if this were my first and last opportunity",
            },
          ],
        },
        {
          entryName: "-(이)지만",
          typicalExample: "【여기가 살림집이기도 해서지만 】",
          categoryKorean: "Dialect/Semi-formal Style",
          categoryEnglish: "(Dialect/Semi-formal Style)",
          translation:
            "[Statement]-[이]지만: using the although reading on an entire clause; also does/is also",
          sentences: [
            {
              Kor: "여기가 살림집이기도 해서지만 ",
              Eng: "It is also partly because this is a private house ...",
            },
          ],
        },
        {
          entryName: "이토록 ",
          typicalExample:
            "【이토록 관장을 업신여기는 년이 일찌기 있었다는 말도 듣지 못하였거니와】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "In origin, a contraction of 이 this + 하도록 and meaning to this extent (paraphrasable as 이러한 정도로까지 to such an extent; to this extent; so (very) much).",
          sentences: [
            {
              Kor: "이토록 관장을 업신여기는 년이 일찌기 있었다는 말도 듣지 못하였거니와",
              Eng: "It was bad enough that he had never encountered a wench so contemptuous of government authority...",
            },
          ],
        },
        {
          entryName: "-(이)ㄴ가1",
          typicalExample: "【벌써 세 번짼가 내 번째 】",
          categoryKorean: "Pseudo-particle",
          categoryEnglish: "(Pseudo-particle)",
          translation:
            "NOUN1인가 NOUN2(인가). Quasi-particle 인가: NOUN or the like; is/was it NOUN?; NOUN or so; some-WH or other. ",
          sentences: [
            {
              Kor: "벌써 세 번짼가 내 번째 ",
              Eng: "already the third or fourth time (or so)",
            },
            {
              Kor: "고고인가 꽈배기 양춤인가를",
              Eng: "[the 'driver', who was said to dance] the Go-Go or the twist [or whatever] ...",
            },
            {
              Kor: "그는 열살 때부턴가 ",
              Eng: "lit.: He said that from the age of 10 or so [he had been shining shoes.]",
            },
            { Kor: "작년인가부터는", Eng: "From, I think, last year or so …" },
          ],
        },
        {
          entryName: "-(이)ㄴ가2",
          typicalExample: "【가슴을 무엇인가 둔중한 것으로 얻어맞은 듯이 】",
          categoryKorean: "Pseudo-particle",
          categoryEnglish: "(Pseudo-particle)",
          translation:
            "Question word + counter -(이)ㄴ가: how many/what/when (etc.) was it?—I'm not sure ",
          sentences: [
            {
              Kor: "가슴을 무엇인가 둔중한 것으로 얻어맞은 듯이 ",
              Eng: "as if I had been hit in the chest with something blunt and heavy",
            },
            {
              Kor: "작은 병들이 몇 개인가 담긴 플라스틱 바구니",
              Eng: "a plastic basket containing a few little bottles or so",
            },
            {
              Kor: "지수가 몇 번인가 고개를 흔들었지만 ",
              Eng: "Chisu shook her head 'no' several times, but …",
            },
            {
              Kor: "지수의 몸 안, 어느 한쪽에 숨어 있던 무언가가  ",
              Eng: "Something, some je ne sais quois, that had been hiding inside Chisu's body, to one side …",
            },
            {
              Kor: "어느 날부터인가 여자는 출근하기를 그만두고  ",
              Eng: "From one day (can't remember when it was) the woman stopped going to work in the morning, and …",
            },
            { Kor: "몇 개인가의 서류", Eng: "a few documents" },
            {
              Kor: "달의 가장자리, 그 어느 어름에선가 흰 줄기가 ... ",
              Eng: "From somewhere on the rim around the moon, from some juncture, a white rope ... ",
            },
            { Kor: "누군가의 비명 ", Eng: "somebody's scream" },
            {
              Kor: "누군가 잘못 떨어뜨린 솜뭉치",
              Eng: "a cotton wad that somebody [or other] had accidentally dropped",
            },
            {
              Kor: "살아 있는 누군가가 움직이고 있었다.  ",
              Eng: "Some living person was moving.",
            },
            {
              Kor: "언제부턴가",
              Eng: "He didn't know when it had started ...; or, Snce sometime or other way back when … (lit.: oblique question 'Since when was it that it started?')",
            },
            {
              Kor: "어느 거리에선가 지나가던 행인 ",
              Eng: "a passerby that passed by one some street or other",
            },
            {
              Kor: "그에게선 어딘가 이 세상 사람 같지 않은 느낌이 배어났다. ",
              Eng: "A feeling emanated from him that somehow he was not of this world.",
            },
            {
              Kor: "어디선가 들어봤음직한 ... 음률이다. ",
              Eng: "It was music that I had likely heard somewhere.",
            },
            {
              Kor: "그는 무엇인가를 빠르게 말하고 있다.",
              Eng: "He is saying something or other quickly.",
            },
            {
              Kor: "엄마가 어디론가 가고 있다. ",
              Eng: "Mom is going somewhere or other.",
            },
            {
              Kor: "엄마의 입에서 나온 뱀은 어디론가 사라져간다. ",
              Eng: "The snake that has just exited mom's mouth disappears to somewhere.",
            },
          ],
        },
        {
          entryName: "-(이)ㄴ 것이다",
          typicalExample:
            "【단식이나 침묵 시위 같은 것은 애초에 성립될 수조차 없는 일인 것이다.  】",
          categoryKorean: "Pseudo-particle",
          categoryEnglish: "(Pseudo-particle)",
          translation:
            "NOUN인 것이다: It is a case of being NOUN (rather more distancing and objective-sounding than just NOUN이다.)",
          sentences: [
            {
              Kor: "단식이나 침묵 시위 같은 것은 애초에 성립될 수조차 없는 일인 것이다.  ",
              Eng: "The fact of the matter is that refusing to eat her food or giving her the silent treatment just aren't options from the get-go.",
            },
          ],
        },
        {
          entryName: "-인즉(슨)",
          typicalExample:
            "【내용인즉 칭찬이었고, 따라서 주인으로서는 당연히 겸사의 말이 있어야겠지만 】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "NOUN인즉(슨) copula modifier -인 + postmodifier 즉(슨): to speak of, speaking of, as for. A somewhat literary or lively equivalent to just 은/는.",
          sentences: [
            {
              Kor: "내용인즉 칭찬이었고, 따라서 주인으로서는 당연히 겸사의 말이 있어야겠지만 ",
              Eng: "As for the content, it was full of praise and as the owner there should definitely be a humble speech but...",
            },
            { Kor: "그 어둠인즉슨 ", Eng: "speaking of the darkness ..." },
          ],
        },
        {
          entryName: "일반으로",
          typicalExample: "【마을에서 볼 때와 일반으로 멀다】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "NOUN과/와 일반으로: compared to NOUN, it is generally ...; with no difference compared to NOUN",
          sentences: [
            {
              Kor: "마을에서 볼 때와 일반으로 멀다",
              Eng: "It was on the whole more distant than looking out from the village.",
            },
          ],
        },
        {
          entryName: "있은",
          typicalExample: "【가맣게 잊고 있은 듯했다 】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation: "Rare/archaic use of past modifier -(으)ㄴ on 있다.",
          sentences: [
            {
              Kor: "가맣게 잊고 있은 듯했다 ",
              Eng: "It was as if he had completely forgotten.",
            },
            {
              Kor: "B부인에게 와 있은 것이 ",
              Eng: "The fact of his having come and stayed at Mrs. B's.",
            },
            { Kor: "공이 있은 자 ", Eng: "a person with honor " },
          ],
        },
        {
          entryName: "있은 지",
          typicalExample: "【그 일이 있은 지 한 달도 채 못되어서였다】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "Rare/archaic use of past modifier -(으)ㄴ on 있다. Nowadays one rarely sees the use of past modifier -(으)ㄴ on 있-. ",
          sentences: [
            {
              Kor: "그 일이 있은 지 한 달도 채 못되어서였다",
              Eng: "It hadn't even been quite a month since it happened.",
            },
          ],
        },
        {
          entryName: "있잖아(요)",
          typicalExample: '【"여보, 태원이 있잖아." 】',
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            'NOUN 있잖아(요). You know how in English we sometimes start a conversation or jump-start an exchange by introducing a topic of conversation with You know [how] ... ? This is accomplished in Korean with "TOPIC + 있잖아(요)?" and a rising intonation. Another related meaning of 있잖아(요)? (often preceded by an  interjection like 왜 or 저~) is You know what I mean, right? (when you yourself can\'t remember!)',
          sentences: [
            {
              Kor: '"여보, 태원이 있잖아." ',
              Eng: '"Honey, you know T\'aewŏn?"',
            },
          ],
        },
        {
          entryName: "있지(요)",
          typicalExample:
            "【부친 위해 보험 든 거 있지? = 부친(을) 위해(서) 보험(에) 든 (이) 있지?】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "NOUN은/는 + 있지(요)? You know the NOUN? (as a way of jump-starting the conversation, introducing a new topic into the discourse). Example: You know the insurance you took out for your father? Alternatively, this particular example could just be a typical case of -지요? in its tag question (n'est-ce pas?, nicht wahr?, etc.) function; lit., there exists the fact of having taken out insurance ..., doesn't there?, i.e., You took out some insurance for your father, didn't you?",
          sentences: [
            {
              Kor: "부친 위해 보험 든 거 있지? = 부친(을) 위해(서) 보험(에) 든 (이) 있지?",
              Eng: "You took out some insurance for your father, didn't you? ",
            },
          ],
        },
        {
          entryName: "-자 (마자)",
          typicalExample: "【그러자 나도 마음이 급해졌다】",
          categoryKorean: "Modifier",
          categoryEnglish: "(Modifier)",
          translation:
            "Plain base + -자 (마자): as soon as, when, no sooner than. In literary Korean, it is more common to leave off the optional 마자, and in spoken Korean, some speakers treat the latter as 말자.",
          sentences: [
            {
              Kor: "그러자 나도 마음이 급해졌다",
              Eng: "No sooner [had I thought these thoughts that] I too grew uneasy.",
            },
            {
              Kor: "영달이가 투덜대자 정씨가 말했다",
              Eng: "As soon as Yŏngdal whined, Mr. Chŏng said ...",
            },
            {
              Kor: "아버지는 아홉시가 넘자 잠이 들었다 ",
              Eng: "Father fell asleep after nine o'clock.",
            },
            {
              Kor: "지난 봄에 내가 실직을 하게 되자 ",
              Eng: "Last spring, as soon as I lost my job, ...",
            },
            {
              Kor: "아버지는 아침이 되자 한결 좋아져서 세면을 하러 우물까지 혼자 갔다올 정도였다 ",
              Eng: "As soon as it became morning, father was doing so much better that he could go to the well to wash up on his own.",
            },
            {
              Kor: "다음날 반찬이 열다섯 가지쯤 되는 여관의 아침상을 받자 ",
              Eng: "As soon as I got the inn's breakfast table, consisting of about fifteen side dishes...",
            },
            {
              Kor: "버스가 속력을 내자 나는 겁먹은 소리로 물었다.",
              Eng: "No sooner had the bus started to speed up when I asked her fearfully...",
            },
            {
              Kor: "상지대를 한 바퀴 돌자 다시 눈앞에 얼어붙은 호수가 펼쳐졌다.",
              Eng: "Once I had circled the commercial district, the frozen lake lay before me again. ",
            },
            {
              Kor: "차츰 어둠에 눈이 익자 아랫목에 단정히 앉았는 한 노파를 볼 수 있었다 ",
              Eng: "As my eyes slowly got accustomed to the dark I was able to see an old woman sitting upright on the warmer part of the ondol room.",
            },
            {
              Kor: "몸이 녹자 잠이 오기 시작했다. ",
              Eng: "As soon as my body thawed I began to drift into sleep.",
            },
            {
              Kor: "세상이 뒤바뀌자 그 땅은 전부가 동양척식회사의 소유에 들어가고 말았다.",
              Eng: "No sooner had things been turned on their head, than the entire property ended up being owned by the East Colonizaton Company.",
            },
            {
              Kor: "그 소문이 퍼지자 그 처녀 가족은 그 동리에서 못 살고 ",
              Eng: "No sooner had word spread, than the girl's family were no longer able to live in that village, and...",
            },
            {
              Kor: "만약 안방이라도 뒤져값진 물건을 가져간다면 생각이 거기에 미치자 ",
              Eng: "As soon as his thoughts drifted to wondering what would happen if they ransacked his room and took the item...",
            },
            {
              Kor: "군청으로 출근을 하자 금지에서 온 소식이 나를 기다리고 있었다",
              Eng: "As soon as I took off for work at the county office, I found news from Kŭmji waiting for me. ",
            },
            {
              Kor: "그 여자의 노래가 끝나자 나는 의식적으로 바보 같은 웃음을 띠고",
              Eng: "As soon as her song finished I soberly began to laugh foolishly...",
            },
            {
              Kor: "피로가 어느 정도 풀리자 그는 물을 잠그고 몸을 정성들여 닦는다 ",
              Eng: "As soon as his exhaustion had eased somewhat, he turned off the shower and carefully dried off his body. ",
            },
            {
              Kor: "스위치를 내리자, 벽에 도료처럼 붙었던 어둠이 차곡차곡 잠겨서 덤벼들고 ",
              Eng: "As soon as he turned off the switch, the shadows that had been stuck on the wall like paint gradually became immersed in the darkness and attacked him, and...",
            },
            {
              Kor: "인자가 미국으로 떠나 버리자 아버지는 조금 흔들리는 것 같았으나",
              Eng: "It seemed as though Father had weakened slightly as soon as Inja took off for the United States but...",
            },
            {
              Kor: "승지 부임하자 마자 터진 옥사로 구명 운동에 이리 뛰고 저리 달려 온 지난 두 달 동안에  ",
              Eng: "During the two months that he spent running around here and there trying to save his father's name after the revelations of his involvements in a capital offense... ",
            },
          ],
        },
        {
          entryName: "-자겠나",
          typicalExample:
            "【일러 주구 구경만 하자겠나 = 일러 주고 구경만 하자고 하겠나?】",
          categoryKorean: "Abbreviation",
          categoryEnglish: "(Abbreviation)",
          translation:
            "Rhetorical question in -겠나? built on an abbreviated quoted suggestion in -자[고 하]겠나: Is it the case that [somone] would suggest/want to . . . ? (You bet!) Note that quoted suggestions in -자고 like these can come close in meaning to intentions in -(으)려고 한다.)",
          sentences: [
            {
              Kor: "일러 주구 구경만 하자겠나 = 일러 주고 구경만 하자고 하겠나?",
              Eng: "Do you suppose anyone would tell you such an inside tip and then intend to just stand by and watch [without asking for money? Of course not!] ",
            },
          ],
        },
        {
          entryName: "-자고가",
          typicalExample: "【돈 벌자고가 아녜요】",
          categoryKorean: "Abbreviation",
          categoryEnglish: "(Abbreviation)",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "돈 벌자고가 아녜요",
              Eng: "We don't do it in order to make money.",
            },
          ],
        },
        {
          entryName: "-자고 대든다 ",
          typicalExample: "【싸우자고 대들어도 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "-자고 대드-ㄹ-: incite oneself to doing something; firmly intend to do something; work up the gumption to do something; launch oneself into something with the intention of doing it",
          sentences: [
            {
              Kor: "싸우자고 대들어도 ",
              Eng: "even though she might pick a fight ...",
            },
          ],
        },
        {
          entryName: "-자꾸나 한다",
          typicalExample: "【우리는 그것을 알자꾸나 하지 말자 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "Lively variant of -자고 한다 intends to, equivalent to purposive -(으)려(고) 한다. ",
          sentences: [
            {
              Kor: "우리는 그것을 알자꾸나 하지 말자 ",
              Eng: "Let's not make it our business to know that.",
            },
          ],
        },
        {
          entryName: "자네",
          typicalExample: "【자네 참 호살세 호사야 = 호사일세, 호사이야】",
          categoryKorean: "Pronoun",
          categoryEnglish: "(Pronoun)",
          translation:
            "자네 is one of the second person pronouns ('you') appropriate for use with the Familiar ((하네) Style, though Martin classifies it as INTIMATE.",
          sentences: [
            {
              Kor: "자네 참 호살세 호사야 = 호사일세, 호사이야",
              Eng: "You've really gone out in style—in real style. (호사: luxury, extravagance, sumptuousness)",
            },
            { Kor: "자네가 뉘기여? ", Eng: "Who are you?" },
            {
              Kor: "자네 심정을 내가 알겠네. ",
              Eng: "I can understand how you're feeling.",
            },
            {
              Kor: "이번에 자네가 전무가 되는 건 틀림없는 거구",
              Eng: "There is no doubt that you will become the executive director this time...",
            },
            {
              Kor: "자네 한 일주일 동안 시골에 내려가서 긴장을 풀고 푹 쉬었다가 오게 ",
              Eng: "Go down to the country for a week or so and relax—take a break.  ",
            },
            {
              Kor: "자네 금년에 어떻게 되지? ",
              Eng: "How old are you this year? ",
            },
            {
              Kor: "학교측에서 보면 자네 같은 선생은 구하기도 힘들 거야  ",
              Eng: "From the school's perspective, I bet it's hard to find a teacher like you.",
            },
            {
              Kor: "저기 앉아 계시는 하 선생님한테서 자네 얘긴 늘 듣고 있었지.",
              Eng: "I've been hearing news about you from Ms. Han sitting over there.",
            },
            {
              Kor: "자네, 하 선생을 좋아하고 있는 모양이군",
              Eng: "You like Ms. Han, don't you?",
            },
            {
              Kor: "자네가 그 여선생을 좋아한다면 좀 더 적극적으로 나가야 해. ",
              Eng: "If you really like that teacher you need to approach her more directly.",
            },
            {
              Kor: "장례비가 넉넉하니 자네 돈 그 계집애 줄 거 없네. ",
              Eng: "You definitely have enough for funeral fees so there's no need to give that girl any money.",
            },
            {
              Kor: "흥..... 자네 참 호살세 호사야..... 잘 죽었느니. ",
              Eng: "Hmm, this is extravagant my friend—you died well.",
            },
            {
              Kor: "이 아가씨가 자네한테 반했다...... 그 말이야.  ",
              Eng: "I'm telling you—this girl has fallen for you.",
            },
            {
              Kor: "자네가 국일이보다 한 살 많으니까 잘 보살펴주게.",
              Eng: "Please look after Kugil, since you are a year older than him.",
            },
          ],
        },
        {
          entryName: "-자는 ",
          typicalExample: "【함께 마시자는 양씨】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation: "Contracted quotation from -자[고 하]는.",
          sentences: [
            {
              Kor: "함께 마시자는 양씨",
              Eng: "Mr. Yang, who invited me along for a drin",
            },
            {
              Kor: "사람을 살리자는 산의 뜻이라고",
              Eng: "saying it must be the will of the mountains to save the people ...",
            },
          ],
        },
        {
          entryName: "-자는 대로",
          typicalExample:
            "【나 하자는 대로 할까? = 나 하자고 하는 대로 할까?】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "A contracted quoted suggestion in -자는 from -자[고 하]는 followed by the postmodifier 대로 as, just as, just as it is (was); in accordance with.",
          sentences: [
            {
              Kor: "나 하자는 대로 할까? = 나 하자고 하는 대로 할까?",
              Eng: "Shall we do it as/in accordance with what I am?",
            },
          ],
        },
        {
          entryName: "-자니(까)",
          typicalExample:
            "【연방 청백을 뇌던 끝에, 이 양복장을 보자니 얼굴이 간지러웠다 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "From -자[고 하]니까 and equivalent to -(으)려니까 when or just as one attempts to/is about to/is on the verge of ... one then finds/discovers ...",
          sentences: [
            {
              Kor: "연방 청백을 뇌던 끝에, 이 양복장을 보자니 얼굴이 간지러웠다 ",
              Eng: "After continuously reiterating that he was innocent, his face felt ticklish as he opened the wardrobe to take a look inside.",
            },
            {
              Kor: "“아이 참 죽겠네.”　골을 이렇게 내자니까…",
              Eng: 'Just when I was about to get angry and saying "Oh my god", he...',
            },
          ],
        },
        {
          entryName: "-자니까",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Abbreviation",
          categoryEnglish: "(Abbreviation)",
          translation:
            'Abbreviated from -자고 하니까. For insistent, reproachful reiterations. But this abbreviated pattern has a special nuance: I say/insist it is/does or I tell you (emphatically, yet again) it is/does = it really is/does; it is/does, you see (mind you); But I tell you, " ... !"',
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "-자면 ",
          typicalExample: "【독직이나 죄가 되자면 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contracted suggestion quotation from -자[고 하]면 if one suggests ...-ing; if one intends to ...",
          sentences: [
            {
              Kor: "독직이나 죄가 되자면 ",
              Eng: "[in order] for it to be[come] corruption or a crime ...",
            },
            {
              Kor: "그러나 정확하게 말하자면",
              Eng: "to put it more clearly ...",
            },
            {
              Kor: "그가 나서자면 ",
              Eng: 'Had he said, "Let\'s go for it," ...',
            },
          ],
        },
        {
          entryName: "-자부터 ",
          typicalExample: "【선비의 엄한 규율은 농민으로 떨어지자부터 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Ever since the moment when ...; from the moment when ...; right from when ...",
          sentences: [
            {
              Kor: "선비의 엄한 규율은 농민으로 떨어지자부터 ",
              Eng: "Ever since the family fell to the status of farmers, their strict sŏnbi discipline …",
            },
          ],
        },
        {
          entryName: "자신 ",
          typicalExample: "【공부를 하는 것은 윤호 자신이었다. 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation: "NAME 자신: NAME himself/herself",
          sentences: [
            {
              Kor: "공부를 하는 것은 윤호 자신이었다. ",
              Eng: "It was Yunho himself who was doing the studying.",
            },
          ],
        },
        {
          entryName: "-자우",
          typicalExample: "【자, 가자우, 가자우 】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "Dialect/substandard morphology. The form in –자우 is contracted from -자[고 하]우 ← -자[고 하]오 what I'm suggesting is ..., i.e., an insistent request come on ..., let's go, in the gendered \"ajumma-ese\" 하우 Style. ",
          sentences: [
            {
              Kor: "자, 가자우, 가자우 ",
              Eng: "Come on, then, let's go [already]!",
            },
          ],
        },
        {
          entryName: "자체 ",
          typicalExample: "【세 번째 남자는 섹스 자체를 공포스러워했다.  】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation: "NOUN 자체: the NOUN itself ",
          sentences: [
            {
              Kor: "세 번째 남자는 섹스 자체를 공포스러워했다.  ",
              Eng: "The third guy was terrified of sex, full stop.",
            },
          ],
        },
        {
          entryName: "자칫하면",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "Meaning at the slightest slip, with the slightest, this phrase often flags or presages an upcoming almost did it pattern in -ㄹ뻔 했다; provocation.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "자칫했더라면 ",
          typicalExample: "【자칫했더라면 물에 빠질 뻔했다】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "Meaning at the slightest slip, with the slightest, this phrase often flags or presages an upcoming almost did it pattern in -ㄹ뻔 했다; provocation.",
          sentences: [
            {
              Kor: "자칫했더라면 물에 빠질 뻔했다",
              Eng: "almost fell into the water",
            },
          ],
        },
        {
          entryName: "-잖-",
          typicalExample: "【나쁜 장면들은 내버려두면 잊혀지잖아?  】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contraction of -지 않- to -쟎- to -잖-. Usually for Rhetorical Retorts in -잖- come on, isn't it (actually) the case that ... ? ",
          sentences: [
            {
              Kor: "나쁜 장면들은 내버려두면 잊혀지잖아?  ",
              Eng: "If you leave out the bad scenes, they get forgotten, isn't that right? ",
            },
            {
              Kor: "“너무 감격하지 마, 그러면 내가 미안해지잖아.” ",
              Eng: "\"Don't get too emotional—then I get all sorry, don't you know.\"",
            },
            {
              Kor: "놀랐잖니. ",
              Eng: "This really gave me a scare, you know!",
            },
            {
              Kor: "절 데리고 가시잖을 작정이시군요",
              Eng: "Oh, I see you are not planning to take me with you! (Contracted from 저를 데리고 가시지 않을.)",
            },
            {
              Kor: "왜, 선생님이 가시잖구요 ",
              Eng: "And why aren't you going? (Contracted from 가시지 않고요.)",
            },
            { Kor: "나이짝 맞잖게 ", Eng: "in a way unbefitting one's age" },
          ],
        },
        {
          entryName: "-잘지",
          typicalExample: "【집 보러 가잘지 몰라 ← 가자고 할지 몰라(서) 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contracted from -자[고 하]ㄹ지 whether (someone) might suggest to ...",
          sentences: [
            {
              Kor: "집 보러 가잘지 몰라 ← 가자고 할지 몰라(서) ",
              Eng: "Because one never knew if [someone] might ask/suggest to go see a house.",
            },
          ],
        },
        {
          entryName: "-재도",
          typicalExample: "【자동차 길엘 가재도 】",
          categoryKorean: "Abbreviation",
          categoryEnglish: "(Abbreviation)",
          translation:
            "Abbreviation of -자[고 ㅎ]ㅐ도 even though one suggests, 'Let's ... ; even if one wants/wanted to ...; even if one were to ... ",
          sentences: [
            {
              Kor: "자동차 길엘 가재도 ",
              Eng: "Even [if one were to suggest] travelling by car …",
            },
          ],
        },
        {
          entryName: "-쟀더니 ",
          typicalExample: "【남처럼 한번 우리두 살쟀더니 】",
          categoryKorean: "Abbreviation",
          categoryEnglish: "(Abbreviation)",
          translation:
            "Abbreviation of -자[고 ㅎ앴더니: The form in -더니 usually implies a marked or abrupt contrast between the first and second clauses. Thus, the composite meaning here is: Suggested/said \"Let's ...  whereupon. And, by extension, Even though we intended to/tried to/wanted to ... ",
          sentences: [
            {
              Kor: "남처럼 한번 우리두 살쟀더니 ",
              Eng: "All we did was try to live like everybody else … (lit.: All we did was say 'Let us live like everybody else ...')",
            },
          ],
        },
        {
          entryName: "저",
          typicalExample:
            "【내가 언제 저더러 호수까지 데려다 달랬던 것처럼 차장은 당당했다. 】",
          categoryKorean: "Pronoun",
          categoryEnglish: "(Pronoun)",
          translation:
            "As the low/plain form of reflexive 자기. Korean has at least three levels of reflexive pronoun meaning one's self: low 저, polite 자기 (자기 자신, 자기 자체), honorific 당신.",
          sentences: [
            {
              Kor: "내가 언제 저더러 호수까지 데려다 달랬던 것처럼 차장은 당당했다. ",
              Eng: "The conductor of the bus was imposing, as if I had asked her to take me to the lake.",
            },
            {
              Kor: "저도 역시 입을 맞추고",
              Eng: "She too, as expected, kissed it and ...",
            },
            {
              Kor: "K는 저 혼자 낙담을 했는지",
              Eng: "perhaps because he was all disappointed ...",
            },
            {
              Kor: "암만 즈 아버지가 반대를 하더라도 ",
              Eng: "even if her father should oppose ... (즈 for 저의 = 자기의)",
            },
          ],
        },
        {
          entryName: "저희 ",
          typicalExample: "【은희가 저희 차를 향해 걸어갈 때 윤호는 말했다.】",
          categoryKorean: "Pronoun",
          categoryEnglish: "(Pronoun)",
          translation:
            "저희 as the lowest equivalent of retroflexive self, where the options are (in order of speech level, from highest to lowest): 당신, 자기 and 저 in the singular, and 당신네, 자기네 and 저희 in the plural. ",
          sentences: [
            {
              Kor: "은희가 저희 차를 향해 걸어갈 때 윤호는 말했다.",
              Eng: "Yunho spoke as Ŭnhŭi walked toward her car.",
            },
            {
              Kor: '"시골에서 저희 어머니가 올라왔대요."',
              Eng: '"She says her mother came up from the countryside."',
            },
          ],
        },
        {
          entryName: "정",
          typicalExample: "【정 약을 안 사다먹겠다면】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 정: (if) ... really/truly, which usually sets up a conditional in -다면.",
          sentences: [
            {
              Kor: "정 약을 안 사다먹겠다면",
              Eng: "if [Kilsu] really didn't intend to buy any medicine ...",
            },
          ],
        },
        {
          entryName: "정녕",
          typicalExample: "【정녕 그것인 성싶었다】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 정녕 meaning 틀림없이 꼭, .i.e., without fail, (will) definitely ",
          sentences: [
            {
              Kor: "정녕 그것인 성싶었다",
              Eng: "It seemed for certain that it was the case. ",
            },
          ],
        },
        {
          entryName: "정도(는) ",
          typicalExample:
            "【진통이 오면 이런 이물감 정도는 전혀 느끼지도 못할 것이었다.  】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "정도(는) degree, grade, extent, limit, measure, standard, functioning almost like postnoun 따위 the (piddlin', measly, insignificant) likes of. ",
          sentences: [
            {
              Kor: "진통이 오면 이런 이물감 정도는 전혀 느끼지도 못할 것이었다.  ",
              Eng: "Once the labor pains came, she wouldn't even be able to sense the [piddling] extent of the feeling of an alien object like this. ",
            },
            {
              Kor: "1주일에 서너 번 정도다. ",
              Eng: "It's around three or four times a week.",
            },
          ],
        },
        {
          entryName: "정반대로",
          typicalExample: "【이와 정반대로 서분이의 음성은 높고 가시 같다.】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "NOUN와/과 정반대로: (정 exactly/diametrically) opposite to/in contrast to NOUN.",
          sentences: [
            {
              Kor: "이와 정반대로 서분이의 음성은 높고 가시 같다.",
              Eng: "Sŏbun's voice was high and sharp, which was completely opposite to the other man's.  ",
            },
          ],
        },
        {
          entryName: "제",
          typicalExample: "【제 아내를 잃은 듯 섭섭해 했다】",
          categoryKorean: "Possessive",
          categoryEnglish: "(Possessive)",
          translation:
            "Reflexive possessive (one's own, self's own) 제 = 저의. Modern Korean distinguishes at least three levels of reflexive pronoun: Low/Deprecatory 저 (possessive 제 or 저의), Plain 자기, and Honorific 당신. In colloquial Korean, reflexive possessive 제 often comes out as 지.",
          sentences: [
            {
              Kor: "제 아내를 잃은 듯 섭섭해 했다",
              Eng: "He looked as sad as though it was his own wife he had lost.",
            },
            {
              Kor: "제 주둥이로 물어 내버리는 수가 있다",
              Eng: "There are occasions when they pick up their young in their beaks and throw them out.",
            },
            {
              Kor: "제 따위들이 뭐라구",
              Eng: "Who do you guys think you are ...",
            },
            {
              Kor: "중실은 제 몸이 스스로 별이 됨을 느꼈다",
              Eng: "Chungsil felt his body turning into a star.",
            },
          ],
        },
        {
          entryName: "제발 ...-었으면 ",
          typicalExample: "【나도 제발 그런 거리가 하나 걸렸으면 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 제발 is used to presage a fervent wish, desire or request. If only I could; if only ... ",
          sentences: [
            {
              Kor: "나도 제발 그런 거리가 하나 걸렸으면 ",
              Eng: "if only I too could do that ...",
            },
            {
              Kor: "할아버진 아무것도 모르니 제발 좀 가만히 계시라고 했다.  ",
              Eng: "I told my grandfather that he should just butt out when he didn't know anything.",
            },
            {
              Kor: "떠날 테면 떠나. 제발 그래 줘. ",
              Eng: "By all means, leave if you want to. Please just leave.",
            },
            {
              Kor: "그의 혀가 내 다리 구석구석을 핥고 있다. 오, 제발.  ",
              Eng: "His tongue is scratching me all over my legs.  Oh please…",
            },
            {
              Kor: "“아, 제발.” 도마뱀은 천천히 항문을 통해 빠져나온다.  ",
              Eng: "Oh goodness. The viper was slowly making its way out of the anus.",
            },
            {
              Kor: "맹순사는, 나도 제발 그런 거리가 하나 걸렸으면......  ",
              Eng: '"If only I could get my hands on a big profit like that..." thought Constable Maeng.',
            },
            {
              Kor: "그 더러운 총을 제발 치워.  ",
              Eng: "Please get rid of that filthy gun.",
            },
          ],
        },
        {
          entryName: "제아무리 ...-어도 ",
          typicalExample:
            "【산이 제 아무리 높고 깊으며 산령(山靈)이 영험스러워도 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 제아무리 flags an upcoming concessive form, which here is just -어도 no matter how much one might VERB  ...; VERB as one might. The 제 is optional.",
          sentences: [
            {
              Kor: "산이 제 아무리 높고 깊으며 산령(山靈)이 영험스러워도 ",
              Eng: "No matter how high or deep the mountain, how miraculous the mountain spirit, ...",
            },
          ],
        },
        {
          entryName: "제외하고(는)",
          typicalExample: "【남자들 몇 사람을 제외하고는 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation: "NOUN(을) 제외하고(는): only with the exception of NOUN",
          sentences: [
            {
              Kor: "남자들 몇 사람을 제외하고는 ",
              Eng: "With the exception of a few of the men ...",
            },
            {
              Kor: "그 놀음판에서 나의 뜨거워져 가는 머리와 떨리는 손가락만을 제외하곤  ",
              Eng: "At that gambling table, aside from my burning face and shaking fingers...",
            },
            {
              Kor: "앉은뱅이책상이 놓인 밑변을 제외한 다른 세 변에 각각 20개씩의 깡통을자형으로 배열했다.  ",
              Eng: "I arranged the cans so that there were twenty in a horseshoe against each wall, except for the wall with the crippled table leaning on it.  ",
            },
          ],
        },
        {
          entryName: "조차",
          typicalExample:
            "【그이는 이제 내 얼굴을 똑바로 쳐다보는 일조차 별로 없다.  】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "Particle 조차: (not) even; too, to boot, in addition, into the bargain. Typically followed by a negative verb.",
          sentences: [
            {
              Kor: "그이는 이제 내 얼굴을 똑바로 쳐다보는 일조차 별로 없다.  ",
              Eng: "Now he hardly ever even looks me straight in the eye.",
            },
            {
              Kor: "결혼 이후에는 그녀가 책을 들추는 것조차 본 적이 없는데.......  ",
              Eng: "After getting married, I've never seen her so much as crack open a book, but …",
            },
            {
              Kor: "단식이나 침묵 시위 같은 것은 애초에 성립될 수조차 없는 일인 것이다.  ",
              Eng: "The fact of the matter is that refusing to eat her food or giving her the treatment just aren't options from the get-go.",
            },
            {
              Kor: "시체가 부패하는 냄새조차 나지 않았을 정도였지.  ",
              Eng: "It was to the extent that you couldn't smell the corpse rotting.",
            },
            {
              Kor: "아버지가 그 여자와 이야기를 나누는 것조차 본 적이 없는데. ",
              Eng: "I've never once even seen father talking with her.",
            },
            {
              Kor: "옆자리에 있는 것조차 잊어버렸던 남자가 말했다.",
              Eng: " … said the man beside her, about whom she had forgotten completely.",
            },
            {
              Kor: "자기 자신조차 잊어버리면서",
              Eng: "while losing even their own selves ...",
            },
            {
              Kor: "쉰 김치 조각조차 없는 깡술판 ",
              Eng: "a drinking table without even a piece of sour kimch'i ",
            },
            {
              Kor: "그는 가짜 호랑이 소동조차 알고 있질 못했다.",
              Eng: "He hadn't even known about the uproar concerning the fake tiger.",
            },
            {
              Kor: "운흥산의 산세를 알고 있는 친구조차 그 산에 호랑이가 있다는 내 말은 곧이듣지 않았다. ",
              Eng: "Even those friends who knew what Unhŭng Mountain looked like did not take him seriously when he said there really was a tiger up there.",
            },
            {
              Kor: "산중의 호랑이조차 벌써 먼 산으로 자리를  비켰을지도 모른다는 불안한 예감을 키웠다 ",
              Eng: "I began to dwell on the thought that maybe even the tiger in the heart of the mountain had already left for another distant mountain.",
            },
            {
              Kor: "쌍안경조차 필요하지 않았다",
              Eng: "We didn't even need binoculars.",
            },
            {
              Kor: "미안감조차 일지를 않았다",
              Eng: "I didn't even feel apologetic.",
            },
            {
              Kor: "꿈조차 메말라간다고 여자는 생각했다",
              Eng: "The woman thought that even her dreams were drying up.",
            },
            {
              Kor: "생각해 보는 일조차 없었다 ",
              Eng: "She never even gave it a thought.",
            },
            {
              Kor: "그것조차 분간할 수 없는 기쁨",
              Eng: "the joy whereby they could not distinguish even those [two things]",
            },
            {
              Kor: "음식맛이 단지 짠지조차 구별이 안 갔다 ",
              Eng: "… couldn't even distinguish the mere taste of [salted radish].",
            },
            {
              Kor: "그런 감회를 일으킴조차 요즘은 적어지고 말았다",
              Eng: "Even the arousing of such deep emotions has diminished these days.  ",
            },
            {
              Kor: "대꾸할 필요조차 없는, 여자들이 으레 하는 시큰둥한 시비였다. ",
              Eng: "They were just the usual saucy remarks made by women—there was no need whatsoever to reply.",
            },
            {
              Kor: "꿈에서 봤던가, 현실에서 봤던가 그것조차 아리숭한 채 ",
              Eng: "Even while it was still unclear to me whether I had seen it in my dreams or in real life I...",
            },
          ],
        },
        {
          entryName: "조차도",
          typicalExample: "【나조차도 자세히 본 일이 없는 저 성기】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "조차도: particle 조차 even, too, to boot, in addition, into the bargain intensified by particle 도 even, too.",
          sentences: [
            {
              Kor: "나조차도 자세히 본 일이 없는 저 성기",
              Eng: "those genitalia, which even I have never had a close look at",
            },
            {
              Kor: "호랑이에 대해선 관심조차도 가지질 않았다 ",
              Eng: "They couldn't even muster up interest in the tiger.",
            },
            {
              Kor: "꼰대 메기조차도 이 강마담 만큼은 어쩌지 못하는 모양이었다.",
              Eng: "It seemed that even Catfish was not able to stand up to Madam Kang.",
            },
          ],
        },
        {
          entryName: "좀처럼 ",
          typicalExample: "【아이는 좀처럼 울지 않았다.】",
          categoryKorean: "Compound Particle",
          categoryEnglish: "(Compound Particle)",
          translation:
            "(Usually with negatives) just isn't/wont do/be; simply ... When coupled with a long negative in -지 않- the combined effect is something like just will not, just does not (implying frustration with one's lack of control over a situation). In such cases, the long negative pattern often has pre-emphasis with either 를 or 가 (-지를 않-/-지가 않-). Note also that this adverb frequently sets up a following pattern in -어 지지 않는다 implying that the subject/agent is either (sometimes adversely) affected by the action, and/or has no control over the action of the verb. ",
          sentences: [
            {
              Kor: "아이는 좀처럼 울지 않았다.",
              Eng: "The child never cried; or, The child just wouldn't cry.",
            },
            {
              Kor: "율사의 권총은 좀처럼 나오지 않았다.",
              Eng: "The lawyer's pistol just wouldn't reveal itself.",
            },
            {
              Kor: "좀처럼 남에게 질 생각이 없으나 ",
              Eng: "I never have the slightest desire to lose to anyone else...",
            },
            {
              Kor: "좀처럼 맞서서 언성을 높여 탄하고 싸우고 하는 법이 없었다",
              Eng: "… hardly ever fought with her (or the like).",
            },
            {
              Kor: "꽃송이가 달린 줄기를 잡고 끊기 시작한다. 좀처럼 끊어지지  않는다.  ",
              Eng: "She started to grab flower stems with buds still on them and snap them. They hardly ever actually broke off. ",
            },
            {
              Kor: "인가가 좀처럼 보이지 않는 황량한 들판이었다. ",
              Eng: "It was a desolate field where the house was never visible.  ",
            },
            {
              Kor: "방에 데리고 들어갔지만 좀처럼 자려고 하지 않는 민영이",
              Eng: "I brought him to the room but Minyŏng never wanted to sleep...",
            },
            {
              Kor: "남의 음식이니까 맛이 있지　 하는 생각이 좀처럼 그를 비관하게는 하지 않았다  ",
              Eng: "The thought that food from other people tasted better never discouraged him.   ",
            },
          ],
        },
        {
          entryName: "좀체(로) ",
          typicalExample: "【답은 좀체 얻어지지 않았다】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "A synonym for 좀처럼, this adverb means just isn't/wont do/be; simply ... When coupled with a long negative in -지 않- the combined effect is something like just will not, just does not (implying frustration with one's lack of control over a situation). In such cases, the long negative pattern often has pre-emphasis with either 를 or 가 (-지를 않-/-지가 않-). Note also that this adverb frequently sets up a following pattern in -어지지 않는다 implying that the subject/agent is either (sometimes adversely) affected by the action, and/or has no control over the action of the verb. ",
          sentences: [
            {
              Kor: "답은 좀체 얻어지지 않았다",
              Eng: "For the life of me I couldn't come up with an answer.",
            },
            {
              Kor: "좀체로 지워지지 않는 인주와 잉크",
              Eng: "the stamp pad and ink which just would not (for the life of me) come off",
            },
          ],
        },
        {
          entryName: "죽어라[고]!",
          typicalExample: "【죽어라고 닦아논 차】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Die! grammaticalized as an emphatic adverb: with all one's might. Use of Plain Style imperative as an emphatic adverb.",
          sentences: [
            {
              Kor: "죽어라고 닦아논 차",
              Eng: 'the car which I cleaned with all my might ...(lit.: saying, “DIE!") ',
            },
          ],
        },
        {
          entryName: "죽자구나",
          typicalExample: "【죽자구나 도리머리를 어지럽게 흔든단다】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Let's die! grammaticalized as an emphatic adverb: with all one's might. Use of Plain Style imperative as an emphatic adverb.",
          sentences: [
            {
              Kor: "죽자구나 도리머리를 어지럽게 흔든단다",
              Eng: "She would shake her head wildly. ",
            },
          ],
        },
        {
          entryName: "줄 = 지",
          typicalExample: "【우리 마누라가 뭐라는 줄 알아? 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "줄 = 지. I.e., 뭐라는 줄 알아? ← 무엇이라고 하는 줄 아나? with colloquial 줄 functioning like oblique question 지.",
          sentences: [
            {
              Kor: "우리 마누라가 뭐라는 줄 알아? ",
              Eng: "Do you know what my wife says?",
            },
          ],
        },
        {
          entryName: "중",
          typicalExample: "【B부인의 계획중인 장래 양로원 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "Postnoun 중: in the middle of NOUN; in the midst of NOUN ",
          sentences: [
            {
              Kor: "B부인의 계획중인 장래 양로원 ",
              Eng: "Mrs. B's future old folks home which was in the middle of being planned",
            },
            {
              Kor: "우리 눈에는 빙산이라고 해야 할 얼음 덩어리가 녹고 있는 중이었다  ",
              Eng: "A large chunk of ice, that in our eyes would be called an iceberg, was melting.",
            },
            {
              Kor: "청년들 중에 누구의 어깨 너머로 술이나 안주를 가운데 상에  넘겨 놓는가가 문제였다  ",
              Eng: "The question was who among the men would have the food tray or drinks passed over their shoulders and put on the main table.",
            },
            {
              Kor: "참새는...그중 약한 제 새끼를 골라 제 주둥이로 물어 내버리는 수가 있다 ",
              Eng: "Sparrows would sometimes pick the weakest of their young and get rid of them. ",
            },
            {
              Kor: "밤중에 복녀의 시체는 왕서방의 집에서 남편의 집으로 옮겨졌다.",
              Eng: "During the night, Pongnyŏ's dead body was taken from Mr. Wang's room to her husband's house.",
            },
            {
              Kor: "그가 홀어머니를 모시고 악으로 악으로 모진 목숨을 이어가는 중  ",
              Eng: "While he was desperately living out his miserable life caring for his widowed mother...",
            },
            {
              Kor: "아주머니는 얼른 줄행랑처럼 붙은 손님방 중 한 방으로 먼저 들어가 ",
              Eng: "The lady first hurried into one of the guest rooms that looked like outhouses and...",
            },
            {
              Kor: "패잔병 중 한 사람의 눈에 살기가 번뜩이는가 하는 순간 총이 그녀의 남편을 향해 난사됐다",
              Eng: "The moment that there were hints of a survivor among the remnants of the dead army, random gunfire were directed at her husband.",
            },
            {
              Kor: "무진 중학 출신 중에서 형님이 제일 출세하셨다고들 하고 있어요 ",
              Eng: "They're all saying that among the graduates from Mujin Middle School you have become the most successful.",
            },
            {
              Kor: "예, 형님하고 형님 동기(同期) 중에서 조형(趙兄)하고요.  ",
              Eng: "Yes, both you and Jo from your class [were said to be the most successful].",
            },
            {
              Kor: "그 중 한 남자가 천천히 그것을 안아 올렸다. ",
              Eng: "One man from among them slowly lifted the body up.",
            },
            {
              Kor: "4천원 중 나머지 2천원은  방값이기 때문이다.   ",
              Eng: "It was because two thousand of the four thousand wŏn were for the room rental fee.",
            },
          ],
        },
        {
          entryName: "중에도",
          typicalExample: "【걸인 중에도 상거지다】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation: "NOUN중에도: even amidst; even between, among, out of ",
          sentences: [
            {
              Kor: "걸인 중에도 상거지다",
              Eng: "Even among beggars he was the most wretched. ",
            },
            {
              Kor: "그런 중에도 K를 만난 것은 행운에 속하는 일이었다.",
              Eng: "Even amidst all that happened, meeting K could be appertained to luck.",
            },
            {
              Kor: "그는 얼굴 두어 군데를 베었고 그 중의 하나는 너무 크게 베어져 ",
              Eng: "He cut his face in two places and one of the cuts were so deep that...",
            },
            {
              Kor: "아내는 늘 껌을 씹고 있었는데, 그것은 아내의 버릇 중의 하나였다  ",
              Eng: "His wife had always been chewing gum—it had been one of her habits.",
            },
            {
              Kor: "키 위의 책들을 마구 뽑았다. 그 중의 한 권에서 권총이 나왔다.",
              Eng: "He randomly took out books above him off the shelf and a gun revealed itself in one of the books.",
            },
            {
              Kor: "박희완 영감이란 세 영감 중의 하나로 안초시처럼 이 복덕방에 와  ",
              Eng: "Mr. Park, one of the new fellows, came to the Broker's office like An and...",
            },
            {
              Kor: "눈에 거슬리는 것은 그 두 곳 손님 중의 상당수를 차지하는 학생들이었다  ",
              Eng: "Among the guests at both places the ones that he hated the most were the students.",
            },
            {
              Kor: "“우리 돈내기 한판 하실까요?” 세무서 직원 중의 하나가 내게 말했다.  ",
              Eng: '"Shall we play a round with bets?"  One of the tax office employees asked me.',
            },
          ],
        },
        {
          entryName: "NOUN중의 NOUN이지만",
          typicalExample: "【천민중의 천민이지만】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "NOUN중의 NOUN이지만: among NOUNS is indeed NOUN, but ...",
          sentences: [
            {
              Kor: "천민중의 천민이지만",
              Eng: "… is indeed a low-life among low-lives, but ...",
            },
          ],
        },
        {
          entryName: "즈이 ",
          typicalExample:
            "【그 때 즈이 아버지가 그 지경 당하는 걸 내 등에 업혀서 보던 녀석이  】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation: "Dialect/substandard for 저희. ",
          sentences: [
            {
              Kor: "그 때 즈이 아버지가 그 지경 당하는 걸 내 등에 업혀서 보던 녀석이  ",
              Eng: "The little boy on my back who had inadvertently watched his father suffer that much [is now]…",
            },
          ],
        },
        {
          entryName: "-지",
          typicalExample: "【우리는 서로 받기만을 바라는 거지 부부인가 】",
          categoryKorean: "연결 어미",
          categoryEnglish: "(연결 어미)",
          translation:
            "-지 followed by suspensive, emphatic pause: it is most emphatically the case that[and not something else]. Followed by either a rebuke or rhetorical question or else rounded off with a form of 그러-.",
          sentences: [
            {
              Kor: "우리는 서로 받기만을 바라는 거지 부부인가 ",
              Eng: "We are merely hoping each of us to get something from the other—we're not a couple (lit.: Are we a couple?—which is, of course, a rhetorical question)",
            },
            {
              Kor: "쳇, 영화표를 주지 음악회표를 줘요?",
              Eng: "Damn, why didn't she give me movie passes instead of concert tickets?",
            },
            { Kor: "가보지 그래요", Eng: "Why don't you just go? " },
            {
              Kor: "천생 가쾌지 별거냐 = 가쾌(이)지 별 것이냐? ",
              Eng: "Your lot in life is to be a [mere] real estate agent—are you anything special? (No, of course not.)",
            },
            {
              Kor: "정말이지 손님이 안 드셨으면 어쩔 뻔했을까 모르겠어요. ",
              Eng: "I'm telling you, I don't know what I would have done if you hadn't come and eaten the food. ",
            },
            {
              Kor: "혈기에서 나오는 것이기 때문이지 몸에서 혈기가 줆을 따라 그런 감회를 일으킴조차 요즘은 적어지고 말았다 ",
              Eng: "",
            },
            {
              Kor: "양 씨 어른이야  애초부터  농사꾼이였지 언제부터 짐승 좇는  포수고  몰이꾼이었나요. ",
              Eng: "From the very outset Yang was a farmer and never a beast hunter.  ",
            },
            {
              Kor: "여름 장마 때나 돼야 물이 콸콸 흐르다가 마는 이름만 개울이지 ",
              Eng: "It's a stream by name only, with water rushing through it only during the summer monsoons...",
            },
          ],
        },
        {
          entryName: "지가 ",
          typicalExample: "【지가 똥갈보라】",
          categoryKorean: "Pronoun/Colloquial",
          categoryEnglish: "(Pronoun/Colloquial)",
          translation:
            "The colloquial version of 제가, the lowest/Plain Style of the reflexive pronouns 저, 자기, and 당신. ",
          sentences: [{ Kor: "지가 똥갈보라", Eng: "She's a prostitute." }],
        },
        {
          entryName: "-지가 않-",
          typicalExample: "【도저히 익숙해질 것 같지가 않았다 】",
          categoryKorean: "Pronoun/Colloquial",
          categoryEnglish: "(Pronoun/Colloquial)",
          translation:
            "Negative pre-emphasis in -지가 않-. Shows dissatisfaction with or displeasure at the negated state or act.",
          sentences: [
            {
              Kor: "도저히 익숙해질 것 같지가 않았다 ",
              Eng: "It seemed like I would never get used to [that kind of ambience].",
            },
            {
              Kor: "막상 되지가 않았다 ",
              Eng: "[When it got right down to it, he] just couldn't do it.",
            },
            {
              Kor: "조금도 덜해지지가 않았다 ",
              Eng: "[The feeling] didn't lessen one bit.",
            },
            {
              Kor: "마음이 턱 놓이지가 않아 ",
              Eng: "He was not completely at ease ...",
            },
            {
              Kor: "일이 손에 잡히지가 않아 ",
              Eng: "I just couldn't get down to work.",
            },
          ],
        },
        {
          entryName: "-지도 못하다",
          typicalExample:
            "【진통이 오면 이런 이물감 정도는 전혀 느끼지도 못할 것이었다.  】",
          categoryKorean: "Pronoun/Colloquial",
          categoryEnglish: "(Pronoun/Colloquial)",
          translation: "-지도 못하다: can't even ...",
          sentences: [
            {
              Kor: "진통이 오면 이런 이물감 정도는 전혀 느끼지도 못할 것이었다.  ",
              Eng: "Once the labor pains came, she wouldn't even be able to sense the [piddling] extent of the feeling of an alien object like this. ",
            },
          ],
        },
        {
          entryName: "-지도 않는다",
          typicalExample:
            "【엄마나 아빠는 나를 소망하지도 않았던 것 같고 믿지도 않았으며】",
          categoryKorean: "Pronoun/Colloquial",
          categoryEnglish: "(Pronoun/Colloquial)",
          translation:
            "Doesn't even … In other words, the long-form negation pattern in -지 않는다 for processive verbs reinforced with particle 도.",
          sentences: [
            {
              Kor: "엄마나 아빠는 나를 소망하지도 않았던 것 같고 믿지도 않았으며",
              Eng: "It seemed mom and dad had not wanted and did not trust me …",
            },
            {
              Kor: "그렇다고 사랑하는 것 같지도 않았다.  ",
              Eng: "Having said that, it didn't even seem they loved me.",
            },
            { Kor: "녹지도 않고 ", Eng: "It hasn't even melted, and ..." },
            {
              Kor: "남자는 거들떠보지도 않고 ",
              Eng: "The man didn't even bother to lift his eyes, and ...",
            },
            {
              Kor: "그는 돌아보지도 않고 ",
              Eng: "He didn't even turn around and [just waved.]",
            },
            {
              Kor: "얼마 남지도 않았을 그의 넋",
              Eng: "the little bit of her spirit that was left",
            },
            {
              Kor: "사람처럼 보이지도 않는다",
              Eng: "[He] doesn't even look like a person.",
            },
            {
              Kor: "꿀이 다 떨어지지도 않은 그저께 밤에는 ",
              Eng: "The night of the day before yesterday, when the honey hadn't even run out ...",
            },
            {
              Kor: "지치지도 않고 떠들어댔다",
              Eng: "[Imagining the shock] we didn't even get tired and pressed on noisily.",
            },
          ],
        },
        {
          entryName: "-지도 않다",
          typicalExample: '【"그이상의 협조는 바라지도 않아." 】',
          categoryKorean: "Pronoun/Colloquial",
          categoryEnglish: "(Pronoun/Colloquial)",
          translation:
            "Isn't even ... In other words, the long-form negation pattern in -지 않다 for descriptive verbs reinforced with particle 도.",
          sentences: [
            {
              Kor: '"그이상의 협조는 바라지도 않아." ',
              Eng: '"Any cooperation above and beyond that I don\'t even expect."',
            },
            {
              Kor: '"그만둬. 새 따위는 나오지도 않는다구." ',
              Eng: '"Drop it already. There ain\'t no birds or the like coming!"',
            },
            {
              Kor: "아무렇지도 않은 듯",
              Eng: "as if nothing had happened; or, as if nothing were the matter ",
            },
          ],
        },
        {
          entryName: "-지를 못하-",
          typicalExample:
            "【점심때가 가까워 오는데 겨우 열아홉 켤레 밖에 낚지를 못했다.  】",
          categoryKorean: "Pronoun/Colloquial",
          categoryEnglish: "(Pronoun/Colloquial)",
          translation:
            "Negative pre-emphasis in -지를 못-. The addition of 를 to -지 is thought to add emphasis, insistence or a hint of frustration to the long negation patterns in -지 않는다 and -지 못하-.",
          sentences: [
            {
              Kor: "점심때가 가까워 오는데 겨우 열아홉 켤레 밖에 낚지를 못했다.  ",
              Eng: "It was almost lunch time but he had only managed to shine nineteen pairs of shoes.",
            },
          ],
        },
        {
          entryName: "-지를 않-",
          typicalExample: "【천신되어지지를 아니하였다 】",
          categoryKorean: "Pronoun/Colloquial",
          categoryEnglish: "(Pronoun/Colloquial)",
          translation:
            "Negative pre-emphasis in -지를 않- for processive verbs. Shows dissatisfaction with or displeasure at the negated state or act.",
          sentences: [
            {
              Kor: "천신되어지지를 아니하였다 ",
              Eng: "… just could not for the life of him …",
            },
            {
              Kor: "성 같은 것이 나지를 아니하였다 ",
              Eng: "There was nothing of note.",
            },
            {
              Kor: "부자가 되지를 아니하였으니 ",
              Eng: "since he hadn't become rich ...",
            },
            {
              Kor: "그렇게 불어나지를 않았다 ",
              Eng: "[The money] didn't grow as much [as was calculated.] ",
            },
            {
              Kor: "잘 모이지를 않았다 ",
              Eng: "[The villagers] wouldn't gather [willingly].",
            },
            {
              Kor: "아들이 하숙집에 들어오지를 않는다는군요 ",
              Eng: "The son hasn't come home to the boarding house [for more than a week.]",
            },
            {
              Kor: "미안감조차 일지를 않았다 ",
              Eng: "I didn't even feel apologetic.",
            },
          ],
        },
        {
          entryName: "-지 마러",
          typicalExample: "【하나두 버리지 마러 】",
          categoryKorean: "Colloquial ",
          categoryEnglish: "(Colloquial )",
          translation:
            "Colloquial -지 마러 for standard -지 마라 = -지 말아 ← -지 말다.",
          sentences: [
            { Kor: "하나두 버리지 마러 ", Eng: "Don't throw away even one!" },
          ],
        },
        {
          entryName: "-지만 않-",
          typicalExample:
            "【검지만 않아도 되는 유리 구슬 눈알, 검지만 않아도 되는 머리털에서 그들이 선남선녀로 느껴지는 순간이었다  】",
          categoryKorean: "Colloquial ",
          categoryEnglish: "(Colloquial )",
          translation:
            "Use of particle 만 to emphasize the negation pattern in -지 않- just not (is/does).",
          sentences: [
            {
              Kor: "검지만 않아도 되는 유리 구슬 눈알, 검지만 않아도 되는 머리털에서 그들이 선남선녀로 느껴지는 순간이었다  ",
              Eng: "It was then that she felt that they were the good men and women who had glass eyes that didn't have to be black and hair that didn't have to be black.",
            },
            {
              Kor: '“팔로 시험지를 가리지만 않으면 돼."',
              Eng: '"All you have to do is not cover your exam paper with your arm."',
            },
          ],
        },
        {
          entryName: "-지만 않으면",
          typicalExample: '【“팔로 시험지를 가리지만 않으면 돼."】',
          categoryKorean: "Colloquial ",
          categoryEnglish: "(Colloquial )",
          translation:
            "-지만 않으면 as long as one doesn't ... In other words, a long-form negative in -지 않- reinforced by delimiter 만. ",
          sentences: [
            {
              Kor: '“팔로 시험지를 가리지만 않으면 돼."',
              Eng: '"All you have to do is not cover your exam paper with your arm."',
            },
          ],
        },
        {
          entryName: "-지만서두 ",
          typicalExample: "【모두들 발랑까졌다구 하지만서두 】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "For -지만서도 even though, despite the fact that. Appears to be a dialect/sub-standard form.",
          sentences: [
            {
              Kor: "모두들 발랑까졌다구 하지만서두 ",
              Eng: "Even though they all said that she was promiscuous ...",
            },
          ],
        },
        {
          entryName: "-지 말고 VERB2 ",
          typicalExample: '【"자, 망설이지 말고 쏴." 】',
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "VERB1-지 말고 VERB2: don't  do VERB1—do VERB2! Or Instead of doing VERB1, VERB2!",
          sentences: [
            {
              Kor: '"자, 망설이지 말고 쏴." ',
              Eng: '"OK, then—shoot without hesitating."',
            },
          ],
        },
        {
          entryName: "-지 못하다",
          typicalExample: "【그런데도 그는 자유롭지 못한 사람이었다.  】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "-지 못하다 with descriptive bases can imply that the speaker is not entirely satisfied or pleased with the negative state of affairs.   ",
          sentences: [
            {
              Kor: "그런데도 그는 자유롭지 못한 사람이었다.  ",
              Eng: "But alas, he had not been a free man.",
            },
            {
              Kor: "지섭은 누나가 좋아할 어떤 한 면도 갖고 있지 못했다.  ",
              Eng: "There was absolutely no aspect of Chisŏp that his big sister could have liked.",
            },
            {
              Kor: "내가 가정적이지 못한 것이 불만이긴 하겠지만",
              Eng: "That I wasn't much of a family man was a source of dissatisfaction, true enough, but … (Normally speaking, it is difficult to find examples of the long negation pattern in -지 못하다 on the copula -이다, but those cases that do appear tend to be with -적(的), as in this example. As with other examples of descriptive verbs in the -지 못하다 pattern, this example is tinged with a sense of let-down or disappointment on the part of the speaker.)",
            },
          ],
        },
        {
          entryName: "-지, 뭐(+ optional copula form)",
          typicalExample: "【괜히 온갖 방정맞은 생각이 다 나지 뭡니까 】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "The pattern in -지, 뭐 -지, 뭐야 -지, 뭐예요, etc. means I'll be damned if it isn't the case that ... The -지 is often followed by the suspensive (\"I've still got the floor\") intonation, and this pattern means something like: Of course VERB is the case—what other analysis is there?; But, but anyway, somehow or other, just; of course, wouldn't you know?",
          sentences: [
            {
              Kor: "괜히 온갖 방정맞은 생각이 다 나지 뭡니까 ",
              Eng: "But perhaps foolishly, every possible ominous thought is coming to my mind. ",
            },
            {
              Kor: "송장이나 다름없지 뭔가?",
              Eng: "In that case, you're no different from a corpse—isn't that so?",
            },
            {
              Kor: "화중지병이지 뭘 허나 = 뭘 하나 ",
              Eng: "It's a pie in the sky—what can you do about it? (Nothing!)",
            },
            {
              Kor: "온통 난장판이지 뭡니까 ",
              Eng: "It's all a huge mess, wouldn't you know?",
            },
            {
              Kor: "내 장사 수완 덕이지 뭐 ",
              Eng: "My business is thanks to my resourcefulness, you know.",
            },
            {
              Kor: "뒷방에서 늦잠자는 줄 알았지 뭔가 ",
              Eng: "I just thought she was sleeping late in the back room (—what else could I have thought?)",
            },
            {
              Kor: "색시가 도망을 쳤지 뭐에요 ",
              Eng: "The girl took off, wouldn't you know? or, You know what? The bitch took off. ",
            },
          ],
        },
        {
          entryName: "-지, 뭘 ",
          typicalExample: "【화중지병이지 뭘 허나 = 뭘 하나 】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "Must have been or must be the case that..., or obviously/presumably it is the case that..., don't you think?; eh?; duh!",
          sentences: [
            {
              Kor: "화중지병이지 뭘 허나 = 뭘 하나 ",
              Eng: "It's a pie in the sky—what can you do about it? (Nothing!)",
            },
            {
              Kor: "학나무하구 이장댁에 불을 놓았지 뭘 ",
              Eng: "He must have set fire to the crane tree and Village Head Yi's house, don't you think?",
            },
          ],
        },
        {
          entryName: "-지 않고 VERB2",
          typicalExample: "【은희는 웃지 않고 말했다. 】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "VERB1-지 않고 VERB2: do VERB2 instead of VERB1; do VERB2 without VERB1-ing",
          sentences: [
            {
              Kor: "은희는 웃지 않고 말했다. ",
              Eng: "Ŭnhŭi spoke without smiling. ",
            },
          ],
        },
        {
          entryName: "-지 않고 있다",
          typicalExample: "【여자의 가게에 모습을 나타내지 않고 있었다 】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "Still in a state of not doing; is getting along without ...-ing. A phrase consisting of a -고 form and a form of 있- is, stays, corresponds to English verb phrases like is writing, is eating, is buying, as opposed to simple forms like writes, eats, buys. The negative can be made either on the underlying verb or on the expression as a whole, with slightly different meanings. An expression like 쓰고 있지 않아요 is not writing denies the assertion as a whole, but to imply that the subject goes along nicely without doing the activity, Korean uses 쓰지 않고 있어요 is not writing [for the time being, these days, etc.] or gets along (manages to get by) without writing or keeps away from one's typewriter.",
          sentences: [
            {
              Kor: "여자의 가게에 모습을 나타내지 않고 있었다 ",
              Eng: "The man never showed up at her store again.",
            },
            {
              Kor: "자넨 왜 여태 결혼하지 않고 있나? ",
              Eng: "And you, how come you're still not married?",
            },
            {
              Kor: "설탕물은 이미 당분을 포함하고 뜨겁게 달아 있으나 설탕은 포화 상태를 넘어 아직 풀리지 않고 있다.  ",
              Eng: "The sugar water was sweet and hot but all of the sugar hadn't dissolved yet because the water was past saturation point.",
            },
            {
              Kor: "그래 덕이가 봉네 이야기를 할 때  그는 아무런 대꾸도 하지 않고 그저 듣고만 있었다.  ",
              Eng: "That's right—when Dŏki was talking about Bong, he didn't respond at all and just listened.",
            },
            {
              Kor: "아무튼 아버지는 환자답지 않게 자세를 하나도 흐트러뜨리지 않고 있었다.   ",
              Eng: "Anyhow, Father maintained proper posture unlike a patient.",
            },
          ],
        },
        {
          entryName: "-지 않고는",
          typicalExample: "【주절대지 않고는 】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "The and then pattern in -고는 combined with the long negative in -지 않- creates a pattern to express unless: the consequence implied or stated is usually dire if one doesn't do something, look out, because something unpleasant will/might happen.",
          sentences: [
            {
              Kor: "주절대지 않고는 ",
              Eng: "[Perhaps because he felt he wouldn't be able to stand it] unless he muttered something or other.",
            },
            {
              Kor: "아들의 힘을 빌지 않고는 ",
              Eng: "Unless he relies on his son's strength ...",
            },
            {
              Kor: "노란 기운이 도는 것을 표해보지 않고는 ",
              Eng: "Unless one noted that their long legs were slightly more yellowish than their mother's ...",
            },
          ],
        },
        {
          entryName: "-지 않고서(는)",
          typicalExample: "【면장갑이라도 끼지 않고서는 배겨낼 도리가 없었다】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "Warning in -지 않고서(는) if one doesn't ...(then some dire consequence) unless. The 서 after -고 makes explicit the 'does and then; doing and then' meaning of -고, which can carry the meanings of simple concatenative 'and' and 'and then'. Here -고서는 has the slight difference of more emphasis on the consequence: does and THEN. ",
          sentences: [
            {
              Kor: "면장갑이라도 끼지 않고서는 배겨낼 도리가 없었다",
              Eng: "There was no way to survive if he didn't at least wear a pair of cotton gloves or the like. ",
            },
          ],
        },
        {
          entryName: "-지 않구",
          typicalExample: "【그렇지 않구 】",
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation:
            "Dialect -지 않구 (= -지 않고) for standard -고 말고(요) of course, to be sure.",
          sentences: [{ Kor: "그렇지 않구 ", Eng: "Of course it is!" }],
        },
        {
          entryName: "-지 않냐고",
          typicalExample:
            "【이놈의 서울 생활보다 여유가 있지 않냐고 부러워했고  】",
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation:
            "-지 않냐고 ← -지 않으냐고 asking whether it isn't the case that .... Note that in colloquial, spoken Korean, Plain Style questions in -으냐 and -느냐 tend to lose their -으- and -느-. ",
          sentences: [
            {
              Kor: "이놈의 서울 생활보다 여유가 있지 않냐고 부러워했고  ",
              Eng: "I envied him—didn't he have more freedom than this wretched life here in Seoul?",
            },
          ],
        },
        {
          entryName: "-지 않느냐",
          typicalExample: "【소녀가 이리 건너오고 있지 않느냐 】",
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation:
            "Negative rhetorical question for literary effect—often with a switch from past tense to present (historical present) to heighten the tension and liveliness. ",
          sentences: [
            {
              Kor: "소녀가 이리 건너오고 있지 않느냐 ",
              Eng: "Wouldn't you know it—the girl is walking this way!; or, And whadda ya know—the girl is walking over this way [toward me]!; Whoa!—she's walking this way!",
            },
          ],
        },
        {
          entryName: "-지 않는다",
          typicalExample:
            "【욕조와 벽 사이의 실리콘에 곰팡이가 잘 닦이지 않는다.  】",
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation:
            "Passive in the sense of middle. In other words, we might expect nowadays to encounter 잘 닦여지지 않는다 here rather than just 잘 닦이지 않는다 just won't (let itself) get wiped off. ",
          sentences: [
            {
              Kor: "욕조와 벽 사이의 실리콘에 곰팡이가 잘 닦이지 않는다.  ",
              Eng: "The mold on the silicon between the bathtub and the wall just won't rub off.",
            },
          ],
        },
        {
          entryName: "-지 않았다면 (...-었을 것이다)",
          typicalExample: '【"네가 찾아오지 않았다면 벌써 끝났을 거야." 】',
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation:
            "-지 않았다면 (...-었을 것이다). Conditional in -는/ -(으)ㄴ 다면, -다면. More hypothetical than plain -(으)면, and in origin an abbreviated indirect quote (-는/-(으)ㄴ 다[고 하]면, -다[고 하]면): if one says/supposes that ...  In our example here, the -다면 is functioning much like the counterfactual -더라면had it not been the case that ... (then something would have happened, but didn't).",
          sentences: [
            {
              Kor: '"네가 찾아오지 않았다면 벌써 끝났을 거야." ',
              Eng: "If you hadn't dropped by, it would have been all over already.",
            },
          ],
        },
        {
          entryName: "-지 않았던가(요)?",
          typicalExample: "【그녀는 이런 말까지 하지 않았던가.  】",
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation:
            "-지 않았던가(요)?: Was it not the case, I wonder (-ㄴ가), that something had been observed as having taken place in the past?",
          sentences: [
            {
              Kor: "그녀는 이런 말까지 하지 않았던가.  ",
              Eng: "Didn't she once even goes so far as to say this?",
            },
          ],
        },
        {
          entryName: "-지 않을 수 없다",
          typicalExample:
            "【다음날 다시 그녀의 일기장을 훔쳐보지 않을 수 없었다. 】",
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation:
            "Double negative in -지 않을 수 없-: has no choice/alternative but to ...; cannot help but to ...; can't help ...-ing",
          sentences: [
            {
              Kor: "다음날 다시 그녀의 일기장을 훔쳐보지 않을 수 없었다. ",
              Eng: "I couldn't help but sneak a look at her diary the next day again.",
            },
            {
              Kor: "수긍하지 않을 수 없었다 ",
              Eng: "I had no choice but to consent.",
            },
            {
              Kor: "자신을 상실하지 않을 수 없었던 과거의 경험 ",
              Eng: "My past experience was [that being in Mujin always resulted in me] not being able to not lose my self-confidence.",
            },
            {
              Kor: "아르켜 주지 않을 수 없었다 ",
              Eng: "I had no choice but teach/show her. ",
            },
            {
              Kor: "나의 안해가 꼭 되어 주지 않을 수 없을 것이다",
              Eng: "She will have no choice but to become my wife.",
            },
          ],
        },
        {
          entryName: "진작 ...-지 않고서(는)",
          typicalExample: "【진작 말하지 않구선 】",
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation: "You should have ...earlier; Why didn't you ...earlier?",
          sentences: [
            {
              Kor: "진작 말하지 않구선 ",
              Eng: "Why didn't you tell me sooner?",
            },
          ],
        },
        {
          entryName: "진작 ...-었어야 했다",
          typicalExample: "【진작 알아봤어야 했는데.  】",
          categoryKorean: "Dialect",
          categoryEnglish: "( substandard)",
          translation:
            "진작 ...-었어야 했다: should have done from the very beginning; should have ... in the first place/from the get-go",
          sentences: [
            {
              Kor: "진작 알아봤어야 했는데.  ",
              Eng: "I should have recognized him.",
            },
          ],
        },
        {
          entryName: "-질",
          typicalExample: "【잦은 딸꾹질을 멈추게 하려고  】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "Postnoun: the act of (doing),...ing (sometimes pejorative).",
          sentences: [
            {
              Kor: "잦은 딸꾹질을 멈추게 하려고  ",
              Eng: "in an attempt to make its frequent hiccuping stop",
            },
            {
              Kor: "서로 고자질하는 짓 ",
              Eng: "The [despicable] act of tattling [was prevalent everywhere.]",
            },
            {
              Kor: "그만 일로 편지질을 해서 ",
              Eng: "By up and writing a letter on account of such a [trifling] matter ...",
            },
            {
              Kor: "실로 이 계집의 허영으로 인하여, 순사들이 얼마나 더 악착히 '순사질'을 하였음인고. ",
              Eng: 'In fact, it was because of the vanity of women like this that constables were all the more stubborn and stern in the execution of their "duties”.  ',
            },
            {
              Kor: "그런 게 글쎄 다 불한당질루 장만한 거 아냐? ",
              Eng: "Aren't all those things provided by hooliganism? ",
            },
            {
              Kor: "배운 도적질이 그뿐이니 ",
              Eng: 'Since the "thieving" he had learned was the only thing [he knew]...',
            },
            {
              Kor: "살인강도질을 하고 ",
              Eng: "… was into murdering and thieving, and...",
            },
            { Kor: "나의 끊임없는 충돌질 ", Eng: "my incessant pestering " },
            {
              Kor: "도망질한 것도 당연하죠 ",
              Eng: "Her taking-off was to be expected.",
            },
            {
              Kor: "온 마을 사람들로부터 주정뱅이라고 손가락질 받고 ",
              Eng: "He was pointed at and called a drunk by the entire village, and ...",
            },
            {
              Kor: "나에게 혹독한 채찍질을 하였다 ",
              Eng: "… harshly whipped me.",
            },
            { Kor: "신경질을 부리던 것 ", Eng: "getting annoyed" },
          ],
        },
        {
          entryName: "-질 못하-",
          typicalExample: "【】",
          categoryKorean: '"Colloquial/준꼴 Contraction',
          categoryEnglish: '("Colloquial/준꼴 Contraction)',
          translation: "",
          sentences: [{ Kor: "", Eng: "" }],
        },
        {
          entryName: '"',
          typicalExample:
            "【I did not feel it even once when I was up in the mountains.】",
          categoryKorean: '"Colloquial/준꼴 Contraction',
          categoryEnglish: '("Colloquial/준꼴 Contraction)',
          translation: "-지가 않-, -지를 않-, -질 않-, -지를 못하-",
          sentences: [
            {
              Kor: "I did not feel it even once when I was up in the mountains.",
              Eng: "산행 2",
            },
            { Kor: "알고 있질 못했다 ", Eng: "He had no way of knowing." },
            {
              Kor: "반도 걷질 못했습니다 ",
              Eng: "We haven't even gone half-way.",
            },
            { Kor: "갖고 있질 못했다 ", Eng: "I did not have ..." },
            {
              Kor: "확실하게 알고 있질 못했던 것이다 ",
              Eng: "I did not know exactly ...",
            },
            {
              Kor: "멧돼지 발자국조차 찾질 못했다 ",
              Eng: "We didn't even find a footprint of a wild boar.",
            },
            {
              Kor: "손끝 하나 움직이질 못한다는 하씨는 ",
              Eng: "Mr. Ha, who couldn't budge a finger ...",
            },
            {
              Kor: "토끼에게 가질 못하였다",
              Eng: "I couldn't go visit the rabbit.",
            },
          ],
        },
        {
          entryName: "-질 않-",
          typicalExample: "【영 발동이 걸리질 않는다 】",
          categoryKorean: "Colloquial/준꼴 Contraction",
          categoryEnglish: "(Colloquial/준꼴 Contraction)",
          translation:
            "Colloquial contracted version of the negative pre-emphasis pattern in -지를 않-. Shows dissatisfaction with or displeasure at the negated state or act.",
          sentences: [
            {
              Kor: "영 발동이 걸리질 않는다 ",
              Eng: "He was completely incapable of moving (lit.: The engine in his body just wouldn't start.)",
            },
            {
              Kor: "쉽사리 사람 눈에 띄질 않습니다 ",
              Eng: "They don't appear easily to human eyes.",
            },
            {
              Kor: "관람용 호랑이 생각에서 더 이상 나아가질 않았다 ",
              Eng: "They did not go past their impression of the tiger as an exhibit. ",
            },
            {
              Kor: "시계는 2-30미터도 트이질 않았다 ",
              Eng: "Our field of vision was blocked even for 20-30 meters ahead.",
            },
            { Kor: "아무것도 보이질 않았다 ", Eng: "Nothing was to be seen." },
            {
              Kor: "양씨의 얼굴엔 남아 있질 않았다 ",
              Eng: "There was not a trace left on Mr. Yang's countenance.",
            },
            {
              Kor: "호랑이에 대해선 관심조차도 가지질 않았다 ",
              Eng: "They couldn't even muster up interest in the tiger.",
            },
            {
              Kor: "호기심만은 숨기질 않았다 ",
              Eng: "Only his curiosity was not hidden.",
            },
            {
              Kor: "은근히 밉질 않다고 ",
              Eng: "[He thought to himself that] deep down, he couldn't bring himself to despise [this refreshing attitude].",
            },
            {
              Kor: "배추가 아직 푹 삭질 않아서 ",
              Eng: "as the cabbage was not yet completely cooked ...",
            },
            {
              Kor: "믿어지질 않았다 ",
              Eng: "She could not come to believe it.",
            },
            {
              Kor: "정씨는 발걸음이 내키질 않았다 ",
              Eng: "Mr. Chŏng was not inclined to walk",
            },
            {
              Kor: "억울함과 분함은 가시질 않는다 ",
              Eng: "His feelings of mortification and vexation do not go away.",
            },
          ],
        },
        {
          entryName: "-째",
          typicalExample: "【그렇군 꼭 서른 여섯 해째군 】",
          categoryKorean: "Ordinalizer",
          categoryEnglish: "(Ordinalizer)",
          translation: "Ordinalizer -째.",
          sentences: [
            {
              Kor: "그렇군 꼭 서른 여섯 해째군 ",
              Eng: "That's right, it's the thirty-sixth year.",
            },
            {
              Kor: "올해 삼십육년짼가? ",
              Eng: "Is this the thirty-sixth year?",
            },
          ],
        },
        {
          entryName: "쯤에도 ",
          typicalExample:
            "【열흘 전쯤에도 멧돼지 발자국보다 훨씬 크고 확실한 자국을 사석대 어귀에서 보았다는 그의 말이었다.  】",
          categoryKorean: "Ordinalizer",
          categoryEnglish: "(Ordinalizer)",
          translation: "About approximately; to/at/in ... also/even/either.",
          sentences: [
            {
              Kor: "열흘 전쯤에도 멧돼지 발자국보다 훨씬 크고 확실한 자국을 사석대 어귀에서 보았다는 그의 말이었다.  ",
              Eng: "He was saying that even about ten days ago he had seen tracks that were bigger and more definite than those of a wild boar at the entrance of Sasŏkdae.",
            },
          ],
        },
        {
          entryName: "쯤은",
          typicalExample:
            "【언제든지 한 번쯤은 무슨 수가 생기어 다시 한번 내 집을 쓰게 되고 】",
          categoryKorean: "Particle",
          categoryEnglish: "(Particle)",
          translation:
            "Particle 쯤은: about (so much), approximately (with a sense of denigration); (of a) caliber; so much of, of that extent",
          sentences: [
            {
              Kor: "언제든지 한 번쯤은 무슨 수가 생기어 다시 한번 내 집을 쓰게 되고 ",
              Eng: "At some time they will have to use my house again even just once because they found a means to do so, and...",
            },
            {
              Kor: "하씨를 대하고서 느꼈던 불안감쯤은 ",
              Eng: "The anxiety I had felt after coming into contact with Mr. Ha ...",
            },
          ],
        },
        {
          entryName: "쯤이나",
          typicalExample: "【무전여행쯤이나 될까? 】",
          categoryKorean: "Pseudo-particle",
          categoryEnglish: "(Pseudo-particle)",
          translation:
            "Pseudo-particle -(이)나 on approximate 쯤: or the like; like; or something; or so; the likes of; any; at least; anyway; just (used in gently/vaguely urging), even if it is not of particular interest. Use of 쯤 attenuates even further the piddlin', insignificant likes of meaning of -(이)나.",
          sentences: [
            {
              Kor: "무전여행쯤이나 될까? ",
              Eng: 'Shall we call it "penniless traveling"?',
            },
          ],
        },
        {
          entryName: "차라리",
          typicalExample: "【차라리 잘된 일인지도 몰라요.】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "The adverb 차라리 flags an upcoming comparison or statement of druthers. ",
          sentences: [
            {
              Kor: "차라리 잘된 일인지도 몰라요.",
              Eng: "It might be better that it ended up this way. ",
            },
            {
              Kor: "차라리, 나의 어둡던 세월이 일단 지나가 버린 지금은 나는 거의 항상 무진을 잊고 있었던 편이다. ",
              Eng: "On the contrary, I've been living these days forgetting about Mujin, compared to my dark life before.  ",
            },
            {
              Kor: "그 병명은 차라리 우스꽝스러웠다. ",
              Eng: "His nickname was rather ridiculous.  ",
            },
            {
              Kor: "차라리 종이 노끈인 채 쓰기로 하고 오십 전은 담뱃값으로 나가고 말았다.  ",
              Eng: "He chose to just use [his glasses] with the paper cord instead, and spent the remaining fifty cents on cigarettes.  ",
            },
            {
              Kor: "도루 차라리 여배우 노릇을 댕기라고 그래라. ",
              Eng: "You should tell her to go back to being an actress.  ",
            },
            {
              Kor: "차라리, 저쪽 다릿목에서 버스나 기다릴 걸 잘못했나 봐요 ",
              Eng: "I guess I should have waited for the bus over by the path to the bridge instead.  ",
            },
            {
              Kor: "더욱 얼크러질 앞일을 생각하고 중실은 차라리 하직하고 나온 것이다.  ",
              Eng: "Thinking of how much more entangled things could get, Jungsil had said good-bye and come out instead.  ",
            },
            {
              Kor: "차라리 그 당시에 말씀드리고 함께 슬퍼하시면서 오히려 우리를 위로하실 수도 있었을는지도 모르죠.  ",
              Eng: "If we had told you at the time [rather than now] and been sad together you could have comforted us instead.  ",
            },
          ],
        },
        {
          entryName: "차라리 NOUN이 낫다",
          typicalExample:
            "【차라리 꽃꽂이나 서예 강좌가 낫다는 생각이 들었다.  】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "(차라리) NOUN이 낫다: NOUN would be better; NOUN is preferable. The adverb 차라리 flags an upcoming comparison or statement of druthers. ",
          sentences: [
            {
              Kor: "차라리 꽃꽂이나 서예 강좌가 낫다는 생각이 들었다.  ",
              Eng: "[Instead of lectures of this nature] it occurs to me that flower arrangement or calligraphy lessons would be better.",
            },
          ],
        },
        {
          entryName: "채",
          typicalExample: "【아직 두시도 채 안 된 시간이었다 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 채 + NEGATIVE: not yet [completed]; not entirely/not quite [finished]",
          sentences: [
            {
              Kor: "아직 두시도 채 안 된 시간이었다 ",
              Eng: "It wasn't even two o'clock yet.",
            },
            {
              Kor: "네 개가 채 못되는 것이다",
              Eng: "He had not yet completed four ['正' tallies].",
            },
            {
              Kor: "아직 털도 채 나지 않은 학 ",
              Eng: "the baby crane that didn't even have all its feathers yet",
            },
            {
              Kor: "그 일이 있은 지 한 달도 채 못되어서였다 ",
              Eng: "It hadn't even been quite a month since it happened.",
            },
          ],
        },
        {
          entryName: "~처럼 느껴진다",
          typicalExample: "【알던 사람처럼 느껴졌어요 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN처럼 느껴지-: feel just like, is just like",
          sentences: [
            {
              Kor: "알던 사람처럼 느껴졌어요 ",
              Eng: "It felt as though I knew you [from before].",
            },
            {
              Kor: "아끼지 않으면 안 될 내 몸의 일부처럼 느껴졌다 ",
              Eng: "It was as if [she had become] a part of my body, one that I simply had to hold dear.",
            },
          ],
        },
        {
          entryName: "처럼 보인다",
          typicalExample: "【흰 새처럼 보이는 그 물체 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation:
            "NOUN처럼 보인다: look like a NOUN, appear to be a NOUN ",
          sentences: [
            {
              Kor: "흰 새처럼 보이는 그 물체 ",
              Eng: "the object, which looked like a white bird",
            },
            { Kor: "솜뭉치처럼 보였다. ", Eng: "looked like a cotton wad" },
          ],
        },
        {
          entryName: "처럼 생각(이) 된다",
          typicalExample: "【이 모든 것이 장난처럼 생각되었다 】",
          categoryKorean: "관용구",
          categoryEnglish: "()",
          translation: "NOUN처럼 생각 되-: strike one as NOUN, seem like NOUN",
          sentences: [
            {
              Kor: "이 모든 것이 장난처럼 생각되었다 ",
              Eng: "It all seemed like a joke.",
            },
          ],
        },
        {
          entryName: "치",
          typicalExample: "【심상치 않어 ← 심상하지 않어 】",
          categoryKorean: "Abbreviation",
          categoryEnglish: "(Abbreviation)",
          translation:
            "하지 in the long negation pattern can abbreviate to 치 after voiced consonants and vowels other than 어 and 아. For example: 물건 된 품이 든든치 못 하겠다 It doesn't look very solid (substantial, strong).",
          sentences: [
            {
              Kor: "심상치 않어 ← 심상하지 않어 ",
              Eng: "is not ordinary/commonplace, i.e., is extraordinary, unusual",
            },
            { Kor: "심상치 않은 일 ", Eng: "not something ordinary" },
            {
              Kor: "무언가 예기치 않았던 일이 닥치면  ",
              Eng: "Whenever some unanticipated event happened …",
            },
            { Kor: "마땅치 않았다 ", Eng: "… wasn't proper. " },
          ],
        },
        {
          entryName: "~ 치고는 ",
          typicalExample: "【탁상시계치곤 고급 시계이긴 하나  】",
          categoryKorean: "보조사",
          categoryEnglish: "(보조사)",
          translation:
            "NOUN치고는: When it comes to NOUN; as for every NOUN; as for being a NOUN; 'for a NOUN' considering it is [just] a NOUN",
          sentences: [
            {
              Kor: "탁상시계치곤 고급 시계이긴 하나  ",
              Eng: "It was high-class for a table-clock but...",
            },
            {
              Kor: "뚜--- 소리가 날 때마다 안영감도 그 숨찬 턱을 덜걱거리며 부두로 달음질치곤 했다  ",
              Eng: "Every time the horn sounded, Old Man Ahn would run to the wharf with his rickety jaw gasping for air.  ",
            },
          ],
        },
        {
          entryName: "치군",
          typicalExample: "【시골 아낙네치군 】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation:
            "NOUN치군 is the colloquial/substandard version of NOUN치고는 for a ...; compared to others of the same ilk or kind.",
          sentences: [
            {
              Kor: "시골 아낙네치군 ",
              Eng: "For a country girl [, she was ...]",
            },
          ],
        },
        {
          entryName: "~치 아니하-",
          typicalExample: "【흡족치 아니하고 】",
          categoryKorean: "Colloquial/substandard",
          categoryEnglish: "(Colloquial/substandard)",
          translation:
            "NOUN치 아니하- for NOUN 하지 아니하- is not; does not ...",
          sentences: [
            { Kor: "흡족치 아니하고 ", Eng: "… is not sufficient, and ..." },
          ],
        },
        {
          entryName: "~친 않-",
          typicalExample: "【아무래도 수월친 않을 거야 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contraction from 치는 않- which itself a contraction from 하지는 않-, the long negation pattern.",
          sentences: [
            {
              Kor: "아무래도 수월친 않을 거야 ",
              Eng: "No matter how you look at it, it's not going to be easy. (수월친 않 for 수월하지는 않 - not easy, difficult.)",
            },
          ],
        },
        {
          entryName: "커녕",
          typicalExample: "【뉴똥치마커녕 거지치마 한 감 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "NOUN(은 ~ 는) 커녕: let alone NOUN (not even; to say nothing of NOUN; far from (being); to say nothing of; let alone; on the contrary; not only; not only not; contrary to expectation; instead of (something desirable that one might expect) ",
          sentences: [
            {
              Kor: "뉴똥치마커녕 거지치마 한 감 ",
              Eng: "let alone a silk dress, not even one beggar's dress...",
            },
            {
              Kor: "맹순사는 몇 만 원은커녕, 한 번에 백 원 이상을 얻어먹어 본 적이 없었다. ",
              Eng: "Constable Maeng had never been able to get treated to more than a hundred wŏn at once, let alone several tens of thousands of wŏn.",
            },
            {
              Kor: "샤쓰는커녕 안경다리를 고치겠다고 돈 일 원만 달래도 ",
              Eng: "Never mind the shirt, she wouldn't even give him the money necessary to fix his glasses.",
            },
            {
              Kor: "그러니 이제는 오십전짜리는커녕 단 십전짜리도 얻어 볼 길이 없다.",
              Eng: "Now he had no way of getting even a 10-chŏn piece, let alone a 50-chŏn piece.",
            },
            {
              Kor: "대문간 흙을 몰래 파가는 것은커녕 우리집에다 대고 더위를 팔더라도 ",
              Eng: "To say nothing of the soil from the front gate that was secretly dug away, even if she blamed the heat out loud in front of our house...",
            },
            {
              Kor: "그릇은커녕 깨어진 사금파리 한 조각 볼 수 없었다 ",
              Eng: "Let alone dishes, not even a chip of porcelain could be seen.",
            },
            {
              Kor: "그런데 다방에 들어간 꼰대는 마담을 후려 까기는커녕 꾸벅꾸벅 절을 하는 게 아닌가 ",
              Eng: "Not only was Catfish not smacking her in the face—he was actually bowing to her? ",
            },
            {
              Kor: "인사는 커녕 쳐다도 안 보고 ",
              Eng: "She didn't even look at him, much less greet him ...",
            },
          ],
        },
        {
          entryName: "케 ",
          typicalExample: "【몸 편케 보살펴 드리고, 뭐, 그런 거죠 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation: "Contraction from 하게. ",
          sentences: [
            {
              Kor: "몸 편케 보살펴 드리고, 뭐, 그런 거죠 ",
              Eng: "Making her feel comfortable, things like that.",
            },
            {
              Kor: "어둠을 생각케 했기 때문이다 ",
              Eng: "It was because the dark had me thinking. ",
            },
          ],
        },
        {
          entryName: "키 ",
          typicalExample: "【추적을 감행키로 했다 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contraction of 하기 to 키 after a consonant, usually in the pattern -기로 하- decide to do something.",
          sentences: [
            {
              Kor: "추적을 감행키로 했다 ",
              Eng: "We decided to venture off in our pursuit.",
            },
          ],
        },
        {
          entryName: "타",
          typicalExample: "【청백타 하던 것이었었다 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation: "Contraction of 하다 to 타 after a consonant.",
          sentences: [
            {
              Kor: "청백타 하던 것이었었다 ",
              Eng: "[He] thought he was upright.",
            },
          ],
        },
        {
          entryName: "탓이다",
          typicalExample: "【넘치는 젊음의 활력 탓이리라 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "Postnoun 탓 plus copula: is on account of/due to NOUN; is the fault of NOUN; is to be blamed on NOUN",
          sentences: [
            {
              Kor: "넘치는 젊음의 활력 탓이리라 ",
              Eng: "It's probably due to the overflowing vitality of youth.",
            },
            {
              Kor: "불장난을 심히 하고 온 탓이리라고 조금 전 생각을 하며 마침내 양보하였다  ",
              Eng: "While thinking that I had played with fire many times before I finally decided to let him take part.",
            },
            {
              Kor: "물론 그런 것이 필요 없을 만큼 그가 건강한 탓이기도 했다.  ",
              Eng: "Naturally, the reason why he didn't need such things was also because he was healthy enough.",
            },
            {
              Kor: "생활하는 시간대가 다른 탓이다.  ",
              Eng: "It was because the timing in our lives were different.",
            },
            {
              Kor: "어두운 탓이겠지.",
              Eng: "It's probably because it was dark.",
            },
          ],
        },
        {
          entryName: "터니 ",
          typicalExample: "【풍세대작터니 】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation: "Contraction from 하더니. ",
          sentences: [
            {
              Kor: "풍세대작터니 ",
              Eng: "since the wind was blowing fiercely ...",
            },
          ],
        },
        {
          entryName: "토록 ",
          typicalExample: "【종일토록】",
          categoryKorean: "준꼴 Contraction",
          categoryEnglish: "(준꼴 Contraction)",
          translation:
            "Contraction from 하도록: to the point where it does, says, is, etc.; as much as, to the extent of",
          sentences: [
            { Kor: "종일토록", Eng: "all day long " },
            {
              Kor: "이토록 관장을 업신여기는 년이 일찌기 있었다는 말도 듣지 못하였거니와  ",
              Eng: "I have never heard of a girl who scorned the director to this extent and...",
            },
            {
              Kor: "그날도 골짜기로 비탈로 종일토록 헤매고 있던 노인은 어느 산모퉁이에서 문득 인가를 만나게 되었다.  ",
              Eng: "After wandering down valleys and up hills all day, the old man unexpectedly met Inga on corner of the mountain.",
            },
            {
              Kor: "남원의 성춘향이 그토록 사랑하면서 그토록 두려워한 바로 그 어둠인지  ",
              Eng: "Whether it was the same darkness that Song Ch'unhyang had so loved and dreaded in Namwŏn or [whether it was...]  ",
            },
            {
              Kor: "나로 하여금 그토록 기꺼이 그 사내를 맞아들이게 한 것이나 아니었을까  ",
              Eng: "Perhaps he was letting me show him in out of my own willingness...",
            },
            {
              Kor: "언제부터 저토록 자연스럽게 내 침대의 한 귀퉁이를 차지하고 누워 있게 된 걸까. ",
              Eng: "Since when did he start taking up a corner of my bed so naturally?",
            },
            {
              Kor: "생의 어느 한때인들 그토록 절절히 고통에의 열망에 젖을 때가 있을까  ",
              Eng: "What moment in her life could be so ardently painful and more filled with fervent hope?",
            },
          ],
        },
        {
          entryName: "통에",
          typicalExample:
            "【남편이 난리 통에 첫 번째 아내와 생이별한 게 꼭 첫 번째 아내가 지금 딸만한 나이 때였겠구나 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "Postnoun 통에: as a consequence/result of, under the influence of (something disturbing). More or less synonymous with 바람에 and 탓으로 because of, due to. ",
          sentences: [
            {
              Kor: "남편이 난리 통에 첫 번째 아내와 생이별한 게 꼭 첫 번째 아내가 지금 딸만한 나이 때였겠구나 ",
              Eng: "It must have been when his wife was about the age of his daughter that he was forced to be separated from her in the ravages of war.  ",
            },
            {
              Kor: "그것은 육이오 동란 통에 발작한 증세였다. ",
              Eng: "Those were the symptoms of a stroke that happened during the Korean War.",
            },
          ],
        },
        {
          entryName: "통해(서)",
          typicalExample:
            "【이주자들은 불모의 황무지에 살게 될 것이라고 책을 통해 알게 된 것 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation: "NOUN(을) 통해(서): through/by way of NOUN",
          sentences: [
            {
              Kor: "이주자들은 불모의 황무지에 살게 될 것이라고 책을 통해 알게 된 것 ",
              Eng: "the things he had learned through the book; that the settlers would end up living in a barren wasteland",
            },
            {
              Kor: "모 유력자를 통해 ",
              Eng: "through an influential person [called] So-and-So",
            },
            {
              Kor: "양씨의 열띤 눈빛을 통해 ",
              Eng: "through Mr. Yang's fervent glance ",
            },
            {
              Kor: "그들은 옥에 이르러 기왕에 통해 놓은 사정이 자리를 비켜 주어서  ",
              Eng: "When they arrived at the jail, the lackey they had been in cahoots with stepped aside [and they made their way to Ch’unhyang's cell.]",
            },
            {
              Kor: "청년은 뫄한머루라는 격투기 수련을 통해 도에 이른다는 독특한 종교를 갖고 있었는데 ",
              Eng: "She knew without a doubt that her husband saw the image of the wife he had left in North Korea through his daughter. ",
            },
            {
              Kor: "남편은 딸을 통해 이북에 두고 온 당시의 아내의 모습을 되살렸음에 틀림없다.  ",
              Eng: "",
            },
            {
              Kor: "도마뱀은 천천히 항문을 통해 빠져나온다.   ",
              Eng: "The lizard is slowly escaping through the anus.  ",
            },
            {
              Kor: "그의 성기가 내 몸을 관통해버릴 것 같은 두려움이 든다.  ",
              Eng: "I feared that his genitals would pierce through my body.",
            },
            {
              Kor: "주위는 누런 색안경을 통해서 내다본 것처럼 뿌옇게 보였다  ",
              Eng: "The world around me appeared misty as though I was looking out through a pair of yellow-tinted glasses.",
            },
            {
              Kor: "양 씨의 열띤 눈빛을 통해 호랑이의 존재는 어김없이 느껴졌던 것이다.  ",
              Eng: "By the fiery look in Yang's eyes, I knew for sure that the tigers really did exist.  ",
            },
          ],
        },
        {
          entryName: "틀림없다 ",
          typicalExample: "【글씨를 봐도 그녀가 틀림없다. 】",
          categoryKorean: "Postnoun",
          categoryEnglish: "(Postnoun)",
          translation:
            "NOUN이 틀림없다: there is no doubt that (it is); there is no questioning that fact that it is ....",
          sentences: [
            {
              Kor: "글씨를 봐도 그녀가 틀림없다. ",
              Eng: "From the handwriting, it was clearly that it was hers.",
            },
          ],
        },
        {
          entryName: "판에",
          typicalExample: "【출출하던 판에 】",
          categoryKorean: "Postmodifier",
          categoryEnglish: "(Postmodifier)",
          translation:
            "Postmodifier 판 (= 판국) + 에: in the [somewhat lamentable or undesirable] situation where/that",
          sentences: [
            {
              Kor: "출출하던 판에 ",
              Eng: "[after smoking several cigarettes] on an empty stomach; or, [after smoking several cigarettes] in the situation where he had an empty stomach ",
            },
            {
              Kor: "춤을 추다 웃음판에 그대로 나가넘어진 일도 있었다  ",
              Eng: "There were times when they just burst into laughter while dancing.",
            },
            {
              Kor: "나라가 망하던 판에 오죽해.  ",
              Eng: "What else could you do when the country was coming to ruin.",
            },
            {
              Kor: "쌀 나무 들일 길이 막연할 판에 이르렀다  ",
              Eng: "They had come to a point where obtaining rice and wood did not seem possible anymore.",
            },
            {
              Kor: "그 놀음판에서 나의 뜨거워져 가는 머리와 떨리는 손가락만을 제외하곤  ",
              Eng: "At that gambling table, aside from my burning face and shaking fingers...",
            },
            {
              Kor: "그것이 남편과 아내의 판에 박힌 대화법이니까.  ",
              Eng: "That was the stereotypical conversation style between a married couple.  ",
            },
            {
              Kor: "길수는 노점 싸구려판에서 엄마 고무신과 두 동생의 양말 한 켤레씩을 샀다.",
              Eng: "Kilsu bought two pairs of socks for his younger siblings and a pair of rubber shoes for his mother from one of the cheap street venders.",
            },
          ],
        },
        {
          entryName: "핑계로",
          typicalExample: "【공부 핑계로 】",
          categoryKorean: "Postmodifier",
          categoryEnglish: "(Postmodifier)",
          translation:
            "NOUN(을) 핑계로: taking NOUN as one's excuse on the pretext of NOUN; under the pretense of NOUN; with a plea of NOUN",
          sentences: [
            { Kor: "공부 핑계로 ", Eng: "on the pretext of studying" },
            {
              Kor: "나는 피로를 핑계로 ",
              Eng: "with the excuse that I was tired ",
            },
          ],
        },
        {
          entryName: "하기는 ",
          typicalExample: "【영감의 신세가 하기는 가엾기는 하다 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation: "For adverb 하긴: in fact, in truth, indeed; actually",
          sentences: [
            {
              Kor: "영감의 신세가 하기는 가엾기는 하다 ",
              Eng: "After all, the old man's lot in life was pitiful.",
            },
          ],
        },
        {
          entryName: "하긴",
          typicalExample: "【하긴 그런 운동이 진작 일어났어야 하는 거예요. 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation: "Adverb 하긴: in fact, in truth, indeed; actually",
          sentences: [
            {
              Kor: "하긴 그런 운동이 진작 일어났어야 하는 거예요. ",
              Eng: "Actually, a demonstration like that should have happened already.",
            },
            {
              Kor: "하긴 그녀와 길게 얘기를 나눠 본 것도 꽤 오래되긴 했다 ",
              Eng: "It had indeed been a long time since they had shared a long conversation.",
            },
            {
              Kor: "하긴 살뜰하고 다감하여 지겨운 아내 ",
              Eng: "Indeed, she is a frugal, sensitive and wearisome wife. ",
            },
            {
              Kor: "담배 꽁초를 건네주며 영달이가 퉁명스럽게 말했다. 하긴 창피한 노릇이었다. ",
              Eng: "Youngdal spoke bluntly as he handed me the cigarette butt. In reality, it was an embarrassing habit.",
            },
          ],
        },
        {
          entryName: "하나이",
          typicalExample: "【농부 하나가 억새풀 사이로 올라왔다. 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Archaic subject marker 이 on a noun ending in a vowel. Whereas modern Korean as two different shapes for the subject particle 이 and 가  Middle Korean had just 이. The noun 하나 'one' in Middle Korean actually ended in a final ㅎ, now lost. Thus, 하나이 is an archaic (and thus rather literary-sounding) equivalent of 하나가.",
          sentences: [
            {
              Kor: "농부 하나가 억새풀 사이로 올라왔다. ",
              Eng: "A farmer had come up through the purple eulalia grass.",
            },
          ],
        },
        {
          entryName: "하니",
          typicalExample: "【대문 빗장을 따고 떡하니 뒷짐을 지고서는, 에헴… 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb in -어니 ~-아니. For 하-, one encounters both 허니 and 하니 (= 하게). Cf. 일찌거니 a bit earlier, 슬그머니 surreptitiously, 멀거니 with a far-off look, absentmindedly, etc.",
          sentences: [
            {
              Kor: "대문 빗장을 따고 떡하니 뒷짐을 지고서는, 에헴… ",
              Eng: "After unbolting the front gate she firmly stood there with her hands behind her bad and cleared her throat...",
            },
            {
              Kor: "우선 내 눈에 뜨이는 것이, 문간에고 양쪽 기둥 밑에 소복하니 뿌리어진, 고운 황토 흙이었다. ",
              Eng: "The first thing that I noticed was the yellow earth heaped up just inside the gate and under the post on each side.",
            },
            {
              Kor: "똥파리가 어깨를 두들겨주고 휑하니 찬바람 속을 달려갔다. ",
              Eng: "Dung Fly hovered aboout my shoulder and with a whoosh, raced into the wind.",
            },
            {
              Kor: "하나 우뚝하니 서 있을 뿐이었다 ",
              Eng: "Only one lone [cracked earthenware jar] was left standing there, conspicuously.",
            },
            {
              Kor: "콧마루가 찡하니 아파왔다 ",
              Eng: "The bridge of my nose scowled up and began to smart.",
            },
            {
              Kor: "메밀밭이다. 전에 없이 메밀꽃내가 짜릿하니 코를 찌른다고 생각됐다. ",
              Eng: "It's a buckwheat field. I thought that the scent of buckwheat flowers thrillingly pierced my nose.",
            },
            {
              Kor: "멍하니 앉아 있다가 문득 내려다보면",
              Eng: "Whenever I would look down after sitting absentmindedly for a while …",
            },
            {
              Kor: "멍하니 서 있는 지수",
              Eng: "Chisu, who was standing vacantly",
            },
          ],
        },
        {
          entryName: "하도 ",
          typicalExample: "【하도 오랜만에】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation: "[So] very much indeed, ever so (hard)",
          sentences: [
            {
              Kor: "하도 오랜만에",
              Eng: "It had indeed been such a long time since ...",
            },
            {
              Kor: "하도 추워하시길래 안방으로 모셨어요 ",
              Eng: "Since you looked so cold I brought you into the inside room.  ",
            },
            {
              Kor: "하도 착실한 학생이었던지라 만의 하나라도 무슨 일이 있는 게 아닌가 싶어 알리는 거니 ",
              Eng: "I'm only lettting you know in case there is even a one-in-ten-thousand chance that something happened to him, since he was such a trust-worthy student. ",
            },
          ],
        },
        {
          entryName: "하마",
          typicalExample: "【하마 나가자빠질 뻔하였다 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 하마(터면) by the skin of his teeth, on the verge, flagging or presaging the almost pattern in -(으)ㄹ뻔 하-.",
          sentences: [
            {
              Kor: "하마 나가자빠질 뻔하였다 ",
              Eng: "… nearly fell over backwards.",
            },
          ],
        },
        {
          entryName: "하마터면",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 하마터면 by the skin of his teeth, on the verge, flagging or presaging the almost pattern in -(으)ㄹ뻔 하-.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "하사",
          typicalExample:
            "【천지지신은 감동하사 한양성 이몽룡을 청운에 높이 올려 내 딸 춘향 살려지이다. 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Literary and premodern form of 하셔 deigns to do/say/think/be; [often] you do/say/think/are. In origin, from Middle Korean hosya, where Middle Korean –sya was the regular equivalent to modern -시-어 (honorific plus infinitive).",
          sentences: [
            {
              Kor: "천지지신은 감동하사 한양성 이몽룡을 청운에 높이 올려 내 딸 춘향 살려지이다. ",
              Eng: "Oh Sun, Moon and Stars, please be moved by my request, let Yi Mongnyong in Hanyang achieve high office and save my daughter Ch'unhyang. ",
            },
          ],
        },
        {
          entryName: "하야 ",
          typicalExample:
            "【소녀의 가련한 자식 춘향이는.  무도한 사또 강박하야. 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Old, premodern form of 하여, which in turn is the source of 해.",
          sentences: [
            {
              Kor: "소녀의 가련한 자식 춘향이는.  무도한 사또 강박하야. ",
              Eng: "My poor little Ch'unhyang . . . the wicked magistrate is forcing her to... ",
            },
          ],
        },
        {
          entryName: "하지-deletion",
          typicalExample: "【부인을 않고 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Elision/deletion (optional dropping) of 하지 in long negation patterns after a processive verbal noun. Literary Korean often drops 하지 after verbal nouns (such as 말, 공부, etc.) in the patterns VERBAL NOUN 하지 않- and VERBAL NOUN 하지 마-ㄹ-.",
          sentences: [
            {
              Kor: "부인을 않고 ",
              Eng: "I did not take my words back, and ...",
            },
            {
              Kor: "단한마디 말도 않던 알마덴",
              Eng: "Almaden who had not spoken a single word to her",
            },
            {
              Kor: "김을 맬 생각도 않고 ",
              Eng: "[They] didn't even think of doing the weeding, and ...",
            },
            { Kor: "눈도 깜짝 않고 ", Eng: "not even blinking, and ..." },
            {
              Kor: "대꾸 않을 수도 없었다",
              Eng: "had no choice but to reply (lit.: couldn't even not reply)",
            },
            {
              Kor: "개 한 마리도 얼씬을 않더마 ",
              Eng: "There wasn't even a dog to show its face; or, Not even a dog was to be seen.",
            },
            {
              Kor: "신분도 확인 않고 ",
              Eng: "without even checking up on his identity ",
            },
            {
              Kor: "직장이 잘 받아주려고도 않는 그런 사람들",
              Eng: "people like those who don't even try to get good jobs",
            },
            {
              Kor: "백화가 대답을 않고 ",
              Eng: "Paekhwa didn't answer, and ...",
            },
            {
              Kor: "일체 호랑이 얘길 않더니 ",
              Eng: "not saying a word about tigers, now ...",
            },
            {
              Kor: "잠을 잘 생각을 않았다 ",
              Eng: "We had no thoughts of sleep. (For 생각을 [하지]않았다.)",
            },
            {
              Kor: "아까 5분 넘게 벨을 눌러도 끄떡 않던 그녀의 잠은  ",
              Eng: "My wife's slumber, even though she hadn't budged just now as I rang the buzzer for five minutes … ",
            },
            {
              Kor: "“아직 시작도 않았어요.” ",
              Eng: "They haven't even started it.",
            },
            { Kor: "아무 걱정 마십쇼 ", Eng: "Don't worry about me one bit." },
            { Kor: "염려 말아 ", Eng: "Have no fear." },
            { Kor: "염려 말아요 ", Eng: "Don't worry." },
            {
              Kor: "자연을 보호할 생각을 말고 ",
              Eng: "Don't think about protecting the environment ...",
            },
          ],
        },
        {
          entryName: "하필(이면)",
          typicalExample: "【하필이면 네가 그런 큰일을 저지르다니.】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "[Why] of all things ...? Why necessarily .... Glossed by English-Korean dictionaries as: 다른 방도를 취하지 아니하고 어찌 꼭: Why must one forsake other methods and insist on …?. ",
          sentences: [
            {
              Kor: "하필이면 네가 그런 큰일을 저지르다니.",
              Eng: "To think that you, of all people, would have made such an awful blunder! ",
            },
            {
              Kor: "하필이면 네가 그런 말을 하다니.",
              Eng: "To think that you, of all persons, should say so. ",
            },
            {
              Kor: "하필이면 왜 그를 뽑았는가? ",
              Eng: "Why did you elect him of all people? ",
            },
            {
              Kor: "하필이면 왜 내가 가야만 하니?",
              Eng: "Why should I, of all persons, go?",
            },
            {
              Kor: "다른 날도 많은데 하필이면 섣달 그믐날 밤이라니. ",
              Eng: "Of all the good days in the year, on News Year's Eve! ",
            },
            { Kor: "하필이면 오늘이냐? ", Eng: "Why today of all days? " },
            {
              Kor: "하필이면 그런 남자와 결혼을 하다니.",
              Eng: "Just imagine [Fancy] her marrying such a man of all men!",
            },
            {
              Kor: "하필 제일 분주할 때 그가 찾아와 괴롭히다니. ",
              Eng: "Just as I was very busy, he came to bother me.",
            },
            {
              Kor: '"하필 이 겨울에 혼자서 여행을?" 남편이 놀라다 못해 신기해했다.',
              Eng: '"Why on earth would you travel alone in the winter like this?" Her husband was more curious than shocked.',
            },
            {
              Kor: "서울을요? 왜요? 하필이면 이 추운 날. ",
              Eng: "Seoul?  Why there?  Why, of all days, on this cold day?",
            },
          ],
        },
        {
          entryName: "해필(이면)",
          typicalExample:
            "【아---니, 해필 임자가 옷이 많다는 게 아니라, 보통 여자들이 말야. 】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "Dialect/substandard for 하필(이면): of all things, necessarily. Glossed by Korean-Korean dictionaries as: 다른 방도를 취하지 아니하고 어찌 꼭: Why must one forsake other methods and insist on ... ? ",
          sentences: [
            {
              Kor: "아---니, 해필 임자가 옷이 많다는 게 아니라, 보통 여자들이 말야. ",
              Eng: "Now of all things, I wasn't saying that you have a lot of clothes but that other women do in general.",
            },
          ],
        },
        {
          entryName: "한 ",
          typicalExample: "【한 이웃에 살던 사람이구마 】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation: "Meaning [one and] the same.",
          sentences: [
            {
              Kor: "한 이웃에 살던 사람이구마 ",
              Eng: "She was a person who used to live in the same neighbourhood as us.",
            },
          ],
        },
        {
          entryName: "한가운데 ",
          typicalExample:
            "【들 한가운데 조망이 좋은 곳에 커다랗게 서 있는 청량음료 광고판과 텔리비전과 세탁기 선전판이 보였다. 】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation:
            "The very midst, the very middle. 한 meaning the peak, the extreme, the most, the very. Note other examples such as 한잠 deepest sleep; 한고비 the very climax/height. ",
          sentences: [
            {
              Kor: "들 한가운데 조망이 좋은 곳에 커다랗게 서 있는 청량음료 광고판과 텔리비전과 세탁기 선전판이 보였다. ",
              Eng: "Right down the middle of the field where the view was good, there were advertisement billboards for refreshing drinks and televisions.",
            },
          ],
        },
        {
          entryName: "한다",
          typicalExample:
            "【한동안 의아하게 쳐다보더니 다음 순간 '설마, 꿈이겠지' 하는 표정으로  】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation: "Verbatim quote with 하- say, think to oneself.",
          sentences: [
            {
              Kor: "한동안 의아하게 쳐다보더니 다음 순간 '설마, 꿈이겠지' 하는 표정으로  ",
              Eng: "after staring at me with a dubious expression for a while, the next moment, with an expression as if to say, 'Must be a dream', ...",
            },
            {
              Kor: "외풍이 세어서 어쩌나 하면서 ",
              Eng: '"The wind outside is so strong, what shall I do?" she said to herself. (Lit.: She said, “외풍이 세어서 어쩌나?”)',
            },
            {
              Kor: "어딜 가면 살 수 있겠는가' 하는 생각에서였다 ",
              Eng: "It came from the thought that he would not be able to live anywhere. ",
            },
          ],
        },
        {
          entryName: "한테 ",
          typicalExample:
            "【봉네의 태도가 그들이 왜놈한테 끌려갔다 다시 마을로 돌아온 뒤는 또 좀 달랐다. 】",
          categoryKorean: "부사격 조사 ",
          categoryEnglish: "(부사격 조사 )",
          translation: "To mark the agent by-phrase in a passive.",
          sentences: [
            {
              Kor: "봉네의 태도가 그들이 왜놈한테 끌려갔다 다시 마을로 돌아온 뒤는 또 좀 달랐다. ",
              Eng: "The attitude of Bong's family was different after they had returned from being dragged off by the Japs. ",
            },
            {
              Kor: "그들은 탄실이 아버지한테 붙들리어 ",
              Eng: "They were caught by T'ansil's father (and then) ...",
            },
          ],
        },
        {
          entryName: "해서 ",
          typicalExample:
            "【아녀자의 넓지 못한 속...... 해서 오늘 아침만 하여도 하찮은 일로 시초가 되어】",
          categoryKorean: "부사격 조사 ",
          categoryEnglish: "(부사격 조사 )",
          translation: "Says/thinks ...and so/then.",
          sentences: [
            {
              Kor: "아녀자의 넓지 못한 속...... 해서 오늘 아침만 하여도 하찮은 일로 시초가 되어",
              Eng: "But even so, here again is this simple woman with her one-track mind...only just this morning she starts up her bitching about ... ",
            },
          ],
        },
        {
          entryName: "향하여(서)",
          typicalExample: "【달을 향하여 긴 주둥이를 들고 】",
          categoryKorean: "부사격 조사 ",
          categoryEnglish: "(부사격 조사 )",
          translation:
            "NOUN(을) 향하여(서): heads for, starts/leaves for NOUN; is bound for, goes/proceeds to/in the direction of NOUN; in the direction of/toward NOUN. The more bookish and older form of 향해(서).",
          sentences: [
            {
              Kor: "달을 향하여 긴 주둥이를 들고 ",
              Eng: "raising its long beak in the direction of the moon, and ...",
            },
            {
              Kor: "한참이나 마을 쪽을 향하여 서 있었다. ",
              Eng: "They were standing there for quite some time facing the village.",
            },
            {
              Kor: "억쇠를 향하여 ",
              Eng: "in Oksoe's direction; or, toward Oksoe ",
            },
            { Kor: "뻐스를 향하여 ", Eng: "in the direction of the bus " },
            { Kor: "묘를 향하여 엎드려 절했다", Eng: "I bowed to the grave." },
            {
              Kor: "나는 그를 좀 방문해 볼까 하고 그의 집으로 향하여 시적시적 가다가도  ",
              Eng: "Even while I was reluctantly heading toward his house, thinking that I should drop by and see how he's doing...",
            },
          ],
        },
        {
          entryName: "향해(서)",
          typicalExample: "【어두운 들판을 향해서 달려갔다 】",
          categoryKorean: "부사격 조사 ",
          categoryEnglish: "(부사격 조사 )",
          translation:
            "NOUN(을) 향해(서): heads for, starts/leaves for NOUN; is bound for, goes/proceeds to/in the direction of NOUN; in the direction of/toward NOUN. ",
          sentences: [
            {
              Kor: "어두운 들판을 향해서 달려갔다 ",
              Eng: "headed off into the dark fields",
            },
            {
              Kor: "다음 정거장을 향해 ",
              Eng: "[The bus] was heading for the next stop ...",
            },
            {
              Kor: "둑길을 향해 올라갔다",
              Eng: "He went up towards the path along the dike.",
            },
            {
              Kor: "마을을 향해 꺾어졌다 ",
              Eng: "He turned toward the village.",
            },
            {
              Kor: "사람들은 입구를 향해 ",
              Eng: "The people were (noisily crowding) toward the entrance, and…",
            },
            {
              Kor: "지수는 닿을 수 없는 곳을 향해 손을 내밀었다. ",
              Eng: "Chisu reached out toward a place that she couldn't reach.",
            },
            {
              Kor: "지수 자매를 향해 아버지는 단지 이렇게만 말했다. ",
              Eng: "This was everything their father said to Chisu and her sister.",
            },
            {
              Kor: "곳곳에서 지수를 향해 번득였다.  ",
              Eng: "sparkled toward Chisu from here and there",
            },
            {
              Kor: "그 소리들을 향해  ",
              Eng: "in the direction of those sounds",
            },
            { Kor: "그 물체를 향해  ", Eng: "in the direction of the object" },
            {
              Kor: "공중에 매달린 그물주머니를 향해 공을 던지고  ",
              Eng: "Throwing the ball in the direction of the net suspended in mid-air, and …",
            },
            {
              Kor: "분명히 누군가 자신을 향해 다가오던 느낌.  ",
              Eng: "The feeling that somebody had clearly been coming toward her.",
            },
            {
              Kor: "도마뱀이 침대를 향해 다가온다.  ",
              Eng: "The lizard comes toward the bed.",
            },
          ],
        },
        {
          entryName: "허니",
          typicalExample: "【[Examples?]】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb in -어니 ~-아니. For 하-, one encounters both 허니 and 하니 (= 하게). Cf. 일찌거니 a bit earlier, 슬그머니 surreptitiously, 멀거니 with a far-off look, absentmindedly, etc.",
          sentences: [{ Kor: "[Examples?]", Eng: "" }],
        },
        {
          entryName: "허- ",
          typicalExample: "【살인강도허구 다를 게 있었나 】",
          categoryKorean: "Dialect/substandard",
          categoryEnglish: "(Dialect/substandard)",
          translation: "Dialect/substandard equivalent of 하-.",
          sentences: [
            {
              Kor: "살인강도허구 다를 게 있었나 ",
              Eng: "Is there any difference between a robbing murderer and… ?",
            },
            {
              Kor: "그렇게만 생각허믄 ",
              Eng: "If that's the only way you think...",
            },
            { Kor: "돈벌이만 잘 허믄서 ", Eng: "While making a good living …" },
            { Kor: "헐 말이 무슨 헐 말이우?", Eng: "What is there to say?" },
          ],
        },
        {
          entryName: "혹 ",
          typicalExample: "【혹 구경했는기오 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 혹 short for 혹시 meaning maybe, perchance to soften, attenuate or otherwise make more polite a question.",
          sentences: [
            {
              Kor: "혹 구경했는기오 ",
              Eng: "Did you happen to see?; or, Did you perchance see? ",
            },
          ],
        },
        {
          entryName: "혹시",
          typicalExample:
            "【혹시 대문이 열렸나 하고 이서방의 어머니가 미리 와서 흔들어봤던 것인가...  】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Adverb 혹시 meaning maybe, perchance to soften, attenuate or otherwise make more polite a question.",
          sentences: [
            {
              Kor: "혹시 대문이 열렸나 하고 이서방의 어머니가 미리 와서 흔들어봤던 것인가...  ",
              Eng: "Perhaps Lee's mother had come by earlier and shook the gate to see if it was open...",
            },
            {
              Kor: "어머니, 혹시 제가 지금 미친다면 대강 다음과 같은 원인들 때문일 테니  ",
              Eng: "Mother, if I go insane it's probably because of the following reasons...",
            },
            {
              Kor: "저 아주머니 혹시 나를 약이라도 먹고 영영 잠들려는 손님으로 오해했던 게 아닌가 하는 생각까지 들었다  ",
              Eng: "I even had the thought that maybe the lady mistook me for one of those guests who came to overdose on pills and commit suicide.",
            },
            {
              Kor: "혹시 볼펜 같은 거 있어요?",
              Eng: "Do you any pens by any chance?",
            },
            {
              Kor: "혹시 누구네 집엔 있을지도 모르겠다고 가겟집 주인은 직무유기성 발언을 했다. ",
              Eng: "The store owner, out of duty, declared that he might be at somebody's house.  ",
            },
            {
              Kor: "저 혹시 카퍼필드라구 아세요…….",
              Eng: "Do you by any chance know of someone named Copperfield? ",
            },
            {
              Kor: "그래도 내겐 그놈 하나밖에......  혹시나 돌아올까 해서",
              Eng: "She is still the only one that I have...[I just thought I'd check] if perhaps she comes back...",
            },
            {
              Kor: "꿈 가운데 임을 만나 만단정회 하였더니 혹시 서방님께서 기별 왔소? ",
              Eng: "I met my beloved in a dream and we shared so many tender memories together. Any news from my betrothed?  ",
            },
            {
              Kor: "혹시 딸이 이 배에나 저 배에나 돌아오지나 않을까 하고, 망망한 바다에 뱃소리만  기다리고 ",
              Eng: "He waits only for the sound of boats coming from the boundless ocean in case his daughter had come back on this boat or some other boat.  ",
            },
            {
              Kor: "어떤 때는 혹시 뉘집 부엌에서 고기 국물이나 얻어 마시고 나서면서는 흐릿하게나마  ",
              Eng: "At times, emerging from a neighborhood kitchen after downing a bowl of free meat broth, he would dimly think these thoughts... ",
            },
            {
              Kor: "여자는 그가 혹시 어디서 만나자고 하면 뭐라고 대답해야 할까 생각해 보았다  ",
              Eng: "The woman thought about how she should reply if he happened to ask her to meet him somewhere.",
            },
          ],
        },
        {
          entryName: "흡사",
          typicalExample:
            "【그건 흡사 강한 자석에 쇠붙이가 끌리는 것과 같았다. 】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation: "[Usage]",
          sentences: [
            {
              Kor: "그건 흡사 강한 자석에 쇠붙이가 끌리는 것과 같았다. ",
              Eng: "It was just like a metal object being dragged by a strong magnet.",
            },
            {
              Kor: "언제 적 내린 눈인지...흡사 더러운 홑이불을 펼쳐 놓은 것처럼 궁상스러워 보였다  ",
              Eng: "You could tell that it hadn't snowed to long ago. It was miserable-looking, like a dirty blanket had been spread over the ground.",
            },
          ],
        },
        {
          entryName: "Reduplication",
          typicalExample: "【지수의 나날을 견디게 한 것은  】",
          categoryKorean: "Adverb",
          categoryEnglish: "(Adverb)",
          translation:
            "Not often seen in three-syllable words. Cf. 집집이, 곳곳에, etc.",
          sentences: [
            {
              Kor: "지수의 나날을 견디게 한 것은  ",
              Eng: "The thing that allowed Chisu to endure these days upon end … (나날 days, in origin a reduplicated from from 날 + 날.)",
            },
            { Kor: "중간중간에", Eng: "here and there, everywhere, all over" },
            {
              Kor: "그녀의 푸념 마디마디가 ",
              Eng: "Each and every bit of her complaint ...",
            },
            {
              Kor: "무더기무더기 핀 진달래꽃 ",
              Eng: "the azaleas blooming in clusters here and there",
            },
            {
              Kor: "책들을 한권 한권 뽑아갔다.",
              Eng: "… pulled out books, one book at a time.",
            },
            {
              Kor: "철거계고장을 한자 한자 뜯어 읽었다.",
              Eng: "He read aloud, syllable by syllable, the notice of eviction.",
            },
            {
              Kor: "오래오래 이 산중에 남아",
              Eng: "to stay over a long time in the mountain ",
            },
          ],
        },
      ],
      keys: {
        entryName: "entryName",
      },
      entryName: "entryName",
      fuseOptions: {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 50,
        maxPatternLength: 12,
        minMatchCharLength: 3,
        keys: ["entryName"],
      },
    };
    this.renderVocab.bind(this);
    this.updateSearchTerm.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.startGrammarSearch(this.props.storyInfo);
  }

  componentWillUnmount() {
    if (this.props.analytics.sessions.length > 0) {
      //this.props.endGrammarSearch();
    }
  }

  renderVocab = (vocabWord) => {
    return (
      <TableRow>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          {vocabWord.korean}
        </TableCell>
        <TableCell>{vocabWord.english}</TableCell>
        <TableCell>
          <i className="material-icons">add</i>
        </TableCell>
      </TableRow>
    );
  };

  updateSearchTerm = (searchTerm) => {
    this.setState({
      searchTerm,
    });
  };

  render() {
    return (
      <div>
        <style>
          {`
            .FuseInstructions{
              display: none;
            }
            .FuseResults {
              list-style-type: none;
            }
            .FusePicker {
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            .FuseInput {
              background: #f3f3f3;
              border-radius: 19px;
              border: 1px solid #f3f3f3;
              height: 40px;
              padding: 10px;
            }

            .FuseInput:focus {outline:0;}
            
            `}
        </style>
        <Grid
          style={{ marginTop: "2em" }}
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <h5 style={{ backgroundColor: "#ffd8d5", padding: "15px" }}>
            Instructions: start typing a search term such as &quot;가&quot; in
            the box below to search for grammar patterns starting with that
            character.
          </h5>
          <FusePicker
            isOpen={true}
            items={this.state.items}
            renderItem={(item) => {
              return (
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="title">{item.entryName}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <h4>Translation:</h4>
                      {item.translation}
                      <h4>Examples:</h4>
                      <ol>
                        {item.sentences.map((sentence) => (
                          <div key={sentence.Kor}>
                            <li>
                              {sentence.Kor}{" "}
                              <span>
                                <strong>({sentence.Eng})</strong>
                              </span>
                            </li>
                          </div>
                        ))}
                      </ol>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            }}
            fuseOptions={this.state.fuseOptions}
            onChange={(item) => {
              this.props.updateGrammarSearch(item.entryName);
            }}
          />
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  analytics: state.analytics,
});

const mapDispatchToProps = {
  getVocabforStory,
  updateGrammarSearch,
  startGrammarSearch,
  endGrammarSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(GrammarSearch);
