import React, { useState } from "react";
import { Typography, Tooltip, ClickAwayListener } from "@material-ui/core";

function TextTooltip({ text, data }) {
  const [open, toggleTooltip] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => toggleTooltip(false)}>
      <Tooltip
        arrow
        onClose={() => toggleTooltip(false)}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="top"
        style={{ opacity: 1, bgColor: "black" }}
        title={
          <Typography sx={{ p: 2 }}>
            <strong>English</strong>: {data.eng}
            <br />
            <strong>Hanja</strong>: {data.hanja}
          </Typography>
        }
      >
        <span
          onClick={() => toggleTooltip(true)}
          dangerouslySetInnerHTML={{ __html: text }}
          style={{ color: "red", cursor: "pointer" }}
        />
      </Tooltip>
    </ClickAwayListener>
  );
}

export default TextTooltip;
