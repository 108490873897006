import "./NewHanjaContainer.css";

import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";
import { showHanjiAnimation } from "../../../utils";
import { withRouter } from "react-router-dom";
import NavigatingButtons from "../../components/Lessons/NavigatingButtons/NavigatingButtons";
import Divider from "@material-ui/core/Divider";
import axios from "axios";

class NewHanjaContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newHanja: [],
      currentLesson: this.props.match.params.lesson,
    };
  }

  UNSAFE_componentWillMount() {
    axios.get("/api/lesson/getNewHanja").then((resp) => {
      const currLesson = this.props.match.params.lesson;

      let temp = (resp.data || [])
        .filter((word) => {
          return word.lesson === parseInt(currLesson);
        })
        .sort((a, b) => a.hanja.localeCompare(b.hanja, "ko-KR"));

      this.setState(
        {
          newHanja: temp,
        },
        () => {
          this.state.newHanja.forEach((pair, idx) => {
            this.showHanjiAnimation(pair.hanja, idx);
          });
        },
      );
    });
  }

  showHanjiAnimation = (hanja, idx) => {
    showHanjiAnimation(hanja, "character-target-div-" + idx);
  };

  render() {
    const { newHanja } = this.state;

    return (
      <div style={{ display: "flex" }}>
        <div className="new-hanja">
          <h3 style={{ paddingBottom: 10 }}>새 漢字</h3>
          <div>
            <h4 style={{ textAlign: "left", width: "50%" }}>
              제 {this.state.currentLesson} 과
            </h4>
          </div>
          <Divider style={{ marginBottom: "0.5rem" }} />
          <br />
          <Grid container spacing={1} className="new-hanja-con">
            {newHanja.map((char, idx) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                  className="new-hanja-box"
                  key={idx}
                >
                  <div className="new-hanja-boxContainer">
                    <div
                      className="new-hanja-char"
                      style={{ textAlign: "center" }}
                    >
                      <br />
                      <div id={"character-target-div-" + idx} />
                      <br />
                    </div>
                    <div className="new-hanja-card">
                      <p>
                        <b>訓 (훈) + 音 (음):</b>&nbsp;&nbsp; {char.hoonEum}
                      </p>
                      <p>
                        <b>Meaning(s):</b> &nbsp;&nbsp;
                        {char.meaning}
                      </p>
                      <p>
                        <b>Total Stroke Count:</b> &nbsp;&nbsp;
                        {char.totalStrokeCount}
                      </p>
                      <p>
                        <b>Radical:</b>&nbsp;&nbsp; {char.radical}
                      </p>
                      <p>
                        <b>Radical Name:</b>&nbsp;&nbsp; {char.radicalName}
                      </p>
                      <p>
                        <b>Radical Meaning:</b>&nbsp;&nbsp;{" "}
                        {char.radicalMeaning}
                      </p>
                      <p>
                        <b>Radical Stroke Count:</b> &nbsp;&nbsp;{" "}
                        {char.radicalStrokeCount}
                      </p>
                      <p>
                        <b>Phonetic:</b>&nbsp;&nbsp; {char.phonetic}
                      </p>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <br />
          <Grid container className="root">
            <Grid item xs={6}>
              <span id="animation" style={{ textAlign: "center" }}>
                <div id="character-target-div" />
              </span>
            </Grid>
          </Grid>
        </div>
        <NavigatingButtons />
      </div>
    );
  }
}

export default withRouter(NewHanjaContainer);
