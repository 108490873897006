/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import {
  getAboutNewBusu,
  getMainText,
  getNewHanjaCombos,
  getNewVocabulary,
} from "../../../actions/KORN351/Lessons";
import {
  saveMainText,
  saveTitle,
  saveExampleSentence,
  addNewExampleSentence,
  deleteExampleSentence,
  saveOthers,
  saveAboutNewBusu,
  saveNewPhonetic,
  saveNewHanjaCombo,
  saveSideBarVocab,
  deleteAboutNewBusu,
  deleteNewHanjaCombo,
  deleteNewPhonetic,
} from "../../../actions/KORN351/Instructor";
import WordPower from "../InstructorPanel/WordPower/WordPower";
import "./EditLesson.css";
import Divider from "@material-ui/core/Divider/Divider";
import { getPhonetics } from "../../../actions/KORN351/Okpyeon";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  FormControl,
  TextField,
  Box,
  Modal,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PlusIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/CancelRounded";
import AddIcon from "@material-ui/icons/AddBoxOutlined";
import axios from "axios";
import _ from "lodash";

class EditLesson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      lesson: "1", // string apparently
      mainText: "",
      title: "",
      subText: {
        subHeading: "",
        content: "",
      },
      exampleSentences: [],
      aboutNewBusu: [],
      phonetics: [],
      newHanjaCombos: [],
      newVocabMainText: [],
      newVocabExampleSentences: [],
      newExampleSent: { num: [], sentences: [] },
      isNewBusuModalOpen: false,
      newAboutNewBusu: {},
      isNewHanjaComboModalOpen: false,
      newHanjaCombo: {},
      sidebarMaintext: "",
      sidebarExamples: "",
    };
  }

  componentDidMount() {
    this.initialize();
  }

  addMainTextVocab = (index) => {
    const clonedMainTextVocab = _.clone(this.state.newVocabMainText);
    clonedMainTextVocab.splice(index + 1, 0, {
      kor: "",
      eng: "",
      dictionaryForm: "",
      hanja: "",
    });
    this.setState({ newVocabMainText: clonedMainTextVocab });
  };

  addExSentVocab = (index) => {
    const clonedExSentencesVocab = _.clone(this.state.newVocabExampleSentences);
    clonedExSentencesVocab.splice(index + 1, 0, {
      kor: "",
      eng: "",
      dictionaryForm: "",
      hanja: "",
    });
    this.setState({ newVocabExampleSentences: clonedExSentencesVocab });
  };

  removeMainTextVocab = (index) => {
    let clonedVocabMainText = _.clone(this.state.newVocabMainText);
    clonedVocabMainText = clonedVocabMainText.filter((s, i) => i !== index);
    this.setState({ newVocabMainText: clonedVocabMainText });
  };

  removeExampleSentencesVocab = (index) => {
    let clonedVocabExampleSentences = _.clone(
      this.state.newVocabExampleSentences,
    );
    clonedVocabExampleSentences = clonedVocabExampleSentences.filter(
      (s, i) => i !== index,
    );
    this.setState({ newVocabExampleSentences: clonedVocabExampleSentences });
  };

  onChangeExample(index, field, value, type) {
    const clonedSentences = _.clone(
      type === "newVocabMainText"
        ? this.state.newVocabMainText
        : this.state.newVocabExampleSentences,
    ).map((s, i) => {
      if (i === index) {
        s[field] = value;
      }
      return s;
    });
    if (type === "newVocabMainText") {
      this.setState({ newVocabMainText: clonedSentences });
    } else {
      this.setState({ newVocabExampleSentences: clonedSentences });
    }
  }

  initialize() {
    this.setState({ tabValue: 0 });
    this.setState({ newExampleSent: { num: [], sentences: [] } });

    let lesson = this.props.match.params.id;
    let updateState = false;
    if (this.state.lesson.toString() !== lesson) {
      updateState = true;
    }

    this.props.getMainText(lesson).then(() => {
      this.setState({
        lesson: lesson,
        title: this.props.title,
        mainText: this.props.mainText,
        subText: this.props.subText,
        exampleSentences: this.props.exampleSentences,
      });
    });

    if (
      this.props.aboutNewBusu.length === 0 ||
      this.props.aboutNewBusu === undefined ||
      updateState
    ) {
      this.props.getAboutNewBusu().then(() => {
        let temp = this.props.aboutNewBusu.filter((word) => {
          return word.lesson === lesson;
        });
        this.setState({
          aboutNewBusu: temp,
        });
      });
    } else {
      this.setState({
        aboutNewBusu: this.props.aboutNewBusu,
      });
    }

    //phonetics
    if (
      this.props.phonetics.length === 0 ||
      this.props.phonetics === undefined ||
      updateState
    ) {
      this.props.getPhonetics().then(() => {
        let temp = this.props.phonetics.filter((phonetic) => {
          return (phonetic.lesson || "").toString() === lesson;
        });
        this.setState({
          phonetics: temp,
        });
      });
    } else {
      this.setState({
        phonetics: this.props.phonetics,
      });
    }

    //newHanjaCombos
    if (
      this.props.newHanjaCombos.length === 0 ||
      this.props.newHanjaCombos === undefined ||
      updateState
    ) {
      this.props.getNewHanjaCombos().then(() => {
        let temp = this.props.newHanjaCombos.filter((combo) => {
          return combo.lesson === lesson;
        });
        this.setState({
          newHanjaCombos: temp,
        });
      });
    } else {
      this.setState({
        newHanjaCombos: this.props.newHanjaCombos,
      });
    }

    //sidebar
    if (
      this.props.newVocab === "" ||
      this.props.newVocab === undefined ||
      updateState
    ) {
      this.props.getNewVocabulary(lesson).then(() => {
        this.setState({
          newVocabMainText: this.props.newVocab.mainText,
          newVocabExampleSentences: this.props.newVocab.exSentences,
        });
      });
    } else {
      this.setState({
        newVocabMainText: this.props.newVocab.mainText,
        newVocabExampleSentences: this.props.newVocab.exSentences,
      });
    }
  }

  handleOnChangeTab = (event, value) => {
    this.setState({ tabValue: value });
  };

  handleMainTextChange = (event) => {
    this.setState({
      mainText: event.target.value,
    });
  };

  handleTitleChange = (event) => {
    this.setState({
      title: event.target.value,
    });
  };

  saveMainText = () => {
    this.props.saveMainText(this.state.lesson, this.state.mainText);
  };

  saveTitle = () => {
    this.props.saveTitle(this.state.lesson, this.state.title);
  };

  saveOthers = () => {
    const subheading = this.subheading.value;
    const subcontent = this.subcontent.value;

    this.setState({
      subText: {
        subHeading: subheading,
        content: subcontent,
      },
    });

    this.props.saveOthers(this.state.lesson, subheading, subcontent);
  };

  saveExSentence = (num) => {
    let sentencesString = this.state[num].value;
    let temp = sentencesString.split("\n");
    temp = temp.filter((sentence) => {
      return sentence.trim() !== "";
    });

    let exampleSentences = this.state.exampleSentences;
    exampleSentences.forEach((sentence) => {
      if (sentence.num === num) {
        sentence.sentences = temp;
      }
    });

    this.props.saveExampleSentence(this.state.lesson, exampleSentences);
  };

  handleNewExSentNumChange = (e) => {
    const { value, _name } = e.target;
    this.setState({ num: value });
  };

  handleNewExSentChange = (e) => {
    const { value, _name } = e.target;
    this.setState({ sentences: value });
  };

  addNewExampleSentence = () => {
    let exSentencesSplit = this.state.sentences.split(/\n/);
    let exSentencesArray = [];
    for (let row of exSentencesSplit) {
      exSentencesArray.push(row);
    }

    let newExampleSent = { num: this.state.num, sentences: exSentencesArray };
    this.props.addNewExampleSentence(this.state.lesson, newExampleSent);
    this.initialize();
  };

  deleteExampleSentence = (id, num) => {
    if (
      window.confirm(
        "Are you sure you want to delete example sentence # " + num + " ?",
      )
    ) {
      this.props.deleteExampleSentence(this.state.lesson, id);
      alert("Example sentence #" + num + " has been deleted!");
      this.initialize();
    } else {
      alert("Example sentence #" + num + " has NOT been deleted.");
    }
  };

  createNewBusu = () => {
    const { newAboutNewBusu, lesson } = this.state;
    if (newAboutNewBusu.examples) {
      newAboutNewBusu.examples = newAboutNewBusu.examples
        .split("\n")
        .map((c) => c.trim())
        .map((c) => {
          const [word, def] = c.split(":");
          return { word: word.trim(), def: def.trim() };
        });
    }
    newAboutNewBusu.lesson = lesson;

    axios({
      method: "POST",
      data: newAboutNewBusu,
      url: "/api/instructor351/addAboutNewBusu",
    }).then((res) => {
      newAboutNewBusu._id = res.data;
      this.setState((prevState) => ({
        aboutNewBusu: [newAboutNewBusu, ...prevState.aboutNewBusu],
      }));
      this.onNewBusuAddModalToggle();
    });
  };

  saveNewBusu = (id) => {
    let characters = this.state[id].value.split("\n");
    characters = characters.filter((ex) => {
      return ex.trim() !== "";
    });
    characters = characters.map((ex) => {
      let temp = ex.split(":");
      return {
        word: temp[0].trim(),
        def: temp[1].trim(),
      };
    });

    this.props.saveAboutNewBusu(
      this.state.lesson,
      id,
      this.state[id + "word"].value.trim(),
      this.state[id + "def"].value.trim(),
      this.state[id + "busu"].value.trim(),
      this.state[id + "desc"].value.trim(),
      characters,
    );
  };

  deleteNewBusu = (id, word) => {
    if (
      window.confirm("Are you sure you want to delete " + word + " from 부수?")
    ) {
      this.props.deleteAboutNewBusu(this.state.lesson, id);
      window.alert(word + " has been deleted!");
    } else {
      alert(word + "has NOT been deleted.");
    }
  };

  saveNewPhonetics = (id) => {
    let examples = this.state[id].value.split("\n");
    examples = examples.filter((ex) => {
      return ex.trim() !== "";
    });

    let subPronunciation = [];
    let subCharacters = [];

    if (this.state[id + "sub-pronunciation"])
      subPronunciation = this.state[id + "sub-pronunciation"].value.trim();
    if (this.state[id + "sub-characters"]) {
      subCharacters = this.state[id + "sub-characters"].value.split("\n");
      subCharacters = subCharacters.filter((ex) => {
        return ex.trim() !== "";
      });
    }
    this.props.saveNewPhonetic(
      this.state.lesson,
      id,
      this.state[id + "phonetic"].value.trim(),
      this.state[id + "pronunciation"].value.trim(),
      examples,
      subPronunciation,
      subCharacters,
    );
  };
  deleteNewPhonetics = (id, phonetic) => {
    if (
      window.confirm(
        "Are you sure you want to delete " + phonetic + " from phonetics?",
      )
    ) {
      this.props.deleteNewPhonetic(this.state.lesson, id);
      window.alert(phonetic + " has been deleted!");
    } else {
      alert(phonetic + " has NOT been deleted.");
    }
  };

  onNewBusuAddModalToggle = () => {
    this.setState((prevState) => ({
      isNewBusuModalOpen: !prevState.isNewBusuModalOpen,
      newAboutNewBusu: {},
    }));
  };

  onNewHanjaComboAddModalToggle = () => {
    this.setState((prevState) => ({
      isNewHanjaComboModalOpen: !prevState.isNewHanjaComboModalOpen,
      newHanjaCombo: {},
    }));
  };

  saveNewHanjaCombo = (id) => {
    let hanja = this.state[id + "hanja"].value.trim();
    let kor = this.state[id + "kor"].value.trim();
    let eng = this.state[id + "eng"].value.trim();

    this.props.saveNewHanjaCombo(this.state.lesson, id, hanja, kor, eng);
  };

  createNewHanjaCombo = () => {
    const { newHanjaCombo, lesson } = this.state;
    newHanjaCombo.lesson = lesson;

    axios({
      method: "POST",
      data: newHanjaCombo,
      url: "/api/instructor351/addNewHanjaCombo",
    }).then((res) => {
      newHanjaCombo._id = res.data;
      this.setState((prevState) => ({
        newHanjaCombos: [newHanjaCombo, ...prevState.newHanjaCombos],
      }));
      this.onNewHanjaComboAddModalToggle();
    });
  };
  deleteNewHanjaCombo = (id, hanja) => {
    if (
      window.confirm(
        "Are you sure you want to delete " + hanja + " from New 한자?",
      )
    ) {
      this.props.deleteNewHanjaCombo(this.state.lesson, id);
      window.alert(hanja + " has been deleted!");
    } else {
      alert(hanja + " has NOT been deleted.");
    }
  };

  saveSideBarVocab = () => {
    const { newVocabMainText, newVocabExampleSentences, lesson } = this.state;
    this.props.saveSideBarVocab(
      lesson,
      newVocabMainText.filter((a) => !!a.kor),
      newVocabExampleSentences.filter((a) => !!a.kor),
    );
  };

  render() {
    const { _mainText, subText, _exampleSentences } = this.props;

    const {
      aboutNewBusu,
      _phonetics,
      newHanjaCombos,
      _lesson,
      newVocabMainText,
      newVocabExampleSentences,
      _sidebarMaintext,
      _sidebarExamples,
      isNewBusuModalOpen,
      newAboutNewBusu,
      newHanjaCombo,
      isNewHanjaComboModalOpen,
    } = this.state;

    return (
      <div className="edit-lesson-container">
        <h2>Lesson {this.props.match.params.id}</h2>
        <h5>
          <i>Editing Lesson: Click on a category to edit</i>
        </h5>

        <div>
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleOnChangeTab}
            indicatorColor="primary"
            textColor="primary"
            style={{ padding: "2%" }}
            variant="scrollable"
          >
            <Tab label="Main Lesson" />
            <Tab label="새 부수에 대하여" />
            <Tab label="New 한자 Combos" />
            <Tab label="Word Power" />
          </Tabs>
        </div>

        {this.state.tabValue === 0 && (
          <div className="edit-lesson-background">
            <h2>Title, Main Text & Example Sentences (& Others)</h2>
            <Divider />
            <br />
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Title</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <input
                    type="text"
                    defaultValue={this.state.title}
                    onChange={this.handleTitleChange}
                  />
                  <br /> <br />
                  <Button
                    style={{
                      marginRight: "4px",
                      backgroundColor: "#00284d",
                      color: "white",
                      width: "20%",
                      textAlign: "center",
                    }}
                    onClick={() => this.saveTitle()}
                  >
                    SAVE
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Main Text</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <textarea
                    name="main-text"
                    style={{ width: "100%", overflowWrap: "break-word" }}
                    defaultValue={this.state.mainText}
                    rows="7"
                    cols="90"
                    className="edit-input"
                    onChange={this.handleMainTextChange}
                  />
                  <Button
                    style={{
                      marginRight: "4px",
                      backgroundColor: "#00284d",
                      color: "white",
                      width: "20%",
                      textAlign: "center",
                    }}
                    onClick={() => this.saveMainText()}
                  >
                    SAVE
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Example Sentences</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  {this.state.exampleSentences.map((num) => {
                    let str = "";
                    num.sentences.forEach((sentence) => {
                      str += sentence + "\n";
                    });
                    let unique = num.num;
                    return (
                      <div key={unique} style={{ padding: "0 5%" }}>
                        Num: {num.num}
                        <br />
                        Sentences:
                        <textarea
                          style={{ overflowWrap: "break-word" }}
                          rows="5"
                          cols="50"
                          className="edit-input"
                          ref={(input) => (this.state[unique] = input)}
                          defaultValue={str}
                        />
                        <Button
                          style={{
                            marginRight: "4px",
                            backgroundColor: "#00284d",
                            color: "white",
                            width: "20%",
                          }}
                          onClick={() => this.saveExSentence(num.num)}
                        >
                          SAVE
                        </Button>
                        <Button
                          style={{
                            marginRight: "4px",
                            backgroundColor: "#f12433",
                            color: "white",
                            width: "20%",
                          }}
                          onClick={() =>
                            this.deleteExampleSentence(num._id, num.num)
                          }
                        >
                          DELETE
                        </Button>
                        <br />
                        <br />
                      </div>
                    );
                  })}
                  <Divider />
                  <div style={{ padding: "0 5%" }}>
                    <form>
                      <br />
                      <p>
                        <b>Add new example sentences</b>
                      </p>
                      Example num: {""}
                      <input
                        name="new-example-num"
                        id="new-example-num"
                        onChange={this.handleNewExSentNumChange}
                        value={this.state.num}
                        type="text"
                        style={{ overflowWrap: "break-word" }}
                        rows="5"
                        cols="50"
                      />
                      <br />
                      <br />
                      Example sentences: {""}
                      <textarea
                        name="new-example-sentences"
                        id="new-example-sentences"
                        onChange={this.handleNewExSentChange}
                        defaultValue={this.state.sentences}
                        type="text"
                        placeholder="Press 'enter' after each line."
                        style={{ overflowWrap: "break-word" }}
                        rows="5"
                        cols="60"
                      />
                    </form>
                    <Button
                      style={{
                        marginRight: "4px",
                        backgroundColor: "#5bba73",
                        color: "white",
                        width: "20%",
                      }}
                      onClick={this.addNewExampleSentence}
                    >
                      ADD
                    </Button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Others</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!!subText ? (
                  <div>
                    <div>
                      Sub Heading: <br />
                      <textarea
                        rows="3"
                        type="text"
                        defaultValue={this.state.subText.subHeading}
                        style={{ width: "100%" }}
                        ref={(input) => (this.subheading = input)}
                      />
                      <br />
                      Content:
                      <textarea
                        style={{ overflowWrap: "break-word" }}
                        rows="5"
                        cols="90"
                        className="edit-input"
                        defaultValue={this.state.subText.content}
                        ref={(input) => (this.subcontent = input)}
                      />
                      <br />
                      <br />
                    </div>
                    <Button
                      style={{
                        marginRight: "4px",
                        backgroundColor: "#00284d",
                        color: "white",
                        width: "20%",
                      }}
                      onClick={() => this.saveOthers()}
                    >
                      SAVE
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Side-bar Vocabulary</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <FormControl variant="standard">
                    <label>From Main Text</label>
                    {(newVocabMainText || [])
                      .sort((a, b) => a.kor.localeCompare(b.kor, "ko-KR"))
                      .map((sent, i) => (
                        <div
                          key={i}
                          className="ir-EditLesson-sentenceContainer"
                        >
                          <div className="ir-EditLesson-sentence">
                            <FormControl className="ir-EditLesson-sentenceInput">
                              <TextField
                                size="small"
                                id="outlined-basic"
                                label="Korean"
                                variant="outlined"
                                onChange={(event) =>
                                  this.onChangeExample(
                                    i,
                                    "kor",
                                    event.target.value,
                                    "newVocabMainText",
                                  )
                                }
                                value={sent.kor}
                              />
                            </FormControl>
                            <FormControl className="ir-EditLesson-sentenceInput">
                              <TextField
                                size="small"
                                id="outlined-basic"
                                label="English"
                                variant="outlined"
                                onChange={(event) =>
                                  this.onChangeExample(
                                    i,
                                    "eng",
                                    event.target.value,
                                    "newVocabMainText",
                                  )
                                }
                                value={sent.eng}
                              />
                            </FormControl>
                            <FormControl className="ir-EditLesson-sentenceInput">
                              <TextField
                                size="small"
                                id="outlined-basic"
                                label="Dictionary Form"
                                variant="outlined"
                                onChange={(event) =>
                                  this.onChangeExample(
                                    i,
                                    "dictionaryForm",
                                    event.target.value,
                                    "newVocabMainText",
                                  )
                                }
                                value={sent.dictionaryForm}
                              />
                            </FormControl>
                            <FormControl className="ir-EditLesson-sentenceInput">
                              <TextField
                                size="small"
                                id="outlined-basic"
                                label="Hanja"
                                variant="outlined"
                                onChange={(event) =>
                                  this.onChangeExample(
                                    i,
                                    "hanja",
                                    event.target.value,
                                    "newVocabMainText",
                                  )
                                }
                                value={sent.hanja}
                              />
                            </FormControl>
                          </div>
                          <div className="ir-EditLesson-sentenceActions">
                            <Tooltip title="Add">
                              <AddIcon
                                onClick={() => this.addMainTextVocab(i)}
                              />
                            </Tooltip>
                            {newVocabMainText.length > 1 && (
                              <Tooltip title="Remove">
                                <RemoveIcon
                                  onClick={() => this.removeMainTextVocab(i)}
                                />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      ))}
                  </FormControl>
                  <FormControl variant="standard">
                    <label>From Example Sentences</label>
                    {(newVocabExampleSentences || [])
                      .sort((a, b) => a.kor.localeCompare(b.kor, "ko-KR"))
                      .map((sent, i) => (
                        <div
                          key={i}
                          className="ir-EditLesson-sentenceContainer"
                        >
                          <div className="ir-EditLesson-sentence">
                            <FormControl className="ir-EditLesson-sentenceInput">
                              <TextField
                                size="small"
                                id="outlined-basic"
                                label="Korean"
                                variant="outlined"
                                onChange={(event) =>
                                  this.onChangeExample(
                                    i,
                                    "kor",
                                    event.target.value,
                                    "newVocabExampleSentences",
                                  )
                                }
                                value={sent.kor}
                              />
                            </FormControl>
                            <FormControl className="ir-EditLesson-sentenceInput">
                              <TextField
                                size="small"
                                id="outlined-basic"
                                label="English"
                                variant="outlined"
                                onChange={(event) =>
                                  this.onChangeExample(
                                    i,
                                    "eng",
                                    event.target.value,
                                    "newVocabExampleSentences",
                                  )
                                }
                                value={sent.eng}
                              />
                            </FormControl>
                            <FormControl className="ir-EditLesson-sentenceInput">
                              <TextField
                                size="small"
                                id="outlined-basic"
                                label="Dictionary Form"
                                variant="outlined"
                                onChange={(event) =>
                                  this.onChangeExample(
                                    i,
                                    "dictionaryForm",
                                    event.target.value,
                                    "newVocabExampleSentences",
                                  )
                                }
                                value={sent.dictionaryForm}
                              />
                            </FormControl>
                            <FormControl className="ir-EditLesson-sentenceInput">
                              <TextField
                                size="small"
                                id="outlined-basic"
                                label="Hanja"
                                variant="outlined"
                                onChange={(event) =>
                                  this.onChangeExample(
                                    i,
                                    "hanja",
                                    event.target.value,
                                    "newVocabExampleSentences",
                                  )
                                }
                                value={sent.hanja}
                              />
                            </FormControl>
                          </div>
                          <div className="ir-EditLesson-sentenceActions">
                            <Tooltip title="Add">
                              <AddIcon onClick={() => this.addExSentVocab(i)} />
                            </Tooltip>
                            {newVocabExampleSentences.length > 1 && (
                              <Tooltip title="Remove">
                                <RemoveIcon
                                  onClick={() =>
                                    this.removeExampleSentencesVocab(i)
                                  }
                                />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      ))}
                  </FormControl>
                  <Button
                    style={{
                      marginRight: "4px",
                      backgroundColor: "#00284d",
                      color: "white",
                      width: "20%",
                    }}
                    onClick={() => this.saveSideBarVocab()}
                  >
                    SAVE
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        )}

        {this.state.tabValue === 1 && (
          <div className="edit-lesson-background">
            <div className="ir-EditOkpyeon-header">
              <h2>새 부수에 대하여</h2>
              <IconButton
                size="medium"
                onClick={this.onNewBusuAddModalToggle}
                className="primary-button"
                variant="contained"
              >
                <PlusIcon />
              </IconButton>
            </div>
            <Modal
              open={isNewBusuModalOpen}
              onClose={this.onNewBusuAddModalToggle}
            >
              <Box className="ir-EditOkpyeon-modal">
                <Typography variant="h6" component="h6">
                  Add Hanja Character
                </Typography>
                <label>Word:</label>
                <input
                  type="text"
                  placeholder="Type Word.."
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newAboutNewBusu.word = event.target.value)
                  }
                />
                <br />
                <label>Definition:</label>
                <textarea
                  placeholder="Type Definition.."
                  style={{ width: 400 }}
                  rows="3"
                  onChange={(event) =>
                    (newAboutNewBusu.def = event.target.value)
                  }
                ></textarea>
                <br />
                <label>Busu:</label>
                <input
                  type="text"
                  placeholder="Type Busu.."
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newAboutNewBusu.busu = event.target.value)
                  }
                />
                <br />
                <label>Description:</label>
                <textarea
                  placeholder="Type Description.."
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newAboutNewBusu.desc = event.target.value)
                  }
                ></textarea>
                <br />
                <label>Examples:</label>
                <textarea
                  placeholder="Type examples separated by new line in the format word:definition"
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newAboutNewBusu.examples = event.target.value)
                  }
                ></textarea>
                <br />
                <br />
                <Button
                  style={{
                    marginRight: "4px",
                    backgroundColor: "#00284d",
                    color: "white",
                    width: "20%",
                  }}
                  onClick={this.createNewBusu}
                >
                  Create
                </Button>
                <Button
                  variant="contained"
                  onClick={this.onNewBusuAddModalToggle}
                >
                  Cancel
                </Button>
              </Box>
            </Modal>
            <Divider />
            <br />
            {aboutNewBusu &&
              (aboutNewBusu || [])
                .sort((a, b) => a.word.localeCompare(b.word, "ko-KR"))
                .map((busu) => {
                  let str = "";
                  if (busu.examples) {
                    busu.examples.forEach((ex) => {
                      str += ex.word + ":" + ex.def + "\n";
                    });
                  }

                  return (
                    <div key={busu._id}>
                      <Accordion key={busu._id}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>{busu.word}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            Word:{" "}
                            <input
                              cols="50"
                              type="text"
                              defaultValue={busu.word}
                              style={{ width: "100%" }}
                              ref={(input) =>
                                (this.state[busu._id + "word"] = input)
                              }
                            />
                            <br />
                            <br />
                            Definition:{" "}
                            <textarea
                              cols="50"
                              type="text"
                              defaultValue={busu.def}
                              style={{ width: "100%" }}
                              ref={(input) =>
                                (this.state[busu._id + "def"] = input)
                              }
                            />
                            <br />
                            <br />
                            Busu:{" "}
                            <input
                              cols="50"
                              type="text"
                              defaultValue={busu.busu}
                              style={{ width: "100%" }}
                              ref={(input) =>
                                (this.state[busu._id + "busu"] = input)
                              }
                            />
                            <br />
                            <br />
                            Description:{" "}
                            <textarea
                              rows="7"
                              cols="50"
                              type="text"
                              defaultValue={busu.description}
                              style={{ width: "100%" }}
                              ref={(input) =>
                                (this.state[busu._id + "desc"] = input)
                              }
                            />
                            <br />
                            <br />
                            Examples:
                            <textarea
                              name="main-text"
                              style={{ overflowWrap: "break-word" }}
                              defaultValue={str}
                              rows="7"
                              cols="50"
                              className="edit-input"
                              ref={(input) => (this.state[busu._id] = input)}
                            />
                            <br />
                            <br />
                            <Button
                              style={{
                                marginRight: "4px",
                                backgroundColor: "#00284d",
                                color: "white",
                                width: "20%",
                              }}
                              onClick={() => this.saveNewBusu(busu._id)}
                            >
                              SAVE
                            </Button>
                            <Button
                              style={{
                                marginRight: "4px",
                                backgroundColor: "#f6152f",
                                color: "white",
                                width: "20%",
                              }}
                              onClick={() =>
                                this.deleteNewBusu(busu._id, busu.word)
                              }
                            >
                              DELETE
                            </Button>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <br />
                    </div>
                  );
                })}
          </div>
        )}
        {/**<div className="edit-lesson-background">
                 <h2>새 한자</h2>
                 <Divider /><br/>

                 </div>
                 <br/><br/>**/}

        {this.state.tabValue === 2 && (
          <div className="edit-lesson-background">
            <div className="ir-EditOkpyeon-header">
              <h2>New 한자 Combos</h2>
              <IconButton
                size="medium"
                onClick={this.onNewHanjaComboAddModalToggle}
                className="primary-button"
                variant="contained"
              >
                <PlusIcon />
              </IconButton>
            </div>
            <Divider />
            <br />
            <Modal
              open={isNewHanjaComboModalOpen}
              onClose={this.onNewHanjaComboAddModalToggle}
            >
              <Box className="ir-EditOkpyeon-modal">
                <Typography variant="h6" component="h6">
                  Add New 한자 Combos
                </Typography>
                <label>Hanja:</label>
                <input
                  type="text"
                  placeholder="Type Hanja.."
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newHanjaCombo.hanja = event.target.value)
                  }
                />
                <br />
                <label>Korean:</label>
                <input
                  type="text"
                  placeholder="Type Korean.."
                  style={{ width: 400 }}
                  onChange={(event) => (newHanjaCombo.kor = event.target.value)}
                />
                <br />
                <label>English:</label>
                <input
                  type="text"
                  placeholder="Type English.."
                  style={{ width: 400 }}
                  onChange={(event) => (newHanjaCombo.eng = event.target.value)}
                />
                <br />
                <br />
                <Button
                  style={{
                    marginRight: "4px",
                    backgroundColor: "#00284d",
                    color: "white",
                    width: "20%",
                  }}
                  onClick={this.createNewHanjaCombo}
                >
                  Create
                </Button>
                <Button
                  variant="contained"
                  onClick={this.onNewHanjaComboAddModalToggle}
                >
                  Cancel
                </Button>
              </Box>
            </Modal>
            {newHanjaCombos
              .sort((a, b) => a.hanja.localeCompare(b.hanja, "ko-KR"))
              .map((combo) => {
                return (
                  <div key={combo._id}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>
                          {combo.hanja} {combo.kor}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        Hanja:{" "}
                        <input
                          type="text"
                          defaultValue={combo.hanja}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[combo._id + "hanja"] = input)
                          }
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        Korean:{" "}
                        <input
                          type="text"
                          defaultValue={combo.kor}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[combo._id + "kor"] = input)
                          }
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        English:{" "}
                        <input
                          type="text"
                          defaultValue={combo.eng}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[combo._id + "eng"] = input)
                          }
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Button
                          style={{
                            marginRight: "4px",
                            backgroundColor: "#00284d",
                            color: "white",
                            width: "10%",
                          }}
                          onClick={() => this.saveNewHanjaCombo(combo._id)}
                        >
                          SAVE
                        </Button>
                        <Button
                          style={{
                            marginRight: "4px",
                            backgroundColor: "#f6152f",
                            color: "white",
                            width: "10%",
                          }}
                          onClick={() =>
                            this.deleteNewHanjaCombo(combo._id, combo.hanja)
                          }
                        >
                          DELETE
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                    <br />
                  </div>
                );
              })}
          </div>
        )}

        {this.state.tabValue === 3 && (
          <WordPower lesson={this.props.match.params.id} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mainText: state.lessons.mainText,
  title: state.lessons.title,
  subText: state.lessons.subText,
  exampleSentences: state.lessons.exampleSentences,
  aboutNewBusu: state.lessons.aboutNewBusu,
  phonetics: state.okpyeon.phonetics,
  newHanjaCombos: state.lessons.newHanjaCombos,
  newVocab: state.lessons.newVocabulary,
});

const mapDispatchToProps = {
  getMainText,
  getAboutNewBusu,
  saveMainText,
  saveTitle,
  saveExampleSentence,
  addNewExampleSentence,
  deleteExampleSentence,
  saveOthers,
  saveAboutNewBusu,
  getPhonetics,
  saveNewPhonetic,
  getNewHanjaCombos,
  saveNewHanjaCombo,
  getNewVocabulary,
  saveSideBarVocab,
  deleteAboutNewBusu,
  deleteNewHanjaCombo,
  deleteNewPhonetic,
};
export default connect(mapStateToProps, mapDispatchToProps)(EditLesson);
