import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { withCookies } from "react-cookie";

class Landing extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated || this.props.cookies.get("jwtToken")) {
      this.props.history.push("/dashboard/KORN351");
    }
  }

  loginWithCWL = () => {
    window.location.href = `${window.location.origin}/saml/auth`;
  };

  render() {
    return (
      <div className="landing">
        <div className="dark-overlay landing-inner text-light">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1 className="display-3 mb-4">Interline Reader</h1>
                <p className="lead">
                  Get assistance reading challenging literary texts from foreign
                  languages.
                </p>
                <hr />
                <Button
                  size="large"
                  style={{ backgroundColor: "#002144", color: "white" }}
                  onClick={this.loginWithCWL}
                  variant="contained"
                >
                  Login with UBC CWL
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withCookies(Landing));
