import React, { useState, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import "./MainText.css";
import { connect } from "react-redux";
import { getMainText } from "../../../../actions/KORN351/Lessons";
import img1 from "./1.png";
import img2 from "./2.png";
import img3 from "./3.png";
import img4 from "./4.png";
import img6 from "./6.png";
import img7 from "./7.png";
import img8 from "./8.png";
import img9 from "./9.png";
import img11 from "./11.png";
import img12 from "./12.png";
import img13 from "./13.png";
import img14 from "./14.png";
import img16 from "./16.png";
import img17 from "./17.png";
import img18 from "./18.png";
import img19 from "./19.png";

import { withRouter } from "react-router-dom";
import {
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
} from "@material-ui/core";
import TextTooltip from "../TextTooltip/TextTooltip";
import axios from "axios";

const MainText = (props) => {
  const [currentText, switchText] = useState("mainText");
  const [showVocab, setShowVocab] = useState(false);
  const [vocab, setVocab] = useState("");
  const currLesson = props.match.params.lesson;
  const { mainText, subText, exampleSentences, koreanText, title } = props;

  useEffect(() => {
    axios
      .get("/api/lesson/getNewVocab", { params: { lesson: currLesson } })
      .then((resp) => {
        if (resp.data.length !== 0) {
          const data = resp.data[0];
          setVocab(data);
        }
      });
  }, [currLesson]);

  const renderWithToggled = (text, type) => {
    let vocabs = [];
    const list = type === "mainText" ? vocab.mainText : vocab.exSentences;
    for (let i = 0; i < list.length; i++) {
      let temp = list[i].kor.split("(")[0];
      if (temp !== null && text.indexOf(temp) > -1) {
        temp = temp.trim();
        if (temp !== "") vocabs.push(temp);
      }
    }

    if (vocabs.length > 0) {
      let tempText = text;
      vocabs.forEach((v) => {
        tempText = tempText.replaceAll(v, "@@" + v + "@@");
      });
      let tempSearchWordArray = tempText.split("@@"); // i.e., ['...', '호랑이', '...', '출판사']
      let childComponent = (
        <span>
          {tempSearchWordArray.map((text) => {
            const shouldHighlight = vocabs.indexOf(text) > -1 && text !== "";
            const data = list.find((v) => text.includes(v.kor.split("(")[0]));

            return shouldHighlight && data ? (
              <TextTooltip text={text} data={data} />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: text }} />
            );
          })}
        </span>
      );

      return (
        <div className="storytext-paragraph-parent">
          {React.createElement(
            "p",
            { className: "storytext-paragraph", id: "text-paragraph" },
            childComponent,
          )}
        </div>
      );
    } else {
      let childComponent = <span dangerouslySetInnerHTML={{ __html: text }} />;
      return (
        <div className="storytext-paragraph-parent">
          {React.createElement(
            "p",
            { className: "storytext-paragraph", id: "text-paragraph" },
            childComponent,
          )}
        </div>
      );
    }
  };

  // TODO: how to use images for new lessons? ask maintainers to manually add images to the folder?
  let image = img1;
  if (currLesson === "2") image = img2;
  if (currLesson === "3") image = img3;
  if (currLesson === "4") image = img4;
  if (currLesson === "6") image = img6;
  if (currLesson === "7") image = img7;
  if (currLesson === "8") image = img8;
  if (currLesson === "9") image = img9;
  if (currLesson === "11") image = img11;
  if (currLesson === "12") image = img12;
  if (currLesson === "13") image = img13;
  if (currLesson === "14") image = img14;
  if (currLesson === "16") image = img16;
  if (currLesson === "17") image = img17;
  if (currLesson === "18") image = img18;
  if (currLesson === "19") image = img19;
  if (currLesson === "5" || currLesson === "10" || currLesson === "15")
    image = "";

  // https://stackoverflow.com/questions/46534376/javascript-convert-arabic-numerals-to-chinese-characters
  // Accessed Oct 17, 2021
  function toChineseNumber(n) {
    if (!Number.isInteger(n) && n < 0) {
      throw Error("请输入自然数");
    }

    const digits = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
    const positions = [
      "",
      "十",
      "百",
      "千",
      "万",
      "十万",
      "百万",
      "千万",
      "亿",
      "十亿",
      "百亿",
      "千亿",
    ];
    const charArray = String(n).split("");
    let result = "";
    let prevIsZero = false;
    //处理0  deal zero
    for (let i = 0; i < charArray.length; i++) {
      const ch = charArray[i];
      if (ch !== "0" && !prevIsZero) {
        result += digits[parseInt(ch)] + positions[charArray.length - i - 1];
      } else if (ch === "0") {
        prevIsZero = true;
      } else if (ch !== "0" && prevIsZero) {
        result +=
          "零" + digits[parseInt(ch)] + positions[charArray.length - i - 1];
      }
    }
    //处理十 deal ten
    if (n < 100) {
      result = result.replace("一十", "十");
    }
    return result;
  }

  return (
    <Grid container>
      <Grid item md={1} />
      <Grid item xs={12} md={10}>
        <div
          className="col-lg-12 context engVer"
          style={{ paddingBottom: "48px" }}
          id="theHeader"
        >
          <div className="main-text-story-header">
            <h3 className="main-text-title">
              第 {toChineseNumber(currLesson)} 課
            </h3>
            <h3 className="main-text-title">{title}</h3>
          </div>
          <Divider style={{ marginBottom: "0.5rem" }} />
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="primary"
                    checked={currentText !== "mainText"}
                    onChange={() =>
                      currentText === "mainText"
                        ? switchText("koreanText")
                        : switchText("mainText")
                    }
                  />
                }
                label="Disable Main Text"
                labelPlacement="start"
              />
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="primary"
                    checked={showVocab}
                    onChange={() => setShowVocab(!showVocab)}
                  />
                }
                label="Show Vocab"
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
          <div className="main-text">
            <img className="img" src={image} alt="main-text" />
            {showVocab && (vocab || []).mainText ? (
              renderWithToggled(mainText, "mainText")
            ) : (
              <span
                className="text"
                dangerouslySetInnerHTML={{
                  __html: currentText === "mainText" ? mainText : koreanText,
                }}
              />
            )}
          </div>
          <br />
          <div>
            <b>From the Example Sentences:</b>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {exampleSentences.map((obj) => {
                return (
                  <div key={obj.num} style={{ marginTop: 14 }}>
                    <b>{obj.num}</b> <br />
                    <br />{" "}
                    {obj.sentences.map((sentence) => {
                      return showVocab && (vocab || []).exSentences ? (
                        renderWithToggled(sentence, "exSentences")
                      ) : (
                        <p key={sentence}>{sentence}</p>
                      );
                    })}{" "}
                    <br />{" "}
                  </div>
                );
              })}
            </div>
          </div>
          {subText && subText.subHeading && subText.content ? (
            <div>
              <b>{subText.subHeading}:</b>
              <br />
              <div style={{ margin: "3%" }}>{subText.content}</div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    mainText: state.lessons.mainText,
    subText: state.lessons.subText,
    exampleSentences: state.lessons.exampleSentences,
    title: state.lessons.title,
  };
};

export default withRouter(connect(mapStateToProps, { getMainText })(MainText));
