import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Modal from "@material-ui/core/Modal";
import FlashCardContainer from "../../components/FlashCard/FlashCardsContainer";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import "./Quizzes.css";
import { withCookies } from "react-cookie";
import { getPhonetics, getRadicals } from "../../../actions/KORN351/Okpyeon";
import {
  getNewHanja,
  getNewHanjaCombos,
  getAllHanjaCombos,
  getPracticeSentences,
  getNumStories,
} from "../../../actions/KORN351/Lessons";
import { findDiff } from "../../../utils";
import { FormControl, Select, MenuItem } from "@material-ui/core";

class Quizzes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      primaryQuestionList: [],
      isPracticeSentence: false,
      quizTopic: "",
      lesson: null,
      newCombosType: "new-combo-eng",
      allCombosType: "all-combo-eng",
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.newHanja.length === 0) this.props.getNewHanja();
    if (this.props.phonetics.length === 0) this.props.getPhonetics();
    if (this.props.radicals.length === 0) this.props.getRadicals();
    if (this.props.newHanjaCombos.length === 0) this.props.getNewHanjaCombos();
    if (this.props.allHanjaCombos.length === 0) this.props.getAllHanjaCombos();
    if (this.props.pracSentences.length === 0)
      this.props.getPracticeSentences();
    if (this.props.numStories === 0) this.props.getNumStories();
  }

  handleClose = () => {
    this.setState({
      showModal: false,
      primaryQuestionList: [],
      secondaryQuestionList: [],
      quizTopic: "",
    });
  };

  openModal = (topic, lesson) => {
    let quizTopic = topic;
    const {
      newHanja,
      phonetics,
      radicals,
      newHanjaCombos,
      allHanjaCombos,
      pracSentences,
    } = this.props;
    let temp = false;
    let primQuestionList = [];

    if (topic === "new-chars") {
      newHanja.forEach((char) => {
        if (char.lesson.toString() === lesson)
          primQuestionList.push({
            _id: char._id,
            question:
              "Choose the Hanja below that matches the 訓 (훈): " +
              findDiff(char.hoonEum, char.eum),
            answer: char.hanja,
          });
      });
    } else if (topic === "rad") {
      radicals.forEach((rad) => {
        if (rad.lesson.toString() === lesson)
          primQuestionList.push({
            _id: rad._id,
            question: rad.hoonEum
              ? `Choose the Busu below that matches the 訓 (훈) + 音 (음): <br /><b style="color: blue">${rad.hoonEum}</b>`
              : `Choose the 부수 that matches the radical name: <br /><b style="color: blue">${rad.radicalName}</b>`,
            answer: rad.radical,
          });
      });
    } else if (topic === "phon") {
      phonetics.forEach((phon) => {
        if ((phon.lesson || "").toString() === lesson)
          primQuestionList.push({
            _id: phon._id,
            question: phon.pronunciation,
            answer: phon.phonetic,
          });
      });
    } else if (topic === "new-combo-kor") {
      newHanjaCombos.forEach((combo) => {
        if (lesson === "0") {
          // Build Cumulative
          primQuestionList.push({
            _id: combo._id,
            question: combo.hanja + ": ",
            answer: combo.kor,
          });
        } else {
          if (combo.lesson === lesson)
            primQuestionList.push({
              _id: combo._id,
              question: combo.hanja + ": ",
              answer: combo.kor,
            });
        }
      });
    } else if (topic === "new-combo-eng") {
      newHanjaCombos.forEach((combo) => {
        if (lesson === "0") {
          // Build Cumulative
          primQuestionList.push({
            _id: combo._id,
            question: combo.hanja + ": ",
            answer: combo.eng,
          });
        } else {
          if (combo.lesson === lesson)
            primQuestionList.push({
              _id: combo._id,
              question: combo.hanja + ": ",
              answer: combo.eng,
            });
        }
      });
    } else if (topic === "all-combo-kor" || topic === "all-combo-eng") {
      primQuestionList = (
        lesson === "0"
          ? allHanjaCombos
          : allHanjaCombos.filter((combo) => combo.lesson.toString() === lesson)
      ).map((combo) => ({
        _id: combo._id,
        question: combo.hanqca + ": ",
        answer: topic === "all-combo-kor" ? combo.hankul : combo.englishGloss,
      }));
    } else if (topic === "prac-sent") {
      temp = true;
      if (lesson === "0")
        pracSentences.forEach((sentence) => {
          primQuestionList.push({
            _id: sentence._id,
            question: sentence.question,
            answer: sentence.answer,
          });
        });
      else
        pracSentences.forEach((sentence) => {
          if (sentence.lesson === lesson)
            primQuestionList.push({
              _id: sentence._id,
              question: sentence.question,
              answer: sentence.answer,
            });
        });
    }

    this.setState({
      primaryQuestionList: primQuestionList,
      quizTopic,
      lesson,
      showModal: true,
      isPracticeSentence: temp,
    });
  };

  render() {
    const {
      primaryQuestionList,
      isPracticeSentence,
      quizTopic,
      showModal,
      lesson,
      newCombosType,
      allCombosType,
    } = this.state;
    const lessonIterArr = [...Array(this.props.numStories)].map(
      (x, i) => i + 1,
    );

    return (
      <Grid container>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={showModal}
        >
          <FlashCardContainer
            onClose={this.handleClose}
            primaryQuestionList={primaryQuestionList}
            isPracticeSentence={isPracticeSentence}
            quizTopic={quizTopic}
            lesson={lesson}
          />
        </Modal>
        <Grid item md={1} />
        <Grid item xs={12} md={10}>
          <div
            className="col-lg-12 context engVer"
            style={{ paddingBottom: "48px" }}
            id="theHeader"
          >
            <div
              className={"storyHeader"}
              style={{ display: "flex", width: "100%" }}
            >
              <h3 style={{ textAlign: "left", width: "50%" }}>Quizzes</h3>
            </div>
            <Divider style={{ marginBottom: "0.5rem" }} />
            <br />
            <h5>New Characters</h5>
            <br />
            <div className="flashcard-deck-container">
              {lessonIterArr.map((num) => (
                <Card
                  key={num}
                  variant="outlined"
                  className="flashcard-deck-card"
                  onClick={() => {
                    this.openModal("new-chars", num.toString());
                  }}
                >
                  <CardContent>Lesson {num}</CardContent>
                </Card>
              ))}
            </div>
            <br />
            <br />
            <br />
            <br />
            <h5>Radicals</h5>
            <br />
            <div className="flashcard-deck-container">
              {lessonIterArr.map((num) => (
                <Card
                  key={num}
                  variant="outlined"
                  className="flashcard-deck-card"
                  onClick={() => {
                    this.openModal("rad", num.toString());
                  }}
                >
                  <CardContent>Lesson {num}</CardContent>
                </Card>
              ))}
            </div>
            <br />
            <br />
            <br />
            <br />

            <h5>Phonetics</h5>
            <br />
            <div className="flashcard-deck-container">
              {lessonIterArr.map((num) => (
                <Card
                  key={num}
                  variant="outlined"
                  className="flashcard-deck-card"
                  onClick={() => {
                    this.openModal("phon", num.toString());
                  }}
                >
                  <CardContent>Lesson {num}</CardContent>
                </Card>
              ))}
            </div>
            <br />
            <br />
            <br />
            <br />

            <div className="flashcard-header">
              <h5>New Combos Vocab</h5>
              <FormControl className="flashcard-header-select">
                <Select
                  labelId="demo-simple-select-label"
                  value={newCombosType}
                  label={
                    newCombosType === "new-combo-kor"
                      ? "Hanja vs. Korean"
                      : "Hanja vs. English"
                  }
                  onChange={(e) =>
                    this.setState({ newCombosType: e.target.value })
                  }
                >
                  <MenuItem value="new-combo-kor">Hanja vs. Korean</MenuItem>
                  <MenuItem value="new-combo-eng">Hanja vs. English</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flashcard-deck-container">
              {lessonIterArr.map((num) => (
                <Card
                  key={num}
                  variant="outlined"
                  className="flashcard-deck-card"
                  onClick={() => {
                    this.openModal(newCombosType, num.toString());
                  }}
                >
                  <CardContent>
                    {num === 0 ? "Cumulative" : "Lesson " + num}
                  </CardContent>
                </Card>
              ))}
            </div>
            <br />
            <br />
            <br />
            <br />

            <div className="flashcard-header">
              <h5>All Combos Vocab</h5>
              <FormControl className="flashcard-header-select">
                <Select
                  labelId="demo-simple-select-label"
                  value={allCombosType}
                  label={
                    allCombosType === "all-combo-kor"
                      ? "Hanja vs. Korean"
                      : "Hanja vs. English"
                  }
                  onChange={(e) =>
                    this.setState({ allCombosType: e.target.value })
                  }
                >
                  <MenuItem value="all-combo-kor">Hanja vs. Korean</MenuItem>
                  <MenuItem value="all-combo-eng">Hanja vs. English</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flashcard-deck-container">
              {lessonIterArr.map((num) => (
                <Card
                  key={num}
                  variant="outlined"
                  className="flashcard-deck-card"
                  onClick={() => {
                    this.openModal(allCombosType, num.toString());
                  }}
                >
                  <CardContent>
                    {num === 0 ? "Cumulative" : "Lesson " + num}
                  </CardContent>
                </Card>
              ))}
            </div>
            <br />
            <br />
            <br />
            <br />

            <h5>Practice Sentences</h5>
            <br />
            <div className="flashcard-deck-container">
              {lessonIterArr.map((num) => (
                <Card
                  key={num}
                  variant="outlined"
                  className="flashcard-deck-card"
                  onClick={() => {
                    this.openModal("prac-sent", num.toString());
                  }}
                >
                  <CardContent>
                    {num === 0 ? "Cumulative" : "Lesson " + num}
                  </CardContent>
                </Card>
              ))}
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    phonetics: state.okpyeon.phonetics,
    newHanja: state.lessons.newHanja,
    radicals: state.okpyeon.radicals,
    newHanjaCombos: state.lessons.newHanjaCombos,
    allHanjaCombos: state.lessons.allHanjaCombos,
    pracSentences: state.lessons.pracSentences,
    numStories: state.lessons.numStories,
  };
};

const mapDispatchToProps = {
  getPhonetics,
  getRadicals,
  getNewHanja,
  getNewHanjaCombos,
  getAllHanjaCombos,
  getPracticeSentences,
  getNumStories,
};

export default withCookies(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Quizzes)),
);
