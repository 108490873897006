import React from "react";
import "./style/navbar.css";

const Footer = () => {
  return (
    <footer className="footer" style={{ zIndex: 999 }}>
      Copyright &copy; {new Date().getFullYear()} Interline Reader
    </footer>
  );
};

Footer.displayName = "Footer";

export default Footer;
