import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PlusIcon from "@material-ui/icons/Add";
// import DeleteIcon from '@material-ui/icons/Delete';
import {
  createLesson,
  deleteLesson,
  getNumStories,
} from "../../../../../actions/KORN351/Lessons";
import "./StoryList.css";
const styles = {
  cardContainer: {
    padding: "18px",
  },
};

class LessonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storyList: [],
    };
  }

  async componentDidMount() {
    const { getNumStories } = this.props;

    await getNumStories();
    this.setState({
      storyList: Array.from(Array(this.props.numStories).keys()).map(
        (x) => x + 1,
      ),
    });
  }

  renderClassStories = (lesson, classIndex) => {
    const { _classes, match } = this.props;
    return (
      <div style={{ padding: "0.5% 3%" }} key={classIndex}>
        <ul>
          <li>
            {match.url.includes("editLesson") ? (
              <Link
                key={lesson}
                to={`/instructor351/editLesson/${lesson}`}
                className={"card-link"}
              >
                Lesson {lesson}
              </Link>
            ) : (
              <Link
                to={`/instructor351/editOkpyeon/${lesson}`}
                className={"card-link"}
              >
                Lesson {lesson}
              </Link>
            )}
          </li>
        </ul>
      </div>
    );
  };

  createNewLesson = async () => {
    await createLesson();
    const newLessonNumber = this.props.numStories + 1;
    alert(
      `Created new Lesson ${newLessonNumber}\nYou will be redirected to edit the new lesson.`,
    );
    this.props.history.push({
      pathname: `/instructor351/editLesson/${newLessonNumber}`,
    });
  };

  deleteLatestLesson = async () => {
    const confirm = window.confirm(
      `Are you sure you want to delete the latest Lesson ${this.props.numStories}?`,
    );
    if (confirm) {
      await deleteLesson(this.props.numStories);
      this.setState({
        storyList: this.state.storyList.slice(0, -1),
      });
    }
  };

  render() {
    return (
      <div className="class-list" style={{ padding: "3%" }}>
        <h3>Lesson List</h3>
        {this.props.match.url.includes("editLesson") ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "10%",
              marginBottom: "1em",
            }}
          >
            <Tooltip title="Create New Lesson" aria-label="Create New Lesson">
              <IconButton
                size="medium"
                onClick={this.createNewLesson}
                style={{ backgroundColor: "#3f51b5", color: "white" }}
              >
                <PlusIcon />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Delete Latest Lesson" aria-label="Delete Latest Lesson">
              <IconButton size="medium" onClick={this.deleteLatestLesson} style={{backgroundColor: '#f50057', color: 'white'}}>
                <DeleteIcon/>
              </IconButton>
            </Tooltip> */}
          </div>
        ) : null}
        <ul>
          {this.state.storyList.map((lesson, classIndex) => {
            return this.renderClassStories(lesson, classIndex);
          })}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  numStories: state.lessons.numStories,
});

const mapDispatchToProps = {
  getNumStories,
};

// Combine them using compose
export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(LessonList);
