import React, { Component } from "react";
import { connect } from "react-redux";
import "./style/about.css";
import NewAbout from "./components/NewAbout";

class AboutContainer extends Component {
  state = {
    applicationDescription: "",
  };

  render() {
    return <NewAbout />;
  }
}

const mapStateToProps = (_state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AboutContainer);
