import {
  getMainText,
  getAboutNewBusu,
  getNewHanjaCombos,
  getNewVocabulary,
  getNewHanja,
} from "./Lessons";

import { getCharacters, getPhonetics, getRadicals } from "./Okpyeon";

import axios from "axios";

export const saveMainText = (lesson, mainText) => (_dispatch) => {
  return new Promise((resolve, _reject) => {
    axios
      .put("/api/story/saveMainText", {
        params: { lesson: lesson, mainText: mainText },
      })
      .then((_resp) => {
        alert("Success");
        getMainText(lesson);
        resolve();
      });
  });
};

export const saveTitle = (lesson, title) => (_dispatch) => {
  return axios
    .put("/api/story/saveTitle", { params: { lesson, title } })
    .then((_resp) => {
      alert("Success");
      return getMainText(lesson);
    });
};

export const saveExampleSentence = (lesson, exSentences) => (_dispatch) => {
  return new Promise((resolve, _reject) => {
    axios
      .put("/api/story/saveExampleSentence", {
        params: { lesson: lesson, exSentences: exSentences },
      })
      .then((_resp) => {
        alert("Success");
        getMainText(lesson);
        resolve();
      });
  });
};

export const addNewExampleSentence = (lesson, exSentences) => (_dispatch) => {
  console.log(lesson, exSentences);
  return new Promise((resolve, _reject) => {
    axios
      .put("/api/story/addNewExampleSentence", {
        params: { lesson: lesson, exSentences: exSentences },
      })
      .then((_resp) => {
        alert("Success");
        getMainText(lesson);
        resolve();
      });
  });
};

export const deleteExampleSentence = (lesson, id) => (_dispatch) => {
  return new Promise((resolve, _reject) => {
    axios
      .put("/api/story/deleteExampleSentence", {
        params: { lesson: lesson, id: id },
      })
      .then((_resp) => {
        // alert("Success");
        getMainText(lesson);
        resolve();
      });
  });
};

export const saveOthers = (lesson, subHeading, content) => (_dispatch) => {
  return new Promise((resolve, _reject) => {
    axios
      .put("/api/story/saveOthers", {
        params: { lesson: lesson, subHeading: subHeading, content: content },
      })
      .then((_resp) => {
        alert("Success");
        getMainText(lesson);
        resolve();
      });
  });
};

export const saveAboutNewBusu =
  (lesson, id, word, def, busu, descr, examples) => (_dispatch) => {
    return new Promise((resolve, _reject) => {
      axios
        .put("/api/instructor351/saveAboutNewBusu", {
          params: {
            lesson: lesson,
            id: id,
            word: word,
            def: def,
            busu: busu,
            descr: descr,
            examples: examples,
          },
        })
        .then((_resp) => {
          getAboutNewBusu();
          alert("Success");
          resolve();
        });
    });
  };
export const deleteAboutNewBusu = (lesson, id) => (_dispatch) => {
  return new Promise((resolve, _reject) => {
    axios
      .put("/api/instructor351/deleteAboutNewBusu", {
        params: { lesson: lesson, id: id },
      })
      .then((_resp) => {
        getAboutNewBusu();
        alert("Success");
        resolve();
        window.location.reload();
      });
  });
};

export const saveNewPhonetic =
  (
    lesson,
    id,
    phonetic,
    pronunciation,
    characters,
    subPronunciation,
    subCharacters,
  ) =>
  (_dispatch) => {
    return new Promise((resolve, _reject) => {
      axios
        .put("/api/instructor351/saveNewPhonetic", {
          params: {
            lesson: parseInt(lesson),
            id: id,
            phonetic: phonetic,
            pronunciation: pronunciation,
            characters: characters,
            subPronunciation: subPronunciation,
            subCharacters: subCharacters,
          },
        })
        .then((_resp) => {
          getPhonetics();
          alert("Success");
          resolve();
        });
    });
  };
export const deleteNewPhonetic = (lesson, id) => (_dispatch) => {
  return new Promise((resolve, _reject) => {
    axios
      .put("/api/instructor351/deleteNewPhonetic", {
        params: { lesson: parseInt(lesson), id: id },
      })
      .then((_resp) => {
        getPhonetics();
        alert("Success");
        resolve();
        window.location.reload();
      });
  });
};

export const saveNewHanjaCombo =
  (lesson, id, hanja, kor, eng) => (_dispatch) => {
    return new Promise((resolve, _reject) => {
      axios
        .put("/api/instructor351/saveNewHanjaCombo", {
          params: { lesson: lesson, id: id, hanja: hanja, kor: kor, eng: eng },
        })
        .then((_resp) => {
          getNewHanjaCombos();
          alert("Success");
          resolve();
        });
    });
  };
export const deleteNewHanjaCombo =
  (lesson, id, _hanja, _kor, _eng) => (_dispatch) => {
    return new Promise((resolve, _reject) => {
      axios
        .put("/api/instructor351/deleteNewHanjaCombo", {
          params: { lesson: lesson, id: id },
        })
        .then((_resp) => {
          getNewHanjaCombos();
          alert("Success");
          resolve();
          window.location.reload();
        });
    });
  };

export const saveSideBarVocab =
  (lesson, mainText, exSentences) => (_dispatch) => {
    return new Promise((resolve, _reject) => {
      axios
        .put("/api/instructor351/saveSideBarVocab", {
          lesson: lesson,
          mainText: mainText,
          exSentences: exSentences,
        })
        .then((_resp) => {
          getNewVocabulary(parseInt(lesson));
          alert("Success");
          resolve();
        });
    });
  };

export const saveHanjaCharacter =
  (
    lesson,
    id,
    additionalHoonMeaning,
    characterStrokeCount,
    eum,
    hanja,
    hoonEum,
    meaning,
    phonetic,
    primaryHoonMeaning,
    radical,
    radicalMeaning,
    radicalName,
    radicalStrokeCount,
    totalStrokeCount,
  ) =>
  (_dispatch) => {
    return new Promise((resolve, _reject) => {
      axios
        .put("/api/instructor351/saveHanjaCharacter", {
          lesson: parseInt(lesson),
          id: id,
          additionalHoonMeaning: additionalHoonMeaning,
          characterStrokeCount: characterStrokeCount,
          eum: eum,
          hanja: hanja,
          hoonEum: hoonEum,
          meaning: meaning,
          phonetic: phonetic,
          primaryHoonMeaning: primaryHoonMeaning,
          radical: radical,
          radicalMeaning: radicalMeaning,
          radicalName,
          radicalStrokeCount: radicalStrokeCount,
          totalStrokeCount: totalStrokeCount,
        })
        .then((_resp) => {
          getNewHanja();
          alert("Success");
          resolve();
          window.location.reload();
        });
    });
  };

export const deleteHanjaCharacter = (lesson, id) => (_dispatch) => {
  return new Promise((resolve, _reject) => {
    axios
      .put("/api/instructor351/deleteHanjaCharacter", {
        lesson: parseInt(lesson),
        id: id,
      })
      .then((_resp) => {
        getNewHanja();
        resolve();
        window.location.reload();
      });
  });
};

export const saveCharacter =
  (
    lesson,
    id,
    additionalHoonMeaning,
    characterStrokeCount,
    eum,
    hanja,
    hoonEum,
    meaning,
    phonetic,
    primaryHoonMeaning,
    radical,
    radicalHangul,
    radicalStrokeCount,
    totalStrokeCount,
  ) =>
  (_dispatch) => {
    return new Promise((resolve, _reject) => {
      axios
        .put("/api/instructor351/saveCharacter", {
          params: {
            lesson: parseInt(lesson),
            id: id,
            additionalHoonMeaning: additionalHoonMeaning,
            characterStrokeCount: characterStrokeCount,
            eum: eum,
            hanja: hanja,
            hoonEum: hoonEum,
            meaning: meaning,
            phonetic: phonetic,
            primaryHoonMeaning: primaryHoonMeaning,
            radical: radical,
            radicalHangul: radicalHangul,
            radicalStrokeCount: radicalStrokeCount,
            totalStrokeCount: totalStrokeCount,
          },
        })
        .then((_resp) => {
          getCharacters();
          alert("Success");
          resolve();
        });
    });
  };
export const deleteCharacter = (lesson, id) => (_dispatch) => {
  return new Promise((resolve, _reject) => {
    axios
      .put("/api/instructor351/deleteCharacter", {
        params: { lesson: parseInt(lesson), id: id },
      })
      .then((_resp) => {
        getCharacters();
        alert("Success");
        resolve();
        window.location.reload();
      });
  });
};

export const savePhonetic =
  (
    lesson,
    id,
    characters,
    phonetic,
    pronunciation,
    sub_pronunciation_characters,
  ) =>
  (_dispatch) => {
    return new Promise((resolve, _reject) => {
      axios
        .put("/api/instructor351/savePhonetic", {
          lesson: parseInt(lesson),
          id: id,
          characters: characters,
          phonetic: phonetic,
          pronunciation: pronunciation,
          sub_pronunciation_characters,
        })
        .then((_resp) => {
          getPhonetics();
          alert("Success");
          resolve();
        });
    });
  };
export const deletePhonetic = (lesson, id) => (_dispatch) => {
  return new Promise((resolve, _reject) => {
    axios
      .put("/api/instructor351/deletePhonetic", {
        lesson: parseInt(lesson),
        id: id,
      })
      .then((_resp) => {
        getCharacters();
        alert("Success");
        resolve();
        window.location.reload();
      });
  });
};

export const saveRadical =
  (
    lesson,
    id,
    additionalHoonMeaning,
    hoonEum,
    meaning,
    primaryHoonMeaning,
    radical,
    radicalHangul,
    phonetic,
    radicalStrokeCount,
  ) =>
  (_dispatch) => {
    return new Promise((resolve, _reject) => {
      axios
        .put("/api/instructor351/saveRadical", {
          lesson: parseInt(lesson),
          id: id,
          additionalHoonMeaning: additionalHoonMeaning,
          phonetic,
          hoonEum: hoonEum,
          meaning: meaning,
          primaryHoonMeaning: primaryHoonMeaning,
          radical: radical,
          radicalHangul: radicalHangul,
          radicalStrokeCount: radicalStrokeCount,
        })
        .then((_resp) => {
          getRadicals();
          alert("Success");
          resolve();
        });
    });
  };
export const deleteRadical = (lesson, id) => (_dispatch) => {
  return new Promise((resolve, _reject) => {
    axios
      .put("/api/instructor351/deleteRadical", {
        lesson: parseInt(lesson),
        id: id,
      })
      .then((_resp) => {
        getRadicals();
        alert("Success");
        resolve();
        window.location.reload();
      });
  });
};
