import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import "./UserManagement.css";

export default function UserManagement() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios.get("/api/users").then((resp) => {
      setUsers(resp.data);
    });
  }, []);

  function handleChange(userId, event) {
    const { value } = event.target;
    axios.put("/api/users/update-role", { role: value, userId }).then((_) => {
      setUsers(
        users.map((user) => {
          if (user._id === userId) {
            user.role = value;
          }
          return user;
        }),
      );
    });
  }

  return (
    <div className="ir-UserManagement">
      <ul>
        <li>
          <span className="ir-UserManagement-header">Name</span>
          <span className="ir-UserManagement-header">Email</span>
          <span className="ir-UserManagement-header">Role</span>
        </li>
        {users.map((user) => (
          <li key={user._id}>
            <span className="ir-UserManagement-meta">
              {user.name}&nbsp;&nbsp;
            </span>
            <span className="ir-UserManagement-meta">
              {user.email}&nbsp;&nbsp;
            </span>
            <FormControl style={{ width: 250 }}>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={user.role}
                label="Role"
                onChange={(event) => handleChange(user._id, event)}
              >
                <MenuItem value={"STUDENT"}>Student</MenuItem>
                <MenuItem value={"INSTRUCTOR"}>Instructor</MenuItem>
                <MenuItem value={"ADMIN"}>Admin</MenuItem>
              </Select>
            </FormControl>
          </li>
        ))}
      </ul>
    </div>
  );
}
