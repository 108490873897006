import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./AboutNewPhonetics.css";
import NavigatingButtons from "../../components/Lessons/NavigatingButtons/NavigatingButtons";
import { getAboutNewBusu } from "../../../actions/KORN351/Lessons";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import "./AboutNewBusu.css";

class AboutNewBusu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutNewBusu: [],
      currentLesson: this.props.match.params.lesson,
    };
  }

  UNSAFE_componentWillMount() {
    if (
      this.props.aboutNewBusu.length === 0 ||
      this.props.aboutNewBusu === undefined
    ) {
      this.props.getAboutNewBusu().then(() => {
        const currLesson = this.props.match.params.lesson;

        let temp = this.props.aboutNewBusu.filter((word) => {
          return word.lesson === currLesson;
        });
        this.setState({
          aboutNewBusu: temp,
        });
      });
    }
  }

  componentDidMount() {
    if (this.state.aboutNewBusu.length === 0) {
      const currLesson = this.props.match.params.lesson;

      let temp = this.props.aboutNewBusu.filter((word) => {
        return word.lesson === currLesson;
      });
      this.setState({
        aboutNewBusu: temp,
      });
    }
  }

  render() {
    const { aboutNewBusu } = this.state;

    return (
      <div className="about-new-busu">
        <div
          className="col-lg-12 context engVer"
          style={{ paddingBottom: "48px" }}
          id="theHeader"
        >
          <div
            className={"storyHeader"}
            style={{ display: "flex", width: "100%" }}
          >
            <h3 style={{ textAlign: "left", width: "50%" }}>
              새 부수에 대하여
            </h3>
          </div>
          <div>
            <h4 style={{ textAlign: "left", width: "50%" }}>
              제 {this.state.currentLesson} 과
            </h4>
          </div>
          <Divider style={{ marginBottom: "0.5rem" }} />
          <Grid container>
            {aboutNewBusu
              .sort((a, b) => a.word.localeCompare(b.word, "ko-KR"))
              .map((busu) => {
                return (
                  <Grid key={busu.word} item xs={12} md={6} lg={6}>
                    <Card variant="outlined" style={{ padding: "2%" }}>
                      <CardContent
                        className="phonetics-card-content"
                        style={{ maxHeight: "350px", overflowY: "auto" }}
                      >
                        <Typography variant="h5" component="h2">
                          {busu.word} &nbsp;&nbsp; | &nbsp;&nbsp; {busu.def}
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                          부수: {busu.busu}
                        </Typography>
                        <br />
                        <Typography variant="body2">
                          {busu.description}
                        </Typography>
                        <br />
                        <br />
                        {busu.examples &&
                          busu.examples
                            .sort((a, b) =>
                              a.word.localeCompare(b.word, "ko-KR"),
                            )
                            .map((b) => {
                              return b.word !== "0" && b.def !== "0" ? (
                                <div key={b.word} style={{ margin: "2%" }}>
                                  {b.word} : {b.def}
                                </div>
                              ) : null;
                            })}
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </div>
        <NavigatingButtons />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    aboutNewBusu: state.lessons.aboutNewBusu,
  };
};

export default withRouter(
  connect(mapStateToProps, { getAboutNewBusu })(AboutNewBusu),
);
