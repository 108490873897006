import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Modal,
  Tab,
  Tabs,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
} from "@material-ui/core";
import FlashCardContainer from "../../KORN351/components/FlashCard/FlashCardsContainer";
import { connect } from "react-redux";
import { getPhonetics, getRadicals } from "../../actions/KORN351/Okpyeon";
import {
  getNewHanja,
  getNewHanjaCombos,
  getPracticeSentences,
} from "../../actions/KORN351/Lessons";
import { QUIZ_TOPIC_MAP } from "../../config";
import { stringAvatar, findDiff } from "../../utils";
import TabPanel, { a11yProps } from "../common/TabPanel";
import StoreAccessor from "../../utils/localStore";
import RemoveIcon from "@material-ui/icons/DeleteOutline";
import axios from "axios";
import _ from "lodash";

import "./Profile.css";

const savedVocabsStore = new StoreAccessor("351savedVocabs");
function Profile(props) {
  const [primaryQuestionList, setPrimaryQuestionList] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [shouldShowModal, showModal] = useState(false);
  const [quizTopic, setQuizTopic] = useState("");
  const [lesson, setLesson] = useState(null);
  const [isPracticeSentence, setIsPractiseSentence] = useState(false);
  const [vocabs, setVocabs] = useState([]);

  useEffect(() => {
    if (props.newHanja.length === 0) props.getNewHanja();
    if (props.phonetics.length === 0) props.getPhonetics();
    if (props.radicals.length === 0) props.getRadicals();
    if (props.newHanjaCombos.length === 0) props.getNewHanjaCombos();
    if (props.pracSentences.length === 0) props.getPracticeSentences();
    getSavedVocabs();
  }, [props]);

  const openModal = (topic, _lesson, _isSaved = false) => {
    let _quizTopic = topic;
    const { newHanja, phonetics, radicals, newHanjaCombos, pracSentences } =
      props;
    let _isPractiseQuestion = false;
    let primQuestionList = [];

    if (topic === "new-chars") {
      newHanja.forEach((char) => {
        if (char.lesson.toString() === _lesson)
          primQuestionList.push({
            _id: char._id,
            question:
              "Choose the Hanja below that matches the 訓 (훈): " +
              findDiff(char.hoonEum, char.eum),
            answer: char.hanja,
          });
      });
    } else if (topic === "rad") {
      radicals.forEach((rad) => {
        if (rad.lesson.toString() === _lesson)
          primQuestionList.push({
            _id: rad._id,
            question: rad.radicalHangul,
            answer: rad.radical,
          });
      });
    } else if (topic === "phon") {
      phonetics.forEach((phon) => {
        if (phon.lesson.toString() === _lesson)
          primQuestionList.push({
            _id: phon._id,
            question: phon.pronunciation,
            answer: phon.phonetic,
          });
      });
    } else if (topic === "new-combo-kor") {
      newHanjaCombos.forEach((combo) => {
        if (lesson === "0") {
          // Build Cumulative
          primQuestionList.push({
            _id: combo._id,
            question: combo.hanja + ": ",
            answer: combo.kor,
          });
        } else {
          if (combo.lesson === lesson)
            primQuestionList.push({
              _id: combo._id,
              question: combo.hanja + ": ",
              answer: combo.kor,
            });
        }
      });
    } else if (topic === "new-combo-eng") {
      newHanjaCombos.forEach((combo) => {
        if (lesson === "0") {
          // Build Cumulative
          primQuestionList.push({
            _id: combo._id,
            question: combo.hanja + ": ",
            answer: combo.eng,
          });
        } else {
          if (combo.lesson === lesson)
            primQuestionList.push({
              _id: combo._id,
              question: combo.hanja + ": ",
              answer: combo.eng,
            });
        }
      });
    } else if (topic === "all-combo-kor") {
      if (lesson === "0")
        newHanjaCombos.forEach((combo) => {
          // Build Cumulative
          if (combo.isAllCombo)
            primQuestionList.push({
              _id: combo._id,
              question: combo.hanja + ": ",
              answer: combo.kor,
            });
        });
      else
        newHanjaCombos.forEach((combo) => {
          if (combo.lesson === lesson && combo.isAllCombo)
            primQuestionList.push({
              _id: combo._id,
              question: combo.hanja + ": ",
              answer: combo.kor,
            });
        });
    } else if (topic === "all-combo-eng") {
      if (lesson === "0")
        newHanjaCombos.forEach((combo) => {
          // Build Cumulative
          if (combo.isAllCombo)
            primQuestionList.push({
              _id: combo._id,
              question: combo.hanja + ": ",
              answer: combo.eng,
            });
        });
      else
        newHanjaCombos.forEach((combo) => {
          if (combo.lesson === lesson && combo.isAllCombo)
            primQuestionList.push({
              _id: combo._id,
              question: combo.hanja + ": ",
              answer: combo.eng,
            });
        });
    } else if (topic === "prac-sent") {
      _isPractiseQuestion = true;
      if (_lesson === "0") {
        pracSentences.forEach((sentence) => {
          primQuestionList.push({
            _id: sentence._id,
            question: sentence.question,
            answer: sentence.answer,
          });
        });
      } else {
        pracSentences.forEach((sentence) => {
          if (sentence.lesson === _lesson)
            primQuestionList.push({
              _id: sentence._id,
              question: sentence.question,
              answer: sentence.answer,
            });
        });
      }
    }

    setIsSaved(_isSaved);
    setPrimaryQuestionList(primQuestionList);
    setQuizTopic(_quizTopic);
    setLesson(_lesson);
    showModal(true);
    setIsPractiseSentence(_isPractiseQuestion);
  };

  const [value, setValue] = React.useState(0);

  function getCards(type) {
    return Object.keys(localStorage)
      .filter((key) => key.includes(`${type}:`))
      .map((key) => {
        const [prefix, topic, lesson] = key.split(":");

        return {
          value: localStorage[key],
          header: QUIZ_TOPIC_MAP[topic],
          topic,
          lesson: parseInt(lesson),
          label: "Lesson " + lesson,
          prefix,
        };
      })
      .reduce((result, item) => {
        if (result[item.header]) {
          result[item.header].push(item);
          result[item.header].sort((a, b) => (a.lesson > b.lesson ? 1 : -1));
        } else {
          result[item.header] = [item];
        }
        return result;
      }, {});
  }

  function onDeleteVocab(lesson, type, vocabId) {
    const clonedVocabs = _.cloneDeep(vocabs);
    clonedVocabs[lesson][type] = clonedVocabs[lesson][type].filter(
      (voc) => voc._id !== vocabId,
    );
    setVocabs(clonedVocabs);
    savedVocabsStore.set(
      savedVocabsStore
        .get()
        .filter((voc) => voc !== `${lesson}_${type}_${vocabId}`),
    );
  }

  function getSavedVocabs() {
    const savedVocabs = savedVocabsStore.get() || [];
    const groupedByLessons = {};
    savedVocabs.forEach((voc) => {
      const [lesson, vocabType, vocId] = voc.split("_");
      if (_.isEmpty(groupedByLessons)) {
        groupedByLessons[lesson] = { mainText: [], exSentences: [] };
        groupedByLessons[lesson][vocabType] = [vocId];
      } else {
        groupedByLessons[lesson][vocabType].push(vocId);
      }
    });

    axios
      .get("/api/lesson/getNewVocab", {
        params: { lesson: Object.keys(groupedByLessons).join(",") },
      })
      .then((resp) => {
        if (resp.data.length) {
          const data = resp.data;
          data.forEach((d) => {
            if (d.lesson in groupedByLessons) {
              if ("mainText" in groupedByLessons[d.lesson]) {
                groupedByLessons[d.lesson].mainText = groupedByLessons[
                  d.lesson
                ].mainText.map((id) => {
                  return d.mainText.find((voc) => voc._id === id);
                });
              }
              if ("exSentences" in groupedByLessons[d.lesson]) {
                groupedByLessons[d.lesson].exSentences = groupedByLessons[
                  d.lesson
                ].exSentences.map((id) => {
                  return d.exSentences.find((voc) => voc._id === id);
                });
              }
            }
          });
          setVocabs(groupedByLessons);
        }
      });
  }

  const inProgressCards = getCards("inprogress");
  const savedCards = getCards("saved");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    showModal(false);
    setPrimaryQuestionList([]);
    setQuizTopic("");
  };
  const name = props.auth.user.name;

  return (
    <div className="ir-Profile">
      <div className="ir-Profile-greeting">
        <Avatar {...stringAvatar(name)} />
        <h2 className="ir-Profile-username">Hello, {name}!</h2>
      </div>
      <br />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            className="ir-Profile-tabs"
            value={value}
            onChange={handleChange}
            aria-label="profile tabs"
          >
            <Tab label={`In Progress ${props.type}`} {...a11yProps(0)} />
            <Tab label={`Saved ${props.type}`} {...a11yProps(1)} />
            <Tab label="Saved Vocabulary" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={shouldShowModal}
        >
          <FlashCardContainer
            showInProgressCards={!isSaved}
            onClose={handleClose}
            primaryQuestionList={primaryQuestionList}
            isPracticeSentence={isPracticeSentence}
            quizTopic={quizTopic}
            lesson={lesson}
          />
        </Modal>
        <TabPanel value={value} index={0}>
          {Object.entries(inProgressCards || []).length ? (
            Object.entries(inProgressCards || []).map(([header, value]) => (
              <div className="ir-Profile-cardsRow" key={header}>
                <h5>{header}</h5>
                <br />
                <div className="ir-Profile-cardContainer">
                  {value.map((card) => (
                    <Card
                      key={card.lesson}
                      variant="outlined"
                      className="ir-Profile-card"
                      onClick={() =>
                        openModal(card.topic, card.lesson.toString())
                      }
                    >
                      <CardContent>{card.label}</CardContent>
                    </Card>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <h4 className="ir-Profile-cardsRow">No quizzes in progress</h4>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {Object.entries(savedCards || []).length ? (
            Object.entries(savedCards || []).map(([header, value]) => (
              <div className="ir-Profile-cardsRow" key={header}>
                <h5>{header}</h5>
                <br />
                <div className="ir-Profile-cardContainer">
                  {value.map((card) => (
                    <Card
                      key={card.lesson}
                      variant="outlined"
                      className="ir-Profile-card"
                      onClick={() =>
                        openModal(card.topic, card.lesson.toString(), true)
                      }
                    >
                      <CardContent>{card.label}</CardContent>
                    </Card>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <h4 className="ir-Profile-cardsRow">No quizzes saved</h4>
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {Object.entries(vocabs || []).length ? (
            Object.entries(vocabs || []).map(([header, value]) => (
              <div className="ir-Profile-cardsRow" key={"Lesson " + header}>
                <h5>{"Lesson " + header}</h5>
                <br />
                {value.mainText && (
                  <div>
                    <h6>From Main Text</h6>
                    <br />
                    <Table className={"table"}>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ width: "25%", textAlign: "left" }}
                          >
                            {" "}
                            <b>Korean</b>{" "}
                          </TableCell>
                          <TableCell
                            style={{ width: "25%", textAlign: "left" }}
                          >
                            {" "}
                            <b>Dictionary Form</b>{" "}
                          </TableCell>
                          <TableCell
                            style={{ width: "25%", textAlign: "left" }}
                          >
                            {" "}
                            <b>English</b>{" "}
                          </TableCell>
                          <TableCell
                            style={{ width: "25%", textAlign: "left" }}
                          >
                            {" "}
                            <b>Hanja</b>{" "}
                          </TableCell>
                          <TableCell
                            style={{ width: "25%", textAlign: "left" }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {value.mainText.map((aVocab, index) => (
                          <TableRow key={"mainTextVocab" + index}>
                            <TableCell
                              style={{ width: "25%", textAlign: "left" }}
                            >
                              {aVocab.kor}
                            </TableCell>
                            <TableCell
                              style={{ width: "25%", textAlign: "left" }}
                            >
                              {aVocab.dictionaryForm}
                            </TableCell>
                            <TableCell
                              style={{ width: "25%", textAlign: "left" }}
                            >
                              {aVocab.eng}
                            </TableCell>
                            <TableCell
                              style={{ width: "25%", textAlign: "left" }}
                            >
                              {aVocab.hanja}
                            </TableCell>
                            <TableCell
                              style={{ width: "25%", textAlign: "right" }}
                            >
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  onDeleteVocab(header, "mainText", aVocab._id)
                                }
                              >
                                <RemoveIcon />
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                )}
                {value.exSentences && (
                  <div>
                    <h6>From Example Sentences</h6>
                    <br />
                    <Table className={"table"}>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ width: "25%", textAlign: "left" }}
                          >
                            {" "}
                            <b>Korean</b>{" "}
                          </TableCell>
                          <TableCell
                            style={{ width: "25%", textAlign: "left" }}
                          >
                            {" "}
                            <b>Dictionary Form</b>{" "}
                          </TableCell>
                          <TableCell
                            style={{ width: "25%", textAlign: "left" }}
                          >
                            {" "}
                            <b>English</b>{" "}
                          </TableCell>
                          <TableCell
                            style={{ width: "25%", textAlign: "left" }}
                          >
                            {" "}
                            <b>Hanja</b>{" "}
                          </TableCell>
                          <TableCell
                            style={{ width: "25%", textAlign: "left" }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(value.exSentences || []).map((aVocab, index) => (
                          <TableRow key={"exSentences" + index}>
                            <TableCell
                              style={{ width: "25%", textAlign: "left" }}
                            >
                              {aVocab.kor}
                            </TableCell>
                            <TableCell
                              style={{ width: "25%", textAlign: "left" }}
                            >
                              {aVocab.dictionaryForm}
                            </TableCell>
                            <TableCell
                              style={{ width: "25%", textAlign: "left" }}
                            >
                              {aVocab.eng}
                            </TableCell>
                            <TableCell
                              style={{ width: "25%", textAlign: "left" }}
                            >
                              {aVocab.hanja}
                            </TableCell>
                            <TableCell
                              style={{ width: "25%", textAlign: "right" }}
                            >
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  onDeleteVocab(
                                    header,
                                    "exSentences",
                                    aVocab._id,
                                  )
                                }
                              >
                                <RemoveIcon />
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                )}
              </div>
            ))
          ) : (
            <h4 className="ir-Profile-cardsRow">No vocabularies saved</h4>
          )}
          <br />
        </TabPanel>
      </Box>
    </div>
  );
}

Profile.propTypes = {
  type: PropTypes.oneOf(["Quizzes", "Flashcards"]),
};

Profile.defaultProps = {
  type: "Quizzes",
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    phonetics: state.okpyeon.phonetics,
    newHanja: state.lessons.newHanja,
    radicals: state.okpyeon.radicals,
    newHanjaCombos: state.lessons.newHanjaCombos,
    pracSentences: state.lessons.pracSentences,
  };
};

export default connect(mapStateToProps, {
  getRadicals,
  getNewHanja,
  getPhonetics,
  getNewHanjaCombos,
  getPracticeSentences,
})(Profile);
