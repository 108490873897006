// Okpyeon
export const GET_CHARACTERS = "GET_CHARACTERS";
export const GET_NEW_BUSU = "GET_NEW_BUSU";
export const GET_RADICALS = "GET_RADICALS";
export const GET_PHONETICS = "GET_PHONETICS";
export const GET_EUM_FILTERS = "GET_EUM_FILTERS";

// Main Text
export const GET_MAIN_TEXT_AND_EX_SENTENCES = "GET_MAIN_TEXT_AND_EX_SENTENCES";
export const GET_NEW_VOCABULARY = "GET_NEW_VOCABULARY";

// Lesson - New Hanja Combo
export const GET_NEW_HANJA_COMBOS = "GET_NEW_HANJA_COMBOS";
export const INSERT_NEW_HANJA_COMBO = "INSERT_NEW_HANJA_COMBO";

// Lesson - All Hanja Combo
export const GET_ALL_HANJA_COMBOS = "GET_ALL_HANJA_COMBOS";

// Lesson - About New Busu
export const GET_ABOUT_NEW_BUSU = "GET_ABOUT_NEW_BUSU";

// Lesson - Practice Sentences (Yemun)
export const GET_YEMUN = "GET_YEMUN";

// Quizzes
export const GET_PRAC_SENTENCES = "GET_PRAC_SENTENCES";

// Lesson - New Hanja
export const GET_NEW_HANJA = "GET_NEW_HANJA";

// Instructor - Edit Lesson
export const SAVE_MAIN_TEXT = "SAVE_MAIN_TEXT";
export const SAVE_EX_SENTENCES = "SAVE_EX_SENTENCES";
export const SAVE_OTHERS = "SAVE_OTHERS";

export const SAVE_ABOUT_NEW_BUSU = "SAVE_ABOUT_NEW_BUSU";

// Instructor - Create Lesson
export const GET_NUM_STORIES = "GET_NUM_STORIES";
