/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { connect } from "react-redux";
import "./EditLesson.css";
import Divider from "@material-ui/core/Divider/Divider";
import {
  saveHanjaCharacter,
  savePhonetic,
  saveRadical,
  deleteHanjaCharacter,
  deleteRadical,
  deletePhonetic,
} from "../../../actions/KORN351/Instructor";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Modal,
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PlusIcon from "@material-ui/icons/Add";
import axios from "axios";

class EditOkpyeon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radicals: [],
      newHanja: [],
      phonetics: [],
      pracSentences: [],
      isPhoneticModalOpen: false,
      newPhonetic: {},
      isRadicalModalOpen: false,
      isPracSentencesModalOpen: false,
      newPracSentence: {},
      newRadical: {},
      isHanjaAddModalOpen: false,
      newHanjaChar: {},
    };

    this.lesson = parseInt(this.props.match.params.id);
  }

  componentDidMount() {
    this.setState({ tabValue: 0 });

    axios.get("/api/okpyeon/getRadicals").then((resp) => {
      let temp = (resp.data || []).filter((char) => {
        return char.lesson === this.lesson;
      });
      this.setState({
        radicals: temp,
      });
    });

    axios.get("/api/lesson/getPracSentences").then((resp) => {
      let temp = (resp.data || []).filter((char) => {
        return parseInt(char.lesson) === this.lesson;
      });
      this.setState({
        pracSentences: temp,
      });
    });

    axios.get("/api/lesson/getNewHanja").then((resp) => {
      let temp = (resp.data || []).filter((char) => {
        return char.lesson === this.lesson;
      });

      this.setState({
        newHanja: temp,
      });
    });

    axios.get("/api/okpyeon/getPhonetics").then((resp) => {
      let temp = (resp.data || []).filter((phonetic) => {
        return phonetic.lesson === this.lesson;
      });
      this.setState({
        phonetics: temp,
      });
    });
  }

  createHanjaChar = () => {
    const { newHanjaChar } = this.state;
    newHanjaChar.lesson = this.lesson;
    axios({
      method: "POST",
      data: newHanjaChar,
      url: "/api/instructor351/addHanjaCharacter",
    }).then((res) => {
      newHanjaChar._id = res.data;
      this.setState((prevState) => ({
        newHanja: [newHanjaChar, ...prevState.newHanja],
      }));
      this.onHanjaAddModalToggle();
    });
  };

  createPracSentence = () => {
    const { newPracSentence } = this.state;
    newPracSentence.lesson = this.lesson;
    axios({
      method: "POST",
      data: newPracSentence,
      url: "/api/lesson/addPracSentence",
    }).then((res) => {
      newPracSentence._id = res.data;
      this.setState((prevState) => ({
        pracSentences: [newPracSentence, ...prevState.pracSentences],
      }));
      this.onPracSentencesToggle();
    });
  };

  savePracSentence = (id) => {
    let question = this.state[id + "question"].value.trim();
    let answer = this.state[id + "answer"].value.trim();
    axios({
      method: "PUT",
      data: { question, answer, id, lesson: this.lesson.toString() },
      url: "/api/lesson/updatePracSentence",
    }).then(() => {
      window.alert("Updated successfully!");
    });
  };

  saveHanjaCharacter = (id) => {
    let additionalHoonMeaning = this.state[id + "additionalHoonMeaning"]
      ? this.state[id + "additionalHoonMeaning"].value.trim()
      : "";
    let characterStrokeCount = parseInt(
      this.state[id + "characterStrokeCount"].value.trim(),
    );
    let eum = this.state[id + "eum"].value.trim();
    let hanja = this.state[id + "hanja"].value.trim();
    let hoonEum = this.state[id + "hoonEum"].value.trim();
    let meaning = this.state[id + "meaning"].value.trim();
    let phonetic = this.state[id + "phonetic"]
      ? this.state[id + "phonetic"].value.trim()
      : "";
    let primaryHoonMeaning = this.state[id + "primaryHoonMeaning"]
      ? this.state[id + "primaryHoonMeaning"].value.trim()
      : "";
    let radical = this.state[id + "radical"].value.trim();
    let radicalMeaning = this.state[id + "radicalMeaning"].value.trim();
    let radicalName = this.state[id + "radicalName"].value.trim();
    let radicalStrokeCount = parseInt(
      this.state[id + "radicalStrokeCount"].value.trim(),
    );
    let totalStrokeCount = parseInt(
      this.state[id + "totalStrokeCount"].value.trim(),
    );

    this.props.saveHanjaCharacter(
      parseInt(this.props.match.params.id),
      id,
      additionalHoonMeaning,
      characterStrokeCount,
      eum,
      hanja,
      hoonEum,
      meaning,
      phonetic,
      primaryHoonMeaning,
      radical,
      radicalMeaning,
      radicalName,
      radicalStrokeCount,
      totalStrokeCount,
    );
  };
  deleteHanjaCharacter = (id, hanja) => {
    if (
      window.confirm(
        "Are you sure you want to delete " + hanja + " from Hanja Characters?",
      )
    ) {
      this.props.deleteHanjaCharacter(parseInt(this.props.match.params.id), id);
      window.alert(hanja + " has been deleted!");
    } else {
      alert(hanja + " has NOT been deleted.");
    }
  };

  deletePracSentence = (id) => {
    if (
      window.confirm("Are you sure you want to delete this Practice Sentence?")
    ) {
      axios({
        method: "DELETE",
        url: "/api/lesson/deletePracSentence",
        data: { id, lesson: this.lesson.toString() },
      }).then(() => {
        this.setState((prevState) => ({
          pracSentences: prevState.pracSentences.filter((p) => p._id !== id),
        }));
        window.alert("Deleted successfully!");
      });
    } else {
      alert("Delete failed. Please try again.");
    }
  };

  createPhonetic = () => {
    const { newPhonetic } = this.state;
    if (newPhonetic.characters) {
      newPhonetic.characters = newPhonetic.characters
        .split(",")
        .map((c) => c.trim())
        .filter((c) => !!c);
    }
    if (newPhonetic.sub_pronunciation_characters) {
      newPhonetic.sub_pronunciation_characters = deserializeObject(
        newPhonetic.sub_pronunciation_characters.trim(),
      );
    }
    newPhonetic.lesson = this.lesson;

    axios({
      method: "POST",
      data: newPhonetic,
      url: "/api/instructor351/addPhonetic",
    }).then((res) => {
      newPhonetic._id = res.data;
      this.setState((prevState) => ({
        phonetics: [newPhonetic, ...prevState.phonetics],
      }));
      this.onPhoneticAddModalToggle();
    });
  };

  createRadical = () => {
    const { newRadical } = this.state;
    newRadical.lesson = this.lesson;
    axios({
      method: "POST",
      data: newRadical,
      url: "/api/instructor351/addRadical",
    }).then((res) => {
      newRadical._id = res.data;
      this.setState((prevState) => ({
        radicals: [newRadical, ...prevState.radicals],
      }));
      this.onRadicalAddModalToggle();
    });
  };

  savePhonetic = (id) => {
    let characters = this.state[id + "characters"].value.split("\n");
    characters = characters.filter((ex) => {
      return ex.trim() !== "";
    });
    let phonetic = this.state[id + "phonetic"].value.trim();
    let pronunciation = this.state[id + "pronunciation"].value.trim();

    // // Apparently unused code below
    // let sub_pronunciation = [];
    // let sub_characters = [];

    // if (this.state[id + 'sub-pronunciation'])
    //     sub_pronunciation = this.state[id + 'sub-pronunciation'].value.trim();
    // if (this.state[id + 'sub-pronunciation']) {
    //     sub_characters = this.state[id + 'sub-characters'].value.split("\n");
    //     sub_characters = sub_characters.filter(ex => {
    //         return ex.trim() !== "";
    //     });
    // }
    let sub_pronunciation_characters = {};
    if (this.state[id + "sub_pronunciation_characters"]) {
      sub_pronunciation_characters = deserializeObject(
        this.state[id + "sub_pronunciation_characters"].value.trim(),
      );
    }

    this.props.savePhonetic(
      parseInt(this.props.match.params.id),
      id,
      characters,
      phonetic,
      pronunciation,
      sub_pronunciation_characters,
    );
  };
  deletePhonetic = (id, phonetic) => {
    if (
      window.confirm(
        "Are you sure you want to delete " + phonetic + " from Phonetics?",
      )
    ) {
      this.props.deletePhonetic(parseInt(this.props.match.params.id), id);
      window.alert(phonetic + " has been deleted!");
    } else {
      alert(phonetic + " has NOT been deleted.");
    }
  };

  onHanjaAddModalToggle = () => {
    this.setState((prevState) => ({
      isHanjaAddModalOpen: !prevState.isHanjaAddModalOpen,
      newHanjaChar: {},
    }));
  };

  onPhoneticAddModalToggle = () => {
    this.setState((prevState) => ({
      isPhoneticModalOpen: !prevState.isPhoneticModalOpen,
      newPhonetic: {},
    }));
  };

  onRadicalAddModalToggle = () => {
    this.setState((prevState) => ({
      isRadicalModalOpen: !prevState.isRadicalModalOpen,
      newRadical: {},
    }));
  };

  onPracSentencesToggle = () => {
    this.setState((prevState) => ({
      isPracSentencesModalOpen: !prevState.isPracSentencesModalOpen,
      newPracSentence: {},
    }));
  };

  saveRadical = (id) => {
    let additionalHoonMeaning = this.state[id + "additionalHoonMeaning"]
      ? this.state[id + "additionalHoonMeaning"].value.trim()
      : "";
    let hoonEum = this.state[id + "hoonEum"].value.trim();
    let meaning = this.state[id + "meaning"].value.trim();
    let primaryHoonMeaning = this.state[id + "primaryHoonMeaning"]
      ? this.state[id + "primaryHoonMeaning"].value.trim()
      : "";
    let radical = this.state[id + "radical"].value.trim();
    let phonetic = this.state[id + "phonetic"].value.trim();
    let radicalHangul = this.state[id + "radicalHangul"].value.trim();
    let radicalStrokeCount = parseInt(
      this.state[id + "radicalStrokeCount"].value.trim(),
    );
    this.props.saveRadical(
      parseInt(this.props.match.params.id),
      id,
      additionalHoonMeaning,
      hoonEum,
      meaning,
      primaryHoonMeaning,
      radical,
      radicalHangul,
      phonetic,
      radicalStrokeCount,
    );
  };
  deleteRadical = (id, radical) => {
    if (
      window.confirm(
        "Are you sure you want to delete " + radical + " from Radicals?",
      )
    ) {
      this.props.deleteRadical(parseInt(this.props.match.params.id), id);
      window.alert(radical + " has been deleted!");
    } else {
      alert(radical + " has NOT been deleted.");
    }
  };

  handleOnChangeTab = (event, value) => {
    this.setState({ tabValue: value });
  };

  render() {
    const {
      newHanja,
      phonetics,
      radicals,
      newPhonetic,
      newRadical,
      isPhoneticModalOpen,
      isRadicalModalOpen,
      isHanjaAddModalOpen,
      newHanjaChar,
      pracSentences,
      isPracSentencesModalOpen,
      newPracSentence,
    } = this.state;

    return (
      <div className="edit-lesson-container">
        <h2>Lesson {this.props.match.params.id}</h2>
        <h5>
          <i>Editing Okpyeon: Click on a category to edit</i>
        </h5>

        <div>
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleOnChangeTab}
            indicatorColor="primary"
            textColor="primary"
            centered
            style={{ padding: "2%" }}
          >
            <Tab label="Hanja Characters" />
            <Tab label="Phonetics" />
            <Tab label="Radicals" />
            <Tab label="Practice Sentences" />
          </Tabs>
        </div>

        {this.state.tabValue === 0 && (
          <div className="edit-lesson-background">
            <div className="ir-EditOkpyeon-header">
              <h2>
                Hanja Characters (For Lesson&#39;s &#39;새 한자&#39; section &
                Okpyeon)
              </h2>
              <IconButton
                size="medium"
                onClick={this.onHanjaAddModalToggle}
                className="primary-button"
                variant="contained"
              >
                <PlusIcon />
              </IconButton>
            </div>
            <Divider />
            <br />
            <Modal
              open={isHanjaAddModalOpen}
              onClose={this.onHanjaAddModalToggle}
            >
              <Box className="ir-EditOkpyeon-modal">
                <Typography variant="h6" component="h6">
                  Add Hanja Character
                </Typography>
                <label>Hanja:</label>
                <input
                  type="text"
                  placeholder="Type Hanja.."
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newHanjaChar.hanja = event.target.value)
                  }
                />
                <br />
                <label>音 (음/Eum):</label>
                <input
                  type="text"
                  placeholder="Type 音 (음/Eum).."
                  style={{ width: 400 }}
                  onChange={(event) => (newHanjaChar.eum = event.target.value)}
                />
                <br />
                <label>Meaning:</label>
                <textarea
                  placeholder="Type Meaning.."
                  style={{ width: 400 }}
                  rows="3"
                  onChange={(event) =>
                    (newHanjaChar.meaning = event.target.value)
                  }
                ></textarea>
                <br />
                <label>訓 (훈) + 音 (음):</label>
                <input
                  placeholder="Type 訓 (훈) + 音 (음).."
                  type="text"
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newHanjaChar.hoonEum = event.target.value)
                  }
                />
                <br />
                <label>Primary 訓 (훈/Hoon) Meaning:</label>
                <textarea
                  placeholder="Type Primary 訓 (훈/Hoon) Meaning.."
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newHanjaChar.primaryHoonMeaning = event.target.value)
                  }
                ></textarea>
                <br />
                <label>Additional 訓 (훈/Hoon):</label>
                <textarea
                  placeholder="Type Additional 訓 (훈/Hoon).."
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newHanjaChar.additionalHoonMeaning = event.target.value)
                  }
                ></textarea>
                <br />
                <label>Phonetic:</label>
                <input
                  placeholder="Add Phonetic.."
                  type="text"
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newHanjaChar.phonetic = event.target.value)
                  }
                />
                <br />
                <label>Radical:</label>
                <input
                  placeholder="Add Radical.."
                  type="text"
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newHanjaChar.radical = event.target.value)
                  }
                />
                <br />
                <label>Radical Meaning:</label>
                <input
                  placeholder="Add Radical Meaning.."
                  type="text"
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newHanjaChar.radicalMeaning = event.target.value)
                  }
                />
                <br />
                <label>Radical Name:</label>
                <input
                  placeholder="Add Radical Name.."
                  type="text"
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newHanjaChar.radicalName = event.target.value)
                  }
                />
                <br />
                <label>Radical Stroke Count:</label>
                <input
                  placeholder="Add Radical Stroke Count.."
                  type="text"
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newHanjaChar.radicalStrokeCount = event.target.value)
                  }
                />
                <br />
                <label>Character Stroke Count:</label>
                <input
                  placeholder="Add Character Stroke Count.."
                  type="text"
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newHanjaChar.characterStrokeCount = event.target.value)
                  }
                />
                <br />
                <label>Total Stroke Count (Character + Radical):</label>
                <input
                  placeholder="Add Total Stroke Count.."
                  type="text"
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newHanjaChar.totalStrokeCount = event.target.value)
                  }
                />
                <br />
                <br />
                <Button
                  style={{
                    marginRight: "4px",
                    backgroundColor: "#00284d",
                    color: "white",
                    width: "20%",
                  }}
                  onClick={this.createHanjaChar}
                >
                  Create
                </Button>
                <Button
                  variant="contained"
                  onClick={this.onHanjaAddModalToggle}
                >
                  Cancel
                </Button>
              </Box>
            </Modal>
            <div style={{ textAlign: "center" }}>
              {newHanja.map((char) => {
                return (
                  <div key={char._id} style={{ padding: "0 5%" }}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>
                          {char.hanja} {char.eum}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        Hanja:{" "}
                        <input
                          type="text"
                          defaultValue={char.hanja}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[char._id + "hanja"] = input)
                          }
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        音 (음/Eum):{" "}
                        <input
                          type="text"
                          defaultValue={char.eum}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[char._id + "eum"] = input)
                          }
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        Meaning:{" "}
                        <textarea
                          input
                          type="text"
                          defaultValue={char.meaning}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[char._id + "meaning"] = input)
                          }
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        訓 (훈) + 音 (음):{" "}
                        <input
                          type="text"
                          defaultValue={char.hoonEum}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[char._id + "hoonEum"] = input)
                          }
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        Primary 訓 (훈/Hoon) Meaning:{" "}
                        <textarea
                          input
                          type="text"
                          defaultValue={char.primaryHoonMeaning}
                          style={{ width: "50%" }}
                          ref={(input) =>
                            (this.state[char._id + "primaryHoonMeaning"] =
                              input)
                          }
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        Additional 訓 (훈/Hoon):{" "}
                        <textarea
                          input
                          type="text"
                          defaultValue={char.additionalHoonMeaning}
                          style={{ width: "50%" }}
                          ref={(input) =>
                            (this.state[char._id + "additionalHoonMeaning"] =
                              input)
                          }
                        />
                      </AccordionDetails>

                      <AccordionDetails>
                        Phonetic:{" "}
                        <input
                          type="text"
                          defaultValue={char.phonetic}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[char._id + "phonetic"] = input)
                          }
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        Radical:{" "}
                        <input
                          type="text"
                          defaultValue={char.radical}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[char._id + "radical"] = input)
                          }
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        Radical Meaning:{" "}
                        <input
                          type="text"
                          defaultValue={char.radicalMeaning}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[char._id + "radicalMeaning"] = input)
                          }
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        Radical Name:{" "}
                        <input
                          type="text"
                          defaultValue={char.radicalName}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[char._id + "radicalName"] = input)
                          }
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        Radical Stroke Count:{" "}
                        <input
                          type="text"
                          defaultValue={char.radicalStrokeCount}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[char._id + "radicalStrokeCount"] =
                              input)
                          }
                        />
                      </AccordionDetails>

                      <AccordionDetails>
                        Character Stroke Count:{" "}
                        <input
                          type="text"
                          defaultValue={char.characterStrokeCount}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[char._id + "characterStrokeCount"] =
                              input)
                          }
                        />
                        <br />
                      </AccordionDetails>
                      <AccordionDetails>
                        Total Stroke Count (Character + Radical):{" "}
                        <input
                          type="text"
                          defaultValue={char.totalStrokeCount}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[char._id + "totalStrokeCount"] = input)
                          }
                        />
                        <br />
                        <br />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Button
                          style={{
                            marginRight: "4px",
                            backgroundColor: "#00284d",
                            color: "white",
                            width: "20%",
                          }}
                          onClick={() => this.saveHanjaCharacter(char._id)}
                        >
                          SAVE
                        </Button>

                        <Button
                          style={{
                            marginRight: "4px",
                            backgroundColor: "#f6152f",
                            color: "white",
                            width: "20%",
                          }}
                          onClick={() =>
                            this.deleteHanjaCharacter(char._id, char.hanja)
                          }
                        >
                          DELETE
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                    <br />
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {this.state.tabValue === 1 && (
          <div className="edit-lesson-background">
            <div className="ir-EditOkpyeon-header">
              <h2>
                Phonetics (For Lesson&#39;s &#39;새 부수&#39; section & Okpyeon)
              </h2>
              <IconButton
                size="medium"
                onClick={this.onPhoneticAddModalToggle}
                className="primary-button"
                variant="contained"
              >
                <PlusIcon />
              </IconButton>
            </div>
            <Divider />
            <br />
            <Modal
              open={isPhoneticModalOpen}
              onClose={this.onPhoneticAddModalToggle}
            >
              <Box className="ir-EditOkpyeon-modal">
                <Typography variant="h6" component="h6">
                  Add Phonetic
                </Typography>
                <label>Phonetic:</label>
                <input
                  type="text"
                  placeholder="Type Phonetic.."
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newPhonetic.phonetic = event.target.value)
                  }
                />
                <br />
                <label>Characters:</label>
                <textarea
                  placeholder="Type Characters separated by commas"
                  style={{ width: 400 }}
                  rows="3"
                  className="edit-input"
                  onChange={(event) =>
                    (newPhonetic.characters = event.target.value)
                  }
                ></textarea>
                <br />
                <label>Pronounciation:</label>
                <input
                  type="text"
                  placeholder="Type Pronounciation.."
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newPhonetic.pronunciation = event.target.value)
                  }
                />
                <br />
                <label>Sub-Pronounciation & Sub-Characters:</label>
                <div className="ir-EditOkpyeon-instructions">
                  Type in the format{" "}
                  {"<Sub-Pronounciation>: <Sub-characters separated by commas>"}
                  . <br />
                  For multiple sub-pronounciation and sub-characters mapping,
                  use the next line. See example below:
                  <br />
                  막: 莫,幕,膜,漠,寞
                  <br />
                  하: 霞,遐,蝦,鰕,瑕
                  <br />
                </div>
                <textarea
                  style={{ width: 400 }}
                  rows="3"
                  className="edit-input"
                  onChange={(event) =>
                    (newPhonetic.sub_pronunciation_characters =
                      event.target.value)
                  }
                ></textarea>
                <br />
                <br />
                <Button
                  style={{
                    marginRight: "4px",
                    backgroundColor: "#00284d",
                    color: "white",
                    width: "20%",
                  }}
                  onClick={this.createPhonetic}
                >
                  Create
                </Button>
                <Button
                  variant="contained"
                  onClick={this.onPhoneticAddModalToggle}
                >
                  Cancel
                </Button>
              </Box>
            </Modal>
            <div>
              {phonetics.map((phon) => {
                let str = "";
                phon.characters.forEach((char) => {
                  str += char + "\n";
                });

                // Apparently unused code below
                // let str2 = '';
                // if (phon.sub_pronunciation !== undefined) {
                //     phon.sub_characters.forEach(charac => {
                //         str2 += charac + '\n';
                //     })
                // }

                return (
                  <div key={phon._id} style={{ padding: "0 5%" }}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{phon.phonetic}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        Phonetic:{" "}
                        <input
                          type="text"
                          defaultValue={phon.phonetic}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[phon._id + "phonetic"] = input)
                          }
                        />
                        <br />
                      </AccordionDetails>
                      <AccordionDetails>
                        Characters:
                        <textarea
                          style={{ overflowWrap: "break-word", width: "50%" }}
                          rows="5"
                          className="edit-input"
                          ref={(input) =>
                            (this.state[phon._id + "characters"] = input)
                          }
                        >
                          {str}
                        </textarea>
                        <br />
                      </AccordionDetails>
                      <AccordionDetails>
                        Pronunciation:{" "}
                        <input
                          type="text"
                          defaultValue={phon.pronunciation}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[phon._id + "pronunciation"] = input)
                          }
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <div>
                          <label>Sub-Pronounciation & Sub-Characters:</label>
                          <div className="ir-EditOkpyeon-instructions">
                            Type in the format{" "}
                            {
                              "<Sub-Pronounciation>: <Sub-characters separated by commas>"
                            }
                            . <br />
                            For multiple sub-pronounciation and sub-characters
                            mapping, use the next line. See example below:
                            <br />
                            막:莫,幕,膜,漠,寞
                            <br />
                            하:霞,遐,蝦,鰕,瑕
                            <br />
                          </div>
                          <textarea
                            style={{ width: 400 }}
                            rows="3"
                            className="edit-input"
                            defaultValue={serializeObject(
                              phon.sub_pronunciation_characters,
                            )}
                            ref={(input) =>
                              (this.state[
                                phon._id + "sub_pronunciation_characters"
                              ] = input)
                            }
                          ></textarea>
                        </div>
                      </AccordionDetails>
                      <AccordionDetails>
                        <Button
                          style={{
                            marginRight: "4px",
                            backgroundColor: "#00284d",
                            color: "white",
                            width: "20%",
                          }}
                          onClick={() => this.savePhonetic(phon._id)}
                        >
                          SAVE
                        </Button>
                        <Button
                          style={{
                            marginRight: "4px",
                            backgroundColor: "#f6152f",
                            color: "white",
                            width: "20%",
                          }}
                          onClick={() =>
                            this.deletePhonetic(phon._id, phon.phonetic)
                          }
                        >
                          DELETE
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                    <br />
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {this.state.tabValue === 2 && (
          <div className="edit-lesson-background">
            <div className="ir-EditOkpyeon-header">
              <h2>Radicals (For Okpyeon)</h2>
              <IconButton
                size="medium"
                onClick={this.onRadicalAddModalToggle}
                className="primary-button"
                variant="contained"
              >
                <PlusIcon />
              </IconButton>
            </div>
            <Divider />
            <br />
            <Modal
              open={isRadicalModalOpen}
              onClose={this.onRadicalAddModalToggle}
            >
              <Box className="ir-EditOkpyeon-modal">
                <Typography variant="h6" component="h6">
                  Add Radical
                </Typography>
                <label>Radical:</label>
                <input
                  type="text"
                  placeholder="Type Radical.."
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newRadical.radical = event.target.value)
                  }
                />
                <br />
                <label>Radical Name:</label>
                <input
                  type="text"
                  placeholder="Type Radical Name.."
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newRadical.radicalHangul = event.target.value)
                  }
                />
                <br />
                <label>Radical Meaning:</label>
                <textarea
                  placeholder="Type Meaning.."
                  style={{ width: 400 }}
                  className="edit-input"
                  onChange={(event) =>
                    (newRadical.meaning = event.target.value)
                  }
                ></textarea>
                <br />
                <label>訓 (훈) + 音 (음):</label>
                <input
                  placeholder="Type 訓 (훈) + 音 (음).."
                  type="text"
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newRadical.hoonEum = event.target.value)
                  }
                />
                <br />
                <label>Primary 訓 (훈/Hoon) Meaning:</label>
                <textarea
                  placeholder="Type Primary 訓 (훈/Hoon) Meaning.."
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newRadical.primaryHoonMeaning = event.target.value)
                  }
                ></textarea>
                <br />
                <label>Additional 訓 (훈/Hoon):</label>
                <textarea
                  placeholder="Type Additional 訓 (훈/Hoon).."
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newRadical.additionalHoonMeaning = event.target.value)
                  }
                ></textarea>
                <br />
                <label>Phonetic:</label>
                <input
                  placeholder="Add Phonetic.."
                  type="text"
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newRadical.phonetic = event.target.value)
                  }
                />
                <br />
                <label>Radical Stroke Count:</label>
                <input
                  placeholder="Type Radical Stroke Count.."
                  type="text"
                  style={{ width: 400 }}
                  onChange={(event) =>
                    (newRadical.radicalStrokeCount = event.target.value)
                  }
                />
                <br />
                <br />
                <Button
                  style={{
                    marginRight: "4px",
                    backgroundColor: "#00284d",
                    color: "white",
                    width: "20%",
                  }}
                  onClick={this.createRadical}
                >
                  Create
                </Button>
                <Button
                  variant="contained"
                  onClick={this.onPhoneticAddModalToggle}
                >
                  Cancel
                </Button>
              </Box>
            </Modal>
            <div style={{ textAlign: "center" }}>
              {radicals.map((rad) => {
                return (
                  <div key={rad._id} style={{ padding: "0 5%" }}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>
                          {rad.radical} {rad.radicalHangul}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        Radical:{" "}
                        <input
                          type="text"
                          defaultValue={rad.radical}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[rad._id + "radical"] = input)
                          }
                        />
                        <br />
                      </AccordionDetails>
                      <AccordionDetails>
                        Radical Name:{" "}
                        <input
                          type="text"
                          defaultValue={rad.radicalHangul}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[rad._id + "radicalHangul"] = input)
                          }
                        />
                        <br />
                      </AccordionDetails>
                      <AccordionDetails>
                        Radical Meaning:{" "}
                        <textarea
                          input
                          type="text"
                          defaultValue={rad.meaning}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[rad._id + "meaning"] = input)
                          }
                        />
                        <br />
                      </AccordionDetails>
                      <AccordionDetails>
                        訓 (훈) + 音 (음):{" "}
                        <input
                          type="text"
                          defaultValue={rad.hoonEum}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[rad._id + "hoonEum"] = input)
                          }
                        />
                        <br />
                      </AccordionDetails>
                      <AccordionDetails>
                        Primary 訓 (훈/Hoon) Meaning:{" "}
                        <textarea
                          input
                          type="text"
                          defaultValue={rad.primaryHoonMeaning}
                          style={{ width: "50%" }}
                          ref={(input) =>
                            (this.state[rad._id + "primaryHoonMeaning"] = input)
                          }
                        />
                        <br />
                      </AccordionDetails>
                      <AccordionDetails>
                        Additional 訓 (훈/Hoon):{" "}
                        <textarea
                          input
                          type="text"
                          defaultValue={rad.additionalHoonMeaning}
                          style={{ width: "50%" }}
                          ref={(input) =>
                            (this.state[rad._id + "additionalHoonMeaning"] =
                              input)
                          }
                        />
                        <br />
                      </AccordionDetails>
                      <AccordionDetails>
                        Phonetic:{" "}
                        <textarea
                          input
                          type="text"
                          defaultValue={rad.phonetic}
                          style={{ width: "50%" }}
                          ref={(input) =>
                            (this.state[rad._id + "phonetic"] = input)
                          }
                        />
                        <br />
                      </AccordionDetails>
                      <AccordionDetails>
                        Radical Stroke Count:{" "}
                        <input
                          type="text"
                          defaultValue={rad.radicalStrokeCount}
                          style={{ width: "25%" }}
                          ref={(input) =>
                            (this.state[rad._id + "radicalStrokeCount"] = input)
                          }
                        />
                        <br />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Button
                          style={{
                            marginRight: "4px",
                            backgroundColor: "#00284d",
                            color: "white",
                            width: "20%",
                          }}
                          onClick={() => this.saveRadical(rad._id)}
                        >
                          SAVE
                        </Button>
                        <Button
                          style={{
                            marginRight: "4px",
                            backgroundColor: "#f6152f",
                            color: "white",
                            width: "20%",
                          }}
                          onClick={() =>
                            this.deleteRadical(rad._id, rad.radical)
                          }
                        >
                          DELETE
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                    <br />
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {this.state.tabValue === 3 && (
          <div className="edit-lesson-background">
            <div className="ir-EditOkpyeon-header">
              <h2>Practice Sentences</h2>
              <IconButton
                size="medium"
                onClick={this.onPracSentencesToggle}
                className="primary-button"
                variant="contained"
              >
                <PlusIcon />
              </IconButton>
            </div>
            <Divider />
            <br />
            <Modal
              open={isPracSentencesModalOpen}
              onClose={this.onPracSentencesToggle}
            >
              <Box className="ir-EditOkpyeon-modal">
                <Typography variant="h6" component="h6">
                  Add Practice Sentence
                </Typography>
                <label>Question:</label>
                <input
                  type="text"
                  placeholder="Type Question.."
                  style={{ width: 800 }}
                  onChange={(event) =>
                    (newPracSentence.question = event.target.value)
                  }
                />
                <br />
                <label>Answer:</label>
                <input
                  type="text"
                  placeholder="Type Answer.."
                  style={{ width: 800 }}
                  onChange={(event) =>
                    (newPracSentence.answer = event.target.value)
                  }
                />
                <br />
                <br />
                <Button
                  style={{
                    marginRight: "4px",
                    backgroundColor: "#00284d",
                    color: "white",
                    width: "20%",
                  }}
                  onClick={this.createPracSentence}
                >
                  Create
                </Button>
                <Button
                  variant="contained"
                  onClick={this.onPracSentencesToggle}
                >
                  Cancel
                </Button>
              </Box>
            </Modal>
            <div style={{ textAlign: "center" }}>
              {pracSentences.map((char) => {
                return (
                  <div key={char._id} style={{ padding: "0 5%" }}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{char.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        Question:{" "}
                        <input
                          type="text"
                          defaultValue={char.question}
                          style={{ width: "70%" }}
                          ref={(input) =>
                            (this.state[char._id + "question"] = input)
                          }
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        Answer:{" "}
                        <input
                          type="text"
                          defaultValue={char.answer}
                          style={{ width: "70%" }}
                          ref={(input) =>
                            (this.state[char._id + "answer"] = input)
                          }
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Button
                          style={{
                            marginRight: "4px",
                            backgroundColor: "#00284d",
                            color: "white",
                            width: "20%",
                          }}
                          onClick={() => this.savePracSentence(char._id)}
                        >
                          SAVE
                        </Button>

                        <Button
                          style={{
                            marginRight: "4px",
                            backgroundColor: "#f6152f",
                            color: "white",
                            width: "20%",
                          }}
                          onClick={() => this.deletePracSentence(char._id)}
                        >
                          DELETE
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                    <br />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function serializeObject(obj) {
  let serialization = "";
  for (const [key, value] of Object.entries(obj || {})) {
    serialization += `${key}:${value.join(",")}\n`;
  }
  return serialization;
}

function deserializeObject(str) {
  const obj = {};
  const items = str
    .split("\n")
    .map((s) => s.trim())
    .filter((s) => s);
  items.forEach((item) => {
    let [key, value] = item.split(":");
    if (value.includes(",")) {
      obj[key.trim()] = value
        .split(",")
        .map((s) => s.trim())
        .filter((s) => s);
    } else if (value.includes(";")) {
      obj[key.trim()] = value
        .split(";")
        .map((s) => s.trim())
        .filter((s) => s);
    } else if (value.includes(".")) {
      obj[key.trim()] = value
        .split(".")
        .map((s) => s.trim())
        .filter((s) => s);
    }
  });
  return obj;
}

const mapStateToProps = (state) => ({
  radicals: state.okpyeon.radicals,
  phonetics: state.okpyeon.phonetics,
  newHanja: state.lessons.newHanja,
  pracSentences: state.lessons.pracSentences,
});

const mapDispatchToProps = {
  saveHanjaCharacter,
  saveRadical,
  savePhonetic,
  deleteHanjaCharacter,
  deleteRadical,
  deletePhonetic,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOkpyeon);
