import HanziWriter from "hanzi-writer";
const MISSING_CHARS = [
  "窓",
  "金",
  "鄕",
  "利",
  "甁",
  "𥫗",
  "牜", // the only one still missing (lesson 4)

  // "金", "金", // lesson 1
  // "車", "車", "通", // lesson 2
  // '答', '國', "茶", "茶", // lesson 3
  // "旅", "旅", "窓", // lesson 4 -- window 窓 actually has no hanja in hanzi-writer demo
  // "復", "復", "飮", "飲", "來", "來", // lesson 5
  // "六", "六", "樂", "樂" // lesson 6
  "利",
  "汚",
  "粧",
  "念",
  "飜",
  "査",
  "眞",
  "顔",
  "狀",
];

function showHanjiAnimation(hanja, target) {
  hanja = hanja.trim();
  let writer;

  if (MISSING_CHARS.includes(hanja)) {
    return (document.getElementById(target).innerHTML =
      `<span class="missing-hanja">${hanja}</span>`);
  } else {
    writer = HanziWriter.create(target, hanja, {
      width: 100,
      height: 100,
      padding: 5,
      showOutline: true,
    });
  }
  writer.loopCharacterAnimation();
}

function getQuestionFontSize(question) {
  return question.length > 80 ? "20px" : "30px";
}

function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
}

function stringAvatar(name) {
  const splitName = name.split(" ");
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children:
      splitName.length === 2
        ? `${splitName[0][0]}${splitName[1][0]}`
        : splitName[0][0],
  };
}

function findDiff(str1, str2) {
  let str1WithoutStr2 = "";
  str2 = str2.replace(/[,;//~\s]/g, "");
  for (let i = 0; i < str2.length; i++) {
    const char = str2.charAt(i);
    if (str1.includes(char)) {
      const lastOcc = str1.lastIndexOf(char);
      for (let i = 0; i < str1.length; i++) {
        if (i == lastOcc) {
          str1WithoutStr2 += "";
        } else {
          str1WithoutStr2 += str1.charAt(i);
        }
      }
      str1 = str1WithoutStr2;
      str1WithoutStr2 = "";
    }
  }
  return str1.trim();
}

function getHanjaTitle(char) {
  const hoon = findDiff(char.hoonEum, char.eum);
  return `${hoon} ${char.hanja.trim().normalize("NFC")}(${char.eum})`;
}

export const HanjaComparator = new Intl.Collator("ko-KR", {
  numeric: true,
  sensitivity: "base",
});
export const compareHanja = (a, b) =>
  HanjaComparator.compare(
    a.trim().normalize("NFC"),
    b.trim().normalize("NFC"),
  ) === 0;

export {
  getQuestionFontSize,
  stringAvatar,
  stringToColor,
  showHanjiAnimation,
  findDiff,
  getHanjaTitle,
};
