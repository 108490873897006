import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

class Stories extends Component {
  render() {
    return <Grid item xs={8}></Grid>;
  }
}

export default Stories;
